/* global getEnvironment */
import bowser from 'bowser';

(function () {
  'use strict';

  angular.module('loginApp', [
    'ngSentry',
    'constants',
    'ngCookies',
    'blocks.fadeIn',
    'blocks.lowercase',
    'blocks.i18n',
    'ngSanitize',
  ]);

  angular
    .module('loginApp')
    .controller('LoginController', LoginController);

  /* @ngInject */
  function LoginController(
    $cookies,
    $http,
    $q,
    $rootScope,
    $scope,
    $timeout,
    $window,
    CONSTANTS,
  ) {

    const vm = this;
    let requestCanceller = $q.defer();

    vm.closeChangeLanguageModal = closeChangeLanguageModal;
    vm.enableUserById = enableUserById;
    vm.browserIsInternetExplorer = isInternetExplorer();
    vm.browserIsInternetExplorer = false;
    vm.languageCodes = CONSTANTS.LANGUAGE_CODES_FOR_EMPLOYEE;
    vm.showForgotForm = showForgotForm;
    vm.showForm = 'LOGIN';
    vm.showLoginForm = showLoginForm;
    vm.userEnablerStatus = 'INACTIVE';
    vm.user = false;
    vm.userHasNoPassword = userHasNoPassword;
    vm.username = $cookies.get('username') || '';
    vm.sendTemporaryPasswordToEmail = sendTemporaryPasswordToEmail;
    vm.sendingTemporaryPasswordToEmail = false;
    vm.temporaryPasswordSentToEmailSuccessfully = null;
    vm.forgottenPasswordDeliveryMethod = 'SMS';

    $rootScope.LOCALE = $window.LOCALE;
    $rootScope.SM_APPEARANCE = $window.SM_APPEARANCE;

    activate();

    ////

    $scope.$watch('vm.username', usernameWatcher);

    ////

    function closeChangeLanguageModal() {
      vm.changeLanguageModalIsOpen = false;
      $scope.$digest();
    }

    function isInternetExplorer() {
      const browser = bowser.getParser(window.navigator.userAgent);
      return browser.getBrowserName() === 'Internet Explorer';
    }

    function activate() {
      $rootScope.environment = getEnvironment();
      angular.forEach(CONSTANTS, (value, key) => {
        $rootScope[key] = value;
      });
    }

    function enableUserById(userId) {
      vm.userEnablerStatus = 'LOADING';
      $http.put('access-control/api/v1/users/' + userId + '/temporary-password?delivery-method=SMS', '')
        .then(enableRequestSuccessHandler)
        .catch(enableRequestErrorHandler);
    }

    function enableRequestSuccessHandler() {
      vm.user.canLogin = true;
      vm.userEnablerStatus = 'ENABLED';
    }

    function enableRequestErrorHandler() {
      vm.userEnablerStatus = 'ERROR';
    }

    function sendTemporaryPasswordToEmail(userId) {
      vm.sendingTemporaryPasswordToEmail = true;
      vm.temporaryPasswordSentToEmailSuccessfully = null;
      $http.put('access-control/api/v1/users/' + userId + '/temporary-password?delivery-method=EMAIL', '')
        .then(() => {
          vm.sendingTemporaryPasswordToEmail = false;
          vm.temporaryPasswordSentToEmailSuccessfully = true;
        })
        .catch(() => {
          vm.sendingTemporaryPasswordToEmail = false;
          vm.temporaryPasswordSentToEmailSuccessfully = false;
        });
    }

    function userHasNoPassword() {
      return !(vm.username && !vm.user.canLogin);
    }

    function showForgotForm() {
      vm.showForm = 'FORGOT';
    }

    function showLoginForm() {
      vm.showForm = 'LOGIN';
    }

    function usernameWatcher(username) {

      requestCanceller.resolve();
      requestCanceller.promise.then(checkUsername);

      function checkUsername() {

        vm.checkingUsername = true;
        vm.user = false;
        requestCanceller = $q.defer();

        if (!username) {
          return endRequest();
        }
        const config = { params: { username }, timeout: requestCanceller.promise };
        $http.get('access-control/api/v1/users', config)
          .then(userRequestComplete)
          .catch(angular.noop)
          .finally(endRequest);

      }

      function userRequestComplete(response) {
        if (response && response.data?.id) {
          vm.user = response.data;
          $cookies.put('username', vm.username);
        }
      }

      function endRequest() {
        vm.checkingUsername = false;
      }

    }

  }

})();
