(function () {
  'use strict';

  angular
    .module('blocks.i18n')
    .service('i18nSimpleService', i18nSimpleService);

  /* @ngInject */
  function i18nSimpleService(
    messagesDeService,
    messagesEnService,
    messagesEtService,
    messagesFiService,
    messagesPlService,
    messagesRuService,
    messagesSkService,
    messagesSvService,
    $cookies,
  ) {


    this.get = get;
    this.getOrThrow = getOrThrow;

    function get(stringName, stringArgs) {
      if (!stringName) {
        return '';
      }

      let message;

      // Retrieving a cookie
      const lang = $cookies.get('lang') || 'fi';

      const defaultLangService = messagesEnService;
      const langServices = {
        de: messagesDeService,
        en: messagesEnService,
        et: messagesEtService,
        fi: messagesFiService,
        pl: messagesPlService,
        ru: messagesRuService,
        sk: messagesSkService,
        sv: messagesSvService,
      };

      const key = stringName.trim();
      message = langServices[lang] && langServices[lang].messages[key] || defaultLangService.messages[key] || stringName;

      if (typeof stringArgs === 'undefined') {
        return message;
      }

      if (stringArgs instanceof Array) {
        for (let i in stringArgs) {
          message = message.replace('{' + i + '}', stringArgs[i]);
        }
      } else {
        message = message.replace('{0}', stringArgs);
      }

      return message;
    }

    function getOrThrow(stringName, stringArgs) {
      var string = get(stringName, stringArgs);
      if (string === undefined) {
        throw 'String ' + stringName + ' not found!';
      }
      return string;
    }

  }

})();
