/* eslint-disable max-len */
export default {
  'a1Certificate': 'Socialförsäkringsintyg (t.ex. A1)',
  'accessPermit.addAndProceedToOrientation': 'Lägg till och fortsätt till introduktion',
  'accessPermit.addMissingCompetence': 'Lägg till saknad kompetens',
  'accessPermit.addMissingCompetences': 'Lägg till saknade kompetenser',
  'accessPermit.addMissingScanOfCertificate': 'Lägg till saknad bild på certifikat',
  'accessPermit.addRequiredCompetencesFirst': 'Lägg först till de kompetenser som krävs.',
  'accessPermit.admittance.department.valid': 'Den avdelningsspecifika inskrivningen är giltig {0} - {1}',
  'accessPermit.admittance.site.invalid': 'Medarbetaren har inte tillträde till arbetsplatsen.',
  'accessPermit.admittance.site.valid': 'Arbetsplatsspecifikt inträde är giltigt {0} - {1}',
  'accessPermit.admittanceInfo': 'Tillträde till arbetsplatsen baseras på tillträdestillstånd och giltighet för erforderlig kompetens.',
  'accessPermit.apprix.courseInfo.addEmail': 'Lägg till en e-postadress för den som ska länka personen till Apprix.',
  'accessPermit.apprix.courseInfo.fail': 'Kopplingen misslyckades',
  'accessPermit.apprix.courseInfo.info': 'Arbetsplatsen använder Apprix utbildningsmiljö. Du kan länka medarbetaren till Apprix igen genom att klicka på knappen nedan. Medarbetaren får ett e-postmeddelande med en länk till utbildningsmiljön.',
  'accessPermit.apprix.courseInfo.relink': 'Länka igen',
  'accessPermit.apprix.courseInfo.success': 'Länkningen lyckades',
  'accessPermit.approval.allRequestedChangedDone': 'Alla begärda ändringar har gjorts',
  'accessPermit.approval.approve': 'Godkänn person',
  'accessPermit.approval.approved': 'Godkänd {0}',
  'accessPermit.approval.changesAreStillBeingMade': 'Ändringar pågår fortfarande',
  'accessPermit.approval.changesRequested': 'Ändringar begärda',
  'accessPermit.approval.changesRequestedBy': 'Ändringar begärda av: {0}',
  'accessPermit.approval.checkAccessPermitLabels': 'Kontrollera etiketterna på tillträdestillståndet.',
  'accessPermit.approval.confirm': 'Godkänn tillstånd <strong>{1}</strong> av <strong>{0}</strong>?',
  'accessPermit.approval.decision': 'Beslut',
  'accessPermit.approval.heading': 'Verifiera arbetsrätt',
  'accessPermit.approval.headingForSummary': 'Arbetsrätt',
  'accessPermit.approval.notPssible': 'Tillträdestillstånd kan inte godkännas.',
  'accessPermit.approval.missingEmail': 'Personen inte har någon e-postadress angiven.',
  'accessPermit.approval.missingPhone': 'Personen saknar telefonnummer.',
  'accessPermit.approval.missingPhoneEmail': 'Personen saknar e-postadress eller telefonnummer.',
  'accessPermit.approval.periodType.max': 'Maximal möjlig varaktighet',
  'accessPermit.approval.removeLabels': 'Ta bort etiketter',
  'accessPermit.approval.requestChanges': 'Begär ändringar',
  'accessPermit.approval.requestedChangesAlert': 'Verifiering av arbetsrätt misslyckades. En del av informationen var felaktig och/eller saknades och bör korrigeras.',
  'accessPermit.approval.requestedChangesStatus': 'Har alla begärda ändringar gjorts?',
  'accessPermit.approval.requirements.expat.residencePermitDurationMinusOne': 'Avresedagen från landet har beaktats. Dokumentets officiella datum är {0}.',
  'accessPermit.approval.requirementsLoadError': 'Kraven kunde inte läsas in.',
  'accessPermit.approval.retainLabels': 'Spara etiketter',
  'accessPermit.approval.sendChangeRequest': 'Begär ändringar',
  'accessPermit.approval.updateLabels': 'Uppdatera etiketter',
  'accessPermit.approval.validationPeriod': 'Giltighetstid för tillträdestillstånd',
  'accessPermit.approved': 'Godkänd',
  'accessPermit.approvedInfo': 'Tillträdestillståndet är godkänt och kan inte ändras.',
  'accessPermit.cannotBeRemoved': 'Tillståndet kan inte tas bort',
  'accessPermit.changesInWorkHourBalance': 'Förändring av saldo',
  'accessPermit.checkContactInfo': 'Se till att personens kontaktuppgifter är uppdaterade.',
  'accessPermit.expertise.contextInfo': 'Medarbetaren får ett sms med information om att genomföra kursen när tillträdestillståndet har skapats.',
  'accessPermit.expertise.missingExpertise': 'Medarbetaren saknar kunskap (eller har kunskap som löper ut inom en månad) som krävs på arbetsplatsen.',
  'accessPermit.expertise.missingExpertisePlural': 'Medarbetaren saknar kunskap (eller har kunskap som löper ut inom en månad) som krävs på arbetsplatsen.',
  'accessPermit.expertise.missingOptionalExpertise': 'Medarbetaren saknar en valfri kompetens. Detta blockerar inte tillträdet till platsen.',
  'accessPermit.expertise.missingOptionalExpertisePlural': 'Medarbetaren saknar valfria kompetenser. Detta blockerar inte tillträdet till platsen.',
  'accessPermit.expertise.missingRequiredExpertise': 'Brister',
  'accessPermit.expertise.missingScanOfCompetenceCertificate': 'Medarbetaren saknar fotot på certifikatet för en kompetens.',
  'accessPermit.expertise.missingScanOfCompetenceCertificatePlural': 'Medarbetaren saknar foto på certifikatet för följande kompetenser.',
  'accessPermit.expertise.newRequirement': 'Nytt krav',
  'accessPermit.expertise.performedInExternalSystem': 'Medarbetaren genomför kursen i ett externt system.',
  'accessPermit.expertise.validityBasedOnCertificateScanInfoPlural': 'Tillståndet sparas, men blir giltigt först efter att foton på certifikat bifogats.',
  'accessPermit.expertise.validityBasedOnCertificateScanInfoSingular': 'Tillståndet sparas, men blir giltigt först efter att fotot på certifikatet har bifogats.',
  'accessPermit.expertise.validityInfoPlural': 'Tillståndet sparas, men blir giltigt först efter att giltiga kompetenser har lagts till.',
  'accessPermit.expertise.validityInfoSingular': 'Tillståndet sparas, men blir giltigt först efter att en giltig kompetens har lagts till.',
  'accessPermit.confirmRemoval': 'Ta bort tillträdestillstånd <strong>{0}</strong> från person <strong>{1}</strong>?',
  'accessPermit.contactInfoChecked': 'Ja, kontaktuppgifterna är uppdaterade',
  'accessPermit.created': 'Skapad',
  'accessPermit.createdAsVisitorInfo': '<strong>Besökstillstånd.</strong> Arbetsplatsen har använt besökstillstånd tidigare när detta tillstånd skapades.',
  'accessPermit.createdWithVehicleInfo': '<strong>Fordonstillstånd.</strong> Arbetsplatsen har använt fordonstillstånd tidigare när detta tillträdestillstånd skapades. Du kan ta bort fordonstillståndet om du vill.',
  'accessPermit.createFromUnresolvedCheckIn.areYouSure': 'Är du säker på att du vill skapa ett nytt tillträdestillstånd? Incheckningen kan också kopplas till befintliga tillstånd genom att klicka på länken ”Hantera”.',
  'accessPermit.createFromUnresolvedCheckIn.personHasAlreadyPermitsAtSite': 'Personen har redan följande tillträdestillstånd på platsen',
  'accessPermit.earlierA1Certificate': 'Tidigare socialförsäkringsintyg gäller till <strong>{0}</strong>.',
  'accessPermit.earlierExpatRep': 'Tidigare representant har varit <strong>{0}</strong>.',
  'accessPermit.earlierExpatRepListStart': 'Person som tidigare har arbetat som representant',
  'accessPermit.earlierIdConfirmation': 'Tidigare använt id ({0})',
  'accessPermit.earlierSiteCard': 'Tidigare använt platskort ({0})',
  'accessPermit.employer': 'Arbetsgivare',
  'accessPermit.expatInfo': 'Med utstationerad arbetstagare avses i allmänhet en arbetstagare under följande villkor:<ul><li>Arbetsgivaren är inte finsk.</li><li>Medarbetaren arbetar normalt utanför Finland.</li><li>Medarbetaren är tillfälligt utstationerad till Finland.</li></ul><a target="_blank" href="https://www.tyosuojelu.fi/web/en/employment-relationship/posted-worker">Läs mer <span class="far fa-external-link"></span></a>',
  'accessPermit.expatInfoMessage': 'Personen har markerats som utstationerad arbetstagare eftersom hans/hennes huvudsakliga anställningsland inte är Finland. Socialförsäkringsintyg krävs från den utstationerade arbetstagaren. <a href="https://tyosuojelu.fi/tyosuhde/lahetettu-tyontekija" rel="noopener" target="_blank">Mer information <span class="far fa-external-link"></span></a> (tyosuojelu.fi). Dessutom ska personen anmälas till Regionförvaltningsverken: <a href="https://asiointipalvelu.ahtp.fi/forms/2627047" rel="noopener" target="_blank">Anmälan av utstationerade arbetstagare <span class="far fa-external-link"></span></a> (asiointipalvelu.ahtp.fi).',
  'accessPermit.expatPersonnelReport': 'Rapport för utlandsarbetare',
  'accessPermit.expatPersonnelReportDate': 'Rapporteringsdatum',
  'accessPermit.expatUncheckModal.cancel': 'Nej',
  'accessPermit.expatUncheckModal.confirm': 'Ja, ändra huvudsakligt anställningsland',
  'accessPermit.expatUncheckModal.content': '<p>Personen har markerats som utstationerad arbetstagare eftersom ett annat land än {0} har markerats som huvudsakligt anställningsland. Det huvudsakliga anställningslandet är det land där personen arbetar mer än 6 månader om året.</p><p>Är {1} personens huvudsakliga anställningsland och ska detta registreras i personens uppgifter?</p>',
  'accessPermit.expatUncheckModal.error': 'Fel: Det gick inte att uppdatera statusen för den utstationerade arbetstagaren.',
  'accessPermit.expatUncheckModal.heading': 'Huvudsakligt anställningsland',
  'accessPermit.expatUncheckModal.saving': 'Ändrar huvudsakligt anställningsland ...',
  'accessPermit.foreignEmployeeRepresentative': 'Representant i Finland för utlandsarbetare eller utländsk medarbetare',
  'accessPermit.foreignEmployeeRepresentativeMany': 'Representant i Finland för utlandsarbetare eller utländska medarbetare',
  'accessPermit.foremanNeedsContactInfo': '<strong>Person fungerar som arbetsledare</strong> och därför behövs kontaktuppgifter.',
  'accessPermit.fortyhours': '40 timmar',
  'accessPermit.hasDataCannotRemove': 'Tillståndet har rader för inchecknings- eller faktureringstimmar och kan därför inte tas bort. Uppsägning är dock möjlig.',
  'accessPermit.idCardPhotoAdded': 'Tillagd',
  'accessPermit.idCardPhotoNotAdded': 'Har inte lagts till',
  'accessPermit.labels.infoText': 'Alla kan se dessa. Endast köparen och huvudentreprenören kan göra ändringar.',
  'accessPermit.labels.noLabels': 'Inga kommentarer',
  'accessPermit.labels': 'Kommentarer',
  'accessPermit.latestCheckIn': 'Senaste incheckning',
  'accessPermit.locked': 'Låst',
  'accessPermit.maxPeriodExceeded': 'Slutdatumet har satts längre än vad kraven tillåter.',
  'accessPermit.maxPeriodFromRequirements': 'Krav uppfylls inom tiden',
  'accessPermit.maxPeriodFromRequirementsChanged': 'Krav inte uppfyllda. Krav har sannolikt ändrats efter godkännandet av tillståndet.',
  'accessPermit.maxPeriodFromRequirementsSet': 'Varaktighet sätts till maximal längd, enligt tillhörande kontrakt och eventuella krav.',
  'accessPermit.maxPeriodNotAvailable': 'Giltighetstiden kunde inte beräknas från kraven. Sannolikt på grund av att ett krav saknas.',
  'accessPermit.newCompetenceRequirement': 'Nytt kompetenskrav',
  'accessPermit.newWorkHourBalance': 'Nytt saldo',
  'accessPermit.noIdentifierNoVisitorOption2': '<strong>Personen har inga identifierare inställda och besökstillstånd är inte tillåtna på arbetsplatsen.</strong> Lägg till en identifierare, till exempel ett skattenummer, för medarbetaren. Alternativt kan huvudentreprenören tillåta besökstillstånd från platsinställningar och skapa besökstillstånd.',
  'accessPermit.noIdentifiers': 'Personen behöver ha en identifierare, t.ex. ett skattenummer, inställt för att kunna skapa ett tillträdestillstånd för medarbetare.',
  'accessPermit.noIdentifiersNoVisitorOption1': '<strong>Personen har inga identifierare inställda.</strong> Endast besökstillstånd är tillåtna utan identifierare. Du har inte behörighet att skapa besökstillstånd. Lägg till en identifierare, till exempel ett skattenummer, för medarbetaren eller be huvudentreprenören att lägga till besökstillståndet.',
  'accessPermit.noPreviousWorkHourBalanceUpdates': 'Inga tidigare saldouppdateringar',
  'accessPermit.noWorkHourBalance': 'Inget saldo',
  'accessPermit.orientationAfterCheckIn': 'Introduktion utförd efter incheckning',
  'accessPermit.orientationAfterCheckInDetails': 'Denna person checkade in den <strong>{0}</strong>, men introduktionen är markerad som skedd den <strong>{1}</strong>. Introduktionen ska vara genomförd innan arbete utförs på arbetsplatsen.',
  'accessPermit.overlapInfo': 'Du kan skapa ett nytt tillstånd, men incheckningar som skapas från en incheckningsenhet kopieras till varje tillstånd. Om medarbetaren skapar en incheckning med sin egen enhet måste han/hon välja det tillstånd som incheckningen ska göras för.',
  'accessPermit.overlapNotPossible': 'Endast ett aktivt tillstånd är tillåtet för varje arbetsgivare på varje arbetsplats.',
  'accessPermit.pending': 'Väntar',
  'accessPermit.personMissingExpertiseCertificates': 'Vissa kompetenser saknar obligatoriskt foto på certifikat.',
  'accessPermit.personMissingExpertise': 'Tillträdestillståndet blir giltigt och introduktion kan endast utföras efter att erforderlig kunskap har lagts till.',
  'accessPermit.protacon.beginCourse': 'Starta kursen i ett annat fönster',
  'accessPermit.protacon.course': 'Avsluta kursen',
  'accessPermit.protacon.courseInfo.addEmail': 'Lägg till en e-postadress för personen som ska länka personen till Type.',
  'accessPermit.protacon.courseInfo.fail': 'Kopplingen misslyckades',
  'accessPermit.protacon.courseInfo.linkSuccessful': 'Medarbetaren har kopplats till alla Protacon Type-kurser som krävs enligt tillståndet.',
  'accessPermit.protacon.courseInfo.missingCourses': 'Medarbetaren har inte kopplats till alla <strong>Protacon Type</strong>-kurser som krävs enligt tillståndet.',
  'accessPermit.protacon.courseInfo.notSuccessful': 'Är du säker på att <strong>{0}</strong> är rätt e-postadress? Om det är rätt kan du försöka länka personen igen genom att klicka på knappen nedan.',
  'accessPermit.protacon.courseInfo.relink': 'Länka igen',
  'accessPermit.protacon.linkFetchFail': 'Beställning misslyckades. Var god försök igen senare.',
  'accessPermit.protacon.linkValidUntil': 'Länken är giltig till {0}',
  'accessPermit.protacon.siteUsesProtacon': 'Arbetsplatsen använder <strong>Protacon Type</strong> onlinekurser. Medarbetaren behöver en e-postadress för att slutföra kurserna.',
  'accessPermit.reInviteForTrainings': 'Skicka utbildningsinbjudan igen',
  'accessPermit.removeAccessPermit': 'Ta bort tillträdestillstånd',
  'accessPermit.removeHeading': 'Ta bort tillträdestillstånd?',
  'accessPermit.removePreventedByCheckins': 'Tillträdestillståndet kan inte tas bort eftersom det finns incheckningar relaterade till det.',
  'accessPermit.removePreventedByInvoiceHours': 'Tillträdestillståndet kan inte tas bort eftersom det har godkända fakturatimrader. Om du ändå vill ta bort raden, kontakta Zeroni support (support@zeroni.fi)',
  'accessPermit.removePreventedHeading': 'Kan inte ta bort tillträdestillståndet',
  'accessPermit.requirements': 'Kravprofil',
  'accessPermit.requirementsFulfilledDuringPeriod': 'Krav uppfylls under perioden {0}.',
  'accessPermit.requirementsFulfilledDuringValidity': 'Krav uppfylls under hela giltighetsperioden för tillträdestillståndet.',
  'accessPermit.revoked': 'Avvisad',
  'accessPermit.roles': 'Roller på arbetsplatsen',
  'accessPermit.searchOrSelectLaborAgreement': 'Kollektivavtal',
  'accessPermit.setOrientationToFirstCheckIn': 'Ändra introduktionsdatum för att matcha den första incheckningen den {0}',
  'accessPermit.site.change': 'Ändra',
  'accessPermit.shortenedBy': 'Förkortad enligt {0}',
  'accessPermit.siteCardPhotoAdded': 'Tillagd',
  'accessPermit.siteCardPhotoNotAdded': 'Har inte lagts till',
  'accessPermit.thirtysevenandhalfhours': '37,5 timmar',
  'accessPermit.useContractTimePeriod': 'Ange att tillståndet ska vara giltigt enligt avtalet ({0})',
  'accessPermit.useTimePeriod': 'Ange att tillståndet ska vara giltigt enligt ({0})',
  'accessPermit.validationPeriodIsSetByApprover': 'Giltighetstiden för tillträdestillståndet bekräftas vid godkännande.',
  'accessPermit.vehicleNeedsContactInfo': '<strong>Fordonstillstånd</strong> kräver att personen har uppdaterade kontaktuppgifter.',
  'accessPermit.verification': 'Verifiering av arbetsrätt',
  'accessPermit.waitingForIntroduction.fsm': 'Väntar på introduktion',
  'accessPermit.waitingForIntroduction': 'Väntar på introduktion',
  'accessPermit.whyNotVisible': 'Varför inte synliga?',
  'accessPermit.whyNotVisibleHelpText': 'Du har inte behörighet att se/ändra denna information. Kontakta närmaste administratör om du behöver rättigheterna.',
  'accessPermit.withdrawApproval.confirm': 'Återkalla verifiering av arbetsrätt för tillstånd <strong>{1}</strong> av <strong>{0}</strong>? Efter denna ändring måste arbetsrätten verifieras på nytt.',
  'accessPermit.withdrawApproval.heading': 'Återkalla verifiering av arbetsrätt?',
  'accessPermit.workHourBalance.startDate': 'Startdatum',
  'accessPermit.workHourBalance.updateAuthor': 'Uppdaterat av',
  'accessPermit.workHourBalance': 'Saldo',
  'accessPermit.workHoursPerWeek': 'Arbetstimmar per vecka',
  'actions': 'Åtgärder',
  'activation.checkIns.failed': 'Inläsning av incheckningar misslyckades.',
  'activation.failed': 'Aktiveringen misslyckades.',
  'activation.retry': 'Försök igen',
  'activeLayouts': 'Aktiva planritningar',
  'addRequiredInformationBeforeSign': 'Lägg till nödvändig information innan signering',
  'admin.devicesImeiOrCompany': 'Enhetens IMEI eller företag',
  'alert.andWatchIntroductionVideo': 'och titta på den nya <a href={0} target="_blank">introduktionsvideon.</a>',
  'alert.readMore': 'Läs mer om versionshistorik',
  'alert.released': 'aktiveras.',
  'alert.siteManagerVersion': 'Zeroni-version',
  'allLayouts': 'Alla planritningar',
  'anonymousreport.anonymousReport': 'Anonym rapport',
  'anonymousreport.anonymousReports': 'Anonyma rapporter',
  'anonymousreport.anonymousReportsShort': 'Anonym',
  'anonymousreport.blackmarket': 'Svart marknad',
  'anonymousreport.change': 'Ändra',
  'anonymousreport.confirmReport': 'Bekräfta rapport',
  'anonymousreport.feedback': 'Feedback',
  'anonymousreport.geolocationFailed': 'Det gick inte att hämta din plats. Se till att du har tillåtit delning av din plats.',
  'anonymousreport.gettingLocation': 'Hämtar din plats ...',
  'anonymousreport.locateAgain': 'Hitta på nytt',
  'anonymousreport.located': 'Plats hittad',
  'anonymousreport.lookingForSites': 'Söker efter platser i närheten ...',
  'anonymousreport.noNearbySites': 'Inga platser hittades',
  'anonymousreport.refresh': 'Uppdatera',
  'anonymousreport.removeReport': 'Ta bort händelse',
  'anonymousreport.reportSent': 'Rapport skickad!',
  'anonymousreport.selectObservationType': 'Välj observationstyp',
  'anonymousreport.showMoreSites': 'Visa fler arbetsplatser',
  'anonymousreport.turnOnGeolocationInstructions': 'Hur aktiverar jag platstjänster på min enhet?',
  'app.newVersionAvailable.refreshCallToAction': 'Uppdatera nu',
  'app.newVersionAvailable.title': 'Uppdatering för Zeroni tillgänglig.',
  'assetManagement': 'Tillgångsförvaltning',
  'attachment.showAttachment': 'Visa bilaga',
  'attachment.showAttachmentNo': 'Visa bilaga {0}',
  'beamer.showFeed': 'Visa meddelanden',
  'beta.activate': 'Aktivera',
  'beta.addPersonsIntro': 'Vi har byggt om sättet att lägga till tillträdestillstånd. Prova det nya verktyget nu!',
  'beta.openBeta': 'Ändra till uppdaterat verktyg',
  'beta.returnToOld': 'Tillbaka till den gamla vyn',
  'beta.useUpdatedInterface': 'Prova det uppdaterade verktyget',
  'beta.youCanReturn': 'Du kan växla tillbaka till originalverktyget om du vill.',
  'beta': 'Beta',
  'checkIn.approve': 'Godkänn',
  'checkIn.approved': 'Godkänd',
  'checkIn.autoGeneratedTooltip': 'Incheckning genereras automatiskt',
  'checkIn.cannotResolve.info': 'Observera att vissa incheckningar inte kan kopplas till ett tillträdestillstånd eftersom tagginnehavaren inte har haft ett aktivt tillträdestillstånd under tiden för incheckningen.',
  'checkIn.checkInCannotResolve': 'Incheckning kan inte kopplas till något tillträdestillstånd.',
  'checkIn.checkInDelete': 'Vill du ta bort denna incheckning?',
  'checkIn.checkInDeleteMessage': 'Du är på väg att ta bort incheckningen <strong>{0}: <span class="far fa-sign-in"></span> {1} <span class="far fa-sign-out"></span> {2}</strong>, från <strong>{3}</strong>. Är du säker på att du vill ta bort denna incheckning?',
  'checkIn.checkInType.all': 'Alla incheckningar',
  'checkIn.checkInType.gate': 'Andra incheckningar',
  'checkIn.checkInType.workHour': 'Incheckningar av arbetstimmar',
  'checkIn.contractAndSubcontractDigest': 'Kumulativ arbetstidsfördelning för avtal och dess underavtal',
  'checkIn.contractDigest': 'Arbetstidsfördelning för kontrakt',
  'checkIn.devices.confirmRemoteOpening': 'Bekräfta fjärröppning',
  'checkIn.devices.missing': 'Saknas enheten i listan?',
  'checkIn.devices.missingHelp': 'Du kan ändra och lägga till nya enheter på sidan:',
  'checkIn.devices.opening': 'Öppnar ...',
  'checkIn.devices.remoteOpening': 'Fjärrstyrd öppning',
  'checkIn.devices.removeFromOtherSites': 'Ta bort enhet från andra arbetsplatser',
  'checkIn.employeeDigest': 'Arbetstidsfördelning för medarbetare',
  'checkIn.handle': 'Handtag',
  'checkIn.handleCheckIn': 'Hantera incheckning',
  'checkIn.handleHelpText': 'Incheckningen kommer antingen att tas bort eller anslutas till det befintliga arbetstillståndet.',
  'checkIn.hideContractDigest': 'Dölj arbetstidsfördelning för avtal',
  'checkIn.hideEmployeeDigest': 'Dölj arbetstidsfördelning för medarbetare',
  'checkIn.includeSubcontractors': 'Inkludera underentreprenörer',
  'checkIn.lunchSubtracted': 'Lunch (30 min) subtraherad',
  'checkIn.onlyIncomplete': 'Visa endast ofullständiga',
  'checkIn.printDigests': 'Skriv ut arbetstidsfördelningar',
  'checkIn.remove': 'Ta bort',
  'checkIn.removeConfirmation': 'Ta bort incheckning?',
  'checkIn.removeOrResolveHelpText': 'Man kan antingen ta bort eller bifoga en given incheckning till ett befintligt tillträdestillstånd.',
  'checkIn.resolveSeveralCheckIns.headerText': 'Taggen har flera incheckningar',
  'checkIn.resolveSeveralCheckIns.info': 'Flera ej bifogade incheckningar skapas med den angivna taggen <strong>{0}</strong>. Nedan finns alternativ för att bifoga eller ta bort de listade incheckningarna. När du har gjort dina val klickar du på ”Process”.',
  'checkIn.resolveStraglerCheckInInfoExtra': 'Medarbetaren har ett aktivt tillträdestillstånd där incheckningen kan bifogas.',
  'checkIn.searchJobNumber': 'Jobbnummer',
  'checkIn.searchResults': 'Sökresultat',
  'checkIn.selectJobNumber': 'Välj jobbnummer',
  'checkIn.showContractDigest': 'Visa arbetstidsfördelning för avtal',
  'checkIn.showDigestsAlways': 'Visa alltid fördelning',
  'checkIn.showEmployeeDigest': 'Visa arbetstidsfördelning för medarbetare',
  'checkIn.showOnlyApprovableByMe': 'Kan endast godkännas av mig',
  'checkIn.showOnlyApproved': 'Endast godkända',
  'checkIn.showOnlyFirstAndLastCheckins': 'Visa endast dagens första och sista incheckning',
  'checkIn.stragler.connected': 'Incheckning kopplad till ett tillstånd',
  'checkIn.stragler.removed': 'Incheckning borttagen',
  'checkIn.toExcel': 'Excel-rapport',
  'checkIn.toExcelDetailed': 'Excel-rapport (mer information)',
  'checkIn.tryToConnectToAccessPermit': 'Bifoga till tillträdestillstånd',
  'checkIn.unallocated': 'Ej tilldelad',
  'checkIn.warning.editAndDeleteDisabled': 'Redigering och borttagning är inaktiverad när filtret ”Visa endast dagens första incheckning och sista utcheckning” används.',
  'checkIn.warning.noPermissions': 'Ändring av incheckning är inaktiverad. Kontakta arbetsplatsens byggherre eller huvudentreprenör för mer information.',
  'checkIn.workHour': 'Incheckning av arbetstimmar',
  'checkIns.searchResults': 'Sökresultat',
  'claned.allOk': 'Medarbetaren har kopplats till alla obligatoriska Claned-kurser.',
  'claned.getSignInLink': 'Hämta inloggningslänken för medarbetaren.',
  'claned.info': 'Arbetsplatsen använder Claned utbildningssystem.',
  'claned.linkFail': 'Kopplingen misslyckades',
  'claned.missingCourses': 'Medarbetaren har ännu inte kopplats till alla obligatoriska Claned-kurser.',
  'claned.missingEmail': 'Lägg till en e-postadress för medarbetaren för att koppla honom/henne till Claned.',
  'claned.reLink': 'Länka igen',
  'company.addCertificate': 'Lägg till certifikat',
  'company.addNewCertificate': 'Lägg till nytt certifikat',
  'company.businessIdUpdateConfirmation': 'Du håller på att uppdatera företagets företags-ID. <b>Observera att företags-ID endast kan uppdateras via UI om det har angetts felaktigt när företaget skapades.</b> Om företags-ID har ändrats på grund av sammanslagning, diffusion eller någon annan orsak som inte är relaterad till användarfel måste ändringen göras direkt i databasen. Kontrollera företagsinformation före uppdatering: <a href="https://tietopalvelu.ytj.fi/yritys/{0}" target="_blank">https://tietopalvelu.ytj.fi/yritys/{0}</a>',
  'company.businessIdUpdateConfirmationShort': 'Är du säker på att du vill uppdatera företagets företags-ID?',
  'company.businessLine': '<strong>Affärsområde:</strong>',
  'company.businessLines': '<strong>Affärsområden:</strong>',
  'company.certificate.expirationDate': 'Giltig till {0}',
  'company.certificate.missing': 'Saknas',
  'company.certificate.validUntil': 'Giltig till {0}',
  'company.certificate': 'Certifikat',
  'company.certificateAdded': 'Certifikat <strong>{0}</strong> tillagt.',
  'company.certificates': 'Certifikat',
  'company.certificateUpdated': 'Certifikat <strong>{0}</strong> uppdaterat.',
  'company.changesForAYear': 'Förändringar för de senaste 12 månaderna.',
  'company.companyChange': 'Företags-ID har ändrats {0}.',
  'company.companyContinuesAsMany': 'Företaget fortsätter som {0} (företags-ID {1}) bland andra företag. Du hittar mer information från exempelvis <a target="_blank" rel="noopener" href="https://www.YTJ.fi/">YTJ databas</a>.',
  'company.companyContinuesAsOne': 'Företaget fortsätter som {0} (företags-ID {1}). Du hittar mer information från exempelvis <a target="_blank" rel="noopener" href="https://www.YTJ.fi/">YTJ databas</a>.',
  'company.companyHasAcquired': '<strong>Företaget har förvärvat följande företag:</strong>',
  'company.companyHasSplit': '<strong>Företaget har delats upp i följande företag:</strong>',
  'company.companyHomeCountry': 'Företagets hemland',
  'company.companyInLiquidation': 'Företaget har varit {0} sedan {1}.',
  'company.companyInLiquidationWithName': 'Företaget {0} har varit {1} sedan {2}.',
  'company.companyMergedTo_': '<strong>Företaget har slagits samman till företaget: </strong>',
  'company.companyMergedToNoName': 'Företaget har använt ett nytt företags-ID {0} (namnet är inte tillgängligt) sedan {1}.',
  'company.companyMergedToWithName': 'Företaget har slagits samman till {0} ({1}) {2}.',
  'company.contractlessIntro': '<b>Detta företag kommer att läggas till utan avtal</b>. Lägg till relevant information så att företaget kan användas när du söker efter en leverantör för material eller arbete.',
  'company.discontinued': 'Företaget har avslutats {0}.',
  'company.error.evaluationRemoved': 'Fel vid borttagning av leverantörsutvärdering',
  'company.error.evaluationSaving': 'Fel vid lagring av leverantörsutvärdering.',
  'company.evaluate': 'Utvärdering',
  'company.evaluationRemoved': 'Leverantörsutvärdering borttagen',
  'company.evaluationSaved': 'Leverantörsutvärdering sparad',
  'company.fixingIncorrectBusinessId': 'Jag åtgärdar fel företags-ID',
  'company.generalInfo': 'Allmän information',
  'company.hasPaidWorkForce': 'Företaget har betald arbetskraft',
  'company.jobTitleExamples': 'Ex. schaktning, el, rivning',
  'company.managesItself': '<strong>{0}</strong> hanterar sina egna data. Om ändringar behövs kan du skicka dem till företagets kontaktperson (se nedan).',
  'company.materialExamples': 'Ex. virke, metallprodukter, stengods',
  'company.newCertificate': 'Nytt certifikat',
  'company.noCertificates': 'Inga certifikat',
  'company.onlyWithActiveContracts': 'Aktiva avtal',
  'company.paidWorkForce': 'Betald arbetskraft',
  'company.prhNameSuggestion': 'Använd namn <strong>{0}</strong> (Namnet som motsvarar företags-ID sedan {1} enligt PRH.)',
  'company.prhSource': 'Källa: <a target="_blank" rel="noopener" href="http://avoindata.PRH.fi/index_en.html">Finnish Patent and Registration Office (PRH).</a> Innehållet har ändrats. Licens <a target="_blank" rel="noopener" href="https://creativecommons.org/licenses/BY/4,0/">CC BY 4,0.</a>',
  'company.reliablePartner.foreignCompanyInfo': 'Vastuu Group erbjuder inte en automatisk kontroll av Luotettava Kumppani -status för utländska företag. Du kan kontrollera informationen manuellt via <a target="_blank" rel="noopener" href="https://www.vastuugroup.fi/">Vastuu Groups</a> företagsökningstjänst.',
  'company.removedFromTradeRegister': 'Företaget {0} har tagits bort från handelsregistret {1}.',
  'company.search.evaluation': 'Utvärdering',
  'company.search.jobTitle': 'Jobbtitel',
  'company.search.jobTitles': 'Jobbtitlar',
  'company.searchByBusinessIdOrName': 'Skriv antingen det finska företags-ID:t eller början på företagets namn.',
  'company.searchCertificates': 'Sök certifikat',
  'company.searchOrSelectCompanies': 'Företag',
  'company.searchOrSelectCompany': 'Företag',
  'company.summary.activeContracts': 'Aktiva avtal',
  'company.summary.endedContracts': 'Uppsagda avtal',
  'company.updateCertificate': 'Uppdatera certifikat',
  'company.whyBusinessIdIsLocked': 'Företags-ID är låst. Om det har ändrats, kontakta supporten (support@zeroni.fi).',
  'company.whyCantIEdit': 'Varför kan jag inte redigera detta?',
  'company.withoutContract': 'Utan ett avtal',
  'companyEditor.fusionInfo': 'Företaget har gått igenom en sammanslagning <strong>{0}</strong> och är för närvarande en del av företag {1} ({2}).',
  'companyEditor.hasNoAddress': 'Företaget saknar en adress',
  'companyEditor.select': 'Välj',
  'companyEditor.skip': 'Nej, tack',
  'companyEditor.suggestAddressMessage': 'Vill du välja något av följande alternativ?',
  'companyPersons.showTags': 'Visa taggar',
  'competence.cardNumber': 'Kortnummer',
  'competence.code': 'Kod',
  'competence.confirmRemoval': 'Ta bort kompetens <strong>{0}</strong> från person <strong>{1}</strong>?',
  'competence.correctInformation': 'Nej, jag korrigerar gammal information',
  'competence.createNew': 'Ja, jag lägger till ett nytt kort',
  'competence.duration': 'Varaktighet',
  'competence.durationPlaceHolder': 'Sista giltighetsdatum',
  'competence.expired': 'Förfallen',
  'competence.months': 'månader',
  'competence.monthsShort': 'mån',
  'competence.ongoing': 'Pågående',
  'expertise.pendingTraining': 'Utbildning som ska slutföras',
  'expertise.pendingTrainings': 'Utbildningar som ska slutföras',
  'expertise.removeExpertise': 'Ta bort kunskap',
  'expertise.removeExpertiseFailed': 'Fel vid borttagning av kunskap.',
  'expertise.removeHeading': 'Ta bort kunskap?',
  'expertise.testFailed': 'Testet misslyckades',
  'competence.updateOrCreateNew': 'Du har ändrat kompetensens nyckelinformation. Här görs ändringarna för den aktuella kompetensen, men om du har ett nytt kompetenskort kommer det att läggas till för en ny kompetens. Har du fått ett nytt kort eller annat certifikat för kompetensen?',
  'competence.validUntil': 'Giltigt till',
  'competences': 'Kompetensområden',
  'confirmRemoveRightsHeader': 'Bekräfta borttagning av åtkomstbehörighet',
  'confirmRemoveSiteRights': 'Varning! Du är på väg att ta bort åtkomstbehörigheten för detta avtal från dig själv. Utan åtkomstbehörighet kanske du inte kan komma åt denna arbetsplats.',
  'connectionError': 'Anslutningsfel',
  'contactInfo.addressIsUsedMultipleTimesWarning': 'Den här adressen används även för {0} andra personer. När adressen är verifierad kan de andra inte använda den.',
  'contactInfo.confirmEmail': 'Adressen är korrekt',
  'contactInfo.confirmInfo': 'Bekräfta kontaktuppgifter',
  'contactInfo.confirmPhone': 'Numret är korrekt',
  'contactInfo.editEmail': 'Redigera e-post',
  'contactInfo.editorModal': 'Dialogrutan Redigerare av kontaktuppgifter',
  'contactInfo.editPhone': 'Redigera telefonnummer',
  'contactInfo.emailVerified': 'Bekräftad e-postadress',
  'contactInfo.enterCodeFromYourEmail': 'Ange koden som skickades till din e-postadress',
  'contactInfo.enterCodeFromYourPhone': 'Ange koden som skickades till dig som sms',
  'contactInfo.helloSuperuser': 'Hej superanvändare!',
  'contactInfo.isAddressCorrect': 'Är det här din adress?',
  'contactInfo.isAddressStillCorrect': 'Är det fortfarande din adress?',
  'contactInfo.isNumberCorrect': 'Är detta ditt nummer?',
  'contactInfo.isNumberStillCorrect': 'Är det fortfarande ditt nummer?',
  'contactInfo.iWillTakeCareOfThisLater': 'Jag tar hand om det senare',
  'contactInfo.notVerified': 'Ej verifierad',
  'contactInfo.numberIsUsedMultipleTimesWarning': 'Detta nummer används även för {0} andra personer. När numret har verifierats kan de andra inte använda det.',
  'contactInfo.phoneVerified': 'Telefon verifierad',
  'contactInfo.superUserShouldKnowAboutEditingVerifiedData': 'Om du redigerar verifierade kontaktuppgifter tas verifieringen bort och användaren måste verifiera de nya uppgifterna.',
  'contactInfo.superUserShouldKnowAboutLoggingIn': 'Kontaktuppgifter används för att logga in och att ange felaktiga kontaktuppgifter kan förhindra användaren från att logga in.',
  'contactInfo.verificationModal': 'Dialogruta för verifiering av kontaktuppgifter',
  'contactInfo.verified': 'Verifierat',
  'contactInfo.verifyEmail': 'Bekräfta e-postadress',
  'contactInfo.verifyPhone': 'Bekräfta telefonnummer',
  'contactInfo.wrongCodeTryAgain': 'Felaktig kod. Försök igen.',
  'contactInfoStatusBadge.verified': 'Verifierat',
  'continueToPersons': 'Fortsätt till fliken personer',
  'contract.accessAreas.specialAreasInfo': 'Detta avtal innehåller tillträdesområden som inte ärvs från det överordnade avtalet. Områden kan endast ändras av arbetsplatsens ägare.',
  'contract.activeMainContractor': 'Aktiv huvudentreprenör',
  'contract.addChosenPerson': 'Lägg till tillträdestillstånd för vald person',
  'contract.addChosenPersons': 'Lägg till tillträdestillstånd för valda {0} personer',
  'contract.addHotWorkPermit': 'Lägg till tillstånd för heta arbeten',
  'contract.addJobTitles': 'Lägg till omfattning',
  'contract.addNthContact': 'Lägg till {0}. kontaktperson',
  'contract.addPermitAndCheckIns': 'Lägg till tillträdestillstånd och incheckningar relaterade till detta avtal.',
  'contract.addPersons.aboutToLeave': 'Du är på väg att lämna och avbryta tillägget av personer.',
  'contract.addPersons.add.employees': 'Medarbetare',
  'contract.addPersons.add.visitors': 'Besökare',
  'contract.addPersons.add': 'Lägg till',
  'contract.addPersons.additionalDetails': 'Övrig information',
  'contract.addPersons.andNOthers': 'och <strong>{0}</strong> andra.',
  'contract.addPersons.approvalInfo': '<strong>Arbetsplatsen kräver ett separat tillståndsgodkännande.</strong> Ett e-postmeddelande skickas till kontraktets kontaktperson efter godkännandet.',
  'contract.addPersons.areYouSureYouWantToDeselectPerson': 'Är du säker på att du vill avmarkera <b>{0}</b>?',
  'contract.addPersons.areYouSureYouWantToLeave': 'Är du säker på att du vill avsluta?',
  'contract.addPersons.checkAndSubmit.abbr': 'Skicka',
  'contract.addPersons.checkAndSubmit': 'Granska och skicka',
  'contract.addPersons.deselectPerson': 'Avmarkera person',
  'contract.addPersons.deselectPersonQuestion': 'Avmarkera person?',
  'contract.addPersons.differentEmployerOnValttiTag': 'Annan arbetsgivare på Valtti-tagg.',
  'contract.addPersons.differentEmployerOnValttiTagAlert': 'Du är på väg att lägga till en medarbetare för <strong>{0}</strong>, men personens Valtti-tagg är tilldelad <strong>{1}</strong>. Har du valt rätt kontrakt och medarbetare?',
  'contract.addPersons.differentEmployerOnValttiTagAlertSameName': 'Du är på väg att lägga till en medarbetare för <strong>{0}</strong> (företags-ID {1}), men denna persons Valtti-tagg är tilldelad <strong>{2}</strong> (företags-ID {3}). Har du valt rätt kontrakt och medarbetare?',
  'contract.addPersons.employment': 'Anställningsform',
  'contract.addPersons.existingOrientation': 'Personen har fått introduktion för denna arbetsplats den {0}.',
  'contract.addPersons.foreignerDetails': 'Utländska uppgifter',
  'contract.addPersons.foreignerRep.abbr': 'Repr.',
  'contract.addPersons.foreignerRepShort': 'Representant',
  'contract.addPersons.foremanIntro': '<p>Ingen arbetsledare vald för kontraktet <b>{0}</b>. Arbetsledaren är avgörande för att säkerställa säkerhet och effektivitet i arbetet.</p><p>Typiskt ansvar för en arbetsledare:</p><ul><li>Ta emot och distribuera information relaterad till driften och säkerheten på byggarbetsplatsen.</li><li>Att agera som ansvarig person för att korrigera säkerhetsavvikelser.</li></ul>',
  'contract.addPersons.foremanNotAmongSelected': 'Arbetsledaren finns inte bland de valda personerna.',
  'contract.addPersons.foremanSelectionUpdatesAllContracts': 'Valet av arbetsledare kommer att uppdateras för alla valda avtal.',
  'contract.addPersons.ids': 'Legitimation',
  'contract.addPersons.invalid': 'Ogiltiga data',
  'contract.addPersons.leaveQuestion': 'Avsluta nu?',
  'contract.addPersons.leavingDiscardsAllChanges': 'Om du avslutar innan du skickar data förlorar du alla val och inmatningar som du har gjort.',
  'contract.addPersons.manyPersonsWithMissingCompetences': '<strong>{0} personer</strong> har inte alla nödvändiga kompetenser. Tillträdestillstånd sparas för varje person, men blir giltigt när personen har erforderlig kompetens.',
  'contract.addPersons.modal.title': 'Personer kan inte läggas till i avtalet',
  'contract.addPersons.multiplePersons': 'Flera personer',
  'contract.addPersons.nextPerson': 'Nästa person',
  'contract.addPersons.nextStep': 'Nästa steg',
  'contract.addPersons.noSelections': 'Inga personer valda.',
  'contract.addPersons.onePerson': '1 person',
  'contract.addPersons.orientationCopyNotPossible': 'Introduktion kan inte kopieras eftersom kompetenserna inte är uppdaterade.',
  'contract.addPersons.orientationStillValid': 'Introduktionen är fortfarande giltig',
  'contract.addPersons.overlappingAccessPermits': 'Överlappande tillträdestillstånd',
  'contract.addPersons.prohibited': 'Person kan inte läggas till',
  'contract.addPersons.relationAndValidationPeriod': 'Relation och valideringsperiod',
  'contract.addPersons.requiresNewOrientation': 'Nytt krävs',
  'contract.addPersons.resultPerson': '<b>1</b> person vald',
  'contract.addPersons.resultPersons': '<b>{0}</b> personer valda',
  'contract.addPersons.selectAtOnce': 'Välj nu',
  'contract.addPersons.selectedPersons': 'Valda personer',
  'contract.addPersons.selectFirstPersons': 'Välj första personer',
  'contract.addPersons.selectMorePersons': 'Välj fler personer',
  'contract.addPersons.selectNPersons': 'Välj {0} personer',
  'contract.addPersons.selectNPersonsShort': 'Välj {0}',
  'contract.addPersons.selectPerson': 'Välj en person',
  'contract.addPersons.selectPersons': 'Välj personer',
  'contract.addPersons.selectPersonShort': 'Välj',
  'contract.addPersons.sharedDetails.abbr': 'Gem.',
  'contract.addPersons.sharedDetails.short': 'Gemensam för alla',
  'contract.addPersons.sharedDetails': 'Gemensamt för alla valda personer',
  'contract.addPersons.showOrientationForm': 'Visa introduktionsformulär (PDF)',
  'contract.addPersons.singlePersonWithMissingCompetences': '<strong>{0}</strong> har inte alla nödvändiga kompetenser. Tillträdestillstånd sparas men blir giltigt när personen har erforderlig kompetens.',
  'contract.addPersons.theseAccessPermitsMayAffectApproving': 'Dessa tillträdestillstånd kan påverka godkännandet av detta tillträdestillstånd.',
  'contract.addPersons.tooltip.notAllowedToAddPersons.and': ' och ',
  'contract.addPersons.tooltip.notAllowedToAddPersons.bSideInvalid': 'företagets grundinformation har fyllts i',
  'contract.addPersons.tooltip.notAllowedToAddPersons.liabilityDocs': 'handlingarna för entreprenörens åtaganden är i ordning',
  'contract.addPersons.tooltip.notAllowedToAddPersons': 'Du kan lägga till medarbetare i avtalet först efter ',
  'contract.addPersons.useUniqueDetails': 'Ställ in separat',
  'contract.addPersons.valid': 'Giltig',
  'contract.addPersons.valttiEmployerAlertDismiss': 'Ja, fortsätt att lägga till medarbetaren',
  'contract.addPersons.valttiEmployerAlertRemove': 'Nej, ta bort personen',
  'contract.addPersons.yesLeave': 'Ja, lämna',
  'contract.addPersons': 'Lägg till personer',
  'contract.addPersonSearchPlaceholder': 'Sök person (efter namn, skattenummer eller Valtti)',
  'contract.addPersonsHelp.orAddNew': 'lägg till en ny person',
  'contract.addPersonsHelp': '<strong>Välj en eller flera personer.</strong> Välj bland befintliga personer nedan eller',
  'contract.addPersonsStrong': 'Lägg till <strong>medarbetare</strong>',
  'contract.addPersonsToContract': 'Lägg till personer i avtalet',
  'contract.addSubContract': 'Lägg till underleverantörsavtal',
  'contract.alreadyExists': 'Avtalet finns redan',
  'contract.alreadyExistsAddedBySomebody': 'Avtal för entreprenör <strong>{0}</strong> har redan lagts till av någon.',
  'contract.approver': 'Godkännare',
  'contract.askAboutPermitEndDateChange': 'Vill du även ändra slutdatum för tillträdestillstånd för detta avtal och dess underavtal? Detta alternativ uppdaterar alla tillträdestillstånd <strong> vars slutdatum är {0}. Det nya slutdatumet är {1}.',
  'contract.askAboutPermitStartDateChange': 'Vill du också ändra startdatum för tillträdestillstånd för detta avtal och dess underavtal? Detta alternativ uppdaterar alla tillträdestillstånd <strong> vars startdatum är {0} och som inte är introducerade. Det nya startdatumet är {1}.',
  'contract.askAboutSubcontractEndDateChange': 'Vill du ändra slutdatum för detta avtals underavtal? Detta alternativ uppdaterar alla avtal <strong> vars slutdatum är {0}</strong>. Det nya slutdatumet är {1}.',
  'contract.askAboutSubcontractStartDateChange': 'Vill du ändra startdatum för detta avtals underavtal? Detta alternativ uppdaterar alla avtal <strong> vars startdatum är {0}</strong>. Det nya startdatumet är {1}.',
  'contract.automaticPeriodChange': 'Startdatum och slutdatum för tillträdestillstånd och underavtal ändras automatiskt så att de ligger inom perioden {0}–{1}.',
  'contract.automaticPeriodChangeContractsOnly': 'Startdatum och slutdatum för underavtal ändras automatiskt så att de ligger inom perioden {0}–{1}.',
  'contract.bSideInvalid.addingEmployeesPrevented': 'Du kan inte lägga till medarbetare i avtalet förrän informationen har fyllts i.',
  'contract.bSideInvalid': 'Företagsinformationen saknas.',
  'contract.buyer': 'Köpare',
  'contract.cantMoveHeading': 'Avtalet kan inte flyttas',
  'contract.cantMoveInfo': 'Det finns godkända fakturerade timmar på avtalet. Därför kan	 avtalet flyttas. Om du fortfarande behöver flytta avtalet, kontakta Zeroni support (support@zeroni.fi).',
  'contract.cantRemoveHeading': 'Avtalet kan inte tas bort',
  'contract.cantRemoveInfo': 'Det finns godkända fakturerade timmar på avtalet. Därför kan	 avtalet tas bort. Om du fortfarande behöver ta bort avtalet, kontakta Zeroni support (support@zeroni.fi).',
  'contract.chain': 'Kedja',
  'contract.chooseFromListButEmpty': 'Jobbtitlar måste väljas från listan, men listan över jobbtitlar är tom.',
  'contract.chooseNewParent': 'Välj det avtal under vilket avtalet <strong>{0}</strong> ska flyttas:',
  'contract.companyPendingCertificates': 'Behöver certifikat',
  'contract.expertiseRequirement.all': 'Alla kompetenser och kurser',
  'contract.expertiseRequirement.custom': 'Endast kurser',
  'contract.expertiseRequirement.none': 'Inga krav',
  'contract.confirmSafetyLevel': 'Bekräfta säkerhetsnivån',
  'contract.contacts': 'Kontakter',
  'contract.continuing': 'Fortsätter ...',
  'contract.contractAll': 'Sammanfoga alla',
  'contract.contractContacts': 'Entreprenörskontakter',
  'contract.contractEditorNotifications': 'Meddelanden',
  'contract.contractHasNoForemanInfo0': 'Avtalet har ingen primär arbetsledare.',
  'contract.contractHasNoForemanInfo1': 'Du kan välja arbetsledare på personsidan eller ',
  'contract.contractHasNoForemanInfo2': 'du kan välja att inte använda arbetsledare på kontraktet.',
  'contract.contractHasNoForemanInfoFull.noContractModifyPermission': 'Avtalet har ingen primär arbetsledare. Du kan välja arbetsledare på personsidan.',
  'contract.contractHasNoForemanInfoFull': 'Avtalet har ingen primär arbetsledare. Du kan välja arbetsledare på personsidan eller välja att inte använda arbetsledare på avtalet.',
  'contract.contractMissing': 'Saknas avtal?',
  'contract.contractValidityPeriod': 'Avtalsperiod',
  'contract.developerAccessWarning': 'Byggherrens kontaktpersoner får administratörsrättigheter och kan redigera arbetsplatsen på samma sätt som huvudentreprenören.',
  'contract.document.location.elsewhere': 'Sparas i separat system',
  'contract.documents.accidentInsuranceLegend.EE': 'A1-/E101-dokument utfärdat av <em>Sosiaalkindlustusamet</em>s kontor eller bevis på olycksfallsförsäkring utfärdat av ett finskt försäkringsbolag som har försäkrade kontraktspartners anställda',
  'contract.documents.accidentInsuranceLegend.generic': 'Intyg på olycksfallsförsäkring utfärdat av ett finskt försäkringsbolag som har försäkrade kontraktspartners anställda.',
  'contract.documents.accidentInsuranceLegend.LT': 'A1-/E101-dokument utfärdat av <em>Valstybinio socialinio draudimo fondo valdybos</em>s kontor eller bevis på olycksfallsförsäkring utfärdat av ett finskt försäkringsbolag som har försäkrade kontraktspartners anställda',
  'contract.documents.accidentInsuranceLegend.LV': 'A1-/E101-dokument utfärdat av sociālās apdrošināšanas aģentūra <em>Valsts (VSAA)</em>s kontor eller bevis på olycksfallsförsäkring utfärdat av ett finskt försäkringsbolag som har försäkrade kontraktspartners anställda',
  'contract.documents.accidentInsuranceLegend.NO': 'A1-/E101-dokument utfärdat av <em>Norges arbeids- og velferdsetaten (NAV)</em>s kontor eller bevis på olycksfallsförsäkring utfärdat av ett finskt försäkringsbolag som har försäkrade kontraktspartners anställda',
  'contract.documents.accidentInsuranceLegend.PL': 'A1-/E101-dokument utfärdat av <em>Zakładu Ubezpieczeń Społecznych (ZUS)</em>s kontor eller bevis på olycksfallsförsäkring utfärdat av ett finskt försäkringsbolag som har försäkrade kontraktspartners anställda',
  'contract.documents.accidentInsuranceLegend.SE': 'A1-/E101-dokument utfärdat av <em>Pensionsmyndigheten</em>s kontor eller bevis på olycksfallsförsäkring utfärdat av ett finskt försäkringsbolag som har försäkrade kontraktspartners anställda',
  'contract.documents.approvedDocuments': 'Senast godkända handlingar',
  'contract.documents.archivedDocuments': 'Arkiverade handlingar',
  'contract.documents.areContractorLiabilityDocumentsRequired': 'Är avtalspartnern undantagen från att leverera entreprenörens ansvarshandlingar?',
  'contract.documents.confirmExemption': 'Bekräfta undantag',
  'contract.documents.confirmExemptionInfo': 'Bekräfta undantaget enligt vad som anges i Luotettava Kumppani-rapporten ovan.',
  'contract.documents.decider': 'Handlingar granskade av',
  'contract.documents.documentsExpirationDate': 'Sista giltighetsdatum',
  'contract.documents.employerRegisterLegend.NO': 'Registreringshandling utfärdad av <em>Skatteetaten</em>s kontor',
  'contract.documents.employerRegisterLegend.SE': 'Ett registreringshandlingar från <em>Skatteverket</em> för att vara en del av arbetsgivarregistret',
  'contract.documents.exemptPending': 'Byggherren eller huvudentreprenören måste bekräfta undantaget.',
  'contract.documents.expirationInPast': 'Observera att utgångsdatumet ligger i det förflutna.',
  'contract.documents.expirationToday': 'Observera att utgångsdatumet är idag.',
  'contract.documents.genericHelp': 'Läs mer på Arbetsmiljöverkets hemsida',
  'contract.documents.hideHelp': 'Dölj nödvändiga handlingar',
  'contract.documents.inExternalSystem': 'Handlingarna har sparats i ett externt system.',
  'contract.documents.info': 'Godkända handlingar flyttas till avsnitt Senast godkända handlingar. Om nya handlingar godkänns för att ersätta gamla utgångna handlingar flyttas de gamla handlingarna till avsnittet Arkiverade handlingar och nya handlingar till avsnittet Senast godkända handlingar. Godkända handlingar kan inte tas bort.',
  'contract.documents.intro.EE': '<strong>Ditt företags hemland är Estland</strong> Följande handlingar krävs från estniska företag.',
  'contract.documents.intro.FI': '<strong>Ditt företags hemland är Finland</strong> Följande handlingar krävs från finska företag.',
  'contract.documents.intro.LT': '<strong>Ditt företags hemland är Litauen</strong> Följande handlingar krävs från litauiska företag.',
  'contract.documents.intro.LV': '<strong>Ditt företags hemland är Lettland</strong> Följande handlingar krävs från lettiska företag.',
  'contract.documents.intro.NO': '<strong>Ditt företags hemland är Norge</strong> Följande handlingar krävs av norska företag.',
  'contract.documents.intro.PL': '<strong>Ditt företags hemland är Polen</strong> Följande handlingar krävs från polska företag.',
  'contract.documents.intro.RU': '<strong>Ditt företags hemland är Ryssland</strong> Följande handlingar krävs från ryska företag.',
  'contract.documents.intro.SE': '<strong>Ditt företags hemland är Sverige</strong> Följande handlingar krävs från svenska företag.',
  'contract.documents.intro.UDEFINED': '<strong>Företagets hemland har inte angetts</strong> Följande handlingar krävs från <em>finska</em>.',
  'contract.documents.intro.UNDEFINED': '<strong>Entreprenören har ingen adress.</strong> För <em>en finsk</em> entreprenör krävs följande handlingar.',
  'contract.documents.location': 'Plats för handlingar',
  'contract.documents.noEmployerRegister': 'Krävs inte (inget arbetsgivarregister)',
  'contract.documents.noFiles': 'Inga handlingar hittades',
  'contract.documents.pending': 'Handlingarna väntar på godkännande.',
  'contract.documents.pensionInsuranceLegend.EE': 'A1-/E101-dokument utfärdat av <em>Sosiaalvakuutustusamet</em> eller ett dokument utfärdat av det finska tjänstepensionsverket som har försäkrade avtalsparters anställda',
  'contract.documents.pensionInsuranceLegend.LT': 'Anmälningshandling utfärdad av <em>Valstybinio socialinio draudimo fondo valdybos</em> kontor eller handling utfärdad av finska tjänstepensionsmyndigheten som har försäkrade kontraktspartners anställda',
  'contract.documents.pensionInsuranceLegend.LV': 'A1-/E101-dokument utfärdat av sociālās apdrošināšanas aģentūra <em>Valsts (VSAA)</em>s kontor eller ett dokument utfärdat av det finska tjänstepensionskontoret som har försäkrade kontraktspartners anställda.',
  'contract.documents.pensionInsuranceLegend.NO': 'A1-/E101-dokument utfärdat av <em>Norges arbeids- og velferdsetaten (NAV)</em> eller ett dokument utfärdat av det finska tjänstepensionskontoret som har försäkrade kontraktspartners anställda',
  'contract.documents.pensionInsuranceLegend.PL': 'A1-/E101-dokument utfärdat av kontoret <em>Zakładu Ubezpieczeń Społecznych (ZUS)</em> eller ett dokument utfärdat av det finska tjänstepensionskontoret som har anställda hos försäkrade avtalsparter.',
  'contract.documents.pensionInsuranceLegend.RU': 'TYÖKOMENNUS KESTÄÄ ENINTÄÄN 2 VUOTTA:<br>enintään 2 vuotta kestävän <br>työkomennuksen osalta <br>todistus Venäjän <br>työeläkejärjestelmän <br>mukaisesta vakuutuksesta ja <br>eläkevakuutusmaksujen <br>maksamisesta. <br>TYÖKOMENNUS KESTÄÄ <br>YLI 2 VUOTTA:<br>2 vuotta ylittävän <br>komennuksen ajalta selvitys <br>Eläketurvakeskuksen <br>päätöksestä, jolla on myönnetty <br>vapautus työntekijän eläkelaimukaisesta <br>vakuuttamisvelvollisuudesta ja <br>todistus Venäjän <br>työeläkejärjestelmän <br>mukaisesta vakuutuksesta ja <br>eläkevakuutusmaksujen <br>maksamisesta <br><u>tai <br>sopimuskumppanin työntekijät <br>vakuuttaneen suomalaisen <br>työeläkelaitoksen antama <br>todistus</u>.<br>(Huom. Jos työntekijä <br>työkomennuksen alkaessa <br>kuuluu Suomen sosiaaliturvan <br>piiriin taikka häneen <br>sovelletaan EU:n sosiaaliturvan <br>perusasetusta, tulee esittää <br>suomalaisen työeläkelaitoksen <br>antama todistus taikka A1-/E101-todistus.)',
  'contract.documents.pensionInsuranceLegend.SE': 'A1-/E101-dokument utfärdat av <em>Pensionsmyndigheten</em> eller ett dokument utfärdat av den finska tjänstepensionsmyndigheten som har försäkrade kontraktspartners anställda.',
  'contract.documents.prepaymentLegend.EE': 'Registreringshandling utfärdad av <em>Maksu- och Tullverket</em> om att vara förtecknad i register över betalningsskyldiga',
  'contract.documents.prepaymentLegend.LT': 'Registreringshandling utfärdad av <em>Valstybinė mokesčių inspekcija prie Lietuvos Respublikos finans⌈ ministerijos (VMI)</em>s kontor',
  'contract.documents.prepaymentLegend.LV': 'Registreringshandling utfärdad av <em>Valsts ieņēmumu dienests (VID)</em>s kontor',
  'contract.documents.prepaymentLegend.NO': 'Registreringshandling utfärdad av <em>Skatteetaten</em>s kontor',
  'contract.documents.prepaymentLegend.PL': 'Registreringshandling utfärdad av <em>Urzad Skarbowy (USA)</em>s kontor (PIN-kod)',
  'contract.documents.prepaymentLegend.RU': 'Registreringshandling utfärdad av <em>Федеральная налоговая служба (övers. Federalnaja nalogovaja sluzhba)</em> kontor om att bli förtecknad på skattekontoret',
  'contract.documents.prepaymentLegend.SE': 'Registreringshandling utfärdad av <em>Skatteverket</em>s kontor',
  'contract.documents.privateDocs': 'Privata handlingar',
  'contract.documents.privateDocsInfo': 'Privata handlingar är endast synliga för användare av företaget {0} vars användarroll inkluderar behörighet att se privata handlingar.',
  'contract.documents.privateDocsInfoWithLink': 'Privata handlingar är endast synliga för användare i företaget {0} vars <a href="#!/manage/usergroups" target="_blank">användarroll</a> inkluderar behörighet att se privata handlingar.',
  'contract.documents.publicDocsInfo': 'Handlingarna är tillgängliga för entreprenören och för alla företag som ligger ovanför entreprenören i kontraktsträdet.',
  'contract.documents.removeSelection': 'Avmarkera allt',
  'contract.documents.requiredDocuments': 'Nödvändiga handlingar',
  'contract.documents.showHelp': 'Visa nödvändiga handlingar',
  'contract.documents.taxPaymentsLegend.EE': 'Registreringshandling utfärdad av <em>Maksu- och Tullverket</em>',
  'contract.documents.taxPaymentsLegend.LT': 'Registreringshandling utfärdad av <em>Valstybinė mokesčių inspekcija prie Lietuvos Respublikos finans⌈ ministerijos (VMI)</em>s kontor',
  'contract.documents.taxPaymentsLegend.LV': 'Registreringshandling utfärdad av <em>Valsts ieņēmumu dienests (VID)</em>s kontor',
  'contract.documents.taxPaymentsLegend.NO': 'Registreringshandling utfärdad av <em>Skatteetaten</em>s kontor',
  'contract.documents.taxPaymentsLegend.PL': 'Registreringshandling från <em>Urzad Skarbowy (USA)</em> (formulär ZAS-W)',
  'contract.documents.taxPaymentsLegend.RU': 'Registreringshandling utfärdad av <em>Федеральная налоговая служба (övers. Federalnaja nalogovaja sluzhba)</em> eller av <em>Межрайонная ИФНС России (övers. Mezhraionnaja IFNS Rossii)</em>s kontor',
  'contract.documents.taxPaymentsLegend.SE': 'Registreringshandling utfärdad av <em>Skatteverket</em>s kontor',
  'contract.documents.tesLegend.generic': 'Förklaring av avtalspart om kollektivavtal eller andra arbetsavtal',
  'contract.documents.tradeRegisterLegend.EE': 'En registreringshandling utfärdad av <em>Registrite och Infojärjestelmien Keskus (RIK)</em> för upptagande i företagsregistret',
  'contract.documents.tradeRegisterLegend.LT': 'Registreringshandling utfärdad av <em>Juridini⌉ asmenu registras </em>s kontor för förteckning i <em>registravimo pazymėjimas</em>',
  'contract.documents.tradeRegisterLegend.LV': 'En registreringshandling utfärdad av <em>Latvijas Republikas uzņēmumu registerkontor</em>för förteckning i företagsregistret',
  'contract.documents.tradeRegisterLegend.NO': 'En registreringshandling som lämnats av <em>Enhetsregisteret</em> för förteckning i företagsregistret',
  'contract.documents.tradeRegisterLegend.PL': 'Polskägda företag: Registreringshandling utfärdat avGłówny <em> Urz⌈d Statystyczny (GUS)</em> kontor, som innehåller REGON-kod. (Samma registreringshandling gäller för företag som ägs av polska eller utländska företag.) Företag ägda av utlänningar: Registreringshandling tillhandahållet av <em>Krajowy Rejestr Sadowy (KRS)</em>s kontor.',
  'contract.documents.tradeRegisterLegend.RU': 'En registreringshandling utfärdad avФедеральная налоговая служба <em> (övers. Federalnaja nalogovaja sluzhba)</em>s kontor för statlig registrering av juridiska personer (свидетельство ⌉ государственной регистрации юридических лиц)',
  'contract.documents.tradeRegisterLegend.SE': 'Ett registreringsbevis från <em>bolagsverket</em>s kontor för registrering i Bolagsregistret',
  'contract.documents.vatObligationLegend.EE': 'Registreringshandling utfärdad av <em>Maksu- och Tullverket</em>',
  'contract.documents.vatObligationLegend.LT': 'Registreringshandling (<em>pridėtinės vertės mokesčio mokėtojo pažymėjimasregistracijos </em>) utfärdad av <em>Valstybinė mokesčių inspekcija prie Lietuvos Respublikos finans⌉ ministerijos (VMI)</em>s kontor',
  'contract.documents.vatObligationLegend.LV': 'Registreringshandling utfärdad av <em>Valsts ieņēmumu dienests (VID)</em>s kontor',
  'contract.documents.vatObligationLegend.NO': 'Registreringshandling utfärdad av <em>Skatteetaten</em>s kontor',
  'contract.documents.vatObligationLegend.PL': 'Registreringshandling utfärdad av <em>Urzad Skarbowy (USA)</em>s kontor, som innehåller nummer Identifikacji Podatkowej (PIN-kod)',
  'contract.documents.vatObligationLegend.RU': 'Samma som avsnitt 1. dokument',
  'contract.documents.vatObligationLegend.SE': 'Registreringshandling utfärdad av <em>Skatteverket</em>s kontor',
  'contract.doNotSendContractTaxReports': '<strong>Skicka inte</strong> rapporter om avtal',
  'contract.doNotSendEitherTaxReports': '<strong>Skicka inte</strong> rapporter om avtal eller medarbetare',
  'contract.doNotSendEmployeeTaxReports': '<strong>Skicka inte</strong> rapporter om medarbetare',
  'contract.duplicateUserEmails': '<strong>Personers e-postadresser måste vara unika!</strong> <br>Varje person måste ha en personlig e-postadress. Samma adress kan inte användas av fler än en person.',
  'contract.editContract': 'Redigera avtal',
  'contract.email': 'E-post',
  'contract.emails': 'E-postmeddelanden',
  'contract.evaluations': 'Utvärderingar',
  'contract.expandAll': 'Expandera alla',
  'contract.expanding': 'Expanderar ...',
  'contract.extend': 'Förläng',
  'contract.extendingEndDate': 'Du ändrar slutdatumet till ett senare datum.',
  'contract.extendingStartDate': 'Du ändrar avtalets startdatum till ett tidigare datum.',
  'contract.extendNone': 'Förläng inte',
  'contract.extendOnlyThis': 'Endast för detta avtal',
  'contract.extendPermits': 'Du förlänger avtalets löptid. Vill du även förlänga giltighetstiden för tillträdestillstånd?',
  'contract.extendThisAndBelow': 'Även för underavtal',
  'contract.forceManagedInfo': 'Arbetsplatsens hanteringsrättigheter kommer att överföras till företaget {0} på grund av det nya huvudavtalet.',
  'contract.getCurrentJobTitleList': 'Hämta aktuell lista över arbetsetiketter',
  'contract.getCurrentMaterialsList': 'Hämta aktuell materiallista',
  'contract.getCurrentScopeList': 'Hämta aktuell lista över omfattningar',
  'contract.hasBeenRejected': 'Avtalet har avvisats',
  'contract.hasMergedTo': 'Har slagits samman med',
  'contract.hideSubcontracts': 'Dölj underavtal',
  'contract.highlight': 'Markera',
  'contract.informContractorPresence': 'Skicka ett e-postmeddelande när entreprenören kommer in på arbetsplatsen',
  'contract.insurerInfo': 'Du måste välja försäkringsgivare för tillstånd för heta arbeten innan du kan skapa dem. Försäkringsgivaren väljs från arbetsplatsens inställningar.',
  'contract.insurerMissing': 'Försäkringsgivare saknas',
  'contract.interrupting': 'Avbryter ...',
  'contract.invoiceHourApprover': 'Godkännare för fakturatimmar',
  'contract.invoiceHourReporterInfo': 'Fakturatimmar rapporteras av {{0}} och godkänns av {{1}}.',
  'contract.invoiceHours': 'Faktureringstimmar',
  'contract.invoiceHoursNotUsed': 'Faktureringstimmar används inte.',
  'contract.invoiceHoursRole': 'Faktureringstimmar, roll',
  'contract.invoiceHoursUsed': 'Faktureringstimmar som använts',
  'contract.jobTitles': 'Omfattning',
  'contract.jobTitlesAndMaterials': 'Omfattning',
  'contract.logbooks.goToLogbook': 'Gå till dagliga rapporter',
  'contract.logbooks.useLogbook': 'Börja använda dagliga rapporter',
  'contract.mainContractDescription': 'En <strong>huvudkontrakt</strong> konstruktion innefattar vanligtvis konstruktion av fundament, kaross och inredning.',
  'contract.managed': 'Tillträdesrättigheter',
  'contract.managed.deny': 'Inga tillträdesrättigheter',
  'contract.managed.deny.info': 'Personal under detta avtal läggs till på arbetsplatsen av en högre enhet i avtalskedjan.',
  'contract.managed.grant': 'Bevilja tillträdesrättigheter',
  'contract.managed.grant.info': 'Företaget kan lägga till sin egen personal på arbetsplatsen. Tillträdesrättigheter för detta avtal beviljas till de kontaktpersoner som listas här.',
  'contract.managed.label': 'Hantering av eget avtal',
  'contract.moveAsSideContract': 'Vänd till sidoavtal',
  'contract.myContract': 'Eget avtal',
  'contract.newCompanyHelp': '<strong>Nytt företag läggs till via avtal.</strong> Det nya företaget finns tillgängligt i företagsregistret så snart det är lovande för något avtal.',
  'contract.newPerson': 'Ny person',
  'contract.noContractDocumentsModal': 'Inga handlingar att visa',
  'contract.noContractDocumentsModalMessage': 'Inga avtal hittades vars handlingar kunde visas. Se till att du har behörighet till avtal som har handlingar.',
  'contract.noContractTaxReportsBelowThreshold': 'Enligt begäran från Skatteverket kommer inga rapporter om avtal att skickas om det uppskattade värdet är under 15 000 €.',
  'contract.noEvaluations': 'Inga utvärderingar',
  'contract.noForemanPossibleForUsersWithModifyPermission': 'Det går att ställa in avtalet som att det inte behövs någon arbetsledare. Denna åtgärd kräver dock behörighet att ändra avtalet, och dina behörigheter inkluderar inte det.',
  'contract.noForemanRequired': 'Arbetsledare krävs inte',
  'contract.noJobTitlesAvailable': 'Ingen omfattning tillgänglig',
  'contract.noMatchingResults': 'Inga matchande resultat.',
  'contract.noMaterialsAvailable': 'Inget material tillgängligt',
  'contract.noPersons': 'Avtalet har inga personer ännu.',
  'contract.noReportsToTaxAdmin': 'Inga rapporter till skatteverket',
  'contract.noReportToTaxAdmin': 'Ingen skatterapportering',
  'contract.notificationOfPostingOfWorkers': 'Meddelande om utstationering av arbetstagare',
  'contract.notificationOfPostingOfWorkersDate': 'Meddelandedatum',
  'contract.notificationOfPostingOfWorkersInfoMessage': 'Meddelandeplikten träder i kraft den 1 september 2017. <a href="https://anon.ahtp.fi/_layouts/Lomake.ashx?LomakeID=30103" target="_blank" rel="noopener">Info (tyosuojelu.fi) <span class="far fa-external-link-alt"></span></a>',
  'contract.notUsed': 'Används ej',
  'contract.onlyActive': 'Endast aktiv',
  'contract.onlyOwn': 'Endast egen',
  'contract.openSettings': 'Öppna inställningar',
  'contract.permitsNeedContracts': 'Förlängning av tillstånd kräver förlängning av underavtal.',
  'contract.persons': 'Personer',
  'contract.personsInContract': 'Personer<br><small>i kontrakt{0}</small>',
  'contract.reasonForRejection': 'Orsak till avslag',
  'contract.rejected': 'Avvisad',
  'contract.rejectingParty': 'Avvisande part',
  'contract.rejection': 'Avvisande',
  'contract.removeThisContact': 'Ta bort denna kontaktperson',
  'contract.reporter': 'Rapportör',
  'contract.requestApproval': 'Accepterar <strong>{0}</strong> det nya avtalet för entreprenören <strong>{1}</strong> ({2})?',
  'contract.requiredCertificates': 'Nödvändiga certifikat',
  'contract.safetyLevel': 'Säkerhetsnivå',
  'contract.safetyLevelForContract': 'Säkerhetsnivå för avtal <strong>{0}</strong> ({1})',
  'contract.scope': 'Omfattning',
  'contract.search': 'Avtalssökning',
  'contract.searchOrSelectContractContactPlaceholder': 'Kontaktperson',
  'contract.searchOrSelectJobTitlePlaceholder': 'Omfattning (t.ex. schaktning, el, rivning)',
  'contract.searchSelectOrAddJobTitlePlaceholder': 'Omfattning (t.ex. schaktning, el, rivning)',
  'contract.selectContact': 'Välj en kontaktperson',
  'contract.selectContractForNewPersons': 'Välj ett avtal för medarbetarna.',
  'contract.sendBothTaxReports': '<strong>Skicka</strong> rapporter om kontrakt och medarbetare',
  'contract.sendContractTaxReports': '<strong>Skicka</strong> rapporter om avtal',
  'contract.sendEmployeeTaxReports': '<strong>Skicka</strong> rapporter om medarbetare',
  'contract.showOnlyActive': 'Visa endast aktiva',
  'contract.showOnlyOwn': 'Visa endast egna',
  'contract.showRemainingSubcontracts': 'Visa återstående {0} underavtal',
  'contract.showSubcontracts': 'Visa underavtal ({0})',
  'contract.showSuggestionsForContact': 'Visa kontaktförslag',
  'contract.showSuggestionsForSecurityContact': 'Visa förslag på säkerhetskontakter',
  'contract.sideContractDescription': '<strong>sidoavtal</strong> är ett avtal som inte ingår i huvudavtalet, beställt av byggherren.',
  'contract.siteCompetences': 'Kompetenskrav',
  'contract.siteCompetencesRequired': 'Obligatoriskt',
  'contract.statusOnCreation': 'Status när avtalet skapades',
  'contract.subContractDescription': '<strong>Underavtal</strong> är en egen enhet definierad av entreprenören, som utförs av en av entreprenören vald underentreprenör.',
  'contract.suggestSafetyLevel': 'Föreslå säkerhetsnivå',
  'contract.suspended': 'Avbryt',
  'contract.taxReportsOnSiteNeither': 'Arbetsplatsinställningar: varken rapporter om avtal eller medarbetare kommer att skickas',
  'contract.taxReportsOnSiteNoContracts': 'Arbetsplatsinställningar: rapporter om avtal kommer inte att skickas.',
  'contract.taxReportsOnSiteNoEmployees': 'Arbetsplatsinställningar: rapporter om medarbetare kommer inte att skickas.',
  'contract.toBeMoved': 'Avtal som ska flyttas',
  'contract.valtticardNotValid': 'Det går inte att skapa ett tillträdestillstånd eftersom Valtti-kortet inte är giltigt.',
  'contractDocuments.furtherInfoCLReportUpdate': 'Information för uppdatering av entreprenöransvarsrapport',
  'contractEditor.contractExistsForB': 'Arbetsplatsen har redan ett avtal för denna entreprenör:',
  'contractEditor.emailDomainEqualsToOwn': '<strong>Ange kontaktuppgifter för din entreprenör:</strong> Angiven e-postadress liknar din egen. Se till att denna person är entreprenörens kontaktperson.',
  'contractEditor.furtherInfoOfManualCLupdate': 'Ytterligare information relaterad till uppdatering av entreprenörens ansvarsstatus',
  'contractEditor.iWantToAddMultipleContractsForThisCompany': 'Jag vill lägga till flera avtal för detta företag',
  'contractEditor.newMainContract': 'Nytt huvudavtal',
  'contractEditor.newSideContract': 'Nytt sidoavtal',
  'contractEditor.parentContract': 'Överordnat avtal',
  'contractEditor.parentContractEnds': 'Slutdatum för överordnat avtal <strong>{0}</strong>: <strong>{1}</strong>.',
  'contractEditor.showLiabilityDocs': 'Visa handlingar för entreprenörens åtaganden',
  'contractEditor.updateContractAndCLReport': 'Uppdatera kontraktsstatus och handlingar för entreprenörens åtaganden',
  'contractEditor.updateContractorLiabilityDocuments': 'Uppdatera entreprenörens ansvarshandlingar',
  'contractEditor.useHelperDuration': 'Ställ in detta avtal så att det stämmer överens med överordnat avtal',
  'contractEditor.useHelperDuration2': 'Ställ in detta avtals startdatum så att det är lika med incheckningens datum och slutdatum för att stämma överens med överordnat avtal',
  'contractEditor.useHelperDuration3': 'Avtalets startdatum måste vara före sammanslagningsdatumet <strong>{0}</strong>. Om datumet ska vara senare väljer du {1} ({2}) som avtalspartner.',
  'contractorObligationGetCompanyInfo': 'Resultat från Vastuu Group.',
  'contractorSignature': 'Entreprenörens underskrift',
  'country.eu': 'EU',
  'country.notEu': 'Ej EU',
  'course.fromExternalSystem': 'Från externt system',
  'course.noManualCreation': 'Denna utbildning läggs till personer i ett externt system, så du kan inte lägga till den i Zeroni.',
  'dailyReport.buyer': 'Köpare',
  'dailyReport.buyerRep': 'Beställarens representant',
  'dailyReport.byCheckIns': 'Genom incheckningar',
  'dailyReport.byPreviousDay': 'Föregående dag',
  'dailyReport.changeMonth': 'Ändra månad',
  'dailyReport.contractorRep': 'Entreprenörsrepresentant',
  'dailyReport.copyValues': 'Använd',
  'dailyReport.day': 'Dag',
  'dailyReport.dayWeather': 'Väder under dagen',
  'dailyReport.documents': 'Handlingar',
  'dailyReport.employeeUnitShort': 'Person(er)',
  'dailyReport.extinguishingEquipments': 'Släckningsutrustning och övervakning av heta arbeten',
  'dailyReport.firstEnterRequiredInfo': 'Fyll först i nödvändig information',
  'dailyReport.jobTitle': 'Jobbtitel',
  'dailyReport.logbook.dailyReportsAvailableAfterSiteBegins': 'Dagliga rapporter är tillgängliga efter att arbetsplatsen har startat {0}',
  'dailyReport.logbook.dataIsUnavailable': 'Loggbokens historikinformation är inte tillgänglig',
  'dailyReport.logbook.noPagesToPrint': 'Inga sidor att skriva ut.',
  'dailyReport.logbook.printAllDays': 'Skriv ut alla dagar',
  'dailyReport.logbook.printingLogbookPleaseWait': 'Förbereder loggbok för utskrift. Detta kan ta några minuter. Vänligen vänta ...',
  'dailyReport.logbook': 'Loggbok',
  'dailyReport.logbooks.changeLogbook': 'Ändra loggbok',
  'dailyReport.logbooks.name': 'Loggbokens namn',
  'dailyReport.logbooks.new': 'Ny loggbok',
  'dailyReport.logbooks.open': 'Öppen',
  'dailyReport.logbooks.printLogbook': 'Skriv ut',
  'dailyReport.logbooks.printLogbookWeek': 'Skriv ut vecka',
  'dailyReport.logbooks.searchResults.many': '{0} loggböcker',
  'dailyReport.logbooks.searchResults.one': 'En loggbok',
  'dailyReport.logbooks.selectContract': 'Välj det avtal som du vill lägga till en loggbok för.',
  'dailyReport.logbooks.selectDaysToPrint': 'Välj dagar att skriva ut',
  'dailyReport.logbooks.siteSpecific': 'Arbetsplatsens loggbok',
  'dailyReport.logbooks': 'Loggböcker',
  'dailyReport.machine': 'Maskin',
  'dailyReport.machines': 'Maskiner',
  'dailyReport.machineUnitShort': 'pcs',
  'dailyReport.morning': 'Morgon',
  'dailyReport.morningWeather': 'Vädret på morgonen',
  'dailyReport.noEmployees': 'Inga medarbetare',
  'dailyReport.noPermissionsToSupervisorNotes': 'Du har inte behörighet att ändra din arbetsledares anteckningar.',
  'dailyReport.noSignedReport': 'Ingen signerad rapport',
  'dailyReport.noSupervisorNotes': 'Anteckningar från arbetsledare saknas',
  'dailyReport.openNext': 'Öppna nästa rapport',
  'dailyReport.openNextReport': 'Signera och öppna nästa osignerade rapport',
  'dailyReport.others': 'Övriga',
  'dailyReport.otherShort': 'Övrigt',
  'dailyReport.resources': 'Resurs',
  'dailyReport.resourcesInitialized': 'Personal bestäms enligt incheckningar. Maskinerna initieras till föregående dags nivåer. Kontrollera uppgifterna.',
  'dailyReport.resourcesShort': 'Resultat',
  'dailyReport.sectionTitle': 'Avsnittsrubrik',
  'dailyReport.showSuggestion': 'Förslag',
  'dailyReport.sign': 'Signera',
  'dailyReport.signatureFromPersonWhoHasPermission': 'Signatur från en person som har rätt att fylla i en dagrapport',
  'dailyReport.signatureFromSupervisorNeeded': 'Signatur från en person som har rätt att registrera arbetsledarens anteckningar',
  'dailyReport.signatures': 'Signaturer',
  'dailyReport.signaturesShort': 'Signera',
  'dailyReport.staff': 'Personal',
  'dailyReport.supervisor': 'Arbetsledare',
  'dailyReport.supervisorHelp': '<strong>Vid behov kan en extern arbetsledare utses till arbetsplatsen.</strong> Till exempel kan din organisations administratör skapa en användare som får en arbetsledarroll som är begränsad till den här platsen',
  'dailyReport.supervisorShort': 'ARBETSLED.',
  'dailyReport.supervisorSignature': 'ARBETSLEDARENS UNDERSKRIFT',
  'dailyReport.supervisorsNotes': 'Arbetsledarens anteckningar',
  'dailyReport.temperature': 'Temperatur',
  'dailyReport.template': 'Mall för daglig byggrapport',
  'dailyReport.templateHelp': '<p><strong>Fälten i avsnittet Övrigt kan ändras.</strong></p><p>Du kan lägga till eller ta bort fält som du vill i <strong>Mall för daglig byggrapport</strong>.</p>',
  'dailyReport.templates': 'Mallar för daglig byggrapport',
  'dailyReport.templateUpdatedNotification': 'Mall för daglig byggrapport uppdaterad',
  'dailyReport.useThis': 'Använd gärna',
  'dailyReport.weather.cloudy': 'molnigt',
  'dailyReport.weather.partlyCloudy': 'Delvis molnigt',
  'dailyReport.weather.rain': 'Regn',
  'dailyReport.weather.sleet': 'Snöblandat regn',
  'dailyReport.weather.snow': 'Snö',
  'dailyReport.weather.strongWind': 'Kraftig vind',
  'dailyReport.weather.sunny': 'Solsken',
  'dailyReport.weather.thunder': 'Åska',
  'dailyReport.weather': 'Väder',
  'dailyReport.weatherCondition': 'Väderförhållanden',
  'dailyReport.workMonitoring': 'Arbetsledning',
  'dailyReports.missingReportLegend': 'Dagbokspost saknas',
  'dailyReports.noSafetyMeasurements': 'Inga säkerhetsåtgärder.',
  'dailyReports.printHelp': 'Dagens sida är tillgänglig för utskrift efter att den har signerats.',
  'dailyReports.reportWaitingForSupervisorLegend': 'Arbetsledarens meddelande eller underskrift saknas',
  'dashboard.accessControl': 'Tillträdeskontroll',
  'dashboard.activeCompanies': 'Aktiva företag',
  'dashboard.activeContracts': 'aktiva avtal',
  'dashboard.activeEmployees': 'Aktiva medarbetare (tillståndet är aktivt)',
  'dashboard.activeOwn': 'Aktivt eget',
  'dashboard.activeOwnEmployees': 'Aktiva egna medarbetare',
  'dashboard.activeOwnPartners': 'Aktiva avtalsparter',
  'dashboard.activeVisitors': 'Aktiva besökare',
  'dashboard.activeWorkers': 'Medarbetare på arbetsplatsen',
  'dashboard.activeWorkersAction': 'Visa incheckade arbetstagare',
  'dashboard.activeWorkersDescription': '{0} % av arbetstagare med tillstånd',
  'dashboard.addSubContract': 'Lägg till underleverantörsavtal',
  'dashboard.allActiveSites': 'Alla aktiva arbetsplatser',
  'dashboard.allEmployeesFromStart': 'Alla medarbetare',
  'dashboard.assignEmployeeToSite': 'Anmäl arbetare till arbetsplatsen',
  'dashboard.averageActiveSitesInSixMonths': 'Genomsnittligt antal arbetsplatser under de senaste sex månaderna',
  'dashboard.averageLevel': 'Genomsnittlig nivå för {0}-mätningar: <strong>{1}%</strong>',
  'dashboard.averageStrengthFromLastSixMonths': 'Genomsnittlig styrka på alla arbetsplatser under de senaste sex månaderna',
  'dashboard.checkInDevices': 'Incheckningsenheter',
  'dashboard.companies': 'Företag',
  'dashboard.contractCompletedPayments': 'Slutförda betalningar',
  'dashboard.contractors': 'Entreprenörer',
  'dashboard.contractorWithoutForeman': 'Entreprenör <strong>{0}</strong> har ingen utsedd arbetsledare med tillträdestillstånd.',
  'dashboard.contractsWithoutCheckIns': 'Följande avtal har inga incheckningar:',
  'dashboard.contractWithoutCheckIns': 'Följande avtal har inga incheckningar:',
  'dashboard.editCompanyInfo': 'Redigera företag',
  'dashboard.employees': 'Medarbetare',
  'dashboard.expired': 'Förfallen',
  'dashboard.expiring': 'Går ut',
  'dashboard.foreignCompanies': 'Utländska företag',
  'dashboard.gateDevices': 'Grindenheter',
  'dashboard.hotWorkPermitExpired': 'Medarbetarens <strong>tillstånd för heta arbeten</strong> har gått ut inom de senaste tre dagarna.',
  'dashboard.hotWorkPermitsExpired': '<strong>{0}</strong> medarbetares <strong>tillstånd för heta arbeten</strong> har gått ut inom de senaste tre dagarna.',
  'dashboard.jumpToSite': 'Gå till arbetsplats',
  'dashboard.latestSafetyLevel': 'Senaste {0} mätning: {1} %',
  'dashboard.loadNotifications': 'Ladda meddelanden',
  'dashboard.mainContractorSites': 'Huvudentreprenad/utveckling',
  'dashboard.nationalityEU': 'Övrigt EU',
  'dashboard.nationalityFin': 'Finska',
  'dashboard.nationalityNONEU': 'Ej EU',
  'dashboard.noFilesYet': 'Inga filer har laddats upp',
  'dashboard.noForeman': 'Ingen arbetsledare',
  'dashboard.noMeasurements': 'Inga säkerhetsåtgärder',
  'dashboard.noNotifications': 'Allt är bra! Inga meddelanden eller varningar.',
  'dashboard.noPermissionToCheckIns': 'Ingen behörighet att komma åt incheckningar',
  'dashboard.noPermissionToContracts': 'Ingen behörighet att komma åt avtal',
  'dashboard.noPermissionToSafety': 'Ingen behörighet att komma åt säkerhetsmätningar',
  'dashboard.noSafetyMeasurementType': 'Säkerhetsmätningstyp har inte valts för denna arbetsplats',
  'dashboard.noSitesSoAddNew': '<strong>Inga arbetsplatser.</strong> Registret innehåller inga arbetsplatser eller så har du inte nödvändiga behörigheter.',
  'dashboard.noSitesSoAddNew2': 'Lägg till ny arbetsplats',
  'dashboard.notification.noOrientationPermission': 'Du har inte behörighet att bekräfta introduktion. Kontakta huvudentreprenören.',
  'dashboard.notification.personPendingChanges': 'Person <a href="#!/sites/{0}/persons/{1}">{2}</a> klarade inte verifiering av arbetsrätt.',
  'dashboard.notification.personPendingVerification': 'Person <a href="#!/sites/{0}/persons/{1}">{2}</a> väntar på verifiering av arbetsrätt.',
  'dashboard.notification.personsPendingChanges': '<strong>{0} personer</strong> klarade inte verifiering av arbetsrätt, väntar på ändringar.',
  'dashboard.notification.personsPendingVerification': '<strong>{0} personer</strong> väntar på verifiering av arbetsrätt.',
  'dashboard.notification.vehiclePermitAwaitingApproval': '<strong>1</strong> fordonstillstånd väntar på godkännande eller åtgärder.',
  'dashboard.notification.vehiclePermitsAwaitingApproval': '<strong>{0}</strong> fordonstillstånd väntar på godkännande eller åtgärder.',
  'dashboard.notifications': 'Meddelanden',
  'dashboard.onlyOwnEmployees': 'Endast egna medarbetare',
  'dashboard.openFiles': 'Filer',
  'dashboard.openGraphs': 'Diagram',
  'dashboard.openNotifications': 'Meddelanden',
  'dashboard.openOverview': 'Översikt',
  'dashboard.orientationAndOtherAttachments': 'Introduktion och bilagor',
  'dashboard.overallLevel': 'Procentandel korrekta observationer från alla säkerhetsmätningar.',
  'dashboard.overallLevelLastFourWeeks': 'Procentandel av alla korrekta observationer från alla säkerhetsmätningar under de senaste fyra veckorna.',
  'dashboard.overallLevelLastWeek': 'Procentandel av alla korrekta observationer från alla säkerhetsmätningar under de senaste sju dagarna.',
  'dashboard.overview.noReportsOfContractsSent': 'Inga skatterapporter för avtalsbetalningar skickade',
  'dashboard.overview.noReportsOfEmployeesSent': 'Inga medarbetarrapporter skickas',
  'dashboard.overview': 'Översikt',
  'dashboard.quickActions': 'Snabba åtgärder',
  'dashboard.residencePermitContinuationNeedsToBeChecked': '<strong>Uppehållstillstånd</strong> fortsättningsstatus behöver kontrolleras.',
  'dashboard.residencePermitContinuationsNeedsToBeChecked': '<strong>{0} uppehållstillstånds </strong> fortsatta status behöver kontrolleras.',
  'dashboard.safetyMeasurementsAverage': 'Genomsnitt av säkerhetsmått',
  'dashboard.safetyMeasurementsAverageAction': 'Senaste mätning ({0} %)',
  'dashboard.searchHelp': 'Sök efter personer, entreprenörer, arbetsplatser och andra',
  'dashboard.searchPlaceholder': 'Ange sökord',
  'dashboard.selectForeman': 'Välj arbetsledare',
  'dashboard.siteContractsWithoutCheckIns': 'Följande avtal för arbetsplatsen <strong>{0}</strong> har inga incheckningar:',
  'dashboard.siteContractWithoutCheckIns': 'Avtalet för arbetsplatsen <strong>{0}</strong> har inga incheckningar:',
  'dashboard.siteDocuments': 'Allmänna arbetsplatshandlingar',
  'dashboard.siteLinks': 'Arbetsplatslänkar',
  'dashboard.siteOrientationMaterial': 'Arbetsplatsens introduktionsmaterial',
  'dashboard.sites': 'Arbetsplatser',
  'dashboard.subContractorSites': 'underleverantörsarbetsplatser',
  'dashboard.totalDays': '{0} antal arbetsdagar (7,5 h)',
  'dashboard.totalHours': 'Totalt antal arbetstimmar',
  'dashboard.totalNumberOfHazards': 'Väntande säkerhetsavvikelser',
  'deviation.photoOfDeviation': 'Bild på avvikelsen',
  'deviation.photoOfReparation': 'Bild på reparation',
  'device.basicInformation': 'Allmän information',
  'device.confirmRemovalFromOtherSites': 'Enheten du lade till används redan på en annan arbetsplats. <strong>Vill du ta bort enheten från andra arbetsplatser?</strong>',
  'device.connectDepartmentDevices': 'Anslut enheter för avdelningen {0}',
  'device.connectDepartmentDevicesInfo': 'Arbetsplatsen tillhör avdelningen {0} för vilken {1} enheter har definierats. Vill du ansluta enheterna till arbetsplatsen?',
  'device.connectDevices': 'Anslut enheter till arbetsplatsen',
  'device.copyFromDevice': 'Kopiera från enhet',
  'device.deletionPermanent': '<strong>Oåterkallerlig:</strong> Enheten och all dess information kommer att raderas. Raderingen kan inte ångras.',
  'device.doNotRemoveDeviceFromOtherSites': 'Ta inte bort enheten från andra arbetsplatser',
  'device.furtherInformation': 'Övrig information',
  'device.isNotOnAnySite': 'Enheten är inte tilldelad någon arbetsplats.',
  'device.locationOnSite': 'Plats på arbetsplats',
  'device.model': 'Modell',
  'device.purchaseDate': 'Inköpsdatum',
  'device.purchasePrice': 'Inköpspris (€)',
  'device.removeDevice': 'Ta bort enhet',
  'device.removeFromOtherSites': 'Ta bort enhet från andra arbetsplatser?',
  'device.removeFromSite': 'Ta bort från arbetsplats',
  'device.showDevices': 'Visa enheter',
  'device.telephone': 'Telefon',
  'deviceSearch.searchDevice': 'Sök efter en enhet',
  'deviceSubscriptionEnded': 'Mobilabonnemang avslutat',
  'dhs.companyContacts': 'Företagskontakter <span class="text-muted">(antal företag, bransch, status)</span>',
  'dhs.companyContactsEstonia': 'Företagskontakter i Estland',
  'dhs.companyFinancials': '<strong>Företagsekonomi</strong>',
  'dhs.courtInfo': 'Information om företag och personer med ansvar för rättsväsendet',
  'dhs.creditDisturbance': 'Dålig kredithistorik, inkasso, kreditvärdighet',
  'dhs.creditReport': 'Diskret information i realtid',
  'dhs.deliveryTime': 'Leveranstid (arbetsdagar)',
  'dhs.dhsReport1': 'Rapport 1',
  'dhs.dhsReport2': 'Rapport 2',
  'dhs.dhsReports': 'DHS-rapporter',
  'dhs.employeeInfo': 'Medarbetaruppgifter',
  'dhs.EstPersonsInChargeCompanyContactsInEst': 'Estniska personers ansvariga företagskontakter i Estland',
  'dhs.financialStatementAnalysis': 'Årsredovisningsanalys <span class="text-muted">(baserat på de senaste 5 årens årsredovisning)</span>',
  'dhs.hideReportDetails': 'Dölj rapportdetaljer',
  'dhs.orderDhsReport1': 'Beställ <strong>Rapport 1</strong>',
  'dhs.orderDhsReport2': 'Beställ <strong>Rapport 2</strong>',
  'dhs.orderingReport': 'Beställer ...',
  'dhs.orderNew': 'Beställ nytt',
  'dhs.orderReady': 'Var beredd',
  'dhs.orderReceived': 'Beställning mottagen',
  'dhs.paymentDefaultEntry': 'Post för betalningsförsummelse <span class="text-muted">(negativ kredithistorik, förbud mot affärsverksamhet)</span>',
  'dhs.personsInCharge': 'Ansvariga',
  'dhs.prevPersCreditInfoAndCompanyContacts': '<em>Tidigare</em> ansvariga (nu – 2 år) Betalningsförsummelser och företagskontakter',
  'dhs.price': 'Pris (€, moms 0 %)',
  'dhs.registryData': '<strong>Företagets registeruppgifter</strong>',
  'dhs.reports': 'Rapporter',
  'dhs.taxes': 'Moms, förskottsinbetalning, arbetsgivar- och skatteskuldsregisteruppgifter',
  'dhs.whatsIncludedInReports': 'Visa rapporternas innehåll och priser',
  'employeecheckin.areas.checkedIn': 'Du checkade in i område {0}',
  'employeecheckin.areas.checkIn': 'Checka in i område',
  'employeecheckin.areas.checkOut': 'Checka ut från område {0}',
  'employeecheckin.loadWorkTimeReports': 'Visa arbetstider för alla arbetsplatser',
  'employeecheckin.noAccessPermits': 'Du har inga tillträdestillstånd.',
  'employeecheckin.noContractorReadPermissionForCheckIns': 'Entreprenörer har ingen läsbehörighet för instämplingar på denna plats.',
  'employeeMenu.accessPermits': 'Tillträdestillstånd',
  'employeeMenu.checkInsHistory': 'Incheckningshistorik',
  'employeeMenu.competences': 'Kompetensområden',
  'error.endDateHasToBeGreaterThanStartDate': 'Slutdatum måste vara senare än startdatum.',
  'error.endDateHasToBeTodayOrInTheFuture': 'Slutdatum måste vara idag eller i framtiden.',
  'error.valttiCardBusinessIdMismatch': 'Valtti-kortet har fel företags-ID.',
  'error.valttiCardCouldNotBeAdded': 'Det gick inte att lägga till Valtti-kort.',
  'error.valttiCardNotFound': 'Valtti-kort hittades inte.',
  'error.valttiCardWrongPerson': 'Valtti-kortet tillhör en annan person.',
  'errorHandler.emailSent': 'Vår kundtjänst har informerats. Vi kontaktar dig så snart problemet är löst.',
  'errorHandler.hideDetails': 'Dölj detaljer',
  'errorHandler.insufficientPermissions': 'Du har inte behörighet att ändra denna resurs.',
  'errorHandler.ohNo': 'Ett oväntat fel inträffade.',
  'errorHandler.pleaseHideItAlready': 'Åh, göm den redan nu!',
  'errorHandler.readDetails': 'läs tekniska detaljer om felet',
  'errorHandler.readDetailsShort': 'Läs tekniska detaljer om felet',
  'errorHandler.times': 'Det har nu misslyckats {0} gånger.',
  'errorHandler.tryAgainOr': 'Var god försök igen senare eller',
  'evaluation.addCriteriaLink': 'Lägg till kriterier vid: Hantera &rarr; allmänna inställningar',
  'evaluation.addNewEvaluation': 'Lägg till ny utvärdering',
  'evaluation.averageExplanation': 'Betyget är medelvärdet av alla aktiva kriterier. Borttagna kriterier påverkar inte betyget.',
  'evaluation.category.header.existing': 'Välj befintlig',
  'evaluation.category.header.manual': 'Manuell inmatning',
  'evaluation.category.manual': 'Ange namn och nummer manuellt',
  'evaluation.confirmRemoveEvaluation.body': 'Är du säker på att du vill ta bort utvärderingen?',
  'evaluation.confirmRemoveEvaluation.title': 'Ta bort utvärdering?',
  'evaluation.doNotApply': 'Ej aktuellt',
  'evaluation.editEvaluation': 'Redigera utvärdering',
  'evaluation.evaluations': 'utvärderingar',
  'evaluation.howAverageIsCalculated': 'Hur beräknas det?',
  'evaluation.interestedModal.askForQuote': 'Vill du utvärdera dina underentreprenörer? Vi kontaktar ditt företag.',
  'evaluation.interestedModal.heading': 'Intresserad av utvärdering?',
  'evaluation.interestedModal.readMore': 'Läs mer om utvärdering',
  'evaluation.interestedModal.readMoreIntro': '<b>Företagsutvärdering</b> är en betald modul.',
  'evaluation.interestedModal.yesInterested': 'Ja, jag är intresserad',
  'evaluation.newEvaluation': 'Ny utvärdering',
  'evaluation.noCriteria': 'Inga befintliga utvärderingskriterier.',
  'evaluation.removeCriterion.body': 'Är du säker på att du vill ta bort kriteriet <b>{0}</b>?',
  'evaluation.removeCriterion.heading': 'Ta bort kriterium?',
  'evaluation.removeEvaluation': 'Ta bort utvärdering',
  'evaluation.requestSentModal.body': 'Tack! Vi kommer att kontakta dig inom kort.',
  'evaluation.requestSentModal.heading': 'Kontaktförfrågan har skickats',
  'evaluation.settings.readMore': 'Läs mer',
  'evaluation.settings.readMoreIntro': 'Företagsutvärdering är inte aktiverad.',
  'evaluation.unknownSite': 'Okänd arbetsplats',
  'evaluation': 'utvärdering',
  'faultList': 'Lista över fel',
  'faultLists.addFirst': 'Lägg till första fellistan',
  'faultLists.addList': 'Lägg till lista',
  'faultLists.defaultContractor': 'Standardentreprenör',
  'faultLists.defaultContractorHelp': '<strong>Standardentreprenör</strong> är ett standardvärde för en entreprenör som ansvarar för nya kvalitetsavvikelser på denna lista.',
  'faultLists.delete.body': '<p>Alla fel kommer att förbli intakta men de kommer inte att tillhöra någon lista.</p><p>Är du säker på att du vill ta bort listan <strong>{0}</strong>?',
  'faultLists.delete.heading': 'Ta bort lista?',
  'faultLists.lastSync': 'Sparad på',
  'faultLists.newFaultList': 'Ny fellista',
  'faultLists.newList': 'Ny lista',
  'faultLists.noLists': 'Inga listor ännu',
  'faultLists.reportNotSynced': '<b>1</b> avvikelse ej synkroniserad.',
  'faultLists.reports.all': 'Fel',
  'faultLists.reports.repaired': 'reparerad',
  'faultLists.reports.waiting': 'väntar',
  'faultLists.reportsNotSynced': '<b>{0}</b> avvikelser ej synkroniserade.',
  'faultLists.searchByName': 'Sök efter namn',
  'faultLists.searchOrSelectFaultList': 'Lista över fel',
  'faultLists.searchResults.many': '{0} avvikelser',
  'faultLists.searchResults.one': '1 avvikelse',
  'faultLists.syncedJustNow': 'Synkroniserad just nu.',
  'faultLists.syncing': 'Synkroniserar ...',
  'faultLists.types.acceptanceInspection': 'Mottagningskontroll',
  'faultLists.types.other': 'Övrigt',
  'faultLists.types.selfInspection': 'Egenkontroll',
  'faultLists': 'Lista över fel',
  'feedback.continue': 'Fortsätt till Zeroni',
  'file.abortAndContinue': 'Avbryt uppladdningen och lämna sidan',
  'file.fileName': 'Filens namn',
  'file.showFile': 'Visa filen',
  'file.size': 'Storlek',
  'file.uploadCancelInfo': 'Du är på väg att lämna sidan som laddar upp en fil. Om du fortsätter avbryts uppladdningen.',
  'file.uploadInProgress': 'Filen laddas upp ...',
  'files': 'Filer',
  'fileUploadFailed': 'Filuppladdningen misslyckades. Försök igen eller kontakta supporten.',
  'footer.call': 'Ring',
  'footer.or': 'eller',
  'footer.privacyPolicy': '<a target="_blank" rel="noopener" href="https://www.zeroni.fi/privacy-policy/">Sekretesspolicy</a>',
  'footer.sendMessage': 'Skicka ett meddelande',
  'footer.support': 'Support:',
  'foreman.removeContractForeman': 'Ta bort avtalets arbetsledare',
  'foremanNotSelected': 'Ingen arbetsledare har valts för avtalet',
  'foremen.activeEmployees': 'Aktiva medarbetare',
  'foremen.chooseForemen': 'Välj arbetsledare',
  'foremen.chooseForemenInfo': 'Välj arbetsledare för entreprenören <strong>{0}</strong>. Arbetsledarens status sparas i tillträdestillståndet.',
  'foremen.contractForeman': 'Avtalsansvarig',
  'foremen.deputyForeman': 'Tillförordnad arbetsledare',
  'foremen.employeesNotFound': 'Det finns inga medarbetare med giltigt tillträdestillstånd på detta avtal.',
  'foremen.foreman': 'Arbetsledare',
  'foremen.foremen': 'Arbetsledare',
  'foremen.noContractForemanInfo': 'Avtalsarbetsledare har inte valts. Du kan välja avtalsarbetsledare genom att välja i listan.',
  'foremen.notForeman': 'Ingen arbetsledare',
  'foremen.selectAsContractForeman': 'Välj denna person som avtalsarbetsledare.',
  'foremen.skipContractForemanRequirement': 'Om du inte vill välja avtalsarbetsledare klickar du här.',
  'frontPage': 'Förstasida',
  'graph.active_employees_count': 'Aktiva medarbetare',
  'graph.activeCompanies': 'Aktiva företag',
  'graph.activeContracts': 'Aktiva avtal',
  'graph.activeOwnContractsCompanies': 'Underentreprenörer till {0}',
  'graph.chainedcontractor': 'Underentreprenörer',
  'graph.chainedcontractors': 'Underentreprenörer',
  'graph.companiesByPostcode': 'Antal företag',
  'graph.contractpartners': 'Avtalspartner',
  'graph.contractsByLevel': 'Avtal på arbetsplatsen efter typ',
  'graph.contractsValueByPostcode': 'Avtalsvärde (t€)',
  'graph.eperehdytys_count': 'Elektriska data',
  'graph.eperehdytysCount': 'E-introduktion',
  'graph.finnish': 'Finska',
  'graph.firstLevelSubcontracts': 'Underavtal på nivå 1',
  'graph.foreign': 'Utrikes',
  'graph.graphsWillBeShownAfterSiteStarted': 'Diagrammen visas när arbetsplatsen är aktiv.',
  'graph.maincontractor': 'Huvudentreprenör',
  'graph.mainContracts': 'Huvudavtal',
  'graph.nationalities': 'Nationaliteter',
  'graph.newcomerTrainings': 'Nybörjarutbildningar',
  'graph.noGraphsSelectSite': 'Diagrammen är arbetsplatsspecifika. Välj en arbetsplats för att se diagram.',
  'graph.noGraphsYet': 'Inga diagram ännu',
  'graph.numberOfActiveEmployees': 'Antal aktiva medarbetare',
  'graph.otherCompanies': 'Övriga företag',
  'graph.partnerContracts': 'Avtalspartner',
  'graph.permitsByCompanies': 'Tillstånd av företag',
  'graph.secondLevelSubcontracts': 'Underavtal på nivå 2',
  'graph.sidecontractors': 'Tilläggsentreprenörer',
  'graph.sideContracts': 'Tilläggsavtal',
  'graph.sitesContractsbyLevel': 'Avtalstyper',
  'graph.siteStarts': 'Arbetsplatsens startdatum är inställt på {0}.',
  'graph.strength': 'Personalantal',
  'graph.thirdOrMoreLevelSubcontracts': 'Underavtal på nivå 3+',
  'graph.workHours': 'Arbetstimmar',
  'graph': 'Diagram',
  'graphs': 'Diagram',
  'help.addToVendorRegister': 'Du kan lägga till ett företag utan att skapa ett avtal',
  'help.closeQuickTours': 'Stänga snabbguiderna?',
  'help.dontShowQuickToursAgain': 'Visa inte snabbguider igen',
  'help.helpForAdmin': 'Hjälp för admin.',
  'help.helpForSubcontractor': 'Hjälp för underentreprenör',
  'help.quickTours': 'Snabbguider',
  'hotWork.filename': 'tillstånd för heta arbeten',
  'hotWork.guardingTimeAfterWork.hours': 'timmar',
  'hotWork.guardingTimeAfterWork': 'Efter arbete',
  'hotWorkPermit.addHotWorkPermit': 'Lägg till tillstånd för heta arbeten',
  'hotWorkPermit.addPermit': 'Lägg till tillstånd',
  'hotWorkPermit.address': 'Adress för tillstånd för heta arbeten',
  'hotWorkPermit.addressPlaceHolder': 'Adresser',
  'hotWorkPermit.approver': 'Godkännare av tillstånd',
  'hotWorkPermit.approverSignatureAndClarification': 'Godkännarens underskrift och namnförtydligande',
  'hotWorkPermit.backToHotWorkPermits': 'Tillbaka till listan över tillstånd för heta arbeten',
  'hotWorkPermit.basicInfoHeader': 'Heta arbeten, plan och plats',
  'hotWorkPermit.cannotAddNew.noPermission': 'Du kan inte skapa nya tillstånd för heta arbeten eftersom du inte har behörighet.',
  'hotWorkPermit.cannotAddNew': 'Det går inte att skapa ett nytt tillstånd för heta arbeten eftersom medarbetaren saknar nödvändiga aktiva kompetenser för heta arbeten eller tillstånd för tillträde till arbetsplatsen.',
  'hotWorkPermit.careTaker': 'Namn och telefon till den person som ansvarar för brandlarm/sprinklersystem',
  'hotWorkPermit.client.contact': 'Kontaktperson och telefonnummer',
  'hotWorkPermit.client': 'Uppdragsgivare',
  'hotWorkPermit.companyOrCommunity': 'Företag/samhälle',
  'hotWorkPermit.companyOrContractor': 'Företag/entreprenör',
  'hotWorkPermit.contact': 'Byggherrens kontaktperson',
  'hotWorkPermit.contractor': 'Entreprenören',
  'hotWorkPermit.contractorHasNoForemen': 'Entreprenörer har inga arbetsledare att välja som tillståndshavare.',
  'hotWorkPermit.contractorsPlan': 'entreprenörens',
  'hotWorkPermit.dangerAssessment.section.1': 'Valfria arbetsmetoder bedöms innan tillstånd för heta arbeten utfärdas.',
  'hotWorkPermit.dangerAssessment.section.10': 'Gnistor och stänk kan spridas till ett brett område eftersom heta arbeten utförs på hög höjd.',
  'hotWorkPermit.dangerAssessment.section.11': 'Arbetsplats för heta arbeten har luftkonditionering och luftintag.',
  'hotWorkPermit.dangerAssessment.section.12': 'Heta arbeten består av skivkapning eller slipning som orsakar gnistor som kan orsaka fara inom en radie på 10 meter från platsen för heta arbeten.',
  'hotWorkPermit.dangerAssessment.section.13': 'Heta arbeten består av flamskärning som gör att gnistor sprids över ett brett område.',
  'hotWorkPermit.dangerAssessment.section.14': 'Värmen som alstras vid heta arbeten kan spridas till vägg-, tak- och golvkonstruktioner.',
  'hotWorkPermit.dangerAssessment.section.2': 'Arbetsplatsen för heta arbeten innehåller brandfarligt damm, pulveriserat material eller brandfarligt avfall.',
  'hotWorkPermit.dangerAssessment.section.3': 'Det finns brandfarliga material på arbetsplatser för heta arbeten.',
  'hotWorkPermit.dangerAssessment.section.4': 'Ytor på väggar, tak och golv eller strukturer i närheten av arbetsplatsen för heta arbeten kan antändas.',
  'hotWorkPermit.dangerAssessment.section.5': 'Vägg-, tak- och golvkonstruktioner i närheten av arbetsplatsen för heta arbeten innehåller brandfarliga material.',
  'hotWorkPermit.dangerAssessment.section.6': 'Det finns kablar eller kabelrännor på arbetsplatsen för heta arbeten.',
  'hotWorkPermit.dangerAssessment.section.7': 'Föremålet för det heta arbetet innehåller brandfarliga material.',
  'hotWorkPermit.dangerAssessment.section.8': 'Arbetsplatsen för heta arbeten har öppningar, hål, håligheter eller ventilationskanaler/spalter genom vilka lågor, gnistor, stänk kan tränga in i strukturer eller omgivande miljö.',
  'hotWorkPermit.dangerAssessment.section.9': 'Arbetsplatser för heta arbeten innehåller eller riskerar att generera brandfarliga gaser eller ångor.',
  'hotWorkPermit.dangerAssessment.section.otherDangers': 'Övriga faror:',
  'hotWorkPermit.developer': 'Byggherre',
  'hotWorkPermit.developersPlan': 'uppdragsgivarens',
  'hotWorkPermit.duration': 'Varaktighet',
  'hotWorkPermit.emergencyNumber': 'Nödsamtalsnummer',
  'hotWorkPermit.employer': 'Arbetsgivare',
  'hotWorkPermit.equipmentsAndGuarding': 'Utrustning och skydd',
  'hotWorkPermit.extinguishersAcquiredBy': 'Släckningsutrustning tillhandahålls av',
  'hotWorkPermit.extinguishingEquipments.equipment.1': 'Handbrandsläckare 55A 233B C',
  'hotWorkPermit.extinguishingEquipments.equipment.10': 'Särskild brandbekämpningsutrustning',
  'hotWorkPermit.extinguishingEquipments.equipment.11': 'Handbrandsläckare 43A 233B C',
  'hotWorkPermit.extinguishingEquipments.equipment.2': 'Handbrandsläckare 43A 183B C',
  'hotWorkPermit.extinguishingEquipments.equipment.3': 'Handbrandsläckare 27A 144B C',
  'hotWorkPermit.extinguishingEquipments.equipment.4': 'CO2-släckare',
  'hotWorkPermit.extinguishingEquipments.equipment.5': 'Brandslangsvinda',
  'hotWorkPermit.extinguishingEquipments.equipment.6': 'Tryckbrandslang',
  'hotWorkPermit.extinguishingEquipments.equipment.7': 'Kylvätskepump',
  'hotWorkPermit.extinguishingEquipments.equipment.8': 'Brandfilt',
  'hotWorkPermit.extinguishingEquipments.equipment.9': 'Rengöring av utrustningen',
  'hotWorkPermit.extinguishingEquipments': 'Brandsläckningsutrustning',
  'hotWorkPermit.fireAlarmAndSprinklerPolicyInfo': 'Vid behov ska brandlarm kopplas från vid heta arbeten. Endast den person som ansvarar för utrustningen får ansluta eller koppla från systemen. Sprinklersystemet ska inte kopplas från. Vid behov kan sprinklermunstyckena täckas över under heta arbeten. Detta ska överenskommas med den person som ansvarar för utrustningen.',
  'hotWorkPermit.fireExtinguisherRequirements': 'Krav på brandsläckare',
  'hotWorkPermit.guarding.duringWork': 'Under arbete och raster.',
  'hotWorkPermit.guarding': 'Skydd vid heta arbeten',
  'hotWorkPermit.hazards': 'Identifiera och bedöma de risker som är förknippade med heta arbeten',
  'hotWorkPermit.hotWork.description': 'Arbete, plan, plats',
  'hotWorkPermit.hotWork.listOfSafetyRisks': 'Lista över säkerhetsrisker',
  'hotWorkPermit.hotWorkFollowsHotWorkPlanOfPart1': 'Plan för heta arbeten för ',
  'hotWorkPermit.hotWorkFollowsHotWorkPlanOfPart2': 'följs ',
  'hotWorkPermit.hotWorkGuard': 'Skydd vid heta arbeten',
  'hotWorkPermit.hotWorkGuarding': 'Skydd vid heta arbeten',
  'hotWorkPermit.hotWorkPermitApprover': 'Utfärdare av tillstånd för heta arbeten',
  'hotWorkPermit.hotWorkSecurityMeasures': 'Säkerhetsåtgärder vid heta arbeten',
  'hotWorkPermit.hotWorkType.bitumenCooker': 'Användning av bitumenkokare',
  'hotWorkPermit.hotWorkType.discCutting': 'Skivkapning och slipning',
  'hotWorkPermit.hotWorkType.electricWelding': 'Bågsvetsning',
  'hotWorkPermit.hotWorkType.flameCutting': 'Flamskärning',
  'hotWorkPermit.hotWorkType.gasSolderingWelding': 'Gaslödning och svetsning',
  'hotWorkPermit.hotWorkType.hotAirBlower': 'Användning av värmepistol',
  'hotWorkPermit.hotWorkType.lpgTorch': 'Användning av gasolbrännare',
  'hotWorkPermit.hotWorkType.otherHotWork': 'Annan, nämligen',
  'hotWorkPermit.hotWorkType': 'Heta arbeten',
  'hotWorkPermit.infoAboutExtinguisherPolicy.part1': 'Utfärdaren av tillstånd för heta arbeten specificerar nödvändig släckutrustning för arbetsplatsen för heta arbeten baserat på en undersökning och bedömning av risker som orsakas av heta arbeten. Vanligtvis ska minst två handbrandsläckare av klass 43A 183B C finnas tillgängliga. För heta arbeten som utförs på taket är detta obligatoriskt.',
  'hotWorkPermit.infoAboutExtinguisherPolicy.part2': 'Klasserna är minimikrav. I praktiken finns strängare företagsspecifika krav i branschen, till exempel kravet på klass 55A233BC.',
  'hotWorkPermit.infoAboutExtinguisherPolicy.part3': 'Försäkrade arbetsplatser kan också ha ytterligare säkerhetskrav enligt försäkringsbrevet.',
  'hotWorkPermit.maximumDurationExceeded': 'Tillståndets varaktighet överskrider den maximalt tillåtna varaktigheten ({0} dagar)',
  'hotWorkPermit.missingHotWorkGuards': 'Inga skydd för heta arbeten tillgängliga',
  'hotWorkPermit.missingSelectedEmployees': 'Inga medarbetare med kompetens för heta arbeten hittades för denna entreprenör',
  'hotWorkPermit.name': 'Namn',
  'hotWorkPermit.nameOfSiteBuildingDepartmentRegion': 'Företag/byggnad/avdelning/område',
  'hotWorkPermit.nameOfSiteBuildingDepartmentRegionPlaceHolder': 'Namn på företag, byggnad, avdelning eller/och plats',
  'hotWorkPermit.newPermit': 'Nytt tillstånd för heta arbeten',
  'hotWorkPermit.nextStep': 'Nästa steg',
  'hotWorkPermit.noActivePermits': 'Inga aktiva tillstånd för heta arbeten',
  'hotWorkPermit.noDangerOccurs': 'Ingen fara föreligger',
  'hotWorkPermit.noPermitsHaveBeenAdded': 'Inga tillstånd för heta arbeten har lagts till än.',
  'hotWorkPermit.ownerSignatureAndClarification': 'Ägarens underskrift och namnförtydligande',
  'hotWorkPermit.periodAdjustmentDisabled': 'Det går inte att justera varaktigheten för tillståndet för heta arbeten eftersom medarbetare och/eller vakt för heta arbeten har valts.',
  'hotWorkPermit.permitApproverHasCheckedValidationPeriod': 'Tillståndsgodkännaren har kontrollerat valideringsperioden för tillståndet för heta arbeten',
  'hotWorkPermit.permitCannotBeCreated': 'Tillstånd för heta arbeten kan inte skapas.',
  'hotWorkPermit.phoneNumber': 'Telefonnummer',
  'hotWorkPermit.plan': 'Plan för heta arbeten',
  'hotWorkPermit.roofPermit': 'Tillstånd för heta arbeten på tak',
  'hotWorkPermit.scopeId': 'Uppdragsgivare',
  'hotWorkPermit.securityMeasures.section.1': 'Arbetsplatsen för heta arbeten måste rengöras',
  'hotWorkPermit.securityMeasures.section.10': 'Gaskoncentrationen måste mätas och vid behov måste utrymmet ventileras.',
  'hotWorkPermit.securityMeasures.section.11': 'Omgivande områden måste skyddas.',
  'hotWorkPermit.securityMeasures.section.12': 'Övriga nödvändiga säkerhetsåtgärder:',
  'hotWorkPermit.securityMeasures.section.2': 'Eventuellt brännbart material som bildas under arbetet måste avlägsnas.',
  'hotWorkPermit.securityMeasures.section.3': 'Brandfarliga material måste avlägsnas från arbetsplatsen eller täckas över med en brandpresenning.',
  'hotWorkPermit.securityMeasures.section.4': 'Skyddskåpor måste placeras så att flammor, gnistor och stänk inte kan spridas.',
  'hotWorkPermit.securityMeasures.section.5': 'En separat täckkonstruktion måste byggas.',
  'hotWorkPermit.securityMeasures.section.6': 'Eventuella mellanrum och öppningar i väggar, tak och golv måste täckas över.',
  'hotWorkPermit.securityMeasures.section.7': 'Kablar, kabelrännor, maskiner, aggregat och andra brandfarliga konstruktioner etc. måste skyddas.',
  'hotWorkPermit.securityMeasures.section.8': 'Arbetsplatsen för heta arbeten måste vattnas.',
  'hotWorkPermit.securityMeasures.section.9': 'Föremålet för heta arbeten måste kylas kontinuerligt.',
  'hotWorkPermit.securityMeasures': 'Säkerhetsåtgärder',
  'hotWorkPermit.securityMeasuresNeeded': 'säkerhetsåtgärder behövs',
  'hotWorkPermit.selectCompanyFirst': 'Välj ett företag först',
  'hotWorkPermit.showPdf': 'Öppna PDF',
  'hotWorkPermit.signatureClearNameAndPhoneNumber': 'Signatur, namnförtydligande och telefonnummer',
  'hotWorkPermit.signatures': 'Signaturer',
  'hotWorkPermit.signedForm': 'Signerat formulär',
  'hotWorkPermit.site': 'Arbetsplats',
  'hotWorkPermit.subscriber': 'Arbetstagare, varaktighet, uppdragsgivare och godkännare',
  'hotWorkPermit.validDaily': 'Gäller dagligen',
  'hotWorkPermit.workers': 'Arbetstagare',
  'hotWorkPermit.workersAndDuration': 'Arbetstagare, varaktighet',
  'hotWorkPermit': 'Tillstånd för heta arbeten',
  'hotWorkPermits': 'Tillstånd för heta arbeten',
  'introduction.competences.addOther': 'Lägg till annan',
  'introduction.competences.addPopular': 'Lägg till populär kompetens',
  'introduction.competences.existing': 'Befintlig',
  'introduction.competences.failedToLoadCompetences': 'Det gick inte att läsa in kompetenser.',
  'introduction.competences.failedToLoadPopular': 'Kunde inte läsa in populära kompetenser.',
  'introduction.expertise': 'Kunskap',
  'invoiceHourRow.reject': 'Avvisa',
  'invoiceHours.activeInvoiceHours': 'Aktiva faktureringstimmar',
  'invoiceHours.alert1': 'Varning 1',
  'invoiceHours.alert1Short': 'Varn.1',
  'invoiceHours.alert2': 'Varning 2',
  'invoiceHours.alert2Short': 'Varn. 2',
  'invoiceHours.allPendingApproval': 'Alla väntande rader',
  'invoiceHours.approved': 'Godkänd',
  'invoiceHours.approvedOn': 'Godkänd {0}',
  'invoiceHours.approver': 'Godkännare',
  'invoiceHours.badgeTooltip.addedByEmployee': 'Tillagd av arbetstagare',
  'invoiceHours.badgeTooltip.approved': 'Godkänd',
  'invoiceHours.badgeTooltip.approvedSes': 'Godkänt SES-arbete',
  'invoiceHours.badgeTooltip.created': 'Väntar ännu på godkännande',
  'invoiceHours.badgeTooltip.invoiced': 'Godkänd och fakturerad',
  'invoiceHours.badgeTooltip.invoicedSes': 'Godkänd och SES-fakturerad',
  'invoiceHours.badgeTooltip.pending': 'Väntar på godkännande',
  'invoiceHours.badgeTooltip.rejected': 'Avvisad',
  'invoiceHours.buyersReference': 'Köparens referens',
  'invoiceHours.buyersReferenceNumber': 'Köparens referensnummer',
  'invoiceHours.changeApprover': 'Ändra godkännare',
  'invoiceHours.checkedInButNoInvoiceHours': 'Personen har incheckningar men inga faktureringstimmar.',
  'invoiceHours.checkInsWithoutInvoiceHours': 'Incheckningar utan faktureringstimmar',
  'invoiceHours.confirmYourRowDeletion': 'Är du säker på att du vill ta bort den här raden?',
  'invoiceHours.contractorNameAndInvoiceHourSummaryId': 'Entreprenörs- eller sammanfattnings-ID',
  'invoiceHours.contractorNameAndInvoiceHourSummaryIdAndInvoiceNumber': 'Entreprenör, sammanfattnings-ID eller fakturanummer',
  'invoiceHours.contractors': 'Entreprenörer',
  'invoiceHours.contractorsReference': 'Entreprenörens referens',
  'invoiceHours.contractorsSummaries': 'Entreprenörens sammanfattning per timme',
  'invoiceHours.copy': 'Kopiera',
  'invoiceHours.copyDay.cannotBeCopiedToFuture': 'Kan inte kopieras till framtida',
  'invoiceHours.copyDay.copyMultipleRows': 'Kopiera {0} rader',
  'invoiceHours.copyDay.copyOneRow': 'Kopiera 1 rad',
  'invoiceHours.copyDay.copyRowsFromAnotherDay': 'Kopiera rader från en annan dag',
  'invoiceHours.copyDay.futureError': 'Se till att alla markerade rader kan kopieras till framtiden.',
  'invoiceHours.copyDay.noRowsOnSelectedDay': 'Vald dag har inga rader.',
  'invoiceHours.copyDay.noSourceDataAvailable': 'Personen har inga tillämpliga rader tillgängliga.',
  'invoiceHours.copyDay.okCloseCopyDialog': 'Ok, stäng denna kopieringsdialog',
  'invoiceHours.copyDay.selectDayFirst': 'Välj en dag först.',
  'invoiceHours.copyDay.selectRowsToBeCopied': 'Välj rader som du vill kopiera',
  'invoiceHours.copyDay.targetDay': 'Målsättning, dag',
  'invoiceHours.copyDay.targetDays': 'Målsättning, dagar',
  'invoiceHours.copyDay.toMultipleDays': 'Kopiera till flera dagar',
  'invoiceHours.copyDay': 'Kopiera från dag',
  'invoiceHours.copyJobNumber.ownVariant': 'Kopiera jobbnummer',
  'invoiceHours.copyJobNumber': 'Kopiera jobbnummer',
  'invoiceHours.copyToGroup': 'Kopiera timmar till en grupp',
  'invoiceHours.created': 'Datum för skapande',
  'invoiceHours.currentWeekIs': 'Aktuell vecka är vecka {0}.',
  'invoiceHours.date': 'Datum',
  'invoiceHours.dates': 'Datum',
  'invoiceHours.deleteRowHeading': 'Ta bort rad?',
  'invoiceHours.deleteSelectionsBody': 'Är du säker på att du vill avvisa valen? Bekräfta genom att ange en orsak till avvisningen.',
  'invoiceHours.deleteSelectionsHeading': 'Avvisa val?',
  'invoiceHours.draftOnly': '<strong>Detta är ett utkast till sammanfattningen</strong>, inte den slutliga sammanfattningen.',
  'invoiceHours.employee.summary': 'Sammanfattning av faktureringstimmar',
  'invoiceHours.enableInvoiceHours': 'Aktivera faktureringstimmar',
  'invoiceHours.followingDaysHaveMoreHoursThanCheckIns': 'Du är på väg att kopiera {0} arbetstimmar. Enligt incheckningar ligger följande dagar under detta värde.',
  'invoiceHours.fullPerDiem': 'Fullt traktamente',
  'invoiceHours.grandTotalForJobNumber.ownVariant': 'Total tid för jobbnummer',
  'invoiceHours.grandTotalForJobNumber': 'Total tid för jobbnummer',
  'invoiceHours.grandTotalForJobNumberContract.ownVariant': 'Total tid för jobbnummer (avtal)',
  'invoiceHours.grandTotalForJobNumberContract': 'Total tid för jobbnummer (avtal)',
  'invoiceHours.grandTotalForJobNumberDescriptionContract.ownVariant': 'Totalt antal godkända faktureringstimmar från hela arbetsplatsens varaktighet per jobbnummer, godkännare och avtal',
  'invoiceHours.grandTotalForJobNumberDescriptionContract': 'Totalt antal godkända faktureringstimmar från hela arbetsplatsens varaktighet per jobbnummer, godkännare och avtal',
  'invoiceHours.grandTotalForJobNumberDescriptionEmployee.ownVariant': 'Totalt antal godkända faktureringstimmar från hela arbetsplatsens varaktighet per jobbnummer, godkännare och medarbetare',
  'invoiceHours.grandTotalForJobNumberDescriptionEmployee': 'Totalt antal godkända faktureringstimmar från hela arbetsplatsens varaktighet per jobbnummer, godkännare och medarbetare',
  'invoiceHours.grandTotalForJobNumberEmployee.ownVariant': 'Total tid för jobbnummer (medarbetare)',
  'invoiceHours.grandTotalForJobNumberEmployee': 'Total tid för jobbnummer (medarbetare)',
  'invoiceHours.group': 'Grupp',
  'invoiceHours.handler': 'Hanterare',
  'invoiceHours.hasEmployeesInvoiceHours': 'Faktureringstimmar som lagts till av arbetstagare och väntar på slutförande',
  'invoiceHours.hasEmployeesInvoiceHoursShort': 'Faktureringstimmar som väntar på slutförande',
  'invoiceHours.hourBank.balance': 'Saldo',
  'invoiceHours.hourBank': 'Timbank',
  'invoiceHours.hours': 'Timmar',
  'invoiceHours.hoursCheckedIn': 'Incheckade timmar',
  'invoiceHours.hoursOnAllSites': 'Timmar på alla arbetsplatser',
  'invoiceHours.hoursOnAllSitesDescription': 'Beräknas från alla arbetsplatser du har tillgång till.',
  'invoiceHours.hoursOnThisRow': 'Timmar på denna rad',
  'invoiceHours.idle': 'Tomgång',
  'invoiceHours.idleShort': 'Tomgång',
  'invoiceHours.includeSubContractors': 'Inkludera underentreprenörer',
  'invoiceHours.initContractApproval': 'Godkänn rader ...',
  'invoiceHours.invoicing': 'Fakturering',
  'invoiceHours.jobNumber.ownVariant': 'Jobbnummer',
  'invoiceHours.jobNumber': 'Jobbnummer',
  'invoiceHours.jobNumberTotals.ownVariant': 'Summor efter jobbnummer',
  'invoiceHours.jobNumberTotals': 'Summor efter jobbnummer',
  'invoiceHours.loadingCheckIns': 'Läser in incheckningar ...',
  'invoiceHours.lockAndCreate': 'Lås och skapa sammanfattning',
  'invoiceHours.lockedBecauseHoursFromOtherSummaries': 'Låsning förhindras eftersom sammanfattningen innehåller faktureringstimmar från andra sammanfattningar.',
  'invoiceHours.lockedBecausePendingHours': 'Låsning förhindras eftersom sammanfattningen innehåller faktureringstimmar som väntar på godkännande.',
  'invoiceHours.lockFailed': 'Låsningen misslyckades. Det har troligen funnits nya fakturor eller en ny förhandsgranskning för den valda tidsperioden.',
  'invoiceHours.locking': 'Låser ...',
  'invoiceHours.modifyRows': 'Redigera rader ...',
  'invoiceHours.moreHoursDecisions.copyHoursAccordingToCheckIns': 'Kopiera mängden arbete som visas i incheckningar',
  'invoiceHours.moreHoursDecisions.enterReasonForExtraHours': 'Ange orsak för timmar som överskrider incheckningar',
  'invoiceHours.moreHoursThanCheckIns': 'Du försöker kopiera fler timmar än vad som anges av incheckningarna',
  'invoiceHours.new': 'Nya faktureringstimmar',
  'invoiceHours.newRow': 'Ny rad',
  'invoiceHours.newSummary': 'Ny sammanfattning',
  'invoiceHours.newSummaryForInvoice': 'Sammanfattning av nya faktureringstimmar',
  'invoiceHours.noEmployeeRows': 'Inga fakturor ännu den här dagen',
  'invoiceHours.noInvoiceHours': 'Inga faktureringstimmar har lagts till ännu.',
  'invoiceHours.noLockedInvoiceHoursFound': 'Du begärde även timmar i andra sammanfattningar, men inga hittades med de angivna sökorden.',
  'invoiceHours.nonWorkDescription': 'Beskrivning',
  'invoiceHours.noOtherGroupMembersAvailable': 'Inga andra gruppmedlemmar tillgängliga',
  'invoiceHours.noPendingInvoiceHoursFound': 'Du begärde väntande faktureringstimmar, men inga hittades med de angivna filtren.',
  'invoiceHours.noPendingRows': 'Inga väntande rader.',
  'invoiceHours.noPerDiem': 'Inget traktamente',
  'invoiceHours.noPermits': 'Din sökning matchade inga medarbetare',
  'invoiceHours.noSitesWithInvoiceHours': 'Du har inte tillgång till arbetsplatser som har faktureringstimmar aktiverade.',
  'invoiceHours.noSummaries': 'Inga sammanfattningar',
  'invoiceHours.notEnabled': 'Faktureringstimmar ej aktiverade',
  'invoiceHours.onlyApprovablesForDate': 'Kan endast godkännas',
  'invoiceHours.onSummary': 'På sammanfattning',
  'invoiceHours.otherGroupMembers': 'Övriga gruppmedlemmar',
  'invoiceHours.otherTimeSpan': 'Annan tidpunkt',
  'invoiceHours.overtimeReason': 'Orsak till överskridande av incheckade timmar',
  'invoiceHours.own': 'Egen',
  'invoiceHours.partialPerDiem': 'Partiellt traktamente',
  'invoiceHours.payGrade': 'Ersättningsnivå',
  'invoiceHours.payGradeTotals': 'Ersättningsnivåer totalt',
  'invoiceHours.pend': 'Ta bort godkännande',
  'invoiceHours.pending': 'Väntar',
  'invoiceHours.pendQuestion': 'Ta bort godkännande?',
  'invoiceHours.perDiem': 'Traktamente',
  'invoiceHours.perDiemAbbr': 'Trakt.',
  'invoiceHours.permitNotActive': 'Tillträdestillståndet är inte aktivt, nya rader kan inte läggas till.',
  'invoiceHours.permitNotActiveInfo': 'Detta tillträdestillstånd är giltigt <strong>{0}</strong>. Om du vill lägga till faktureringstimmar eller resekostnader för den här dagen måste du ändra tillståndets giltighetstid eller skapa ett nytt tillstånd.',
  'invoiceHours.preview': 'Förhandsgranska',
  'invoiceHours.reasonForRejection': 'Orsak till avslag',
  'invoiceHours.reasonToReject': 'Orsak till avslag',
  'invoiceHours.receiver': 'Mottagare av sammanfattning',
  'invoiceHours.refreshPreview': 'Uppdatera förhandsgranskning',
  'invoiceHours.refreshWithoutOtherHours': 'Hämta information utan andra timmar från andra sammanfattningar',
  'invoiceHours.refreshWithoutPending': 'Uppdatera information utan information som väntar på godkännande',
  'invoiceHours.reject': 'Avvisa',
  'invoiceHours.rejected': 'Avvisad',
  'invoiceHours.rejecting': 'Avvisar ...',
  'invoiceHours.requestConfirmationForDeletion': 'Du håller på att ta bort faktureringstimmar från person <strong>{0}</strong>. Är du säker?',
  'invoiceHours.requestConfirmationForPending': 'Du tar bort godkännandet från faktureringstimmen för <strong>{0}</strong>. Är du säker på att du vill ta bort godkännandet?',
  'invoiceHours.requestProducedNoHours': 'Inga fakturor hittades med hjälp av sökfilter',
  'invoiceHours.rest': 'Vila',
  'invoiceHours.revision': 'Revision',
  'invoiceHours.rowsPendingApproval': 'Godkännande väntar på faktureringstimmar',
  'invoiceHours.salesOrderItem': 'Så rad',
  'invoiceHours.salesOrderNumber': 'Försäljningsorder',
  'invoiceHours.saveAndApprove': 'Spara och godkänn',
  'invoiceHours.searchOrSelectApproverPlaceholder': 'Godkännare',
  'invoiceHours.searchOrSelectCompanyPlaceholder': 'Företag',
  'invoiceHours.searchOrSelectContractPlaceholder': 'Avtal',
  'invoiceHours.searchOrSelectEmployeePlaceholder': 'Medarbetare',
  'invoiceHours.searchOrSelectGroupMembers': 'Övriga gruppmedlemmar',
  'invoiceHours.searchOrSelectJobNumberPlaceholder': 'Jobbnummer',
  'invoiceHours.searchOrSelectPayGradePlaceholder': 'Ersättningsnivå',
  'invoiceHours.searchOrSelectPersonPlaceholder': 'Person',
  'invoiceHours.searchOrSelectServicePlaceholder': 'Service',
  'invoiceHours.selectDay': 'Välj dag',
  'invoiceHours.selectReceivingCompanyPlaceholder': 'Välj mottagare av sammanfattning av faktureringstimmar',
  'invoiceHours.selectTimeSpan': 'Välj tidsperiod',
  'invoiceHours.sendAsPending': 'Skicka för godkännande',
  'invoiceHours.service': 'Service',
  'invoiceHours.serviceNotUsableForFuture': 'Ej tillåtet för framtida rader',
  'invoiceHours.serviceOrder.approved': 'Godkänd',
  'invoiceHours.serviceOrder.ordered': 'Beställd',
  'invoiceHours.serviceOrder.service': 'Service',
  'invoiceHours.serviceOrderTotal': 'Summa servicebeställning',
  'invoiceHours.showGrandTotalForJobNumber': 'Visa all total tid för jobbnummer',
  'invoiceHours.showLocked': 'Även andra sammanfattningar av faktureringstimmar',
  'invoiceHours.shownEmployees.all': 'Alla medarbetare',
  'invoiceHours.shownEmployees.own': 'Egna medarbetare',
  'invoiceHours.shownEmployees.subcontractor': 'Underentreprenörer',
  'invoiceHours.showPending': 'Även faktureringstimmar som väntar på godkännande',
  'invoiceHours.statusType.addedByEmployee': 'Tillagd av medarbetare',
  'invoiceHours.statusType.addedByEmployeeSingular': 'Tillagd av medarbetare',
  'invoiceHours.statusType.all': 'Alla faktureringstimmar',
  'invoiceHours.statusType.approved': 'Godkända faktureringstimmar',
  'invoiceHours.statusType.pending': 'Väntar på godkännande',
  'invoiceHours.summaries': 'Sammanfattningar av faktureringstimmar',
  'invoiceHours.summary': 'Sammanfattning',
  'invoiceHours.summaryIncludesLockedInvoiceHours': 'En persons sammanfattning innehåller timmar från andra sammanfattningar.',
  'invoiceHours.summaryIncludesPendingInvoiceHours': 'En persons sammanfattning inkluderar väntande faktureringstimmar.',
  'invoiceHours.tempSave': 'Spara endast, skicka inte',
  'invoiceHours.totalHours': 'Totalt antal timmar',
  'invoiceHours.travelTime': 'Restid',
  'invoiceHours.travelTimeShort': 'Res.',
  'invoiceHours.workDescription': 'Arbetsbeskrivning',
  'invoiceHours.workRecorded': 'Registrerat arbete',
  'invoiceHours': 'Faktureringstimmar',
  'jobNumber.mostUsed': 'Mest använda',
  'label._foremanChecked': 'Arbetsledare',
  'label.a1CertificateExpired.info': 'Socialförsäkringsintyget har gått ut. Utgångsdatumet kan påverka grindåtkomsten på denna arbetsplats.',
  'label.a1CertificateExpired': 'Socialförsäkringsintyget har gått ut',
  'label.a1CertificateExpiring': 'Socialförsäkringsintyget håller på att gå ut',
  'label.a1CertificatesExpiredOrExpiring': '<strong>{0} socialförsäkringsintyg</strong> har gått ut eller håller på att gå ut inom 14 dagar.',
  'label.accessArea': 'Tillträdesområde',
  'label.accessAreas': 'Tillträdesområden',
  'label.accessAreasSaved': 'Tillträdesområden sparade!',
  'label.accessPermit.workSpaces': 'Arbetsplatser',
  'label.accessPermit': 'Tillträdesbehörighet',
  'label.accessPermitCreator': 'Skapare av tillträdestillstånd',
  'label.accessPermitRejected': 'Avslutad',
  'label.accessPermits.contracts': 'Avtal',
  'label.accessPermits': 'Tillträdestillstånd',
  'label.accessPermitValid': 'Tillträdestillstånd giltigt',
  'label.accessPermitValidationPeriod': 'Nytt tillstånd börjar, slutar',
  'label.accidentInsurance': 'Olycksfallsförsäkring',
  'label.accidentInsuranceLegend': 'Vid avtal inom byggsektorn ska intyg om obligatorisk olycksfallsförsäkring tecknas',
  'label.accomplish': 'Genomförs',
  'label.accomplishedOnline': 'Genomförs online',
  'label.activated': 'Aktiverad',
  'label.activateReferences': 'Betalningsreferenser aktiva',
  'label.activeEmployees': 'Aktiv',
  'label.activeEmployeesDesc': 'Visa medarbetare som har incheckningar under angiven tidsperiod',
  'label.actualValue': 'Verkligt värde',
  'label.add': 'Lägg till',
  'label.addAccessPermit': 'Lägg till tillträdestillstånd',
  'label.addCheckInDevice': 'Lägg till en incheckningsenhet',
  'label.addComment': 'Lägg till kommentar',
  'label.addCommentOrReimbursment': 'Lägg till kommentar/ersättning',
  'label.addCommentToCheckIn': 'Lägg till kommentar för incheckning',
  'label.addCommentToCheckOut': 'Lägg till kommentar för utcheckning',
  'label.addCompany': 'Lägg till företag',
  'label.addCompetence': 'Lägg till kompetens',
  'label.addContract': 'Lägg till avtal',
  'label.addContractAndAccessPermit': 'Lägg till avtal och tillträdestillstånd',
  'label.addContractor': 'Lägg till entreprenör',
  'label.addContractorObligationDocuments': 'Lägg till entreprenörens åtagandehandlingar',
  'label.addDevice': 'Lägg till enhet',
  'label.addDeviceToSite': 'Lägg till enhet till arbetsplats',
  'label.added': 'Tillagd',
  'label.adding': 'Lägger till ...',
  'label.addNew': 'Lägg till ny',
  'label.addNewAccessPermit': 'Lägg till nytt tillträdestillstånd',
  'label.addNewCompany': 'Lägg till nytt företag',
  'label.addNewCompetence': 'Lägg till ny kompetens',
  'label.addNewContract': 'Lägg till nytt avtal',
  'label.addNewDevice': 'Lägg till ny enhet',
  'label.addNewPerson': 'Lägg till ny person',
  'label.addNewSite': 'Lägg till ny arbetsplats',
  'label.addNewUser': 'Lägg till ny användare',
  'label.addPerson': 'Lägg till person',
  'label.address': 'Adress',
  'label.addressInFinland': 'Adress i Finland',
  'label.addressInHomeCountry': 'Adress i hemlandet',
  'label.addSafetyQuarter': 'Lägg till nytt säkerhetsrum',
  'label.addSite': 'Lägg till arbetsplats',
  'label.addTag': 'Lägg till tagg',
  'label.addToVendorRegister': 'Lägg till företag',
  'label.addTraining': 'Lägg till utbildning',
  'label.addUser': 'Lägg till användare',
  'label.addUserGroup': 'Lägg till ny användarroll',
  'label.addValttiCard': 'Lägg till Valtti-kort',
  'label.addVehiclePermit': 'Lägg till fordonstillstånd',
  'label.admittance': 'Tillträde',
  'label.admittanceRequired': 'Person får beträda arbetsplatsen',
  'label.alarmLackingWeeklyHours': 'Larm för timmar per vecka',
  'label.all': 'Alla',
  'label.allForemen': 'Arbetsledare från alla arbetsplatser',
  'label.allHours': 'alla',
  'label.allocate': 'Tilldela',
  'label.allocatedToSite': 'Tillagd på arbetsplatsen',
  'label.allocateSelected': 'Tilldela valda',
  'label.allocating': 'Allokerar ...',
  'label.allowed': 'Tillåtet',
  'label.allRequiredCoursesExist': 'Alla obligatoriska kurser finns',
  'label.allRequiredFieldsValid': 'Obligatoriska fält är ifyllda',
  'label.allSites': 'Alla arbetsplatser',
  'label.allTaxReportsSent': 'Alla skatterapporter skickade',
  'label.amountShort': 'pcs',
  'label.approval.waiting': 'Väntar på godkännande',
  'label.approval': 'Godkännande',
  'label.approve': 'Godkänn',
  'label.approved': 'Godkänd',
  'label.approvedAccessPermitsFromTo': 'Godkända tillträdestillstånd från {0} till {1}',
  'label.approving': 'Godkänner ...',
  'label.archive': 'Arkiv',
  'label.archived': 'Arkiverad',
  'label.area': 'Område',
  'label.areaSaved': 'Område sparat!',
  'label.areYouSure': 'Är du säker?',
  'label.asDeveloper': 'som byggherre',
  'label.askAboutSubcontractEndDateChange': 'Vill du ändra slutdatum för underavtal under detta avtal? Detta alternativ kommer att uppdatera alla avtal som slutar {0}</strong>. Nytt slutdatum är {1}.',
  'label.asMainContractor': 'som huvudentreprenör',
  'label.asSubcontractor': 'som underentreprenör',
  'label.attachment': 'Bilaga',
  'label.attachments': 'Bilagor',
  'label.attachmentsAdded': 'Bilagor har lagts till',
  'label.atTime': 'vid',
  'label.automaticallySaveContractorLiabilityDocsEveryContractAnniversary': 'Uppdatera entreprenörers Tilaajavastuu-status och tillhörande rapport när arbetsplatsens avtal har jubileum samt arkivera gamla rapporter',
  'label.automaticTagListUpdate': 'Automatisk uppdatering av nya taggar till grindenheter',
  'label.automaticTagListUpdateShort': 'Taggar',
  'label.aviMissingTagsTooltip': 'Endast anställda som inte har taggar från huvudentreprenör eller byggherre eller ett Valtti-kort. Genom att välja det extra alternativet ”Endast egna taggar” visas endast medarbetare som inte har taggar som tilldelats av huvudentreprenören eller byggherren.',
  'label.aviReport': 'Inställningar för AVI-rapport',
  'label.aviReportShort': 'AVI',
  'label.aviTimespanTooltip': 'Rapporten inkluderar medarbetare som har tillträdestillstånd för minst en dag under den angivna tidsperioden.',
  'label.awaitingIntroduction': 'Väntar på introduktion',
  'label.backToDashBoard': 'Tillbaka till kontrollpanel',
  'label.basicInformation': 'Allmän information',
  'label.begins': 'Börjar',
  'label.begun': 'Börjat',
  'label.betaVersion': 'Betaversion',
  'label.birthdayBoyOnSite': 'En arbetsplats medarbetare har födelsedag idag eller imorgon',
  'label.birthdayBoysOnSite': '<strong>{0}</strong> medarbetare på arbetsplatsen har födelsedag idag eller imorgon',
  'label.birthdayToday': '<strong>{0}</strong>({1}) blir <strong>{2}</strong> idag!',
  'label.birthdayTomorrow': '<strong>{0}</strong>({1}) blir <strong>{2}</strong> imorgon!',
  'label.blocksReportsToTaxAdmin': 'Spärrar rapporter till Skatteverket',
  'label.both': 'Båda',
  'label.buyer': 'Köpare',
  'label.buyerContactPerson': 'Köparens kontaktperson',
  'label.call': 'Ring',
  'label.cancel': 'Avbryt',
  'label.car': 'Bil',
  'label.card': 'Kort',
  'label.cardId': 'Arbetsplatskarta',
  'label.cardIds': 'Arbetsplatskartor',
  'label.carShort': 'Bil',
  'label.certificates': 'certifikat',
  'label.change': 'Ändra',
  'label.checkedIn': 'På arbetsplats',
  'label.checkedInDesc': 'Visa endast medarbetare som är incheckade nu',
  'label.checkedOnDate': 'Kontrollerad den {0}',
  'label.checkedOutNextDay': 'Utcheckad nästa dag',
  'label.checkIn': 'Incheckning',
  'label.checkInAlreadyExists': 'Du kan inte skapa två överlappande incheckningar för samma minut. Vänta en minut och försök igen.',
  'label.checkInAlreadyExists2': 'Du kan inte skapa två överlappande incheckningar för samma minut.',
  'label.checkInDevice': 'Incheckningsenhet',
  'label.checkInDeviceInfo': 'Information för incheckningsenhet',
  'label.checkInDevices': 'Incheckningsenheter',
  'label.checkInIsModified': 'Denna incheckning har ändrats',
  'label.checkInOutsideSite': 'Det gick inte att verifiera att den mobila incheckningen skedde på arbetsplatsens område.',
  'label.checkIns': 'Incheckningar',
  'label.checkOutForgottenHeading': 'Glömde du att checka ut?',
  'label.checkOutIsModified': 'Denna utcheckning har ändrats',
  'label.chooseFile': 'Välj en fil',
  'label.chooseForemen': 'Välj arbetsledare',
  'label.chooseFromRegister': 'Välj från register',
  'label.chooseLanguage': 'Välj språk ...',
  'label.chooseSiteForEvaluation': 'Välj den arbetsplats som utvärderingen är kopplad till',
  'label.city': 'Ort',
  'label.clear': 'Rensa',
  'label.close': 'Stäng',
  'label.closeWithoutSaving': 'Stäng utan att spara',
  'label.clothingSize': 'Klädstorlek',
  'label.collectiveLaborAgreement': 'Kollektivavtal',
  'label.columnHeadingBirthDate': 'Födelsedata',
  'label.columnHeadingBusinessId': 'Företags-ID',
  'label.columnHeadingCardNumber': 'Kortnummer',
  'label.columnHeadingCity': 'Ort',
  'label.columnHeadingCompetenceCardNumber': 'Kortnummer',
  'label.columnHeadingContact': 'Kontaktperson',
  'label.columnHeadingContractor': 'Entreprenör',
  'label.columnHeadingContractorHomeCountry': 'Arbetsgivarens hemland',
  'label.columnHeadingCountry': 'Land',
  'label.columnHeadingDateShort': 'Datum',
  'label.columnHeadingEmail': 'E-post',
  'label.columnHeadingEmployer': 'Arbetsgivare',
  'label.columnHeadingIdConfirmation': 'ID bekräftat',
  'label.columnHeadingIntroduced': 'Orienterad',
  'label.columnHeadingName': 'Namn',
  'label.columnHeadingNationality': 'Nat.',
  'label.columnHeadingPaymentNote': 'Anmärkning',
  'label.columnHeadingPaymentType': 'Typ',
  'label.columnHeadingPostcode': 'Postnummer',
  'label.columnHeadingRelation': 'Arbetsrelation',
  'label.columnHeadingRep': 'Representant',
  'label.columnHeadingRepCity': 'Representantens stad',
  'label.columnHeadingRepEmail': 'Representantens e-postadress',
  'label.columnHeadingRepFirstName': 'Representantens förnamn',
  'label.columnHeadingRepLastName': 'Representantens efternamn',
  'label.columnHeadingRepPostcode': 'Representantens postnummer',
  'label.columnHeadingRepStreet': 'Representantens adress',
  'label.columnHeadingRepTelephone': 'Representantens telefon',
  'label.columnHeadingResidencePermitExpires': 'Uppehållstillståndet går ut',
  'label.columnHeadingSsn': 'SSN',
  'label.columnHeadingStreet': 'Gata',
  'label.columnHeadingTaxNumber': 'Skattenummer',
  'label.columnHeadingTelephone': 'Telefon',
  'label.columnHeadingValue': 'Värde',
  'label.columnHeadingVehiclePermitApprover': 'Godkännare',
  'label.columnHeadingVehiclePermitStatus': 'Accepterad',
  'label.commaSeparated': 'Kommaseparerad',
  'label.comment': 'Kommentarer',
  'label.commentOrReimbursment': 'Kommentar/ersättning',
  'label.commitmentLink': 'Åtagande {0} (PDF)',
  'label.companies': 'Företag',
  'label.companiesLackingLiabilityInsurance': '<strong>{0} företag</strong> saknar giltig ansvarsförsäkringsinformation i rapporten Tillaajavastuu Reliable Partner. <b>Om företagets ansvarsförsäkring är giltig kan en representant för företaget uppdatera informationen i Reliable Partner-rapporten <a href="https://kampanja.tilaajavastuu.fi/vastuuvakuutus" target="_blank">här.</a></b>',
  'label.companiesWithIncompleteInformation': '<strong>{0} företag</strong> saknar viss information.',
  'label.companiesWithInvalidContractorLiability': '<strong>{0}</strong> företag har ogiltig information om entreprenörens skyldigheter.',
  'label.company': 'Företag',
  'label.companyExtraInfo': 'Visa företagsinformation',
  'label.companyHasntBeenEvaluated': 'Företaget har ännu inte utvärderats.',
  'label.companyHealth': 'Företagshälsovård',
  'label.companyHealthProvider': 'Företagshälsovård',
  'label.companyHealthProviderLegend': 'Redogörelse för hur hälso- och sjukvård tillhandahålls inom företaget (krävs: hälso- och sjukvårdsleverantör i Finland)',
  'label.companyInfo': 'Företagsfakta',
  'label.companyLackingLiabilityInsurance': 'Företaget <strong>{0}</strong> saknar giltig ansvarsförsäkringsinformation på <a href="/integrations/vastuugroup/report/contractorLiabilityPdf/{1}" target="_blank">Tilaajavastuu Reliable Partner-rapport</a>. <b>Om företagets ansvarsförsäkring är giltig kan en representant för företaget uppdatera informationen i Reliable Partner-rapporten <a href="https://kampanja.tilaajavastuu.fi/vastuuvakuutus" target="_blank">här.</a></b>',
  'label.companyLackingLiabilityInsuranceVGServiceNotEnabled': 'Företaget <strong>{0}</strong> saknar giltig ansvarsförsäkringsinformation på Tilaajavastuu Reliable Partner-rapport. <b>Om företagets ansvarsförsäkring är giltig kan en representant för företaget uppdatera informationen i Reliable Partner-rapporten <a href="https://kampanja.tilaajavastuu.fi/vastuuvakuutus" target="_blank">här.</a></b>',
  'label.companyName': 'Företagsnamn',
  'label.companyRole': '{0} roll',
  'label.companySearch': 'Företagssökning',
  'label.companySpecificInfo': 'Företagsinformation',
  'label.companySpecificInfoHelp': 'Företagsspecifik information är endast synlig för användare av företaget {0}.',
  'label.companyUnit': 'Enhet',
  'label.companyWithIncompleteInformation': 'Viss information saknas för företaget <strong>{0}</strong>.',
  'label.companyWithInvalidContractorLiability': 'Information om entreprenörens skyldigheter på <strong>{0}</strong> är ogiltig.',
  'label.competence': 'Kompetens',
  'label.competenceExpired': 'Kompetensen har gått ut',
  'label.competenceExpiring': 'Kompetensen håller på att gå ut',
  'label.competenceLoadError': 'Fel vid inläsning av kompetensdetaljer.',
  'label.competenceModelRemoved': 'Kurstyp borttagen',
  'label.competenceModelRemovedShort': 'Kurstypen används inte längre',
  'label.competencePendingAccomplishment': 'Väntar på resultat',
  'label.competenceReport.active': 'Aktiv',
  'label.competenceReport.expired': 'Förfallen',
  'label.competenceReport.expiring': 'Går ut',
  'label.competenceReport.noCompetence': 'Ingen giltig vald kompetens',
  'label.competenceReportInfo': 'Skapar en Excel-fil som innehåller medarbetares kompetenser med angivna kriterier.',
  'label.competenceReportInfo2': 'Om du väljer utgångsdatum visas alla kompetenser som löper ut om 31 dagar. Om du vill lista kompetenserna för varje medarbetare på ditt företag kan du skapa en virtuell arbetsplats. Du kan sedan lägga till dina medarbetare på den arbetsplatsen.',
  'label.competences': 'Kompetensområden',
  'label.competencesExpiredOrExpiring': '<strong>{0} kompetenser</strong> har gått ut eller håller på att gå ut inom 30 dagar.',
  'label.competencesLoadError': 'Fel vid inläsning av kompetenser.',
  'label.completed': 'Slutförd',
  'label.confirm': 'Bekräfta',
  'label.confirmation': 'Verifikation',
  'label.confirmDeletion': 'Bekräfta borttagning',
  'label.confirmEnd': 'Bekräfta avslut',
  'label.confirming': 'Bekräftar ...',
  'label.confirmRemovalFromSite': 'Bekräfta borttagning',
  'label.confirmRemove': 'Bekräfta borttagning',
  'label.constructor': 'Byggarbetare',
  'label.contactForTaxAdmin': 'Kontaktperson för rapportering till Skatteverket',
  'label.contactForTaxAdminTooltip': 'Ansvarig för att skicka månadsrapporter till Skatteverket',
  'label.contactInfo': 'Kontaktuppgift',
  'label.contactInformation': 'Kontaktuppgift',
  'label.contactPerson': 'Kontaktperson',
  'label.contactPersonInCompany': 'Kontaktperson i företaget {0}',
  'label.continue': 'Fortsätt',
  'label.continueEditing': 'Fortsätt redigeringen',
  'label.continueToPermitAdd': 'Fortsätt att lägga till tillstånd',
  'label.contract': 'Avtal',
  'label.contractAndEmployeeDeclarations': 'Rapport om avtal och medarbetare',
  'label.contractChangeWarning': 'Personincheckningar kommer att flyttas till den nya arbetsgivaren. Lämna detta tillträdestillstånd oförändrat vid sammanslagning och skapa nytt tillträdestillstånd för den nya arbetsgivaren.',
  'label.contractContactPerson': 'Avtalskontakter',
  'label.contractContacts': 'Sök efter avtalskontakter',
  'label.contractContactInfoShort': 'Den angivna kontaktpersonen är synlig, till exempel för entreprenören, men ger inte personen åtkomst till er miljö.',
  'label.contractContactInfoWithLink': 'Den angivna kontaktpersonen är synlig, till exempel för entreprenören, men ger inte personen åtkomst till er miljö. För att lägga till användare, gå till',
  'label.contractContactInfoLinkAnchor': 'Hantera &rarr; Användare',
  'label.contractCount': 'avtal',
  'label.contractDeclarations': 'Rapport om avtalsdetaljer',
  'label.contractDetails': 'Avtalsinformation',
  'label.contractForeman': 'Förste arbetsledare',
  'label.contractInformation': 'Avtalsinformation',
  'label.contractingType': 'Avtalstyp',
  'label.contractingTypeConstruction': 'Byggnation eller underhåll',
  'label.contractingTypeDevelopment': 'Fastighetsutveckling',
  'label.contractingTypeHire': 'Hyr',
  'label.contractingTypeTooltip': 'Typer ges från Skatteverket. För konsultarbeten används ”Byggnation eller underhåll”.',
  'label.contractKey': 'Avtalsnyckel',
  'label.contractManagedLabel': 'Åtkomsträttigheter till entreprenör och kontaktperson',
  'label.contractManagedLabelMultipleContacts': 'Åtkomsträttigheter till entreprenör och kontaktpersoner',
  'label.contractNumber': 'Avtalsnummer',
  'label.contractNumberTooltip': 'Ett nummer eller annan typ av identifierare för detta avtal som används inom ditt företag',
  'label.contractor': 'Entreprenör',
  'label.contractorEmployees': 'Entreprenörs medarbetare',
  'label.contractorIntroduces': 'Entreprenörer kan orientera medarbetare självständigt',
  'label.contractorIntroductionWarning': '<strong>Obs!</strong> Denna funktion är permanent när den har sparats.',
  'label.contractorLiability.search': 'Leverantörsansvarssökning',
  'label.contractorLiability': 'Entreprenörens åtaganden',
  'label.companyCheckFailed': 'Hämtningen av företagets uppgifter misslyckades.',
  'label.contractorLiabilityErrors': 'Fel i entreprenörens åtaganden',
  'label.contractorLiabilityExpired': 'Entreprenörens åtagandehandlingar har gått ut',
  'label.contractorLiabilityMoreInfo': 'https://support.vastuugroup.fi/hc/en-gb/articles/115005569269-The-symbols-on-the-Reliable-Partner-report',
  'label.contractorLiabilityNotFound': 'Hittades inte',
  'label.contractorLiabilityOk': 'Ok',
  'label.contractorLiabilityReport': 'Rapport om entreprenörens åtaganden',
  'label.contractorObligationReport': 'Entreprenörens åtaganderapport',
  'label.contractorObligationReportShort': 'Entreprenörens skyldigheter',
  'label.contractorReport.contractors': 'Entreprenörer',
  'label.contractorReport.showOnlyActive': 'Visa endast aktiva entreprenörer',
  'label.contractorReport': 'Rapportera entreprenör',
  'label.contractorReportInfo': 'Visar entreprenörer på den aktuella arbetsplatsen eller på alla arbetsplatser.',
  'label.contractorReportShort': 'Entreprenör',
  'label.contractors': 'Entreprenörer',
  'label.contractorSearch': 'Entreprenörssökning',
  'label.contractorShort': 'Entrepr.',
  'label.contractorSubcontracts': 'Entreprenörer kan skapa underavtal',
  'label.contractorsWithoutForeman': '<strong>{0}</strong> entreprenörer har ingen arbetsledare med tillträdestillstånd.',
  'label.contractorsWithoutForemanInfo': 'Arbetsledare bestäms genom sitt tillträdestillstånd.',
  'label.contractPartner': 'Avtalspartner',
  'label.contracts': 'Avtal',
  'label.contractsCount': 'avtal',
  'label.contractStatus': 'Avtalsstatus',
  'label.contractsWithAwaitingDocuments': 'Entreprenörens åtagandehandlingar väntar på godkännande',
  'label.contractTree': 'Avtalsträd',
  'label.contractType': 'Avtalstyp',
  'label.contractWork': 'Avtal',
  'label.copied': 'Kopierad',
  'label.copy': 'Kopiera',
  'label.copyAccessPermits': 'Kopiera tillträdestillstånd för alla personer',
  'label.copyContractsAndPersons': 'Kopiera avtal och personer',
  'label.copyContractTreeAll': 'Kopiera hela avtalsträdet',
  'label.copyContractTreeDeveloperAndMC': 'Kopiera endast köparen och huvudentreprenören',
  'label.copyContractTreeInfoText': 'Entreprenörens åtagandehandlingar för samtliga avtal som ska kopieras måste vara i ordning. Detta kan göras genom Trusted Partner-programmet eller genom att lägga till Entreprenörens åtagandehandlingar med utgångsdatum.',
  'label.copyContractTreeInvalidLiabilityStates': 'Entreprenörens åtagandehandlingar är inte i ordning för följande entreprenader:',
  'label.copyContractTreeLiabilityStatusCheckFailed': 'Det gick inte att verifiera information om prenumerantansvar. Var god försök igen. Om problemet kvarstår, kontakta support (support@zeroni.fi).',
  'label.copyContractTreeLiabilityStatusCheckRunning': 'Verifierar entreprenörens åtaganden ...',
  'label.copyContractTreeLiabilityStatusReCheck': 'Försök igen',
  'label.copyFromSite': 'Kopiera från arbetsplats ',
  'label.copying': 'Kopierar ...',
  'label.copySite': 'Kopiera arbetsplats',
  'label.copySourceSite': 'Kopierar arbetsplats',
  'label.count': 'Antal',
  'label.country': 'Land',
  'label.createAccessPermit': 'Skapa tillträdestillstånd från denna incheckning',
  'label.createContractAndAccessPermitFromUnresolvedCheckIn': 'Skapa avtal och tillträdestillstånd från denna incheckning',
  'label.createIntroductionForm': 'Skapa introduktionsformulär',
  'label.createSubContract': 'Skapa underleverantörsavtal',
  'label.creating': 'Skapar ...',
  'label.currentCountry': 'Normalt anställningsland',
  'label.daily': 'Dagbok',
  'label.dailyReport': 'Daglig byggrapport',
  'label.dashboard': 'Kontrollpanel',
  'label.date': 'Datum',
  'label.dateFormat_ddmmyyyy': 'dd.mm.åååå',
  'label.dateFormat_mmyyyy': 'mm/åååå',
  'label.dateOfBirth': 'Födelsedatum',
  'label.dateOfBirthShort': 'Födelsedatum',
  'label.dateShort': 'Datum',
  'label.daysAbbreviation': 'dagar',
  'label.ddmmyyyy': 'dd.mm.åååå',
  'label.declarationID': 'Rapport-ID',
  'label.default': 'Standard',
  'label.defaultRoles': 'standardroller.',
  'label.delete': 'Ta bort',
  'label.deleteDeviceHeading': 'Ta bort <strong>{0}</strong>',
  'label.deleteUserGroup': 'Radera användarroll',
  'label.deleting': 'Raderar ...',
  'label.department': 'Avdelning',
  'label.departmentFilterPlaceholder': 'Filtrera efter avdelning',
  'label.departmentPlaceholder': 'Välj den avdelning som arbetsplatsen tillhör',
  'label.developer': 'Byggherre',
  'label.developerAddress': 'Byggherres adress',
  'label.developerContactPhoneNumber': 'Telefonnummer till kontaktpersoner för byggherrar',
  'label.developerContractManagedLabel': 'Åtkomsträttigheter till byggherre och kontaktperson',
  'label.developerContractManagedLabelMultipleContacts': 'Åtkomsträttigheter till byggherre och kontaktpersoner',
  'label.device': 'Enhet',
  'label.devices': 'Enheter',
  'label.digiPenForm': 'Digi Pen-formulär',
  'label.digiPenForms': 'Digi Pen-formulär',
  'label.digiPenHasComment': 'Inkl. anteckningar',
  'label.disallowed': 'Inte tillåtet',
  'label.documentCopy': 'Foto av handlingen',
  'label.documents': 'Handlingar',
  'label.documentsCanBeSeenByContractors': 'Handlingarna kan ses av entreprenörer.',
  'label.domainContext': 'Arbetsplatstyp och plats',
  'label.doNotAllocate': 'Tilldela inte',
  'label.doNotChange': 'Ändra inte',
  'label.doNotExtend': 'Förläng inte',
  'label.dontShowAgain': 'Visa inte detta igen',
  'label.download': 'Ladda ner',
  'label.downloadCsv': 'Ladda ner CSV',
  'label.downloadExcel': 'Ladda ner Excel',
  'label.downloadFile': 'Ladda ner fil',
  'label.downloadNFCReader': 'Ladda ner NFCReader',
  'label.dragHere': 'Dra och släpp',
  'label.driversLicense': 'Körkort',
  'label.drugTest': 'Senaste drogtest',
  'label.dueDate': 'Förfallodatum',
  'label.edit': 'Redigera',
  'label.edited': 'Redigerad',
  'label.editingRights': 'Redigera',
  'label.editSiteAccessArea': 'Redigera arbetsplatsens tillträdesområde',
  'label.editVehiclePermit': 'Redigera fordonstillstånd',
  'label.eePersonalCode': 'Estniskt personnummer',
  'label.electronicIntroductionError': 'Orienterad online men handlingen (PDF) saknas. Skapa en ny handling genom att klicka här.',
  'label.electronicIntroductionErrorInfo': 'PDF-handlingen kunde inte genereras på grund av ett nätverksfel. Applikationen kunde inte hantera felet korrekt och därför sparades ingen handling. Du kan skapa en ny introduktionshandling genom att klicka på länken nedan. Orsaken till felet har åtgärdats den 4 juli 2019.',
  'label.electronicIntroductionLink': 'Onlinebekräftelse av introduktion {0} (PDF)',
  'label.email': 'E-post',
  'label.emailAddress': 'E-postadress',
  'label.emailNotification': 'E-postmeddelande om rapporten',
  'label.emailNotifications': 'E-postmeddelanden',
  'label.emailNotificationsLong': 'Meddela avtalskontaktperson om tillagd hanteringsrättighet via e-post.',
  'label.emailRequiredHint': 'Vad är syftet med e-postmeddelandet?',
  'label.employed': 'Anställd',
  'label.employee': 'Medarbetare',
  'label.employeeDeclarations': 'Medarbetarinformation',
  'label.employeeInfo': 'Personuppgift',
  'label.employeeReport': 'Personrapport',
  'label.employeeReportInfo': 'Skapar en rapport som listar alla medarbetare eller besökare som matchar de angivna kriterierna. Inställningar för AVI-rapport – kryssrutan väljer automatiskt rätt inställningar för AVI-rapporten.',
  'label.employeeReportPlain': 'Personrapport',
  'label.employeeReportShort': 'Person',
  'label.employees': 'Medarbetare',
  'label.employeeSearch': 'Sök medarbetare',
  'label.employeesWithIncompleteInformation': '<strong>{0}</strong> medarbetare saknar viss information.',
  'label.employeesWithNoPermits': 'Medarbetare utan tillträdestillstånd',
  'label.employeeWithIncompleteInformation': '<strong>{0}</strong> saknar viss information.',
  'label.employeeWithInvalidTaxNumber': 'Person <strong>{0}</strong> har ett ogiltigt skattenummer.',
  'label.employer': 'Arbetsgivare',
  'label.employerRegister': 'Arbetsgivarregister',
  'label.employerRegisterLegend': 'Redogörelse för om företaget är registrerat i arbetsgivarregistret',
  'label.emptyOrientationFormLink': 'Tomt introduktionsformulär (PDF)',
  'label.endAction': 'Slutför',
  'label.endDateAbbrev': 'Avslutas',
  'label.ended.alt': 'Avslutad',
  'label.ended': 'Avslutad',
  'label.endedPlural': 'Avslutad',
  'label.endMeasurement': 'Avsluta mätning',
  'label.ends': 'Avslutas',
  'label.endTask': 'Avsluta <strong>{0}</strong>',
  'label.enterSickLeaveReason': 'Ange orsak till sjukfrånvaro',
  'label.enterUsernameFirst': 'Ange användarnamn först',
  'label.entrepreneur': 'Entreprenör',
  'label.error': 'Fel',
  'label.ersInvoiceNumber': 'ERS-fakturanummer',
  'label.estimatedValue': 'Uppskattat värde',
  'label.estimatedWeeklyHours': 'Uppskattade timmar per vecka',
  'label.estimatedWorkingPeriod': 'Beräknad arbetstid',
  'label.estonianBusinessId': 'Estnisk företags-ID',
  'label.evaluate': 'Utvärdera',
  'label.evaluateLabor': 'Arbetsutvärdering',
  'label.evaluateMaterials': 'Materialbedömning',
  'label.evaluation': 'Utvärdering',
  'label.evaluationAverage': 'Utvärderingsgenomsnitt',
  'label.evaluationNotes': 'Utvärderingsanteckningar',
  'label.evaluationReport': 'Utvärderingsrapport',
  'label.evaluationReportShort': 'Utvärdering',
  'label.evaluations': 'Utvärderingar',
  'label.evaluationsReport': 'Utvärdering',
  'label.excelReportSiteInfo.contactPerson': 'Kontaktperson:',
  'label.excelReportSiteInfo.developer': 'Byggherre:',
  'label.excelReportSiteInfo.email': 'E-post:',
  'label.excelReportSiteInfo.mainContractor': 'Huvudentreprenör:',
  'label.excelReportSiteInfo.siteAddress': 'Arbetsplatsens adress:',
  'label.excelReportSiteInfo.siteForeman': 'Zeroni:',
  'label.excelReportSiteInfo.sitePeriod': 'Börjar/slutar:',
  'label.excelReportSiteInfo.telephone': 'Telefon nummer:',
  'label.execelReportDoesNotInclude': 'Ingår inte i Excel-rapporten',
  'label.exempted': 'Undantagna',
  'label.expat': 'Utstationerad medarbetare',
  'label.expatCompany': 'Arbetsgivare',
  'label.expatRepresentative': 'Representant för denna utlandsarbetare',
  'label.expatRepresentativeMany': 'Representant för utlandsarbetare',
  'label.expats': 'Utstationerade arbetstagare',
  'label.expatSocialSecurityDoc': 'Socialförsäkringsintyg (t.ex. A1)',
  'label.expatSocialSecurityDocExpirationDate': 'Socialförsäkringsintyget går ut',
  'label.expatSocialSecurityDocHelp': 'Se till att utländska medarbetare har ett certifikat A1 eller något annan motsvarande handling (tidigare E101).',
  'label.expatSocialSecurityDocShort': 'Socialförsäkring',
  'label.expatTooltip': 'Arbetsgivarens officiella representant i Finland',
  'label.expenseReimbursment': 'Kostnadsersättning',
  'label.expertise': 'Kunskap',
  'label.expired': 'Förfallen',
  'label.expiredOn': 'Utgången den {0}',
  'label.expires': 'Utgår',
  'label.expiringOn': 'Utgår den {0}',
  'label.exportToExcel': 'Exportera till Excel',
  'label.failed': 'Misslyckades',
  'label.failedToFetchValttiCardImage': 'Valtti-kortbilden kunde inte läsas in. Utan fotot kan kortet tyvärr inte användas istället för ett arbetsplatskort. Vänligen försök igen lite senare. ',
  'label.failedToFetchValttiCardImageDueToCardBeingClosed': 'Valtti-kortbilden kunde inte läsas in eftersom kortet inte längre är aktivt. Utan fotot kan kortet tyvärr inte användas istället för ett arbetsplatskort. Vänligen försök igen lite senare. ',
  'label.failedToLoadPreviousValttiCardImage': 'Bilden kunde inte läsas in från Zeroni. Ladda upp en bild.',
  'label.failedVisyPermitIntegrations': 'Det finns ett problem för <strong>{0}</strong> tillstånd med att skicka tillstånd till VisyGate. Sändningen avslutades med ett fel för <strong>{1}</strong> tillstånd.',
  'label.failedVisyPermitIntegrationStatus.FAILED': 'Fel',
  'label.failedVisyPermitIntegrationStatus.RETRYING': 'Behandlar',
  'label.files.removed': 'Filerna har tagits bort.',
  'label.files': 'Filer',
  'label.filter.showSubContractors': 'Visa även underentreprenörens medarbetare',
  'label.filter.visitors': 'Besökare',
  'label.findExistingPersonWithIdentifierCallFailed': 'Identifieraren kunde inte verifieras. Skicka inaktiverat.',
  'label.findingNameSuggestion': 'Söker efter namn ...',
  'label.finished': 'Avslutad',
  'label.finnishBusinessId': 'Finn. företags-ID',
  'label.firstName': 'Förnamn',
  'label.firstNames': 'Förnamn',
  'label.fix': 'Fixera',
  'label.foreignBusinessId': 'Utländskt registreringsnummer',
  'label.foreignBusinessIdLabel': 'Utländskt företags-ID',
  'label.foreignerRepresentative': 'Representant för utlänningar',
  'label.foreignSsn': 'Utländskt SSN',
  'label.foreman': 'Arbetsledare',
  'label.foremanContactInfo': 'Arbetsledarens kontaktuppgifter',
  'label.foremen': 'Arbetsledare',
  'label.foremenOnly': 'Visa endast arbetsledare',
  'label.found': 'hittades',
  'label.freeEvaluation': 'Utvärdering',
  'label.fridayShort': 'fr',
  'label.fromSiteStartToEnd': 'Från början till slut på arbetsplatsen',
  'label.furtherInformation': 'Övrig information',
  'label.furtherInformation2': 'Mer information',
  'label.gate': 'Grind',
  'label.gateIntegration': 'Grind',
  'label.gateType': 'Typ av grind',
  'label.general': 'Allmän',
  'label.generalRating': 'Betyg',
  'label.generalSettings': 'Allmänna inställningar',
  'label.genericBusinessId': 'Företags-ID',
  'label.geolocationFailed': 'Det gick inte att hämta din plats. Ett meddelande kommer att läggas till i dina incheckningar.',
  'label.getCheckInsOuts': 'Hämta incheckningar',
  'label.getDataFromValtti': 'Läs information från Valtti-kort',
  'label.getting': 'Laddar ...',
  'label.giveBusinessId': 'Ange företags-ID först',
  'label.goBack': 'Tillbaka',
  'label.gotIt': 'Ok',
  'label.goToLiabilityDocuments': 'Gå till ansvarshandling',
  'label.grade': 'Grad',
  'label.groupByContractor': 'Gruppera efter entreprenör',
  'label.handle': 'Process',
  'label.handled': 'Åtgärdad',
  'label.handlingFailed': 'Åtgärden misslyckades',
  'label.healthCard': 'Sjukjournal',
  'label.help': 'Hjälp',
  'label.hereIsListedOnlyTrustedPartners': 'Detta meddelande kommer endast från företag som ingår i programmet Luotettava kumppani.',
  'label.hide': 'Dölj',
  'label.hideAll': 'Dölj alla',
  'label.hideEnded': 'Dölj avslutade',
  'label.hidePreviousCLReports': 'Dölj tidigare leverantörsansvarsrapporter',
  'label.hideSiteMainContracts': 'Dölj huvudavtal',
  'label.hire': 'Anställd',
  'label.homeCountry': 'Hemland',
  'label.hotWorkForm': 'Formulär för heta arbeten',
  'label.hotWorkPermitApproverSignature': 'Godkännarens underskrift',
  'label.hourBank': 'Timbank',
  'label.hourBankReport.heading': 'Timbanksrapport',
  'label.hourBankReport.info': 'Visar arbetstidsbankens status för varje medarbetare: lagrade, använda och tillgängliga timmar. Timmar hämtas från alla avtal där entreprenörens roll är att godkänna timmar.',
  'label.hours': 'Timmar',
  'label.hoursAbbreviation': 't',
  'label.id': 'ID',
  'label.idCard': 'ID-kort',
  'label.idCardNumber': 'ID-fordonsnummer',
  'label.idConfirmation': 'ID-verifiering',
  'label.identifiers': 'Identifierare',
  'label.idNumber': 'ID-nummer',
  'label.iKnowWhatImDoing': 'Jag förstår och vet vad jag gör',
  'label.in': 'Ot',
  'label.inadequate': 'Otillräcklig',
  'label.includeInvoiceHoursAddedByEmployees': 'Visa även ofullständiga faktureringstimmar som lagts till av anställda.',
  'label.includeRemovedPermits': 'Visa borttagna',
  'label.inEnglish': 'På engelska',
  'label.inFinnish': 'På finska',
  'label.inOut': 'In+ut',
  'label.instructorSignature': 'Arbetsledarens underskrift',
  'label.interruptContract': 'Avbryt',
  'label.interruptContractHeading': 'Avbryt avtal {0}',
  'label.interrupted': 'Avbruten',
  'label.introduce': 'Bekräfta introduktion',
  'label.introduction': 'Bekräfta introduktion',
  'label.introductionDate': 'Bekräftelsedatum',
  'label.introductionFormLabel': 'Introduktionsformulär',
  'label.introductionFormLink': 'Introduktionsformulär (PDF)',
  'label.introductionInstructor': 'Arbetsledare',
  'label.introductionMaterial': 'Introduktionsmaterial',
  'label.introductionResponsibility': 'Du som lägger till arbetsplatsen försäkrar dig om att personerna har bekräftat introduktionen.',
  'label.introductionTopics': 'Ämnen som behandlas under introduktionen',
  'label.introductionTopicsAvailable': 'Standardintroduktionsämnen',
  'label.introductionWorker': 'Arbetstagare',
  'label.introSettings': 'Introduktionsinställningar',
  'label.inUse': 'Används',
  'label.invalid': 'Ogiltig',
  'label.invalidAddress': 'Arbetsplatsens adress är ogiltig. En giltig adress krävs för att skatterapporter ska kunna skickas.',
  'label.invalidate': 'Ogiltigförklara',
  'label.invalidateContractsWithExpiredDocs': 'Avtal med utgångna handlingar för entreprenörsåtaganden markeras som otillräckliga',
  'label.invalidateUser': 'Ogiltigförklara användare',
  'label.invalidating': 'Ogiltigförklarar ...',
  'label.invalidContractorLiabilityReport': 'Rapport',
  'label.invalidContracts': 'Alla avtal är inte giltiga.',
  'label.invalidOrientedPermit': 'Medarbetare <strong>{0}</strong> saknar nödvändiga kompetenser',
  'label.invalidOrientedPermits': '<strong>{0}</strong> personer saknar nödvändiga kompetenser',
  'label.invalidPermit': 'Medarbetarens <strong>{0}</strong> tillstånd saknar obligatorisk information',
  'label.invalidPermits': '<strong>{0} tillträdestillstånd</strong> saknar obligatorisk information',
  'label.invalidTaxNumber': 'Ogiltigt skattenummer',
  'label.invoiceHours': 'Faktureringstimmar',
  'label.invoiceHoursNotUsed': 'Faktureringstimmar som inte används',
  'label.invoicehoursReport.heading': 'Rapport över faktureringstimmar',
  'label.invoicehoursReport.info': 'Visar faktureringstimmar för varje dag under det angivna tidsintervallet för den angivna arbetsplatsen.',
  'label.invoiceHoursReport.PersonFilter': 'Sök person (skriv minst 3 tecken)',
  'label.invoiceHoursReport.roleFilter': 'Medarbetarens roll',
  'label.invoiceHoursReport': 'Faktureringstimmar',
  'label.invoiceHoursWaitingForApproval': 'Faktureringstimmar väntar på godkännande.',
  'label.invoiceHoursWaitingForApprovalManySites': 'Faktureringstimmar som väntar på godkännande på <strong>{0}</strong> arbetsplatser',
  'label.invoiceHoursWaitingForApprovalOneSite': 'Arbetsplatsen <strong>{0}</strong> har faktureringstimmar som väntar på godkännande.',
  'label.isForeman': 'Person arbetar som arbetsledare på arbetsplatsen',
  'label.issued': 'Utgiven',
  'label.jobName': 'Jobbtitel',
  'label.jobNumber': 'Jobbnummer',
  'label.jobNumberList': 'Jobbnummerlista',
  'label.jobNumbers': 'Jobbnummer',
  'label.jobNumberSearch': 'Sök jobbnummer',
  'label.jobTitleAddOption': 'Jobbtitlar',
  'label.jobTitleList': 'Beskrivning av jobbtitel',
  'label.jobTitles': 'Jobbtitlar',
  'label.known': 'Känt',
  'label.labor': 'Arbete',
  'label.laborAgreement': 'Anställningsavtal obligatoriskt',
  'label.langAndNationality': 'Språk och nationalitet',
  'label.languages': 'Språk',
  'label.lastCheckIn': 'Senaste incheckning',
  'label.lastCheckOut': 'Senaste utcheckning',
  'label.lastContractEnded': '(senaste avtalet avslutades {0})',
  'label.lastMonth': 'Senaste månaden',
  'label.lastName': 'Efternamn',
  'label.lastWeek': 'Förra veckan',
  'label.latest': 'Senaste',
  'label.liabilityDocs': 'Handlingar för entreprenörens åtaganden',
  'label.liabilityDocsAwaitingApproval': 'Handlingar att redovisa entreprenörens åtaganden i väntan på godkännande.',
  'label.liabilityDocsRejected': 'Handlingar för redovisning av entreprenörens åtaganden avvisas.',
  'label.liabilityInsuranceEnded': 'försäkring avslutad',
  'label.listPersonsWihtoutCompetence': 'Lista personer som <em>saknar</em> den valda kompetensen',
  'label.listToday': 'Idag:',
  'label.listTomorrow': 'Imorgon:',
  'label.loading': 'Laddar ...',
  'label.loadingMoreResults': 'Läser in fler resultat ...',
  'label.loadMoreResults': 'Läs in fler resultat',
  'label.location': 'Plats',
  'label.locationOnSite': 'Plats på arbetsplats',
  'label.locationShort': 'Plats',
  'label.loginToggleEmail': 'E-postadress',
  'label.logout': 'Logga ut',
  'label.mainContract': 'Huvudavtal',
  'label.mainContractor': 'Huvudentreprenör',
  'label.mainContractorAndDeveloperShort': 'Byggh. & huvudentr.',
  'label.mainOrSideContract': 'Huvud- eller sidoavtal',
  'label.manage': 'Företagsledningen',
  'label.management': 'Företagsledningen',
  'label.manpowerArea': 'Område för arbetsstyrkan',
  'label.markOrientationCompleted': 'Alla har slutfört introduktionen',
  'label.materials': 'Material',
  'label.materialsAddOption': 'Material',
  'label.maxFileSize': 'Maximal filstorlek för uppladdning: {0} MB',
  'label.message': 'Meddelande',
  'label.messaging': 'Meddelandehantering',
  'label.missing': 'Saknas',
  'label.missingCardId': 'Kort-ID saknas',
  'label.missingData': 'Inga data tillgängliga',
  'label.missingEmployeeInfo': 'Kontaktuppgift',
  'label.missingTagOnlyUserCompany': 'Endast medarbetare utan {0} tagg eller Valtti-kort',
  'label.mobile.home': 'Hem',
  'label.modifiedFromOriginal': 'Ändrat från original',
  'label.mondayShort': 'mån',
  'label.month': 'Månad',
  'label.move': 'Flytta',
  'label.moveContract': 'Flytta avtal',
  'label.moveContractHeading': 'Flytta avtal?',
  'label.moving': 'Flyttar ...',
  'label.multipleEmployeesWithInvalidTaxNumber': 'Ogiltigt skattenummer för <strong>{0}</strong> personer.',
  'label.name': 'Namn',
  'label.nameInEnglish': 'Namn på engelska',
  'label.nameInFinnish': 'Namn på finska',
  'label.nameNotFound': 'Hittades inte',
  'label.narrowPrintView': 'Smal utskriftsvy',
  'label.nationality': 'Nationalitet',
  'label.nEvaluations': '{0} utvärderingar',
  'label.new': 'Nytt',
  'label.newAccessArea': 'Nytt tillträdesområde',
  'label.newAccessPermit': 'Nytt tillträdestillstånd',
  'label.newcomerTraining': 'Nybörjarutbildning',
  'label.newcomerTrainingDone': 'Nybörjarutbildning slutförd',
  'label.newCompany': 'Nytt företag',
  'label.newCompetence': 'Ny kompetens',
  'label.newContract': 'Nytt avtal',
  'label.newContractor': 'Ny entreprenör',
  'label.newCriterion': 'Nya kriterier',
  'label.newDevice': 'Ny enhet',
  'label.newExpertise': 'Ny kunskap',
  'label.newMainOrSideContract': 'Nytt huvud- eller sidoavtal',
  'label.newMainOrSideContractStrong': 'Nytt <strong>huvud-</strong> eller <strong>sido-</strong> avtal',
  'label.newPayment': 'Ny betalning',
  'label.newPerson': 'Ny person',
  'label.newSideContract': 'Nytt sidoavtal',
  'label.newSideContractStrong': 'Nytt <strong>sido-</strong> avtal',
  'label.newSite': 'Ny arbetsplats',
  'label.newSiteAccessArea': 'Den nya arbetsplatsens tillträdesområde',
  'label.newSubContract': 'Nytt underleverantörsavtal',
  'label.newSubContractStrong': 'Nytt <strong>underleverantörsavtal</strong>',
  'label.newUser': 'Ny användare',
  'label.newUserGroup': 'Ny användarroll',
  'label.nextDay': 'Nästa dag',
  'label.nickName': 'Användarnamn',
  'label.no': 'Nej',
  'label.noAccessAreas': 'Inga tillträdesområden har lagts till.',
  'label.noActiveContracts': 'Entreprenören har inga aktiva avtal.',
  'label.noAffectToTaxReports': 'Uppskattat värde påverkar inte sändning av Skatteverksrapporter',
  'label.noCheckInsOuts': 'Inga in- eller utcheckningar',
  'label.noContractForNewPerson': 'Entreprenören har inga avtal som personer kan läggas till i.',
  'label.noFiles': 'Inga filer',
  'label.noNameSuggestion': 'Namnet hittades inte – ange namn',
  'label.noPhoto': 'Inget foto',
  'label.noReportFoundForContractor': 'Ingen rapport hittades för entreprenör',
  'label.noSafetyQuarters': 'Det finns inga säkerhetsrum än',
  'label.notActivated': 'Inte Aktiverad',
  'label.noTagsAtAll': 'Inga taggar alls',
  'label.notAvailable': 'Ej tillgänglig',
  'label.notDefined': 'Ej specificerad',
  'label.notes': 'Anteckningar',
  'label.notHandled': 'Ej åtgärdad',
  'label.noThanks': 'Nej, tack',
  'label.notification': 'Meddelanden',
  'label.notificationInfo': 'Meddelandena finns på kontrollpanelen och är synliga för alla entreprenörer på arbetsplatsen.',
  'label.notificationSingular': 'Meddelande',
  'label.notKnown': 'Okänd',
  'label.notUsed': 'Används ej',
  'label.noValttiTags': 'Inkludera inte Valtti-kort',
  'label.noVehiclePermits': 'Inga fordonstillstånd.',
  'label.noVehiclePermitTypes': 'Inga fordonstillståndstyper har lagts till.',
  'label.numberOfActiveEmployees': 'Aktiva medarbetare',
  'label.numberOfCompanies': 'Antal företag',
  'label.numberOfEmployees': 'Antal medarbetare',
  'label.numberOfEmployeesDescription': 'Medarbetare med aktivt tillträdestillstånd.',
  'label.ok': 'Ok',
  'label.okThanks': 'Jag förstår, tack!',
  'label.oldestFirst': 'Äldst först',
  'label.oneEvaluation': '1 utvärdering',
  'label.online': 'Online',
  'label.onlyAllocatedToSiteTags': 'Endast taggar som läggs till av arbetsplats',
  'label.onlyEmployeesPresentAtSite': 'Presentera endast medarbetare',
  'label.onlyExpats': 'Endast utstationerade arbetstagare',
  'label.onlyForeignEmployees': 'Endast utländska medarbetare',
  'label.onlyForeMen': 'Endast arbetsledare',
  'label.onlyOwnEmployees': 'Endast egna medarbetare',
  'label.onlyOwnTags': 'Överväg inte Valtti-kort',
  'label.onlyOwnTagsInfo': 'Valtti-kort betraktas inte som taggar i sökningen',
  'label.onPaper': 'På papper',
  'label.onSites': 'På {0} arbetsplatser',
  'label.open': 'Öppen',
  'label.openPersonSummary': 'Öppna personsammanfattning',
  'label.openReport': 'Öppna rapport (PDF)',
  'label.operationFailed': 'Åtgärden misslyckades.',
  'label.operationRunning': 'Utför åtgärd ...',
  'label.optional': 'Valfri',
  'label.or': 'eller',
  'label.orCreateSideContract': 'eller skapa ett sidoavtal',
  'label.orientation': 'Introduktion',
  'label.orientationForm': 'Introduktionsformulär',
  'label.orientationFormLabel': 'Introduktionsformulär',
  'label.orientationFormLink': 'Introduktionsformulär (PDF)',
  'label.orientations': 'Introduktioner',
  'label.orSelectFromThesePreviousRepresentatives': 'Eller välj bland dessa tidigare representanter',
  'label.orUseOtherIdCardType': 'eller använd annan typ av ID-kort',
  'label.other': 'Övrigt',
  'label.otherCompetences': 'Övriga kompetenser',
  'label.otherDevice': 'Övriga enheter',
  'label.otherDocuments': 'Övriga handlingar',
  'label.otherInfo': 'Övrig information',
  'label.otherLanguages': 'Övriga språk',
  'label.otherPersonIdentifierGroup': 'Annan identifierande personlig identifierare',
  'label.otherRequiredInfo': 'Övrig nödvändig information',
  'label.out': 'Ut',
  'label.outsideSite': 'Du befinner dig utanför arbetsplatsområdet. Ett meddelande kommer att läggas till i din incheckning.',
  'label.ownEmployees': 'egna medarbetare',
  'label.page': 'Sida',
  'label.participants': 'Deltagare',
  'label.passport': 'Pass',
  'label.passportNumber': 'Passnummer',
  'label.password': 'Lösenord',
  'label.paymentNote': 'Anmärkning',
  'label.paymentReference': 'Betalningsreferens',
  'label.paymentReverseVat': 'omvänd moms',
  'label.payments': 'Betalningar',
  'label.paymentTypeInvoiced': 'Fakturerad',
  'label.paymentTypePaid': 'Betald',
  'label.paymentTypeScheduled': 'Schemalagd',
  'label.paymentVat': 'VAT',
  'label.pending': 'Väntar',
  'label.pendingApproval': 'Väntar på godkännande',
  'label.pendingTagListUpdates': '<strong>{0}</strong> tagg-ID:n väntar på att uppdateras till grindenheter.',
  'label.pensionInsurance': 'Pensionsförsäkring',
  'label.pensionInsuranceLegend': 'Intyg på tecknade pensionsförsäkringar och inbetalda pensionsförsäkringspremier, eller konto för att betalningsavtal för utestående pensionsförsäkringspremier har ingåtts',
  'label.peopleCounts': 'Styrka',
  'label.permission.notAllowedToAddExpertise': 'Du har inte tillåtelse att lägga till denna utbildning. Kontakta huvudentreprenören för mer information.',
  'label.permitActiveDaily': 'Tillståndet gäller dagligen kl.',
  'label.permitAwaitingIntroduction': '<strong>{0}</strong>s tillträdestillstånd väntar på bekräftelse av introduktion.',
  'label.permitForContract': 'Det skapade tillståndet kommer att bifogas avtalet <strong>{0}</strong>',
  'label.permitlessEmployees': 'Visa endast medarbetare som inte har tillträdestillstånd',
  'label.permitlessEmployeesShort': 'Saknade tillstånd',
  'label.permits': 'Tillträdestillstånd',
  'label.permitsAwaitingIntroduction': '<strong>{0} tillträdestillstånd</strong> väntar på bekräftelse av introduktion.',
  'label.permitsDesc': 'Visa endast senaste tillträdestillstånd för medarbetare',
  'label.permitsShort': 'Tillstånd',
  'label.person.missingIdentifier': 'Ingen identifierare angiven. Lägg till en identifierare för person för att lägga till dem som medarbetare.',
  'label.person': 'Person',
  'label.personIdentifiers': 'Personidentifierare',
  'label.personIdentifiersHelp': 'Minst en identifierare krävs. Vanligtvis räcker skattenummer. Kraven kan variera från arbetsplats till arbetsplats.',
  'label.personnelGate': 'Personalgrind',
  'label.persons': 'Personer',
  'label.personSearch': 'Sök efter en person',
  'label.personSearchExample': 'T.ex. namn, skattenummer, e-postadress, telefonnummer.',
  'label.personsRole': 'Personens roll',
  'label.phoneNumberRequiredHint': 'Vad är numret till?',
  'label.photo': 'Foto',
  'label.photos': 'Foton',
  'label.photoShort': 'Foto',
  'label.portDirection': 'Vägbeskrivning till grind',
  'label.postcode': 'Postnummer',
  'label.postingDate': 'Publiceringsdatum',
  'label.postOffice': 'Ort',
  'label.prefilledForm': 'Förifyllt formulär',
  'label.prepayment': 'Förskottsregister',
  'label.prepaymentLegend': 'Redogörelse för om företaget är registrerat i Förskottsregistret',
  'label.preventsOrientation': 'Introduktion är inte möjlig förrän krav på kurser och kompetenser är uppfyllda.',
  'label.previousCLReports': 'Övriga entreprenörsansvarsrapporter',
  'label.previousDay': 'Föregående dag',
  'label.primaryLanguage': 'Språk',
  'label.primaryLanguageInfo': 'Zeroni arbetar med det valda språket.',
  'label.print': 'Skriv ut',
  'label.printCard': 'Skriv ut kort',
  'label.printCardA4': 'Skriv ut kort (A4)',
  'label.printVehiclePermit': 'Skriv ut fordonstillstånd',
  'label.processDate': 'Bearbetningsdatum',
  'label.processed': 'Bearbetade',
  'label.processStatus': 'Status',
  'label.profession': 'Yrke',
  'label.projectNumber': 'Projektnummer',
  'label.quickOptions': 'Kortkommandon',
  'label.quickTimeSpans': 'Dagens datum',
  'label.readingRights': 'Läs',
  'label.readMore': 'Läs mer',
  'label.ready': 'Klar',
  'label.reasonForRejection': 'Orsak till avslag',
  'label.receptionCommitment': 'Mottagningsåtagande',
  'label.references': 'Referenser',
  'label.region': 'Plats',
  'label.regionExamples': 'T.ex. Nyland, Norra Savolax',
  'label.regions': 'Platser',
  'label.reject': 'Avvisa',
  'label.rejectAccessPermitHeading': 'Ogiltigförklara tillträdestillståndet {0}',
  'label.rejected': 'Avvisad',
  'label.rejecting': 'Avvisar ...',
  'label.remove': 'Ta bort',
  'label.removeContract': 'Ta bort avtal',
  'label.removeContractHeading': 'Ta bort avtal?',
  'label.removed': 'Borttagen',
  'label.removedTrainingAndCourses': 'Borttagna utbildningar och kurser',
  'label.removeMeasurement': 'Ta bort mätning',
  'label.removeRow': 'Ta bort rad',
  'label.removeSelected': 'Ta bort markerad',
  'label.removeSelections': 'Ta bort markerade',
  'label.removeSiteAccessArea': 'Ta bort arbetsplatsens tillträdesområde',
  'label.removeVehiclePermitType': 'Ta bort arbetsplatsens fordonstillstånd',
  'label.removing': 'Tar bort ...',
  'label.repairBy': 'För reparation',
  'label.repaired': 'Reparerad',
  'label.replayToApproval': 'Godkänn/avvisa',
  'label.report': 'Rapport',
  'label.reportHazards': 'Rapportera risker',
  'label.reportInjuries': 'Rapportera skador',
  'label.reportNearMisses': 'Rapportera tillbud',
  'label.reports': 'Rapporter',
  'label.reportsToTaxAdmin': 'Rapporter till Skatteverket',
  'label.reportTax': 'Rapport till Skatteverket',
  'label.ReportWorkHourBalance.alert': 'Ingen spårning av arbetstimmar på arbetsplatser. Om du är intresserad av att spåra arbetstid, kontakta support@zeroni.fi',
  'label.ReportWorkHourBalanceSite.alert': 'Ingen spårning av arbetstimmar för vald arbetsplats. Om du är intresserad av att spåra arbetstid, kontakta support@zeroni.fi',
  'label.required': 'Obligatoriskt',
  'label.requiredReportsToTaxAdmin': 'Nödvändiga rapporter till Skatteverket',
  'label.residencePermit.info': 'En arbetstagare utanför EU behöver ett uppehållstillstånd som tillåter arbete. <a href="https://migri.fi/en/working-in-finland" rel="noopener" target="_blank">Läs mer <span class="far fa-external-link"></span></a> (migri.fi)',
  'label.residencePermit': 'Uppehållstillstånd för medarbetare',
  'label.residencePermitContinuationDueDate': 'Förfallodatum för förlängning',
  'label.residencePermitContinuationDueDatePassed': 'Förlängning av <strong>uppehållstillstånd</strong> behöver kontrolleras.',
  'label.residencePermitContinuationHelp': 'Välj ett datum då förlängning av uppehållstillståndet ska beviljas. Efter det valda datumet visas ett meddelande om uppehållstillståndet på kontrollpanelen.',
  'label.residencePermitContinuationReminder': 'Uppdatera status på det nya uppehållstillståndet eller ändra förfallodatum för förlängning. Kom ihåg att lägga till de nya handlingarna.',
  'label.residencePermitContinuationRequested': 'Förlängning begärd',
  'label.residencePermitExpired': 'Uppehållstillståndet har gått ut',
  'label.residencePermitExpires': 'Uppehållstillståndet går ut',
  'label.residencePermitExpiring': 'Uppehållstillståndet har gått ut eller är på väg att gå ut',
  'label.residencePermitNumber': 'Uppehållstillståndsnummer',
  'label.residencePermitsExpiredOrExpiring': '<strong>{0} Uppehållstillstånd</strong> har gått ut eller är på väg att gå ut inom 14 dagar.',
  'label.residencePermitType': 'Typ av uppehållstillstånd',
  'label.restArea': 'Rastplats',
  'label.restAreaNeeded': 'Rastplats behövs',
  'label.restore': 'Återställ',
  'label.restoring': 'Återställer ...',
  'label.result': 'resultat',
  'label.result2': 'resultat',
  'label.resultAccessPermit': 'tillträdestillstånd',
  'label.resultAccessPermits': 'tillträdestillstånd',
  'label.resultCompanies': 'företag',
  'label.resultCompany': 'företag',
  'label.resultContract': 'avtal',
  'label.resultContractor': 'entreprenör',
  'label.resultContractors': 'entreprenörer',
  'label.resultContracts': 'avtal',
  'label.resultDevice': 'enhet',
  'label.resultDevices': 'enheter',
  'label.resultEmployee': 'medarbetare',
  'label.resultEmployees': 'medarbetare',
  'label.resultOwn': 'egen',
  'label.resultPerson': 'person',
  'label.resultPersons': 'personer',
  'label.resultsByContractor': 'entreprenörens',
  'label.resultSite': 'arbetsplats',
  'label.resultSites': 'arbetsplatser',
  'label.resultUser': 'användare',
  'label.resultUserGroup': 'användargrupp',
  'label.resultUserGroups': 'användargrupper',
  'label.resultUsers': 'användare',
  'label.resultVisitor': 'besökare',
  'label.resultVisitors': 'besökare',
  'label.retryFailedAccessControlIntegrations': 'Starta om överföring',
  'label.return': 'Retur',
  'label.returnId': 'Retur-ID',
  'label.returning': 'Returnera ...',
  'label.reverseVat': 'Omvänd skattskyldighet',
  'label.role': 'Roll',
  'label.roleConsultant': 'Konsult',
  'label.roleContractor': 'Entreprenör',
  'label.roleSecurity': 'Säkerhet',
  'label.roofHotWorkForm': 'Formulär för heta arbeten på tak',
  'label.rotatePhoto': 'Rotera',
  'label.safety': 'Säkerhet',
  'label.safetyIndex': 'Index',
  'label.safetyMeasurementInfo': 'Arbetsplatsens säkerhetsmätningstyper definieras från arbetsplatsinställningarna. När säkerhetsmätningstypen har valts kan man välja relevant mätavsnitt för observationen från mätavsnittslistan.',
  'label.safetyMeasurer': 'Mätare',
  'label.safetyReportContactsInfo1': 'Rapportera säkerhetsavvikelser till dessa extra e-postadresser.',
  'label.safetyReportContactsInfo2': 'Arbetsplatsens arbetsledare och ansvarig entreprenörs säkerhetskontaktperson meddelas automatiskt. Vid skador och tillbud meddelas även huvudentreprenörens och byggherrens säkerhetskontaktpersoner.',
  'label.safetyReportEmails': 'E-postmottagare',
  'label.safetyReports': 'TR/MVR-rapporter',
  'label.safetyReportShort': 'Säkerhet',
  'label.sameAsCompanyContact': 'Samma som företagets kontaktperson',
  'label.saturdayShort': 'sp',
  'label.save': 'Spara',
  'label.saveAgain': 'Spara igen',
  'label.saveContractorObligationsDocuments': 'Spara entreprenörens åtagandehandlingar',
  'label.saved': 'Sparad',
  'label.saveEvaluation': 'Spara utvärdering',
  'label.saveFailed': 'Det gick inte att spara!',
  'label.saveIntroductionForm': 'Spara introduktionsformulär',
  'label.saveModifications': 'Spara ändringar',
  'label.saving': 'Sparar ...',
  'label.scannedCard': 'Skannat kort',
  'label.scannedCompetence': 'Kompetensverifiering (skannat kort etc.)',
  'label.scannedIdCard': 'Foto av ID-kort',
  'label.scannedIntroductionForm': 'Foto av introduktionsformuläret',
  'label.scannedInvoice': 'Skannad faktura',
  'label.scannedInvoices': 'Skannade fakturor',
  'label.scannedSiteIdCard': 'Foto av företagets plats-ID-kort',
  'label.scannedVisa': 'Foto av visum',
  'label.searchByName': 'Sök efter namn',
  'label.searchCollectiveLaborAgreements': 'Sök kollektivavtal',
  'label.searchCompanies': 'Sök företag',
  'label.searchCompetences': 'Sök kompetenser',
  'label.searchContracts': 'Sök efter avtal',
  'label.searchCountries': 'Sök efter land',
  'label.searchDevices': 'Sök efter enheter',
  'label.searchEmployeeBycompetence.noCompetence': 'Personer, utan vald kompetens',
  'label.searchEmployeesBySite': 'Sök medarbetare efter arbetsplats',
  'label.searchLanguages': 'Sök efter språk',
  'label.searchNationalities': 'Sök nationaliteter',
  'label.searchOnlyActiveEmployees': 'Aktivt tillträdestillstånd',
  'label.searchOrSelectBuyer': 'Köpare',
  'label.searchOrSelectRole': 'Roll',
  'label.searchPersons': 'Sök efter personer',
  'label.searchSites': 'Sök efter arbetsplatser',
  'label.securityClearance': 'Säkerhetskontroll',
  'label.securityClearanceDate': 'Datum för säkerhetskontroll',
  'label.securityClearanceDateSentToPolice': 'Datum för säkerhetskontroll skickad till polisen',
  'label.securityClearanceFormEN': 'Formulär att fylla i: Säkerhetskontroll ENG (PDF)',
  'label.securityClearanceFormFI': 'Formulär att fylla i: Säkerhetskontroll FIN (PDF)',
  'label.securityClearanceTooltip': 'Anges av Fennovoimas säkerhetsansvariga när säkerhetskontroll har godkänts. Kontroll krävs för att få tillträdestillstånd till arbetsplatsen.',
  'label.securityContact': 'Säkerhetskontakt',
  'label.securityContactPerson': 'Kontaktperson för företagssäkerhet',
  'label.seeSiteSettings': 'Se arbetsplatsens inställningar',
  'label.select': 'Välj',
  'label.selectAccessArea': 'Välj tillträdesområde',
  'label.selectAll': 'Markera allt',
  'label.selectAllActive': 'Markera alla aktiva',
  'label.selectBuyer': 'Välj köpare',
  'label.selectCollectiveLaborAgreement': 'Välj kollektivavtal',
  'label.selectCompany': 'Välj ett företag',
  'label.selectContract': 'Välj avtal',
  'label.selectContractingType': 'Välj en avtalstyp',
  'label.selectContractPartner': 'Välj en partner för avtalet',
  'label.selectCountry': 'Välj ett land',
  'label.selectCurrentCountry': 'Välj normalt anställningsland',
  'label.selectCurrentSite': 'Välj en arbetsplats att arbeta på',
  'label.selectDevice': 'Välj en enhet',
  'label.selected': 'Vald',
  'label.selectEmployee': 'Välj medarbetare',
  'label.selectEndDate': 'Välj slutdatum',
  'label.selectFiles': 'Välj filer',
  'label.selectHomeCountry': 'Välj ett hemland',
  'label.selectIdType': 'Välj en typ',
  'label.selectManpowerArea': 'Välj område för arbetsstyrkan',
  'label.selectNationality': 'Välj nationalitet',
  'label.selectParentContract': 'Välj ett överordnat avtal',
  'label.selectPerson': 'Välj en person',
  'label.selectPhoto': 'Välj ett foto',
  'label.selectPrimaryLanguage': 'Välj språk',
  'label.selectProfession': 'Välj yrke',
  'label.selectRole': 'Välj en roll',
  'label.selectSite': 'Välj en arbetsplats',
  'label.selectSiteFirst': 'Välj en arbetsplats',
  'label.selectSiteForeman': 'Välj en arbetsledare för arbetsplatsen',
  'label.selectSiteOrForeman': 'Välj en arbetsplats eller sök efter alla arbetsledare',
  'label.selectTaxingCountry': 'Välj beskattningsland',
  'label.send': 'Skicka',
  'label.sendAsPending': 'Skicka som väntande',
  'label.sendEmail': 'Skicka e-postmeddelande',
  'label.sendEmailToForemen': 'Skicka även ett e-postmeddelande till alla arbetsledare i företaget',
  'label.sendFile': 'Skicka fil',
  'label.sending': 'Skickar ...',
  'label.sendingFailed': 'Det gick inte att skicka!',
  'label.sendPasswordInSMS': 'Skicka lösenord',
  'label.sent': 'Skickad',
  'label.serviceCalls': 'Servicebesök',
  'label.serviceCallsReportInfo': 'Listar alla entreprenörers servicebesök på den arbetsplats som det inloggade företaget kan se.',
  'label.seSsn': 'Svenskt personnummer',
  'label.setPermitPeriodOfValidityAutomatically': 'Standardstart- och slutdatum hämtas från avtalet',
  'label.settings': 'Inställningar',
  'label.shoeSize': 'Skostorlek',
  'label.show': 'Visa',
  'label.showAll': 'Visa alla',
  'label.showAllIdentifiers': 'Visa alla personidentifierare',
  'label.showAllSiteCards': 'Visa alla arbetsplatskort',
  'label.showAllSites': 'Visa information för alla arbetsplatser',
  'label.showAllTags': 'Visa alla taggar',
  'label.showAlsoCompleted': 'Visa även slutförda',
  'label.showAlsoValttiCards': 'Visa även Valtti-kort',
  'label.showCheckIns': 'Visa incheckningar',
  'label.showCompleteDetails': 'Visa fullständig information',
  'label.showContractEvaluation': 'Utvärdering av Chow-avtal',
  'label.showContracts': 'Visa avtal',
  'label.showEmptyDays': 'Visa tomma dagar',
  'label.showEvaluation': 'Visa utvärdering',
  'label.showEvaluations': 'Visa utvärderingar',
  'label.showForActiveSites': 'Visa data för aktiva arbetsplatser',
  'label.showLess': 'Visa mindre',
  'label.showMore': 'Visa mer',
  'label.showOnlyActive': 'Visa endast aktiva',
  'label.showOnlyActiveEmployees': 'Visa endast kompetenser för aktiva medarbetare',
  'label.showOnlyActiveInTimePeriod': 'Visa endast aktiva',
  'label.showOnlyActiveLogbooks': 'Dölj utgångna',
  'label.showOnlyAllocatedToSite': 'Visa endast taggar som lagts till på arbetsplatsen',
  'label.showOnlyApprovedInvoiceHours': 'Visa endast godkända',
  'label.showOnlyFirstAndLastCheckInOfTheDay': 'Visa endast dagens första incheckning och dagens sista utcheckning',
  'label.showOnlyForeMen': 'Endast arbetsledare',
  'label.showOnlyGates': 'Visa endast grindar',
  'label.showOnlyUnUsedSiteCards': 'Visa endast arbetsplatskort som inte används',
  'label.showOnlyUnUsedTags': 'Visa endast taggar som inte används',
  'label.showOnlyUsedSiteCards': 'Visa endast arbetsplatskort som används',
  'label.showOnlyUsedTags': 'Visa endast taggar som används',
  'label.showOnlyVacant': 'Visa endast lediga',
  'label.showPayments': 'Visa betalningar',
  'label.showPreviousCLReports': 'Visa tidigare leverantörsansvarsrapporter',
  'label.showReport': 'Visa rapport',
  'label.showSiteMainContracts': 'Visa huvudavtal',
  'label.showTargetCompAndOtherCompetencesInfo': 'Visa {0} och andra kompetenser',
  'label.showWeeklyHours': 'Visa timmar per vecka',
  'label.sideContract': 'Sidoavtal',
  'label.sideContractor': 'Sidoentreprenör',
  'label.sideContractors': 'Sidoentreprenörer',
  'label.sign': 'Signera',
  'label.signature': 'Underskrift',
  'label.signatureClarification': 'Namnförtydligande',
  'label.signedForm': 'Signerat formulär',
  'label.site': 'Arbetsplats',
  'label.siteAccessAreaIdInfo': 'ID:t kan användas för att identifiera tillträdesområdet i externa tillträdeskontrollsystem.',
  'label.siteAccessAreaRemovalConfirmation': 'Är du säker på att du vill ta bort arbetsplatsens tillträdesområde <b>{0}</b>?',
  'label.siteAccessAreas': 'Arbetsplatsens tillträdesområden',
  'label.siteAccessAreasUpdated': 'Tillträdesområden sparade!',
  'label.siteAdditionalSettings': 'Fler inställningar',
  'label.siteAddress': 'Arbetsplatsens adress',
  'label.siteCardReport.all': 'alla',
  'label.siteCardReport.allTagsByCurrentCompany': 'Visa alla taggar som ges av {0}',
  'label.siteCardReport.businessId': 'Företags-ID',
  'label.siteCardReport.chooseContractorPlaceholder': 'Välj entreprenör (valfritt)',
  'label.siteCardReport.closed': 'Stängd',
  'label.siteCardReport.contactPerson': 'Kontaktperson',
  'label.siteCardReport.contract': 'Avtal:',
  'label.siteCardReport.contractor': 'Entreprenör',
  'label.siteCardReport.email': 'E-post',
  'label.siteCardReport.employer': 'Arbetsgivare',
  'label.siteCardReport.endDate': 'Slutdatum',
  'label.siteCardReport.hasEnded': 'Avslutad',
  'label.siteCardReport.info': 'Listar alla arbetsplatskort efter angivna kriterier.',
  'label.siteCardReport.missing': 'saknas',
  'label.siteCardReport.onlyClosed': 'endast arbetsplatskort som inte används',
  'label.siteCardReport.onlyNotClosed': 'endast arbetsplatskort som används',
  'label.siteCardReport.person': 'Person',
  'label.siteCardReport.pickUpDate': 'Upphämtningsdatum',
  'label.siteCardReport.printed': 'Utskriven',
  'label.siteCardReport.site': 'Arbetsplats:',
  'label.siteCardReport.startDate': 'Startdatum',
  'label.siteCardReport.state': 'Stat',
  'label.siteCardReport.tag': 'ID',
  'label.siteCardReport.tags.info': '<b>Visa alla taggar</b> visar alla taggar oavsett om de används eller inte. <b>Visa endast taggar som inte används</b> visar alla taggar som returneras, är skadade eller saknas.',
  'label.siteCardReport.tags': 'Arbetsplatskort:',
  'label.siteCardReport.taxNumber': 'Skattenummer',
  'label.siteCardReport.telephone': 'Telefon',
  'label.siteCardReport.title': 'Rapport för arbetsplatskort',
  'label.siteCardReport.waitingForPhotoShooting': 'Väntar på fotografering',
  'label.siteCardReport.waitingForPrinting': 'Väntar på utskrift',
  'label.siteCards': 'Arbetsplatskort',
  'label.siteCardsDesc': 'Visa endast arbetsplatskort som inte används',
  'label.siteCreated': 'Arbetsplats skapad',
  'label.siteDeveloper': 'Köpare',
  'label.siteDevices': 'Enheter på arbetsplats <strong>{0}</strong>',
  'label.siteEnds': 'Avslutas',
  'label.siteForeman': 'Arbetsplatsens arbetsledare',
  'label.siteForeman.missingEmail': 'E-postadress saknas',
  'label.siteForeman.missingPhoneNumber': 'Telefonnummer saknas',
  'label.siteForeman.missingPhoneNumberAndEmail': 'Telefonnummer och e-postadress saknas',
  'label.siteForemanContactInfo': 'Kontaktuppgifter till arbetsplatsens arbetsledare',
  'label.siteInformation': 'Arbetsplatsinformation',
  'label.siteInvalidAddress': 'Adressen för arbetsplatsen <strong>{0}</strong> är ogiltig. En giltig adress krävs för att skatterapporter ska kunna skickas.',
  'label.siteKey': 'Arbetsplatsnyckel',
  'label.siteMainContracts': 'Huvudavtal för arbetsplatsen',
  'label.siteName': 'Arbetsplatsnamn',
  'label.siteNumber': 'Arbetsplatsnummer',
  'label.siteOrContract': 'Arbetsplats/avtal',
  'label.siteReport': 'Arbetsplatsrapport',
  'label.siteReportHeading': 'Arbetsplatsrapport',
  'label.siteReportInfo': 'Visar information om arbetsplatser och deras inställningar',
  'label.siteReportInfo2': 'Rapporten omfattar endast arbetsplatser där företaget är en byggherre och/eller huvudentreprenör. Arbetsplatser måste vara aktiva minst en dag under den angivna tidsperioden för att inkluderas i rapporten.',
  'label.siteReportsAvailableWithinSite': 'Arbetsplatsspecifika rapporter finns i arbetsplatsinformationen.',
  'label.siteReportShort': 'Arbetsplatser',
  'label.siteRequirementSettings': 'Kravprofil',
  'label.sites': 'Arbetsplatser',
  'label.siteSearch': 'Arbetsplatssökning',
  'label.sitesWithAnonymousReports': '<strong>{0}</strong> arbetsplatser har anonyma säkerhetsrapporter.',
  'label.sitesWithIncompleteEmployees': '<strong>{0}</strong> arbetsplatser har medarbetare som saknar information.',
  'label.sitesWithInvalidContracts': 'Ogiltiga avtal på <strong>{0}</strong> arbetsplatser.',
  'label.sitesWithoutAddress': '<strong>{0}</strong> arbetsplatser har ogiltig adress. En giltig adress krävs för att skatterapporter ska kunna skickas.',
  'label.sitesWithoutLocation': 'Platsen är inte definierad på <strong>{0}</strong> arbetsplatser.',
  'label.sitesWithPendingTagListUpdates': '<strong>{0}</strong> arbetsplatser har tagg-ID:n som väntar på att uppdateras till grindenheter.',
  'label.sitesWithUnresolvedCheckIns': 'Okända incheckningar på <strong>{0}</strong> arbetsplatser.',
  'label.siteWithAnonymousReports': 'Arbetsplatsen <strong>{0}</strong> har anonyma säkerhetsrapporter.',
  'label.siteWithInvalidContract': 'Arbetsplatsen <strong>{0}</strong> har ogiltiga avtal.',
  'label.siteWithoutLocation': 'Arbetsplatsens <strong>{0}</strong> plats är inte definierad.',
  'label.siteWithPendingTagListUpdates': 'Arbetsplatsen <strong>{0}</strong> har <strong>{1}</strong> tagg-id:n som väntar på att uppdateras till grindenheter.',
  'label.siteWithUnresolvedCheckIns': 'Arbetsplatsen <strong>{0}</strong> har okända incheckningar.',
  'label.sms': 'Textmeddelande (SMS)',
  'label.smsCheckIn': 'SMS-incheckning',
  'label.socialRoomsAndVehicles': 'Rastplatser och fordon',
  'label.specialNeeds': 'Särskilda bestämmelser',
  'label.splitFromOriginal': 'Dela från original',
  'label.ssgAccess': 'SSG Access',
  'label.ssn': 'Finskt personnummer',
  'label.ssoUsername': 'SSO-användarnamn',
  'label.start_ends': 'Start–slut',
  'label.startDateAbbrev': 'Startar',
  'label.starting': 'Starta',
  'label.startingFrom': 'Börjar från {0}',
  'label.starts': 'Startar',
  'label.startTask': 'Starta <strong>{0}</strong>',
  'label.status': 'Status',
  'label.streetAddress': 'Gatuadress',
  'label.subcontract': 'Underleverantörsavtal',
  'label.subcontractFromParent': 'Underleverantörsavtal från avtal {0}',
  'label.subcontracting': 'Underleverantör',
  'label.subcontractor': 'Underentreprenör',
  'label.subcontractors': 'Underentreprenörer',
  'label.subcontracts.activeSubcontracts': 'Aktiva underavtal',
  'label.subcontracts': 'Underavtal',
  'label.suggest': 'Föreslå',
  'label.summary': 'Sammanfattning',
  'label.sundayShort': 'sa',
  'label.superUserEyesOnly': 'Visas endast för superanvändare: ',
  'label.swedishBusinessId': 'Svenskt företags-ID',
  'label.tagBelongsTo': 'Taggen tillhör <strong>{0}</strong>.',
  'label.tagBelongsToWithCompany': 'Taggen tillhör <strong>{0} / {1}</strong>.',
  'label.tagReport': 'Taggrapport',
  'label.tagReportShort': 'Taggar',
  'label.tags.numberOfNew': '{0} nya taggar',
  'label.tags.oneNew': '1 ny tagg',
  'label.tags': 'Taggar',
  'label.tagsDesc': 'Visa endast taggar som inte används',
  'label.targetContract': 'Målavtal',
  'label.taxAdmin': 'Skatteverket',
  'label.taxCard': 'Skattekort',
  'label.taxingCountry': 'Beskattningsland',
  'label.taxNumber': 'Skattenummer',
  'label.taxNumberShort': 'Vat-nr',
  'label.taxPayments': 'Skattebetalning',
  'label.taxPaymentsLegend': 'Intyg om skattebetalning eller skatteskuld, eller konto för att betalningsplan har upprättats avseende skatteskuld',
  'label.taxReportsSentAutomatically': 'Alla nödvändiga skatteverksrapporter skickas automatiskt.',
  'label.telephone': 'Telefon',
  'label.telephoneNumber': 'Telefonnummer',
  'label.telephoneShort': 'Tel.',
  'label.telephoneShortNoDot': 'Tel',
  'label.tempSave': 'Spara, skicka inte',
  'label.tes': 'Kollektivavtal',
  'label.tesLegend': 'Redogörelse för det kollektivavtal eller de huvudsakliga anställningsvillkor som gäller för arbetet',
  'label.thisMonth': 'Den här månaden',
  'label.thisWeek': 'Den här veckan',
  'label.thursdayShort': 'th',
  'label.time': 'Tid',
  'label.timeSpan': 'Tidsintervall',
  'label.timeTracking': 'Tidrapportering',
  'label.timeTrackingActive': 'Tidrapportering',
  'label.timezone': 'Tidszon',
  'label.timezoneOfDevices': 'Tidszon för incheckningsenheter',
  'label.tinNumber': 'TIN-nummer',
  'label.today': 'Idag',
  'label.todayAndYesterday': 'Idag och igår',
  'label.tomorrow': 'Imorgon',
  'label.topic': 'Ämne',
  'label.topics': 'Ämnen',
  'label.tot': 'Tot.',
  'label.total': 'Totalt',
  'label.totalHoursOfMainContractor': 'huvudentreprenör',
  'label.totalHoursOfSubContractors': 'underentreprenörer',
  'label.totalPeopleCount': 'Total styrka',
  'label.totalShort': 'Sum',
  'label.totalSmall': 'totalt',
  'label.totalValue': 'Totalt värde',
  'label.trackExpatWorkers': 'Utländska arbetares information',
  'label.trackExpatWorkersInfo': 'Utländska arbetstagare måste lämna bevis på socialförsäkring (t.ex. A1) samt uppgifter om uppehållstillstånd.',
  'label.tradeRegister': 'Handelsregister',
  'label.tradeRegisterLegend': 'Ett utdrag från Handelsregistret',
  'label.trailer': 'Släpvagn',
  'label.trailerPermit': 'Släpvagnstillstånd',
  'label.trainee': 'Praktikant',
  'label.training': 'Utbildning',
  'label.trainingAndCourses': 'Utbildning och kurser',
  'label.trainingAndCoursesName': 'Utbildningens/kursens namn',
  'label.trainingDescription': 'Utbildning och kurser tillgängliga som kompetenser',
  'label.trainingExpired': 'Utbildningen har gått ut',
  'label.trainingExpiring': 'Utbildningen går snart ut',
  'label.trainingSettings': 'Utbildningsinställningar',
  'label.trash.show': 'Visa papperskorg',
  'label.travelDocumentNumber': 'Resehandlingens nummer',
  'label.travelExpenseClaims': 'Reseräkningar',
  'label.travelExpenseReport.heading': 'Reseräkningsrapport',
  'label.travelExpenseReport.info': 'Visar alla resekostnader för den angivna arbetsplatsen och tidsperioden.',
  'label.travelExpenseReportShort': 'Resekostnader',
  'label.truck': 'Lastbil',
  'label.trustedPartner': 'Tillförlitlig partner',
  'label.trustedPartnerReport': 'Rapport om entreprenörens åtaganden',
  'label.tryAgain': 'Försök igen',
  'label.tts': 'Arbetsmiljöplaner',
  'label.tuesdayShort': 'tu',
  'label.työaika': 'Faktureringstimmar',
  'label.type': 'Typ',
  'label.unallocatedPayments': 'Ej tilldelade betalningar',
  'label.unclear': 'Oklar',
  'label.unconfirmed': 'Ej bekräftad',
  'label.undefinedLocation': 'Platsen är inte definierad',
  'label.underagePersonOnSite': '<strong>Minderårig</strong> person på plats',
  'label.underagePersonsOnSite': '<strong>{0} minderåriga</strong> personer på plats.',
  'label.undoing': 'Ångras ...',
  'label.universalSearchPlaceholder': 'Sök',
  'label.unknown': 'Okänd',
  'label.unorientedCheckedInEmployee': 'Ej introducerad medarbetare <strong>{0}</strong> har checkat in på arbetsplatsen',
  'label.unorientedCheckedInEmployees': '<strong>{0}</strong> ej introducerade medarbetare har checkat in på arbetsplatsen',
  'label.unpaid': 'Obetald',
  'label.unresolvedCheckInImei': 'Enhetens IMEI: <strong>{0}</strong> <span class="text-muted">(IMEI: {1})</span>',
  'label.unresolvedCheckIns': 'Okänd incheckning för ID <strong>{0}</strong> <strong>{1}</strong>',
  'label.unresolvedCheckinsShort': 'Okända incheckningar.',
  'label.unresolvedContractPayments': 'Ej tilldelade betalningar',
  'label.unresolvedFENNOCheckIns': 'Okänd incheckning av <strong>{0}</strong>(kort: {1}) <strong>{2}</strong>',
  'label.unresolvedTaxNumberCheckIns': 'Okänd incheckning för skattenummer <strong>{0}</strong> <strong>{1}</strong>',
  'label.unresolvedVALTTICheckIns': 'Okänd incheckning för VALTTI-kort med ID <strong>{0}</strong> <strong>{1}</strong>',
  'label.update': 'Uppdatera',
  'label.updateAccessPermit': 'Uppdatera tillträdestillstånd',
  'label.updateCompany': 'Uppdatera företag',
  'label.updateCompetence': 'Uppdatera kompetens',
  'label.updateContacts': 'Uppdatera kontakter',
  'label.updateContract': 'Uppdatera avtal',
  'label.updated': 'Uppdaterad',
  'label.updatedDeclaration': 'Avhjälpande åtgärd',
  'label.updateDevice': 'Uppdatera enhet',
  'label.updatePerson': 'Uppdatera person',
  'label.updateSafetyReport': 'Uppdatera säkerhetsrapport',
  'label.updateSite': 'Uppdatera arbetsplats',
  'label.updateSiteAdditionalSettings': 'Uppdatera ytterligare inställningar',
  'label.updateSiteRequirementSettings': 'Uppdateringskrav',
  'label.updateSiteWorkTimes': 'Uppdatera arbetstider',
  'label.updateUser': 'Uppdatera användare',
  'label.updateUserGroup': 'Uppdatera användarroll',
  'label.updating': 'Uppdaterar ...',
  'label.uploadCsv': 'Ladda upp CSV',
  'label.useCompanyAddress': 'Samma som företagets',
  'label.useCompanyFinnishAddress': 'Samma som företagets finska adress',
  'label.useCompanyForeignAddress': 'Samma som företagets utlandsadress',
  'label.usedNotRequired': 'Används, krävs inte',
  'label.usedRequired': 'Används, krävs',
  'label.user': 'användare',
  'label.userGroup': 'Användarroll',
  'label.userGroups': 'Användarroller',
  'label.username': 'Användarnamn',
  'label.usernameShort': 'Konto',
  'label.userReport': 'Användarrapport',
  'label.userReportInfo': 'Genererar en Excel-fil som listar alla användarkonton för företaget.',
  'label.userReportShort': 'Användare',
  'label.users': 'Användare',
  'label.userSearch': 'Användarsökning',
  'label.useThis': 'Använd gärna',
  'label.valid': 'Giltig',
  'label.validIndefinately': 'Giltig tills vidare',
  'label.validShort': 'Giltig',
  'label.validTaxNumber': 'Giltigt skattenummer',
  'label.validUntil': 'Giltigt till <strong>{0}</strong>',
  'label.valttiCard': 'Valtti-kort',
  'label.valttiCardAdded': 'Valtti-kortet har lagts till!',
  'label.valttiCardExpired': 'Valtti-kortet har gått ut',
  'label.valttiCardExpiredOrExpiring': '<strong>{0} valtti-kort</strong> har gått ut eller går ut inom en månad',
  'label.valttiCardExpiring': 'Valtti-kortet går ut',
  'label.valttiCardNumber': 'Valtti-kortnummer',
  'label.valttiCards': 'Valtti-kort',
  'label.valttiChecking': 'Kontrollerar Valtti-kortets giltighet ...',
  'label.valttiContractorHasManyContractsAtSite': 'Entreprenören har flera avtal på arbetsplatsen. Välj det avtal för vilket tillträdet ska skapas.',
  'label.valttiFoundWithNumber': 'Valtti-kort med nummer {0} hittades för person:',
  'label.valttiSelectedButMissing': 'Valtti-kort har valts för att ersätta foto på arbetsplatskort, men för närvarande har medarbetaren inga Valtti-kort som matchar entreprenören.',
  'label.value': 'Värde',
  'label.van': 'Transportbil',
  'label.vanShort': 'Transportbil',
  'label.vatNumber': 'Skattenummer',
  'label.vatObligation': 'VAT-register',
  'label.vatObligationLegend': 'Redogörelse för om företaget är registrerat i VAT-registret',
  'label.vehicle': 'Fordon',
  'label.vehicleConfirmRemove': 'Är du säker på att du vill ta bort fordonet <b>{0}</b> från tillståndet?',
  'label.vehicleConfirmRemoveHeading': 'Bekräfta borttagning',
  'label.vehicleDetails': 'Fordonsinformation',
  'label.vehicleGate': 'Fordonsgrind',
  'label.vehiclePermit': 'Fordonstillstånd',
  'label.vehiclePermit2': 'Fordonstillstånd',
  'label.vehiclePermitCardId': 'ID för fordonstillstånd',
  'label.vehiclePermitId': 'Fordonstillståndsnummer',
  'label.vehiclePermitNeeded': 'Fordonstillstånd krävs',
  'label.vehiclePermitReasons': 'Anledningar till att ansöka om fordonstillstånd',
  'label.vehiclePermitTypeRemovalConfirmation': 'Är du säker på att du vill ta bort fordonstillståndstyp <b>{0}</b>?',
  'label.vehiclePermits': 'Fordonstillstånd',
  'label.vehiclePermitsShort': 'Fordon',
  'label.vehicleRegistrationNumber': 'Registreringsnummer',
  'label.vehicleRegistrationNumberAndType': 'Registreringsnummer och fordonstyp',
  'label.vehicleRemove': 'Ta bort fordon',
  'label.vehicleReportHeading': 'Fordonstillstånd',
  'label.vehicleReportInfo': 'Skapar en rapport som listar alla fordonstillstånd som är giltiga minst en dag under den angivna tidsperioden.',
  'label.vehicleReportShowOnlyAccepted': 'Visa endast godkända tillstånd',
  'label.vehicles': 'Fordon',
  'label.vehiclesFromTo': 'Fordonstillstånd från {0} till {1}',
  'label.vehicleTimespanTooltip': 'Visar fordonstillstånd som är aktiva i minst en dag under den angivna tidsperioden.',
  'label.vehicleType': 'Fordonstyp',
  'label.verify': 'Verifiera arbetsrätt',
  'label.verifySummary': 'Visa arbetsrätt',
  'label.visa': 'Visa',
  'label.visaNumber': 'Visa nummer',
  'label.visitor': 'Besökare',
  'label.visitorPermits': 'Produktsäkerhetstillstånd',
  'label.visitors': 'besökare',
  'label.volume.timespanTooltip': 'Inkluderar i rapporten mätningar och fel under den valda tidsperioden.',
  'label.volumeReport': 'Volymrapport',
  'label.volumeReportHeading': 'Volymrapport',
  'label.volumeReportInfo': 'Visar information om entreprenörer, medarbetare, arbetstimmar och avtal för alla arbetsplatser.',
  'label.volumeReportInfo2': 'Rapporten inkluderar alla avtal som är aktiva minst en dag under den angivna tidsperioden. Rapporten inkluderar även alla medarbetare som har giltigt tillträdestillstånd minst en dag under angiven tidsperiod. Alla arbetstimmar som utförts under den angivna tidsperioden ingår också.',
  'label.volumeReportShort': 'Volym',
  'label.voluntary': 'Frivillig',
  'label.wage': 'Timlön',
  'label.waitingApproval': 'Väntar på godkännande',
  'label.waitingForAccessPermitApproval': 'Tillståndet kan inte godkännas. Person har inte ett godkänt tillträdestillstånd under hela fordonstillståndets giltighetstid.',
  'label.waitingForOrientation': 'Tillståndet kan inte godkännas. Personen måste slutföra introduktionen först.',
  'label.waitingForPersonsOrientationToGainVehicleGateAccess': 'Tillträde till fordonsgrind nekad. Tillträde kräver introduktion.',
  'label.waitingForSignature': 'Väntar på underskrift',
  'label.waitingRequiredCompetences': 'Inväntar erforderliga kompetenser',
  'label.wednesdayShort': 've',
  'label.week': 'Vecka',
  'label.weeklyHours': 'Timmar per vecka',
  'label.weeklyHoursAlarm': 'Timmar per vecka, larm',
  'label.weekShort': 'vk',
  'label.weeksShort': 'v',
  'label.when': 'När skydd sker',
  'label.whoIsExpat': 'Vem räknas som utstationerad?',
  'label.whyCantIEdit': 'Varför kan jag inte redigera detta?',
  'label.withdrawApproval': 'Återkalla godkännande',
  'label.withdrawing': 'Återkalla ...',
  'label.withoutTagDesc': 'Visa endast medarbetare som inte har taggar',
  'label.workDayTooLong': 'Varning: över {0} timmars arbetsdag',
  'label.workerSignature': 'Arbetstagarens underskrift',
  'label.workExperience': 'Arbetslivserfarenhet i år',
  'label.workHourBalance': 'Arbetstidssaldo',
  'label.workHourBalanceReport': 'Rapport över arbetstidssaldo',
  'label.workingHoursCombined': 'Arbetstimmar för alla arbetsplatser',
  'label.workingTime': 'Arbetstimmar',
  'label.workPermitExpires': 'Arbetstillståndet går ut',
  'label.workRelation': 'Arbetsrelation',
  'label.workSafetyCard': 'Arbetsmiljökort',
  'label.workTime': 'Arbetstid',
  'label.workTimeHeadingFromTo': 'Arbetstidsrapport från {0} till {1}',
  'label.workTimeReportHeading': 'Arbetstidrapport',
  'label.worktimeReportInfo': 'Visar alla arbetstimmar under den angivna tidsperioden.',
  'label.yes': 'Ja',
  'label.yesterday': 'Igår',
  'layout.edit': 'Redigera planritning',
  'layout.hasRaports': 'Planritningen har redan sparade platser, så den kan inte tas bort. Om du vill ändra planritningen markerar du planritningen som passiv och skapar en ny.',
  'layout.searchOrSelectLayout': 'Planritning',
  'layouts.addFirst': 'Lägg till första planritningen',
  'layouts.layout': 'Planritning',
  'layouts.layouts': 'Planritningar',
  'layouts.newLayout': 'Ny planritning',
  'layouts.noLayouts': 'Inga planritningar',
  'layouts.noReadRights': 'Du har inte behörighet att läsa eller ändra layouter.',
  'layouts.offline': 'Layouter är inte tillgängliga här när de är offline.',
  'layouts.searchResults.many': '{0} planritningar',
  'layouts.searchResults.one': 'En planritning',
  'locale.de.nameInThisLanguage': 'Deutsch',
  'locale.en.nameInThisLanguage': 'English',
  'locale.et.nameInThisLanguage': 'Eesti',
  'locale.fi.nameInThisLanguage': 'Suomi',
  'locale.pl.nameInThisLanguage': 'Polski',
  'locale.ru.nameInThisLanguage': 'Русский',
  'locale.sk.nameInThisLanguage': 'Slovenčina',
  'locale.sv.nameInThisLanguage': 'Svenska',
  'locale': 'en',
  'locationAddedToLayout': 'Plats tillagd på planritning ',
  'login.accountNotFound': 'Kontot kunde inte hittas.',
  'login.didNotReceiveTemporaryPasswordViaSms': 'Har du inte fått ditt lösenord via SMS?',
  'login.enterAccountAndNumber': 'Ange ditt konto.',
  'login.failedToSendTemporaryPassword': 'Det gick inte att skicka lösenord!',
  'login.incorrectRequest': 'Felaktig begäran!',
  'login.infoMessage': 'Ett nytt lösenord skickas till ditt telefonnummer.',
  'login.login': 'Logga in',
  'login.newPasswordSentToEmail': 'Nytt lösenord har skickats till e-postadress',
  'login.newPasswordSentToTelephone': 'Nytt lösenord har skickats till ett telefonnummer',
  'login.ohNoIe': '<p>Din webbläsare är <strong>Internet Explorer</strong> som inte stöds av Zeroni. De webbläsare som stöds är <a href="https://www.google.com/chrome/">Chrome</a>, <a href="https://www.getfirefox.com/">Firefox</a>, <a href="https://www.opera.com/">Opera</a>, <a href="https://www.microsoft.com/fi-fi/windows/microsoft-edge/">Edge</a> och <a href="https://www.apple.com/safari/">Safari</a>.</p>',
  'login.passwordForgotten': 'Har du glömt ditt lösenord?',
  'login.requestNewPassword': 'Begär nytt lösenord',
  'login.returnToLogin': 'Tillbaka till inloggningssidan',
  'login.sendTemporaryPasswordToEmail': 'Skicka lösenord till e-postadress',
  'login.showPassword': 'Visa lösenord',
  'login.telephone': 'Telefonnummer',
  'login.telephoneAndAccountDontMatch': 'Telefonnumret matchar inte kontot',
  'login.temporaryPasswordSentToEmail': 'Lösenordet har skickats till e-postadress!',
  'login.timeout': 'Timeout för inloggning: det tog för lång tid!',
  'login.userHasSsoUsername': '<p>Ditt företag använder en enda inloggningstjänst, <strong>Azure AD</strong>.</p><p>Logga in med din Azure AD-tjänst.</p>',
  'login.wrongPassword': 'Fel e-postadress eller lösenord.',
  'login.wrongVerificationCode': 'Verifieringskoden är felaktig!',
  'mainContractorSignature': 'Huvudentreprenörens underskrift',
  'mainHelp.call': 'Ring',
  'mainHelp.email': 'E-post',
  'mainHelp.help.desc': 'Felsökning, tips, FAQ.',
  'mainHelp.help.heading': 'Hjälp',
  'mainHelp.notifications': 'Anslagstavla',
  'mainHelp.privacyPolicy': '<a target="_blank" rel="noopener" href="https://www.zeroni.fi/privacy-policy/">Sekretesspolicy</a>',
  'mainHelp.resources': 'Resurs',
  'mainHelp.support': 'Stöd',
  'mainHelp.telephone.hours': 'Mån–fre 8–16',
  'mainHelp.telephone.price': '0,69 €/min',
  'manage.info.accessAreas': 'Hantera arbetsplatsens tillträdesområden.',
  'manage.info.devices': 'Lägg till, redigera eller ta bort enheter.',
  'manage.info.docs': 'Lägg till eller ta bort handlingar för arbetsplatsen.',
  'manage.info.downloadNFCReader': 'Ladda ner installationsfil för NFC-läsare',
  'manage.info.edit': 'T.ex. arbetsplatsens namn, adress och varaktighet.',
  'manage.info.generalSettings': 'Innehåller t.ex. standardinställningar för nya arbetsplatser, rapportering av säkerhetsavvikelser och information som behövs för tillträdestillstånd.',
  'manage.info.introduction': 'Definiera och hantera introduktioner för arbetsplatsen.',
  'manage.info.jobtitles': 'Uppdatera och hämta aktuell jobbtitellista.',
  'manage.info.layouts': 'Hantera arbetsplatsens planritningar. Säkerhets- och kvalitetsavvikelser kan finnas på planritningarna.',
  'manage.info.location': 'Definiera arbetsplatsområde. Området används i eq. mobila incheckningar, arbetsplatsval, teehavainto.fi-service.',
  'manage.info.massMessaging': 'Skicka meddelanden till entreprenörer eller arbetsplatsens medarbetare.',
  'manage.info.orientation': 'Hantera teman för arbetsplatsintroduktion.',
  'manage.info.professions': 'Hantera tillåtna jobbtitlar.',
  'manage.info.reportsToTaxAdmin': 'Arkiv för avtals- och medarbetarrapporter som har skickats till Skatteverket.',
  'manage.info.siteAdditionalSettings': 'T.ex. hur kompetenser följs, standardvärden för tillstånd för heta arbeten och information som efterfrågas för tillträdestillstånd.',
  'manage.info.siteRequirementSettings': 'Nödvändiga kompetenser och utbildningar.',
  'manage.info.userGroups': 'Definiera och hantera användarroller. Med användarroller kan du hantera vilken information olika användare ser.',
  'manage.info.users': 'Hantera medarbetarnas arbetstillstånd.',
  'manage.info.workTime': 'Inställningar relaterade till arbetstid.',
  'manage.integrations.vg.messageActivationInfo': 'Tjänsten hanteras i Vastuu Groups webbtjänst och uppdateras i Zeron med ungefär en timmes fördröjning.',
  'manage.integrations.vg.failedToCheckKey': 'Det gick inte att verifiera anslutningen.',
  'manage.integrations.vg.keyNotFound': 'Anslutningsfel, okänd integrationsnyckel.',
  'manage.integrations.vg.keyInvalid': 'Anslutningsfel, ogiltig integrationsnyckel.',
  'manage.integrations.vg.loadingFailed': 'Det gick inte att ladda integrationsdetaljer.',
  'manage.integrations.vg.title': 'Vastuu Group',
  'manage.integrations.vg.label.customerIdentifier': 'Vastuu Group',
  'map.area': 'Område',
  'map.startOver': 'Ta bort område',
  'markLocation': 'Markera plats',
  'markToLayout': 'Markera på planritning',
  'message.acceptanceRecordsOfLiabilityDocumentsStored': 'Intyg för entreprenörens åtaganden och ansvar för avtalet <a href="#!/sites/{0}/contracts/{1}">{2}</a> godkända.',
  'message.accessPermitAddedAlert': 'Tillträdestillstånd tillagt.',
  'message.accessPermitCreatedForMultipleContracts': '<strong>Obs!</strong> För varje valt kontrakt skapas ett eget tillträdestillstånd.',
  'message.accessPermitIntroduced': 'Introduktion till slutförd.',
  'message.accessPermitRequirements': '<strong>Obs!</strong> Giltiga arbetsmiljö- och hälsokort krävs för tillträdestillstånd.',
  'message.accessPermitUpdatedAlert': 'Tillträdestillstånd uppdaterat.',
  'message.accessPermitUpdateFailedAlert': 'Uppdatering av tillträdestillstånd misslyckades.',
  'message.accessPermitValidationPeriodInfo': 'Tillträdestillståndets start- och slutdatum får inte överstiga avtalets.',
  'message.addOtherNotesViaCheckInList': 'Lägg till andra anteckningar och ersättningar via <a href="{0}">checklistan för denna arbetsplats</a>.',
  'message.askFinnishShortcut': 'Du valde Finland som nationalitet. Ska vi också sätta finska som normalt anställningsland och språk?',
  'message.automaticTagListUpdateDescription': 'Automatiska uppdateringar av grindenheter skickar nya tagg-ID till alla grindenheter var 5:e minut. Automatiska uppdateringar bör endast inaktiveras om många nya medarbetare läggs till på arbetsplatsen dagligen under drift (vanligare på arbetsplatser med årliga underhållsstopp) eftersom det kan fördröja driften av enheten om tagguppdateringar skickas för ofta. När funktionen är inaktiverad måste du skicka uppdateringar till enheterna manuellt. Kontrollpanelen visar också antalet taggar som väntar på uppdatering till enheter.',
  'message.avoidOverlapWithAccessPermit': 'Medarbetaren har redan följande tillträdestillstånd:',
  'message.avoidOverlapWithMultipleAccessPermits': 'Medarbetaren har redan följande tillträdestillstånd:',
  'message.beforeAnythingElseCompleteCompanyInfo': '<strong>Ofullständig företagsinformation:</strong> Fyll i uppgifterna för ditt företag innan du fortsätter.',
  'message.businessIdAlreadyInUse': 'Företags-ID används redan!',
  'message.canCreateAccessPermits': 'Du kan skapa ofullständiga tillträdestillstånd och avsluta dem senare',
  'message.canCreateAccessPermitsDespiteErrors': 'Du kan lägga till tillträdestillstånd medan du väntar på saknad information.',
  'message.changesSavedToPerson': '<strong>Sparad till personuppgifter:</strong> Ändringar sparas till personens kontaktuppgifter.',
  'message.changesSavedToPersonSpecific': '<strong>Sparad till personliga uppgifter:</strong> Ändringar sparas till personens kontaktuppgifter när tillträdestillstånd läggs till.',
  'message.changesSavedToPersonX': '<strong>Sparad till personliga uppgifter:</strong> Ändringar sparas till <em>{0}s</em> kontaktuppgifter.',
  'message.checkForemanContactInfo': '<strong>Personen fungerar som arbetsledare och behöver därför en telefon och en e-postadress.</strong> Se till att dennas kontaktuppgifter är uppdaterade.',
  'message.checkInsNotLoaded': 'Incheckningar har inte lästs in än. Välj önskade filter och klicka på ”Hämta incheckningar”.',
  'message.checkInsOnMultipleSites': 'In- och utcheckningar sparas på alla arbetsplatser som enheten är ansluten till, och personen som gör incheckningen har behörighet till dessa.',
  'message.checkoutForgottenBody': '<p>Du checkade in på <strong>{1}</strong> kl. <strong>{0}</strong>, men checkade inte ut.</p><p>Ange tiden då du lämnade arbetsplatsen.</p>',
  'message.checkSiteForemanContactInfo': '<strong>Arbetsplatsens arbetsledare behöver en telefon och en e-postadress.</strong> Se till att dennas kontaktuppgifter är uppdaterade.',
  'message.companyFilterInPersonSearchHelp': 'Visa medarbetare för valt företag. Endast medarbetare med aktivt tillträdestillstånd till ett aktivt avtal visas.',
  'message.companyHasNoEvaluations': 'Företagsavtal har ännu inte utvärderats.',
  'message.companyNotTrustedPartner': 'Företaget är inte en Tillaajavastuu Reliable Partner.',
  'message.companyRegisterIsEmpty': 'Företagsregistret är tomt.',
  'message.companyUpdated': 'Företag <a href="{0}">{1}</a> uppdaterat.',
  'message.companyWorksAs': '<strong>{0}</strong> agerar på arbetsplatsen <strong>{1}</strong>',
  'message.competenceAddedAlert': 'Kompetens tillagd.',
  'message.competenceAlreadyExists': 'Personen har redan denna kompetens.',
  'message.competenceFilterInPersonSearchHelp': 'Endast medarbetare med tillträdestillstånd och nödvändiga kontaktuppgifter visas.',
  'message.competenceUpdatedAlert': 'Kompetens uppdaterad.',
  'message.confirmAccessPermitRejection': 'Är du säker på att du vill ogiltigförklara detta tillträdestillstånd?',
  'message.confirmDeviceDeletion': 'Är du säker på att du vill ta bort enheten <strong>{0}</strong>?',
  'message.confirmEnd': 'Bekräfta att mätningen är redo att avslutas.',
  'message.confirmInterruption': 'Detta avtal och alla dess underavtal kommer att avbrytas. Är du säker på att du vill fortsätta?',
  'message.confirmRemoval': 'Ta bort avtal <strong>{0}</strong>? Denna åtgärd tar bort detta avtal, alla underliggande avtal, tillträdestillstånd och alla incheckningar.',
  'message.contractAdded': 'Avtalet <a href="#!/sites/{0}/contracts/{1}">{2}</a> har lagts till.',
  'message.contractCompanyInvalid': 'Medarbetare kan läggas till i avtalet först efter att företagets uppgifter har fyllts i.',
  'message.contractDeclarationsWaitingForSending': '<strong>Rapport om avtalsdetaljer</strong> har inte skickats!',
  'message.contractStatusChangesByReport': 'Avtalets status kommer att uppdateras enligt den status som anges i rapporten. Även aktuell rapport och status arkiveras i avtalets handlingar.',
  'message.contractStatusInvalid': 'Avtalet är giltigt när antingen information om entreprenörens åtaganden har sparats och godkänts eller när giltig information har erhållits via Trusted Partner-programmet.',
  'message.contractStatusWarning': 'Entreprenörens ”Obligations Act”-handlingar gäller inte längre',
  'message.contractStatusWarningMoreInfo': 'FYI: När avtalet har lagts till med giltiga COA-handlingar är vissa handlingar inte längre giltiga enligt rapporten Reliable partner (<a href="{0}" target="_blank"><span class="far fa-file"></span> PDF</a>). Detta gör inte automatiskt några ändringar och allt fungerar som vanligt.',
  'message.contractStatusWarningMoreInfoNoVGAccess': 'FYI: När avtalet har lagts till med giltiga COA-handlingar är vissa handlingar inte längre giltiga enligt rapporten Reliable partner. Detta gör inte automatiskt några ändringar och allt fungerar som vanligt.',
  'message.contractUpdated': 'Avtalet <a href="#!/sites/{0}/contracts/{1}">{2}</a> har uppdaterats.',
  'message.csvInstructions': 'Uppladdad fil måste vara i .csv-format. Fält separeras med semikolon.',
  'message.dashboardSelectionHelp': 'Kontrollpanelen visar en sammanfattning för alla arbetsplatser eller en specifik arbetsplats.',
  'message.deleteUserGroupConfirmation': 'Är du säker på att du vill ta bort användarrollen <strong>{0}</strong>?',
  'message.deleteUserGroupHeading': 'Ta bort användarroll {0}',
  'message.deviceAddedAlert': 'Enhet tillagd.',
  'message.deviceConnectedAlert': 'Enheten <strong>{0}</strong> har lagts till på arbetsplatsen',
  'message.deviceDeletedAlert': 'Ta bort borttagen.',
  'message.deviceInUseOnMultipleSites': '<strong>Enheten används!</strong> Den här enheten används redan på följande arbetsplatser:',
  'message.deviceInUseOnOneSite': '<strong>Enhet används!</strong> Denna enhet används redan på arbetsplatsen <em>{0} {1}</em>.',
  'message.deviceOpenedRemotely': 'Grind <strong>{0}</strong> fjärröppnad',
  'message.deviceRemoteOpeningFailed': 'Fjärröppning av enheten misslyckades!',
  'message.deviceRemovedAlert': 'Enheten <strong>{0}</strong> har tagits bort från arbetsplatsen',
  'message.emailRequiredInfo': 'E-postadressen är medarbetarens användarnamn för Zeroni. Inbjudningar till onlineutbildningar skickas till medarbetarens e-postadress. Därför kan Zeroni inte ha flera användare med samma e-postadress.',
  'message.employeeDeclarationsWaitingForSending': '<strong>Rapport om medarbetaruppgifter</strong> har inte skickats!',
  'message.employeeMissingNewcomerTraining': 'Person <strong>{0}</strong> saknar utbildning för nybörjare.',
  'message.employeesMissingNewcomerTraining': '<strong>{0} personer</strong> saknar en nybörjarutbildning.',
  'message.emptyDeviceRegister': 'Enhetsregistret är tomt.',
  'message.emptyEmployeeRegister': 'Personregistret är tomt.',
  'message.emptySiteRegister': 'Arbetsplatsregistret är inte tomt.',
  'message.emptyUserGroupRegister': 'Användarrollregistret är tomt.',
  'message.emptyUserRegister': 'Användarregistret är tomt.',
  'message.faultMeasurementRemovalConfirmation': '<strong>Är du säker på att du vill ta bort mätningen?</strong> Alla observationer kommer att gå förlorade. Åtgärden kan inte ångras.',
  'message.fetchNewLiabilityReportAndUpdateContractStatus': 'Hämta ny ansvarshandling från Luotettava kumppani och uppdatera avtalsstatus.',
  'message.firstAccessPermitAddedAlert': 'Tillträdestillstånd tillagt. <a href="{0}">Lägg sedan till kompetenser <span class="far fa-angle-right"></span></a>',
  'message.firstLoginNoPassword': '<p><strong>Hej {0}!</strong></p><p>Du har ännu inte fått något lösenord till Zeroni. Skicka den till din mobiltelefon.</p>',
  'message.formHasInvalidFields': '<strong>Ogiltiga fält:</strong> Ett eller flera inmatningsfält saknar ett värde eller värdet är ogiltigt.',
  'message.formHasInvalidFieldsTxt': 'Ett eller flera inmatningsfält saknar ett värde eller värdet är ogiltigt.',
  'message.giveMinimum': 'Sökningen behöver minst {0} tecken.',
  'message.goToUpdateDocumentsManually': 'Gå till Uppdatera ansvarshandlingar manuellt från avtalets dokumentinställningar.',
  'message.helpManaged': 'Kontaktpersonen får behörighet att hantera företagets del av avtalet, t.ex. skapa tillträdestillstånd.',
  'message.identifierAndBirthDateDontMatch': 'Födelsedatum och identifierare stämmer inte överens.',
  'message.identifierIsInUse': 'Identifieraren används redan av en annan anställd. Kontakta supporten om du behöver hjälp.',
  'message.identityDocumentRequirement': 'Lagen kräver en identitetskontroll av alla som arbetar på byggarbetsplatsen. Handlingar ska sparas i två år efter arbetets slut.',
  'message.idInUse': '<strong>ID används</strong> på denna arbetsplats under vald tidsperiod. Välj ett annat ID.',
  'message.idUsedByPerson': '<strong>ID används</strong> på denna arbetsplats under vald tidsperiod. ID har tilldelats <strong>{0}</strong>.',
  'message.imeiExists': '<strong>IMEI finns redan.</strong> Är IMEI-numret korrekt? Används det på en annan enhet i registret? Om problemet kvarstår, kontakta supporten (support@zeroni.fi).',
  'message.incorrectDate': '<strong>Kontrollera formateringen:</strong> Datum måste anges som <strong>dd.mm.åååå</strong>.',
  'message.incorrectExpirationDate': '<strong>Kontrollera utgångsdatum:</strong> Utgångsdatum ska formateras som <strong>mm/åååå</strong>.',
  'message.incorrectTime': '<strong>Kontrollera tid:</strong> Tid ska formateras som <strong>tt:mm</strong>.',
  'message.instructorStatement': 'Jag har läst och bekräftat introduktionsformuläret. Personen som har hand om introduktionen har erforderliga säkerhetskunskaper och personlig skyddsutrustning. Personen som har hand om introduktionen känner till säkerhetsföreskrifterna på den här arbetsplatsen.',
  'message.instructorStatementWithPerson': 'Jag har introducerats <strong>{0}</strong>. De har erforderliga säkerhetskunskaper och personlig skyddsutrustning enligt arbetsplatsens föreskrifter. De känner till säkerhetsföreskrifterna och förbinder sig att följa dem.',
  'message.instructorStatementWithWorker': 'Jag har genomfört introduktionen med <strong>{0}</strong>. Personen som har hand om introduktionen har erforderliga säkerhetskunskaper och personlig skyddsutrustning. Personen som har hand om introduktionen känner till säkerhetsföreskrifterna på den här arbetsplatsen.',
  'message.introductionToContractInformationInSiteEditor': 'Första avtalet på arbetsplatsen skapas med hjälp av avtalsinformation. Avtal krävs för skattedeklaration och hantering av tillträdestillstånd.',
  'message.invalidationUserConfirmation': 'Är du säker på att du vill återkalla behörighet till företag från <strong>{1}</strong> användare <strong>{0}</strong>?',
  'message.invalidChecksumForFinnishBusinessId': '<strong>Är du helt säker?</strong> Det här ID:t verkar inte vara för ett riktigt företag. Kontrollera siffrorna.',
  'message.invalidChecksumForVatNumber': 'Ange VAT-nummer, dess landskod + identifierare utan mellanslag och bindestreck, t.ex. SE123456',
  'message.invalidContractTimePeriod': 'Ange start- och slutdatum för avtalet. De behövs för att undvika överlappande huvudavtal.',
  'message.jobTitleHelp': 'Sök efter företag efter befattningar som anges i avtal.',
  'message.largeSearchResult': '{0} andra resultat.',
  'message.liabilityDocsCheckBy': 'Handlingarna har kontrollerats och godkänts av <strong>{0}</strong>.',
  'message.liabilityDocsElsewhere': 'Handlingar sparade i ett externt system.',
  'message.linkToManualTaxNumberCheck': 'Kontrollera skattenummer manuellt (www.vero.fi)',
  'message.lowWage': '<strong>Under minimilön:</strong> En medarbetare har rätt till en minimilön på {0} € per månad (dvs. {1} € per timme).',
  'message.message.regionHelp': 'Region där företaget har varit aktivt.',
  'message.missingCardIdHelp': 'Visa endast personer med saknat kort-ID',
  'message.newcomerTrainingRequirements': '<strong>Nybörjarutbildning</strong> är endast tillgänglig efter att följande punkter har slutförts:',
  'message.newPersonFromGlobalDb': 'Information för person <strong>{0}</strong> kopierad från gemensamt personregister. Fyll i resten av de obligatoriska fälten och lägg till den nya personen.',
  'message.newPersonFromValtti': 'Information för person <strong>{0}</strong> kopierad från Valtti-kortet. Fyll i resten av de obligatoriska fälten och lägg till den nya personen.',
  'message.noAccessPermits': 'Inga tillträdestillstånd har lagts till.',
  'message.noAccessPermitsFound': 'Inga tillträdestillstånd hittades.',
  'message.noActiveAccessPermits': 'Inga <em>aktiva</em> tillträdestillstånd.',
  'message.noCompaniesFoundWithGivenFilters': 'Inga företag hittades med angivna sökparametrar.',
  'message.noCompetences': 'Inga kompetenser har lagts till.',
  'message.noCompetencesFound': 'Inga kompetenser hittades.',
  'message.noContracts': 'Inga avtal har lagts till.',
  'message.noContractsForAccessPermit': 'Ett arbetsplatstillstånd kräver ett avtal, men den valda arbetsplatsen har inga aktiva avtal.',
  'message.noPayments': 'Inga betalningar har lagts till i avtalet.',
  'message.noQualityMeasurementsFound': 'Arbetsplatsen har inga kvalitetsmätningar.',
  'message.noSafetyMeasurementsFound': 'Arbetsplatsen har inga säkerhetsmätningar.',
  'message.noSafetyMeasurementsFoundWithFilters': 'Inga säkerhetsmått hittades med angivna filter.',
  'message.noSafetyToursFound': 'Det har inte gjorts några TR- eller MRV-mätningar på denna arbetsplats.',
  'message.noSearchResults': 'Sökningen gav inga resultat.',
  'message.noTaxNumberAlert': '<strong>Inget skattenummer!</strong> Denna person saknar fortfarande ett skattenummer. I de flesta fall krävs skattenummer, t.ex. rapporter till Skatteverket.',
  'message.notUniqueCompetence': '<strong>Kontrollera kompetens och utgångsdatum:</strong> Personen har redan en <strong>{0}</strong> som är giltig till <strong>{1}</strong>.',
  'message.noUnallocatedPayments': 'Ej tilldelade betalningar.',
  'message.noValttiTagsInfo': 'Valtti-kort ingår inte som taggar.',
  'message.numberOfUnallocatedPayments': 'Arbetsplats har <strong>{0} ej tilldelade betalningar</strong>.',
  'message.overlapsMainContract': 'Ett huvudavtal finns redan för angiven tidsperiod.',
  'message.paperIntroductionHowTo': '<a href="{0}" target="_blank">Öppna introduktionsformuläret</a>, skriv ut det och lägg till ett foto av det signerade formuläret nedan.',
  'message.passwordSent': '<strong>Lösenordet har skickats till din mobiltelefon.</strong> Du ska nu kunna logga in.',
  'message.personAddedAlert': 'Person tillagd.',
  'message.personAlreadyExists': 'Person <strong>{0}</strong> finns redan!',
  'message.personalRequirementsErrorNoSiteCard': '<strong>Otillräcklig personlig information.</strong> Arbetsplatskort kan endast skapas efter att dessa brister har åtgärdats:',
  'message.personalRequirementsErrorNoTag': '<strong>Otillräcklig personlig information.</strong> Arbetsplatskort kan endast skapas efter att dessa brister har åtgärdats:',
  'message.personDeleted': 'Person <strong>{0}</strong> borttagen.',
  'message.personUpdatedAlert': 'Person uppdaterad.',
  'message.phoneNumberRequiredInfo': 'Medarbetarens telefonnummer används för tvåfaktorsautentisering för att leverera lösenordet och för att skicka viktiga arbetsmiljömeddelanden. Det går inte att lägga till flera användare i Zeroni med samma telefonnummer.',
  'message.pleaseAddCompetenceFirst': 'Lägg först till en kompetens.',
  'message.pleaseSpecifySearch': 'Förfina din sökning.',
  'message.quarterDeleted': 'Säkerhetsrum <strong>{0}</strong> borttaget.',
  'message.ratingHelp': 'Företagets utvärderingsgenomsnitt minst antal stjärnor. Du kan ta bort sökkriterier för betyg genom att klicka på stjärnan igen.',
  'message.regionHelp': 'Provins, där företaget har arbetat tidigare.',
  'message.returnIdHelp': '<strong>Obs!</strong> Om du returnerar ID:t upphör varje tillträdestillstånd där det finns. Detta gäller såväl nuvarande som framtida tillstånd.',
  'message.scanBothSidesOfResidencePermitDocument': 'Kom ihåg att skanna båda sidorna av uppehållstillståndet.',
  'message.selectContractFirst': 'Välj ett avtal först',
  'message.selectEmployerFirst': 'Välj en arbetsgivare först.',
  'message.selectEndDate': '<strong>{{vm.task.text}}</strong> kan ta flera dagar. Välj slutdatum.',
  'message.selectPaymentsForAllocation': '<strong>Välj</strong> betalningar som ska tilldelas.',
  'message.selectSiteFirst:': 'Välj en arbetsplats först: ',
  'message.selectSiteFirst': 'Välj en arbetsplats först.',
  'message.separateReferencesWithCommas': 'Separera betalningsreferenser med kommatecken.',
  'message.sessionExpired': '<strong>Du har varit inaktiv under en lång tid</strong> så du har blivit utloggad. Logga in igen.',
  'message.settingsUpdated': 'Inställningar uppdaterade.',
  'message.showGeneralEvaluationForCompany': 'Visa genomsnittlig utvärdering för företaget <strong>{0}</strong>',
  'message.sickLeaveReason': 'Ange orsaken till sjukskrivningen.',
  'message.siteAdded': 'Arbetsplatsen <a href="#!/sites/{0}/">{1} {2}</a> har lagts till.',
  'message.siteFilterInPersonSearchHelp': 'Om arbetsplatsen väljs visas endast medarbetare med aktivt tillträdestillstånd.',
  'message.siteNumberTaken': '<strong>Numret är upptaget!</strong> Arbetsplatsnumret du angav används redan. Varje arbetsplats behöver ett unikt nummer.',
  'message.siteUpdated': 'Arbetsplatsen<a href="#!/sites/{0}/">{1} {2}</a> har uppdaterats.',
  'message.tagIdLongToShort': 'Det ID:t ({0})såg ut som det interna numret för en tagg på en nyckelring. Jag bytte ID till taggens kortkod.',
  'message.taxNumber.goToInvalidFields': 'Flytta till ogiltiga fält',
  'message.taxNumber.invalidTaxNumFields': '<strong>Personuppgifterna är felaktiga enligt Skatteverket.</strong> Korrigera uppgifterna före uppdatering.',
  'message.taxNumberFormat': '12 siffror som börjar med ”1000”.',
  'message.taxNumberInvalid': 'Skattenummer och födelsedatum stämmer INTE överens i Skatteverkets offentliga register över skattenummer.',
  'message.taxNumberPendingCheck': 'Det finns problem med verifiering av skattenummer för tillfället. Giltigheten av personuppgifter kan inte verifieras. Verifieringen kommer att försökas igen varannan timme.',
  'message.taxNumberValid': 'Skattenummer, födelsedatum och namn stämmer överens i Skatteverkets register.',
  'message.trustedPartnerConnectionError': 'Det går inte att söka efter Reliable Partners. (Anslutningsfel: Vastuugroup.fi svarar inte.)',
  'message.tryAgainOrContactSupport': 'Försök igen eller kontakta supporten.',
  'message.unknownUsername': 'Zeroni känner inte igen det angivna användarnamnet. Kontrollera ditt användarnamn.',
  'message.userAdded': 'Användaren <strong>{0}</strong> har lagts till.',
  'message.userGroupAdded': 'Användarrollen <strong>{0}</strong> har lagts till.',
  'message.userUpdated': 'Användare <strong>{0}</strong> uppdaterad.',
  'message.valttiCardNotFound': 'Ingen person kunde hittas med angivet Valtti-kortnummer. Om kortet är nytt har det troligen inte uppdaterats i Suomen Vastuu Group Oy:s databas än.',
  'message.ValttiCardPersonExists': 'Personen <strong>{1}</strong> finns redan. <a href="{0}">Visa person <span class="far fa-angle-right"></span></a>',
  'message.valttiIdToBarCode': 'Det ID:t ({0}) såg ut som det interna numret för ett Valtti-kort. Jag bytte ID till kortets streckkod.',
  'message.workerStatement': 'Jag har läst och bekräftat introduktionsformuläret. Jag har erforderliga säkerhetskunskaper och personlig skyddsutrustning. Jag känner till och förbinder mig att följa säkerhetsföreskrifterna på den här arbetsplatsen.',
  'message.wrongFormatForFinnishBusinessId': '<strong>Felaktigt format!</strong> Finska företags-ID:n har formatet <strong>1234567-8</strong>.',
  'messaging.activity.all': 'Alla',
  'messaging.activity.contracts': 'Aktivitet för kontaktens avtal',
  'messaging.activity.onlyActive': 'Aktiv',
  'messaging.activity.onlyActiveOrPending': 'Aktiv och väntande',
  'messaging.activity.onlyPending': 'Väntar',
  'messaging.activity.permits': 'Tillståndsaktivitet',
  'messaging.bulletinBoard.noEnglish.desc': 'Den saknade texten fylls i från det finska fältet.',
  'messaging.bulletinBoard.noEnglish.title': 'Titeln eller innehållet har ingen engelsk översättning.',
  'messaging.bulletinBoard.noFinnish.desc': 'Den saknade texten fylls i från det engelska fältet.',
  'messaging.bulletinBoard.noFinnish.title': 'Titeln eller innehållet har ingen finsk översättning.',
  'messaging.category': 'Kategori',
  'messaging.companyRelation.all': 'Alla',
  'messaging.companyRelation.onlyOther': 'Endast andra',
  'messaging.companyRelation.onlyOwn': 'Endast egen',
  'messaging.companyRelation.ownOrOtherEmployees': 'Alla/egna medarbetare',
  'messaging.companyRelation.ownOrOtherForemen': 'Alla/egna arbetsledare',
  'messaging.content': 'Innehåll',
  'messaging.defaultSubject': 'Meddelande från Zeroni',
  'messaging.emailAttachments': 'Bilagor',
  'messaging.foremen.findAll': 'Hitta arbetsledare och ställföreträdande arbetsledare från alla arbetsplatser',
  'messaging.foremen.only': 'Endast arbetsledare och ställföreträdande arbetsledare',
  'messaging.messageSendFailedAlert': 'Meddelandet kunde inte skickas: {0}',
  'messaging.messageSentAlert': 'Meddelandet har skickats.',
  'messaging.newMessage': 'Nytt meddelande',
  'messaging.receiverName': 'Mottagarnamn',
  'messaging.recipient': 'mottagare',
  'messaging.recipients': 'mottagare',
  'messaging.recipientsLabel': 'Mottagare',
  'messaging.recipientsWithMissingEmail': 'Vissa mottagare saknar e-postadress',
  'messaging.recipientsWithMissingPhone': 'Vissa mottagare saknar telefonnummer',
  'messaging.searchFilters': 'Sökfilter',
  'messaging.selectedContractNotActive': 'Det valda avtalet är inte längre aktivt.',
  'messaging.send': 'Skicka',
  'messaging.sender': 'Avsändare',
  'messaging.sending': 'Skickar ...',
  'messaging.sendMessage': 'Skicka meddelanden',
  'messaging.sent': 'Skickat!',
  'messaging.smsPrice': 'Pris: {0} cent/meddelande',
  'messaging.subject': 'Ämne',
  'messge.deviceUpdatedAlert': 'Enhet uppdaterad.',
  'monitoring.allReports': 'Alla rapporter',
  'monitoring.allSources': 'Alla källor',
  'monitoring.anonymous': 'Anonym',
  'monitoring.deviation': 'Avvikelse',
  'monitoring.estimatedRepairDate': 'Beräknat reparationsdatum',
  'monitoring.fromMeasurement': 'Från mätning',
  'monitoring.layouts': 'Planritningar',
  'monitoring.measurementType': 'Typ av mätning',
  'monitoring.negativeReport': 'Negativ rapport',
  'monitoring.newPositiveReport': 'Ny positiv rapport',
  'monitoring.noOpenReports': 'Inga öppna rapporter',
  'monitoring.openReports': 'Öppna rapporter',
  'monitoring.positiveEnvironmentReport': 'Positiva miljörapporter',
  'monitoring.positiveEnvironmentReports': 'Positiva miljörapporter',
  'monitoring.positiveHelp': '<strong>Positiv rapport</strong> kan rapporteras när säkerhets- eller miljöfrågor har hanterats exceptionellt bra.',
  'monitoring.positiveReport': 'Positiv rapport',
  'monitoring.positiveReports': 'Positiva rapporter',
  'monitoring.positives': 'Positiva',
  'monitoring.positiveSafetyReport': 'Positiv säkerhetsrapport',
  'monitoring.positiveSafetyReports': 'Positiva säkerhetsrapporter',
  'monitoring.process': 'Process',
  'monitoring.repaired': 'Reparerad',
  'monitoring.repairedReports': 'Reparerade rapporter',
  'monitoring.reportInfo': 'Rapportinformation',
  'monitoring.selectArea': 'Välj område',
  'monitoring.selectReporter': 'Välj rapportör',
  'monitoring.separatelyAddedPlural': 'Separat tillagd',
  'monitoring.separatelyAddedSingular': 'Separat tillagd',
  'monitoring.teehavainto': 'Teehavainto.fi',
  'months.short': 'mån',
  'months': 'månader',
  'more': 'Mer',
  'moreInformation.rootDomain': 'Mer information ({0})',
  'moved': 'Flyttad',
  'new': 'Nytt',
  'newLine': 'New rad',
  'next': 'Nästa',
  'noContractorObligationRaport': 'Ingen rapport hittades',
  'noLayouts': 'Arbetsplatsen har inga planritningar. Lägg till planritningar från fliken Hantera.',
  'notification.maintenance': 'Zeroni har ett underhållsstopp vid {0}. Underhållsstoppet tar cirka {1} minuter.',
  'observation.abbr': 'Obs!',
  'observation': 'Observationer',
  'ordererSignature': 'Byggherrens underskrift',
  'orientation.a1Certificate': 'Socialförsäkringsintyg:',
  'orientation.a1CertificateExpires': 'Utgångsdatum för socialförsäkringsintyg:',
  'orientation.a1Certificates': 'Socialförsäkringsintyg',
  'orientation.additionalInfo': 'Övrig info',
  'orientation.additionalInfoForm': 'Övrig info',
  'orientation.addOnlineSignatures': 'Lägg till onlineunderskrifter',
  'orientation.approxWorkingPeriod': 'Beräknad arbetsperiod:',
  'orientation.backToEditMode': 'Redigeringsläge',
  'orientation.cantPerformOrientationBody': 'Medarbetarens introduktion kan inte bekräftas eftersom den nödvändiga kunskapen saknas.',
  'orientation.cantPerformOrientationHeading': 'Introduktionen kan inte bekräftas',
  'orientation.city': 'Stad:',
  'orientation.competence': 'Kompetens',
  'orientation.competences': 'Kompetenser:',
  'orientation.completed': 'Slutförd',
  'orientation.completeOnlineOrientation': 'Fullständig onlineintroduktion',
  'orientation.confirmOrientation': 'Bekräfta introduktion',
  'orientation.date': 'Datum:',
  'orientation.developer': 'Byggherre:',
  'orientation.documents': 'Handlingar',
  'orientation.driverlicense': 'Körkort',
  'orientation.emailAddress': 'E-postadress:',
  'orientation.employed': 'Anställd',
  'orientation.employee': 'Medarbetare',
  'orientation.employeeBirthDate': 'Födelsedatum:',
  'orientation.employeeEmail': 'E-postadress:',
  'orientation.employeeExperience': 'Medarbetarens erfarenhet:',
  'orientation.employeeFirstName': 'Förnamn:',
  'orientation.employeeHealthCardExpirationDate': 'Hälsokortets utgångsdatum:',
  'orientation.employeeInfo': 'Personliga uppgifter:',
  'orientation.employeeLastName': 'Efternamn:',
  'orientation.employeePhone': 'Telefonnummer:',
  'orientation.employeeTaxNumber': 'Skattenummer:',
  'orientation.employer': 'Arbetsgivare:',
  'orientation.employmentRelation': 'Anställningsförhållande:',
  'orientation.entrepreneur': 'Entreprenör',
  'orientation.errorMsg.orientAnyway': 'Introducera ändå',
  'orientation.errorMsg': 'Skapandet av introduktionshandlingen misslyckades. Problemet beror troligen på en dålig internetanslutning. Du kan försöka skapa dokumentet igen genom att klicka på knappen ovan eller så kan du hoppa över skapandet av dokumentet och introducera ändå. Dokumentet kan skapas senare i redigeraren för tillträdestillstånd. Om du använder ett WiFi-nätverk bör du överväga att flytta närmare WiFi-routern. Du kan också prova att använda telefonens mobilnät om du för närvarande använder WiFi.',
  'orientation.errorMsgHeader': 'Fel vid skapande av dokument',
  'orientation.expatRepresentative': 'Representant i Finland för utländska medarbetare',
  'orientation.expiration': 'Sista giltighetsdatum',
  'orientation.filename': 'introduktionsform.pdf',
  'orientation.finnishAddress': 'Finsk adress',
  'orientation.foreignAddress': 'Utländsk adress',
  'orientation.foreignWorkerInfo': 'Information om utländsk arbetstagare',
  'orientation.homeCountry': 'Hemland:',
  'orientation.idcard': 'Legitimation',
  'orientation.idConfirmationType': 'Typ av ID-bekräftelse:',
  'orientation.instructor': 'Instruktör',
  'orientation.known': 'Känt',
  'orientation.mainContractor': 'Huvudentreprenör:',
  'orientation.mentor': 'Mentor',
  'orientation.nationality': 'Nationalitet:',
  'orientation.notNeeded': 'Ej aktuellt',
  'orientation.notUsed': 'Används ej',
  'orientation.orient': 'Introduktion',
  'orientation.orientationReport': 'Introduktionsrapport',
  'orientation.orientOnPaper': 'Introduktion på papper',
  'orientation.passport': 'Pass',
  'orientation.permanent': 'Permanent',
  'orientation.postcode': 'Postnummer:',
  'orientation.primaryLanguage': 'Språk:',
  'orientation.printAvailableAtNextStep': 'Introduktionsformuläret kan skrivas ut efter detta steg.',
  'orientation.printOrientationForm': 'Skriv ut introduktionsformulär',
  'orientation.profession': 'Yrke:',
  'orientation.representative': 'Representant:',
  'orientation.residencePermit': 'Uppehållstillstånd:',
  'orientation.residencePermitExpires': 'uppehållstillståndet går ut:',
  'orientation.saveAndProceedToOrientation': 'Spara och fortsätt till introduktion',
  'orientation.showOrientationForm': 'Visa introduktionsämnen',
  'orientation.signatureClarification': 'Namnförtydligande',
  'orientation.signatures': 'Signaturer',
  'orientation.signatureText': 'Medarbetaren som introduceras och instruktören bekräftar med dessa underskrifter att medarbetaren har nödvändig information om arbetssäkerhet och den personliga utrustning som krävs enligt föreskrifterna. Medarbetaren känner till arbetsplatsens arbetsmiljöinstruktioner och förbinder sig att följa dem.',
  'orientation.siteAddress': 'Arbetsplatsens adress:',
  'orientation.siteForeman': 'Arbetsplatsens arbetsledare:',
  'orientation.siteForemanPhone': 'Telefonnummer till arbetsplatsens arbetsledare:',
  'orientation.siteInfo': 'Arbetsplatsinformation',
  'orientation.siteName': 'Arbetsplatsnamn:',
  'orientation.siteNumber': 'Arbetsplatsnummer:',
  'orientation.streetAddress': 'Adress:',
  'orientation.tasks': 'Uppgifter:',
  'orientation.telephone': 'Telefonnummer:',
  'orientation.temporary': 'Tillfälligt',
  'orientation.topic': 'Introduktionsämne',
  'orientation.topics': 'Introduktionsämnen',
  'orientation.topicsForSite': 'Ämnen för arbetsplatsen',
  'orientation.toSite': 'Bekräfta introduktion',
  'orientation.underageMentor': 'Minderårigs mentor',
  'orientation.unpaid': 'Obetald',
  'orientation.valttiCard': 'Valtti-kort:',
  'otherLocation': 'Annan plats',
  'otherServices': 'Övriga tjänster',
  'param.languageSortKey': 'nameEnglish',
  'passiveLayouts': 'Passiva planritningar',
  'password.changedMessage': 'Lösenordet ändrades',
  'password.changePassword': 'Ändra lösenord',
  'password.changePasswordFailed': 'Det gick inte att uppdatera lösenordet.',
  'password.changing': 'Ändrar ...',
  'password.errorASYNC_VALIDATION_RUNNING': ' ',
  'password.errorERROR': 'Fel vid validering av lösenordets styrka',
  'password.errorKNOWN': 'Angivet lösenord känns igen som för vanligt och är inte tillåtet.',
  'password.errorTOO_LONG': 'Lösenordet får inte vara längre än 256 tecken',
  'password.errorTOO_SHORT': 'Lösenordet måste innehålla minst 12 tecken',
  'password.failedToSubmit': 'Det gick inte att uppdatera lösenordet. Var god försök igen. Om problemet kvarstår, kontakta support (support@zeroni.fi).',
  'password.invalidPasswordMessage': '<strong>Fel lösenord</strong>: Kontrollera ditt gamla lösenord och försök igen',
  'password.newPassword': 'Nytt lösenord',
  'password.oldPassword': 'Gammalt lösenord',
  'password.oldPasswordRequired': 'Gammalt lösenord måste anges',
  'password.showPassword': 'Visa/dölj lösenord',
  'password.strength.fair': 'Sådär',
  'password.strength.good': 'Bra',
  'password.strength.great': 'Mycket bra',
  'password.strength.weak': 'Svagt',
  'password.validationMinLength': 'Lösenordet måste innehålla minst 12 tecken',
  'payment.addNewPayment': 'Lägg till ny betalning',
  'payment.addPayment': 'Lägg till betalning',
  'payment.allPayments': 'Alla betalningar',
  'payment.confirmRemoval': 'Ta bort betalning <strong>{0}</strong>?',
  'payment.confirmRemovals': 'Du tar bort <strong>{0}</strong> betalningar, totalt <strong>{1}</strong>. Är du säker på att du vill fortsätta?',
  'payment.editPayment': 'Redigera betalning',
  'payment.estimatedPriceLowerThatn15k': 'Uppskattat pris under 15 t€.',
  'payment.estimatedSum': 'Uppskattad',
  'payment.importedFromExternalSystem': 'Skapad av annat system',
  'payment.latestPayment': 'Senaste betalning',
  'payment.noEstimatedSum': 'Ingen uppskattning',
  'payment.noPayments': 'Inga betalningar',
  'payment.noTaxReportsAreSent': 'Inga skatterapporter skickas.',
  'payment.noTaxReportsFromThisContract': 'Inga skatterapporter skickas från detta avtal.',
  'payment.noTaxReportsFromThisSite': 'Inga skatterapporter från betalningarna för den här arbetsplatsens avtal skickas. Se arbetsplatsens inställningar.',
  'payment.removePayment': 'Ta bort betalning',
  'payment.removePaymentHeading': 'Ta bort betalning?',
  'payment.removePayments': 'Ta bort betalningar',
  'payment.removePaymentsHeading': 'Ta bort betalningar?',
  'payment.searchUnallocatedByCompanyName': 'Sök efter företagsnamn',
  'person.addTag': 'Lägg till tagg',
  'person.addTagToPerson': 'Lägg till tagg till <strong>{0}</strong>',
  'person.cardExpired': 'Kortet har gått ut!',
  'person.chooseRepresentative': 'Välj representant',
  'person.deactivatedNotification': '<strong>{0}</strong> har avbrutits. Om du vill avbryta denna person, kontakta supporten (support@zeroni.fi).',
  'person.doesNotLookEmployeeEmail': 'E-postadressen stämmer inte överens med för- och efternamnet. Vill du fortfarande använda den här adressen?',
  'person.drugTest.approvalDate': 'Godkänd den {0}.',
  'person.drugTest.expirationDate': 'Giltig till {0}.',
  'person.formerExpatRepresentatives': 'Tidigare använda representanter',
  'person.hotWorkPermitViaManagement': 'Tillstånd för heta arbeten läggs till via hanteringsavsnittet.',
  'person.identifierFound': 'Person hittades med angiven identifierare. Kontrollera uppgifterna i formuläret.',
  'person.incompleteInfo': 'Ofullständiga uppgifter',
  'person.incompleteInfoForOrientation': 'Person <strong>{0}</strong> saknar vissa uppgifter som behövs för att bekräfta introduktion.',
  'person.invoiceHourDefaultApprover': 'Standardgodkännare för faktureringstimmar',
  'person.isNameCorrect': '<strong>Efternamnet ("{0}") liknar ett förnamn.</strong> Ska vi byta för- och efternamn?',
  'person.nationalityFailedToAdd': 'Det gick inte att uppdatera nationalitet.',
  'person.nationalityMissingAlert': 'Personen har ingen nationalitet i sina register.',
  'person.nationalityMissingLink': 'Lägg till nationalitet',
  'person.nationalityRequiredBeforeAccessPermits': 'Nationalitet krävs innan tillträdestillstånd skapas.',
  'person.noAccessPermits': 'Inga tillträdestillstånd.',
  'person.noCompetences': 'Inga kompetenser.',
  'person.noNameIsCorrent': 'Nej, namnet är korrekt',
  'person.noTags': 'Inga taggar.',
  'person.noTagsWarning': '<strong>Inga taggar.</strong> Denna person har dock tillstånd till en arbetsplats med en incheckningsenhet.',
  'person.oldTaxNumberHelp': '<p>Om personen inte anmäls till Skatteverket inom två år tas personens skattenummer bort från det offentliga skattenummerregistret.</p><p><strong>Ett skattenummer registreras</strong> i <a href="https://www.vero.fi/sv/e-tjanster/minskatt/" target="_blank" rel="noopener">MinSkatt <span class="fas fa-external-link-alt"></span></a> (vero.fi), genom att ringa servicelinjen för skattenummer <a href="tel:+358 29 497 070">029 497 070</a> eller besöka ett Skatteverkets kontor.</p><p>Mer information: <a target="_blank" rel="noopener" href="https://www.vero.fi/sv/privatpersoner/skattekort-och-skattedeklaration/fran_utlandet_till_finland/arbeta_i_finland/anvisningar-f%C3%B6r-olika-yrkesgrupper/arbeta-p%C3%A5-en-byggarbetsplats-eller-ett-varv/skattenummer/">skattenummer krävs på byggarbetsplatser <span class="fas fa-external-link-alt"></span></a> (vero.fi)</p>',
  'person.onlyPersonCanChange': 'Endast personen själv kan ändra.',
  'person.pendingSecurityClearance': 'Väntar på säkerhetskontroll. Tillämpad den {0}.',
  'person.personNumber': 'Personnummer',
  'person.personRole': 'Roll',
  'person.previousWorkOverTaxAdminLimit': 'Arbetade personen senast för mer än 2 år sedan?',
  'person.safetyProcedures': 'Säkerhetsföreskrifter',
  'person.search.noneOnThisSite': '<p>Inga personer hittades på den valda arbetsplatsen ({0}).</p>',
  'person.search.pendingVerification': 'Väntar på verifiering av arbetsrätt',
  'person.searchOrSelectPerson': 'Person',
  'person.securityClearance.created': 'Skapad den {0}.',
  'person.securityClearance.validity': 'Giltig {0}.',
  'person.securityClearance.waitingForReply': 'Väntar på svar.',
  'person.submitMissingDetailsBeforeOrientation': 'Skicka in saknade uppgifter för att gå vidare till introduktion.',
  'person.tags': 'Taggar',
  'person.taxNumber.nameSuggestion': 'Enligt skattenummerregistret är rätt namn på personen <strong>{0}, {1}</strong>. Vill du använda det här namnet?',
  'person.taxNumber.verificationRequirements': 'Utöver skattenummer, fyll i för- och efternamn nedan.',
  'person.taxNumber.verifying': 'Verifierar ...',
  'person.taxNumber.verifyingTaxNumber': 'Verifierar skattenummer ...',
  'person.taxNumberChecked': 'Jag har kontrollerat numret och allt stämmer',
  'person.valttiOrAddBelow': 'Ange ett Valtti-kortnummer eller ange personens uppgifter nedan.',
  'person.yesSwitchNames': 'Ja, byt namn',
  'presence.help': 'Markera medarbetare som har arbetat på arbetsplatsen. Information används i medarbetarrapport som skickas till Skatteverket.',
  'presence.personHasCheckIns': 'Personen har incheckningar för vald månad',
  'presence.taxNumberMissing': 'Skattenummer saknas',
  'presence.workersPresentByMonth': 'Arbetstagares närvaro per månad',
  'presence': 'Närvaro',
  'previous': 'Föregående',
  'quality.addFirstDefect': 'Lägg till första kvalitetsfel',
  'quality.defect': 'Kvalitetsfel',
  'quality.defectInfo': '<strong>Kvalitetsfel</strong> rapporteras när det finns problem med arbetets kvalitet.',
  'quality.defects.searchResults.many': '{0} avvikelser',
  'quality.defects.searchResults.one': '1 avvikelse',
  'quality.defects.stepsToPreventInFuture': 'Steg att förebygga i framtiden',
  'quality.defects.underlyingReason': 'Bakomliggande orsak till felet',
  'quality.defects': 'Kvalitetsfel',
  'quality.faultList': 'Lista över fel',
  'quality.faultLists': 'Lista över fel',
  'quality.inspections': 'Inspektioner',
  'quality.measurement': 'Kvalitetsmätning',
  'quality.measurements': 'Kvalitetsmätningar',
  'quality.newDefect': 'Nytt kvalitetsfel',
  'quality.noDefects': 'Inga fel',
  'quality.noOpenDefects': 'Inga öppna fel',
  'quality.noRepairedDefects': 'Inga reparerade fel',
  'quality.openFaultLists': 'Öppna fellistor',
  'quality.openFaultListsShort': 'Öppna listor',
  'quality.openReports': 'Öppna fel',
  'quality.quality': 'Kvalitet',
  'raport.layoutInfo': 'Arbetsplatsen har inga planritningar. <a ui-sref="main.sites.site.manage.layouts">Lägg till planritning härifrån.</a>',
  'ratingTitles': '[”En”, ”Två”, ”Tre”, ”Fyra”, ”Fem”]',
  'removeMarker': 'Ta bort markör',
  'report.accessPermitsWithCheckInsFromTo': 'Tillträdestillstånd med incheckningar från {0} till {1}',
  'report.contractContact.businessId': 'Företags-ID',
  'report.contractContact.company': 'Företag',
  'report.contractContact.contractContact': 'Avtalskontakter',
  'report.contractContact.contractContactFileName': 'Avtalskontakter',
  'report.contractContact.contractContactReport': 'Avtalskontakter-rapport',
  'report.contractContact.email': 'E-post',
  'report.contractContact.filterContract': 'Avtal:',
  'report.contractContact.filterManaged': 'Visa endast kontakter med hanteringsrättigheter.',
  'report.contractContact.filterManagedTitle': 'Hanterad',
  'report.contractContact.filterSite': 'Arbetsplats:',
  'report.contractContact.filterSubcontractors': '(underentreprenörer inkluderade).',
  'report.contractContact.firstName': 'Förnamn',
  'report.contractContact.info': 'Skapar rapport om avtalskontakter',
  'report.contractContact.lastName': 'Efternamn',
  'report.contractContact.telephone': 'Telefon',
  'report.contractorObligation.info': 'Hämta rapport om entreprenörsåtaganden',
  'report.device.info': 'Visar information om alla enheter i ditt företag.',
  'report.device': 'Enhetsrapport',
  'report.deviceReportShort': 'Enheter',
  'report.employeeSafetySummary.info': 'Säkerhetssammanfattning',
  'report.employeeSafetySummary': 'Säkerhetssammanfattning',
  'report.evaluation.evaluationAllSites': 'Alla utvärderingar',
  'report.evaluation.evaluationAllSitesTooltip': 'Skapar en rapport som innehåller alla utvärderingar från alla arbetsplatser',
  'report.evaluation.info': 'Skapa utvärderingsrapport',
  'report.evaluationsReport.info': 'Skapa utvärderingsrapport',
  'report.expats': 'Utstationerade arbetstagare',
  'report.expatsOnlyDesc': 'Visa endast utstationerade arbetstagare',
  'report.manpower.info': 'Rapporten listar alla avdelningar som har stämplat in medarbetare per område',
  'report.manpower': 'Områdesrapport för arbetsstyrkan',
  'report.manpowerReportShort': 'Områden för arbetsstyrkan',
  'report.monitoring.timeSpanInfo': 'Inkluderar de mätningar som gjorts under det angivna intervallet eller avvikelser som inträffat under intervallet.',
  'report.presence.info': 'Rapporten visar alla personer som för närvarande är instämplade på en arbetsplats.',
  'report.presence': 'Personrapport',
  'report.quality.info': 'Skapar en Excel-fil som listar alla kvalitetsmätningar eller kvalitetsavvikelser.',
  'report.quality.quality': 'Kvalitet',
  'report.quality.report': 'Kvalitetsrapport',
  'report.safety.bySite': 'En arbetsplats',
  'report.safety.confirmTime': 'Bekräfta tid',
  'report.safety.createBySiteTooltip': 'Skapar en rapport för den givna arbetsplatsen',
  'report.safety.custom': 'Anpassad mätning',
  'report.safety.hazards': 'Faror',
  'report.safety.hazardsbysite': 'Faror från alla arbetsplatser',
  'report.safety.info.more': 'Skapa en Excel-tabell med resultat från utförda säkerhetsmätningar, mätningar av specialobjekt, positiva säkerhetsobservationer eller säkerhetsavvikelser.<br/><br/>Säkerhetsmätningsrapporten innehåller inga särskilda specialobjekt. Resultaten från specialobjekten finns i specialobjektsrapporten.',
  'report.safety.info': 'Skapar en Excel-fil som listar alla säkerhetsmätningar, anpassade mätningar, säkerhetsavvikelser eller positiva säkerhetsrapporter.',
  'report.safety.report': 'Rapport',
  'report.safety.safety': 'Säkerhet',
  'report.safety.safetyreport': 'Säkerhetsrapport',
  'report.safety.site': 'Arbetsplats',
  'report.safety.timespan': 'Tidsintervall',
  'report.safety.timespanTooltip': 'Inkluderar mätningar eller faror som skapats under den angivna tidsperioden.',
  'report.safety.trmvrbyallsites': 'Mätningar från alla arbetsplatser',
  'report.safety.trmvrbysite': 'Sammanslagning av mätningar från alla arbetsplatser',
  'report.safety.trmvrbysiteTooltip': 'Sammanslagning av mätningar skapar en rapport med alla arbetsplatser, där mätningarna för varje arbetsplats sammanfattas på en rad',
  'report.serviceCalls': 'Servicebesök',
  'report.taxAdmin.info': 'Bläddra bland medarbetar- och avtalsrapporter som skickats till Skatteverket',
  'report.unresolvedContractPayments.info': 'Skapar Excel-rapport från entreprenörsbetalningar som inte kunde tilldelas entreprenören automatiskt',
  'report.unresolvedContractPayments.onlyFromLast13Monthsinfo': 'Endast betalningar från de senaste 13 månaderna kan påverka skatteredovisningen.',
  'report.unresolvedContractPayments.report': 'Rapport över ej tilldelade betalningar',
  'report.unresolvedContractPayments.showOnlyFromLast13Months': 'Visa ej tilldelade betalningar endast från de senaste 13 månaderna',
  'report.unresolvedContractPayments.summaryinfo': 'Lista alla ej tilldelade betalningar, sammanfattning efter arbetsplatsidentifierare/entreprenör eller sammanfattning efter arbetsplatsidentifierare.',
  'report.unresolvedContractPayments.summaryselection.all': 'Alla',
  'report.unresolvedContractPayments.summaryselection.contractor': 'Entreprenörssammanfattning',
  'report.unresolvedContractPayments.summaryselection.site': 'Arbetsplatssammanfattning',
  'report.workHourBalance.info': 'Skapar rapport över arbetstidssaldo.',
  'report.workTime.deductLunch': 'Dra av 30 minuters lunchrast när totalt antal timmar per dag är 6 eller mer',
  'report.workTime.officialHours': 'Visa endast timmar från 7:00 till 15:30',
  'report.workTime.onlyOneCheckInPairPerDay': 'Beräkna endast timmar från dagens första incheckning till dagens sista utcheckning',
  'report.workTime.workTime': 'Arbetstid',
  'reporterName': 'Rapportörens namn',
  'reportersName': 'Rapportörens namn',
  'reports.contractorNotification': 'Meddelande till entreprenör',
  'reports.noRights': 'Du har inte behörighet för denna rapport.',
  'reports.notifyContractor': 'Meddela entreprenör',
  'reports.returnToReports': 'Tillbaka till rapporter',
  'reports.searchResults.many': '{0} rapporter',
  'reports.searchResults.one': '1 rapport',
  'reports.selectedReports': 'Valda rapporter',
  'reports.selectOneOrMore': 'Välj en eller flera rapporter',
  'reports.sendingManyReports': 'Meddelande om <strong>{0}</strong> rapporter',
  'reports.sendingOneReport': 'Avisera om 1 rapport',
  'residencePermit.continuousResidencePermit': 'Kontinuerligt uppehållstillstånd',
  'residencePermit.longTermBrexitResidencePermit': 'Uppehållsrätt enligt utträdesavtalet',
  'residencePermit.longTermEuResidencePermit': 'EU-uppehållstillstånd för tredjelandsmedborgare med långtidsuppehåll i EU',
  'residencePermit.permanentBrexitResidencePermit': 'Uppehållsrätt enligt utträdesavtalet',
  'residencePermit.permanentResidencePermit': 'Permanent uppehållstillstånd',
  'residencePermit.residencePermitNotNeeded': 'Inget uppehållstillstånd krävs',
  'residencePermit.temporaryResidencePermit': 'Tillfälligt uppehållstillstånd',
  'safety.addDeviation': 'Lägg till säkerhetsavvikelse',
  'safety.addingDeviation': 'Lägga till säkerhetsavvikelse',
  'safety.additionalDetails': 'Övrig information',
  'safety.additionalDetailsAdded': 'Ytterligare information har lagts till',
  'safety.addNumberOfObservations': 'Lägg till {0} observationer',
  'safety.addPhoto': 'Lägg till foto',
  'safety.addReport': 'Lägg till rapport',
  'safety.allSites': 'Alla arbetsplatser',
  'safety.allSitesGraphHelp': 'Genomsnitt för alla säkerhetsmätningar på alla arbetsplatser',
  'safety.andMoreMeasurements': 'Och {0} andra mätningar. Visa alla',
  'safety.anonymousReport': 'Anonym rapport',
  'safety.anonymousReports': 'Anonyma rapporter',
  'safety.askForMore': 'Lägg till många?',
  'safety.average': 'Genomsnitt',
  'safety.averageLevel': 'Genomsnittsnivå',
  'safety.class': 'Klass',
  'safety.classes': 'Klasser',
  'safety.clear': 'Rensa',
  'safety.closedHazards': 'Stängda faror',
  'safety.confirm': 'Bekräfta',
  'safety.confirmBody': 'Markera den positiva rapporten som bekräftad. Du kan också lägga till en kommentar om du vill.',
  'safety.confirmed': 'Bekräftad',
  'safety.confirmer': 'Bekräftare',
  'safety.confirmerComment': 'Bekräfta kommentar',
  'safety.confirmHazard': 'Bekräfta observation',
  'safety.confirming': 'Bekräftar ...',
  'safety.confirmRepaired': 'Bekräfta reparation',
  'safety.confirmRepairedBody': 'Bekräfta att denna säkerhetsavvikelse har åtgärdats.',
  'safety.continueMeasurement': 'Fortsätt <strong>{0} mätning</strong>',
  'safety.continueMeasurementNoArg': 'Fortsätt mätningen',
  'safety.contractorHasNoPersons': 'Entreprenören har inga personer tillagda.',
  'safety.contractorInCharge': 'Ansvarig entreprenör',
  'safety.customMeasurement': 'Anpassad mätning',
  'safety.customSectionDetails': 'Anpassad avsnittsinformation',
  'safety.date': 'Datum',
  'safety.daysSickLeave': 'Sjukfrånvaro',
  'safety.daysSinceLatestInjury': 'dagar utan en skada',
  'safety.description': 'Beskrivning',
  'safety.deviation': 'Säkerhetsavvikelse',
  'safety.deviationHelp': '<p><span class="far fa-info-circle"></span><strong>En observation</strong> är en säkerhetsfråga som ännu inte har förverkligats.</p><p><strong>Ett tillbud</strong> är en händelse som har orsakat eller kan ha orsakat skada på andra än människor.</p><p>Om en person har skadats ska du anmäla <strong>en skada</strong>.</p>',
  'safety.deviations': 'Säkerhetsavvikelser',
  'safety.deviationsForContractorsAndEmployees': 'Entreprenörer och medarbetare kan rapportera säkerhetsavvikelser via <a target="_blank" href="http://teehavainto.fi">teehavainto.fi</a>.',
  'safety.deviationsForContractorsAndEmployeesRequirements': '<p>Krav:</p><ul><li>GPS</li><li>-rapportering på arbetsplats</li></ul>',
  'safety.elmeriMeasurement': 'Elmeri+ mätning',
  'safety.elmeriMeasurements': 'Elmeri+ mätningar',
  'safety.employeeRepresentativeSignature': 'Medarbetarrepresentantens underskrift',
  'safety.employerRepresentativeSignature': 'Arbetsgivarrepresentantens underskrift',
  'safety.entries': 'Poster',
  'safety.entriesTotal': 'Poster totalt',
  'safety.environmentalHazard': 'Miljömässiga risker',
  'safety.environmentalHazards': 'Miljömässiga risker',
  'safety.events': 'Säkerhetshändelser',
  'safety.followUpMeasures': 'Uppföljningsåtgärder',
  'safety.generalSettings': 'Allmänna inställningar',
  'safety.hazard': 'Säkerhetsrisk',
  'safety.hazardAlreadyRepairedMessage': 'Säkerhetsrisken har redan tidigare markerats som åtgärdad.',
  'safety.hazardHelp': '<strong>En säkerhetsrisk</strong> är en säkerhetsfråga som ännu inte har förverkligats.',
  'safety.hazardReason': 'Orsak',
  'safety.hazardReasonShort': 'Attn.',
  'safety.hazards': 'Säkerhetsrisker',
  'safety.hazardTeehavainto': 'Teehavainto.fi',
  'safety.help.markingAsRepaired': 'Markera säkerhetsrapporter som reparerade',
  'safety.help.subContractorPermissions': 'Underentreprenörens tillstånd till säkerhetsrapporter',
  'safety.hideDetails': 'Dölj detaljer',
  'safety.hideFixedReports': 'Dölj fasta poster',
  'safety.howToPreventInFuture': 'Hur man förebygger i framtiden',
  'safety.hse': 'Tillbud och skador',
  'safety.indexedDBunavailable': 'Din webbläsare stöder inte mätningen. Om du använder Safari ska du se till att det privata läget är avstängt.',
  'safety.injuredBodyPart': 'Skadad kroppsdel',
  'safety.injuries': 'Skador',
  'safety.injury': 'Skada',
  'safety.injuryHelp': 'Om någon skadats ska du anmäla <strong>en skada</strong>.',
  'safety.injuryInfo': 'Information om incidenten',
  'safety.lastSync': 'Mätning synkroniserad till server på',
  'safety.level': 'Nivå',
  'safety.location': 'Plats',
  'safety.manyUnsyncedReportsDanger': '<strong>Det finns många osparade riskrapporter.</strong> Besök ett område med bättre internetuppkoppling. Mätningen kan fortsätta när rapporterna har sparats.',
  'safety.manyUnsyncedReportsWarning': '<strong>Det finns ett par poster som inte är synkroniserade.</strong> Det skadar inte att spara. Kanske kan du gå någonstans med bättre internetuppkoppling, bara en liten stund?',
  'safety.markAsConfirmed': 'Bekräfta',
  'safety.markAsRepaired': 'Markera som reparerad',
  'safety.measure': 'Mätning',
  'safety.measurement.area.contractors.help': 'Du kan välja en eller flera entreprenörer',
  'safety.measurement.area.contractors': 'Entreprenörer verksamma på området',
  'safety.measurement.area.help': 'Mätningen börjar när området har valts.',
  'safety.measurement.area': 'Område',
  'safety.measurement.areas': 'Mätningsområden',
  'safety.measurement.contractor': 'Entreprenör',
  'safety.measurement.hazardsWaitingForRepair': 'öppna faror',
  'safety.measurement.hazardWaitingForRepair': 'öppen fara',
  'safety.measurement.showCustomReport': 'Visa anpassad rapport',
  'safety.measurement.showReport': 'Visa rapport',
  'safety.measurement.start': 'Starta mätning',
  'safety.measurement.type': 'Typ av mätning',
  'safety.measurement': 'Säkerhetsmätning',
  'safety.measurementAlreadyFinished': 'Mätningen har redan avslutats',
  'safety.measurementAlreadyFinishedBy': '<strong>{0}</strong> har redan avslutat denna mätning <strong>{1}</strong>',
  'safety.measurementFromPast': 'Tidigare genomförd mätning',
  'safety.measurementItems': 'Anteckningar',
  'safety.measurementLoadingFailed': 'Inläsning av mätning misslyckades. Gå tillbaka till avsnittet Säkerhet och försök starta om mätningen.',
  'safety.measurements.asphaltFactory.new': 'Ny <strong>Asfaltsmätning(fabrik)</strong>',
  'safety.measurements.asphaltFactory': 'Asfaltsmätning (fabrik)',
  'safety.measurements.asphaltPaving.new': 'Ny <strong>Asfaltsmätning (Asfalteringsarbetsplatser)</strong>',
  'safety.measurements.asphaltPaving': 'Asfaltsmätning (Asfalteringsarbetsplatser)',
  'safety.measurements.boring.new': 'Ny <strong>borrmätning</strong>',
  'safety.measurements.boring': 'Borrmätning',
  'safety.measurements.cleanlinessindex.new': 'Nytt <strong>renhetsindex</strong>',
  'safety.measurements.cleanlinessindex': 'Renhetsindex',
  'safety.measurements.crushing.new': 'Ny <strong>krossmätning</strong>',
  'safety.measurements.crushing': 'Krossmätning',
  'safety.measurements.general.new': 'Ny <strong>allmän säkerhetsmätning</strong>',
  'safety.measurements.general': 'Allmän säkerhetsmätning',
  'safety.measurements.mining.new': 'Ny <strong>gruvmätning</strong>',
  'safety.measurements.mining': 'Gruvmätning',
  'safety.measurements.repairs.new': 'Ny <strong>reparationsmätning</strong>',
  'safety.measurements.repairs': 'Reparationsmätning',
  'safety.measurements.sac.new': 'Ny <strong>säkerhets- och renhetsmätning</strong>',
  'safety.measurements.sac': 'Säkerhet och renhet',
  'safety.measurements.scaffolding.new': 'Ny <strong>byggnadsställningsmätning</strong>',
  'safety.measurements.scaffolding': 'Byggnadsställning',
  'safety.measurements': 'Arbetsmiljömätningar',
  'safety.measurementsShort': 'Mätningar',
  'safety.measurementType': '{0}-mätning',
  'safety.measurer': 'Mätare',
  'safety.mostUsed': 'Mest använda',
  'safety.moveToFinishedMeasurementSummary': 'Gå till mätningssammanfattning',
  'safety.mvrMeasurement': 'MVR-mätning',
  'safety.mvrMeasurements': 'MVR-mätningar',
  'safety.nearMiss': 'Tillbud',
  'safety.nearMisses': 'Tillbud',
  'safety.nearMissesShort': 'Tillbud',
  'safety.nearMissHelp': '<strong>Ett tillbud</strong> är en händelse som har orsakat eller kan ha orsakat skada på andra än människor.',
  'safety.nearMissInfo': 'Information om situationen',
  'safety.newEnvironmentalHazard': 'Ny miljöfara',
  'safety.newHazard': 'Ny fara',
  'safety.newHazardWithStrong': 'Ny <strong>fara</strong>',
  'safety.newInjury': 'Ny skada',
  'safety.newInjuryWithStrong': 'Ny <strong>skada</strong>',
  'safety.newMeasurement': 'Ny <strong>{0} mätning</strong>',
  'safety.newMeasurement1': 'Ny mätning',
  'safety.newNearMiss': 'Nytt tillbud',
  'safety.newNearMissWithStrong': 'Nytt <strong>tillbud</strong>',
  'safety.newSACMeasurement': 'Ny <strong>säkerhets- och renhetsmätning</strong>',
  'safety.noAnonymousReports': 'Inga anonyma rapporter',
  'safety.noHazards': 'Inga faror',
  'safety.noHazardsWithSelections': 'Inga säkerhetsrisker med aktuella val.',
  'safety.noInjuries': 'Inga skador',
  'safety.noLocationNoDeviationHelp': 'Huvudentreprenören har inte definierat ett område för denna arbetsplats. Nya säkerhetsavvikelser kan läggas till efter att området har definierats.',
  'safety.noNearMisses': 'Inga tillbud',
  'safety.noOpenEnvironmentalHazards': 'Inga öppna miljöfaror',
  'safety.noOpenHazards': 'Inga öppna faror',
  'safety.noRepairedEnvironmentalHazards': 'Inga reparerade miljöfaror',
  'safety.noRepairedHazards': 'Inga reparerade faror',
  'safety.numberOfObservations': 'Nummer som ska läggas till',
  'safety.offlineMeasurement': '<strong>Inget internet.</strong> Mätningen kan fortsätta, men en internetanslutning krävs för att mätningen ska avslutas.',
  'safety.openDeviationsShort': 'Öppen',
  'safety.openHazards': 'Öppna faror',
  'safety.openHazardsShort': 'Öppna faror',
  'safety.otherCompany': 'Annat företag',
  'safety.pendingReparation': 'Väntande reparation',
  'safety.peopleInjured': 'Personer som skadats',
  'safety.peopleInvolved': 'Personer som deltagit',
  'safety.performMeasurement': 'Genomför mätning',
  'safety.photo': 'Foto',
  'safety.photos': 'Foton',
  'safety.positiveHelp': '<strong>Positiva rapporter</strong> kan göras när föredömliga säkerhets- eller arbetsmiljöåtgärder har vidtagits.',
  'safety.previousLocation': 'Föregående plats',
  'safety.quarterPermissions': 'Behörigheter för säkerhetsrum',
  'safety.quarterPermissionsLegend': 'Företag som har rätt att vara värd för säkerhetsrum',
  'safety.quarters.add': 'Lägg till säkerhetsrum',
  'safety.quarters.delete': 'Ta bort säkerhetsrum',
  'safety.quarters.documents.info': 'Exempelvis deltagarlistan eller eget material',
  'safety.quarters.new': 'Nytt säkerhetsrum',
  'safety.quarters.noLinks': 'Inga länkar har bifogats.',
  'safety.quarters.noMaterial': 'Inget material har bifogats.',
  'safety.quarters.noPredefined': 'Det finns inga fördefinierade ämnen på arbetsplatsen.',
  'safety.quarters.participantCount': 'Antal deltagare',
  'safety.quarters.participants.info': 'Deltagarlistan, gruppens namn, avtal etc.',
  'safety.quarters.participants': 'Deltagare',
  'safety.quarters.presenterName': 'Presentatör',
  'safety.quarters.report.info': 'Förteckning över säkerhetsrum som hållits.',
  'safety.quarters.subject.newOnes': 'Nya ämnen',
  'safety.quarters.subject.oldOnes': 'Ämnen som behandlats',
  'safety.quarters.subject.own': 'Eget ämne',
  'safety.quarters.subject': 'Ämne',
  'safety.quarters': 'Säkerhetsrum',
  'safety.reason': 'Orsak',
  'safety.removeReport.confirm.body': 'Är du säker på att du vill ta bort rapporten <b>{0}</b>?',
  'safety.removeReport.confirm': 'Ta bort rapport?',
  'safety.removeReport': 'Ta bort rapport',
  'safety.removeSelection': 'Avmarkera allt',
  'safety.repairBy': 'Reparera senast',
  'safety.repaired': 'Reparerad',
  'safety.repairedOnTheSpot': 'Repareras på plats',
  'safety.repairer': 'Reparatör',
  'safety.repairerComment': 'Reparatörens kommentarer',
  'safety.repairs': 'Reparationer',
  'safety.report.email.info.label1': 'Ett e-postmeddelande om rapporten skickas till ansvarig person och',
  'safety.report.email.info.label2': 'personer som ansvarar för arbetsplatsens säkerhet',
  'safety.report.email.info.p1': 'Ytterligare säkerhetskontakter hos huvudentreprenören och byggherren',
  'safety.report.email.info.p2': 'Entreprenörens säkerhetskontakt (definierad i avtalet)',
  'safety.report.email.info.p3': 'Arbetsplatsens arbetsledare',
  'safety.report.email.info.p4': 'Byggherrens och huvudentreprenörens säkerhetskontakter (definierade i avtalen)',
  'safety.report.severityDeath': 'Dödsfall',
  'safety.report.severityMild': '1–30 dagars sjukfrånvaro',
  'safety.report.severityNone': '0 dagars sjukfrånvaro',
  'safety.report.severitySevere': '31+ dagars sjukfrånvaro',
  'safety.reporter': 'Rapportör',
  'safety.reporterName': 'Rapportörens namn',
  'safety.reportNotSynced': '<b>1</b> avvikelse ej synkroniserad.',
  'safety.reports': 'Säkerhetsrapporter väntar på reparation',
  'safety.reportsNotSynced': '<b>{0}</b> avvikelser ej synkroniserade.',
  'safety.reportsSyncedAt': 'rapporter synkroniserades till servern på',
  'safety.responsiblePerson': 'Ansvarig person',
  'safety.restoreReport.confirm.body': 'Är du säker på att du vill återställa rapporten <b>{0}</b>?',
  'safety.restoreReport.confirm': 'Återställ rapport?',
  'safety.restoreReport': 'Återställ rapport',
  'safety.right': 'Höger',
  'safety.rightTotal': 'Höger totalt',
  'safety.safetyByMonth': 'Säkerhet per månad',
  'safety.safetyBySite': 'Säkerhet per arbetsplats',
  'safety.safetyMeasurement': 'Säkerhetsmätning',
  'safety.safetyMeasurements': 'Säkerhetsmätningar',
  'safety.safetyReports': 'TR/MVR-mätningar',
  'safety.searchOrAddOtherCompany': 'Sökord eller annat företag',
  'safety.searchOrAddOtherLocation': 'Sökord eller annan plats',
  'safety.searchOrAddOtherReason': 'Sökord eller annan orsak',
  'safety.searchOrOther': 'Sök/övrigt',
  'safety.searchOrOtherCompany': 'Sök/övrigt',
  'safety.section': 'Avsnitt',
  'safety.sectionDetails': 'Avsnittsinformation',
  'safety.sections': 'Avsnitt',
  'safety.selectYearAndMonth': 'Välj år och månad',
  'safety.showClosedHazards': 'Visa stängda faror',
  'safety.showDetails': 'Visa detaljer',
  'safety.showSummary': 'Visa sammanfattning',
  'safety.sign': 'Signera',
  'safety.signatures': 'Signaturer',
  'safety.specialCategories': 'Specialkategorier',
  'safety.syncedJustNow': 'Synkroniserad just nu.',
  'safety.syncing': 'Synkroniserar ...',
  'safety.target': 'Mål',
  'safety.targetLevel': 'Målsättningsnivå',
  'safety.trMeasurement': 'TR-mätning',
  'safety.type': 'Typ',
  'safety.waitingForSyncFinish': 'Väntar på att synkroniseringen ska slutföras ...',
  'safety.weekShort': 'Vecka',
  'safety.whatHappened': 'Beskrivning',
  'safety.whatIsMyDeviation': 'Vilken typ av anteckning ska jag lägga till?',
  'safety.wrong': 'Fel',
  'safety.wrongEntries': 'Poster markerade som felaktiga',
  'safety.wrongTotal': 'Fel totalsumma',
  'safetyMeasurement.reasonDescription': 'Detaljerad beskrivning',
  'safetyQuarter': 'Säkerhetsrum',
  'safetyQuarters.contractorPresenter': 'Entreprenör/presentatör',
  'safetyQuarters.participantsOnAverage': 'Deltagarens genomsnitt',
  'safetyQuarters.safetyQuartersQty': 'Säkerhetsrum (antal)',
  'safetyQuarters': 'Säkerhetsrum',
  'sales.areYouInterestedInThisFeature': 'Är du intresserad av den här funktionen?',
  'sales.leaveContactRequest': 'Lämna en kontaktförfrågan så kontaktar vår försäljningsavdelning ditt företag.',
  'sales.premiumService': 'PREMIUM<br>funktion',
  'sales.requestSentModal.body': 'Vi återkommer till dig så snart som möjligt. <br/> – Zeroni-teamet',
  'sales.requestSentModal.heading': 'Kontaktförfrågan har skickats',
  'sales.sendContactRequest': 'Skicka kontaktförfrågan',
  'sales.sending': 'Skickar ...',
  'sales.siteCopy.isPremiumFeature': '<b>Kopiera arbetsplatser</b> är en betald funktion.',
  'sales.siteCopy.readMore': 'Läs mer om att göra kopior av arbetsplatser',
  'search.activeOrEnded': 'Tillträdestillstånd aktivt/avslutat',
  'search.andNOthers': 'och {0} andra',
  'search.declarationSearch': 'Rapport-ID-sökning',
  'search.personNotFoundWithQRCode': 'Inga personer med QR-kod hittades.',
  'search.results': 'Resultat',
  'search.scanAgain': 'Skanna igen',
  'search.scanQRCode': 'Skanna QR-kod',
  'search.searchCustomLocation': 'Välj plats',
  'search.searchOrSelectOrAddPlaceholder': 'Sök, välj eller lägg till',
  'search.searchOrSelectPlaceholder': 'Sök eller välj',
  'search.showingOnlyPartOfResults': 'Visar endast en del av resultaten. Förfina din sökning.',
  'search.showingOnlyTenFirst': 'Visar endast de första 10 resultaten',
  'search.terms': 'Sök',
  'search.totalResults': '{0} resultat',
  'search': 'Sök',
  'serverOffline': 'Anslutning förlorad.',
  'serverOfflineReconnecting': 'Anslutning förlorad. Återansluter ...',
  'settings.accessControlTransfer.modal.error': 'Överföringen kunde inte initieras. Antingen har samma överföring redan påbörjats eller så finns det trängsel i Zeroni. Försök igen senare.',
  'settings.accessControlTransfer.modal.execute': 'Starta överföring',
  'settings.accessControlTransfer.modal.executing': 'Startar ...',
  'settings.accessControlTransfer.modal.info': 'Överföringen uppdaterar tillträdes- och fordonstillstånden till tillträdeskontrollsystemet.<br/><br/> Uppdatering av tillstånden kan behövas i undantagsfall efter ett kommunikationsproblem eller ändring av inställningar.<br/><br/> Överföringen tar flera minuter och utförs i bakgrunden. Varaktigheten beror på antalet tillstånd som ska uppdateras.',
  'settings.accessControlTransfer.modal.infoForRetry': 'Överföringen uppdaterar arbetsplats- och fordonstillstånden från alla byggarbetsplatser som ägs av miljön till tillträdeskontrollsystemet för de tillstånd vars överföring misslyckades tidigare.<br/><br/> Beroende på antalet tillstånd kan överföringen ta flera minuter att slutföra. Överföringen sker i bakgrunden.',
  'settings.accessControlTransfer.modal.ready': 'Överföring startad',
  'settings.accessControlTransfer.modal.success.title': 'Överföring startad',
  'settings.accessControlTransfer.modal.success': 'Överföringen avslutas kl. {0}',
  'settings.accessControlTransfer.modal.title': 'Starta överföring',
  'settings.addressOfForeignEmployee': 'Utländsk medarbetares adress',
  'settings.admittance': 'Tillträde',
  'settings.admittanceAsked': 'Tillträde begärt i tillträdestillstånd',
  'settings.allowJobTitleOutsideTheList': 'Tillåt att lägga till befattningar utanför listan',
  'settings.allowMaterialsOutsideTheList': 'Tillåt att lägga till material utanför listan',
  'settings.askForeignerRepresentative': 'Be alltid om en finsk representant för icke-finska medarbetare och medarbetare som bor utomlands',
  'settings.automaticDataSharingAgreementDesc': 'Skapa avtalsdokument för datadelning automatiskt när tillträdestillstånd beviljas',
  'settings.automaticPermitCreation': 'Automatiskt tillståndsskapande',
  'settings.automaticPermitCreationDescription': 'Om personen inte redan har ett aktivt tillträdestillstånd skapas ett automatiskt för honom/henne när han/hon stämplar in med VALTTI-kort. Kortet måste vara aktivt och företaget från kortet måste ha ett aktivt avtal på arbetsplatsen. Om ”Personverifiering” är aktiverat på platsen kommer det nya tillträdestillståndet att vänta på verifiering. Instämpling från grindenheter skapar inte ett nytt tillträdestillstånd.',
  'settings.automaticPermitCreationFromValttiCheckInEnabled': 'Skapa tillstånd från incheckningar med Valtti-kort ',
  'settings.automaticVisitorPermitDesc': 'Skapa besökstillstånd automatiskt när tillträdestillstånd beviljas',
  'settings.birthdayNotifications': 'Födelsedagsavisering',
  'settings.buyerInAccessPermit': 'Köparens kontaktperson',
  'settings.buyersReferenceId': 'Köparens referens-ID krävs',
  'settings.buyersReferenceIdRequired': 'Köparens referens-ID i faktureringstimmar',
  'settings.commonDocs.info': 'Offentliga dokument är tillgängliga för alla entreprenörer på de arbetsplatser som ditt företag har skapat.',
  'settings.commonDocs': 'Offentliga dokument',
  'settings.companySettingsForPermit': 'Information som efterfrågas för tillträdestillstånd',
  'settings.companySettingsForPermitInfo': 'Du kan definiera om dessa fält måste fyllas i för att skapa ett tillträdestillstånd. Detta är standardalternativ som kan ändras för varje arbetsplats.',
  'settings.expertise.companyFollowed.infoShort': 'Kompetenser som valts ut för spårning på företagsnivå följs på varje arbetsplats.',
  'settings.expertise.followed.info': 'Kompetenser som definieras på företagsnivå följs på alla arbetsplatser. De kan inte tas bort från en arbetsplats, men du kan definiera ytterligare platsspecifika kompetenser i redigeraren för arbetsplatsinställningar.',
  'settings.expertise.followed': 'Kompetenser som följs på arbetsplatser',
  'settings.expertise.info': 'Standardkunskapskrav för nya arbetsplatser. Du kan ändra kraven för befintliga arbetsplatser i arbetsplatsens inställningar.',
  'settings.expertise.requiredCompetences': 'Kompetenskrav',
  'settings.expertise.requiredExpertise': 'Kunskapskrav',
  'settings.expertise.requiredCourses': 'Obligatoriska kurser',
  'settings.course': 'Utbildning eller kurs',
  'settings.courseDuration': 'Varaktighet',
  'settings.courseInfoText': 'Utbildningar och kurser som kan väljas utifrån personens kunskaper',
  'settings.courseRemovalConfirmation': 'Är du säker på att du vill ta bort utbildning eller kurs <b>{0}</b>?',
  'settings.courses.durationHelp': '<strong>Varaktighet</strong> kan också vara tom. I detta fall går kursen aldrig ut.',
  'settings.courses': 'Utbildningar och kurser',
  'settings.coursesAndCompetences': 'Kurser och kompetenser',
  'settings.coursesEmpty': 'Inga utbildningar eller kurser har lagts till.',
  'settings.createCustomSection': 'Skapa avsnitt',
  'settings.criterionChangeInfo': 'Ändrar kriteriets titel. Tidigare utvärderingar kvarstår, endast kriterienamnet ändras.',
  'settings.customMeasurement': 'Säkerhetsavsnitt',
  'settings.customSafetyEventLocations.info': 'Händelseloggning endast till vissa platser, dvs. platsen kan inte anges fritt. Ändringar till dessa platser görs genom support.',
  'settings.customSafetyEventLocations': 'Använd anpassade säkerhetshändelseplatser',
  'settings.customSafetySectionName': 'Namn',
  'settings.customSafetySections': 'Säkerhetsmätning anpassade avsnitt',
  'settings.customSection': 'Anpassa avsnitt',
  'settings.customSectionActive': 'Aktivt avsnitt',
  'settings.customSectionDescription': 'Beskrivning',
  'settings.customSectionHelp.more': 'Anpassade avsnitt för säkerhetsmätning är avsnitt som inte tillhör standardmätning. De mäts samtidigt som den normala mätningen.<br/><br/>Du kan redigera de anpassade avsnitten och skapa nya på dessa sidor. Nya mätningar inkluderar de anpassade avsnitt som är markerade som aktiva. Anpassade avsnitt ändrar inte resultatet av säkerhetsmätningen. Observera att du inte kan ta bort det anpassade avsnittet när det har skapats. <br/><br/> De anpassade avsnitten kan markeras som aktiva på en vald plats från ”Hantera” &rarr; ”Säkerhetsavsnitt”.',
  'settings.customSectionHelp': 'Anpassade avsnitt för säkerhetsmätning är avsnitt som inte hör till mätningen.',
  'settings.customSectionName': 'Namn',
  'settings.customSections': 'Specialavsnitt',
  'settings.customSectionsEmpty': 'Inga anpassade avsnitt har lagts till.',
  'settings.dailyReportSectionsEmpty': 'Inga avsnitt av mallen för daglig rapport har lagts till.',
  'settings.dailyReportTemplate': 'Mall för daglig rapport',
  'settings.dailyReportTemplateInfo': 'På den här sidan kan du definiera standardmallen för dagliga rapporter. Den ställs in automatiskt för arbetsplatsen när arbetsplatsen skapas. Du kan göra ändringar i arbetsplatsmallen i efterhand.',
  'settings.dailyReportTemplateSectionRemovalConfirmation': 'Är du säker på att du vill ta bort avsnittet i mallen för daglig rapport <b>{0}</b>?',
  'settings.dailyReportTemplateSections': 'Avsnitt i mall för daglig rapport',
  'settings.dailyReportTemplateSectionsEmpty': 'Inga avsnitt av mallen för daglig rapport har lagts till.',
  'settings.dataSharingAgreement': 'Överenskommelse om datadelning',
  'settings.departmentRemovalConfirmation': 'Är du säker på att du vill ta bort avdelning <b>{0}</b>?',
  'settings.departments': 'Avdelningar',
  'settings.departmentsEmpty': 'Inga avdelningar har lagts till.',
  'settings.departmentsInfo': 'Du kan använda den här sidan för att definiera avdelningar om ditt företag har flera avdelningar och du vill gruppera arbetsplatser efter avdelning. Det går inte att ta bort avdelningar för vilka arbetsplatser redan har definierats.',
  'settings.description': 'Beskrivning',
  'settings.descriptionOfVehiclePermitType': 'Närmare beskrivning av fordonstillståndet.',
  'settings.durationInHours': 'timmar',
  'settings.editCourse': 'Redigera utbildning eller kurs',
  'settings.editDailyReportTemplateSection': 'Redigera avsnitt i mall för daglig rapport',
  'settings.editDepartment': 'Redigera avdelning',
  'settings.editOrientationTopic': 'Redigera introduktionsämne',
  'settings.editProfession': 'Redigera yrke',
  'settings.editSection': 'Redigera avsnitt',
  'settings.emailRequired': 'E-postadress krävs i kontaktuppgifterna',
  'settings.emailRequiredShort': 'E-postadress krävs',
  'settings.finnishAddressRequiredForForeignEmployee': 'Finsk adress krävs för utländska anställda',
  'settings.gateAllowedTags': 'Tillträde till grind',
  'settings.general.clStatusAndTaxReport.info': 'De utvalda mottagarna kommer att få ett e-postmeddelande om entreprenörsansvar och skatterapporter. Ett e-postmeddelande skickas varje gång en entreprenörs ansvarsstatus blir ogiltig. Dessutom skickas ett e-postmeddelande en gång i månaden om fel i skatterapporten.',
  'settings.general.clStatusAndTaxReport': 'Mottagare av e-postmeddelanden om entreprenörsansvar och skatterapport',
  'settings.general.clStatusNotficationContacts': 'Entreprenörens ansvarsmeddelanden',
  'settings.general.clStatusNotficationContactsInfo': 'När entreprenörens ansvarsinformation blir ogiltig skickas ett e-postmeddelande till nedan angivna adresser. Aviseringar skickas endast dagligen om ändringar har skett under de senaste 24 timmarna.',
  'settings.general.gate.desc': 'Introduktion krävs innan en arbetstagare får gå in genom grindar',
  'settings.general.gate': 'Grind',
  'settings.generateMissingCheckIns.info': 'Incheckning anses saknas när inga incheckningar hittas 13 timmar efter att person stämplat in eller när inga incheckningar hittas 13 timmar innan person stämplat ut. Saknad incheckning genereras 7 timmar från incheckningen. Saknade incheckningar genereras två gånger om dagen, kl. 05:00 och 12:00.',
  'settings.generateMissingCheckIns': 'Generering av incheckningstimmar',
  'settings.goEdit': 'Gå till Inställningar',
  'settings.hotWorkPermit.allowedFireExtinguishers.43A': 'Aktivera med brandsläckare 43A',
  'settings.hotWorkPermit.allowedFireExtinguishers.enabled': 'Aktiverad',
  'settings.hotWorkPermit.allowedFireExtinguishers': 'Tillåtna brandsläckare',
  'settings.hotWorkPermit.hotWorkCardRequiredFromHotWorkPerformersAndGuards': 'Kort för heta arbeten krävs av personer som utför heta arbeten och vakter',
  'settings.hotWorkPermit.hotWorkCardRequirement': 'Krav på tillstånd för heta arbeten',
  'settings.hotWorkPermitMaximumDuration': 'Maximal varaktighet för tillstånd för heta arbeten',
  'settings.hotWorkPermitMaximumDurationUnit': 'dagar',
  'settings.hotWorkPermitSettings': 'Standardinställningar för tillstånd för heta arbeten',
  'settings.hotWorkPermitSettingsInfo': 'Detta är standardinställningar som kopieras till en ny arbetsplats när den skapas. Du kan ändra arbetsplatsens inställningar från sidan för arbetsplatsinställningar.',
  'settings.hourBank': 'Timbank',
  'settings.hourBankDesc': 'Timbank tillgänglig på faktureringstimmar',
  'settings.idConfirmationPhotoInPermit.notRequired': 'Ej obligatoriskt',
  'settings.idConfirmationPhotoInPermit.requiredForForeigners': 'Obligatoriskt för utlänningar',
  'settings.idConfirmationPhotoInPermitShort': 'Foto av ID-bekräftelse',
  'settings.insurer': 'Tillståndsförsäkring för heta arbeten',
  'settings.integrations': 'Integraations',
  'settings.integrations.description': 'Integrations-API-nycklar och inställningar.',
  'settings.integrations.astora.includeAlreadyProcessed.info': 'Sök även bearbetade',
  'settings.integrations.astora.includeAlreadyProcessed': 'Bearbetade',
  'settings.integrations.astora.invoiceHourTransfer.confirm': 'Är du säker på att du vill flytta alla godkända faktureringstimmar inom tidsperioden till csv-filen? Om ingen tidsperiod anges flyttas alla faktureringstimmar. Denna åtgärd kan inte ångras efter bekräftelse.',
  'settings.integrations.astora.invoiceHourTransfer.info': 'Med den här funktionen kan du söka efter godkända faktureringstimmar. Du kan begränsa utmatningen till en viss tidsperiod. Sökningen markerar tidkortsraderna som bearbetade och de kommer inte att visas igen i nästa sökning. Genom att välja ”Sök även bearbetade” kan du även söka efter tidigare bearbetade faktureringstimmar.',
  'settings.integrations.invoiceHourTransfer.confirm': 'Är du säker på att du vill skicka alla godkända faktureringstimmar inom tidsperioden <b>{0}–{1}</b> till löneberäkning? Åtgärden kan inte ångras.',
  'settings.integrations.invoiceHourTransfer.info': 'Här kan du överföra godkända faktureringstimmar till löneberäkningssystemet V10. Endast faktureringstimmar inom angiven tidsperiod kommer att inkluderas.',
  'settings.integrations.invoiceHourTransfer': 'Överföring av faktureringstimmar',
  'settings.integrations.mepco.confirm': 'Är du säker på att du vill skapa lönedata för tidsperiod <b>{0}–{1}</b> och roll <b>{2}</b> från godkända rader för faktureringstimmar? Åtgärden kan inte ångras.',
  'settings.integrations.mepco.fetchPendingRows': 'Hämta väntande rader',
  'settings.integrations.mepco.getExcel': 'Förhandsgranska löneuppgifter',
  'settings.integrations.mepco.heading': 'Skapa löneuppgifter',
  'settings.integrations.mepco.info': 'Här kan du skapa en Excel-rapport över faktureringstimmar för Mepcos löneberäkning. Timmarna är markerade som överförda och de kan inte inkluderas i en annan rapport. Du kan också förhandsgranska timmarna innan du skapar den faktiska rapporten.',
  'settings.integrations.mepco.noPendingRows': 'Det finns inga väntande rader för perioden.',
  'settings.integrations.mepco.onlyApproved': 'Endast godkända',
  'settings.integrations.mepco.onlyPending': 'Endast väntande',
  'settings.integrations.mepco.pendingRows': 'Perioden har fortfarande väntande rader!',
  'settings.integrations.mepco.rowStatus': 'Radstatus',
  'settings.integrations.mepco.showPendingHours': 'Inkludera väntande timmar',
  'settings.integrations.mepco.showTransferedHours': 'Inkludera överförda timmar',
  'settings.integrations.mepco.transferHours.success.info': 'Löneuppgifter har skapats.',
  'settings.integrations.mepco.transferHours.success.link': 'Öppna filen.',
  'settings.integrations.mepco.transferHours': 'Skapa löneuppgifter',
  'settings.integrations.mepco': 'Mepco',
  'settings.invoiceHours.checkedInHoursShown.info': 'Incheckade timmar visas i den anställdas vy över faktureringstimmar',
  'settings.invoiceHours.checkedInHoursShown': 'Synlighet för incheckningstid',
  'settings.invoiceHours.copyApprover': 'Förvald godkännare',
  'settings.invoiceHours.copyApproverFromPreviousEntry': 'Förvald godkännare för att matcha föregående post',
  'settings.jobtitles.fileFormatInfo': 'Excel (xlsx eller xls) rekommenderas som filtyp. Om du vill använda csv-fil måste innehållet vara UTF-8-kodat.',
  'settings.jobTitlesUpdated': 'Filen <strong>{0}</strong> har bearbetats och listan över arbetsetiketter har uppdaterats.',
  'settings.laborAgreement': 'Anställningsavtal krävs',
  'settings.laborAgreementRequired': 'Kollektivavtal krävs',
  'settings.minimumGuardingTimeAfterHotWork': 'Minimiskyddstid efter heta arbeten',
  'settings.modifyVehiclePermitType': 'Ändra fordonstillstånd',
  'settings.newCourse': 'Ny utbildning eller kurs',
  'settings.newDailyReportTemplateSection': 'Nytt avsnitt i mallen för daglig rapport',
  'settings.newDepartment': 'Ny avdelning',
  'settings.newOrientationTopic': 'Nytt introduktionsämne',
  'settings.newProfession': 'Nytt yrke',
  'settings.newSection': 'Nytt avsnitt',
  'settings.newSiteVehiclePermitType': 'Ny typ av fordonstillstånd',
  'settings.noRights': 'Du har inte behörighet för dessa inställningar.',
  'settings.onlySiteTagsToGates': 'Grindåtkomst är endast tillåten för taggar som lagts till på platsen',
  'settings.orientationTopic': 'Introduktionsämne',
  'settings.orientationTopicRemovalConfirmation': 'Är du säker på att du vill ta bort introduktionsämnet <b>{0}</b>?',
  'settings.orientationTopics': 'Introduktionsämnen',
  'settings.orientationTopicsEmpty': 'Inga introduktionsämnen har lagts till.',
  'settings.otherInsurer': 'Tillstånd för heta arbeten används inte',
  'settings.overTimeReasonRequired': 'Orsak till överskridande av incheckade timmar krävs',
  'settings.paperOrientation': 'Introduktion på papper',
  'settings.paperOrientationAllowed': 'Bekräftelse av introduktion i pappersform är tillåten tillsammans med elektronisk bekräftelse',
  'settings.personsVerification.automatic.info': 'Ingen separat verifiering. Tillträdestillstånd är giltigt så snart erforderlig kompetens och introduktion har lagts till.',
  'settings.personsVerification.automatic': 'Automatisk verifiering',
  'settings.personsVerification.manual.info': 'Varje persons rätt att arbeta verifieras separat. De personer som väntar på verifiering visas på kontrollpanelens meddelanden.',
  'settings.personsVerification.manual': 'Arbetsrätt måste verifieras',
  'settings.personsVerification.newVehiclePermit.convert.no': 'Radera alla gamla fordonstillstånd. (Observera! Detta val kräver att alla godkända fordonstillstånd skapas och godkänns på nytt)',
  'settings.personsVerification.newVehiclePermit.convert.yes': 'Redigera gamla fordonstillstånd för att överensstämma med den nya fordonstillståndsmodulen (standard)',
  'settings.personsVerification.newVehiclePermitInfo': 'Har den gamla körkortsmodulen använts på denna arbetsplats? Observera att aktivering av verifiering av arbetsrätt även aktiverar den nya fordonstillståndsmodulen. Verifiera hur du vill hantera gamla fordonstillstånd. Observera att detta val är permanent.',
  'settings.personsVerification': 'Verifiering av arbetsrätt',
  'settings.phoneNumberRequired': 'Telefonnummer krävs i kontaktuppgifterna',
  'settings.phoneNumberRequiredShort': 'Telefonnummer måste anges',
  'settings.postcodeMandatoryForPerson': 'Postnummer är ett obligatoriskt fält för personer som bor i Finland',
  'settings.profession': 'Yrke',
  'settings.professionRemovalConfirmation': 'Är du säker på att du vill ta bort yrket <b>{0}</b>?',
  'settings.professionRequired': 'Yrke som krävs i tillträdestillstånd',
  'settings.professions': 'Yrken',
  'settings.professionsEmpty': 'Inga yrken har lagts till.',
  'settings.removeCourse': 'Ta bort utbildning eller kurs',
  'settings.removeDailyReportTemplateSection': 'Ta bort avsnitt från mall för daglig rapport',
  'settings.removeDepartment': 'Ta bort avdelning',
  'settings.removeOrientationTopic': 'Ta bort introduktionsämne',
  'settings.removeProfession': 'Ta bort yrke',
  'settings.representative': 'Representant efterfrågad för medarbetare bosatta utomlands',
  'settings.residencePermitDocument': 'Uppehållstillståndsdokument',
  'settings.residencePermitDocumentRequired': 'Uppehållstillståndshandlingar måste tillhandahållas',
  'settings.restAreaAsked': 'Rastplats måste anges',
  'settings.restRoom': 'Toalett',
  'settings.safety.quarters.newSubject': 'Nytt ämne',
  'settings.safety.quarters.removeSubject.confirm': 'Är du säker på att du vill ta bort ämnet <b>{0}</b>?',
  'settings.safety.quarters.removeSubject': 'Ta bort ämne',
  'settings.safety.quarters.subject.material': 'Material',
  'settings.safety.quarters.subject': 'Ämne',
  'settings.safety.quarters.subjectlistEmpty': 'Inga ämnen har lagts till.',
  'settings.safety.quarters.subjects.edit': 'Redigera ämne',
  'settings.safety.quarters.subjects': 'Säkerhetsrumsämnen',
  'settings.safety.reports': 'Säkerhetsrapporter',
  'settings.safety.reportSettings': 'Säkerhetsrapportinställningar',
  'settings.safety.reportTimeRequired': 'Säkerhetsrapporter kräver tidsstämpel',
  'settings.safety': 'Säkerhet',
  'settings.safetyLocations': 'Säkerhetsplatser',
  'settings.safetySection': 'Säkerhetsavsnitt',
  'settings.safetySectionActive': 'Aktiv',
  'settings.showBirthdayNotifications': 'Visa meddelande på startsidan när arbetsplatsens arbetstagare har födelsedag',
  'settings.siteBasicDetails': 'Grundläggande information om arbetsplatsen',
  'settings.siteCardPhotoInPermit.info': 'Kortet måste innehålla personens namn, foto och skattenummer samt företagsnamn, typ av affärsenhet och företags-ID.',
  'settings.siteCardPhotoInPermit.notRequired': 'Ej obligatoriskt',
  'settings.siteCardPhotoInPermit.requiredForAll': 'Obligatoriskt för alla',
  'settings.siteCardPhotoInPermit.requiredForForeigners': 'Obligatoriskt för utlänningar',
  'settings.siteCardPhotoInPermit': 'Foto av arbetsplatskort',
  'settings.siteSettings': 'Standardarbetsplatsinställningar',
  'settings.siteSettingsInfo': 'Detta är standardinställningar som kopieras till en ny arbetsplats när den skapas. Du kan ändra arbetsplatsens inställningar från sidan för arbetsplatsinställningar.',
  'settings.siteSettingsNonConstructionInfo': 'En arbetsplats behöver inte nödvändigtvis vara ett traditionellt byggprojekt, definitionen kan även omfatta andra uppgifter som underhåll. När du ändrar denna inställning ska du kontrollera om din arbetsplats uppfyller något av kriterierna för den gemensamma byggarbetsplatsen: <ul class="mt-1"><li>Arbetsplatsen varar i mer än 30 dagar och det finns mer än 10 arbetstagare (inklusive oberoende entreprenörer) på arbetsplatsen<br><em>eller</em></li><li>Den totala arbetsvolymen på arbetsplatsen överstiger 500 personarbetsdagar</li></ul>Om dessa villkor inte uppfylls, betraktas den inte som en byggarbetsplats och omfattas inte av de skyldigheter som definieras i <a href="https://www.finlex.fi/fi/laki/alkup/2009/20090205#Pidm46494958209712/" rel="noopener" target="_blank">Regeringsförordningen om byggsäkerhet <span class="far fa-external-link"></span></a> (finlex.fi).',
  'settings.siteSpecificJobTitles': 'Jobbtitlar',
  'settings.siteSpecificJobTitlesDesc': 'Använd platsspecifik jobbtitellista istället för en global lista',
  'settings.siteVehiclePermitTypes': 'Platsens fordonstillståndstyper',
  'settings.specifyBuyerInAccessPermit': 'Använd fältet ”köparens kontaktperson” i tillträdestillståndet',
  'settings.supplierRegisterCriteria': 'Kriterier för utvärdering av företag',
  'settings.updateCustomSection': 'Uppdatera avsnitt',
  'settings.updateSafetySections': 'Uppdatera säkerhetsavsnitt',
  'settings.vehiclePermit': 'Fordonstillstånd',
  'settings.vehiclePermitAsked': 'Fordonstillstånd begärt i tillträdestillstånd',
  'settings.vehiclePermitType': 'Typ av fordonstillstånd',
  'settings.vehiclePermitTypes': 'Typer av fordonstillstånd',
  'settings.visitor': 'Besökare',
  'settings.visitorAsked': 'Huvudentreprenör och byggherre kan skapa besökstillstånd',
  'settings.visitorPermit': 'Besökstillstånd',
  'settings.visyTransfer': 'Visygate-överföring',
  'settings.workPermitMaximumDuration': 'Arbetstillståndets maximala varaktighet',
  'site.additionalSelection': 'Fler avsnitt',
  'site.additionalSettings': 'Fler inställningar',
  'site.anonReportInfo': 'Skicka meddelande till mottagare när ny rapport skickas via Teehavainto.fi. Skicka även påminnelsemeddelande nattetid före måndag och torsdag',
  'site.anonReportReceivers': 'Mottagare av Teehavainto.fi-rapporter',
  'site.AsafetyMeasurementType': 'Val',
  'site.askAboutContractEndDateChange': 'Vill du ändra slutdatum för arbetsplatsens avtal? Detta alternativ uppdaterar alla avtal <strong> vars slutdatum är {0}</strong>. Det nya slutdatumet är {1}.',
  'site.askAboutContractStartDateChange': 'Vill du ändra startdatum för arbetsplatsens avtal? Detta alternativ uppdaterar alla avtal <strong> vars startdatum är {0}</strong>. Det nya startdatumet är {1}.',
  'site.askAboutPermitEndDateChange': 'Vill du också ändra slutdatum för tillträdestillstånd? Detta alternativ uppdaterar alla tillträdestillstånd <strong> vars slutdatum är {0}. Det nya slutdatumet är {1}.',
  'site.askAboutPermitStartDateChange': 'Vill du också ändra startdatum för tillträdestillstånd? Detta alternativ uppdaterar alla tillträdestillstånd <strong> vars startdatum är {0} och som inte är introducerade. Det nya startdatumet är {1}.',
  'site.automaticPeriodChange': 'Startdatum och slutdatum för avtalen och tillstånden kommer att ändras automatiskt så att de ligger inom perioden {0}–{1}.',
  'site.automaticPeriodChangeContractsOnly': 'Avtalens startdatum och slutdatum ändras automatiskt så att de ligger inom perioden {0}–{1}.',
  'site.bdayNotificationInfo': 'Skickas på morgonen dagen före födelsedagen klockan 7',
  'site.bdayNotificationReceivers': 'Meddelande om medarbetares födelsedagar',
  'site.changeSite': 'Ändra arbetsplats',
  'site.changingSite': 'Ändrar arbetsplats ...',
  'site.checkIns': 'Incheckningar',
  'site.chooseReceivers': 'Välj mottagare',
  'site.expertise.noRightsToModifyCustomExpertise': 'Utbildningskraven definieras av arbetsplatsens ägare.',
  'site.expertise.photoOfCompetenceCertificate': 'Kompetensverifiering',
  'site.expertise.photoOfCompetenceCertificateRequired': 'Foto på certifikat som krävs vid skapande av ny kompetens',
  'site.expertise.requiredCustomCompetences.inherited': 'Krävs av byggherren',
  'site.expertise.requiredCustomExpertise': 'Obligatoriska utbildningar på arbetsplatsen',
  'site.continuousReportsToTaxAdmin': 'Rapporteringstiden är inte inställd. Rapporter skickas mellan arbetsplatsens start- och slutdatum.',
  'site.contractors': 'Entreprenörer',
  'site.copy': 'Kopiera',
  'site.dailyReport.template': 'Mall för daglig byggrapport',
  'site.dailyReport': 'Daglig byggrapport',
  'site.dailyReports': 'Dagbok',
  'site.defineEmailReceivers': 'Mottagare av e-postmeddelanden för arbetsplatsen. Mottagarna väljs bland huvudentreprenörens användare och arbetsplatsens byggherre.',
  'site.developerMustCreateSite': 'Den valda byggherren är en Zeroni-kund. Kontakta byggherren och be honom/henne skapa arbetsplatsen.',
  'site.editSiteInfo': 'Redigera arbetsplats',
  'site.emails.blackMarket.info': 'Kommer att skickas ut när någon gör en rapport om svart marknad på teehavainto.fi.',
  'site.emails.blackMarket': 'Svart marknad',
  'site.emails.feedback.info': 'Kommer att skickas ut när någon ger feedback på teehavainto.fi.',
  'site.emails.feedback': 'Feedback',
  'site.emails.HSE.info': 'Kommer att skickas när någon gör en rapport om tillbud eller skador.',
  'site.emails.HSE': 'Mottagare av tillbuds- och skaderapporter',
  'site.emails.personsPendingVerification.info': 'Skickas varje kväll',
  'site.emails.personsPendingVerification': 'Meddelande om personer i väntan på verifiering av arbetsrätt',
  'site.emails': 'E-postmeddelanden',
  'site.extendContractsAndPermits': 'Du förlänger arbetsplatsens varaktighet. Vill du också förlänga löptiden för arbetsplatsens avtal och tillträdestillstånd?',
  'site.extendingEndDate': 'Du ändrar arbetsplatsens slutdatum till ett senare datum.',
  'site.extendingStartDate': 'Du ändrar arbetsplatsens startdatum till ett tidigare datum.',
  'site.followedCompetences.fromCompanyLevel': 'Följda kompetenser på företagsnivå',
  'site.followedCompetences': 'Följda kompetenser',
  'site.followedCompetencesAll': 'Alla kompetenser',
  'site.followedCompetencesDescription': 'Aviseringarna på kontrollpanelen visar utgångna och utgångna kompetenser. Aviseringarna visar endast de valda kompetenserna.',
  'site.followedCompetencesRequired.description': 'Se arbetsplatsinställningar, ”Krav”',
  'site.followedCompetencesRequired': 'Den här arbetsplatsens obligatoriska kompetenser',
  'site.followedCompetencesSelected': 'Anpassa urval',
  'site.gate.desc': 'Endast introducerade arbetstagare har tillträde genom grindar',
  'site.gate': 'Grind',
  'site.generateMissingCheckins': 'Generera incheckningar automatiskt om de saknas',
  'site.hasNoSafetyMeasurementType': 'Arbetsplatsen har ingen typ av säkerhetsmätning vald',
  'site.hotWorkPermits': 'Tillstånd för heta arbeten',
  'site.integration.visygateId': 'Visygate-identifierare',
  'site.introduction': 'Introduktion',
  'site.link.description': 'Beskrivning (t.ex. Google)',
  'site.link.example': 'Adress (t.ex. http://www.google.com)',
  'site.link': 'Länk',
  'site.links': 'Länkar',
  'site.manage': 'Hantera',
  'site.noExpertiseAvailable': 'Inga kompetenser tillgängliga',
  'site.noContractors': 'Inga entreprenörer',
  'site.noneSelected': 'Arbetsplats har inte valts',
  'site.noRequirementsAvailable': 'Inga kompetenser tillgängliga',
  'site.number.acceptNumberSuggestion': 'Vill du använda {0}?',
  'site.number.autoGenerate': 'Sök nummer',
  'site.number.edit': 'Redigera nummer',
  'site.number.generationInfo': 'Zeroni kan skapa numret som består av en nummerdel och en textdel, till exempel SITE00001. Programmet ser till att det inte finns dubbletter av ID:n. Ange textdelen nedan.',
  'site.number.placeholder': 'Ange textdelen (min. 3 tecken)',
  'site.optionalExpertise.inherited': 'Krävs av byggherren',
  'site.optionalExpertise': 'Valfri kunskap',
  'site.ownerDocs': 'Arbetsplatsägarens offentliga handlingar',
  'site.permitsNeedContracts': 'Förlängning av tillstånd kräver förlängning av avtal.',
  'site.persons': 'Personer',
  'site.quality': 'Kvalitet',
  'site.reports': 'Rapporter',
  'site.requiredCompetences.inherited': 'Krävs av byggherren',
  'site.requiredCompetences': 'Obligatoriska kompetenser på arbetsplatsen',
  'site.requiredExpertiseSettings': 'Kunskapskrav',
  'site.safety': 'Säkerhet',
  'site.safetyMeasurement.targetLevel': 'Målsättningsnivå',
  'site.safetyMeasurementType': 'Typ av arbetsmiljömätningar',
  'site.safetyMeasurementTypes': 'Typer av arbetsmiljömätningar',
  'site.safetySettings.contractorRights': 'Underleverantörens mäträttigheter',
  'site.safetySettings.noTypesSelected': 'Inga säkerhetsmätningstyper valda',
  'site.safetySettings': 'Mätningsinställningar',
  'site.searchOrSelect': 'Arbetsplats',
  'site.searchOrSelectCompetenceModel': 'Kompetens',
  'site.searchOrSelectCustomCompetenceModel': 'Kurs',
  'site.selectSafetyMeasurementTypes': 'Välj säkerhetsmätningstyper',
  'site.senderOfEmployeeReports': 'Avsändare av medarbetarrapporter',
  'site.settings.contractorCheckins.modify': 'Kan se och ändra',
  'site.settings.contractorCheckins.noRights': 'Kan inte se eller ändra',
  'site.settings.contractorCheckins.read': 'Kan se men inte ändra',
  'site.settings.contractorCheckins': 'Entreprenörens incheckningsrättigheter',
  'site.settings.customSectionHelp.more': 'Säkerhetsmätning anpassade avsnitt är avsnitt som inte tillhör standard TR/MVR/Elmeri+-mätning. De mäts samtidigt som den normala mätningen.<br/><br/> Nya mätningar inkluderar de anpassade avsnitten som är markerade som aktiva. Anpassade avsnitt ändrar inte resultatet av säkerhetsmätningen.',
  'site.settings.customSectionHelp': 'Säkerhetsmätning anpassade avsnitt är avsnitt som inte tillhör standard TR/MVR/Elmeri+-mätning.',
  'site.settings.safetyHelp': 'Inställningar för säkerhetsavvikelser och mätningar samt ämnen för säkerhetsrum.',
  'site.settings': 'Inställningar',
  'site.settingsForPermit': 'Information som efterfrågas för tillträdestillstånd',
  'site.settingsForPermitInfo': 'Du kan definiera om dessa fält måste fyllas i för att skapa ett tillträdestillstånd.',
  'site.showSiteInfo': 'Visa arbetsplatsinformation',
  'site.siteEmails': 'Arbetsplatsens e-postmeddelanden',
  'site.siteForeman.notSet': 'Ej inställt',
  'site.siteForeman.set': 'Arbetsledare',
  'site.sixmonths': '6 månader',
  'site.subcontractorSignedReports.label': 'Avtalsloggbokens underskrifter',
  'site.subcontractorSignedReports': 'Avtalsloggböcker kräver underskrift av underentreprenörer',
  'site.summary': 'Arbetsplatssammanfattning',
  'site.threemonths': '3 månader',
  'site.timePeriodForNoReportsToTaxAdmin': 'Ingen rapportering mellan',
  'site.timePeriodForReportsToTaxAdmin': 'Rapporterar mellan',
  'site.timePeriodForReportsToTaxAdminEnd': 'Rapportering avslutas',
  'site.timePeriodForReportsToTaxAdminStart': 'Rapportering startar',
  'site.twelvemonths': '12 månader',
  'site.users': 'Arbetsplatsens användare',
  'site.usersFromContractors': 'Entreprenörens användare',
  'site.usersFromMyCompany': '{0}s användare',
  'site.usesInvoiceHours.reduceLunch.tooltip': 'Lunchen subtraheras automatiskt från faktureringstimmarna om den totala arbetstiden är minst 6 timmar och personen endast har en in- och utcheckning för dagen',
  'site.usesInvoiceHours.reduceLunch': 'Automatisk subtrahering av lunch',
  'site.usesInvoiceHours': 'Faktureringstimmar som använts',
  'site.workHourBalancePeriodType': 'Period för arbetstidssaldo',
  'site.workSafetyPlan': 'Arbetsmiljöplan',
  'site.workSafetyPlans': 'Arbetsmiljöplaner',
  'siteCard.closeReason.damaged': 'Skadad',
  'siteCard.closeReason.lost': 'Förlorad',
  'siteCard.closeReason.returned': 'Returnerad',
  'siteCard.status.passive': 'Otillräcklig',
  'siteCard.status.printed': 'Arbetsplatskortet har hämtats',
  'siteCard.status.printedShort': 'Hämtats upp',
  'siteCard.status.suspended': 'Tillfälligt arbetsplatskort används',
  'siteCard.status.waitingForPhotoshooting': 'Väntar på hämtning av arbetsplatskort',
  'siteCard.status.waitingForPrinting': 'Väntar på utskrift',
  'siteContractors.contractorList': 'Entreprenörslista',
  'siteContractors.contractorTree': 'Entreprenörsträd',
  'siteContractors.showInContractorTree': 'Visa i entreprenörsträd',
  'siteDashboard.searchHelp': 'Sök efter personer, entreprenörer och andra ...',
  'siteEditor.fileFormatInfo': 'Excel (xlsx eller xls) rekommenderas som filformat. Jobbnummer i den första kolumnen och beskrivning i den andra. Om du vill använda csv-format måste innehållet vara UTF-8-kodat i följande form: jobbnummer; beskrivning',
  'siteEditor.getCurrentJobNumberList': 'Hämta aktuell jobbnummerlista',
  'siteEditor.selectCSV': 'Filformatet måste vara .csv: jobbnummer; beskrivning',
  'siteLocation.mapHelp': '<strong>Starta</strong> genom att klicka på ett hörn på arbetsplatsen. <strong>Lägg till</strong> fler punkter längs arbetsplatsgränsen. <strong>Avsluta</strong> området genom att klicka på startpunkten.',
  'siteLocation.mapInformation': 'Området används i eq. mobila incheckningar, arbetsplatsval, teehavainto.fi-service.',
  'sites.usesInvoiceHours': 'Faktureringstimmar som använts',
  'smfc.switch': 'Byt till Zeroni för Entreprenör',
  'smfc': 'Zeroni för Entreprenör',
  'sort.latestEndDate': 'Senaste slutdatum',
  'sort.latestStartDate': 'Senaste startdatum',
  'sort.siteNumberAsc': 'Arbetsplatsnummer A→Z',
  'tag.allocateToSiteInfo': 'Tagg kan användas för att stämpla in till alla arbetsplatser, men genom att välja en arbetsplats kan du bättre styra returen av taggar i en arbetsplats med taggrapport.',
  'tag.canOnlyBeReturnedBy': 'Kan inte returneras. (Ägare: {0})',
  'tag.changeOwner': 'Ändra ägare',
  'tag.changing': 'Ändrar ...',
  'tag.ClosedMessage': '<strong>Valtti-kortet är stängt</strong>. Ange ett annat nummer.',
  'tag.closeReason.damaged': 'Skadad',
  'tag.closeReason.lost': 'Förlorad',
  'tag.closeReason.returned': 'Returnerad',
  'tag.confirmDamaged': 'Markera <strong>{0}</strong> som skadad?',
  'tag.confirmDeleted': 'Radera tagg <strong>{0}</strong>?',
  'tag.confirmLost': 'Markera <strong>{0}</strong> som förlorad?',
  'tag.confirmReturn': 'Returtagg <strong>{0}</strong>?',
  'tag.delete': 'Ta bort',
  'tag.employer': 'Arbetsgivare:',
  'tag.ExpiredMessage': '<strong>Valtti-kortet har gått ut</strong>. Ange ett annat nummer.',
  'tag.help': 'Nummer för VALTTI-kort, nyckelring eller annan ID-tagg',
  'tag.keyRingOrOther': 'Nyckel, höger tagg eller annan NFC-ID-tagg',
  'tag.markAsDamaged': 'Markera som skadad',
  'tag.markAsDamagedHeading': 'Markera taggen som skadad?',
  'tag.markAsDeleted': 'Markera som raderad',
  'tag.markAsLost': 'Markera som förlorad',
  'tag.markAsLostHeading': 'Markera tagg som förlorad?',
  'tag.markTagAsDamagedHeading': 'Markera tagg som skadad',
  'tag.markTagAsDeletedHeading': 'Markera tagg som raderad?',
  'tag.markTagAsLostHeading': 'Markera tagg som förlorad?',
  'tag.newTag': 'Ny tagg',
  'tag.noTags': 'Inga taggar.',
  'tag.notAvailableMessage': '<strong>Tagg används</strong> av en annan Zeroni-användare</strong>. Lägg till ett annat nummer.',
  'tag.otherPersonsValttiMessage': '<strong>Taggen tillhör en annan persons </strong> VALTTI-kort. VALTTI-kort kan inte överföras till andra personer.',
  'tag.return': 'Retur',
  'tag.returnableMessage': '<strong>Taggen används</strong> av personen <strong>{0}</strong>. Vill du flytta taggen till person <strong>{1}</strong>?',
  'tag.returnTagHeading': 'Returnera tagg?',
  'tag.serviceError': 'Ett fel uppstod vid åtkomst till ID-taggar.',
  'tag.status.passive': 'Otillräcklig',
  'tag.status.printed': 'Arbetsplatskortet har hämtats',
  'tag.status.printedShort': 'Hämtats upp',
  'tag.status.suspended': 'Tillfälligt arbetsplatskort används',
  'tag.status.waitingForPhotoshooting': 'Väntar på hämtning av arbetsplatskort',
  'tag.status.waitingForPrinting': 'Väntar på utskrift',
  'tag.status': 'Status:',
  'tag.statusClosed': 'Stängd',
  'tag.statusExpired': 'Förfallen',
  'tag.statusInuse': 'Används',
  'tag.tagNumber': 'Taggnummer',
  'tag.tags': 'Taggar',
  'tag.validUntil': 'Giltig till:',
  'tag.valttiExpiredOrClosedMessage': 'Valtti-kortet har gått ut eller stängts och kan inte längre användas.',
  'tags.sending': 'Skickar ...',
  'tags.sendNew': 'Skicka nya taggar',
  'taitorekisteri': 'Kompetensregister',
  'tax.contracts.info': 'Dessa inställningar är endast relevanta för rapporter från företaget <strong>{0}</strong> på denna arbetsplats.',
  'tax.reportsSentOnSite': 'Skatterapporter skickas på arbetsplatsen',
  'topMenu.contractors': 'Entreprenörer',
  'topMenu.dailyReport': 'Dagbok',
  'topMenu.invoiceHours': 'Timmar',
  'topMenu.loginAsCompany': 'Företag ska logga in som',
  'topMenu.loginAsEmployee': 'Medarbetare ska logga in som',
  'topMenu.manage': 'Hantera',
  'travelExpenseClaim.accommodationDays': 'Boendedagar',
  'travelExpenseClaim.alertAdded': 'Resekostnad tillagd',
  'travelExpenseClaim.alertApproved': 'Resekostnad godkänd',
  'travelExpenseClaim.alertDeleted': 'Reseräkning borttagen',
  'travelExpenseClaim.alertMarkedAsInvoiced': 'Resekostnad markerad som fakturerad',
  'travelExpenseClaim.alertRejected': 'Resekostnad avvisad',
  'travelExpenseClaim.alertRemoved': 'Resekostnad borttagen',
  'travelExpenseClaim.alertUpdated': 'Resekostnad uppdaterad',
  'travelExpenseClaim.approve': 'Godkänn',
  'travelExpenseClaim.approved': 'Godkänd',
  'travelExpenseClaim.approver': 'Godkännare',
  'travelExpenseClaim.begun': 'Börjat',
  'travelExpenseClaim.car': 'Egen bil',
  'travelExpenseClaim.concise': 'Kortfattad reseräkning',
  'travelExpenseClaim.confirmRejection': 'Bekräfta avvisning',
  'travelExpenseClaim.deleteClaimBody': 'Är du säker på att du vill ta bort <strong>{0}</strong>s reseräkning?',
  'travelExpenseClaim.deleteClaimHeading': 'Ta bort ansökan?',
  'travelExpenseClaim.description': 'Beskrivning av utlägg',
  'travelExpenseClaim.distance': 'Körsträcka',
  'travelExpenseClaim.ended': 'Avslutad',
  'travelExpenseClaim.expenses': 'Utgifter',
  'travelExpenseClaim.expenseType': 'Utläggstyp',
  'travelExpenseClaim.fixedExpense': 'Fasta utlägg',
  'travelExpenseClaim.fixedKilometreAllowanceInfo': '<strong>Ange</strong> fast resekostnad till ”utlägg”',
  'travelExpenseClaim.fullPerDiems': 'Fulla traktamenten',
  'travelExpenseClaim.fullPerDiemsUnit': 'Fulla traktamenten',
  'travelExpenseClaim.homeToSite': 'Resekostnader mellan hem och arbetsplats',
  'travelExpenseClaim.invoiced': 'Fakturerad',
  'travelExpenseClaim.jobNumber.ownVariant': 'Jobbnummer',
  'travelExpenseClaim.jobNumber': 'Jobbnummer',
  'travelExpenseClaim.jobNumberTh.ownVariant': 'Jobbnummer',
  'travelExpenseClaim.jobNumberTh': 'Jobbnummer',
  'travelExpenseClaim.kilometreAllowance': 'Kilometerersättning',
  'travelExpenseClaim.kilometreBasedExpense': 'Kilometerbaserat',
  'travelExpenseClaim.markAsInvoiced': 'Markera som fakturerad',
  'travelExpenseClaim.newExpense': 'Ny reseräkning',
  'travelExpenseClaim.noExpenses': 'Inga utlägg',
  'travelExpenseClaim.noPerDiem': 'Kostnadsfritt dagligt traktamente',
  'travelExpenseClaim.noReceivedClaims': 'Inga mottagna reseräkningar',
  'travelExpenseClaim.noRejectedClaims': 'Inga avvisade reseräkningar',
  'travelExpenseClaim.noSentClaims': 'Inga skickade reseräkningar',
  'travelExpenseClaim.notUsedOnSelectedSite': 'Reseräkningar används inte på den valda arbetsplatsen.',
  'travelExpenseClaim.other': 'Övrigt (allmänhet, medpassagerare, taxi etc.)',
  'travelExpenseClaim.otherClaimHasPerDiem': 'Ett traktamente ingår i ett annat anspråk.',
  'travelExpenseClaim.otherThanOwnCar': 'Annan än egen bil',
  'travelExpenseClaim.over10km': 'Över 10 km',
  'travelExpenseClaim.over120km': 'Över 120 km',
  'travelExpenseClaim.over20km': 'Över 20 km',
  'travelExpenseClaim.over30km': 'Över 30 km',
  'travelExpenseClaim.over40km': 'Över 40 km',
  'travelExpenseClaim.over50km': 'Över 50 km',
  'travelExpenseClaim.over5km': 'Över 5 km',
  'travelExpenseClaim.over60km': 'Över 60 km',
  'travelExpenseClaim.over70km': 'Över 70 km',
  'travelExpenseClaim.over80km': 'Över 80 km',
  'travelExpenseClaim.over90km': 'Mer än 90 km',
  'travelExpenseClaim.partialPerDiems': 'Partiella traktamenten',
  'travelExpenseClaim.partialPerDiemsUnit': 'Partiella traktamenten',
  'travelExpenseClaim.pendingApproval': 'Väntar på godkännande',
  'travelExpenseClaim.perDiem': 'Dagtraktamente',
  'travelExpenseClaim.perDiemEnabled': 'Debitera dagtraktamente',
  'travelExpenseClaim.reasonForRejection': 'Orsak till avslag',
  'travelExpenseClaim.receivedTravelExpenseClaims': 'Mottagna reseräkningar',
  'travelExpenseClaim.reject': 'Avvisa',
  'travelExpenseClaim.rejectClaim': 'Avvisa ansökan',
  'travelExpenseClaim.rejected': 'Avvisad',
  'travelExpenseClaim.removeClaim': 'Ta bort ansökan?',
  'travelExpenseClaim.requestConfirmationForDeletion': 'Du tar bort en reseräkning för person <strong>{0}</strong>. Är du säker på att du vill ta bort ansökan?',
  'travelExpenseClaim.route': 'Rutt',
  'travelExpenseClaim.selectApprover': 'Välj godkännare',
  'travelExpenseClaim.sentTravelExpenseClaims': 'Skickade reseräkningar',
  'travelExpenseClaim.showOnlyPending': 'Endast anspråk som väntar på godkännande',
  'travelExpenseClaim.showOnlyRejected': 'Visa endast avvisade',
  'travelExpenseClaim.totalDays': '<strong>Totalt antal dagar:</strong> {0} dagar',
  'travelExpenseClaim.travelDays': 'Resedagar',
  'travelExpenseClaim.travelHours': 'Resetimmar',
  'travelExpenseClaim.undoInvoiced': 'Ångra fakturerad',
  'travelExpenseClaim.update.add': 'Lägg till reseräkning',
  'travelExpenseClaim.update': 'Uppdatera resekostnad',
  'travelExpenseClaim.vehicle': 'Fordon',
  'travelExpenseClaim': 'Reseräkning',
  'travelExpenseClaims.changeApprover': 'Ändra godkännare',
  'travelExpenseClaims': 'Reseräkningar',
  'up': 'Upp',
  'user.accessToAllSites': 'Tillgång till alla arbetsplatser',
  'user.admin': 'Administratören',
  'user.adminAccessInfo': 'Användare med obegränsad åtkomst har tillgång till alla arbetsplatser.',
  'user.changeLanguage': 'Ändra språk',
  'user.grantedAccessToThisSite': 'Åtkomst beviljad till denna arbetsplats',
  'user.noUserRolePermissions': 'Inga användarrollbehörigheter.',
  'user.otherUser': 'Annan användare',
  'user.otherUsers': 'Övriga användare',
  'user.restrictedToSites': 'Åtkomst endast till utvalda arbetsplatser',
  'user.restriction': 'Begränsning',
  'user.searchUserRole': 'Sök användarroll',
  'user.session': 'Session',
  'user.selectUserRole': 'Välj användarroll',
  'user.ssoHelp': 'Azure Active Directory är en autentiseringstjänst. Om Azure AD-användarnamn anges kommer denna användare att logga in på Zeroni med hjälp av din Azure AD-tjänst.',
  'user.ssoUsername': 'Azure AD-användarnamn',
  'user.superUser.remainder': '<strong>Varning!</strong> Användaren får tillgång till alla kundens hyresgäster!',
  'user.superUser': 'Superanvändare',
  'user.unrestricted': 'Obegränsad åtkomst',
  'user.userInformation': 'Användarinformation',
  'user.userRole': 'Användarroll',
  'userEditor.adminRestrictionInfo': 'Administratörer har automatiskt tillgång till alla arbetsplatser.',
  'userEditor.emailAddress': 'E-post',
  'userEditor.emailDomainIsDifferent': '<strong>Obs!</strong> E-postdomänen skiljer sig från din egen. Denna användare kommer att få åtkomst till ditt företag. Användarkonton för dina <em>entreprenörer</em> skapas via avtal.',
  'userEditor.firstName': 'Förnamn',
  'userEditor.lastLogin': 'Senaste inloggning',
  'userEditor.lastName': 'Efternamn',
  'userEditor.noLogins': 'Användaren har inga inloggningar',
  'userEditor.onlyUserCanChange': 'Endast personen själv kan ändra.',
  'userEditor.otherRestrictionInfo': 'Användaren har begränsad åtkomst. Du måste välja de arbetsplatser som användaren ska ha tillgång till.',
  'userEditor.removeSites': 'Ta bort alla arbetsplatser',
  'userEditor.selectUserGroup': 'Välj användargrupp',
  'userEditor.telephone': 'Telefon',
  'userEditor.userEmailExists': '<strong>E-postadressen används redan av en annan person!</strong> <br><i>Tips 1: Kontrollera att personen inte redan har registrerats med ett ogiltigt skattenummer.</i><br><i>Tips 2: Se till att det inte finns någon annan person med samma för- och efternamn i ditt företag.</i>',
  'userEditor.userExists': '<strong>Användaren finns redan:</strong> E-postadressen används redan.',
  'userEditor.userGroup': 'Användargrupp',
  'userEditor.valueChangeText': 'Användarnamn, e-postadress och telefon kan inte ändras av säkerhetsskäl. Om värdena är felaktiga, kontakta support@zeroni.fi',
  'userGroup.companyRights': 'Tillgång till företag',
  'userGroup.declineAllRights': 'Avvisa alla rättigheter',
  'userGroup.grantAllRights': 'Bevilja alla rättigheter',
  'userGroup.operation.add': 'Lägg till',
  'userGroup.operation.addRemove': 'Lägg till/ta bort',
  'userGroup.operation.approve': 'Godkänn',
  'userGroup.operation.approveReject': 'Godkänn/avvisa',
  'userGroup.operation.asterisk': 'Läs',
  'userGroup.operation.cancel': 'Avbryt',
  'userGroup.operation.confirm': 'Bekräfta',
  'userGroup.operation.connectDisconnect': 'Lägg till på arbetsplats/ta bort från arbetsplats',
  'userGroup.operation.continueSuspend': 'Pausa/Fortsätt',
  'userGroup.operation.modify': 'Ändra',
  'userGroup.operation.move': 'Flytta',
  'userGroup.operation.orient': 'Introduktion',
  'userGroup.operation.read': 'Läs',
  'userGroup.operation.remove': 'Ta bort',
  'userGroup.operation.repair': 'Reparera',
  'userGroup.operation.subcontract': 'Underleverantörsavtal',
  'userGroup.permissions.company': 'Företag',
  'userGroup.permissions.person.accessPermit.label': 'Kommentarer',
  'userGroup.permissions.person.accessPermit.role': 'Roll',
  'userGroup.permissions.person.accessPermit': 'Tillträdestillstånd',
  'userGroup.permissions.person.additionalRequiredField.postcode': 'Postnummer krävs',
  'userGroup.permissions.person.competence': 'Kompetensområden',
  'userGroup.permissions.person.drugTest': 'Drogtestning',
  'userGroup.permissions.person.healthCard': 'Sjukjournal',
  'userGroup.permissions.person.manualTaxNumberVerification': 'Verifiering av skattenummer',
  'userGroup.permissions.person.newcomerTraining': 'Nybörjarutbildning',
  'userGroup.permissions.person.securityClearance': 'Säkerhetskontroll',
  'userGroup.permissions.person.tags': 'Taggar',
  'userGroup.permissions.person.workSafetyCard': 'Arbetsmiljökort',
  'userGroup.permissions.person': 'Personer',
  'userGroup.permissions.site.clockIn': 'Instämplingar',
  'userGroup.permissions.site.contract.contractorObligation': 'Entreprenörens skyldigheter',
  'userGroup.permissions.site.contract.payment': 'Betalningar',
  'userGroup.permissions.site.contract.requestForApproval': 'Godkännande',
  'userGroup.permissions.site.contract': 'Avtal',
  'userGroup.permissions.site.dailyReport.supervisor': 'Chefens anteckningar',
  'userGroup.permissions.site.dailyReport.template': 'Mall för daglig byggrapport',
  'userGroup.permissions.site.dailyReport': 'Daglig byggrapport',
  'userGroup.permissions.site.device': 'Enheter',
  'userGroup.permissions.site.doc': 'Handlingar',
  'userGroup.permissions.site.invoiceHours': 'Faktureringstimmar',
  'userGroup.permissions.site.requiredCompetences': 'Kravprofil',
  'userGroup.permissions.site.safety.deviation.anonymous': 'Anonym',
  'userGroup.permissions.site.safety.deviation.environment': 'Miljö',
  'userGroup.permissions.site.safety.deviation.positive': 'Positiv',
  'userGroup.permissions.site.safety.deviation': 'Säkerhetsavvikelser',
  'userGroup.permissions.site.safety.measurement': 'Säkerhetsmätningar',
  'userGroup.permissions.site.safety.summary': 'Säkerhetssammanfattning från arbetsplatser',
  'userGroup.permissions.site.safety': 'Säkerhet',
  'userGroup.permissions.site.travelExpense': 'Resekostnader',
  'userGroup.permissions.site': 'Arbetsplatser',
  'userGroup.personRights': 'Åtkomst för personer',
  'userGroup.safetyRights': 'Rättigheter till säkerhetsmärkningar',
  'userGroup.sitePermissionsInfo.sitePermissionsInfo': 'Rätten att ändra inställningar för skatterapporter för en arbetsplats bunden till rätten att skapa en ny arbetsplats.',
  'userGroup.sitePermissionsInfo': 'Behörighet att ändra inställningar för arbetsplatsens skatterapport har länkats till behörighet att lägga till nya arbetsplatser.',
  'userGroup.siteRights': 'Avtalsrättigheter',
  'userGroup.standardCantBeChanged': 'Standardgruppen kan inte ändras.',
  'userGroup.standardGroup': 'Standardgrupp',
  'userGroup.thisIsStandard': 'Detta är en standardgrupp.',
  'userGroup.userGroupsModifyInfo': 'Endast administratörer har rätt att ändra användarroller.',
  'userSettings.changePassword': 'Ändra lösenord',
  'userSettings.defaultCompany': 'Standardföretag',
  'userSettings.defaultCompanyAndSite': 'Standardföretag och arbetsplats',
  'userSettings.defaultCompanyAndSiteIntro': 'Standardföretag och -arbetsplats väljs automatiskt vid inloggning.',
  'userSettings.defaultCompanyAndSiteShort': 'Standardföretag',
  'userSettings.defaultSite': 'Standardarbetsplats',
  'userSettings.defaultSiteIntro': 'Standardarbetsplats väljs automatiskt vid inloggning.',
  'userSettings.defaultSiteOnlyForDefaultCompany': 'Standardarbetsplats kan endast ställas in om du har loggat in som standardföretag (för närvarande är du inloggad på {0}).',
  'userSettings.settingsSaved': 'Inställningar sparade!',
  'userSettings': 'Användarinställningar',
  'validator.errors.emailFormat': 'Ogiltig e-postadress',
  'validator.errors.emailNotUnique': 'Någon annan har redan denna adress',
  'validator.errors.phoneFormat': 'Ogiltigt telefonnummer',
  'validator.errors.phoneNotUnique': 'Någon annan har redan detta nummer',
  'validator.errors.preventEmptying': 'Kontaktuppgifter krävs för att en person ska kunna logga in.',
  'validator.errors.required': 'Obligatoriskt',
  'validator.valueNotChanged': 'Värdet har inte ändrats',
  'vastuugroup.service.notavailable': 'Vastuu Group-uppgifter ej tillgängliga',
  'vastuugroup.service.notAvailableInfo': 'För att ladda uppgifter om entreprenörsansvar krävs ett aktiverat Vastuu Group Företagskonto och Zeroni API-identifierare i <a href="https://www.vastuugroup.fi/" target="_blank">Vastuu Groups nättjänst</a>. Informationen om aktiveringen av identifieraren uppdateras automatiskt i Zeroni med en timmes fördröjning efter tjänsteaktiveringen.',
  'vastuugroup.service.pdf': 'PDF',
  'vehiclePermit.active': 'Aktiv',
  'vehiclePermit.activeDaily': 'Aktiv dagligen',
  'vehiclePermit.approvalCancelledUponChange': 'Godkännandet upphävs om fordonsuppgifterna ändras.',
  'vehiclePermit.approved': 'Godkänd',
  'vehiclePermit.approvedOn': 'Godkänd {0}.',
  'vehiclePermit.approvers': 'Godkännare',
  'vehiclePermit.cancelApprovalAndEdit': 'Avbryt godkännande och redigera fordonsuppgifter',
  'vehiclePermit.cancelRejectionAndEdit': 'Avbryt avslag och redigera fordonsuppgifter',
  'vehiclePermit.confirmRemoveVehicleFromVehiclePermit': 'Är du säker på att du vill ta bort fordonet {0}?',
  'vehiclePermit.confirmVehiclePermitDeletion': 'Är du säker på att du vill ta bort detta fordonstillstånd?',
  'vehiclePermit.customOrforeignRegistrationNumber': 'Anpassat eller utländskt registreringsnummer',
  'vehiclePermit.duration': 'Varaktighet',
  'vehiclePermit.expired': 'Förfallen',
  'vehiclePermit.hasToBeActiveForAtLeast1Minute': 'Måste vara aktiv i minst 1 minut.',
  'vehiclePermit.missingAccessPermitForPeriods': 'Personen har inte aktivt tillträdestillstånd för följande datum {0}. Du kan skapa fordonstillstånd för med aktuellt vald tidsperiod, men tillståndet kommer inte att vara aktivt under ovannämnda datum.',
  'vehiclePermit.missingUsersFromListOfPossibleApprovers': 'Om personen du söker inte finns med i listan, se till att de har en användarroll som har behörighet att godkänna fordonstillstånd.',
  'vehiclePermit.newVehiclePermit': 'Nytt fordonstillstånd',
  'vehiclePermit.noApproversThus': 'Inga fordonsgodkännare valda. Administratörerna godkänner fordonstillstånd.',
  'vehiclePermit.noPermission': 'Inga behörigheter för fordonstillstånd.',
  'vehiclePermit.noPermissionVerbose': 'Din användarroll har inte behörighet att läsa fordonstillstånd. Be administratören på ditt företag att lägga till nödvändiga behörigheter till din nuvarande användarroll. Observera att de uppdaterade behörigheterna träder i kraft när du har loggat ut och loggat in igen.',
  'vehiclePermit.noPermitsOnThiSite': 'Inga fordonstillstånd på denna arbetsplats.',
  'vehiclePermit.notValid': 'Inaktiv',
  'vehiclePermit.noValidAccessPermitsForSelectedContract': 'Personen har inga tillträdestillstånd som för närvarande är aktiva eller kommer att bli aktiva i framtiden för valt avtal.',
  'vehiclePermit.pending': 'Väntar på godkännande',
  'vehiclePermit.permitApprovers': 'Godkännare',
  'vehiclePermit.personHasOverlappingVehiclePermits': 'Personen har redan följande fordonstillstånd:',
  'vehiclePermit.rejected': 'Avvisad',
  'vehiclePermit.rejectedOn': 'Avvisad {0}.',
  'vehiclePermit.requester': 'Beställare',
  'vehiclePermit.search': 'Sök',
  'vehiclePermit.searchHelp': 'Reg.nummer eller personens namn',
  'vehiclePermit.searchResults.many': '{0} fordonstillstånd',
  'vehiclePermit.searchResults.one': '1 fordonstillstånd',
  'vehiclePermit.showActiveAndUpcoming': 'Aktiv och kommande',
  'vehiclePermit.showAll': 'Alla',
  'vehiclePermit.showApprovable': 'Väntar på godkännande',
  'vehiclePermit.showApproved': 'Godkänd',
  'vehiclePermit.showPendingAndWaitingActions': 'Väntar på godkännande och väntar på åtgärder',
  'vehiclePermit.showWaitingActions': 'Väntar på åtgärder',
  'vehiclePermit.status': 'Status',
  'vehiclePermit.timePeriod': 'Tidsintervall',
  'vehiclePermit.upcoming': 'Kommande',
  'vehiclePermit.upcomingWithStartDate': 'Träder i kraft vid {0}',
  'vehiclePermit.updateRevertsVehiclePermitBackToPendingState': 'Är du säker på att du vill uppdatera detta fordonstillstånd? Uppdateringen återställer fordonstillståndet till tillståndet ”Väntar på godkännande”.',
  'vehiclePermit.useAsTemplate': 'Använd som mall',
  'vendorEvaluation.averageAll': 'Genomsnitt av alla utvärderingar',
  'vendorEvaluation.siteAverage': 'Genomsnitt av utvärderingar på denna arbetsplats',
  'visa.expirationDate': 'Sista giltighetsdatum',
  'vm.contract.continuing': 'Fortsätter ...',
  'vm.contract.interrupting': 'Avbryter ...',
  'weeklyHours.help': 'Timmar per vecka används för att fastställa olycksfrekvensen.',
  'workPermit.active': 'Aktiv',
  'workPermit.addPermit': 'Lägg till tillstånd',
  'workPermit.address': 'Adress',
  'workPermit.addressPlaceHolder': 'Adress',
  'workPermit.approvalAndSignaturesSideButton': 'Godkännande och underskrifter',
  'workPermit.approverSignature': 'Godkännarens underskrift',
  'workPermit.area': 'Arbetsplatsens namn',
  'workPermit.areDangersIdentified': 'Har arbetsrelaterade risker identifierats och relevanta försiktighetsåtgärder vidtagits?',
  'workPermit.assignment': 'Tilldelning',
  'workPermit.backToWorkPermits': 'Tillstånd för fortsatt arbete',
  'workPermit.changePermitOwner': 'Ändra tillståndsinnehavare',
  'workPermit.close': 'Avsluta arbetstillstånd',
  'workPermit.closed': 'Inga stängda arbetstillstånd',
  'workPermit.confirmationOfHandoverOfPermission': 'Den nya tillståndsinnehavaren bekräftar att de riskkontroller som anges i detta tillstånd och bifogade handlingar kommer att följas strikt och att alla personer i mitt arbetsteam kommer att informeras om dem',
  'workPermit.confirmationTextOfApproval': 'Tillståndsgodkännaren bekräftar att allt förberedande arbete inklusive isoleringar har slutförts och att arbetet kan fortsätta på ett säkert sätt.',
  'workPermit.confirmationTextOfPermitOwner': 'Tillståndsinnehavaren är fullt införstådd med att riskkontrollerna är på plats för att detta arbete ska kunna fortsätta.',
  'workPermit.confirmChangeOfPermitOwner': 'Bekräfta ändring av tillståndsinnehavare',
  'workPermit.currentWorkPermitHolder': 'Nuvarande medarbetare',
  'workPermit.dangerEstimation': 'Riskuppskattning',
  'workPermit.dangerEstimationMissing': 'Orsak till saknad utvärdering',
  'workPermit.dangerEvaluation': 'Riskbedömning (JSEA)',
  'workPermit.dangerSurvey': 'Riskidentifiering',
  'workPermit.describe': 'Beskriv',
  'workPermit.description': 'Arbetsbeskrivning och syfte',
  'workPermit.description2': 'beskrivning',
  'workPermit.descriptionAndGeneralInfo': 'Arbetsbeskrivning, Giltighetstid, Entreprenör och Godkännare',
  'workPermit.descriptionAndGeneralInfoSideButton': 'Beskrivning, Medarbetare, riskundersökning',
  'workPermit.duration': 'Varaktighet',
  'workPermit.electricalLockingNumber': 'Antal elektriska lås',
  'workPermit.electricLocking': 'Elektriskt lås',
  'workPermit.employeeAndIssuingDate': 'Medarbetare och utfärdandedatum',
  'workPermit.employeeNames': 'Namn på medarbetare',
  'workPermit.employer': 'Entreprenör',
  'workPermit.equipments': 'Utrustning',
  'workPermit.extend': 'Överföra arbetstillstånd',
  'workPermit.extending': 'Godkännande av förlängning av arbetstillstånd',
  'workPermit.exWork': 'Tidigare arbete',
  'workPermit.formerPermitOwnerSignature': 'Tidigare tillståndsinnehavares underskrift',
  'workPermit.hotWorkLocking': 'Heta arbeten',
  'workPermit.issuingPermit': 'Utfärda ett arbetstillstånd',
  'workPermit.jobTitle': 'Jobbtitel',
  'workPermit.jobTitleDescription': 'Jobbeskrivning',
  'workPermit.location': 'Plats',
  'workPermit.locked': 'Låst',
  'workPermit.locked2': 'LÅST',
  'workPermit.locker': 'Inskickare',
  'workPermit.lockingNumber': 'Låsnummer',
  'workPermit.mechanicalLocking': 'Mekaniska lås',
  'workPermit.mechanicalLockingNumber': 'Antal mekaniska lås',
  'workPermit.mobile.equipments': 'Utrustn.',
  'workPermit.mobile.general': 'Allmän',
  'workPermit.mobile.security': 'Säker.',
  'workPermit.mobile.signaturesAndConfirmation': 'Bekräfta.',
  'workPermit.nameOfSiteBuildingDepartmentRegionPlaceHolder': 'Namn på företag, byggnad, avdelning eller/och plats',
  'workPermit.new': 'Nytt arbetstillstånd',
  'workPermit.newWorkPermit': 'Nytt arbetstillstånd',
  'workPermit.newWorkPermitOwner': 'Ny tillståndsinnehavare',
  'workPermit.newWorkPermitOwnerSignature': 'Tillståndsmottagarens underskrift',
  'workPermit.nextStep': 'Nästa',
  'workPermit.noActivePermits': 'Inga aktiva arbetstillstånd',
  'workPermit.none': 'Inga arbetstillstånd',
  'workPermit.noPermitsHaveBeenAdded': 'Inga arbetstillstånd har lagts till',
  'workPermit.noTerminatedPermits': 'Inga avslutade tillstånd',
  'workPermit.opened': 'Öppnad',
  'workPermit.opened2': 'ÖPPNAD',
  'workPermit.openLockings': 'Öppna lås',
  'workPermit.otherWork': 'Annat arbete',
  'workPermit.permitOwner': 'Tillståndsinnehavare',
  'workPermit.permitOwners': 'Tillståndsinnehavare',
  'workPermit.permitOwnerSignature': 'Tillståndsinnehavarens underskrift',
  'workPermit.permitValidDailyAt': 'Tillståndet gäller dagligen kl.',
  'workPermit.personInCharge': 'ANSVARIG PERSON',
  'workPermit.personsInCharge': 'Ansvarig person',
  'workPermit.precautions': 'Försiktighetsåtgärder',
  'workPermit.precautionsSideButton': 'Försiktighetsåtgärder',
  'workPermit.remove': 'Ta bort',
  'workPermit.riskEvaluation': 'Riskuppskattning',
  'workPermit.safetyLocking': 'SÄKERHETSLÅS',
  'workPermit.safetyLockings': 'Säkerhetslås',
  'workPermit.signatureHolder': 'Tillståndsinnehavarens underskrift',
  'workPermit.signatureIssuer': 'Tillståndsgivarens underskrift',
  'workPermit.subscriber': 'Arbetsbeskrivning, giltighetstid, byggherre och godkännare',
  'workPermit.terminate': 'Avsluta',
  'workPermit.terminated': 'Avslutade',
  'workPermit.terminateWorkPermit': 'Avsluta arbetstillstånd',
  'workPermit.terminationConfirmationText': 'Allt arbete som är kopplat till detta tillstånd är fullständigt och säkert och inget ytterligare arbete är tillåtet.',
  'workPermit.toolsSideButton': 'Utrustning',
  'workPermit.transferToOther': 'Överföring av arbetstillstånd till annan person',
  'workPermit.workDescription': 'Orsak:',
  'workPermit.workers': 'Medarbetare',
  'workPermit.workPermitApprover': 'Tillståndsgodkännare',
  'workPermit.workPermitDelete': 'Ta bort arbetstillstånd?',
  'workPermit.workPermitDeleteMessage': 'Är du säker på att du vill radera arbetstillståndet?',
  'workPermit.workPermitHolder': 'Medarbetare',
  'workPermit.workPermits': 'Arbetstillstånd',
  'workPermit': 'Arbetstillstånd',
  'workPermits.close': 'Stäng arbetstillstånd',
  'workPermits.extend': 'Förlängning av arbetstillstånd',
  'workPermits.new': 'Nytt arbetstillstånd',
  'workPermits': 'Arbetstillstånd',
  'zfc': 'Zeroni för Entreprenör',
};
