/* eslint-disable max-len */
export default {
  'accessPermit.approval.changesRequested': 'Taotletud parandused',
  'accessPermit.approved': 'Heaks kiidetud',
  'accessPermit.expertise.missingRequiredExpertise': 'Puudused',
  'accessPermit.expertise.newRequirement': 'Uus nõue',
  'accessPermit.pending': 'Ootel',
  'accessPermit.revoked': 'Tühistatud ',
  'accessPermit.shortenedBy': 'Sissepääsuluba tööplatsile ajaliselt lühendatud vastavalt kvalifikatsiooni {0} kehtivusajale',
  'accessPermit.verification': 'Töötamisõiguse kontroll',
  'anonymousreport.anonymousReports': 'Anonüümseid teateid',
  'anonymousreport.anonymousReportsShort': 'Anonüümne',
  'anonymousreport.change': 'Muutus',
  'anonymousreport.confirmReport': 'Kinnitan aruande',
  'anonymousreport.geolocationFailed': 'Ei saanud oma asukoha. Veenduge, et teil on lubatud jagada oma asukohta.',
  'anonymousreport.gettingLocation': 'Saada oma asukoht...',
  'anonymousreport.locateAgain': 'Leidke uuesti',
  'anonymousreport.located': 'Asukoht leitud',
  'anonymousreport.lookingForSites': 'Otsin lähedal saitide...',
  'anonymousreport.noNearbySites': 'No saite leida',
  'anonymousreport.refresh': 'Uuenda',
  'anonymousreport.removeReport': 'Eemalda tapahtuma',
  'anonymousreport.reportSent': 'Aruanne saadetakse!',
  'anonymousreport.selectObservationType': 'Valige vaatluse tüüp',
  'anonymousreport.showMoreSites': 'Näita rohkem saitide',
  'anonymousreport.turnOnGeolocationInstructions': 'Kuidas ma saan lubada asukoha määramise teenuste minu seadmes?',
  'connectionError': 'Ühenduse viga',
  'contactInfo.confirmEmail': 'Sisesta oma e-posti aadressile saadetud kood',
  'contactInfo.confirmInfo': 'Kontaktandmete kontrollimine',
  'contactInfo.confirmPhone': 'Telefoninumber on õige',
  'contactInfo.editEmail': 'Muuda e-posti aadressi',
  'contactInfo.editorModal': 'Kontaktandmete muutmise dialoogiaken',
  'contactInfo.editPhone': 'Muuda telefoninumbrit',
  'contactInfo.emailVerified': 'E-posti aadress kinnitatud',
  'contactInfo.enterCodeFromYourEmail': 'Sisesta oma e-posti aadressile saadetud kood',
  'contactInfo.enterCodeFromYourPhone': 'Sisesta SMS-iga saadetud kood',
  'contactInfo.isAddressCorrect': 'Kas see on sinu e-posti aadress?',
  'contactInfo.isAddressStillCorrect': 'Kas see on ikka veel sinu e-posti aadress?',
  'contactInfo.isNumberCorrect': 'Kas see on sinu telefoninumber?',
  'contactInfo.isNumberStillCorrect': 'Kas see on ikka veel sinu telefoninumber?',
  'contactInfo.iWillTakeCareOfThisLater': 'Ma tegelen sellega hiljem',
  'contactInfo.phoneVerified': 'Telefoninumber kinnitatud',
  'contactInfo.verificationModal': 'Kontaktandmete kinnitamise dialoogiaken',
  'contactInfo.verifyEmail': 'Kinnita e-posti aadress',
  'contactInfo.verifyPhone': 'Kinnita telefoninumber',
  'contactInfo.wrongCodeTryAgain': 'Vale kood. Proovi uuesti.',
  'contactInfoStatusBadge.verified': 'Kinnitatud',
  'employeecheckin.areas.checkIn': 'Registreeri end tööplatsile',
  'employeecheckin.loadWorkTimeReports': 'Kuva kõigi tööplatside töötundide summa',
  'employeecheckin.noAccessPermits': 'Sul pole sissepääsuluba ühelegi tööplatsile',
  'employeeMenu.accessPermits': 'Sissepääsuload tööplatsidele',
  'employeeMenu.checkInsHistory': 'Registreerimise ajalugu',
  'employeeMenu.competences': 'Kvalifikatsioonid',
  'expertise.pendingTraining': 'Läbitav koolitus',
  'expertise.pendingTrainings': 'Läbitavad koolitused',
  'footer.call': 'Helista',
  'footer.or': 'või',
  'footer.privacyPolicy': 'Andmekaitseteade',
  'footer.support': 'Tugi:',
  'invoiceHours.employee.summary': 'Tööaja jaotumise ajalugu',
  'label.a1CertificateExpired': 'A1 sertifikaat on aegunud',
  'label.accessPermitRejected': 'Tagasi lükatud',
  'label.accessPermitValid': 'Sissepääsuluba kehtib',
  'label.addCommentToCheckIn': 'Lisa sisseregistreerimisele kommentaar',
  'label.addCommentToCheckOut': 'Lisa väljaregistreerimisele kommentaar',
  'label.allRequiredFieldsValid': 'Kogu kohustuslik teave on täidetud',
  'label.area': 'Tööplats',
  'label.atTime': 'kell',
  'label.begins': 'Algab',
  'label.cancel': 'Loobu',
  'label.checkInAlreadyExists': 'Sa oled juba sisseregistreeritud',
  'label.checkOutForgottenHeading': 'Väljaregistreerimist pole tehtud',
  'label.chooseLanguage': 'Vali keel',
  'label.closeWithoutSaving': 'Sulge salvestamata',
  'label.comment': 'Kommentaar',
  'label.commentOrReimbursment': 'Kommentaar / kulude hüvitamine',
  'label.company': 'Ettevõte',
  'label.competences': 'Kvalifikatsioonid',
  'label.dashboard': 'Avaleht',
  'label.default': 'Vaikimisi',
  'label.edit': 'Muuda',
  'label.employeeInfo': 'Isikuandmed',
  'label.ended': 'Aegunud',
  'label.endTask': 'Lõpeta <strong>{0}</strong>',
  'label.enterSickLeaveReason': 'Sisesta haiguslehe põhjus',
  'label.enterUsernameFirst': 'Sisesta kõigepealt kasutajatunnus',
  'label.geolocationFailed': 'Positsioneerimine ebaõnnestus. Registreerimisele lisatakse märkus.',
  'label.hide': 'Peida',
  'label.in': 'Sisse',
  'label.introduction': 'Sissejuhatus',
  'label.invoiceHours': 'Tööaja jaotumine',
  'label.lastCheckOut': 'Eelmine väljaregistreerimine',
  'label.lastWeek': 'Eelmine nädal',
  'label.logout': 'Logi välja',
  'label.mobile.home': 'Avaleht',
  'label.no': 'Ei',
  'label.notes': 'Lisateave',
  'label.out': 'Välja',
  'label.outsideSite': 'Oled väljaspool tööplatsi ala. Sinu registreerimisele lisatakse märkus.',
  'label.password': 'Salasõna',
  'label.photo': 'Foto',
  'label.ready': 'Valmis!',
  'label.required': 'Nõutav teave',
  'label.residencePermitExpired': 'Elamisluba on aegunud',
  'label.safety': 'Ohutus',
  'label.save': 'Salvesta',
  'label.saving': 'Salvestamine...',
  'label.selectEndDate': 'Vali lõppkuupäev',
  'label.selectPhoto': 'Valige foto',
  'label.selectSite': 'Valige saidil',
  'label.sending': 'Saatmine...',
  'label.sendPasswordInSMS': 'Saada salasõna',
  'label.show': 'Näita',
  'label.startTask': 'Alusta <strong>{0}</strong>',
  'label.thisWeek': 'Jooksev nädal',
  'label.travelExpenseClaims': 'Sõiduhüvitis',
  'label.usernameShort': 'Kasutajatunnus',
  'label.visitor': 'Külaline',
  'label.workHourBalance': 'Töötundide saldo',
  'label.workingHoursCombined': 'Kõigi tööplatside töötundide kogusumma',
  'label.workingTime': 'Tööaeg',
  'label.yes': 'Jah',
  'login.enterAccountAndNumber': 'Sisesta oma kasutajatunnus',
  'login.incorrectRequest': 'Vigane päring!',
  'login.infoMessage': 'Saadame sinu mobiiltelefonile uue salasõna',
  'login.login': 'Logi sisse',
  'login.newPasswordSentToTelephone': 'Sinu uus salasõna on saadetud mobiiltelefonile',
  'login.ohNoIe': '<p>Sa kasutad brauserit <strong>Internet Explorer</strong>, mida Zeroni enam ei toeta. Toetatud brauserid on <a href=\'https://www.google.com/chrome/\'>Chrome</a>, <a href=\'https://www.getfirefox.com/\'>Firefox</a>, <a href=\'https://www.opera.com/\'>Opera</a>, <a href=\'https://www.microsoft.com/fi-fi/windows/microsoft-edge/\'>Edge</a> ja <a href=\'https://www.apple.com/safari/\'>Safari</a>.</p>',
  'login.passwordForgotten': 'Unustasid salasõna?',
  'login.requestNewPassword': 'Taotle uut salasõna',
  'login.returnToLogin': 'Tagasi sisselogimislehele',
  'login.timeout': 'Sisselogimisaja lõpp: sisselogimine võttis liiga kaua aega!',
  'login.userHasSsoUsername': '<p>Teie ettevõttel on kasutusel eraldi sisselogimisteenus <strong>Azure AD</strong>.</p><p>Logige Zeroni sisse, kasutades oma AD-teenust.</p>',
  'login.wrongPassword': 'Vale e-posti aadress või salasõna',
  'login.wrongVerificationCode': 'Kinnituskood on kehtetu!',
  'mainHelp.call': 'Helista',
  'mainHelp.email': 'Saada kiri',
  'mainHelp.help.desc': 'Nõuanded, juhised, KKK.',
  'mainHelp.help.heading': 'Kasutusjuhend',
  'mainHelp.privacyPolicy': 'Andmekaitseteade',
  'mainHelp.resources': 'Ressursid',
  'mainHelp.support': 'Tugi',
  'mainHelp.telephone.hours': 'tööpäeviti kell 8.00–16.00',
  'mainHelp.telephone.price': '0,69 €/min',
  'message.addOtherNotesViaCheckInList': 'Muude märkuste ja kuluhüvitiste lisamiseks lehitse <a href=\'{0}\'>registreerimise ajalugu</a>',
  'message.checkoutForgottenBody': '<p>Sa registreerisid end sisse <strong>{0}</strong> tööplatsile <strong>{1}</strong>, kuid väljaregistreerimine on veel tegemata.</p><p>Sisesta tööplatsilt lahkumise aeg.</p>',
  'message.firstLoginNoPassword': '<p><strong>Tere, {0}!</strong></p><p>sul pole veel Zeroni salasõna. Saada see endale SMS-iga.</p>',
  'message.passwordSent': '<strong>Salasõna saadetud sinu mobiiltelefonile. </strong> Nüüd saad sisse logida.',
  'message.selectEndDate': '<strong>{{vm.task.text}}</strong> võib võtta mitu päeva. Vali lõppkuupäev.',
  'message.sessionExpired': '<strong>Olid liiga kaua passiivne</strong> ja seetõttu logiti sind välja. Logi uuesti sisse.',
  'message.sickLeaveReason': 'Sisesta haiguslehe võtmise põhjus.',
  'message.unknownUsername': '<strong>Zeroni ei tunne sisestatud kasutajatunnust ära.</strong> Kontrolli tunnust.',
  'messaging.send': 'Saatmine',
  'monitoring.positiveReport': 'Positiivset aruannet',
  'more': 'Sisesta',
  'password.changePassword': 'Muuda salasõna',
  'password.changing': 'Muutmine...',
  'password.invalidPasswordMessage': '<strong>Vale salasõna</strong>: Kontrolli vana salasõna ja proovi uuesti.',
  'password.newPassword': 'Uus salasõna',
  'password.oldPassword': 'Vana salasõna',
  'quality.defect': 'Kvaliteedi viga',
  'quality.quality': 'Kvaliteet',
  'reportersName': 'Reporterid nimi',
  'safety.description': 'Kirjeldus',
  'safety.environmentalHazard': 'Keskkonnaohu',
  'safety.hazard': 'Vaatlus',
  'safety.location': 'Asukoht',
  'topMenu.loginAsCompany': 'Logi sisse ettevõttena',
  'topMenu.loginAsEmployee': 'Logi sisse töötajana',
  'user.session': 'Seanss',
  'userEditor.onlyUserCanChange': 'Muuta saab ainult isik ise',
  'userSettings.changePassword': 'Muuda salasõna',
  'userSettings.defaultCompanyAndSite': 'Vaikimisi ettevõte ja tööplats',
  'userSettings.defaultSite': 'Vaikimisi tööplats',
  'userSettings': 'Kasutaja seaded',
  'validator.errors.emailFormat': 'Vale e-posti aadress',
  'validator.errors.emailNotUnique': 'See aadress on juba kasutusel',
  'validator.errors.phoneFormat': 'Vale telefoninumber',
  'validator.errors.phoneNotUnique': 'See telefoninumber on juba kasutusel',
  'validator.errors.required': 'Nõutav teave',
  'validator.valueNotChanged': 'Väärtus pole muutunud',
};
