import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import classnames from 'classnames';

import I18n from 'ReactComponents/I18n';

const Header = (props) => {
  return (
    <Modal.Header
      as={props.as}
      bsPrefix={props.bsPrefix}
      className={classnames('pb-0', props.className)}
      closeButton={props.closeButton}
      onHide={props.onCancel}
      data-testid="header"
    >
      <Modal.Title
        as={props.titleAs}
        bsPrefix={props.titleBsPrefix}
        className={classnames('mb-0', props.titleClassName)}
        data-testid="title"
      >
        {props.title && <I18n code={props.title} params={props.titleParams} />}
        {props.children && props.children}
      </Modal.Title>
    </Modal.Header>
  );
};

Header.defaultProps = {
  closeButton: true,
};

Header.propTypes = {
  as: PropTypes.string,
  bsPrefix: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  closeButton: PropTypes.bool,
  onCancel: PropTypes.func,
  title: PropTypes.string,
  titleAs: PropTypes.string,
  titleBsPrefix: PropTypes.string,
  titleClassName: PropTypes.string,
  titleParams: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.number]),
};

export default Header;