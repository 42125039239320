import Modal from './Modal';
import Header from './Header';
import Body from './Body';
import Footer from './Footer';
import portal from './portal';

export default Object.assign(Modal, {
  Header,
  Body,
  Footer,
  portal,
});