/* eslint-disable max-len */
export default {
  'accessPermit.approval.changesRequested': 'Požadované zlepšenia',
  'accessPermit.approved': 'Schválené',
  'accessPermit.expertise.missingRequiredExpertise': 'Chýbajúca odborná spôsobilosť',
  'accessPermit.expertise.newRequirement': 'Nová požiadavka',
  'accessPermit.pending': 'Čaká',
  'accessPermit.revoked': 'Odobrané ',
  'accessPermit.shortenedBy': 'Oprávnenie na prístup skrátené v závislosti od dĺžky platnosti kvalifikácie {0}',
  'accessPermit.verification': 'Preverenie práva na prácu',
  'connectionError': 'Chyba spojenia',
  'contactInfo.confirmEmail': 'Zadajte kód, ktorý vám bol zaslaný e-mailom',
  'contactInfo.confirmInfo': 'Kontrola kontaktných informácií ',
  'contactInfo.confirmPhone': 'Telefónne číslo je správne',
  'contactInfo.editEmail': 'Spracovať e-mailovú adresu',
  'contactInfo.editorModal': 'Dialógové okno na spracovanie kontaktu',
  'contactInfo.editPhone': 'Spracovanie telefónneho čísla',
  'contactInfo.emailVerified': 'E-mailová adresa potvrdená',
  'contactInfo.enterCodeFromYourEmail': 'Vložte kód, ktorý vám bol zaslaný e-mailom',
  'contactInfo.enterCodeFromYourPhone': 'Vložte kód, ktorý ste dostali v e-maili',
  'contactInfo.isAddressCorrect': 'Je toto vaša e-mailová adresa?',
  'contactInfo.isAddressStillCorrect': 'Je toto ešte stále vaša e-mailová adresa?',
  'contactInfo.isNumberCorrect': 'Je toto vaše telefónne číslo?',
  'contactInfo.isNumberStillCorrect': 'Je toto ešte stále vaše telefónne číslo?',
  'contactInfo.iWillTakeCareOfThisLater': 'Vybavím to neskôr',
  'contactInfo.phoneVerified': 'Telefónne číslo potvrdené',
  'contactInfo.verificationModal': 'Dialógové okno na potvrdenie kontaktu',
  'contactInfo.verifyEmail': 'Potvrdiť e-mailovú adresu',
  'contactInfo.verifyPhone': 'Potvrdiť telefónne číslo',
  'contactInfo.wrongCodeTryAgain': 'Nesprávny kód. Skúste to, prosím, znovu.',
  'contactInfoStatusBadge.verified': 'Potvrdené',
  'employeecheckin.areas.checkIn': 'Zaznamenať čas príchodu na miesto práce',
  'employeecheckin.loadWorkTimeReports': 'Zobraziť celkový pracovný čas na všetkých miestach práce',
  'employeecheckin.noAccessPermits': 'Nemáte pre toto miesto práce prístupové oprávnenie',
  'employeeMenu.accessPermits': 'Prístupové oprávnenia',
  'employeeMenu.checkInsHistory': 'Priebeh záznamu dĺžky pobytu na mieste práce',
  'employeeMenu.competences': 'Odborná spôsobilosť a kvalifikácia',
  'expertise.pendingTraining': 'Školenie na povinné absolvovanie',
  'expertise.pendingTrainings': 'Školenia na povinné absolvovanie',
  'footer.call': 'Zavolať telefonicky',
  'footer.or': 'alebo',
  'footer.privacyPolicy': 'Vyhlásenie o ochrane osobných údajov',
  'footer.support': 'Podpora:',
  'invoiceHours.employee.summary': 'Priebeh rozdelenia pracovného času ',
  'label.a1CertificateExpired': 'Vypršala platnosť osvedčenia A1',
  'label.accessPermitRejected': 'Odmietnuté ',
  'label.accessPermitValid': 'Prístupové oprávnenie pre miesto práce je platné',
  'label.addCommentToCheckIn': 'Pridať komentár k záznamu času príchodu na miesto práce',
  'label.addCommentToCheckOut': 'Pridať komentár k záznamu času odchodu z miesta práce',
  'label.allRequiredFieldsValid': 'Všetky povinné údaje boli vyplnené',
  'label.area': 'Miesto práce ',
  'label.atTime': 'Čas:',
  'label.begins': 'Začína o',
  'label.checkInAlreadyExists': 'Váš čas príchodu na miesto práce je už zaznamenaný',
  'label.checkOutForgottenHeading': 'Nezaznamenaný čas odchodu z miesta práce',
  'label.closeWithoutSaving': 'Zavrieť bez uloženia',
  'label.comment': 'Komentár',
  'label.commentOrReimbursment': 'Komentár / preplatenie výdavkov',
  'label.company': 'Podnik',
  'label.competences': 'Odborná spôsobilosť a kvalifikácia',
  'label.dashboard': 'Úvodná stránka',
  'label.default': 'Štandard',
  'label.edit': 'Spracovať',
  'label.employeeInfo': 'Osobné údaje',
  'label.ended': 'Ukončené',
  'label.endTask': 'Ukončiť <strong>{0}</strong>',
  'label.enterSickLeaveReason': 'Uviesť dôvod pre práceneschopnosť',
  'label.enterUsernameFirst': 'Zadajte najskôr vaše používateľské ID',
  'label.geolocationFailed': 'Určenie miesta práce sa nepodarilo. K záznamu dĺžky pobytu na mieste práce sa priradí poznámka.',
  'label.hide': 'Skryť',
  'label.in': 'Dnu',
  'label.introduction': 'Zapracovanie pracovníka',
  'label.invoiceHours': 'Rozdelenie pracovného času',
  'label.lastCheckOut': 'Predchádzajúci záznam času odchodu z miesta práce',
  'label.lastWeek': 'Posledný týždeň',
  'label.logout': 'Odhlásiť',
  'label.mobile.home': 'Úvodná stránka',
  'label.no': 'Nie',
  'label.notes': 'Ďalšie informácie',
  'label.out': 'Von',
  'label.outsideSite': 'Nachádzate sa mimo miesta práce. K vášmu záznamu dĺžky pobytu na mieste práce sa priradí poznámka.',
  'label.password': 'Heslo',
  'label.ready': 'Hotovo!',
  'label.required': 'Povinný údaj',
  'label.residencePermitExpired': 'Skončila platnosť povolenia na pobyt',
  'label.safety': 'Bezpečnosť',
  'label.save': 'Uložiť',
  'label.saving': 'Ukladá sa...',
  'label.selectEndDate': 'Zvoliť dátum vypršania platnosti',
  'label.sending': 'Zasiela sa...',
  'label.sendPasswordInSMS': 'Zaslať heslo',
  'label.show': 'Zobraziť',
  'label.startTask': 'Začať <strong>{0}</strong>',
  'label.thisWeek': 'Aktuálny týždeň',
  'label.travelExpenseClaims': 'Preplatenie cestovných nákladov',
  'label.usernameShort': 'Používateľské ID',
  'label.visitor': 'Hosť',
  'label.workHourBalance': 'Stav konta pracovného času',
  'label.workingHoursCombined': 'Celkový počet pracovných hodín všetkých pracovných miest',
  'label.workingTime': 'Pracovný čas',
  'label.yes': 'Áno',
  'login.enterAccountAndNumber': 'Vložiť používateľské ID',
  'login.incorrectRequest': 'Chybná žiadosť!',
  'login.infoMessage': 'Zasielame vám nové heslo na vaše mobilné telefónne číslo',
  'login.login': 'Prihlásiť',
  'login.newPasswordSentToTelephone': 'Vaše nové heslo sme vám zaslali na mobilné telefónne číslo',
  'login.ohNoIe': '<p>Používate <strong>prehliadač Internet Explorer</strong>, ktorý už systém Zeroni nepodporuje. Podporované prehliadače sú <a href=\'https://www.google.com/chrome/\'>Chrome</a>, <a href=\'https://www.getfirefox.com/\'>Firefox</a>, <a href=\'https://www.opera.com/\'>Opera</a>, <a href=\'https://www.microsoft.com/fi-fi/windows/microsoft-edge/\'>Edge</a> a <a href=\'https://www.apple.com/safari/\'>Safari</a>.</p>',
  'login.passwordForgotten': 'Zabudli ste heslo?',
  'login.requestNewPassword': 'Vyžiadať nové heslo',
  'login.returnToLogin': 'Späť na prihlasovaciu stránku',
  'login.timeout': 'Prekročený čas pri prihlasovaní: trvalo vám to príliš dlho!',
  'login.userHasSsoUsername': '<p>Váš podnik má vlastnú prihlasovaciu službu, <strong>Azure AD</strong>.</p><p> Prihláste sa v systéme Zeroni cez vašu službu AD.</p>',
  'login.wrongPassword': 'Nesprávna e-mailová adresa alebo nesprávne heslo',
  'login.wrongVerificationCode': 'Potvrdzovací kód nie je správny!',
  'mainHelp.call': 'Zavolať telefonicky',
  'mainHelp.email': 'Zaslať správu',
  'mainHelp.help.desc': 'Tipy, rady, často kladené otázky',
  'mainHelp.help.heading': 'Pokyny na použitie ',
  'mainHelp.privacyPolicy': 'Vyhlásenie o ochrane osobných údajov',
  'mainHelp.resources': 'Zdroje',
  'mainHelp.support': 'Podpora',
  'mainHelp.telephone.hours': 'v pracovné dni od 8 do 16 h.',
  'mainHelp.telephone.price': '0,69 €/min',
  'message.addOtherNotesViaCheckInList': 'Vložiť ďalšie komentáre a preplatenie výdavkov tak, že prehľadáte <a href=\'{0}\'>priebeh záznamu dĺžky pobytu na mieste práce</a>',
  'message.checkoutForgottenBody': '<p>Zaznamenali ste čas príchodu <strong>{0}</strong> na miesto práce <strong>{1}</strong> , avšak nezaznamenali ste ešte čas odchodu z miesta práce.</p><p> Zadajte, prosím, čas, kedy ste opustili miesto práce.</p>',
  'message.firstLoginNoPassword': '<p><strong>Dobrý deň, {0}!</strong></p><p>pre systém Zeroni ešte nemáte heslo. Zašlite si ho sám sebe.</p>',
  'message.passwordSent': '<strong>Heslo bolo zaslané na váš mobilný telefón.</strong> Teraz sa môžete prihlásiť.',
  'message.selectEndDate': '<strong>{{vm.task.text}}</strong> môže byť potrebných viac dní. Zvoľte koncový dátum.',
  'message.sessionExpired': '<strong>Boli ste príliš dlho neaktívny</strong> a boli preto odhlásený. Prosím, znovu sa prihláste.',
  'message.sickLeaveReason': 'Uveďte, prosím, dôvod, prečo ste práceneschopný.',
  'message.unknownUsername': '<strong>Systém Zeroni nedokáže rozpoznať vami zadané ID.</strong> Skontrolujte, prosím, toto ID.',
  'more': 'Pridať',
  'password.changePassword': 'Zmeniť heslo',
  'password.changing': 'Heslo sa mení...',
  'password.invalidPasswordMessage': '<strong> Nesprávne heslo</strong>: skontrolujte vaše staré heslo a skúste to znovu.',
  'password.newPassword': 'Nové heslo',
  'password.oldPassword': 'Staré heslo',
  'quality.quality': 'Kvalita',
  'topMenu.loginAsCompany': 'Prihlásiť sa ako podnik',
  'topMenu.loginAsEmployee': 'Prihlásiť sa ako pracovník',
  'user.session': 'Porada',
  'userEditor.onlyUserCanChange': 'Spracovanie môže urobiť len daná osoba sama',
  'userSettings.changePassword': 'Zmeniť heslo',
  'userSettings.defaultCompanyAndSite': 'Štandardné nastavenia pre podniky a miesto práce',
  'userSettings.defaultSite': 'Štandardné nastavenia pre miesto práce',
  'userSettings': 'Používateľské nastavenia',
  'validator.errors.emailFormat': 'Nesprávna e-mailová adresa',
  'validator.errors.emailNotUnique': 'Táto adresa sa už používa',
  'validator.errors.phoneFormat': 'Nesprávne telefónne číslo',
  'validator.errors.phoneNotUnique': 'Toto číslo sa už používa',
  'validator.errors.required': 'Povinný údaj',
  'validator.valueNotChanged': 'Hodnota sa nezmenila',
};
