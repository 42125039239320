import deMessages from '../../../../blocks/i18n/messages-de';
import enMessages from '../../../../blocks/i18n/messages-en';
import etMessages from '../../../../blocks/i18n/messages-et';
import fiMessages from '../../../../blocks/i18n/messages-fi';
import plMessages from '../../../../blocks/i18n/messages-pl';
import ruMessages from '../../../../blocks/i18n/messages-ru';
import skMessages from '../../../../blocks/i18n/messages-sk';
import svMessages from '../../../../blocks/i18n/messages-sv';
var get = function (stringName, stringArgs) {
    if (!stringName) {
        return '';
    }
    var message;
    var lang = getLangCookie();
    var defaultMessages = enMessages;
    var messages = {
        de: deMessages,
        en: enMessages,
        et: etMessages,
        fi: fiMessages,
        pl: plMessages,
        ru: ruMessages,
        sk: skMessages,
        sv: svMessages,
    };
    var key = stringName.trim();
    message = messages[lang] && messages[lang][key] || defaultMessages[key] || stringName;
    if (typeof stringArgs === 'undefined') {
        return message;
    }
    if (stringArgs instanceof Array) {
        for (var i in stringArgs) {
            message = message.replace('{' + i + '}', stringArgs[i]);
        }
    }
    else {
        message = message.replace('{0}', stringArgs);
    }
    return message;
};
var getOrThrow = function (stringName, stringArgs) {
    var string = get(stringName, stringArgs);
    if (string === undefined) {
        throw 'String ' + stringName + ' not found!';
    }
    return string;
};
var getLangCookie = function () {
    if (!document.cookie || !document.cookie.length) {
        return 'fi';
    }
    var langCookie = document.cookie.split(';').find(function (cookie) { return cookie.trim().startsWith('lang='); });
    if (!langCookie) {
        return 'fi';
    }
    var cookieLang = langCookie.split('=')[1];
    var availableLanguages = isAdminUi() ? window.LANGUAGE_CODES_FOR_ADMIN : window.LANGUAGE_CODES_FOR_EMPLOYEE;
    if (availableLanguages.includes(cookieLang)) {
        return cookieLang;
    }
    return 'en';
    function isAdminUi() {
        return window.currentDomainId; // if currentDomainId is set, user is logged in to admin UI
    }
};
export default {
    get: get,
    getLanguage: getLangCookie,
    getOrThrow: getOrThrow,
};
