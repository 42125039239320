window.getEnvironment = function getEnvironment() {
  const environments = {
    'app.zeroni.fi': 'PRODUCTION',
    'sitemanager.takamaki.fi': 'PRODUCTION',
    'dev.zeroni.fi': 'TEST',
    'staging.zeroni.fi': 'STAGING',
  };
  return environments[window.location.hostname] || 'DEVELOPMENT';
};

