/**
 * Angular.js plugin
 *
 * Provides an $exceptionHandler for Angular.js
 */
import { getClient } from './Sentry';

// See https://github.com/angular/angular.js/blob/v1.4.7/src/minErr.js
var angularPattern = /^\[((?:[$a-zA-Z0-9]+:)?(?:[$a-zA-Z0-9]+))\] (.*?)\n?(\S+)$/;
var moduleName = 'ngSentry';


function angularPlugin() {
  angular = angular || window.angular;
  const sentry = getClient();

  if (!angular) {
    return;
  }

  function ExceptionHandlerProvider($provide) {
    $provide.decorator('$exceptionHandler', ['$delegate', function($delegate) {
      return function(ex, cause) {
        if (shouldReport(ex)) {
          sentry.captureException(ex, {
            extra: { cause },
          });
        }
        $delegate(ex, cause);
      };
    }]);

    function shouldReport(ex) {
      return !abortedCall(getMessage(ex));

      function abortedCall(message) {
        return message.indexOf('"status":-1') !== -1 ||
            message.indexOf('HTTP status: -1') !== -1;

      }
      function getMessage(ex) {
        if (typeof ex === 'object') {
          return ex.message;
        }
        return typeof ex === 'string' ? ex : '';
      }
    }
  }

  angular
    .module(moduleName, [])
    .config(['$provide', ExceptionHandlerProvider]);

}

angularPlugin._normalizeData = function(data) {
  // We only care about mutating an exception
  var exception = data.exception;
  if (exception) {
    exception = exception.values[0];
    var matches = angularPattern.exec(exception.value);

    if (matches) {
      // This type now becomes something like: $rootScope:inprog
      exception.type = matches[1];
      exception.value = matches[2];

      data.message = exception.type + ': ' + exception.value;
      // auto set a new tag specifically for the angular error url
      data.extra.angularDocs = matches[3].substr(0, 250);
    }
  }

  return data;
};

angularPlugin.moduleName = moduleName;

export default angularPlugin;

angularPlugin();