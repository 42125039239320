import React from 'react';
import ReactDOM from 'react-dom';
import { has } from 'lodash';

const portal = (children, node) => {
  const rootNode = node || document.createElement('div');
  document.body.appendChild(rootNode);

  const unmountModalNode = () => {
    ReactDOM.unmountComponentAtNode(rootNode);
    document.body.removeChild(rootNode);
  };

  ReactDOM.render(
    <ModalRenderer unmountModalNode={unmountModalNode}>
      {children}
    </ModalRenderer>,
    rootNode,
  );
};

const ModalRenderer = ({ unmountModalNode, children }) => {
  const [isOpen, setIsOpen] = React.useState(true);

  const closeModalAndUnmountNode = () => {
    setIsOpen(false);
    setTimeout(() => {
      unmountModalNode();
    }, 1000);
  };

  const handleSuccess = (...args) => {
    if (has(children, 'props.onSuccess')) {
      children.props.onSuccess(...args);
    }
    closeModalAndUnmountNode();
  };

  const handleClose = (...args) => {
    if (has(children, 'props.onClose')) {
      children.props.onClose(...args);
    }
    closeModalAndUnmountNode();
  };

  const handleCancel = (...args) => {
    if (has(children, 'props.onCancel')) {
      children.props.onCancel(args);
    }
    closeModalAndUnmountNode();
  };

  return React.cloneElement(children, {
    isOpen,
    onSuccess: handleSuccess,
    onClose: handleClose,
    onCancel: handleCancel,
  });
};

export default portal;
