/* global app, userAccessLevel, jQuery, SYSTEM */
/* eslint @typescript-eslint/no-unused-vars: 0 */

/**
 * Filters
 */

if (typeof app !== typeof undefined) {
  app.filter('slice', function() {
    return function(arr, start, length) {
      return (arr || []).slice(start, start + length);
    };
  });

  app.filter('reverse', function() {
    return function(items) {
      return items.slice().reverse();
    };
  });

  app.filter('ceil', function() {
    return function(n) {
      return Math.ceil(n);
    };
  });

  app.filter('abs', function () {
    return function(val) {
      return Math.abs(val);
    };
  });

  app.filter('endDateFilter', function() {
    return function(items, showOnlyActive) {
      if (!showOnlyActive) {
        return items;
      } else {
        var filtered = [];
        angular.forEach(items, function(item) {
          if (userAccessLevel >= item.minAccess) {
            filtered.push(item);
          }
        });
        return filtered;
      }
    };
  });

}

/***
 * 1500 lines of other stuff :-)
 */

window.clone = function clone(obj) {
  return jQuery.extend(true, {}, obj);
};

window.uniqueSort = function uniqueSort(a) {
  return a.sort().filter(function(item, pos, ary) {
    return !pos || item !== ary[pos - 1];
  });
};

window.ratingAverages = function ratingAverages(rating) {
  var subset1Total = rating.AP101 + rating.AP102 + rating.AP103;
  rating.subset1 = (subset1Total) / 3 || false;

  var subset2Total = rating.AP104 + rating.AP105 + rating.AP106;
  rating.subset2 = (subset2Total) / 3 || false;

  var subset3Total = rating.AP107 + rating.AP108 + rating.AP109;
  rating.subset3 = (subset3Total) / 3 || false;

  var subset4Total = rating.AP110 + rating.AP111 + rating.AP112 + rating.AP113 + rating.AP114 + rating.AP115;
  rating.subset4 = (subset4Total) / 6 || false;

  rating.overview = (subset1Total + subset2Total + subset3Total + subset4Total) / 15 || false;
  return rating;
};

window.setPage = function setPage(scope, myDocument, searchFactory, pageNumber) {
  scope.currentPage = pageNumber;
  if ((scope.currentPage + 1) * scope.resultsPerPage > searchFactory.items.length) {
    searchFactory.nextPage();
  }
  myDocument.scrollTop(0, 500);
};

window.submitClickedOnForm = function submitClickedOnForm(form) {
  form.submitClicked = true;
  window.setAllInputsDirty(form);
};

window.setAllInputsDirty = function setAllInputsDirty(scope) {
  angular.forEach(scope, function(value, key) {
    // Skips non-form and non-inputs
    if (!value || value.$dirty === 'undefined') {
      return;
    }
    // Recursively applies same method on nested forms
    if (value.$addControl) {
      return setAllInputsDirty(value);
    } else if (value.$setDirty) {
      return value.$setDirty();
    }
  });
};

window.camelCase = function camelCase(input) {
  return input.toLowerCase().replace(/-(.)/g, function(match, group1) {
    return group1.toUpperCase();
  });
};

window.removeAllButLast = function removeAllButLast (string, token) {
  var parts = string.split(token);
  if (parts.length > 1) {
    return parts.slice(0, -1).join('') + token + parts.slice(-1);
  } else {
    return string;
  }
};

window.stripCurrency = function stripCurrency (amount) {
  var newAmount = String(amount).replace(/[^0-9,.-]+/g, '');
  newAmount = newAmount.replace(',', '.');
  newAmount = window.removeAllButLast(newAmount, '.');
  return parseFloat(newAmount);
};

if (typeof app !== typeof undefined) {

  app.directive('tmRequired', function() {
    return {
      restrict: 'E',
      replace: true,
      template: '<span class="required fas fa-asterisk"></span>',
    };
  });

  app.directive('tmOptional', function() {
    return {
      restrict: 'E',
      replace: true,
      template: '<span class="optional far fa-check"></span>',
    };
  });

  app.directive('tmRequiredLegend', ['$parse', function($parse) {
    return {
      restrict: 'E',
      replace: true,
      templateUrl: 'templates/secured/html/required-legend.html',
      link (scope, element, attrs) {
        if (attrs.hasOwnProperty('form')) {
          scope.form = $parse(attrs.form)(scope);
        }
      },
    };
  }]);

  app.directive('tmAlert', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        alertService: '=',
      },
      template: '<div class="alert-timeout alert alert-{{message.type}} alert-dismissible" ' +
        'role="alert" alert-timeout="7000">' +
        '<button type="button" class="close" data-dismiss="alert">' +
        '<span aria-hidden="true">&times;</span>' +
        '</button>' +
        '<p data-ng-bind-html="message.text"></p>' +
        '</div>',
    };
  });

  app.directive('tmHelp', function() {
    return {
      restrict: 'E',
      replace: true,
      template: '<span class="fas fa-question-circle hint min-height"></span>',
    };
  });

  app.directive('tmInput', function() {
    return {
      restrict: 'E',
      compile(element, attrs) {

        var inputElementStart, inputElementEnd, type;

        setType();

        function setType() {
          if (attrs.hasOwnProperty('textarea')) {
            var rows = attrs.rows ? 'rows="' + attrs.rows + '"' : '';
            inputElementStart = 'textarea ' + rows;
            inputElementEnd = '</textarea>';
          } else {
            type = attrs.type || 'text';
            inputElementStart = 'input type="' + type + '"';
            inputElementEnd = '';
          }
        }

        var placeholder = attrs.hasOwnProperty('placeholder') ? attrs.placeholder : attrs.label;

        var required = '';
        var requiredTag = '';

        setRequired();

        function setRequired() {
          if (attrs.hasOwnProperty('required')) {
            required = ' required="required" ';
            requiredTag = '<tm-required></tm-required>';
          }
        }

        var ngModelOptions = '';
        setModelOptions();
        function setModelOptions() {
          if (attrs.hasOwnProperty('ngModelOptions')) {
            ngModelOptions = ' data-ng-model-options="' + attrs.ngModelOptions + '" ';
          }
        }

        var rowClass = 'row form-group';
        rowClass += attrs.hasOwnProperty('size') ? ' form-group-' + attrs.size : '';
        rowClass += attrs.hasOwnProperty('divider') ? ' form-group-divider ' : '';

        var datePicker = '';
        var inputClass = 'form-control';
        var pattern = '';
        var secondInput = '';

        setDates();
        function setDates() {
          if (attrs.hasOwnProperty('date') || attrs.hasOwnProperty('beginAndEndDate')) {
            datePicker = ' simple-date-picker ';
            inputClass += ' date ';
          }
          if (attrs.hasOwnProperty('beginAndEndDate')) {
            secondInput = ' &mdash; <' + inputElementStart +
            //        ' oninvalid="setInvalidMessage(this,\'' + invalidMessage + '\')" ' +
            //        ' oninput="this.setCustomValidity(\'\')" ' +
            ' class="' + inputClass + '" ' +
            ' id="' + attrs.endDateName + '" ' +
            ' name="' + attrs.endDateName + '" ' +
            ' value="{{' + attrs.endDateModel + '}}" ' +
            ' data-ng-model="' + attrs.endDateModel + '" ' +
            ' placeholder="' + attrs.endDatePlaceholder + '"' +
            required +
            datePicker +
            '>' + inputElementEnd;
          }
        }

        var inputWidth = attrs.inputWidth;
        var labelWidth = 4;
        var inputName = attrs.inputName;
        var ngModel = attrs.ngModel;
        var secondColumn = '';
        setPostCode();
        function setPostCode() {
          if (attrs.hasOwnProperty('postcode')) {
            inputName = attrs.postcodeInputName;
            ngModel = attrs.postcodeModel;
            inputWidth = 3;
            var cityWidth = 5;
            var cityPlaceholder = attrs.cityPlaceholder;
            secondColumn = '<div class="col-sm-' + cityWidth + '">' +
              '<' + inputElementStart + ' class="' + inputClass + '" id="' +
              attrs.cityInputName + '" name="' + attrs.cityInputName +
              '" data-ng-model="' + attrs.cityModel + '" placeholder="' +
              cityPlaceholder + '"' + required + datePicker + '>' +
              inputElementEnd + '</div>';
          }
        }

        type = attrs.hasOwnProperty('type') ? attrs.type : 'text';

        var autofocus = attrs.hasOwnProperty('autofocus') ? 'autofocus' : '';

        pattern = attrs.hasOwnProperty('pattern') ? ' pattern="' + attrs.pattern + '" ' : '';

        var htmlText =
          '<div class="' + rowClass + '"  data-ng-class="{ \'has-error\': form[\'' +
            inputName + '\'].$dirty \&\& form[\'' + inputName + '\'].$invalid, \'valid\': form[\'' +
            inputName + '\'].$valid }">' +
          '  <label ' +
          '    class="col-sm-' + labelWidth + ' control-label" ' +
          '    for="' + inputName + '">' +
          requiredTag + attrs.label +
          '  </label>' +
          '  <div class="col-sm-' + inputWidth + '">' +
          '    <' + inputElementStart +
          '      type="' + type + '" ' + pattern +
          '      class="' + inputClass + '" ' +
          '      id="' + inputName + '" ' +
          '      name="' + inputName + '" ' +
          '      data-ng-model="' + ngModel + '" ' + ngModelOptions +
          '      placeholder="' + placeholder + '"' +
          required + autofocus +
          datePicker + '>' + inputElementEnd + secondInput +
          '  </div>' +
          secondColumn +
          '</div>';
        element.replaceWith(htmlText);
      },
    };
  });

}

function setInvalidMessage(node, message) {
  node.setCustomValidity('');
  if (!node.validity.valid) {
    node.setCustomValidity(message);
  }
}

function focusElement(elemId) {
  setTimeout(function() {
    $('#' + elemId).focus();
  }, 0);
}

window.concatArrays = function (arrays) {
  var newArray = [];
  angular.forEach(arrays, function(array) {
    angular.forEach(array, function(item) {
      newArray.push(item);
    });
  });
  return newArray;
};

window.regexEscape = function (s) {
  return s.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
};

if (typeof app !== typeof undefined) {

  app.directive('tmFiles', function() {
    return {
      restrict: 'E',
      compile(element, attrs) {

        var required = '';
        var requiredTag = '';
        if (attrs.hasOwnProperty('required')) {
          required = ' required=\'required\' ';
          requiredTag = '<tm-required></tm-required>';
        }

        var rowClass = 'row form-group';
        rowClass += attrs.hasOwnProperty('divider') ? ' form-group-divider ' : '';

        var inputWidth = attrs.inputWidth;
        var labelWidth = 4;

        var limitDropzoneArea = '';
        if (attrs.hasOwnProperty('limitArea')) {
          limitDropzoneArea = ' data-limit-dropzone-area="true" ';
        }
        var htmlText = '<tm-docs-dropzone data-file-upload="options" data-upload-url="' +
            attrs.url + '" ' + limitDropzoneArea + '></tm-docs-dropzone>';
        element.replaceWith(htmlText);
      },
    };
  });

  app.directive('tmLoading', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        cssClass: '=',
        i18ncode: '@i18ncode',
      },
      template: `<p class="loading mb-0 {{cssClass}}">
                <span class="fas fa-spinner-third fa-spin"></span>
                <i18n-simple code="{{i18ncode}}"></i18n-simple>
                </p>`,
    };
  });

  app.directive('validFile', function () {
    return {
      require: 'ngModel',
      link (scope, el, attrs, ngModel) {
        ngModel.$render = function () {
          ngModel.$setViewValue(el.val());
        };

        el.bind('change', function () {
          scope.$apply(function () {
            ngModel.$render();
          });
        });
      },
    };
  });

  app.directive('watchChange', function() {
    return {
      restrict: 'A',
      link(scope, element, attrs) {
        element.on('input', function() {
          scope[attrs.watchChange](this);
        });
      },
    };
  });

  // Removes the element after milliseconds given as value in alert-timeout="value"
  app.directive('alertTimeout', function() {
    return {
      restrict: 'A',
      link(scope, element, attrs, ngModel) {
        element.delay(attrs.alertTimeout).fadeTo(500, 0).slideUp(500, function() {
          $(this).remove();
        });
      },
    };
  });

  app.directive('nickOptions', ['$parse', function($parse) {
    return {
      restrict: 'A',
      require: '?ngModel',
      link(scope, element, attrs, ngModel) {

        var nickModel = $parse(attrs.nick);

        scope.$watch(attrs.ngModel, function() {

          var nameArr = element[0].value.split(' ');
          scope[attrs.nickOptions] = [];
          angular.forEach(nameArr, function(val) {
            if (val !== '' && scope[attrs.nickOptions].indexOf(val) === -1) {
              scope[attrs.nickOptions].push(val);
            }
          });

          if (scope[attrs.nickOptions].length === 1) { // only one option for a nick
            nickModel.assign(scope, scope[attrs.nickOptions][0]);
          } else if (scope[attrs.nickOptions].indexOf($parse(attrs.nick)(scope)) === -1) {
            // nick not found in possible options
            nickModel.assign(scope, scope[attrs.nickOptions][0]);
          }
        });

      },
    };
  }]);

  app.directive('clockPicker', function() {
    return {
      restrict: 'A',
      require: '?ngModel',
      link(scope, element, attrs, ngModel) {
        if (!ngModel) {
          return;
        }

        element.clockpicker({
          placement: attrs.placement || 'top',
          align: 'left',
          autoclose: true,
          afterDone() {
            scope.$apply();
          },
        });
      },
    };
  });

}

function prepareForm (form, scope) {
  form.submitClicked = true;
  window.setAllInputsDirty(scope);
}
