/* eslint-disable max-len */
export default {
  'accessPermit.approval.changesRequested': 'Запрошены исправления',
  'accessPermit.approved': 'Одобрено',
  'accessPermit.expertise.missingRequiredExpertise': 'Недостатки',
  'accessPermit.expertise.newRequirement': 'Новое требование',
  'accessPermit.pending': 'Ожидает',
  'accessPermit.revoked': 'Аннулировано',
  'accessPermit.shortenedBy': 'Срок действия допуска на объект сокращен в соответствии с действием квалификации {0}',
  'accessPermit.verification': 'Проверка права на работу',
  'anonymousreport.anonymousReports': 'Анонимные сообщения',
  'anonymousreport.anonymousReportsShort': 'Анонимный',
  'anonymousreport.change': 'Изменение',
  'anonymousreport.confirmReport': 'Подтвердите отчет',
  'anonymousreport.geolocationFailed': 'Не может получить ваше местоположение. Убедитесь, что вы разрешили обмен вашего местоположения.',
  'anonymousreport.gettingLocation': 'Получение вашего местоположения...',
  'anonymousreport.locateAgain': 'Снова найти ',
  'anonymousreport.located': 'Место нашли',
  'anonymousreport.lookingForSites': 'Глядя на соседних сайтах...',
  'anonymousreport.noNearbySites': 'Не найдено мест',
  'anonymousreport.refresh': 'Обновить',
  'anonymousreport.removeReport': 'Удалить tapahtuma',
  'anonymousreport.reportSent': 'Отчет отправлен!',
  'anonymousreport.selectObservationType': 'Выберите тип наблюдения',
  'anonymousreport.showMoreSites': 'Показать больше объектов',
  'anonymousreport.turnOnGeolocationInstructions': 'Как я могу включить службы геолокации на своем устройстве?',
  'connectionError': 'Ошибка соединения',
  'contactInfo.confirmEmail': 'Введите код, отправленный на ваш адрес электронной почты',
  'contactInfo.confirmInfo': 'Проверка контактных данных',
  'contactInfo.confirmPhone': 'Номер правильный',
  'contactInfo.editEmail': 'Измените адрес электронной почты',
  'contactInfo.editorModal': 'Диалоговое окно редактирования контактных данных',
  'contactInfo.editPhone': 'Измените номер телефона',
  'contactInfo.emailVerified': 'Адрес электронной почты подтвержден',
  'contactInfo.enterCodeFromYourEmail': 'Введите код, отправленный на ваш адрес электронной почты',
  'contactInfo.enterCodeFromYourPhone': 'Введите код, отправленный СМС-сообщением',
  'contactInfo.isAddressCorrect': 'Это ваш адрес?',
  'contactInfo.isAddressStillCorrect': 'Это все еще ваш адрес?',
  'contactInfo.isNumberCorrect': 'Это ваш номер?',
  'contactInfo.isNumberStillCorrect': 'Это все еще ваш номер?',
  'contactInfo.iWillTakeCareOfThisLater': 'Я сделаю это позже',
  'contactInfo.phoneVerified': 'Номер телефона подтвержден',
  'contactInfo.verificationModal': 'Диалоговое окно подтверждения контактных данных',
  'contactInfo.verifyEmail': 'Подтвердите адрес электронной почты',
  'contactInfo.verifyPhone': 'Подтвердите номер телефона',
  'contactInfo.wrongCodeTryAgain': 'Неправильный код. Попробуйте еще раз.',
  'contactInfoStatusBadge.verified': 'Подтверждено',
  'employeecheckin.areas.checkIn': 'Поставить отметку о входе на территорию',
  'employeecheckin.loadWorkTimeReports': 'Показать суммарное количество часов, отработанных на всех объектах',
  'employeecheckin.noAccessPermits': 'У вас нет ни одного допуска на объект',
  'employeeMenu.accessPermits': 'Допуски на объекты',
  'employeeMenu.checkInsHistory': 'История отметок',
  'employeeMenu.competences': 'Действующие квалификации',
  'expertise.pendingTraining': 'Обучение, которое следует завершить',
  'expertise.pendingTrainings': 'Обучения, которые следует завершить',
  'footer.call': 'Позвонить',
  'footer.or': 'или',
  'footer.privacyPolicy': 'Политика конфиденциальности',
  'footer.support': 'Поддержка:',
  'invoiceHours.employee.summary': 'Отчёт о часах работы сотрудника',
  'label.a1CertificateExpired': 'Срок действия свидетельства А1 истек',
  'label.accessPermitRejected': 'Отказано',
  'label.accessPermitValid': 'Допуск на объект действует',
  'label.addCommentToCheckIn': 'Добавить комментарий к входной отметке',
  'label.addCommentToCheckOut': 'Добавить комментарий к выходной отметке ',
  'label.allRequiredFieldsValid': 'Вся обязательная информация указана',
  'label.area': 'Территория объекта',
  'label.atTime': 'Время:',
  'label.begins': 'Начало',
  'label.cancel': 'Отменить',
  'label.checkInAlreadyExists': 'У Вас уже стоит отметка о входе',
  'label.checkOutForgottenHeading': 'Отметка о выходе не поставлена',
  'label.chooseLanguage': 'выберите язык',
  'label.closeWithoutSaving': 'Закрыть без сохранения',
  'label.comment': 'Комментарий',
  'label.commentOrReimbursment': 'Комментарий / компенсация расходов',
  'label.company': 'Предприятие',
  'label.competences': 'Квалификации',
  'label.dashboard': 'Главная страница',
  'label.default': 'По умолчанию',
  'label.edit': 'Редактировать',
  'label.employeeInfo': 'Личные данные',
  'label.ended': 'Завершено',
  'label.endTask': 'Завершить <strong>{0}</strong>',
  'label.enterSickLeaveReason': 'Укажите причину отпуска по болезни',
  'label.enterUsernameFirst': 'Сначала введите имя пользователя',
  'label.geolocationFailed': 'Определить местонахождение не удалось. К отметкам будет добавлено примечание.',
  'label.hide': 'Скрыть',
  'label.in': 'Вход',
  'label.introduction': 'Ознакомление',
  'label.invoiceHours': 'Привязка рабочего времени',
  'label.lastCheckOut': 'Предыдущая отметка о выходе',
  'label.lastWeek': 'Прошлая неделя',
  'label.logout': 'Выйти',
  'label.mobile.home': 'Главная страница',
  'label.no': 'Нет',
  'label.notes': 'Дополнительные сведения',
  'label.out': 'Выход',
  'label.outsideSite': 'Вы находитесь за пределами территории объекта. К вашей отметке будет добавлено примечание.',
  'label.password': 'Пароль',
  'label.photo': 'Фото',
  'label.ready': 'Готово!',
  'label.required': 'Обязательные сведения',
  'label.residencePermitExpired': 'Срок действия разрешения на пребывание истек',
  'label.safety': 'Безопасность',
  'label.save': 'Сохранить',
  'label.saving': 'Сохранение…',
  'label.selectEndDate': 'Выберите дату окончания',
  'label.selectPhoto': 'Выберите фотографию',
  'label.selectSite': 'Выберите сайт',
  'label.sending': 'Отправка…',
  'label.sendPasswordInSMS': 'Отправить пароль',
  'label.show': 'Показать',
  'label.startTask': 'Начать <strong>{0}</strong>',
  'label.thisWeek': 'Текущая неделя',
  'label.travelExpenseClaims': 'Компенсации дорожных расходов',
  'label.usernameShort': 'Имя пользователя',
  'label.visitor': 'Гость',
  'label.workHourBalance': 'Накопление баланса',
  'label.workingHoursCombined': 'Суммарное количество часов, отработанных на всех объектах',
  'label.workingTime': 'Рабочее время',
  'label.yes': 'Да',
  'login.enterAccountAndNumber': 'Введите свое имя пользователя.',
  'login.incorrectRequest': 'Неверный запрос!',
  'login.infoMessage': 'Мы отправим новый пароль на ваш номер мобильного телефона',
  'login.login': 'Войти',
  'login.newPasswordSentToTelephone': 'Ваш новый пароль отправлен на номер телефона ',
  'login.ohNoIe': '<p>Вы используете браузер <strong>Internet Explorer</strong>, который больше не поддерживается Zeroni. Поддерживаемыми браузерами являются <a href=\'https://www.google.com/chrome/\'>Chrome</a>, <a href=\'https://www.getfirefox.com/\'>Firefox</a>, <a href=\'https://www.opera.com/\'>Opera</a>, <a href=\'https://www.microsoft.com/fi-fi/windows/microsoft-edge/\'>Edge</a> и <a href=\'https://www.apple.com/safari/\'>Safari</a>.</p>',
  'login.passwordForgotten': 'Забыли пароль?',
  'login.requestNewPassword': 'Запросить новый пароль',
  'login.returnToLogin': 'Вернуться на страницу входа',
  'login.timeout': 'Вход в систему прерван: прошло слишком много времени!',
  'login.userHasSsoUsername': '<p>Ваша компания использует отдельную службу входа в систему, <strong>Azure AD</strong>.</p><p>Войдите в Zeroni, используя вашу службу AD.</p>',
  'login.wrongPassword': 'Неверный адрес электронной почты или пароль',
  'login.wrongVerificationCode': 'Код подтверждения недействителен!',
  'mainHelp.call': 'Позвонить',
  'mainHelp.email': 'Отправить сообщение',
  'mainHelp.help.desc': 'Советы, инструкции, часто задаваемые вопросы.',
  'mainHelp.help.heading': 'Инструкции по использованию',
  'mainHelp.privacyPolicy': 'Заявление о защите данных',
  'mainHelp.resources': 'Ресурсы',
  'mainHelp.support': 'Поддержка',
  'mainHelp.telephone.hours': 'по будням 8.00–16.00',
  'mainHelp.telephone.price': '0,69 €/мин',
  'message.addOtherNotesViaCheckInList': 'Добавьте другие комментарии и сведения о компенсации расходов, просматривая <a href=\'{0}\'>историю отметок</a>',
  'message.checkoutForgottenBody': '<p>Вы поставили отметку о входе на объект <strong>{1}</strong> в <strong>{0}</strong>, но отметки о выходе еще нет.</p><p>Пожалуйста, укажите время, когда вы покинули объект.</p>',
  'message.firstLoginNoPassword': '<p><strong>Здравствуйте, {0}!</strong></p><p>У вас еще нет пароля для входа в Zeroni. Отправьте его себе СМС-сообщением.</p>',
  'message.passwordSent': '<strong>Пароль отправлен на ваш номер мобильного телефона.</strong> Теперь вы можете войти в систему.',
  'message.selectEndDate': '<strong>{{vm.task.text}}</strong> может длиться несколько дней. Выберите дату завершения.',
  'message.sessionExpired': '<strong>Вы бездействовали слишком долго</strong> и поэтому ваш сеанс входа был завершен. Пожалуйста, войдите заново.',
  'message.sickLeaveReason': 'Пожалуйста, укажите причину вашего отпуска по болезни.',
  'message.unknownUsername': '<strong>Zeroni не распознает введенное имя пользователя.</strong> Пожалуйста, проверьте его.',
  'messaging.send': 'Отправить',
  'monitoring.positiveReport': 'Позитивный отчет',
  'more': 'Добавить',
  'password.changePassword': 'Изменить пароль',
  'password.changing': 'Смена пароля…',
  'password.invalidPasswordMessage': '<strong>Неверный пароль</strong>: Проверьте старый пароль и попытайтесь еще раз.',
  'password.newPassword': 'Новый пароль',
  'password.oldPassword': 'Старый пароль',
  'quality.defect': 'Дефект качества',
  'quality.quality': 'Качество',
  'reportersName': 'Имя заявителя',
  'safety.description': 'Описание',
  'safety.environmentalHazard': 'Опасность для окружающей среды ',
  'safety.hazard': 'Наблюдение',
  'safety.location': 'Расположение',
  'topMenu.loginAsCompany': 'Войти как предприятие',
  'topMenu.loginAsEmployee': 'Войти как работник',
  'user.session': 'Сеанс',
  'userEditor.onlyUserCanChange': 'Редактировать может только само лицо',
  'userSettings.changePassword': 'Изменить пароль',
  'userSettings.defaultCompanyAndSite': 'Предприятие и объект по умолчанию',
  'userSettings.defaultSite': 'Объект работ по умолчанию',
  'userSettings': 'Пользовательские настройки',
  'validator.errors.emailFormat': 'Неверный адрес электронной почты',
  'validator.errors.emailNotUnique': 'Этим адресом уже пользуется кто-то другой',
  'validator.errors.phoneFormat': 'Неверный номер телефона',
  'validator.errors.phoneNotUnique': 'Этим номером уже пользуется кто-то другой',
  'validator.errors.required': 'Обязательные сведения',
  'validator.valueNotChanged': 'Значение не изменилось',
};
