(function () {
  'use strict';

  angular
    .module('blocks.i18n')
    .service('messagesOverrideService', messagesOverrideService);

  /* @ngInject */
  function messagesOverrideService(currentSiteService) {

    this.applyOverride = applyOverride;

    const overrides = [
      {
        original: 'safety.section',
        override: 'safety.class',
        condition: sectionRenamedToClass,
      },
      {
        original: 'safety.sections',
        override: 'safety.classes',
        condition: sectionRenamedToClass,
      },
    ];

    function applyOverride(stringName) {
      const override = overrides.find(o => o.original === stringName);
      return override && override.condition() ? override.override : stringName;
    }

    function sectionRenamedToClass() {
      return currentSiteService.site?.safetySectionRenamedToClass;
    }

  }
})();