(function() {
  'use strict';

  angular
    .module('blocks.i18n')
    .component('i18nSimple', {
      template: '',
      bindings: {
        code: '@',
        text: '@?',
        var: '@?',
        arguments: '<?',
      },
      controller: i18nSimpleWithArguments,
    });

  i18nSimpleWithArguments.$inject = ['i18nSimpleService', '$compile', '$element', '$scope'];

  function i18nSimpleWithArguments(i18nSimpleService, $compile, $element, $scope) {
    const vm = this;

    this.$onInit = () => {
      updateMessage();
    };

    this.$onChanges = () => {
      updateMessage();
    };

    function updateMessage() {
      if (vm.var && vm.code) {
        $scope.$parent[vm.var] = i18nSimpleService.get(vm.code, vm.arguments);
      } else {
        $element.html($compile('<span>' + i18nSimpleService.get(vm.code, vm.arguments) + '</span>')($scope));
      }
    }
  }

})();
