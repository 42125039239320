import angular from 'angular';
import issueMonitoringService from '../../inc/js/react/services/issueMonitoringService';

angular
  .module('blocks.issueMonitoring')
  .factory('issueMonitoringService', importedIssueMonitoringService);

function importedIssueMonitoringService() {
  return issueMonitoringService;
}
