import messagesEt from './messages-et';

(function() {
  'use strict';

  angular
    .module('blocks.i18n')
    .service('messagesEtService', messagesEtService);

  function messagesEtService() {
    this.messages = messagesEt;
  }

})();
