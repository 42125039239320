import classnames from 'classnames';
import { isPlainObject } from 'lodash';

const createMarginClass = (mlOrOptions, mr, mx) => {
  if (isPlainObject(mlOrOptions)) {
    return createMarginClassInternal(mlOrOptions.ml, mlOrOptions.mr, mlOrOptions.mx);
  }
  return createMarginClassInternal(mlOrOptions, mr, mx);
};

const createMarginClassInternal = (ml, mr, mx) => {
  return classnames({
    [`mx-${mx}`]: _.isNumber(mx),
    [`mr-${mr}`]: _.isNumber(mr),
    [`ml-${ml}`]: _.isNumber(ml),
    'mx-1': mx && _.isBoolean(mx),
    'mr-1': mr && _.isBoolean(mr),
    'ml-1': ml && _.isBoolean(ml),
  }).trim() || null;
};

export default createMarginClass;