window.version = $.now();
window.VERSION = window.version;

angular.module('constants', []);

angular
  .module('constants')
  .constant('VERSION', window.VERSION);

const idpUrls = {
  'DEVELOPMENT': 'http://localhost:8083',
  'TEST': 'https://idp.dev.public.cloud.zeroni.fi',
  'STAGING': 'https://idp.stg.public.cloud.zeroni.fi',
  'PRODUCTION': 'https://idp.prd.public.cloud.zeroni.fi',
};
const idpClientIds = {
  'DEVELOPMENT': '796cb6d6-a7c4-4c11-9642-8faef57b266f',
  'TEST': 'b61b60c0-1fc9-45c8-96f5-9bcd961e453d',
  'STAGING': '7d2e7cf8-b11f-47b2-9d5b-4536378e2bb7',
  'PRODUCTION': 'ba0847a5-f793-4a2d-ab48-bf376fe72a8d',
};

const TEN_MINUTES = 1000 * 60 * 10;
window.LANGUAGE_CODES_FOR_EMPLOYEE = ['de', 'et', 'en', 'fi', 'pl', 'ru', 'sk', 'sv'];
window.LANGUAGE_CODES_FOR_ADMIN = ['en', 'fi', 'sv'];


window.CONSTANTS = {
  IDP_URL: window.getEnvironment && idpUrls[window.getEnvironment()] || 'https://idp.prd.public.cloud.zeroni.fi',
  IDP_CLIENT_ID: window.getEnvironment && idpClientIds[window.getEnvironment()] || 'ba0847a5-f793-4a2d-ab48-bf376fe72a8d',
  FIN_ADDRESS_POSTCODE_MAXLENGTH: 5,
  FIN_ADDRESS_CITY_MAXLENGTH: 30,
  FIN_ADDRESS_STREET_MAXLENGTH: 100,
  APPROX_HEIGHT_OF_STICKY_HEADERS: 150, // px
  CACHE_AGE: TEN_MINUTES,
  COLLAPSE_DURATION: 350, // millisecs
  CONTACT_INFO_WRAPPER_CLASS: 'd-block d-xl-flex flex-wrap',
  DEFAULT_ALERT_DURATION: 10000, // millisecs
  DEFAULT_CARD_CLASS: 'card bg-light border-0 shadow pull-sides-true pull-sides-md-false mb-3',
  DEFAULT_LIST_GROUP_CLASS: 'list-group list-group-flush shadow pull-sides-true pull-sides-md-false',
  DEFAULT_LOADING_ICON: 'far fa-spinner-third fa-spin',
  DEFAULT_DEBOUNCE: 1000, // millisecs
  DEFAULT_SEARCH_DELAY: 1000, // millisecs
  DEFAULT_SEARCH_FILTER_BADGE_CLASS: 'badge badge-secondary',
  DEFAULT_MIN_QUERY_LENGTH: 3,
  DEFAULT_SEARCH_PAGE_SIZE: 150,
  DELAY_BEFORE_SCROLL: 500, // millisecs
  ERROR_HANDLER_SENDS_EMAIL: true,
  EXPAT_REP_STREET_REQUIRED_TIMESTAMP: new Date('2023-01-31 17:00:00').getTime(),
  FADEOUT_DURATION: 400, // millisecs
  INVOICE_HOURS_OVERTIME_REASON_THRESHOLD: 0.5, // hours
  KEEPALIVE_INTERVAL_ONLINE: 60 * 60, // as seconds, i.e. 1 hour
  LANGUAGE_CODES_FOR_ADMIN: window.LANGUAGE_CODES_FOR_ADMIN,
  LANGUAGE_CODES_FOR_EMPLOYEE: window.LANGUAGE_CODES_FOR_EMPLOYEE,
  FORM_INFO_TEXT_CLASS: 'small form-text text-muted',
  FORM_INFO_DANGER_TEXT_CLASS: 'small form-text text-danger',
  POSTED_WORKER_RULES_2024_START_TIMESTAMP: new Date('2024-01-17 17:00:00').getTime(),

  // DataURLs for blue-map-marker.svg and red-map-marker.svg
  MAP_MARKER_BLUE: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE2IDRDMTQuODE4MSAzLjk5OTk5IDEzLjY0NzggNC4yMzI3NyAxMi41NTU4IDQuNjg1MDZDMTEuNDYzOSA1LjEzNzM1IDEwLjQ3MTcgNS44MDAyOCA5LjYzNjAxIDYuNjM2MDFDOC44MDAyOCA3LjQ3MTc0IDguMTM3MzUgOC40NjM5IDcuNjg1MDYgOS41NTU4M0M3LjIzMjc3IDEwLjY0NzggNi45OTk5OSAxMS44MTgxIDcgMTNDNyAxOSAxMy43NTgzIDI2LjA3NzYgMTUuMTYxNiAyNy42MzEzQzE1LjI2ODEgMjcuNzQ3NSAxNS4zOTc3IDI3Ljg0MDMgMTUuNTQyIDI3LjkwMzhDMTUuNjg2NCAyNy45NjcyIDE1Ljg0MjMgMjggMTYgMjhDMTYuMTU3NyAyOCAxNi4zMTM2IDI3Ljk2NzIgMTYuNDU4IDI3LjkwMzhDMTYuNjAyMyAyNy44NDAzIDE2LjczMTkgMjcuNzQ3NSAxNi44Mzg0IDI3LjYzMTNDMTguMjQxNyAyNi4wNzc2IDI1IDE5IDI1IDEzQzI1IDExLjgxODEgMjQuNzY3MiAxMC42NDc4IDI0LjMxNDkgOS41NTU4M0MyMy44NjI3IDguNDYzOSAyMy4xOTk3IDcuNDcxNzQgMjIuMzY0IDYuNjM2MDFDMjEuNTI4MyA1LjgwMDI4IDIwLjUzNjEgNS4xMzczNSAxOS40NDQyIDQuNjg1MDZDMTguMzUyMiA0LjIzMjc3IDE3LjE4MTkgMy45OTk5OSAxNiA0VjRaTTE2IDE4QzE1LjAxMTEgMTggMTQuMDQ0NCAxNy43MDY4IDEzLjIyMjEgMTcuMTU3M0MxMi4zOTk5IDE2LjYwNzkgMTEuNzU5IDE1LjgyNyAxMS4zODA2IDE0LjkxMzRDMTEuMDAyMiAxMy45OTk4IDEwLjkwMzEgMTIuOTk0NSAxMS4wOTYxIDEyLjAyNDVDMTEuMjg5IDExLjA1NDYgMTEuNzY1MiAxMC4xNjM3IDEyLjQ2NDUgOS40NjQ0N0MxMy4xNjM3IDguNzY1MiAxNC4wNTQ2IDguMjg5IDE1LjAyNDUgOC4wOTYwN0MxNS45OTQ1IDcuOTAzMTUgMTYuOTk5OCA4LjAwMjE2IDE3LjkxMzQgOC4zODA2QzE4LjgyNyA4Ljc1OTA0IDE5LjYwNzkgOS4zOTk5IDIwLjE1NzMgMTAuMjIyMUMyMC43MDY4IDExLjA0NDQgMjEgMTIuMDExMSAyMSAxM0MyMSAxNC4zMjYxIDIwLjQ3MzIgMTUuNTk3OCAxOS41MzU1IDE2LjUzNTVDMTguNTk3OCAxNy40NzMyIDE3LjMyNjEgMTggMTYgMThWMThaIiBmaWxsPSIjNDZiOGRhIi8+Cjwvc3ZnPgo=', // eslint-disable-line max-len
  MAP_MARKER_RED: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE2IDRDMTQuODE4MSAzLjk5OTk5IDEzLjY0NzggNC4yMzI3NyAxMi41NTU4IDQuNjg1MDZDMTEuNDYzOSA1LjEzNzM1IDEwLjQ3MTcgNS44MDAyOCA5LjYzNjAxIDYuNjM2MDFDOC44MDAyOCA3LjQ3MTc0IDguMTM3MzUgOC40NjM5IDcuNjg1MDYgOS41NTU4M0M3LjIzMjc3IDEwLjY0NzggNi45OTk5OSAxMS44MTgxIDcgMTNDNyAxOSAxMy43NTgzIDI2LjA3NzYgMTUuMTYxNiAyNy42MzEzQzE1LjI2ODEgMjcuNzQ3NSAxNS4zOTc3IDI3Ljg0MDMgMTUuNTQyIDI3LjkwMzhDMTUuNjg2NCAyNy45NjcyIDE1Ljg0MjMgMjggMTYgMjhDMTYuMTU3NyAyOCAxNi4zMTM2IDI3Ljk2NzIgMTYuNDU4IDI3LjkwMzhDMTYuNjAyMyAyNy44NDAzIDE2LjczMTkgMjcuNzQ3NSAxNi44Mzg0IDI3LjYzMTNDMTguMjQxNyAyNi4wNzc2IDI1IDE5IDI1IDEzQzI1IDExLjgxODEgMjQuNzY3MiAxMC42NDc4IDI0LjMxNDkgOS41NTU4M0MyMy44NjI3IDguNDYzOSAyMy4xOTk3IDcuNDcxNzQgMjIuMzY0IDYuNjM2MDFDMjEuNTI4MyA1LjgwMDI4IDIwLjUzNjEgNS4xMzczNSAxOS40NDQyIDQuNjg1MDZDMTguMzUyMiA0LjIzMjc3IDE3LjE4MTkgMy45OTk5OSAxNiA0VjRaTTE2IDE4QzE1LjAxMTEgMTggMTQuMDQ0NCAxNy43MDY4IDEzLjIyMjEgMTcuMTU3M0MxMi4zOTk5IDE2LjYwNzkgMTEuNzU5IDE1LjgyNyAxMS4zODA2IDE0LjkxMzRDMTEuMDAyMiAxMy45OTk4IDEwLjkwMzEgMTIuOTk0NSAxMS4wOTYxIDEyLjAyNDVDMTEuMjg5IDExLjA1NDYgMTEuNzY1MiAxMC4xNjM3IDEyLjQ2NDUgOS40NjQ0N0MxMy4xNjM3IDguNzY1MiAxNC4wNTQ2IDguMjg5IDE1LjAyNDUgOC4wOTYwN0MxNS45OTQ1IDcuOTAzMTUgMTYuOTk5OCA4LjAwMjE2IDE3LjkxMzQgOC4zODA2QzE4LjgyNyA4Ljc1OTA0IDE5LjYwNzkgOS4zOTk5IDIwLjE1NzMgMTAuMjIyMUMyMC43MDY4IDExLjA0NDQgMjEgMTIuMDExMSAyMSAxM0MyMSAxNC4zMjYxIDIwLjQ3MzIgMTUuNTk3OCAxOS41MzU1IDE2LjUzNTVDMTguNTk3OCAxNy40NzMyIDE3LjMyNjEgMTggMTYgMThWMThaIiBmaWxsPSIjYzkzMDJjIi8+Cjwvc3ZnPgo=', // eslint-disable-line max-len

  MAX_JOB_NUMBERS_IN_SELECTIZE: 500,
  MAX_PERSONS_IN_SELECTIZE: 500,
  MAX_OPTIONS_WITHOUT_TOP_SELECTIONS: 7,
  MAX_PAGE_SIZE: 2000, // results on one query page from backend
  MAX_PHOTO_WIDTH: 800, // pixels
  MODAL_CANCELLED: 0,
  MODAL_SUBMITTED: 1,
  PRH_TIMEOUT: 5000, // millisecs
  SYSTEM: window.SYSTEM,
  SM_APPEARANCE: window.SM_APPEARANCE,
  DOCMANAGER_URL: 'documents',
  TIME_TO_INDICATE_FAILED_SUBMISSION: 5000, // millisecs
  TIME_TO_INDICATE_SUCCESSFUL_SUBMISSION: 1000, // millisecs
};

window.CONSTANTS.DEFAULT_MODEL_OPTIONS = {
  'updateOn': 'default blur',
  'debounce': {
    'default': window.CONSTANTS.DEFAULT_DEBOUNCE,
    'blur': 0,
  },
};


angular
  .module('constants')
  .constant('CONSTANTS', window.CONSTANTS);

angular.forEach(window.CONSTANTS, function (value, key) {
  angular
    .module('constants')
    .constant(key, value);
});

const CONSTANTS = window.CONSTANTS;
export { CONSTANTS };
