import messagesRu from './messages-ru';

(function() {
  'use strict';

  angular
    .module('blocks.i18n')
    .service('messagesRuService', messagesRuService);

  function messagesRuService() {
    this.messages = messagesRu;
  }

})();
