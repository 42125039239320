import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

const Body = (props) => {
  return (
    <Modal.Body
      as={props.as}
      bsPrefix={props.bsPrefix}
      className={props.className}
      data-testid="body"
    >
      {props.children}
    </Modal.Body>
  );
};

Body.propTypes = {
  children: PropTypes.node.isRequired,
  as: PropTypes.string,
  bsPrefix: PropTypes.string,
  className: PropTypes.string,
};

export default Body;