import { getClient } from '../../../helpers/Sentry';
import { get } from 'lodash';
import { getService } from 'react-in-angularjs';

const setMetaData = (key, metaData) => {
  const Sentry = getClient();
  if (!key || !metaData) {
    return;
  }
  Sentry.setExtra(key, JSON.stringify(metaData));
};

const shouldReport = (level) => {
  return level === 'error' && window.getEnvironment() === 'PRODUCTION';
};

const captureEvent = (message, level, options) => {

  if (!shouldReport(level)) {
    console.log('Not reporting error to SENTRY:', message, level, options); // eslint-disable-line no-console
    return;
  }

  const Sentry = getClient();

  let parsedMessage = message;
  if (message && message.message) {
    parsedMessage = message.message;
  }

  Sentry.captureEvent({
    message: parsedMessage,
    level,
    extra: {
      context: get(getService('$state'), ['current', 'name']),
      errorClass: message,
      ...options,
    },
  });
};

const error = (message, options) => captureEvent(message, 'error', options);
const warning = (message, options) => captureEvent(message, 'warning', options);
const info = (message, options) => captureEvent(message, 'info', options);

const issueMonitoringService = {
  error,
  warning,
  info,
  setMetaData,
};

export default issueMonitoringService;
