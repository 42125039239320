import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import classnames from 'classnames';

import I18n from 'ReactComponents/I18n';

const Footer = (props) => {
  const className = classnames(props.className, {
    'justify-content-start': props.align === 'left',
    'justify-content-center': props.align === 'center',
    'justify-content-end': props.align === 'right',
  });
  const cancelButtonOnTheLeft = props.cancelButton && (!props.align || props.align !== 'left');
  const cancelButtonOnTheRight = props.cancelButton && props.align === 'left';

  return (
    <Modal.Footer
      as={props.as}
      bsPrefix={props.bsPrefix}
      className={className || null}
      data-testid="footer"
    >
      {cancelButtonOnTheLeft && <CancelButton text={props.cancelText} onCancel={props.onCancel} />}
      {props.children}
      {cancelButtonOnTheRight && <CancelButton text={props.cancelText} onCancel={props.onCancel} />}
    </Modal.Footer>
  );
};

Footer.defaultProps = {
  cancelButton: true,
};

Footer.propTypes = {
  as: PropTypes.string,
  bsPrefix: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  align: PropTypes.string,
  cancelButton: PropTypes.bool,
  cancelText: PropTypes.string,
  onCancel: PropTypes.func,
};


const CancelButton = (props) => {
  return (
    <Button
      variant={props.variant || 'clear'}
      onClick={props.onCancel}
      data-testid="cancel-button"
    >
      <I18n code={props.text || 'label.cancel'} />
    </Button>
  );
};

CancelButton.propTypes = {
  onCancel: PropTypes.func.isRequired,
  text: PropTypes.string,
  variant: PropTypes.string,
};


export default Footer;