import React from 'react';
import PropTypes from 'prop-types';
import { Modal as BsModal } from 'react-bootstrap';
import { omit } from 'lodash';

const Modal = (props) => {
  return (
    <BsModal
      {...omit(props, ['isOpen', 'children'])}
      show={props.isOpen}
      onHide={props.onClose || props.onCancel}
      data-testid="modal"
    >
      {props.children}
    </BsModal>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
  centered: PropTypes.bool,
  bsPrefix: PropTypes.string,
  size: PropTypes.string,
};

export default Modal;