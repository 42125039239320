import enMessages from './messages-en';

(function() {
  'use strict';

  angular
    .module('blocks.i18n')
    .service('messagesEnService', messagesEnService);

  function messagesEnService() {
    this.messages = enMessages;
  }

})();
