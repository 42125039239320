import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import createMarginClass from 'ReactHelpers/create-margin-class';

const TextNode = ({ as, children, value, block, className, variant, uppercase, lowercase, testId, ...margin }) => {
  const wrapperClassName = classnames(className, createMarginClass(margin), {
    'd-block': block,
    [`text-${variant}`]: variant,
    'text-uppercase': uppercase,
    'text-lowercase': lowercase,
  }) || null;

  const WrapperComponent = as || 'span';

  return (
    <WrapperComponent className={wrapperClassName} data-testid={testId || 'text-node'}>
      {children && children}
      {value && value}
    </WrapperComponent>
  );
};

TextNode.propTypes = {
  as: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  children: PropTypes.node,
  value: PropTypes.string,
  block: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.string,
  mr: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  ml: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  uppercase: PropTypes.bool,
  lowercase: PropTypes.bool,
  testId: PropTypes.string,
};

export default TextNode;