/* eslint-disable max-len */
export default {
  'a1Certificate': 'Todistus sosiaaliturvasta (esim. A1)',
  'accessPermit.addAndProceedToOrientation': 'Lisää ja jatka perehdytyksen vahvistukseen',
  'accessPermit.addMissingCompetence': 'Lisää puuttuva pätevyys',
  'accessPermit.addMissingCompetences': 'Lisää puuttuvat pätevyydet',
  'accessPermit.addMissingScanOfCertificate': 'Lisää puuttuva kuva todistuksesta',
  'accessPermit.addRequiredCompetencesFirst': 'Lisää ensin puuttuvat pätevyydet.',
  'accessPermit.admittance.department.valid': 'Yksikkökohtainen kulkulupa voimassa: {0} - {1}',
  'accessPermit.admittance.site.invalid': 'Työntekijällä ei ole kulkulupaa työmaalle.',
  'accessPermit.admittance.site.valid': 'Työmaakohtainen kulkulupa voimassa: {0} - {1}',
  'accessPermit.admittanceInfo': 'Kulkulupa määräytyy työntekijän työmaalupien ja pakollisten pätevyyksien voimassaoloaikojen perusteella.',
  'accessPermit.apprix.courseInfo.addEmail': 'Lisää henkilölle sähköpostiosoite liittääksesi hänet Apprixiin.',
  'accessPermit.apprix.courseInfo.fail': 'Liittäminen epäonnistui',
  'accessPermit.apprix.courseInfo.info': 'Työmaalla on käytössä Apprix-koulutusjärjestelmä. Voit linkittää työntekijän uudelleen Apprixiin alla olevasta painikkeesta. Työntekijä saa sähköpostiinsa linkin koulutusjärjestelmään.',
  'accessPermit.apprix.courseInfo.relink': 'Liitä uudelleen',
  'accessPermit.apprix.courseInfo.success': 'Liittäminen onnistui!',
  'accessPermit.approval.allRequestedChangedDone': 'Kaikki pyydetyt korjaukset tehty',
  'accessPermit.approval.approve': 'Hyväksy työntekijä',
  'accessPermit.approval.approved': 'Hyväksytty {0}',
  'accessPermit.approval.changesAreStillBeingMade': 'Työmaalupaan tehdään vielä korjauksia',
  'accessPermit.approval.changesRequested': 'Pyydetty korjauksia',
  'accessPermit.approval.changesRequestedBy': 'Korjauspyynnön tehnyt: {0}',
  'accessPermit.approval.checkAccessPermitLabels': 'Tarkista työmaaluvan merkinnät',
  'accessPermit.approval.confirm': 'Hyväksytäänkö henkilön <strong>{0}</strong> työmaalupa <strong>{1}</strong>?',
  'accessPermit.approval.decision': 'Päätös',
  'accessPermit.approval.heading': 'Työnteko-oikeuden tarkastus',
  'accessPermit.approval.headingForSummary': 'Työnteko-oikeus',
  'accessPermit.approval.notPossible': 'Työmaalupaa ei voi hyväksyä.',
  'accessPermit.approval.missingEmail': 'Henkilöltä puuttuu sähköpostiosoite.',
  'accessPermit.approval.missingPhone': 'Henkilöltä puuttuu puhelinnumero.',
  'accessPermit.approval.missingPhoneEmail': 'Henkilöltä puuttuu puhelinnumero sekä sähköpostiosoite.',
  'accessPermit.approval.periodType.max': 'Pisin mahdollinen kesto',
  'accessPermit.approval.removeLabels': 'Poista merkinnät',
  'accessPermit.approval.requestChanges': 'Pyydä korjauksia',
  'accessPermit.approval.requestedChangesAlert': 'Työnteko-oikeuden tarkastuksessa havaittiin puutteita, joita pyydettiin korjattaviksi.',
  'accessPermit.approval.requestedChangesStatus': 'Korjauspyyntöjen tilanne',
  'accessPermit.approval.requirements.expat.residencePermitDurationMinusOne': 'Päivän maastapoistumisaika huomioitu. Dokumentin virallinen päiväys on {0}.',
  'accessPermit.approval.requirementsLoadError': 'Vaatimusten lataaminen ei onnistunut.',
  'accessPermit.approval.retainLabels': 'Säilytä merkinnät',
  'accessPermit.approval.sendChangeRequest': 'Merkitse korjausta odottavaksi',
  'accessPermit.approval.updateLabels': 'Päivitä merkinnät',
  'accessPermit.approval.validationPeriod': 'Työmaaluvan voimassaolo',
  'accessPermit.approved': 'Hyväksytty',
  'accessPermit.approvedInfo': 'Työmaalupa on hyväksytty, joten sitä ei voi muokata.',
  'accessPermit.cannotBeRemoved': 'Työmaalupaa ei voi poistaa',
  'accessPermit.changesInWorkHourBalance': 'Saldomuutokset',
  'accessPermit.checkContactInfo': 'Vahvista alla olevat henkilötietojen merkinnät.',
  'accessPermit.confirmRemoval': 'Poistetaanko työmaalupa <strong>{0}</strong> henkilöltä <strong>{1}</strong>?',
  'accessPermit.contactInfoChecked': 'Kyllä, yhteystiedot ovat kunnossa',
  'accessPermit.created': 'Lisätty',
  'accessPermit.createdAsVisitorInfo': '<strong>Vierailijalupa.</strong> Työmaalla on aiemmin käytetty vierailijalupia, jolloin tämä työmaalupa on luotu.',
  'accessPermit.createdWithVehicleInfo': '<strong>Ajolupa.</strong> Työmaalla on käytetty ajoneuvolupia silloin kun tämä työmaalupa on luotu. Voit poistaa ajoneuvoluvan jos haluat.',
  'accessPermit.createFromUnresolvedCheckIn.areYouSure': 'Haluatko varmasti luoda uuden työmaaluvan? Voit myös kohdistaa leimauksen henkilön olemassaolevaan työmaalupaan klikkaamalla \'Kuittaa\' linkkiä.',
  'accessPermit.createFromUnresolvedCheckIn.personHasAlreadyPermitsAtSite': 'Henkilöltä löytyy jo seuraavat työmaaluvat työmaalta',
  'accessPermit.earlierA1Certificate': 'Aiempi todistus on voimassa <strong>{0}</strong> asti.',
  'accessPermit.earlierExpatRep': 'Aiemmin edustajana on toiminut <strong>{0}</strong>.',
  'accessPermit.earlierExpatRepListStart': 'Aiemmin edustajana on toiminut',
  'accessPermit.earlierIdConfirmation': 'Edellisellä kerralla käytetty henkilöllisyystodistus ({0})',
  'accessPermit.earlierSiteCard': 'Edellisellä kerralla käytetty henkilötunnistekortti ({0})',
  'accessPermit.employer': 'Palkanmaksaja',
  'accessPermit.expatInfo': 'Lähetetyllä työntekijällä tarkoitetaan yleensä työntekijää, joka täyttää seuraavat ehdot:<ul><li>Työnantaja ei ole suomalainen.</li><li>Työntekijä työskentelee tavallisesti muualla kuin Suomessa.</li><li>Työntekijä on lähetetty väliaikaisesti Suomeen.</li></ul><a target="_blank"href="https://www.tyosuojelu.fi/tyosuhde/lahetetty-tyontekija">Lue lisää <span class="far fa-external-link"></span></a>',
  'accessPermit.expatInfoMessage': 'Henkilö on merkitty lähetetyksi työntekijäksi, koska pääasiallinen työskentelyvaltio ei ole Suomi. Lähetetyltä työntekijältä vaaditaan todistus sosiaaliturvasta. <a href="https://tyosuojelu.fi/tyosuhde/lahetetty-tyontekija" rel="noopener" target="_blank">Lisätietoja <span class="far fa-external-link"></span></a> (työsuojelu.fi). Lisäksi henkilö on ilmoitettava AVI:lle: <a href="https://asiointipalvelu.ahtp.fi/forms/2627047" rel="noopener" target="_blank">Ilmoitus työntekijöiden lähettämisestä <span class="far fa-external-link"></span></a> (asiointipalvelu.ahtp.fi).',
  'accessPermit.expatPersonnelReport': 'Ilmoitus lähetettyjen työntekijöiden käytöstä',
  'accessPermit.expatPersonnelReportDate': 'Ilmoituksen päivämäärä',
  'accessPermit.expatUncheckModal.cancel': 'Ei',
  'accessPermit.expatUncheckModal.confirm': 'Kyllä, vaihda pääasiallinen työskentelyvaltio',
  'accessPermit.expatUncheckModal.content': '<p>Henkilö on merkitty lähetetyksi työntekijäksi, koska pääasialliseksi työskentelyvaltioksi on merkitty muu valtio kuin {0}. Pääasiallinen työskentelyvaltio on valtio, jossa henkilö työskentelee yli 6 kk vuodesta.</p><p>Onko henkilön pääasiallinen työskentelyvaltio {1} ja tallennetaanko tämä henkilön tietoihin?</p>',
  'accessPermit.expatUncheckModal.error': 'Virhe: Työntekijän tietojen päivittäminen ei onnistunut.',
  'accessPermit.expatUncheckModal.heading': 'Pääasiallinen työskentelyvaltio',
  'accessPermit.expatUncheckModal.saving': 'Vaihdetaan pääasiallista työskentelyvaltiota...',
  'accessPermit.expertise.contextInfo': 'Työntekijä saa työmaaluvan luonnin jälkeen tekstiviestin, jossa on lisätietoa koulutuksen suorittamisesta.',
  'accessPermit.expertise.missingExpertise': 'Työntekijältä puuttuu työmaalla vaadittavaa osaamista, tai se on kuukauden sisällä vanhentumassa.',
  'accessPermit.expertise.missingExpertisePlural': 'Työntekijältä puuttuu työmaalla vaadittavia osaamisia, tai ne ovat kuukauden sisällä vanhentumassa.',
  'accessPermit.expertise.missingOptionalExpertise': 'Työntekijältä puuttuu valinnainen koulutus. Tämä ei estä kulkuluvan muodostumista.',
  'accessPermit.expertise.missingOptionalExpertisePlural': 'Työntekijältä puuttuu valinnaisia koulutuksia. Tämä ei estä kulkuluvan muodostumista.',
  'accessPermit.expertise.missingRequiredExpertise': 'Puutteita',
  'accessPermit.expertise.missingScanOfCompetenceCertificate': 'Työntekijältä puuttuu kuva pätevyyden todistuksesta.',
  'accessPermit.expertise.missingScanOfCompetenceCertificatePlural': 'Työntekijältä puuttuu kuva alla olevien pätevyyksien todistuksista.',
  'accessPermit.expertise.newRequirement': 'Uusi vaatimus',
  'accessPermit.expertise.performedInExternalSystem': 'Työntekijä suorittaa koulutuksen erillisessä järjestelmässä.',
  'accessPermit.expertise.validityBasedOnCertificateScanInfoPlural': 'Työmaalupa tallennetaan, mutta se astuu voimaan vasta kun kuvat todistuksista on liitetty pätevyyksiin.',
  'accessPermit.expertise.validityBasedOnCertificateScanInfoSingular': 'Työmaalupa tallennetaan, mutta se astuu voimaan vasta kun kuva todistuksesta on liitetty pätevyyteen.',
  'accessPermit.expertise.validityInfoPlural': 'Työmaalupa tallennetaan, mutta se astuu voimaan vasta kun voimassaolevat osaamiset on lisätty.',
  'accessPermit.expertise.validityInfoSingular': 'Työmaalupa tallennetaan, mutta se astuu voimaan vasta kun voimassaoleva osaaminen on lisätty.',
  'accessPermit.foreignEmployeeRepresentative': 'Ulkomaalaisen tai ulkomailla asuvan työntekijän edustaja Suomessa',
  'accessPermit.foreignEmployeeRepresentativeMany': 'Ulkomaalaisten tai ulkomailla asuvien työntekijöiden edustaja Suomessa',
  'accessPermit.foremanNeedsContactInfo': '<strong>Henkilö toimii työnjohtajana</strong> ja työnjohtajalta vaaditaan ajantasaiset yhteystiedot.',
  'accessPermit.fortyhours': '40 tuntia',
  'accessPermit.hasDataCannotRemove': 'Työmaaluvalla on leimauksia tai tuntilappumerkintöjä, joten sitä ei voi poistaa. Luvan päättäminen sen sijaan on mahdollista.',
  'accessPermit.idCardPhotoAdded': 'Lisätty',
  'accessPermit.idCardPhotoNotAdded': 'Ei lisätty',
  'accessPermit.labels.infoText': 'Näkyvät kaikille. Muokkausoikeus vain tilaajalla ja pääurakoitsijalla.',
  'accessPermit.labels.noLabels': 'Ei merkintöjä',
  'accessPermit.labels': 'Merkinnät',
  'accessPermit.latestCheckIn': 'Viimeisin leimaus',
  'accessPermit.locked': 'Lukittu',
  'accessPermit.maxPeriodExceeded': 'Päättymispäivä on asetettu pidemmälle kuin mitä vaatimukset sallivat.',
  'accessPermit.maxPeriodFromRequirements': 'Vaatimukset täyttyy ajalla',
  'accessPermit.maxPeriodFromRequirementsChanged': 'Puutteita vaatimuksissa. Vaatimukset ovat todennäköisesti muuttuneet luvan hyväksynnän jälkeen.',
  'accessPermit.maxPeriodFromRequirementsSet': 'Asetettu voimassaolo vastaa pisintä aikaa, jonka sopimus ja mahdolliset vaatimukset sallivat.',
  'accessPermit.maxPeriodNotAvailable': 'Vaatimusten mukaista voimassaoloaikaa ei voitu laskea. Luultavasti syynä on puuttuva vaatimus.',
  'accessPermit.newCompetenceRequirement': 'Uusi pätevyysvaatimus',
  'accessPermit.newWorkHourBalance': 'Uusi saldo',
  'accessPermit.noIdentifierNoVisitorOption2': '<strong>Henkilöllä ei ole tunnistetta, kuten veronumeroa, eikä työmaalla sallita vierailijalupia.</strong> Lisää henkilölle tunniste luodaksesi työmaaluvan. Vaihtoehtoisesti pääurakoitsija voi sallia vierailijaluvat työmaan asetuksista ja luoda vierailijalupia.',
  'accessPermit.noIdentifiers': '<strong>Henkilöllä ei ole tunnisteita, kuten veronumeroa</strong>, joten luvasta muodostuu <em>vierailijalupa</em>. Lisää henkilölle tunniste luodaksesi <em>työntekijälle</em> työmaalupa.',
  'accessPermit.noIdentifiersNoVisitorOption1': '<strong>Henkilöllä ei ole tunnisteita, kuten veronumeroa</strong>, joten voisit luoda vain vierailijaluvan, mutta sinulla ei ole oikeutta luoda vierailijalupia tälle työmaalle. Lisää henkilölle tunniste tai pyydä pääurakoitsijaa lisäämään vierailijalupa.',
  'accessPermit.noPreviousWorkHourBalanceUpdates': 'Ei aiempia saldomuutoksia',
  'accessPermit.noWorkHourBalance': 'Ei saldokertymää',
  'accessPermit.orientationAfterCheckIn': 'Perehdytyksen vahvistus tehty ensimmäisen leimauksen jälkeen.',
  'accessPermit.orientationAfterCheckInDetails': 'Henkilön ensimmäinen leimaus on tehty <strong>{0}</strong>, mutta perehdytyksen vahvistus on merkitty suoritetuksi vasta <strong>{1}</strong>. Perehdytyksen vahvistus tulisi olla suoritettu ennen työskentelyä työmaalla.',
  'accessPermit.overlapInfo': 'Voit luoda uuden luvan, mutta leimauslaitteella tehdyt leimaukset kopioituvat jokaiselle luvalle. Jos taas työntekijä tekee leimauksia omalla päätelaitteellaan, hän joutuu valitsemaan luvan, johon leimaus tehdään.',
  'accessPermit.overlapNotPossible': 'Henkilöllä ei voi olla kahta yhtäaikaista lupaa samalle sopimukselle.',
  'accessPermit.pending': 'Odottaa',
  'accessPermit.personMissingExpertise': 'Työmaalupa astuu voimaan ja perehdytys voidaan vahvistaa vasta kun kaikki puuttuvat osaamiset lisätty.',
  'accessPermit.personMissingExpertiseCertificates': 'Joistakin pätevyyksistä/koulutuksista puuttuu vaadittavat kuvat todistuksista.',
  'accessPermit.protacon.beginCourse': 'Aloita koulutuksen suoritus uudessa ikkunassa',
  'accessPermit.protacon.course': 'Koulutuksen suorittaminen',
  'accessPermit.protacon.courseInfo.addEmail': 'Lisää henkilölle sähköpostiosoite liittääksesi hänet Typeen.',
  'accessPermit.protacon.courseInfo.fail': 'Liittäminen epäonnistui',
  'accessPermit.protacon.courseInfo.linkSuccessful': 'Työntekijä on liitetty kaikille työmaaluvan edellyttämille Protacon Type -kursseille.',
  'accessPermit.protacon.courseInfo.missingCourses': 'Työntekijää ei ole liitetty kaikille työmaaluvan edellyttämille <strong>Protacon Type</strong> -kursseille.',
  'accessPermit.protacon.courseInfo.notSuccessful': 'Onko <strong>{0}</strong> varmasti työntekijän henkilökohtainen sähköpostiosoite? Jos sähköpostiosoite on oikein, voit linkittää työntekijän alla olevasta painikkeesta.',
  'accessPermit.protacon.courseInfo.relink': 'Liitä uudelleen',
  'accessPermit.protacon.linkFetchFail': 'Haku epäonnistui. Yritä myöhemmin uudelleen',
  'accessPermit.protacon.linkValidUntil': 'Linkki on voimassa {0} asti',
  'accessPermit.protacon.siteUsesProtacon': 'Työmaalla käytetään <strong>Protacon Type</strong> -verkkokoulutuksia. Jotta työntekijä voi suorittaa koulutukset, hän tarvitsee sähköpostiosoitteen.',
  'accessPermit.reInviteForTrainings': 'Lähetä koulutuskutsu uudelleen',
  'accessPermit.removeAccessPermit': 'Poista työmaalupa',
  'accessPermit.removeHeading': 'Poista työmaalupa?',
  'accessPermit.removePreventedByCheckins': 'Työmaalupaa ei pysty poistamaan koska siihen liittyy leimauksia.',
  'accessPermit.removePreventedByInvoiceHours': 'Työmaalupaa ei voi poistaa, koska sille on merkitty hyväksyttyjä tuntilappurivejä. Jos haluat silti poistaa työmaaluvan, ota yhteyttä Zeroni -tukeen (support@zeroni.fi).',
  'accessPermit.removePreventedHeading': 'Työmaaluvan poisto ei onnistu',
  'accessPermit.requirements': 'Vaatimukset',
  'accessPermit.requirementsFulfilledDuringPeriod': 'Vaatimukset täyttyvät {0}.',
  'accessPermit.requirementsFulfilledDuringValidity': 'Vaatimukset täyttyvät koko työmaaluvan voimassaolon ajan',
  'accessPermit.revoked': 'Mitätöity',
  'accessPermit.roles': 'Roolit työmaalla',
  'accessPermit.searchOrSelectLaborAgreement': 'Työehtosopimus',
  'accessPermit.setOrientationToFirstCheckIn': 'Vaihda perehdytyksen vahvistuspäiväksi ensimmäisen leimauksen päivä {0}',
  'accessPermit.shortenedBy': 'Lyhennetty pätevyyden {0} mukaisesti',
  'accessPermit.site.change': 'Vaihda',
  'accessPermit.siteCardPhotoAdded': 'Lisätty',
  'accessPermit.siteCardPhotoNotAdded': 'Ei lisätty',
  'accessPermit.thirtysevenandhalfhours': '37.5 tuntia',
  'accessPermit.useContractTimePeriod': 'Aseta työmaaluvan voimassaolo sopimuksen mukaan ({0})',
  'accessPermit.useTimePeriod': 'Aseta työmaaluvan voimassaolo aikavälin ({0}) mukaan',
  'accessPermit.validationPeriodIsSetByApprover': 'Työmaaluvan voimassaolo vahvistetaan hyväksymisen yhteydessä.',
  'accessPermit.vehicleNeedsContactInfo': '<strong>Ajoluvan yhteydessä</strong> henkilöltä vaaditaan ajantasaiset yhteystiedot.',
  'accessPermit.verification': 'Työnteko-oikeuden tarkastus',
  'accessPermit.waitingForIntroduction.fsm': 'Odottaa työmaakohtaista vahvistusta perehdytyksestä',
  'accessPermit.waitingForIntroduction': 'Odottaa vahvistusta perehdytyksestä',
  'accessPermit.whyNotVisible': 'Miksi ei näy?',
  'accessPermit.whyNotVisibleHelpText': 'Sinulla ei ole oikeutta nähdä/muokata tätä tietoa. Mikäli tarvitset oikeudet, ota yhteyttä lähimpään pääkäyttäjään.',
  'accessPermit.withdrawApproval.confirm': 'Perutaanko henkilön <strong>{0}</strong> työnteko-oikeuden tarkastus työmaaluvalle <strong>{1}</strong>? Muutoksen jälkeen tarkastus pitää tehdä uudestaan.',
  'accessPermit.withdrawApproval.heading': 'Perutaanko työnteko-oikeuden tarkastus?',
  'accessPermit.workHourBalance.startDate': 'Voimassa alkaen',
  'accessPermit.workHourBalance.updateAuthor': 'Kirjaaja',
  'accessPermit.workHourBalance': 'Saldo',
  'accessPermit.workHoursPerWeek': 'Työtunteja viikossa',
  'actions': 'Toiminnot',
  'activation.checkIns.failed': 'Leimausten latauksessa tapahtui virhe.',
  'activation.failed': 'Tiedon latauksessa tapahtui virhe.',
  'activation.retry': 'Yritä uudestaan',
  'activeLayouts': 'Käytössä olevat pohjapiirustukset',
  'addRequiredInformationBeforeSign': 'Täytä vaaditut tiedot ennen allekirjoittamista',
  'admin.devicesImeiOrCompany': 'Laitteen IMEI tai yritys',
  'alert.andWatchIntroductionVideo': 'ja katso uusi <a href={0} target=\'_blank\'>esittelyvideo.</a>',
  'alert.readMore': 'Lue lisää versiohistoriasta',
  'alert.released': 'on julkaistu.',
  'alert.siteManagerVersion': 'Zeronin versio',
  'allLayouts': 'Kaikki pohjapiirustukset',
  'anonymousreport.anonymousReport': 'Vahvistamaton havainto',
  'anonymousreport.anonymousReports': 'Vahvistamattomat havainnot',
  'anonymousreport.anonymousReportsShort': 'Vahvistamattomat',
  'anonymousreport.blackmarket': 'Harmaa talous',
  'anonymousreport.change': 'Vaihda',
  'anonymousreport.confirmReport': 'Vahvista tapahtuma',
  'anonymousreport.feedback': 'Vapaa palaute',
  'anonymousreport.geolocationFailed': 'Paikannus epäonnistui. Varmista, että olet sallinut sijaintisi jakamisen.',
  'anonymousreport.gettingLocation': 'Paikannetaan...',
  'anonymousreport.locateAgain': 'Paikanna uudestaan',
  'anonymousreport.located': 'Paikannettu',
  'anonymousreport.lookingForSites': 'Haetaan lähistön työmaita...',
  'anonymousreport.noNearbySites': 'Työmaita ei löytynyt',
  'anonymousreport.refresh': 'Päivitä',
  'anonymousreport.removeReport': 'Poista tapahtuma',
  'anonymousreport.reportSent': 'Havainto lähetetty!',
  'anonymousreport.selectObservationType': 'Valitse havainnon tyyppi',
  'anonymousreport.showMoreSites': 'Näytä lisää työmaita',
  'anonymousreport.turnOnGeolocationInstructions': 'Kuinka kytken paikannuksen päälle laitteestani?',
  'app.newVersionAvailable.refreshCallToAction': 'Päivitä nyt',
  'app.newVersionAvailable.title': 'Zeronin päivitys saatavilla.',
  'assetManagement': 'Kalustonhallinta',
  'attachment.showAttachment': 'Näytä liite',
  'attachment.showAttachmentNo': 'Näytä liite {0}',
  'beamer.showFeed': 'Näytä tiedotteet',
  'beta.activate': 'Ota käyttöön',
  'beta.addPersonsIntro': 'Teimme työmaaluvan lisäyksestä sujuvampaa. Testaa uutta työkalua nyt!',
  'beta.openBeta': 'Avaa uusi työkalu',
  'beta.returnToOld': 'Palaa vanhaan näkymään',
  'beta.useUpdatedInterface': 'Kokeile päivitettyä henkilön lisäystä',
  'beta.youCanReturn': 'Voit halutessasi palata takaisin vanhaan näkymään.',
  'beta': 'Beta',
  'checkIn.approve': 'Hyväksy',
  'checkIn.approved': 'Hyväksytty',
  'checkIn.autoGeneratedTooltip': 'Leimaus on automaattisesti luotu',
  'checkIn.cannotResolve.info': 'Huom. joitakin leimauksia ei pysty kohdentamaan, koska leimauksen ajankohtana kulkutunnisteen haltijalla ei ole ollut voimassaolevaa työmaalupaa.',
  'checkIn.checkInCannotResolve': 'Leimausta ei ole mahdollista kohdentaa mihinkään työmaalupaan.',
  'checkIn.checkInDelete': 'Poistetaanko leimaus?',
  'checkIn.checkInDeleteMessage': 'Olet poistamassa leimausta <strong>{0}: <span class="far fa-sign-in"></span> {1} <span class="far fa-sign-out"></span> {2}</strong>, henkilöltä <strong>{3}</strong>. Haluatko varmasti poistaa leimauksen?',
  'checkIn.checkInType.all': 'Kaikki leimaukset',
  'checkIn.checkInType.gate': 'Muut leimaukset',
  'checkIn.checkInType.workHour': 'Tuntileimaukset',
  'checkIn.contractAndSubcontractDigest': 'Sopimuksen ja sen aliurakointisopimuksien yhteenlaskettu tuntierittely',
  'checkIn.contractDigest': 'Sopimuksen tuntierittely',
  'checkIn.devices.confirmRemoteOpening': 'Vahvista etäavaus',
  'checkIn.devices.missing': 'Puuttuuko laite listalta?',
  'checkIn.devices.missingHelp': 'Voit muokata ja lisätä uusia laitteita sivulla:',
  'checkIn.devices.opening': 'Avataan...',
  'checkIn.devices.remoteOpening': 'Etäavaus',
  'checkIn.devices.removeFromOtherSites': 'Poista laite muilta työmailta',
  'checkIn.employeeDigest': 'Työntekijän tuntierittely',
  'checkIn.handle': 'Kuittaa',
  'checkIn.handleCheckIn': 'Kuittaa leimaus',
  'checkIn.handleHelpText': 'Kuittaus joko merkitsee leimauksen poistetuksi tai yrittää kohdentaa sen olemassaolevaan työmaalupaan, jolloin leimauksesta ei myöskään tule enää varoitusta.',
  'checkIn.hideContractDigest': 'Piilota sopimuksen tuntierittely',
  'checkIn.hideEmployeeDigest': 'Piilota työntekijän tuntierittely',
  'checkIn.includeSubcontractors': 'Näytä myös aliurakoitsijat',
  'checkIn.lunchSubtracted': 'Lounas (30min) vähennetty',
  'checkIn.onlyIncomplete': 'Sisään- tai ulosleimaus puuttuu',
  'checkIn.printDigests': 'Tulosta tuntierittelyt',
  'checkIn.remove': 'Poista',
  'checkIn.removeConfirmation': 'Haluatko poistaa leimauksen?',
  'checkIn.removeOrResolveHelpText': 'Voit joko poistaa tai kohdistaa leimauksen olemassaolevaan työmaalupaan.',
  'checkIn.resolveSeveralCheckIns.headerText': 'Kulkutunnisteella on useampi leimaus',
  'checkIn.resolveSeveralCheckIns.info': 'Kulkutunnisteella <strong>{0}</strong> on tehty useampi leimaus, joita ei ole saatu kohdistettua. Alla voit valita yksittäisten leimausten osalta, haluatko kohdentaa vai poistaa leimauksen. Valinnan jälkeen klikkaa \'Käsittele\'.',
  'checkIn.resolveStraglerCheckInInfoExtra': 'Työntekijällä on työmaalla aktiivinen työmaalupa, johon leimauksen voi kohdistaa.',
  'checkIn.searchJobNumber': 'Työnumero',
  'checkIn.searchResults': 'Hakutulokset',
  'checkIn.selectJobNumber': 'Valitse työnumero',
  'checkIn.showContractDigest': 'Näytä sopimuksen tuntierittely',
  'checkIn.showDigestsAlways': 'Näytä tuntikoosteet aina',
  'checkIn.showEmployeeDigest': 'Näytä työntekijän tuntierittely',
  'checkIn.showOnlyApprovableByMe': 'Vain hyväksyntääni odottavat',
  'checkIn.showOnlyApproved': 'Vain hyväksytyt',
  'checkIn.showOnlyFirstAndLastCheckins': 'Näytä vain päivän ensimmäinen sisäänleimaus ja viimeinen ulosleimaus',
  'checkIn.stragler.connected': 'Leimaus kohdistettu työmaalupaan',
  'checkIn.stragler.removed': 'Leimaus poistettu',
  'checkIn.toExcel': 'Excel-raportti',
  'checkIn.toExcelDetailed': 'Excel-raportti (laajemmat tiedot)',
  'checkIn.tryToConnectToAccessPermit': 'Kohdenna',
  'checkIn.unallocated': 'Kohdistamattomat',
  'checkIn.warning.editAndDeleteDisabled': 'Muokkaus ja poisto estetty \'Näytä vain päivän esimmäinen sisäänleimaus ja viimeinen ulosleimaus\'-hakuehtoa käytettäessä.',
  'checkIn.warning.noPermissions': 'Leimausten muokkaus pois käytöstä. Lisätietoja: tilaaja/päätoteuttaja.',
  'checkIn.workHour': 'Tuntileimaus',
  'checkIns.searchResults': 'Hakutulokset',
  'claned.allOk': 'Työntekijä on liitetty kaikille työmaaluvan edellyttämille Claned-kursseille.',
  'claned.getSignInLink': 'Hae työntekijän kirjautumislinkki Clanediin tästä.',
  'claned.info': 'Työmaalla on käytössä Claned-koulutusjärjestelmä.',
  'claned.linkFail': 'Linkitys epäonnistui',
  'claned.missingCourses': 'Työntekijää ei ole liitetty kaikille työmaaluvan edellyttämille Claned-kursseille.',
  'claned.missingEmail': 'Lisää työntekijälle sähköpostiosoite liittääksesi hänet Clanediin.',
  'claned.reLink': 'Liitä uudelleen',
  'company.addCertificate': 'Lisää sertifikaatti',
  'company.addNewCertificate': 'Lisää uusi sertifikaatti',
  'company.businessIdUpdateConfirmation': 'Olet päivittämässä yrityksen Y-tunnusta. <b>Huomaathan, että Y-tunnusta saa päivittää käyttöliittymän kautta vain, jos Y-tunnus on yrityksen lisäysvaiheessa syötetty väärin.</b> Jos Y-tunnus on muuttunut yrityksen sulautumisen, jakautumisen tai jonkun muun käyttövirheestä johtumattoman syyn seurauksena tulee muutos tehdä tietokannan kautta. Tarkista yritystiedot ennen päivittämistä: <a href="https://tietopalvelu.ytj.fi/yritys/{0}" target="_blank">https://tietopalvelu.ytj.fi/yritys/{1}</a>',
  'company.businessIdUpdateConfirmationShort': 'Haluatko varmasti päivittää Y-tunnuksen?',
  'company.businessLine': '<strong>Toimiala:</strong>',
  'company.businessLines': '<strong>Toimialat:</strong>',
  'company.certificate.expirationDate': 'Voimassa {0} asti',
  'company.certificate.missing': 'Puuttuu',
  'company.certificate.validUntil': 'Voimassa {0} asti',
  'company.certificate': 'Sertifikaatti',
  'company.certificateAdded': 'Sertifikaatti <strong>{0}</strong> lisätty',
  'company.certificates': 'Sertifikaatit',
  'company.certificateUpdated': 'Sertifikaatti <strong>{0}</strong> päivitetty',
  'company.changesForAYear': 'Muutokset vuoden ajalta.',
  'company.companyChange': 'Yrityksellä on tapahtunut Y-tunnusmuutos {0}.',
  'company.companyContinuesAsMany': 'Yritys jatkaa toimintaansa mm. yrityksen {0} (Y-tunnus {1}) muodossa. Lisätietoja esimerkiksi <a target=\'_blank\' rel=\'noopener\' href="https://www.ytj.fi/"> YTJ:n tietojärjestelmästä.</a>',
  'company.companyContinuesAsOne': 'Yritys jatkaa toimintaansa yrityksen {0} (Y-tunnus {1}) muodossa. Lisätietoja esimerkiksi <a target=\'_blank\' rel=\'noopener\' href="https://www.ytj.fi/"> YTJ:n tietojärjestelmästä.</a>',
  'company.companyHasAcquired': '<strong>Yritykseen ovat sulautuneet:</strong>',
  'company.companyHasSplit': '<strong>Yritys on jakaantunut seuraaviksi yrityksiksi:</strong>',
  'company.companyHomeCountry': 'Yrityksen kotivaltio',
  'company.companyInLiquidation': 'Yritys on ollut {0} {1} lähtien.',
  'company.companyInLiquidationWithName': 'Yritys {0} on ollut {1} {2} lähtien.',
  'company.companyMergedTo_': '<strong>Yritys on fuusioitunut yritykseen: </strong>',
  'company.companyMergedToNoName': 'Yritys on toiminut Y-tunnuksella {0} (nimeä ei saatavilla) {1} lähtien.',
  'company.companyMergedToWithName': 'Yritys on fuusioitunut yritykseen {0} (Y-tunnus {1}) {2}.',
  'company.contractlessIntro': '<b>Yritys lisätään ilman sopimusta</b>. Merkitse yritykselle tarvittavat tiedot, jotta voit hyödyntää niitä kun kartoitat toimittajia.',
  'company.discontinued': 'Yritys on lopettanut toimintansa {0}.',
  'company.error.evaluationRemoved': 'Tapahtui virhe toimittaja-arvioinnin poistamisessa.',
  'company.error.evaluationSaving': 'Tapahtui virhe toimittaja-arvioinnin tallennuksessa.',
  'company.evaluate': 'Arviointi',
  'company.evaluationRemoved': 'Toimittaja-arviointi poistettu',
  'company.evaluationSaved': 'Toimittaja-arvointi tallennettu',
  'company.fixingIncorrectBusinessId': 'Korjaan virheellisen Y-tunnuksen',
  'company.generalInfo': 'Yleistä',
  'company.hasPaidWorkForce': 'Yrityksellä on palkattua työvoimaa',
  'company.jobTitleExamples': 'Esim. maanrakennus, sähkötyöt, purku',
  'company.managesItself': '<strong>{0}</strong> hallitsee omia tietojaan. Kerro muutostarpeet esimerkiksi yrityksen yhteyshenkilölle (ks. alla).',
  'company.materialExamples': 'Esim. Puu-, metalli-, kivitavara',
  'company.newCertificate': 'Uusi sertifikaatti',
  'company.noCertificates': 'Ei sertifikaatteja',
  'company.onlyWithActiveContracts': 'Aktiivisia sopimuksia',
  'company.paidWorkForce': 'Palkattu työvoima',
  'company.prhNameSuggestion': 'Käytä nimeä <strong>{0}</strong> (PRH:ssa Y-tunnusta vastaava nimi {1} alkaen).',
  'company.prhSource': 'Lähde: <a target="_blank" rel="noopener" href="http://avoindata.prh.fi/index.html">Patentti- ja rekisterihallitus (PRH).</a> Aineistoa muokattu. Lisenssi <a target="_blank" rel="noopener" href="https://creativecommons.org/licenses/by/4.0/">CC BY 4.0.</a>',
  'company.reliablePartner.foreignCompanyInfo': 'Vastuu Group ei tarjoa ulkomaisten yritysten Luotettava Kumppani -tilan automaattista tarkastusta. Voit tarkastaa tiedot käsin <a target="_blank" rel="noopener" href="https://www.vastuugroup.fi/">Vastuu Groupin</a> Yrityshaku-palvelusta.',
  'company.removedFromTradeRegister': 'Yritys {0} on poistunut kaupparekisteristä {1}.',
  'company.search.evaluation': 'Arviointi',
  'company.search.jobTitle': 'Työtehtävä',
  'company.search.jobTitles': 'Työtehtävät',
  'company.searchByBusinessIdOrName': 'Kirjoita y-tunnus tai yrityksen nimen alkuosa.',
  'company.searchCertificates': 'Hae sertifikaatti',
  'company.searchOrSelectCompanies': 'Yritykset',
  'company.searchOrSelectCompany': 'Yritys',
  'company.summary.activeContracts': 'Aktiiviset sopimukset',
  'company.summary.endedContracts': 'Päättyneet sopimukset',
  'company.updateCertificate': 'Päivitä sertifikaatti',
  'company.whyBusinessIdIsLocked': 'Yritystunnus on lukittu virhetilanteiden välttämiseksi. Jos Y-tunnus on vaihtunut, ottakaa yhteys Zeronin tukeen (support@zeroni.fi).',
  'company.whyCantIEdit': 'Miksi ei muokattavissa?',
  'company.withoutContract': 'Ilman sopimusta',
  'companyEditor.fusionInfo': 'Yritys on sulautunut <strong>{0}</strong> ja on nykyään osa yritystä {1} ({2}).',
  'companyEditor.hasNoAddress': 'Yritykseltä puuttuu osoite',
  'companyEditor.select': 'Valitse',
  'companyEditor.skip': 'Ei kiitos',
  'companyEditor.suggestAddressMessage': 'Haluatko valita jonkin seuraavista vaihtoehdoista?',
  'companyPersons.showTags': 'Näytä kulkutunnisteet',
  'competence.cardNumber': 'Kortin numero',
  'competence.code': 'Tunniste',
  'competence.confirmRemoval': 'Poistetaanko pätevyys <strong>{0}</strong> henkilöltä <strong>{1}</strong>?',
  'competence.correctInformation': 'Ei, korjaan vanhoja tietoja',
  'competence.createNew': 'Kyllä, olen lisäämässä uutta korttia',
  'competence.duration': 'Voimassa',
  'competence.durationPlaceHolder': 'Voimassaoloaika',
  'competence.expired': 'Päättynyt',
  'competence.months': 'kuukautta',
  'competence.monthsShort': 'kk',
  'competence.ongoing': 'Aloitettu',
  'competence.updateOrCreateNew': '<strong>Muutit pätevyyden avaintietoja</strong>. Nykyisen pätevyyden muutokset tehdään tähän, mutta uusi kortti lisätään uutena pätevyytenä. Oletko saanut uuden kortin (tms.) uusilla tiedoilla?',
  'competence.validUntil': 'Viimeinen voimassaolopäivä',
  'competences': 'Pätevyydet',
  'connectionError': 'Yhteysvirhe',
  'contactInfo.addressIsUsedMultipleTimesWarning': 'Tämä osoite on käytössä myös {0} muulla henkilöllä. Kun osoite vahvistetaan, muut eivät enää voi käyttää sitä.',
  'contactInfo.confirmEmail': 'Osoite on oikea',
  'contactInfo.confirmInfo': 'Yhteystietojen tarkistus',
  'contactInfo.confirmPhone': 'Numero on oikea',
  'contactInfo.editEmail': 'Muokkaa sähköpostia',
  'contactInfo.editorModal': 'Yhteystiedon muokkauksen dialogi-ikkuna',
  'contactInfo.editPhone': 'Muokkaa puhelinnumeroa',
  'contactInfo.emailVerified': 'Sähköposti vahvistettu',
  'contactInfo.enterCodeFromYourEmail': 'Syötä sähköpostiisi lähetetty koodi',
  'contactInfo.enterCodeFromYourPhone': 'Syötä tekstiviestillä lähetetty koodi',
  'contactInfo.helloSuperuser': 'Hei, sinä, superkäyttäjä!',
  'contactInfo.isAddressCorrect': 'Onko tämä osoitteesi?',
  'contactInfo.isAddressStillCorrect': 'Onko tämä edelleen osoitteesi?',
  'contactInfo.isNumberCorrect': 'Onko tämä numerosi?',
  'contactInfo.isNumberStillCorrect': 'Onko tämä edelleen numerosi?',
  'contactInfo.iWillTakeCareOfThisLater': 'Hoidan tämän myöhemmin',
  'contactInfo.notVerified': 'Ei vahvistettu',
  'contactInfo.numberIsUsedMultipleTimesWarning': 'Tämä numero on käytössä myös {0} muulla henkilöllä. Kun numero vahvistetaan, muut eivät enää voi käyttää sitä.',
  'contactInfo.phoneVerified': 'Puhelin vahvistettu',
  'contactInfo.superUserShouldKnowAboutEditingVerifiedData': 'Vahvistettujen yhteystietojen muokkaus poistaa vahvistuksen ja käyttäjän on vahvistettava yhteystieto uudestaan.',
  'contactInfo.superUserShouldKnowAboutLoggingIn': 'Yhteystietoja käytetään kirjautumiseen, joten väärän tiedon tallennus voi estää käyttäjää pääsemästä sisään.',
  'contactInfo.verificationModal': 'Yhteystiedon vahvistuksen dialogi-ikkuna',
  'contactInfo.verified': 'Vahvistettu',
  'contactInfo.verifyEmail': 'Vahvista sähköposti',
  'contactInfo.verifyPhone': 'Vahvista puhelinnumero',
  'contactInfo.wrongCodeTryAgain': 'Väärä koodi. Yritä uudestaan.',
  'contactInfoStatusBadge.verified': 'Vahvistettu',
  'continueToPersons': 'Jatka henkilöt-välilehdelle',
  'contract.accessAreas.specialAreasInfo': 'Tälle sopimukselle on määritelty kulkualueita, jotka eivät periydy ylätason sopimukselta. Alueita voi muokata vain työmaan päätoteuttaja.',
  'contract.activeMainContractor': 'Päätoteuttaja',
  'contract.addChosenPerson': 'Lisää henkilölle työmaalupa',
  'contract.addChosenPersons': 'Lisää {0} henkilölle työmaalupa',
  'contract.addHotWorkPermit': 'Lisää tulityölupa',
  'contract.addJobTitles': 'Lisää työtehtäviä',
  'contract.addNthContact': 'Lisää {0}. yhteyshenkilö',
  'contract.addPermitAndCheckIns': 'Lisää sopimukseen liittyvä työmaalupa ja leimaus.',
  'contract.addPersons.aboutToLeave': 'Olet poistumassa henkilöiden lisäyksestä.',
  'contract.addPersons.add.employees': 'Työntekijöitä',
  'contract.addPersons.add.visitors': 'Vierailijoita',
  'contract.addPersons.add': 'Lisää',
  'contract.addPersons.additionalDetails': 'Lisätiedot',
  'contract.addPersons.andNOthers': 'ja <strong>{0}</strong> muuta.',
  'contract.addPersons.approvalInfo': '<strong>Työmaalla on käytössä erillinen työmaalupien hyväksyntä.</strong> Hyväksynnästä lähetetään ilmoitus sopimuksessa mainitulle yhteyshenkilölle.',
  'contract.addPersons.areYouSureYouWantToDeselectPerson': 'Oletko varma, että haluat poistaa henkilön <b>{0}</b> valinnan?',
  'contract.addPersons.areYouSureYouWantToLeave': 'Haluatko varmasti poistua?',
  'contract.addPersons.checkAndSubmit.abbr': 'Lis.',
  'contract.addPersons.checkAndSubmit': 'Yhteenveto ja lisäys',
  'contract.addPersons.deselectPerson': 'Poista henkilö',
  'contract.addPersons.deselectPersonQuestion': 'Poistetaanko henkilö?',
  'contract.addPersons.differentEmployerOnValttiTag': 'Valtti-kortilla eri yritys.',
  'contract.addPersons.differentEmployerOnValttiTagAlert': 'Olet lisäämässä henkilöä työskentelemään yrityksessä <strong>{0}</strong>, mutta henkilön Valttikortti on myönnetty yritykselle <strong>{1}</strong>. Oletko valinnut oikean henkilön ja sopimuksen?',
  'contract.addPersons.differentEmployerOnValttiTagAlertSameName': 'Olet lisäämässä henkilöä työskentelemään yrityksessä {0} <strong>(Y-tunnus {1})</strong>, mutta henkilön Valttikortti on myönnetty yritykselle {2} <strong>(Y-tunnus {3})</strong>. Oletko valinnut oikean henkilön ja sopimuksen?',
  'contract.addPersons.employment': 'Työskentely',
  'contract.addPersons.existingOrientation': 'Henkilö on perehdytetty tälle työmaalle {0}.',
  'contract.addPersons.foreignerDetails': 'Ulkomaalaisen työntekijän tiedot',
  'contract.addPersons.foreignerRep.abbr': 'Edust.',
  'contract.addPersons.foreignerRepShort': 'Edustaja',
  'contract.addPersons.foremanIntro': '<p>Sopimukselle <b>{0}</b> ei ole valittu työnjohtajaa. Valinta on tärkeä osa turvallista ja tehokasta työskentelyä.</p><p>Tyypillisiä työnjohtajan tehtäviä:</p><ul><li>Työmaan toimintaan ja turvallisuuteen liittyvien tiedotteiden vastaanotto ja jakelu.</li><li>Vastuuhenkilönä toimiminen turvallisuuspoikkeamien korjauksessa.</li></ul>',
  'contract.addPersons.foremanNotAmongSelected': 'Työnjohtaja ei ole valittujen henkilöiden joukossa',
  'contract.addPersons.foremanSelectionUpdatesAllContracts': 'Työnjohtaja-valinta tallennetaan kaikille valituille sopimuksille.',
  'contract.addPersons.ids': 'Henkilöllisyystodistukset',
  'contract.addPersons.invalid': 'Tietoja puuttuu',
  'contract.addPersons.leaveQuestion': 'Poistutaanko?',
  'contract.addPersons.leavingDiscardsAllChanges': 'Jos poistut ennen tietojen lähettämistä, menetät henkilöiden valinnat ja syötetyt tiedot.',
  'contract.addPersons.manyPersonsWithMissingCompetences': '<strong>{0} henkilöltä</strong> puuttuu vaadittu pätevyys. Työmaaluvat tallennetaan nyt, mutta ne astuvat voimaan vasta kun henkilöillä on kaikki vaaditut pätevyydet.',
  'contract.addPersons.modal.title': 'Sopimukseen ei voi lisätä henkilöitä',
  'contract.addPersons.multiplePersons': 'Useampi henkilö',
  'contract.addPersons.nextPerson': 'Seuraava henkilö',
  'contract.addPersons.nextStep': 'Seuraava vaihe',
  'contract.addPersons.noSelections': 'Ei valittuja henkilöitä.',
  'contract.addPersons.onePerson': '1 henkilö',
  'contract.addPersons.orientationCopyNotPossible': 'Vahvistusta perehdytyksestä ei voi kopioida, koska pätevyysvaatimukset eivät täyty.',
  'contract.addPersons.orientationStillValid': 'Vahvistus perehdytyksestä on edelleen voimassa',
  'contract.addPersons.overlappingAccessPermits': 'Päällekkäisiä työmaalupia',
  'contract.addPersons.prohibited': 'Lisäys estetty',
  'contract.addPersons.relationAndValidationPeriod': 'Suhde ja kesto',
  'contract.addPersons.requiresNewOrientation': 'Vaaditaan uusi',
  'contract.addPersons.resultPerson': '<b>1</b> henkilö valittu',
  'contract.addPersons.resultPersons': '<b>{0}</b> henkilöä valittu',
  'contract.addPersons.selectAtOnce': 'Valitse kerralla',
  'contract.addPersons.selectedPersons': 'Valitut henkilöt',
  'contract.addPersons.selectFirstPersons': 'Valitse ensimmäiset henkilöt',
  'contract.addPersons.selectMorePersons': 'Valitse lisää henkilöitä',
  'contract.addPersons.selectNPersons': 'Valitse {0} henkilöä',
  'contract.addPersons.selectNPersonsShort': 'Valitse {0}',
  'contract.addPersons.selectPerson': 'Valitse henkilö',
  'contract.addPersons.selectPersons': 'Valitse henkilöitä',
  'contract.addPersons.selectPersonShort': 'Valitse',
  'contract.addPersons.sharedDetails.abbr': 'Yht.',
  'contract.addPersons.sharedDetails.short': 'Yhteiset tiedot',
  'contract.addPersons.sharedDetails': 'Yhteiset tiedot kaikille valituille henkilöille',
  'contract.addPersons.showOrientationForm': 'Näytä perehdytyksen vahvistustuslomake (PDF)',
  'contract.addPersons.singlePersonWithMissingCompetences': 'Henkilöltä <strong>{0}</strong> puuttuu vaadittu pätevyys. Työmaalupa tallennetaan nyt, mutta se astuu voimaan vasta kun henkilöllä on vaaditut pätevyydet.',
  'contract.addPersons.theseAccessPermitsMayAffectApproving': 'Nämä työmaaluvat saattavat vaikuttaa tämän työmaaluvan hyväksymiseen.',
  'contract.addPersons.tooltip.notAllowedToAddPersons.and': ' ja ',
  'contract.addPersons.tooltip.notAllowedToAddPersons.bSideInvalid': 'yrityksen tiedot on täytetty',
  'contract.addPersons.tooltip.notAllowedToAddPersons.liabilityDocs': 'tilaajavastuudokumentit ovat kunnossa',
  'contract.addPersons.tooltip.notAllowedToAddPersons': 'Voit lisätä henkilöitä sopimukselle vasta kun ',
  'contract.addPersons.useUniqueDetails': 'Aseta erikseen',
  'contract.addPersons.valid': 'Tiedot kunnossa',
  'contract.addPersons.valttiEmployerAlertDismiss': 'Kyllä, jatka henkilön lisäystä',
  'contract.addPersons.valttiEmployerAlertRemove': 'Ei, poista henkilö',
  'contract.addPersons.yesLeave': 'Kyllä, poistu',
  'contract.addPersons': 'Lisää henkilöitä',
  'contract.addPersonSearchPlaceholder': 'Hae henkilö (syötä nimi, veronro tai VALTTI)',
  'contract.addPersonsHelp.orAddNew': 'lisää uusi henkilö',
  'contract.addPersonsHelp': '<strong>Valitse yksi tai useampi henkilö.</strong> Valitse nykyisistä alta tai',
  'contract.addPersonsStrong': 'Lisää <strong>työntekijöitä</strong>',
  'contract.addPersonsToContract': 'Lisää henkilöitä sopimukseen',
  'contract.addSubContract': 'Lisää aliurakointisopimus',
  'contract.alreadyExists': 'Sopimus on jo olemassa',
  'contract.alreadyExistsAddedBySomebody': 'Urakoitsijalle <strong>{0}</strong> on jo lisätty sopimus.',
  'contract.approver': 'Hyväksyjä',
  'contract.askAboutPermitEndDateChange': 'Haluatko muuttaa työmaalupien päättymispäivää? Tämä päivittää työmaaluvat, <strong>joiden päättymispäivä on {0}</strong>. Uusi päättymispäivä on {1}.',
  'contract.askAboutPermitStartDateChange': 'Haluatko muuttaa työmaalupien alkamispäivää? Tämä päivittää työmaaluvat, <strong>joiden alkamispäivä on {0} ja joita ei ole vahvistettu</strong>. Uusi aloituspäivämäärä on {1}.',
  'contract.askAboutSubcontractEndDateChange': 'Muutetaanko tämän sopimuksen alla olevien aliurakkasopimusten päättymispäivää? Tämä vaihtoehto päivittää kaikki sopimukset, <strong>joiden päättymispäivä on {0}</strong>. Uusi päättymispäivä on {1}.',
  'contract.askAboutSubcontractStartDateChange': 'Muutetaanko tämän sopimuksen alla olevien aliurakkasopimusten alkamispäivää? Tämä vaihtoehto päivittää kaikki sopimukset, <strong>joiden alkamispäivä on {0}</strong>. Uusi aloituspäivämäärä on {1}.',
  'contract.automaticPeriodChange': 'Aliurakkasopimusten ja työmaalupien voimassaoloa muutetaan automaattisesti siten, että ne ovat voimassaolokauden {0}—{1} sisällä.',
  'contract.automaticPeriodChangeContractsOnly': 'Aliurakkasopimusten voimassaoloa muutetaan automaattisesti siten, että ne ovat voimassaolokauden {0}—{1} sisällä.',
  'contract.bSideInvalid.addingEmployeesPrevented': 'Voit lisätä henkilöitä sopimukselle vasta kun yrityksen tiedot on täytetty.',
  'contract.bSideInvalid': 'Yritystiedot ovat puutteelliset.',
  'contract.buyer': 'Työn tilaaja',
  'contract.cantMoveHeading': 'Sopimuksen siirto ei onnistu',
  'contract.cantMoveInfo': 'Sopimuksen työmaaluville on kirjattu hyväksyttyjä tuntilappumerkintöjä, joten sopimusta ei voi siirtää. Jos kuitenkin haluat siirtää sopimuksen, ota yhteyttä Zeroni -tukeen (support@zeroni.fi).',
  'contract.cantRemoveHeading': 'Sopimuksen poisto ei onnistu',
  'contract.cantRemoveInfo': 'Sopimuksen työmaaluville on kirjattu hyväksyttyjä tuntilappumerkintöjä, joten sopimusta ei voi poistaa. Jos kuitenkin haluat poistaa sopimuksen, ota yhteyttä Zeroni -tukeen (support@zeroni.fi).',
  'contract.chain': 'Ketju',
  'contract.chooseFromListButEmpty': 'Työtehtävät on valittava listalta, mutta työtehtävälista on tyhjä.',
  'contract.chooseNewParent': 'Valitse sopimus, jonka alle sopimus <strong>{0}</strong> siirretään:',
  'contract.companyPendingCertificates': 'Odottaa vaadittuja sertifikaatteja',
  'contract.confirmSafetyLevel': 'Vahvista turvataso',
  'contract.contacts': 'Yhteyshenkilöt',
  'contract.continuing': 'Jatketaan...',
  'contract.contractAll': 'Pienennä kaikki',
  'contract.contractContacts': 'Urakoitsijan yhteyshenkilöt',
  'contract.contractEditorNotifications': 'Ilmoitukset',
  'contract.contractHasNoForemanInfo0': 'Sopimukselle ei ole valittu työnjohtajaa.',
  'contract.contractHasNoForemanInfo1': 'Voit valita työnjohtajan henkilöt-välilehdeltä tai merkitä, että ',
  'contract.contractHasNoForemanInfo2': 'sopimukselle ei tarvita työnjohtajaa.',
  'contract.contractHasNoForemanInfoFull.noContractModifyPermission': 'Sopimukselle ei ole valittu työnjohtajaa. Voit valita työnjohtajan henkilöt-välilehdeltä.',
  'contract.contractHasNoForemanInfoFull': 'Sopimukselle ei ole valittu työnjohtajaa. Voit valita työnjohtajan henkilöt-välilehdeltä tai merkitä, että sopimukselle ei tarvita työnjohtajaa.',
  'contract.contractMissing': 'Puuttuuko sopimus?',
  'contract.contractValidityPeriod': 'Urakan kesto',
  'contract.developerAccessWarning': 'Tilaajan yhteyshenkilöt saavat pääkäyttäjäoikeudet ja he voivat muokata työmaata kuten pääurakoitsija.',
  'contract.document.location.elsewhere': 'Tallennettu erilliseen järjestelmään',
  'contract.documents.accidentInsuranceLegend.EE': '<em>Sotsiaalkindlustusamet</em>-viraston antama A1-/E101-todistus tai sopimuskumppanin työntekijät vakuuttaneen suomalaisen tapaturmavakuutusyhtiön antama todistus Suomesta otetusta tapaturmavakuutuksesta',
  'contract.documents.accidentInsuranceLegend.generic': 'Sopimuskumppanin työntekijät vakuuttaneen suomalaisen tapaturmavakuutusyhtiön antama todistus Suomesta otetusta tapaturmavakuutuksesta',
  'contract.documents.accidentInsuranceLegend.LT': '<em>Valstybinio socialinio draudimo fondo valdybos</em> -viraston antama A1-/E101-todistus tai sopimuskumppanin työntekijät vakuuttaneen suomalaisen tapaturmavakuutusyhtiön antama todistus Suomesta otetusta tapaturmavakuutuksesta',
  'contract.documents.accidentInsuranceLegend.LV': '<em>Valsts sociālās apdrošināšanas aģentūra (VSAA)</em> -viraston antama A1-/E101-todistus tai sopimuskumppanin työntekijät vakuuttaneen suomalaisen tapaturmavakuutusyhtiön antama todistus Suomesta otetusta tapaturmavakuutuksesta',
  'contract.documents.accidentInsuranceLegend.NO': '<em>Norges arbeids- og velferdsetaten (NAV)</em>-viraston antama A1-/E101-todistus tai sopimuskumppanin työntekijät vakuuttaneen suomalaisen tapaturmavakuutusyhtiön antama todistus Suomesta otetusta tapaturmavakuutuksesta',
  'contract.documents.accidentInsuranceLegend.PL': '<em>Zakładu Ubezpieczeń Społecznych (ZUS)</em>-viraston antama A1-/E101-todistus tai sopimuskumppanin työntekijät vakuuttaneen suomalaisen tapaturmavakuutusyhtiön antama todistus Suomesta otetusta tapaturmavakuutuksesta',
  'contract.documents.accidentInsuranceLegend.SE': '<em>Pensionsmyndigheten</em>-viraston antama A1-/E101-todistus tai sopimuskumppanin työntekijät vakuuttaneen suomalaisen tapaturmavakuutusyhtiön antama todistus Suomesta otetusta tapaturmavakuutuksesta',
  'contract.documents.approvedDocuments': 'Viimeisimmät hyväksytyt dokumentit',
  'contract.documents.archivedDocuments': 'Arkistoidut dokumentit',
  'contract.documents.areContractorLiabilityDocumentsRequired': 'Onko sopimuskumppani vapautettu toimittamasta tilaajavastuudokumentteja?',
  'contract.documents.confirmExemption': 'Vahvista vapautus',
  'contract.documents.confirmExemptionInfo': 'Vahvista että sopimuskumppani on vapautettu dokumenttien toimittamiselta Luotettava Kumppani -raportin mukaisesti.',
  'contract.documents.decider': 'Dokumenttien tarkastaja',
  'contract.documents.documentsExpirationDate': 'Viimeinen voimassaolopäivä',
  'contract.documents.employerRegisterLegend.NO': '<em>Skatteetaten</em>-viraston antama todistus/rekisteriote',
  'contract.documents.employerRegisterLegend.SE': '<em>Skatteverket</em>-viraston antama todistus/rekisteriote työnantajarekisteriin (arbetsgivarregister) kuulumisesta',
  'contract.documents.exemptPending': 'Työmaan tilaajan tai pääurakoitsijan vahvistettava vapautus.',
  'contract.documents.expirationInPast': 'Huomioithan, että dokumentit ovat vanhentuneet.',
  'contract.documents.expirationToday': 'Huomioithan, että viimeinen voimassaolopäivä on jo tänään.',
  'contract.documents.genericHelp': 'Lue tarkemmat ohjeet Työsuojeluhallinnon sivuilta:',
  'contract.documents.hideHelp': 'Piilota tarvittavat dokumentit',
  'contract.documents.inExternalSystem': 'Tilaajavastuudokumentit on tallennettu erilliseen järjestelmään.',
  'contract.documents.info': 'Hyväksytyt dokumentit siirtyvät Viimeksi hyväksytyt dokumentit -kohtaan. Jos vanhentuneiden dokumenttien tilalle myöhemmin hyväksytään uusia dokumentteja, siirtyvät aikaisemmin hyväksytyt dokumentit Arkistoidut dokumentit -kohtaan ja uudet dokumentit Viimeksi hyväksytyt dokumentit -kohtaan. Hyväksyttyjä dokumentteja ei pysty poistamaan.',
  'contract.documents.intro.EE': '<strong>Yrityksen kotipaikaksi on merkitty Viro.</strong> Virolaiselta yritykseltä vaaditaan seuraavat dokumentit.',
  'contract.documents.intro.FI': '<strong>Yrityksen kotipaikaksi on merkitty Suomi.</strong> Suomalaiselta yritykseltä vaaditaan seuraavat dokumentit.',
  'contract.documents.intro.LT': '<strong>Yrityksen kotipaikaksi on merkitty Liettua.</strong> Liettualaiselta yritykseltä vaaditaan seuraavat dokumentit.',
  'contract.documents.intro.LV': '<strong>Yrityksen kotipaikaksi on merkitty Latvia.</strong> Latvialaiselta yritykseltä vaaditaan seuraavat dokumentit.',
  'contract.documents.intro.NO': '<strong>Yrityksen kotipaikaksi on merkitty Norja.</strong> Norjalaiselta yritykseltä vaaditaan seuraavat dokumentit.',
  'contract.documents.intro.PL': '<strong>Yrityksen kotipaikaksi on merkitty Puola.</strong> Puolalaiselta yritykseltä vaaditaan seuraavat dokumentit.',
  'contract.documents.intro.RU': '<strong>Yrityksen kotipaikaksi on merkitty Venäjä.</strong> Venäläiseltä yritykseltä vaaditaan seuraavat dokumentit.',
  'contract.documents.intro.SE': '<strong>Yrityksen kotipaikaksi on merkitty Ruotsi.</strong> Ruotsalaiselta yritykseltä vaaditaan seuraavat dokumentit.',
  'contract.documents.intro.UDEFINED': '<strong>Yritykselle ei ole merkitty kotipaikkaa.</strong> <em>Suomalaiselta</em> yritykseltä vaaditaan seuraavat dokumentit.',
  'contract.documents.intro.UNDEFINED': '<strong>Yritykselle ei ole merkitty kotipaikkaa.</strong> <em>Suomalaiselta</em> yritykseltä vaaditaan seuraavat dokumentit.',
  'contract.documents.location': 'Dokumenttien sijainti',
  'contract.documents.noEmployerRegister': 'Ei vaadita (ei työnantajarekisterkiä)',
  'contract.documents.noFiles': 'Ei tilaajavastuudokumentteja',
  'contract.documents.pending': 'Tilaajavastuudokumentit odottavat käsittelyä.',
  'contract.documents.pensionInsuranceLegend.EE': '<em>Sotsiaalkindlustusamet</em>-viraston antama A1-/E101-todistus tai sopimuskumppanin työntekijät vakuuttaneen suomalaisen työeläkelaitoksen antama todistus',
  'contract.documents.pensionInsuranceLegend.LT': '<em>Valstybinio socialinio draudimo fondo valdybos</em> -laitoksen antama A1-/E101-todistus tai sopimuskumppanin työntekijät vakuuttaneen suomalaisen työeläkelaitoksen antama todistus',
  'contract.documents.pensionInsuranceLegend.LV': '<em>Valsts sociālās apdrošināšanas aģentūra (VSAA)</em> -viraston antama A1-/E101-todistus tai sopimuskumppanin työntekijät vakuuttaneen suomalaisen työeläkelaitoksen antama todistus',
  'contract.documents.pensionInsuranceLegend.NO': '<em>Norges arbeids- og velferdsetaten (NAV)</em> -viraston antama A1-/E101-todistus tai sopimuskumppanin työntekijät vakuuttaneen suomalaisen työeläkelaitoksen antama todistus',
  'contract.documents.pensionInsuranceLegend.PL': '<em>Zakładu Ubezpieczeń Społecznych (ZUS)</em>-viraston antama A1-/E101-todistus tai sopimuskumppanin työntekijät vakuuttaneen suomalaisen työeläkelaitoksen antama todistus',
  'contract.documents.pensionInsuranceLegend.RU': 'TYÖKOMENNUS KESTÄÄ ENINTÄÄN 2 VUOTTA: <br>enintään 2 vuotta kestävän <br>työkomennuksen osalta <br>todistus Venäjän <br>työeläkejärjestelmän <br>mukaisesta vakuutuksesta ja <br>eläkevakuutusmaksujen <br>maksamisesta. <br>TYÖKOMENNUS KESTÄÄ <br>YLI 2 VUOTTA:<br>2 vuotta ylittävän <br>komennuksen ajalta selvitys <br>Eläketurvakeskuksen <br>päätöksestä, jolla on myönnetty <br>vapautus työntekijän eläkelaimukaisesta <br>vakuuttamisvelvollisuudesta ja <br>todistus Venäjän <br>työeläkejärjestelmän <br>mukaisesta vakuutuksesta ja <br>eläkevakuutusmaksujen <br>maksamisesta <br><u>tai <br>sopimuskumppanin työntekijät <br>vakuuttaneen suomalaisen <br>työeläkelaitoksen antama <br>todistus</u>.<br>(Huom. Jos työntekijä <br>työkomennuksen alkaessa <br>kuuluu Suomen sosiaaliturvan <br>piiriin taikka häneen <br>sovelletaan EU:n sosiaaliturvan <br>perusasetusta, tulee esittää <br>suomalaisen työeläkelaitoksen <br>antama todistus taikka A1-/E101-todistus.)',
  'contract.documents.pensionInsuranceLegend.SE': '<em>Pensionsmyndigheten</em>-viraston antama A1-/E101-todistus tai sopimuskumppanin työntekijät vakuuttaneen suomalaisen työeläkelaitoksen antama todistus',
  'contract.documents.prepaymentLegend.EE': '<em>Maksu- ja Tolliamet</em> -viraston antama todistus/rekisteriote kuulumisesta maksuvelvollisten rekisteriin',
  'contract.documents.prepaymentLegend.LT': '<em>Valstybinė mokesčių inspekcija prie Lietuvos Respublikos finansų ministerijos (VMI)</em> -viraston antama todistus/rekisteriote',
  'contract.documents.prepaymentLegend.LV': '<em>Valsts ieņēmumu dienests (VID)</em> -viraston antama todistus/rekisteriote',
  'contract.documents.prepaymentLegend.NO': '<em>Skatteetaten</em>-viraston antama todistus/rekisteriote',
  'contract.documents.prepaymentLegend.PL': '<em>Urzad Skarbowy (US)</em> -viraston antama todistus (NIP-tunnus)',
  'contract.documents.prepaymentLegend.RU': '<em>Федеральная налоговая служба (transl. Federalnaja nalogovaja sluzhba)</em> -viraston antama todistus/rekisteriote verovirastoon ilmoittautumisesta',
  'contract.documents.prepaymentLegend.SE': '<em>Skatteverket</em>-viraston antama todistus/rekisteriote',
  'contract.documents.privateDocs': 'Yksityiset dokumentit',
  'contract.documents.privateDocsInfo': 'Yksityiset dokumentit näkyvät vain yrityksen <strong>{0}</strong> käyttäjille. Yrityksen sisällä dokumenttien näkyvyyttä hallitaan käyttäjäroolien oikeuksilla.',
  'contract.documents.privateDocsInfoWithLink': 'Yksityiset dokumentit näkyvät vain yrityksen <strong>{0}</strong> käyttäjille. Yrityksen sisällä dokumenttien näkyvyyttä hallitaan <a href="#!/manage/usergroups" target="_blank">käyttäjäroolien oikeuksilla</a>.',
  'contract.documents.publicDocsInfo': 'Dokumentit näkyvät urakoitsijalle ja kaikille sopimuspuussa ylempänä oleville yrityksille.',
  'contract.documents.removeSelection': 'Poista valinta',
  'contract.documents.requiredDocuments': 'Tarvittavat dokumentit',
  'contract.documents.showHelp': 'Näytä tarvittavat dokumentit',
  'contract.documents.taxPaymentsLegend.EE': '<em>Maksu- ja Tolliamet</em> -viraston antama todistus/rekisteriote',
  'contract.documents.taxPaymentsLegend.LT': '<em>Valstybinė mokesčių inspekcija prie Lietuvos Respublikos finansų ministerijos (VMI)</em> -viraston antama todistus/rekisteriote',
  'contract.documents.taxPaymentsLegend.LV': '<em>Valsts ieņēmumu dienests (VID)</em> -viraston antama todistus/rekisteriote',
  'contract.documents.taxPaymentsLegend.NO': '<em>Skatteetaten</em>-viraston antama todistus/rekisteriote',
  'contract.documents.taxPaymentsLegend.PL': '<em>Urzad Skarbowy (US)</em> -viraston antama todistus (lomake ZAS-W)',
  'contract.documents.taxPaymentsLegend.RU': '<em>Федеральная налоговая служба (transl. Federalnaja nalogovaja sluzhba)</em> -viraston antama todistus/rekisteriote tai <em>Межрайонная ИФНС России (transl. Mezhraionnaja IFNS Rossii)</em> -viraston antama todistus/rekisteriote',
  'contract.documents.taxPaymentsLegend.SE': '<em>Skatteverket</em>-viraston antama todistus/rekisteriote',
  'contract.documents.tesLegend.generic': 'Sopimuskumppanin antama selvitys työhön sovellettavasta työehtosopimuksesta tai työehdoista',
  'contract.documents.tradeRegisterLegend.EE': '<em>Registrite ja Infosüsteemide Keskus (RIK)</em> -viraston antama todistus/rekisteriote yritysrekisteriin (äriregister) kuulumisesta',
  'contract.documents.tradeRegisterLegend.LT': '<em>Juridinių asmenu registras </em> -viraston antama todistus/rekisteriote (<em>registravimo pazymėjimas</em>) oikeushenkilöiden rekisteriin kuulumisesta',
  'contract.documents.tradeRegisterLegend.LV': '<em>Latvijas Republikas uzņēmumu registrs</em> -viraston antama todistus/rekisteriote yritysrekisteriin kuulumisesta',
  'contract.documents.tradeRegisterLegend.NO': '<em>Enhetsregisteret</em>-viraston antama todistus/rekisteriote yritysrekisteriin (foretaksregisteret) kuulumisesta',
  'contract.documents.tradeRegisterLegend.PL': 'Puolalaisomistuksessa olevat yhtiöt: <em>Główny Urząd Statystyczny (GUS)</em> -viraston antama todistus/rekisteriote, josta ilmenee REGON-tunnus. (Puolalaisomistuksessa olevien yhtiöiden osalta käy myös sama rekisteriote kuin ulkomaalaisomisteistenkin.) Ulkomaalaisomistuksessa olevat yhtiöt: <em>Krajowy Rejestr Sadowy (KRS)</em> -viraston antama rekisteriote.',
  'contract.documents.tradeRegisterLegend.RU': '<em>Федеральная налоговая служба (transl. Federalnaja nalogovaja sluzhba)</em> -viraston antama ote juridisten henkilöiden valtiollisesta rekisteröinnistä (свидетельство о государственной регистрации юридических лиц)',
  'contract.documents.tradeRegisterLegend.SE': '<em>Bolagsverket</em>-viraston antama todistus/rekisteriote yritysrekisteriin (bolagsregister) kuulumisesta',
  'contract.documents.vatObligationLegend.EE': '<em>Maksu- ja Tolliamet</em> -viraston antama todistus/rekisteriote',
  'contract.documents.vatObligationLegend.LT': '<em>Valstybinė mokesčių inspekcija prie Lietuvos Respublikos finansų ministerijos (VMI)</em> -viraston antama <em>pridėtinės vertės mokesčio mokėtojo registracijos pažymėjimas</em> -todistus/rekisteriote',
  'contract.documents.vatObligationLegend.LV': '<em>Valsts ieņēmumu dienests (VID)</em> -viraston antama todistus/rekisteriote',
  'contract.documents.vatObligationLegend.NO': '<em>Skatteetaten</em>-viraston antama todistus/rekisteriote',
  'contract.documents.vatObligationLegend.PL': '<em>Urzad Skarbowy (US)</em> -viraston antama asiakirja, josta ilmenee Numer Identifikacji Podatkowej (NIP-tunnus)',
  'contract.documents.vatObligationLegend.RU': 'Sama kuin kohdan 1. todistus',
  'contract.documents.vatObligationLegend.SE': '<em>Skatteverket</em>-viraston antama todistus/rekisteriote',
  'contract.doNotSendContractTaxReports': '<strong>Ei</strong> lähetetä urakkailmoituksia',
  'contract.doNotSendEitherTaxReports': '<strong>Ei</strong> lähetetä urakka- eikä työntekijäilmoituksia',
  'contract.doNotSendEmployeeTaxReports': '<strong>Ei</strong> lähetetä työntekijäilmoituksia',
  'contract.duplicateUserEmails': '<strong>Henkilöiden sähköpostiosoitteiden tulee olla yksilöllisiä!</strong> <br> Kullekin henkilölle tulee syöttää henkilökohtainen sähköpostiosoite. Sama osoite ei voi olla käytössä useammalla henkilöllä.',
  'contract.editContract': 'Muokkaa sopimusta',
  'contract.email': 'Sähköpostiosoite',
  'contract.emails': 'Sähköpostiosoitteet',
  'contract.evaluations': 'Arviot',
  'contract.expandAll': 'Avaa kaikki',
  'contract.expanding': 'Avataan...',
  'contract.expertiseRequirement.all': 'Kaikki pätevyydet ja koulutukset',
  'contract.expertiseRequirement.custom': 'Vain koulutukset',
  'contract.expertiseRequirement.none': 'Ei mitään',
  'contract.extend': 'Pidennä',
  'contract.extendingEndDate': 'Olet muuttamassa sopimuksen päättymispäivää myöhäisemmäksi.',
  'contract.extendingStartDate': 'Olet muuttamassa sopimuksen alkamispäivää aikaisemmaksi.',
  'contract.extendNone': 'Älä pidennä',
  'contract.extendOnlyThis': 'Vain tämän sopimuksen luvat',
  'contract.extendPermits': 'Olet pidentämässä sopimuksen voimassaoloaikaa. Pidennetäänkö myös työmaalupien voimassaoloajat?',
  'contract.extendThisAndBelow': 'Myös aliurakoiden luvat',
  'contract.forceManagedInfo': 'Työmaan hallintaoikeus siirtyy uuden pääurakointisopimuksen myötä yritykselle {0}.',
  'contract.getCurrentJobTitleList': 'Hae nykyinen työnimikelista',
  'contract.getCurrentMaterialsList': 'Hae nykyinen materiaalilista',
  'contract.getCurrentScopeList': 'Hae nykyinen työnimikelista',
  'contract.hasBeenRejected': 'Sopimus on hylätty',
  'contract.hasMergedTo': 'Sulautunut yritykseen',
  'contract.hideSubcontracts': 'Piilota aliurakoitsijat',
  'contract.highlight': 'Korosta',
  'contract.informContractorPresence': 'Ilmoita urakoitsijan läsnäolosta työmaalla sähköpostitse',
  'contract.insurerInfo': 'Työmaalle täytyy määrittää tulityölupien vakuuttaja ennen kuin tulityölupia voidaan myöntää. Vakuutusyhtiö määritellään työmaan tiedot -sivulta.',
  'contract.insurerMissing': 'Vakuutusyhtiö puuttuu',
  'contract.interrupting': 'Keskeytetään...',
  'contract.invoiceHourApprover': 'Tuntilapun hyväksyjä',
  'contract.invoiceHourReporterInfo': 'Sopimuksen tunnit täyttää {0} ja hyväksyy {1}.',
  'contract.invoiceHours': 'Tuntilappu',
  'contract.invoiceHoursNotUsed': 'Tuntilappu ei käytössä.',
  'contract.invoiceHoursRole': 'Tuntilappurooli',
  'contract.invoiceHoursUsed': 'Tuntilappu käytössä',
  'contract.jobTitles': 'Työtehtävät',
  'contract.jobTitlesAndMaterials': 'Työtehtävät ja materiaalit',
  'contract.logbooks.goToLogbook': 'Siirry työmaapäiväkirjaan',
  'contract.logbooks.useLogbook': 'Ota työmaapäiväkirja käyttöön',
  'contract.mainContractDescription': 'Rakennushankkeessa <strong>pääurakka</strong> on yleensä varsinaiset rakennustekniset työt kattava urakka, joka sisältää perustus-, runko- ja sisä-valmistusvaiheiden rakennustekniset työt.',
  'contract.managed': 'Hallintaoikeus',
  'contract.managed.deny': 'Ei hallintaoikeutta',
  'contract.managed.deny.info': 'Tämän sopimuksen alla työskentelevät henkilöt ilmoitetaan työmaalle sopimusketjun ylemmän tahon toimesta.',
  'contract.managed.grant': 'Myönnä hallintaoikeus',
  'contract.managed.grant.info': 'Yritys voi ilmoittaa itse henkilöitä työmaalle. Oikeus tähän sopimukseen myönnetään tässä listatuille yhteyshenkilöille.',
  'contract.managed.label': 'Oman sopimuksen hallinta',
  'contract.moveAsSideContract': 'Siirrä sivu-urakointisopimukseksi',
  'contract.myContract': 'Oma sopimus',
  'contract.newCompanyHelp': '<strong>Uusi yritys lisätään sopimuksen luonnin yhteydessä.</strong> Uusi yritys näkyy yritysrekisterissä välittömästi lisättyäsi sen sopimuskumppaniksi.',
  'contract.newPerson': 'Uusi henkilö',
  'contract.noContractDocumentsModal': 'Ei näytettäviä dokumentteja',
  'contract.noContractDocumentsModalMessage': 'Ei löytynyt yhtään sopimusta jonka dokumentteja voisi näyttää. Tarkista että sinulla on oikeudet sopimuksiin, joilla on dokumentteja.',
  'contract.noContractTaxReportsBelowThreshold': 'Verottajan pyynnöstä urakkailmoituksia ei lähetetä, jos sopimuksen arvioitu summa on alle 15 000 €.',
  'contract.noEvaluations': 'Ei arviointeja',
  'contract.noForemanPossibleForUsersWithModifyPermission': 'Sopimukseen voidaan myös merkitä tieto, että työnjohtajaa ei tarvita. Tämä toiminto vaatii kuitenkin sopimuksen muokkausoikeuden, jota sinulla ei ole.',
  'contract.noForemanRequired': 'Ei tarvita työnjohtajaa',
  'contract.noJobTitlesAvailable': 'Ei valittavia työtehtäviä',
  'contract.noMatchingResults': 'Hakua vastaavia henkilöitä ei löytynyt.',
  'contract.noMaterialsAvailable': 'Ei valittavia materiaaleja',
  'contract.noPersons': 'Sopimukseen ei ole vielä lisätty yhtään henkilöä.',
  'contract.noReportsToTaxAdmin': 'Ei ilmoiteta verottajalle',
  'contract.noReportToTaxAdmin': 'Ei ilmoiteta verottajalle',
  'contract.notificationOfPostingOfWorkers': 'Ilmoitus lähetettyjen työntekijöiden käytöstä',
  'contract.notificationOfPostingOfWorkersDate': 'Ilmoituksen päivämäärä',
  'contract.notificationOfPostingOfWorkersInfoMessage': 'Ilmoitusvelvollisuus on voimassa 1.9.2017 alkaen. <a href="https://anon.ahtp.fi/_layouts/Lomake.ashx?LomakeID=10103" target="_blank" rel="noopener">Ohje (tyosuojelu.fi) <span class="far fa-external-link-alt"></span></a>',
  'contract.notUsed': 'Ei käytössä',
  'contract.onlyActive': 'Vain aktiiviset',
  'contract.onlyOwn': 'Vain omat',
  'contract.openSettings': 'Avaa asetukset',
  'contract.permitsNeedContracts': 'Työmaalupien pidentäminen edellyttää aliurakointisopimusten pidentämistä.',
  'contract.persons': 'Henkilöt',
  'contract.personsInContract': 'Henkilöt<br><small>sopimuksessa{0}</small>',
  'contract.reasonForRejection': 'Hylkäyksen syy',
  'contract.rejected': 'Hylätty',
  'contract.rejectingParty': 'Hylkääjä',
  'contract.rejection': 'Hylkäys',
  'contract.removeThisContact': 'Poista tämä yhteyshenkilö',
  'contract.reporter': 'Täyttäjä',
  'contract.requestApproval': 'Hyväksyykö <strong>{0}</strong> uuden sopimuksen yritykselle <strong>{1}</strong> ({2})?',
  'contract.requiredCertificates': 'Vaadittavat sertifikaatit',
  'contract.safetyLevel': 'Turvataso',
  'contract.safetyLevelForContract': 'Sopimuksen <strong>{0}</strong> ({1}) turvataso',
  'contract.scope': 'Työtehtävät',
  'contract.search': 'Sopimushaku',
  'contract.searchOrSelectContractContactPlaceholder': 'Yhteyshenkilö',
  'contract.searchOrSelectJobTitlePlaceholder': 'Työtehtävä (esim. maanrakennus, sähkötyöt, purku)',
  'contract.searchSelectOrAddJobTitlePlaceholder': 'Työtehtävä (esim. maanrakennus, sähkötyöt, purku)',
  'contract.selectContact': 'Valitse yhteyshenkilö',
  'contract.selectContractForNewPersons': 'Valitse sopimus, johon työntekijät lisätään.',
  'contract.sendBothTaxReports': '<strong>Lähetetään</strong> sekä urakka- että työntekijäilmoitukset',
  'contract.sendContractTaxReports': '<strong>Lähetetään</strong> urakkailmoitukset',
  'contract.sendEmployeeTaxReports': '<strong>Lähetetään</strong> työntekijäilmoitukset',
  'contract.showOnlyActive': 'Näytä vain aktiiviset',
  'contract.showOnlyOwn': 'Näytä vain omat',
  'contract.showRemainingSubcontracts': 'Näytä loput {0} aliurakoitsijaa',
  'contract.showSubcontracts': 'Näytä aliurakoitsijat ({0})',
  'contract.showSuggestionsForContact': 'Näytä ehdotukset yhteyshenkilöksi',
  'contract.showSuggestionsForSecurityContact': 'Näytä ehdotukset turvallisuusvastaavaksi',
  'contract.sideContractDescription': '<strong>Sivu-urakka</strong> on rakennuttajan tilaama pääurakkaan kuulumaton urakka.',
  'contract.siteCompetences': 'Vaadittavat pätevyydet',
  'contract.siteCompetencesRequired': 'Vaaditaan',
  'contract.statusOnCreation': 'Tila sopimusta tehtäessä',
  'contract.subContractDescription': '<strong>Aliurakka</strong> on urakoitsijan urakasta erottama kokonaisuus, jonka suorittaa urakoitsijan valitsema aliurakoitsija.',
  'contract.suggestSafetyLevel': 'Ehdota turvatasoa',
  'contract.suspended': 'Keskeytetty',
  'contract.taxReportsOnSiteNeither': 'Työmaan asetukset: urakka- tai työntekijäilmoituksia ei lähetetä.',
  'contract.taxReportsOnSiteNoContracts': 'Työmaan asetukset: urakkailmoituksia ei lähetetä.',
  'contract.taxReportsOnSiteNoEmployees': 'Työmaan asetukset: työntekijäilmoituksia ei lähetetä.',
  'contract.toBeMoved': 'Siirrettävä sopimus',
  'contract.valtticardNotValid': 'Työmaaluvan luominen ei ole mahdollista, koska Valttikortti ei ole voimassa.',
  'contractDocuments.furtherInfoCLReportUpdate': 'Manuaalisen päivityksen lisätieto',
  'contractEditor.contractExistsForB': 'Tällä yrityksellä on jo sopimus tällä työmaalla:',
  'contractEditor.emailDomainEqualsToOwn': '<strong>Syötä urakoitsijan yhteystiedot:</strong> Sähköposti näyttää samalta kuin omasi. Varmista, että syötät yhteyshenkilöksi <em>urakoitsijan</em> edustajan.',
  'contractEditor.furtherInfoOfManualCLupdate': 'Lisätietoja tilaajavastuuraportin hakemiseen liittyen',
  'contractEditor.iWantToAddMultipleContractsForThisCompany': 'Haluan lisätä useita sopimuksia tälle yritykselle',
  'contractEditor.newMainContract': 'Uusi pääurakointisopimus',
  'contractEditor.newSideContract': 'Uusi sivu-urakointisopimus',
  'contractEditor.parentContract': 'Ylätason sopimus',
  'contractEditor.parentContractEnds': 'Ylätason sopimuksen <strong>{0}</strong> päättymispäivä: <strong>{1}</strong>.',
  'contractEditor.showLiabilityDocs': 'Näytä tilaajavastuudokumentit',
  'contractEditor.updateContractAndCLReport': 'Sopimuksen tilan päivitys ja Luotettava kumppani -raportin nouto',
  'contractEditor.updateContractorLiabilityDocuments': 'Päivitä tilaajavastuudokumentit',
  'contractEditor.useHelperDuration': 'Aseta tämä sopimus alkamaan tänään, päättymään kuten ylätason sopimus',
  'contractEditor.useHelperDuration2': 'Aseta tämä sopimus alkamaan leimauksen päivämäärästä, ja päättymään kuten ylätason sopimus',
  'contractEditor.useHelperDuration3': 'Sopimuksen aloituspäivän täytyy olla ennen sulautumispäivää <strong>{0}</strong>. Jos sopimus alkaa myöhemmin, luo sopimus yritykselle {1} ({2}).',
  'contractorObligationGetCompanyInfo': 'Tilaajavastuutietoja haetaan Vastuu Groupin rekisteristä.',
  'contractorSignature': 'Urakoitsijan allekirjoitus',
  'country.eu': 'EU',
  'country.notEu': 'Ei EU',
  'course.fromExternalSystem': 'Ulkoisesta järjestelmästä',
  'course.noManualCreation': 'Tämä koulutus lisätään henkilöille ulkoisessa järjestelmässä, joten koulutusta ei voi itse lisätä Zeronissa.',
  'dailyReport.buyer': 'Tilaaja',
  'dailyReport.buyerRep': 'Tilaajan edustaja',
  'dailyReport.byCheckIns': 'Leimauksista',
  'dailyReport.byPreviousDay': 'Edellinen päivä',
  'dailyReport.changeMonth': 'Vaihda kuukausi',
  'dailyReport.contractorRep': 'Urakoitsijan edustaja',
  'dailyReport.copyValues': 'Käytä',
  'dailyReport.day': 'Päivä',
  'dailyReport.dayWeather': 'Päivän sää',
  'dailyReport.documents': 'Dokumentit',
  'dailyReport.employeeUnitShort': 'hlö',
  'dailyReport.extinguishingEquipments': 'Sammutuskalusto ja tulityön vartiointi',
  'dailyReport.firstEnterRequiredInfo': 'Täytä ensin puuttuvat tiedot.',
  'dailyReport.jobTitle': 'Ammattinimike',
  'dailyReport.logbook.dailyReportsAvailableAfterSiteBegins': 'Päiväraportit ovat käytettävissä työmaan alettua {0}',
  'dailyReport.logbook.noPagesToPrint': 'Ei tulostettavia sivuja.',
  'dailyReport.logbook.printAllDays': 'Tulosta kaikki päivät',
  'dailyReport.logbook.printingLogbookPleaseWait': 'Valmistellaan työpäiväkirjaa tulostusta varten. Tämä voi kestää muutaman minuutin. Odotathan hetken...',
  'dailyReport.logbook': 'Päiväkirja',
  'dailyReport.logbooks.changeLogbook': 'Vaihda päiväkirjaa',
  'dailyReport.logbooks.name': 'Työmaapäiväkirjan nimi',
  'dailyReport.logbooks.new': 'Uusi työmaapäiväkirja',
  'dailyReport.logbooks.open': 'Avaa',
  'dailyReport.logbooks.printLogbook': 'Tulosta',
  'dailyReport.logbooks.printLogbookWeek': 'Tulosta viikko',
  'dailyReport.logbooks.searchResults.many': '{0} työmaapäiväkirjaa',
  'dailyReport.logbooks.searchResults.one': 'Yksi työmaapäiväkirja',
  'dailyReport.logbooks.selectContract': 'Valitse sopimus, johon työmaapäiväkirja lisätään.',
  'dailyReport.logbooks.selectDaysToPrint': 'Valitse tulostettavat päivät',
  'dailyReport.logbooks.siteSpecific': 'Työmaakohtainen päiväkirja',
  'dailyReport.logbooks': 'Työmaapäiväkirjat',
  'dailyReport.machine': 'Kone',
  'dailyReport.machines': 'Koneet',
  'dailyReport.machineUnitShort': 'kpl',
  'dailyReport.morning': 'Aamu',
  'dailyReport.morningWeather': 'Aamun sää',
  'dailyReport.noEmployees': 'Ei työntekijöitä',
  'dailyReport.noPermissionsToSupervisorNotes': 'Sinulla ei ole oikeutta muokata valvojan huomioita.',
  'dailyReport.noSignedReport': 'Ei allekirjoitettua päiväkirjamerkintää',
  'dailyReport.noSupervisorNotes': 'Valvojan huomiot puuttuvat',
  'dailyReport.openNext': 'Avaa seuraava merkintä',
  'dailyReport.openNextReport': 'Allekirjoita ja avaa seuraava allekirjoittamaton merkintä',
  'dailyReport.others': 'Muut',
  'dailyReport.otherShort': 'Muu',
  'dailyReport.resources': 'Resurssit',
  'dailyReport.resourcesInitialized': 'Henkilöstö määritetty leimausten perusteella. Koneet asetettu edellisen päivän tasolle. Tarkista tiedot.',
  'dailyReport.resourcesShort': 'Res.',
  'dailyReport.sectionTitle': 'Kappaleen otsikko',
  'dailyReport.showSuggestion': 'Ehdotukset',
  'dailyReport.sign': 'Allekirjoita',
  'dailyReport.signatureFromPersonWhoHasPermission': 'Allekirjoitus henkilöltä, jolla on oikeus täyttää päiväkirjaa',
  'dailyReport.signatureFromSupervisorNeeded': 'Allekirjoitus henkilöltä, jolla on oikeus kirjata valvojan huomioita',
  'dailyReport.signatures': 'Allekirjoitukset',
  'dailyReport.signaturesShort': 'Allek.',
  'dailyReport.staff': 'Henkilöstö',
  'dailyReport.supervisor': 'Valvoja',
  'dailyReport.supervisorHelp': '<strong>Työmaalle voidaan tarvittaessa nimetä ulkopuolinen valvoja.</strong> Esimerkiksi organisaatiosi pääkäyttäjä voi luoda valvojalle käyttäjän, jolle annetaan valvojarooli, joka on rajattu tähän työmaahan.',
  'dailyReport.supervisorShort': 'Valv.',
  'dailyReport.supervisorSignature': 'VALVOJAN ALLEKIRJOITUS',
  'dailyReport.supervisorsNotes': 'Valvojan huomiot',
  'dailyReport.temperature': 'Lämpötila',
  'dailyReport.template': 'Päiväkirjapohja',
  'dailyReport.templateHelp': '<p><strong>Muut-kohdan kentät ovat muokattavissa.</strong></p><p>Voit lisätä tai poistaa kenttiä muokkaamalla <strong>päiväkirjapohjaa</strong>.</p>',
  'dailyReport.templates': 'Päiväkirjapohjat',
  'dailyReport.templateUpdatedNotification': 'Päiväkirjapohja päivitetty',
  'dailyReport.useThis': 'Käytä tätä',
  'dailyReport.weather.cloudy': 'Pilvinen',
  'dailyReport.weather.partlyCloudy': 'Puolipilvinen',
  'dailyReport.weather.rain': 'Vesisade',
  'dailyReport.weather.sleet': 'Räntä',
  'dailyReport.weather.snow': 'Lumisade',
  'dailyReport.weather.strongWind': 'Kova tuuli',
  'dailyReport.weather.sunny': 'Aurinkoinen',
  'dailyReport.weather.thunder': 'Ukkonen',
  'dailyReport.weather': 'Sää',
  'dailyReport.weatherCondition': 'Säätila',
  'dailyReport.workMonitoring': 'Työn seuranta',
  'dailyReports.missingReportLegend': 'Puuttuva päiväkirjamerkintä',
  'dailyReports.noSafetyMeasurements': 'Ei turvallisuusmittauksia tälle päivälle.',
  'dailyReports.printHelp': 'Työmaapäiväkirjan sivun voi tulostaa allekirjoitusten jälkeen.',
  'dailyReports.reportWaitingForSupervisorLegend': 'Valvojan huomio tai allekirjoitus puuttuu',
  'dashboard.accessControl': 'Kulunvalvonta',
  'dashboard.activeCompanies': 'Aktiivista yritystä',
  'dashboard.activeContracts': 'aktiivista sopimusta',
  'dashboard.activeEmployees': 'Aktiivista työntekijää (kulkulupa voimassa)',
  'dashboard.activeOwn': 'aktiivista omaa',
  'dashboard.activeOwnEmployees': 'Aktiivista omaa työntekijää',
  'dashboard.activeOwnPartners': 'Aktiiviset omat sopimuskumppanit',
  'dashboard.activeVisitors': 'Aktiivista vierailijaa',
  'dashboard.activeWorkers': 'Työmaalla tällä hetkellä',
  'dashboard.activeWorkersAction': 'Näytä sisäänleimanneet',
  'dashboard.activeWorkersDescription': '{0}% kulkuluvallisista työntekijöistä',
  'dashboard.addSubContract': 'Lisää aliurakointisopimus',
  'dashboard.allActiveSites': 'Kaikki aktiiviset työmaat',
  'dashboard.allEmployeesFromStart': 'Kaikki työntekijät koko ajalta',
  'dashboard.assignEmployeeToSite': 'Ilmoita työntekijä työmaalle',
  'dashboard.averageActiveSitesInSixMonths': 'Työmaita keskimäärin viimeisen 6kk aikana',
  'dashboard.averageLevel': '{0}-mittausten keskimääräinen taso: <strong>{1}%</strong>',
  'dashboard.averageStrengthFromLastSixMonths': 'Työmaiden keskimääräinen vahvuus viimeisen 6kk ajalta',
  'dashboard.checkInDevices': 'Leimauslaitteet',
  'dashboard.companies': 'Yritykset',
  'dashboard.contractCompletedPayments': 'Urakkatapahtumien toteuma',
  'dashboard.contractors': 'Urakoitsijat',
  'dashboard.contractorWithoutForeman': 'Urakoitsijalle <strong>{0}</strong> ei ole merkitty työmaaluvallista työnjohtajaa.',
  'dashboard.contractsWithoutCheckIns': 'Sopimuksilla ei ole vielä leimauksia:',
  'dashboard.contractWithoutCheckIns': 'Sopimuksella ei ole vielä leimauksia:',
  'dashboard.editCompanyInfo': 'Muokkaa yrityksen tietoja',
  'dashboard.employees': 'Työntekijät',
  'dashboard.expired': 'Päättynyt',
  'dashboard.expiring': 'Päättymässä',
  'dashboard.foreignCompanies': 'Ulkomaalaiset yritykset',
  'dashboard.gateDevices': 'Porttilaitteet',
  'dashboard.hotWorkPermitExpired': 'Työntekijän <strong>tulityölupa</strong> on päättynyt viimeisen kolmen päivän sisällä.',
  'dashboard.hotWorkPermitsExpired': '<strong>{0}</strong> työntekijän <strong>tulityölupa</strong> on päättynyt viimeisen kolmen päivän sisällä.',
  'dashboard.jumpToSite': 'Siirry työmaalle',
  'dashboard.latestSafetyLevel': 'Viimeisin {0}-mittaus: {1}%',
  'dashboard.loadNotifications': 'Lataa ilmoitukset',
  'dashboard.mainContractorSites': 'Pääurakointia / rakennuttamista',
  'dashboard.nationalityEU': 'Muu EU',
  'dashboard.nationalityFin': 'Suomalaiset',
  'dashboard.nationalityNONEU': 'Ei-EU',
  'dashboard.noFilesYet': 'Ei vielä tiedostoja',
  'dashboard.noForeman': 'Ei työnjohtajaa',
  'dashboard.noMeasurements': 'Ei turvallisuusmittauksia',
  'dashboard.noNotifications': 'Kaikki hyvin! Ei varoituksia tai huomioita.',
  'dashboard.noPermissionToCheckIns': 'Ei oikeuksia leimauksiin',
  'dashboard.noPermissionToContracts': 'Ei oikeuksia sopimuksiin',
  'dashboard.noPermissionToSafety': 'Ei oikeuksia turvallisuustietoihin',
  'dashboard.noSafetyMeasurementType': 'Työmaalle ei ole valittu työ&shy;turvallisuus&shy;mittauksen tyyppiä',
  'dashboard.noSitesSoAddNew': '<strong>Ei työmaita.</strong> Rekisterissä ei ole työmaita tai sinulla ei ole niihin vaadittavia oikeuksia.',
  'dashboard.noSitesSoAddNew2': 'Lisää uusi työmaa',
  'dashboard.notification.noOrientationPermission': 'Sinulla ei ole oikeutta vahvistaa perehdytystä. Ota yhteys pääurakoitsijaan.',
  'dashboard.notification.personPendingChanges': 'Henkilön <a href="#!/sites/{0}/persons/{1}">{2}</a> työnteko-oikeuden tarkastuksessa havaittu puutteita.',
  'dashboard.notification.personPendingVerification': 'Henkilön <a href="#!/sites/{0}/persons/{1}">{2}</a> työnteko-oikeus odottaa tarkastusta.',
  'dashboard.notification.personsPendingChanges': '<strong>{0} henkilön</strong> työnteko-oikeuden tarkastuksessa havaittu puutteita, odottaa korjauksia.',
  'dashboard.notification.personsPendingVerification': '<strong>{0} henkilöä</strong> odottaa työnteko-oikeuden tarkastusta.',
  'dashboard.notification.vehiclePermitAwaitingApproval': '<strong>1</strong> ajoneuvolupa odottaa hyväksyntää tai toimenpiteitä.',
  'dashboard.notification.vehiclePermitsAwaitingApproval': '<strong>{0} ajoneuvolupaa</strong> odottaa hyväksyntää tai toimenpiteitä.',
  'dashboard.notifications': 'Ilmoitukset',
  'dashboard.onlyOwnEmployees': 'Vain omat työntekijät',
  'dashboard.openFiles': 'Tiedostot',
  'dashboard.openGraphs': 'Kaaviot',
  'dashboard.openNotifications': 'Ilmoitukset',
  'dashboard.openOverview': 'Yleiskatsaus',
  'dashboard.orientationAndOtherAttachments': 'Perehdytysmateriaali ja liitteet',
  'dashboard.overallLevel': 'Oikein-merkintöjen prosenttiosuus kaikista turvallisuusmittausten havainnoista.',
  'dashboard.overallLevelLastFourWeeks': 'Oikein-merkintöjen prosenttiosuus kaikista turvallisuusmittausten havainnoista viimeisen neljän viikon ajalta.',
  'dashboard.overallLevelLastWeek': 'Oikein-merkintöjen prosenttiosuus kaikista turvallisuusmittausten havainnoista viimeisen viikon ajalta.',
  'dashboard.overview.noReportsOfContractsSent': 'Työmaan sopimuksista ei lähetetä urakkailmoituksia',
  'dashboard.overview.noReportsOfEmployeesSent': 'Työntekijöistä ei lähetetä verottajalle ilmoituksia',
  'dashboard.overview': 'Yleiskatsaus',
  'dashboard.quickActions': 'Pikatoiminnot',
  'dashboard.residencePermitContinuationNeedsToBeChecked': '<strong>Oleskeluluvan</strong> jatkoluvan tila tarkistettava.',
  'dashboard.residencePermitContinuationsNeedsToBeChecked': '<strong>{0} oleskeluluvan </strong> jatkoluvan tila tarkistettava.',
  'dashboard.safetyMeasurementsAverage': 'Turvallisuusmittausten keskiarvo',
  'dashboard.safetyMeasurementsAverageAction': 'Viimeisin mittaus ({0}%)',
  'dashboard.searchHelp': 'Hae esim. henkilöitä, urakoitsijoita ja työmaita',
  'dashboard.searchPlaceholder': 'Syötä hakusanat',
  'dashboard.selectForeman': 'Valitse työnjohtaja',
  'dashboard.siteContractsWithoutCheckIns': 'Työmaan <strong>{0}</strong> sopimuksilla ei ole vielä leimauksia:',
  'dashboard.siteContractWithoutCheckIns': 'Työmaan <strong>{0}</strong> sopimuksella ei ole vielä leimauksia:',
  'dashboard.siteDocuments': 'Työmaan yleiset dokumentit',
  'dashboard.siteLinks': 'Työmaan linkit',
  'dashboard.siteOrientationMaterial': 'Työmaan perehdytysmateriaali',
  'dashboard.sites': 'Työmaat',
  'dashboard.subContractorSites': 'aliurakointia',
  'dashboard.totalDays': '{0} työpäivää (7,5h)',
  'dashboard.totalHours': 'Tunnit yhteensä',
  'dashboard.totalNumberOfHazards': 'Korjaamattomia turvallisuus-havaintoja',
  'deviation.photoOfDeviation': 'Kuva poikkeamasta',
  'deviation.photoOfReparation': 'Kuva korjauksesta',
  'device.basicInformation': 'Perustiedot',
  'device.confirmRemovalFromOtherSites': 'Lisäämäsi laite on jo käytössä toisella työmaalla. <strong>Haluatko poistaa laitteen muilta työmailta?</strong>',
  'device.connectDepartmentDevices': 'Liitä yksikön {0} laitteet työmaalle',
  'device.connectDepartmentDevicesInfo': 'Työmaa kuuluu yksikköön <b>{0}</b>, jolle on määritelty <b>{1}</b> laitetta. Haluatko liittää laitteet työmaalle?',
  'device.connectDevices': 'Liitä laitteet työmaalle',
  'device.copyFromDevice': 'Kopio laitteesta',
  'device.deletionPermanent': '<strong>Ei peruttavissa:</strong> Laite ja kaikki sen tiedot poistetaan. Poistoa ei voi perua.',
  'device.doNotRemoveDeviceFromOtherSites': 'Älä poista laitetta muilta työmailta',
  'device.furtherInformation': 'Lisätietoja',
  'device.isNotOnAnySite': 'Laite ei ole käytössä millään työmaalla.',
  'device.locationOnSite': 'Sijainti työmaalla',
  'device.model': 'Malli',
  'device.purchaseDate': 'Ostopäivä',
  'device.purchasePrice': 'Ostohinta (€)',
  'device.removeDevice': 'Poista laite',
  'device.removeFromOtherSites': 'Poistetaanko laite muilta työmailta?',
  'device.removeFromSite': 'Poista työmaalta',
  'device.showDevices': 'Näytä laitteet',
  'device.telephone': 'Puhelinnumero',
  'deviceSearch.searchDevice': 'Laitehaku',
  'deviceSubscriptionEnded': 'Liittymä suljettu',
  'dhs.companyContacts': 'Yritysyhteydet <span class="text-muted">(yritysten määrä, toimiala, status)</span>',
  'dhs.companyContactsEstonia': 'Yritysyhteydet Virossa',
  'dhs.companyFinancials': '<strong>Yrityksen taloustiedot</strong>',
  'dhs.courtInfo': 'Yrityksen ja vastuuhenkilöiden tuomioistuintiedot',
  'dhs.creditDisturbance': 'Maksuhäiriömerkinnät, perintätoimenpiteet, luottoluokitus',
  'dhs.creditReport': 'Reaaliaikaiset ulosottotiedot',
  'dhs.deliveryTime': 'Toimitusaika (työpäivää)',
  'dhs.dhsReport1': 'Raportti 1',
  'dhs.dhsReport2': 'Raportti 2',
  'dhs.dhsReports': 'DHS-raportit',
  'dhs.employeeInfo': 'Henkilötiedot',
  'dhs.EstPersonsInChargeCompanyContactsInEst': 'Virolaisten vastuuhenkilöiden yritysyhteydet Virossa',
  'dhs.financialStatementAnalysis': 'Tilinpäätösanalyysi <span class="text-muted">(perustuen viimeisen 5 vuoden tilinpäätöstietoihin)</span>',
  'dhs.hideReportDetails': 'Piilota taulukko',
  'dhs.orderDhsReport1': 'Tilaa <strong>Raportti 1</strong>',
  'dhs.orderDhsReport2': 'Tilaa <strong>Raportti 2</strong>',
  'dhs.orderingReport': 'Tilataan...',
  'dhs.orderNew': 'Tilaa uusi',
  'dhs.orderReady': 'Valmistunut',
  'dhs.orderReceived': 'Toimeksianto vastaanotettu',
  'dhs.paymentDefaultEntry': 'Luottotietomerkinnät <span class="text-muted">(negatiivinen luottohistoria, maksuhäiriöt, liiketoimintakiellot)</span>',
  'dhs.personsInCharge': 'Vastuuhenkilöt',
  'dhs.prevPersCreditInfoAndCompanyContacts': '<em>Entisten</em> vastuuhenkilöiden (nykyhetki - 2 vuotta) luottotietomerkinnät ja yritysyhteydet',
  'dhs.price': 'Hinta (€, alv 0%)',
  'dhs.registryData': '<strong>Yrityksen rekisteritiedot</strong>',
  'dhs.reports': 'Raportit',
  'dhs.taxes': 'ALV-, ennakkoperintä-, työnantaja- ja verovelkarekisteritiedot',
  'dhs.whatsIncludedInReports': 'Näytä raporttien sisällys ja hinnat',
  'employeecheckin.areas.checkedIn': 'Leimasit alueelle {0}',
  'employeecheckin.areas.checkIn': 'Leimaa sisään alueelle',
  'employeecheckin.areas.checkOut': 'Leimaa ulos alueelta {0}',
  'employeecheckin.loadWorkTimeReports': 'Näytä yhteenlasketut työtunnit kaikilta työmailta',
  'employeecheckin.noAccessPermits': 'Sinulla ei ole yhtään työmaalupaa.',
  'employeecheckin.noContractorReadPermissionForCheckIns': 'Urakoitsijoilla ei ole oikeutta nähdä leimauksiaan tällä työmaalla.',
  'employeeMenu.accessPermits': 'Työmaaluvat',
  'employeeMenu.checkInsHistory': 'Leimaushistoria',
  'employeeMenu.competences': 'Pätevyydet',
  'employerChangeTool.checkingPhoneAvailability': 'Tarkistetaan puhelinnumeron kelvollisuutta...',
  'employerChangeTool.error': 'Henkilön yhteystietojen vaihtaminen epäonnistui.',
  'employerChangeTool.name': 'Yhteystietojen vaihtaminen',
  'employerChangeTool.description':
    'Sähköpostiosoite toimii Zeronin käyttäjätunnuksena. Vaihtamalla sähköpostiosoitteen voit luoda uuden käyttäjän ' +
    'tai yhdistää henkilön olemassa olevaan käyttäjään.',
  'employerChangeTool.description.action': 'Vaihda yhteystiedot',
  'employerChangeTool.failedToCheckEmail': 'Sähköpostiosoitteen tarkastaminen epäonnistui.',
  'employerChangeTool.failedToCheckPhone': 'Puhelinnumeron yksilöllisyyden tarkastaminen epäonnistui.',
  'employerChangeTool.phoneNotUniqueWarning': 'Tämä numero on käytössä toisellakin henkilöllä. Kun henkilö vahvistaa numeron, muut eivät enää voi käyttää sitä.',
  'employerChangeTool.step1description': 'Syötä uusi sähköpostiosoite henkilölle {0}. Sähköpostiosoite toimii käyttäjätunnuksena.',
  'employerChangeTool.notAllowedToUpdate': 'Syöttämäsi sähköpostiosoite ei ole käytettävissä.',
  'employerChangeTool.success': 'Henkilön yhteystietojen vaihtaminen onnistui.',
  'employerChangeTool.phoneCantBeChanged':
    'Sähköpostiosoitteella löytyy jo käyttäjä Zeronista. Henkilön puhelinnumeroksi ' +
    'asetetaan tämän käyttäjän puhelinnumero. <br/><br/>Tietoturvasyistä puhelinnumero näytetään ' +
    'henkilön tiedoissa vasta tämän toiminnon suorittamisen jälkeen. Jos henkilölle ' +
    'halutaan vaihtaa myös puhelinnumero, tulee tämä tehdä Zeroni-tuen kautta.',
  'error.endDateHasToBeGreaterThanStartDate': 'Päättymispäivän täytyy olla suurempi kuin alkamispäivä.',
  'error.endDateHasToBeTodayOrInTheFuture': 'Päättymispäivän tulee olla tänään tai tulevaisuudessa.',
  'error.valttiCardBusinessIdMismatch': 'Valttikortti on myönnetty toiselle yritykselle.',
  'error.valttiCardCouldNotBeAdded': 'Valttikortin lisääminen ei onnistunut.',
  'error.valttiCardNotFound': 'Valttikorttia ei löytynyt.',
  'error.valttiCardWrongPerson': 'Valttikortti on myönnetty toiselle henkilölle.',
  'errorHandler.emailSent': 'Virheilmoitus on lähetetty asiakaspalveluumme. Olemme yhteydessä, kun tilanne on selvitetty.',
  'errorHandler.hideDetails': 'Piilota tekniset lisätiedot',
  'errorHandler.insufficientPermissions': 'Sinulla ei ole oikeutta muokata tätä resurssia.',
  'errorHandler.ohNo': 'Tapahtui odottamaton virhe.',
  'errorHandler.pleaseHideItAlready': 'Kiitos, riittää! Saisiko tuon piiloon?',
  'errorHandler.readDetails': 'lukea tekniset nippelitiedot virheestä',
  'errorHandler.readDetailsShort': 'Lue tekniset nippelitiedot virheestä',
  'errorHandler.times': 'Toiminto on epäonnistunut nyt {0} kertaa.',
  'errorHandler.tryAgainOr': 'Voit yrittää uudestaan tai',
  'evaluation.addCriteriaLink': 'Lisää kriteerit: Hallinta &rarr; Yleiset asetukset',
  'evaluation.addNewEvaluation': 'Lisää uusi arviointi',
  'evaluation.averageExplanation': 'Yleisarvosana on aktiivisten kriteerien keskiarvo. Poistetut kriteerit eivät vaikuta yleisarvosanaan.',
  'evaluation.category.header.existing': 'Valitse listalta',
  'evaluation.category.header.manual': 'Lisää käsin',
  'evaluation.category.manual': 'Lisää nimi ja numero itse',
  'evaluation.confirmRemoveEvaluation.body': 'Haluatko varmasti poistaa arvioinnin?',
  'evaluation.confirmRemoveEvaluation.title': 'Poistetaanko arviointi?',
  'evaluation.doNotApply': 'Ei arvioida',
  'evaluation.editEvaluation': 'Muokkaa arviointia',
  'evaluation.evaluations': 'arviota',
  'evaluation.howAverageIsCalculated': 'Miten lasketaan?',
  'evaluation.interestedModal.askForQuote': 'Haluaisitko käyttää toimittajien arviointia? Otamme yhteyttä yritykseenne.',
  'evaluation.interestedModal.heading': 'Kiinnostaako arviointi?',
  'evaluation.interestedModal.readMore': 'Lue lisää arvioinnista',
  'evaluation.interestedModal.readMoreIntro': '<b>Toimittajien arviointi</b> on maksullinen lisätoiminto.',
  'evaluation.interestedModal.yesInterested': 'Kyllä, arviointi kiinnostaa',
  'evaluation.newEvaluation': 'Uusi arviointi',
  'evaluation.noCriteria': 'Ei arviointikriteerejä.',
  'evaluation.removeCriterion.body': 'Haluatko varmasti poistaa kriteerin <b>{0}</b>?',
  'evaluation.removeCriterion.heading': 'Poistetaanko kriteeri?',
  'evaluation.removeEvaluation': 'Poista arviointi',
  'evaluation.requestSentModal.body': 'Kiitos! Otamme pian yhteyttä yritykseenne.',
  'evaluation.requestSentModal.heading': 'Yhteydenottopyyntö lähetetty',
  'evaluation.settings.readMore': 'Lisätietoja',
  'evaluation.settings.readMoreIntro': 'Toimittajien arviointia ei ole kytketty päälle.',
  'evaluation.unknownSite': 'Tuntematon työmaa',
  'evaluation': 'arviointi',
  'expertise.pendingTraining': 'Suoritettava koulutus',
  'expertise.pendingTrainings': 'Suoritettavat koulutukset',
  'expertise.removeExpertise': 'Poista osaaminen',
  'expertise.removeExpertiseFailed': 'Osaamisen poistaminen epäonnistui.',
  'expertise.removeHeading': 'Poista osaaminen?',
  'expertise.testFailed': 'Testi hylätty',
  'faultList': 'Vika- ja puutelista',
  'faultLists.addFirst': 'Lisää ensimmäinen vika- ja puutelista',
  'faultLists.addList': 'Lisää lista',
  'faultLists.defaultContractor': 'Vastaava urakoitsija',
  'faultLists.defaultContractorHelp': '<strong>Vastaava urakoitsija</strong> valitaan oletuksena listalle lisättävien poikkeamien urakoitsijaksi.',
  'faultLists.delete.body': '<p>Tehdyt poikkeamamerkinnät säilyvät, mutta jos lista poistetaan, ne eivät enää kuulu mihinkään listaan.</p><p>Haluatko varmasti poistaa listan <strong>{0}</strong>?',
  'faultLists.delete.heading': 'Poista lista?',
  'faultLists.lastSync': 'Tallennettu klo',
  'faultLists.newFaultList': 'Uusi vika- ja puutelista',
  'faultLists.newList': 'Uusi lista',
  'faultLists.noLists': 'Ei vielä listoja',
  'faultLists.reportNotSynced': 'Tallentamatta <b>1</b> poikkeama.',
  'faultLists.reports.all': 'Poikkeamia',
  'faultLists.reports.repaired': 'korjattu',
  'faultLists.reports.waiting': 'odottaa korjausta',
  'faultLists.reportsNotSynced': 'Tallentamatta <b>{0}</b> poikkeamaa.',
  'faultLists.searchByName': 'Etsi nimellä',
  'faultLists.searchOrSelectFaultList': 'Vika- ja puutelista',
  'faultLists.searchResults.many': '{0} vika- ja puutelistaa',
  'faultLists.searchResults.one': '1 vika- ja puutelista',
  'faultLists.syncedJustNow': 'Tallennettu juuri äsken.',
  'faultLists.syncing': 'Tallennetaan...',
  'faultLists.types.acceptanceInspection': 'Vastaanottoluovutus',
  'faultLists.types.other': 'Muu',
  'faultLists.types.selfInspection': 'Itselleluovutus',
  'faultLists': 'Vika- ja puutelistat',
  'feedback.continue': 'Jatka Zeroniin',
  'file.abortAndContinue': 'Keskeytä siirto ja poistu tältä sivulta',
  'file.fileName': 'Tiedoston nimi',
  'file.showFile': 'Näytä tiedosto',
  'file.size': 'Koko',
  'file.uploadCancelInfo': 'Olet poistumassa sivulta, joka edelleen siirtää tiedostoja. Jos jatkat, tiedostojen siirto keskeytetään.',
  'file.uploadInProgress': 'Tiedoston siirto kesken...',
  'files': 'Tiedostot',
  'fileUploadFailed': 'Tiedoston lähettäminen epäonnistui. Ole hyvä ja yritä uudestaan tai ota tukeen yhteyttä.',
  'footer.call': 'Soita',
  'footer.or': 'tai',
  'footer.privacyPolicy': '<a target=\'_blank\' rel=\'noopener\' href=\'https://www.zeroni.fi/tietosuojaseloste-2/\'>Tietosuojaseloste</a>',
  'footer.sendMessage': 'Lähetä viesti',
  'footer.support': 'Tuki:',
  'foreman.removeContractForeman': 'Poista ensisijainen työnjohtaja',
  'foremanNotSelected': 'Työnjohtajaa ei ole valittu sopimukselle',
  'foremen.activeEmployees': 'Aktiiviset työntekijät',
  'foremen.chooseForemen': 'Valitse työnjohtajat',
  'foremen.chooseForemenInfo': 'Valitse työnjohtajat urakoitsijalle <strong>{0}</strong>. Merkintä tallennetaan henkilön työmaalupaan.',
  'foremen.contractForeman': 'Ensisijainen työnjohtaja',
  'foremen.deputyForeman': 'Varatyönjohtaja',
  'foremen.employeesNotFound': 'Sopimuksella ei ole työntekijöitä, joilla on voimassaoleva työmaalupa.',
  'foremen.foreman': 'Työnjohtaja',
  'foremen.foremen': 'Työnjohtajat',
  'foremen.noContractForemanInfo': 'Sopimuksen ensisijaista työnjohtajaa ei ole valittu. Voit määrittää työnjohtajan henkilölistasta.',
  'foremen.notForeman': 'Ei työnjohtaja',
  'foremen.selectAsContractForeman': 'Merkitään henkilö työnjohtajaksi sopimukselle',
  'foremen.skipContractForemanRequirement': 'Jos sopimuksella ei käytetä työnjohtajaa, klikkaa tästä.',
  'frontPage': 'Etusivu',
  'graph.active_employees_count': 'Aktiiviset työntekijät',
  'graph.activeCompanies': 'Aktiiviset yritykset',
  'graph.activeContracts': 'Aktiiviset sopimukset',
  'graph.activeOwnContractsCompanies': '{0}:n sopimusyritykset',
  'graph.chainedcontractor': 'Aliurakoitsijat',
  'graph.chainedcontractors': 'Aliurakoitsijat',
  'graph.companiesByPostcode': 'Yritysten määrä',
  'graph.contractpartners': 'Sopimuskumppanit',
  'graph.contractsByLevel': 'Sopimukset tyypeittäin',
  'graph.contractsValueByPostcode': 'Sopimusten arvo',
  'graph.eperehdytys_count': 'E-vahvistetut',
  'graph.eperehdytysCount': 'E-vahvistus',
  'graph.finnish': 'Suomalaiset',
  'graph.firstLevelSubcontracts': '1. tason ketjutetut',
  'graph.foreign': 'Ulkomaalaiset',
  'graph.graphsWillBeShownAfterSiteStarted': 'Kaaviot näytetään työmaan alkamisen jälkeen.',
  'graph.maincontractor': 'Pääurakoitsija',
  'graph.mainContracts': 'Pääurakointisopimus',
  'graph.nationalities': 'Kansalaisuudet',
  'graph.newcomerTrainings': 'Tulokoulutukset',
  'graph.noGraphsSelectSite': 'Kaaviot ovat työmaakohtaisia. Valitse työmaa nähdäksesi kaavioita.',
  'graph.noGraphsYet': 'Ei vielä kaavioita.',
  'graph.numberOfActiveEmployees': 'Työntekijöiden määrä',
  'graph.otherCompanies': 'Muut yritykset',
  'graph.partnerContracts': 'Sopimuskumppanit',
  'graph.permitsByCompanies': 'Työmaaluvat yrityksittäin',
  'graph.secondLevelSubcontracts': '2. tason ketjutetut',
  'graph.sidecontractors': 'Sivu-urakoitsijat',
  'graph.sideContracts': 'Sivu-urakointisopimukset',
  'graph.sitesContractsbyLevel': 'Sopimusten tyypit',
  'graph.siteStarts': 'Työmaa on merkitty alkavaksi {0}.',
  'graph.strength': 'Henkilövahvuus',
  'graph.thirdOrMoreLevelSubcontracts': '3+ tason ketjutetut',
  'graph.workHours': 'Työtunnit',
  'graph': 'Kaavio',
  'graphs': 'Kaaviot',
  'help.addToVendorRegister': 'Voit lisätä yrityksen ilman sopimuksen luomista',
  'help.closeQuickTours': 'Suljetaanko pikaohjeet?',
  'help.dontShowQuickToursAgain': 'Älä näytä pikaohjeita uudestaan',
  'help.helpForAdmin': 'Ohjeet pääkäyttäjälle',
  'help.helpForSubcontractor': 'Ohjeet urakoitsijalle',
  'help.quickTours': 'Pikaohjeet',
  'hotWork.filename': 'tulityolupa',
  'hotWork.guardingTimeAfterWork.hours': 'tuntia',
  'hotWork.guardingTimeAfterWork': 'Työn jälkeen',
  'hotWorkPermit.addHotWorkPermit': 'Lisää tulityölupa',
  'hotWorkPermit.addPermit': 'Lisää lupa',
  'hotWorkPermit.address': 'Tulityöpaikan osoite',
  'hotWorkPermit.addressPlaceHolder': 'Osoite',
  'hotWorkPermit.approver': 'Luvan myöntäjä',
  'hotWorkPermit.approverSignatureAndClarification': 'Myöntäjän allekirjoitus ja nimenselvennys',
  'hotWorkPermit.backToHotWorkPermits': 'Takaisin tulityölupiin',
  'hotWorkPermit.basicInfoHeader': 'Tulityö, suunnitelma ja kohde',
  'hotWorkPermit.cannotAddNew.noPermission': 'Et voi luoda uusia tulityölupia koska sinulla ei ole oikeuksia.',
  'hotWorkPermit.cannotAddNew': 'Et voi luoda uutta tulityölupaa, koska työntekijältä puuttuu tulityöhön vaadittava pätevyys, tulityön pätevyys on vanhentunut tai henkilöllä ei ole voimassaolevaa työmaalupaa.',
  'hotWorkPermit.careTaker': 'Paloilmoittimen/sprinklerilaitteiston hoitaja',
  'hotWorkPermit.client.contact': 'Yhteyshenkilö ja puhelinnumero',
  'hotWorkPermit.client': 'Tulityön tilaaja',
  'hotWorkPermit.companyOrCommunity': 'Yritys / yhteisö',
  'hotWorkPermit.companyOrContractor': 'Yritys / urakoitsija',
  'hotWorkPermit.contact': 'Tulityön tilaajan yhteyshenkilö',
  'hotWorkPermit.contractor': 'Urakoitsija',
  'hotWorkPermit.contractorHasNoForemen': 'Urakoitsijoilla ei ole työnjohtajia valittavana luvan haltijoiksi.',
  'hotWorkPermit.contractorsPlan': 'urakoitsijan',
  'hotWorkPermit.dangerAssessment.section.1': 'Vaihtoehtoiset työmenetelmät on arvioitu ennen tulityöluvan myöntämistä.',
  'hotWorkPermit.dangerAssessment.section.10': 'Tulityö tehdään korkealla, jolloin kipinät ja roiskeet voivat levitä laajalle alueelle.',
  'hotWorkPermit.dangerAssessment.section.11': 'Tulityöpaikalla on ilmastointilaitteita ja ilman sisäänottopaikkoja.',
  'hotWorkPermit.dangerAssessment.section.12': 'Tulityönä on laikkaleikkaus tai -hionta, jolloin kipinät aiheuttavat vaaraa 10 metrin etäisyydellä tulityöpaikasta.',
  'hotWorkPermit.dangerAssessment.section.13': 'Tulityönä on polttoleikkaus, jolloin roiskeet voivat kulkeutua laajalle alueelle.',
  'hotWorkPermit.dangerAssessment.section.14': 'Tulityössä syntyvää lämpöä voi johtua seinä-, katto- tai lattiarakenteeseen.',
  'hotWorkPermit.dangerAssessment.section.2': 'Tulityöpaikalla on syttyvää pölyä tai hienojakoista ainetta tai syttyviä roskia.',
  'hotWorkPermit.dangerAssessment.section.3': 'Tulityöpaikalla on syttyviä materiaaleja.',
  'hotWorkPermit.dangerAssessment.section.4': 'Tulityöpaikkaa lähellä olevan seinä-, katto- tai lattiarakenteen pinta on syttyvää.',
  'hotWorkPermit.dangerAssessment.section.5': 'Tulityöpaikan lähellä olevat seinä-, katto- tai lattiarakenteet sisältävät syttyviä materiaaleja.',
  'hotWorkPermit.dangerAssessment.section.6': 'Tulityöpaikalla on kaapeleita tai kaapelihyllyjä.',
  'hotWorkPermit.dangerAssessment.section.7': 'Tulityön kohde sisältää materiaaleja, jotka voivat syttyä.',
  'hotWorkPermit.dangerAssessment.section.8': 'Tulityöpaikalla on rakoja, aukkoja, ontelotiloja tai tuuletusvälejä, joiden kautta liekit, kipinät tai roiskeet voivat päästä rakenteisiin tai viereiseen tilaan',
  'hotWorkPermit.dangerAssessment.section.9': 'Tulityöpaikalla on tai sinne voi muodostua syttyviä kaasuja tai höyryjä.',
  'hotWorkPermit.dangerAssessment.section.otherDangers': 'Muut vaarat:',
  'hotWorkPermit.developer': 'Tilaaja',
  'hotWorkPermit.developersPlan': 'tilaajan',
  'hotWorkPermit.duration': 'Voimassaoloaika',
  'hotWorkPermit.emergencyNumber': 'Hätänumero',
  'hotWorkPermit.employer': 'Yritys',
  'hotWorkPermit.equipmentsAndGuarding': 'Sammutuskalusto, vartiointi',
  'hotWorkPermit.extinguishersAcquiredBy': 'Tulityöpaikalla tarvittavan sammutuskaluston hankkii',
  'hotWorkPermit.extinguishingEquipments.equipment.1': 'Käsisammutin 55A 233B C',
  'hotWorkPermit.extinguishingEquipments.equipment.10': 'Erityissammutuskalusto',
  'hotWorkPermit.extinguishingEquipments.equipment.11': 'Käsisammutin 43A 233B C',
  'hotWorkPermit.extinguishingEquipments.equipment.2': 'Käsisammutin 43A 183B C',
  'hotWorkPermit.extinguishingEquipments.equipment.3': 'Käsisammutin 27A 144B C',
  'hotWorkPermit.extinguishingEquipments.equipment.4': 'CO2 sammutin',
  'hotWorkPermit.extinguishingEquipments.equipment.5': 'Pikapaloposti',
  'hotWorkPermit.extinguishingEquipments.equipment.6': 'Paineellinen paloletku',
  'hotWorkPermit.extinguishingEquipments.equipment.7': 'Sankoruisku',
  'hotWorkPermit.extinguishingEquipments.equipment.8': 'Sammutuspeite',
  'hotWorkPermit.extinguishingEquipments.equipment.9': 'Raivauskalusto',
  'hotWorkPermit.extinguishingEquipments': 'Sammutuskalusto',
  'hotWorkPermit.fireAlarmAndSprinklerPolicyInfo': 'Paloilmoitin on tarvittaessa irtikytkettävä tulityön ajaksi. Kytkennät saa tehdä vain laitteiston hoitaja\. Sprinklerilaitteistoa ei kytketä pois päältä. Tarvittaessa sprinklerisuuttimet suojataan tulityön ajaksi\. Suojauksesta on sovittava laitteiston hoitajan kanssa\.',
  'hotWorkPermit.fireExtinguisherRequirements': 'Tulitöissä vaadittava sammutuskalusto',
  'hotWorkPermit.guarding.duringWork': 'Työn ja työtaukojen aikana.',
  'hotWorkPermit.guarding': 'Tulityön vartiointi',
  'hotWorkPermit.hazards': 'Tulityöstä aiheutuvien vaarojen selvitys ja arviointi',
  'hotWorkPermit.hotWork.description': 'Työ, suunnitelma, kohde',
  'hotWorkPermit.hotWork.listOfSafetyRisks': 'Vaarojen selvitys, arviointi',
  'hotWorkPermit.hotWorkFollowsHotWorkPlanOfPart1': 'Työssä noudatetaan ',
  'hotWorkPermit.hotWorkFollowsHotWorkPlanOfPart2': 'tulityösuunnitelmaa',
  'hotWorkPermit.hotWorkGuard': 'Tulityövartija',
  'hotWorkPermit.hotWorkGuarding': 'Tulityövartiointi',
  'hotWorkPermit.hotWorkPermitApprover': 'Tulityöluvan myöntäjä',
  'hotWorkPermit.hotWorkSecurityMeasures': 'Tulityön turvatoimet',
  'hotWorkPermit.hotWorkType.bitumenCooker': 'Bitumikeittimen käyttö',
  'hotWorkPermit.hotWorkType.discCutting': 'Laikkaleikkaus/-hionta',
  'hotWorkPermit.hotWorkType.electricWelding': 'Sähköhitsaus',
  'hotWorkPermit.hotWorkType.flameCutting': 'Polttoleikkaus',
  'hotWorkPermit.hotWorkType.gasSolderingWelding': 'Kaasujuottaminen/-hitsaus',
  'hotWorkPermit.hotWorkType.hotAirBlower': 'Kuumailmapuhaltimen käyttö',
  'hotWorkPermit.hotWorkType.lpgTorch': 'Nestekaasupolttimen käyttö',
  'hotWorkPermit.hotWorkType.otherHotWork': 'Muu, mikä',
  'hotWorkPermit.hotWorkType': 'Tulityö',
  'hotWorkPermit.infoAboutExtinguisherPolicy.part1': 'Tulityöluvan myöntäjä määrää tulityöpaikalla tarvittavan ja soveltuvan sammutuskaluston tulityöstä aiheutuvien vaarojen selvityksen ja arvioinnin perusteella\. Yleensä (kattotulitöissä aina) oltava vähintään kaksi 43A 183B C -teholuokan käsisammutinta.',
  'hotWorkPermit.infoAboutExtinguisherPolicy.part2': 'Teholuokat ovat siis minimivaatimuksia ja käytännössä etenkin teollisuudessa on yrityskohtaisia vaatimuksia korkeamman teholuokan sammuttimelle esim. 55A233BC.',
  'hotWorkPermit.infoAboutExtinguisherPolicy.part3': 'Vakuutetuissa kohteissa voi lisäksi olla vakuutussopimuskohtaisia tulitöiden turvallisuusvaatimuksia.',
  'hotWorkPermit.maximumDurationExceeded': 'Luvan kesto ylittää sallitun maksimikeston({0} päivää)',
  'hotWorkPermit.missingHotWorkGuards': 'Ei tulityövartijoita valittavana',
  'hotWorkPermit.missingSelectedEmployees': 'Tulityöntekijöitä ei löydy',
  'hotWorkPermit.name': 'Nimi',
  'hotWorkPermit.nameOfSiteBuildingDepartmentRegion': 'Yritys / rakennus / osasto / alue',
  'hotWorkPermit.nameOfSiteBuildingDepartmentRegionPlaceHolder': 'Yrityksen, rakennuksen, osaston ja/tai alueen nimi',
  'hotWorkPermit.newPermit': 'Uusi tulityölupa',
  'hotWorkPermit.nextStep': 'Seuraava vaihe',
  'hotWorkPermit.noActivePermits': 'Ei aktiivisia tulityölupia',
  'hotWorkPermit.noDangerOccurs': 'vaaraa ei esiinny',
  'hotWorkPermit.noPermitsHaveBeenAdded': 'Yhtään tulityölupaa ei ole vielä lisätty.',
  'hotWorkPermit.ownerSignatureAndClarification': 'Vastaanottajan allekirjoitus ja nimenselvennys',
  'hotWorkPermit.periodAdjustmentDisabled': 'Työntekijä ja/tai tulityön vartija on valittu, jolloin tulityöluvan voimassaoloaikaa ei voi muuttaa.',
  'hotWorkPermit.permitApproverHasCheckedValidationPeriod': 'Tulityöluvan myöntäjä on tarkastanut tulityökortin voimassaoloajan kaikilta työntekijöiltä',
  'hotWorkPermit.permitCannotBeCreated': 'Tulityölupaa ei pysty luomaan.',
  'hotWorkPermit.phoneNumber': 'Puhelinnumero',
  'hotWorkPermit.plan': 'Tulityösuunnitelma',
  'hotWorkPermit.roofPermit': 'Kattotulityölupa',
  'hotWorkPermit.scopeId': 'Tilaaja',
  'hotWorkPermit.securityMeasures.section.1': 'Tulityöpaikka on puhdistettava',
  'hotWorkPermit.securityMeasures.section.10': 'Kaasupitoisuus on mitattava ja tila tarvittaessa tuuletettava.',
  'hotWorkPermit.securityMeasures.section.11': 'Ympäröiviä tiloja on vartioitava.',
  'hotWorkPermit.securityMeasures.section.12': 'Muut tarvittavat turvatoimet:',
  'hotWorkPermit.securityMeasures.section.2': 'Työn aikana syntyvä syttyvä materiaali on poistettava.',
  'hotWorkPermit.securityMeasures.section.3': 'Syttyvät materiaalit on siirrettävä pois tulityöpaikalta tai suojapeitettävä.',
  'hotWorkPermit.securityMeasures.section.4': 'Suojaukset on tehtävä siten, etteivät liekit, kipinät ja roiskeet pääse leviämään ympäristöön.',
  'hotWorkPermit.securityMeasures.section.5': 'On tehtävä erillinen suojarakenne.',
  'hotWorkPermit.securityMeasures.section.6': 'Seinissä, katossa ja lattiassa olevat raot ja aukot on suojattava.',
  'hotWorkPermit.securityMeasures.section.7': 'Kaapelit, kaapelihyllyt, koneet, laitteet, syttyvät rakenteet yms. on suojattava.',
  'hotWorkPermit.securityMeasures.section.8': 'Työpaikka on kasteltava.',
  'hotWorkPermit.securityMeasures.section.9': 'Työkohdetta on jäähdytettävä jatkuvasti.',
  'hotWorkPermit.securityMeasures': 'Turvatoimet',
  'hotWorkPermit.securityMeasuresNeeded': 'turvatoimia tarvitaan',
  'hotWorkPermit.selectCompanyFirst': 'Valitse ensin yritys',
  'hotWorkPermit.showPdf': 'Avaa PDF',
  'hotWorkPermit.signatureClearNameAndPhoneNumber': 'Allekirjoitus ja nimenselvennys sekä puhelinnumero',
  'hotWorkPermit.signatures': 'Allekirjoitukset',
  'hotWorkPermit.signedForm': 'Allekirjoitettu lomake',
  'hotWorkPermit.site': 'Työkohde',
  'hotWorkPermit.subscriber': 'Tulityön tekijät ja voimassaoloaika, tilaaja ja myöntäjä',
  'hotWorkPermit.validDaily': 'Voimassa päivittäin',
  'hotWorkPermit.workers': 'Tulityöntekijät',
  'hotWorkPermit.workersAndDuration': 'Tekijät, voimassaoloaika',
  'hotWorkPermit': 'Tulityölupa',
  'hotWorkPermits': 'Tulityöluvat',
  'introduction.competences.addOther': 'Lisää muu',
  'introduction.competences.addPopular': 'Lisää yleinen pätevyys',
  'introduction.competences.existing': 'Nykyiset',
  'introduction.competences.failedToLoadCompetences': 'Pätevyyksien lataaminen epäonnistui.',
  'introduction.competences.failedToLoadPopular': 'Yleisten pätevyyksien lataaminen epäonnistui.',
  'introduction.expertise': 'Osaaminen',
  'invoiceHourRow.reject': 'Hylkää',
  'invoiceHours.activeInvoiceHours': 'Käytössä olevat tuntilaput',
  'invoiceHours.alert1': 'Hälytys 1',
  'invoiceHours.alert1Short': 'Häl.1',
  'invoiceHours.alert2': 'Hälytys 2',
  'invoiceHours.alert2Short': 'Häl.2',
  'invoiceHours.allPendingApproval': 'Kaikki hyväksyntää odottavat',
  'invoiceHours.approved': 'Hyväksytty',
  'invoiceHours.approvedOn': 'Hyväksytty {0}',
  'invoiceHours.approver': 'Hyväksyjä',
  'invoiceHours.badgeTooltip.addedByEmployee': 'Työntekijän lisäämä',
  'invoiceHours.badgeTooltip.approved': 'Hyväksytty',
  'invoiceHours.badgeTooltip.approvedSes': 'Hyväksytty SES työ',
  'invoiceHours.badgeTooltip.created': 'Ei vielä lähetetty hyväksyttäväksi',
  'invoiceHours.badgeTooltip.invoiced': 'Hyväksytty ja Yhteenvedolla',
  'invoiceHours.badgeTooltip.invoicedSes': 'Hyväksytty ja SES tilitetty',
  'invoiceHours.badgeTooltip.pending': 'Odottaa hyväksyntää',
  'invoiceHours.badgeTooltip.rejected': 'Hylätty',
  'invoiceHours.buyersReference': 'Ostotilaus',
  'invoiceHours.buyersReferenceNumber': 'Ostotilausnumero',
  'invoiceHours.changeApprover': 'Vaihda hyväksyjää',
  'invoiceHours.checkedInButNoInvoiceHours': 'Henkilöllä on leimauksia, mutta tällä tuntilappurivillä ei ole tunteja.',
  'invoiceHours.checkInsWithoutInvoiceHours': 'Leimauksia, mutta ei tunteja tuntilapussa',
  'invoiceHours.confirmYourRowDeletion': 'Haluatko varmasti poistaa tuntirivin?',
  'invoiceHours.contractorNameAndInvoiceHourSummaryId': 'Toimittaja tai yhteenvedon tunniste',
  'invoiceHours.contractorNameAndInvoiceHourSummaryIdAndInvoiceNumber': 'Toimittaja, yhteenvedon tunniste tai laskun numero',
  'invoiceHours.contractors': 'Urakoitsijat',
  'invoiceHours.contractorsReference': 'Toimittajan viite',
  'invoiceHours.contractorsSummaries': 'Toimittajan laskujen yhteenvedot',
  'invoiceHours.copy': 'Kopio',
  'invoiceHours.copyDay.cannotBeCopiedToFuture': 'Ei kopioitavissa tulevaisuuteen',
  'invoiceHours.copyDay.copyMultipleRows': 'Kopioi {0} riviä',
  'invoiceHours.copyDay.copyOneRow': 'Kopioi 1 rivi',
  'invoiceHours.copyDay.copyRowsFromAnotherDay': 'Kopioi rivejä toiselta päivältä',
  'invoiceHours.copyDay.futureError': 'Varmista että kaikki valitut merkinnät ovat kopioitavissa tulevaisuuteen.',
  'invoiceHours.copyDay.noRowsOnSelectedDay': 'Valitulla päivällä ei ole merkintöjä.',
  'invoiceHours.copyDay.noSourceDataAvailable': 'Henkilöllä ei vielä ole tässä hyödynnettäviä tuntilappurivejä.',
  'invoiceHours.copyDay.okCloseCopyDialog': 'Selvä, sulje kopiointi',
  'invoiceHours.copyDay.selectDayFirst': 'Valitse ensin päivä.',
  'invoiceHours.copyDay.selectRowsToBeCopied': 'Valitse kopioitavat rivit',
  'invoiceHours.copyDay.targetDay': 'Päivä, jolle rivit kopioidaan',
  'invoiceHours.copyDay.targetDays': 'Päivät, joille rivit kopioidaan',
  'invoiceHours.copyDay.toMultipleDays': 'Tee kopio uselle päivälle',
  'invoiceHours.copyDay': 'Kopioitava päivä',
  'invoiceHours.copyJobNumber.ownVariant': 'Kopioi littera',
  'invoiceHours.copyJobNumber': 'Kopioi työnumero',
  'invoiceHours.copyToGroup': 'Kopioi tiedot ryhmälle',
  'invoiceHours.created': 'Luontipäivä',
  'invoiceHours.currentWeekIs': 'Kuluva viikko on vk {0}.',
  'invoiceHours.date': 'Päivä',
  'invoiceHours.dates': 'Päivät',
  'invoiceHours.deleteRowHeading': 'Poistetaanko rivi?',
  'invoiceHours.deleteSelectionsBody': 'Haluatko varmasti hylätä valitut? Vahvista hylkäys syöttämällä hylkäyksen syy.',
  'invoiceHours.deleteSelectionsHeading': 'Hylkää valitut?',
  'invoiceHours.draftOnly': '<strong>Tämä on yhteenvedon luonnos</strong>, ei lopullinen yhteenveto.',
  'invoiceHours.employee.summary': 'Tuntilappuhistoria',
  'invoiceHours.enableInvoiceHours': 'Tuntilappu käytössä',
  'invoiceHours.followingDaysHaveMoreHoursThanCheckIns': 'Kopioitavaa työtä on {0} h. Seuraavilla päivillä on leimauksia sen alle.',
  'invoiceHours.fullPerDiem': 'Kokopäiväraha',
  'invoiceHours.grandTotalForJobNumber.ownVariant': 'Litteran kertymä',
  'invoiceHours.grandTotalForJobNumber': 'Työnumeron kertymä',
  'invoiceHours.grandTotalForJobNumberContract.ownVariant': 'Litteran kertymä (sopimus)',
  'invoiceHours.grandTotalForJobNumberContract': 'Työnumeron kertymä (sopimus)',
  'invoiceHours.grandTotalForJobNumberDescriptionContract.ownVariant': 'Litteran ja hyväksyjän mukaiset hyväksyttyjen tuntien kertymät koko työmaan ajalta. Laskettu kaikilta sopimuksen työntekijöiltä.',
  'invoiceHours.grandTotalForJobNumberDescriptionContract': 'Työnumeron ja hyväksyjän mukaiset hyväksyttyjen tuntien kertymät koko työmaan ajalta. Laskettu kaikilta sopimuksen työntekijöiltä.',
  'invoiceHours.grandTotalForJobNumberDescriptionEmployee.ownVariant': 'Työntekijän, litteran ja hyväksyjän mukaiset hyväksyttyjen tuntien kertymät koko työmaan ajalta',
  'invoiceHours.grandTotalForJobNumberDescriptionEmployee': 'Työntekijän, työnumeron ja hyväksyjän mukaiset hyväksyttyjen tuntien kertymät koko työmaan ajalta',
  'invoiceHours.grandTotalForJobNumberEmployee.ownVariant': 'Litteran kertymä (työntekijä)',
  'invoiceHours.grandTotalForJobNumberEmployee': 'Työnumeron kertymä (työntekijä)',
  'invoiceHours.group': 'Ryhmä',
  'invoiceHours.handler': 'Käsittelijä',
  'invoiceHours.hasEmployeesInvoiceHours': 'Työntekijöiden lisäämiä täydentämättömiä tuntilappumerkintöjä',
  'invoiceHours.hasEmployeesInvoiceHoursShort': 'Täydentämättömiä tuntilappumerkintöjä',
  'invoiceHours.hourBank.balance': 'Saldo',
  'invoiceHours.hourBank': 'Työaikapankki',
  'invoiceHours.hours': 'Tunnit',
  'invoiceHours.hoursCheckedIn': 'Leimausaika',
  'invoiceHours.hoursOnAllSites': 'Tunteja kaikilla työmailla',
  'invoiceHours.hoursOnAllSitesDescription': 'Laskettu kaikilta työmailta, joihin sinulla on näkyvyys.',
  'invoiceHours.hoursOnThisRow': 'Tunteja tällä rivillä',
  'invoiceHours.idle': 'Varalla',
  'invoiceHours.idleShort': 'Var.',
  'invoiceHours.includeSubContractors': 'Myös aliurakoitsijat',
  'invoiceHours.initContractApproval': 'Hyväksy rivejä...',
  'invoiceHours.invoicing': 'Laskutus',
  'invoiceHours.jobNumber.ownVariant': 'Littera',
  'invoiceHours.jobNumber': 'Työnumero',
  'invoiceHours.jobNumberTotals.ownVariant': 'Litterakohtaiset summat',
  'invoiceHours.jobNumberTotals': 'Työnumerokohtaiset summat',
  'invoiceHours.loadingCheckIns': 'Ladataan leimauksia...',
  'invoiceHours.lockAndCreate': 'Lukitse ja luo yhteenveto',
  'invoiceHours.lockedBecauseHoursFromOtherSummaries': 'Lukitseminen estetty, koska yhteenvetoon pyydettiin mukaan tunteja muista yhteenvedoista.',
  'invoiceHours.lockedBecausePendingHours': 'Lukitseminen estetty, koska yhteenvetoon pyydettiin hyväksyntää odottavat tunnit.',
  'invoiceHours.lockFailed': 'Lukitus epäonnistui. Valitsemallesi ajalle on joko lisätty uusia merkintöjä tai uusi esikatselu.',
  'invoiceHours.locking': 'Lukitaan...',
  'invoiceHours.modifyRows': 'Muokkaa rivejä...',
  'invoiceHours.moreHoursDecisions.copyHoursAccordingToCheckIns': 'Kopioi työtä leimausten verran',
  'invoiceHours.moreHoursDecisions.enterReasonForExtraHours': 'Syötä selvitys leimaukset ylittävälle työlle',
  'invoiceHours.moreHoursThanCheckIns': 'Kopioitavaa työtä on enemmän kuin leimattua',
  'invoiceHours.new': 'Uusi tuntilappu',
  'invoiceHours.newRow': 'Uusi rivi',
  'invoiceHours.newSummary': 'Uusi yhteenveto laskulle',
  'invoiceHours.newSummaryForInvoice': 'Uusi yhteenveto laskulle',
  'invoiceHours.noEmployeeRows': 'Ei vielä merkintöjä tällä päivällä',
  'invoiceHours.noInvoiceHours': 'Tuntilappuja ei ole vielä lisätty.',
  'invoiceHours.noLockedInvoiceHoursFound': 'Pyysit mukaan myös muissa yhteenvedoissa olevat tunnit, mutta niitä ei löytynyt annetuilla hakuehdoilla.',
  'invoiceHours.nonWorkDescription': 'Selite',
  'invoiceHours.noOtherGroupMembersAvailable': 'Muita ryhmän jäseniä ei valittavissa',
  'invoiceHours.noPendingInvoiceHoursFound': 'Pyysit mukaan myös hyväksyntää odottavat tunnit, mutta niitä ei löytynyt annetuilla hakuehdoilla.',
  'invoiceHours.noPendingRows': 'Ei hyväksyntää odottavia merkintöjä.',
  'invoiceHours.noPerDiem': 'Ei päivärahaa',
  'invoiceHours.noPermits': 'Hakuehdoilla ei löytynyt työntekijöitä',
  'invoiceHours.noSitesWithInvoiceHours': 'Valittavana ei ole työmaita, joihin voisit täyttää tuntilappuja.',
  'invoiceHours.noSummaries': 'Ei yhteenvetoja',
  'invoiceHours.notEnabled': 'Tuntilappu ei käytössä',
  'invoiceHours.onlyApprovablesForDate': 'Vain hyväksyttävät',
  'invoiceHours.onSummary': 'Yhteenvedolla',
  'invoiceHours.otherGroupMembers': 'Muut ryhmän jäsenet',
  'invoiceHours.otherTimeSpan': 'Muu aikaväli',
  'invoiceHours.overtimeReason': 'Selvitys leimaukset ylittävälle työlle',
  'invoiceHours.own': 'Omat',
  'invoiceHours.partialPerDiem': 'Osapäiväraha',
  'invoiceHours.payGrade': 'Palkkalaji',
  'invoiceHours.payGradeTotals': 'Palkkalajikohtaiset summat',
  'invoiceHours.pend': 'Vapauta',
  'invoiceHours.pending': 'Odottaa hyväksyntää',
  'invoiceHours.pendQuestion': 'Vapautetaanko rivi?',
  'invoiceHours.perDiem': 'Matkakorvaus',
  'invoiceHours.perDiemAbbr': 'Matk.',
  'invoiceHours.permitNotActive': 'Työmaalupa ei ole voimassa, uusia rivejä ei voi lisätä.',
  'invoiceHours.permitNotActiveInfo': 'Tämä työmaalupa on voimassa <strong>{0}</strong>. Jos haluat kirjata tälle päivälle tunteja tai matkakorvauksia, muokkaa luvan pituutta tai luo uusi lupa.',
  'invoiceHours.preview': 'Esikatsele',
  'invoiceHours.reasonForRejection': 'Hylkäyksen syy',
  'invoiceHours.reasonToReject': 'Syy hylkäykselle',
  'invoiceHours.receiver': 'Yhteenvedon vastaanottaja',
  'invoiceHours.refreshPreview': 'Päivitä esikatselu',
  'invoiceHours.refreshWithoutOtherHours': 'Hae tiedot ilman muiden yhteenvetojen tunteja',
  'invoiceHours.refreshWithoutPending': 'Hae tiedot ilman hyväksyntää odottavia tunteja',
  'invoiceHours.reject': 'Hylkää',
  'invoiceHours.rejected': 'Hylätty',
  'invoiceHours.rejecting': 'Hylätään...',
  'invoiceHours.requestConfirmationForDeletion': 'Olet poistamassa tunteja henkilöltä <strong>{0}</strong>. Haluatko varmasti poistaa tuntirivin?',
  'invoiceHours.requestConfirmationForPending': 'Olet vapauttamassa henkilön <strong>{0}</strong> tuntilappurivin muokattavaksi. Haluatko varmasti vapauttaa rivin?',
  'invoiceHours.requestProducedNoHours': 'Hakuehdoilla ei löytynyt tunteja.',
  'invoiceHours.rest': 'Lepo',
  'invoiceHours.revision': 'Revisio',
  'invoiceHours.rowsPendingApproval': 'Hyväksyntää odottavia merkintöjä',
  'invoiceHours.salesOrderItem': 'Rivi',
  'invoiceHours.salesOrderNumber': 'Myyntitosite',
  'invoiceHours.saveAndApprove': 'Tallenna ja hyväksy',
  'invoiceHours.searchOrSelectApproverPlaceholder': 'Hyväksyjä',
  'invoiceHours.searchOrSelectCompanyPlaceholder': 'Yritys',
  'invoiceHours.searchOrSelectContractPlaceholder': 'Sopimus',
  'invoiceHours.searchOrSelectEmployeePlaceholder': 'Työntekijä',
  'invoiceHours.searchOrSelectGroupMembers': 'Muut ryhmän jäsenet',
  'invoiceHours.searchOrSelectJobNumberPlaceholder': 'Työnumero',
  'invoiceHours.searchOrSelectPayGradePlaceholder': 'Palkkalaji',
  'invoiceHours.searchOrSelectPersonPlaceholder': 'Henkilö',
  'invoiceHours.searchOrSelectServicePlaceholder': 'Palvelu',
  'invoiceHours.selectDay': 'Valitse päivä',
  'invoiceHours.selectReceivingCompanyPlaceholder': 'Valitse yhteenvedon vastaanottaja',
  'invoiceHours.selectTimeSpan': 'Valitse aikaväli',
  'invoiceHours.sendAsPending': 'Lähetä hyväksyttäväksi',
  'invoiceHours.service': 'Palvelu',
  'invoiceHours.serviceNotUsableForFuture': 'Käyttö estetty tulevaisuuteen lisättäville riveille',
  'invoiceHours.serviceOrder.approved': 'Hyväksytty',
  'invoiceHours.serviceOrder.ordered': 'Tilattu',
  'invoiceHours.serviceOrder.service': 'Palvelu',
  'invoiceHours.serviceOrderTotal': 'Palvelutilauksen kertymä',
  'invoiceHours.showGrandTotalForJobNumber': 'Näytä työnumeron kertymä',
  'invoiceHours.showLocked': 'Myös muiden yhteenvetojen tunnit',
  'invoiceHours.shownEmployees.all': 'Kaikki työntekijät',
  'invoiceHours.shownEmployees.own': 'Omat työntekijät',
  'invoiceHours.shownEmployees.subcontractor': 'Aliurakoitsijan työntekijät',
  'invoiceHours.showPending': 'Myös hyväksyntää odottavat tunnit',
  'invoiceHours.statusType.addedByEmployee': 'Työntekijän lisäämät',
  'invoiceHours.statusType.addedByEmployeeSingular': 'Työntekijän lisäämä',
  'invoiceHours.statusType.all': 'Kaikki tuntilappumerkinnät',
  'invoiceHours.statusType.approved': 'Hyväksyttyjä tuntilappumerkintöjä',
  'invoiceHours.statusType.pending': 'Hyväksyntää odottavat',
  'invoiceHours.summaries': 'Laskujen yhteenvedot',
  'invoiceHours.summary': 'Yhteenvedot',
  'invoiceHours.summaryIncludesLockedInvoiceHours': 'Henkilön tunnit sisältävät merkintöjä muista yhteenvedoista.',
  'invoiceHours.summaryIncludesPendingInvoiceHours': 'Henkilön tunnit sisältävät hyväksymättömiä tunteja.',
  'invoiceHours.tempSave': 'Tallenna, älä lähetä',
  'invoiceHours.totalHours': 'Tunnit yhteensä',
  'invoiceHours.travelTime': 'Matka-aika',
  'invoiceHours.travelTimeShort': 'Matk.',
  'invoiceHours.workDescription': 'Tehty työ',
  'invoiceHours.workRecorded': 'Työtä leimattu',
  'invoiceHours': 'Tuntilappu',
  'jobNumber.mostUsed': 'Eniten käytetyt',
  'label._foremanChecked': 'Työnjohtaja',
  'label.a1CertificateExpired.info': 'Todistus sosiaaliturvasta päättynyt. Todistuksen päättyminen voi vaikuttaa kulkuoikeuksiin työmaalla.',
  'label.a1CertificateExpired': 'Todistus sosiaaliturvasta päättynyt',
  'label.a1CertificateExpiring': 'Todistus sosiaaliturvasta päättymässä',
  'label.a1CertificatesExpiredOrExpiring': '<strong>{0} todistusta sosiaaliturvasta</strong> on päättynyt tai päättymässä 14 päivän sisällä.',
  'label.accessArea': 'Kulkualue',
  'label.accessAreas': 'Kulkualueet',
  'label.accessAreasSaved': 'Kulkualueet tallennettu!',
  'label.accessPermit.workSpaces': 'Työskentelyosasto',
  'label.accessPermit': 'Työmaalupa',
  'label.accessPermitCreator': 'Työmaaluvan luoja',
  'label.accessPermitRejected': 'Päätetty',
  'label.accessPermits.contracts': 'Sopimukset',
  'label.accessPermits': 'Työmaaluvat',
  'label.accessPermitValid': 'Työmaalupa voimassa',
  'label.accessPermitValidationPeriod': 'Uuden työmaaluvan kesto',
  'label.accidentInsurance': 'Tapaturmavakuutus',
  'label.accidentInsuranceLegend': 'Todistus tapaturmavakuutuksen voimassaolosta',
  'label.accomplish': 'Suorita',
  'label.accomplishedOnline': 'Suoritettu verkossa',
  'label.activated': 'Aktivoitu',
  'label.activateReferences': 'Litterat käytössä',
  'label.activeEmployees': 'Aktiiviset',
  'label.activeEmployeesDesc': 'Näytä henkilöt, joilla leimauksia annetulla aikavälillä',
  'label.actualValue': 'Toteutunut summa',
  'label.add': 'Lisää',
  'label.addAccessPermit': 'Lisää työmaalupa',
  'label.addCheckInDevice': 'Lisää läsnäoloseurantalaite',
  'label.addComment': 'Lisää kommentti',
  'label.addCommentOrReimbursment': 'Lisää kommentti / kulukorvaus',
  'label.addCommentToCheckIn': 'Lisää kommentti sisäänleimaukseen',
  'label.addCommentToCheckOut': 'Lisää kommentti ulosleimaukseen',
  'label.addCompany': 'Lisää yritys',
  'label.addCompetence': 'Lisää pätevyys',
  'label.addContract': 'Lisää sopimus',
  'label.addContractAndAccessPermit': 'Lisää sopimus ja työmaalupa',
  'label.addContractor': 'Lisää urakoitsijaksi',
  'label.addContractorObligationDocuments': 'Lisää tilaajavastuudokumentit',
  'label.addDevice': 'Lisää laite',
  'label.addDeviceToSite': 'Lisää laite työmaalle',
  'label.added': 'Lisätty',
  'label.adding': 'Lisätään...',
  'label.addNew': 'Lisää uusi',
  'label.addNewAccessPermit': 'Lisää uusi työmaalupa',
  'label.addNewCompany': 'Lisää uusi yritys',
  'label.addNewCompetence': 'Lisää uusi pätevyys',
  'label.addNewContract': 'Lisää uusi sopimus',
  'label.addNewDevice': 'Lisää uusi laite',
  'label.addNewPerson': 'Lisää uusi henkilö',
  'label.addNewSite': 'Lisää uusi työmaa',
  'label.addNewUser': 'Lisää uusi käyttäjä',
  'label.addPerson': 'Lisää henkilö',
  'label.address': 'Osoite',
  'label.addressInFinland': 'Osoite Suomessa',
  'label.addressInHomeCountry': 'Osoite kotivaltiossa',
  'label.addSafetyQuarter': 'Lisää uusi turvavartti',
  'label.addSite': 'Lisää työmaa',
  'label.addTag': 'Lisää kulkutunniste',
  'label.addToVendorRegister': 'Lisää yritys',
  'label.addTraining': 'Lisää koulutus',
  'label.addUser': 'Lisää käyttäjä',
  'label.addUserGroup': 'Lisää uusi käyttäjärooli',
  'label.addValttiCard': 'Lisää Valttikortti',
  'label.addVehiclePermit': 'Lisää ajoneuvolupa',
  'label.admittance': 'Kulkulupa',
  'label.admittanceRequired': 'Kulkuoikeus työmaalle',
  'label.alarmLackingWeeklyHours': 'Hälytä puuttuvista viikkotunneista',
  'label.all': 'Kaikki',
  'label.allForemen': 'Hae työnjohtajat kaikilta työmailta',
  'label.allHours': 'kaikki',
  'label.allocate': 'Kohdista',
  'label.allocatedToSite': 'Lisätty työmaalle',
  'label.allocateSelected': 'Kohdista valitut',
  'label.allocating': 'Kohdistetaan...',
  'label.allowed': 'Sallittu',
  'label.allRequiredCoursesExist': 'Kaikki vaadittavat koulutukset ovat olemassa',
  'label.allRequiredFieldsValid': 'Kaikki pakolliset tiedot täytetty',
  'label.allSites': 'Kaikki työmaat',
  'label.allTaxReportsSent': 'Kaikki verottajan ilmoitukset lähetetty',
  'label.amountShort': 'kpl',
  'label.and': ' ja ',
  'label.approval.waiting': 'Odottaa hyväksyntää',
  'label.approval': 'Hyväksyntä',
  'label.approve': 'Hyväksy',
  'label.approved': 'Hyväksytty',
  'label.approvedAccessPermitsFromTo': 'Myönnetyt työmaaluvat ajalla {0} - {1}',
  'label.approving': 'Hyväksytään...',
  'label.archive': 'Arkisto',
  'label.archived': 'Arkistoitu',
  'label.area': 'Alue',
  'label.areaSaved': 'Alue tallennettu!',
  'label.areYouSure': 'Oletko varma?',
  'label.asDeveloper': 'tilaajana',
  'label.askAboutSubcontractEndDateChange': 'Muutetaanko tämän sopimuksen alla olevien aliurakkasopimusten päättymispäivää? Tämä vaihtoehto päivittää kaikki sopimukset, <strong>joiden päättymispäivä on {0}</strong>. Uusi päättymispäivä on {1}.',
  'label.asMainContractor': 'pääurakoitsijana',
  'label.asSubcontractor': 'aliurakoitsijana',
  'label.attachment': 'Liitetiedosto',
  'label.attachments': 'Liitetiedostot',
  'label.attachmentsAdded': 'Liitetiedostoja lisätty',
  'label.atTime': 'klo',
  'label.automaticallySaveContractorLiabilityDocsEveryContractAnniversary': 'Päivitä automaattisesti urakoitsijoiden Tilaajavastuu-status ja -raportti sopimusten vuosipäivinä ja arkistoi vanhat raportit',
  'label.automaticTagListUpdate': 'Automaattinen uusien kulkutunnisteiden päivitys porttilaitteille',
  'label.automaticTagListUpdateShort': 'Kulkutunnisteet',
  'label.aviMissingTagsTooltip': 'Vain henkilöt, joilla ei ole Valttikorttia eikä pääurakoitsijan tai tilaajan antamaa kulkutunnistetta.',
  'label.aviReport': 'AVI-raportin asetukset',
  'label.aviReportShort': 'AVI',
  'label.aviTimespanTooltip': 'Näytetään työntekijät, joiden työvoimalupa on voimassa ainakin yhtenä päivänä annetulla aikavälillä.',
  'label.awaitingIntroduction': 'Odottaa vahvistusta perehdytyksestä',
  'label.backToDashBoard': 'Takaisin etusivulle',
  'label.basicInformation': 'Perustiedot',
  'label.begins': 'Alkaa',
  'label.begun': 'Alkoi',
  'label.betaVersion': 'Beta-versio',
  'label.birthdayBoyOnSite': 'Työmaan työntekijällä on syntymäpäivä tänään tai huomenna',
  'label.birthdayBoysOnSite': 'Työmaan <strong>{0}</strong> työntekijällä on syntymäpäivä tänään tai huomenna',
  'label.birthdayToday': '<strong>{0}</strong>({1}) täyttää <strong>{2}</strong> vuotta tänään!',
  'label.birthdayTomorrow': '<strong>{0}</strong>({1}) täyttää <strong>{2}</strong> vuotta huomenna!',
  'label.blocksReportsToTaxAdmin': 'Estää verottajailmoituksen',
  'label.both': 'Molemmat',
  'label.buyer': 'Tilaaja',
  'label.buyerContactPerson': 'Tilaajan yhteyshenkilö',
  'label.call': 'Soita',
  'label.cancel': 'Peruuta',
  'label.car': 'Henkilöauto',
  'label.card': 'Kortti',
  'label.cardId': 'Kulkutunniste',
  'label.cardIds': 'Kulkutunnisteet',
  'label.carShort': 'Henkilö-',
  'label.certificates': 'Sertifikaatit',
  'label.change': 'Muuta',
  'label.changeContactDetails': 'Vaihda yhteystiedot',
  'label.changeContactDetailsSubmitting': 'Vaihdetaan yhteystiedot...',
  'label.changeContactDetailsSuccess': 'Yhteystiedot vaihdettu!',
  'label.checkedIn': 'Työmaalla',
  'label.checkedInDesc': 'Näytä vain henkilöt, jotka ovat työmaalla',
  'label.checkedOnDate': 'Tarkistettu {0}',
  'label.checkedOutNextDay': 'Ulosleimaus seuraavana päivänä',
  'label.checkIn': 'Leimaus',
  'label.checkInAlreadyExists': 'Et voi luoda kahta päällekäistä leimausta samalle minuutille. Yritä minuutin päästä uudelleen.',
  'label.checkInAlreadyExists2': 'Et voi luoda kahta päällekäistä leimausta samalle minuutille.',
  'label.checkInDevice': 'Leimauslaite',
  'label.checkInDeviceInfo': 'Leimauslaitteen tiedot',
  'label.checkInDevices': 'Kulunvalvontalaitteet',
  'label.checkInIsModified': 'Leimausta on muokattu',
  'label.checkInOutsideSite': 'Ei voitu varmistaa, että mobiilileimaus tapahtui työmaa-alueella.',
  'label.checkIns': 'Leimaukset',
  'label.checkOutForgottenHeading': 'Ulosleimaus tekemättä',
  'label.checkOutIsModified': 'Leimausta on muokattu',
  'label.chooseFile': 'Valitse tiedosto',
  'label.chooseForemen': 'Valitse työnjohtajat',
  'label.chooseFromRegister': 'Valitse rekisteristä',
  'label.chooseLanguage': 'Valitse kieli...',
  'label.chooseSiteForEvaluation': 'Valitse työmaa, johon arviointi liittyy',
  'label.city': 'Kaupunki',
  'label.clear': 'Tyhjennä',
  'label.close': 'Sulje',
  'label.closeWithoutSaving': 'Sulje tallentamatta',
  'label.clothingSize': 'Vaatekoko',
  'label.collectiveLaborAgreement': 'Työehtosopimus',
  'label.columnHeadingBirthDate': 'Syntymäaika',
  'label.columnHeadingBusinessId': 'Y-tunnus',
  'label.columnHeadingCardNumber': 'Korttinro',
  'label.columnHeadingCity': 'Postitoimipaikka',
  'label.columnHeadingCompetenceCardNumber': 'Kortin nro',
  'label.columnHeadingContact': 'Yhteys&shy;henkilö',
  'label.columnHeadingContractor': 'Ura&shy;koit&shy;sija',
  'label.columnHeadingContractorHomeCountry': 'Palkanmaksajan kotimaa',
  'label.columnHeadingCountry': 'Asuin&shy;valtio',
  'label.columnHeadingDateShort': 'Pvm',
  'label.columnHeadingEmail': 'Sähköposti',
  'label.columnHeadingEmployer': 'Palkanmaksaja',
  'label.columnHeadingIdConfirmation': 'Henkilöll. varmen&shy;nettu',
  'label.columnHeadingIntroduced': 'Vahvistettu',
  'label.columnHeadingName': 'Nimi',
  'label.columnHeadingNationality': 'Kans.',
  'label.columnHeadingPaymentNote': 'Lisätieto',
  'label.columnHeadingPaymentType': 'Tila',
  'label.columnHeadingPostcode': 'Postinumero',
  'label.columnHeadingRelation': 'Työ&shy;suhteen laatu',
  'label.columnHeadingRep': 'Edustaja',
  'label.columnHeadingRepCity': 'Edustajan postitoimipaikka',
  'label.columnHeadingRepEmail': 'Edustajan sähköposti',
  'label.columnHeadingRepFirstName': 'Edustajan etunimi',
  'label.columnHeadingRepLastName': 'Edustajan sukunimi',
  'label.columnHeadingRepPostcode': 'Edustajan postinumero',
  'label.columnHeadingRepStreet': 'Edustajan osoite',
  'label.columnHeadingRepTelephone': 'Edustajan puhelin',
  'label.columnHeadingResidencePermitExpires': 'Oleskelulupa päättyy',
  'label.columnHeadingSsn': 'HETU',
  'label.columnHeadingStreet': 'Osoite',
  'label.columnHeadingTaxNumber': 'Veronumero',
  'label.columnHeadingTelephone': 'Puhelin',
  'label.columnHeadingValue': 'Summa',
  'label.columnHeadingVehiclePermitApprover': 'Hyväksyjä',
  'label.columnHeadingVehiclePermitStatus': 'Hyväksytty',
  'label.columnHeadingLastCheckInTime': 'Sisäänkirjaus',
  'label.columnHeadingAccessAreaName': 'Alue',
  'label.commaSeparated': 'Erota pilkulla',
  'label.comment': 'Kommentti',
  'label.commentOrReimbursment': 'Kommentti / kulukorvaus',
  'label.commitmentLink': 'Vastaanottositoumus {0} (PDF)',
  'label.companies': 'Yritykset',
  'label.companiesLackingLiabilityInsurance': '<strong>{0} yrityksen</strong> Luotettava Kumppani -raportilta puuttuu tieto voimassaolevasta vastuuvakuutuksesta. <b>Jos yrityksen vastuuvakuutus on uusittu, niin yrityksen edustaja voi päivittää tiedon Luotettava Kumppani -raportille <a href="https://kampanja.tilaajavastuu.fi/vastuuvakuutus" target="_blank">tällä lomakkeella.</a></b>',
  'label.companiesWithIncompleteInformation': '<strong>{0} yrityksen</strong> tiedot ovat puutteelliset.',
  'label.companiesWithInvalidContractorLiability': '<strong>{0}</strong> yrityksen tilaajavastuutiedot ovat puutteelliset.',
  'label.company': 'Yritys',
  'label.companyExtraInfo': 'Näytä yritystiedot',
  'label.companyHasntBeenEvaluated': 'Yritystä ei ole vielä arvioitu.',
  'label.companyHealth': 'Työterveyshuolto',
  'label.companyHealthProvider': 'Työterveyshuoltoyritys',
  'label.companyHealthProviderLegend': 'Selvitys työterveyshuollon järjestämisestä (vaaditaan työterveyshuoltotoimija Suomessa)',
  'label.companyInfo': 'Yritystiedot',
  'label.companyLackingLiabilityInsurance': 'Yrityksen <strong>{0}</strong> <a href="/integrations/vastuugroup/report/contractorLiabilityPdf/{1}" target="_blank">Luotettava Kumppani -raportilta</a> puuttuu tieto voimassaolevasta vastuuvakuutuksesta. <b>Jos yrityksen vastuuvakuutus on uusittu, yrityksen edustaja voi päivittää tiedon Luotettava Kumppani -raportille <a href="https://kampanja.tilaajavastuu.fi/vastuuvakuutus" target="_blank">tällä lomakkeella.</a></b>',
  'label.companyLackingLiabilityInsuranceVGServiceNotEnabled': 'Yrityksen <strong>{0}</strong> Luotettava Kumppani -raportilta puuttuu tieto voimassaolevasta vastuuvakuutuksesta. <b>Jos yrityksen vastuuvakuutus on uusittu, yrityksen edustaja voi päivittää tiedon Luotettava Kumppani -raportille <a href="https://kampanja.tilaajavastuu.fi/vastuuvakuutus" target="_blank">tällä lomakkeella.</a></b>',
  'label.companyName': 'Yrityksen nimi',
  'label.companyRole': '{0}:n rooli',
  'label.companySearch': 'Yrityshaku',
  'label.companySpecificInfo': 'Yrityskohtaiset tiedot',
  'label.companySpecificInfoHelp': 'Yrityskohtaiset tiedot näkyvät vain yrityksen {0} käyttäjille.',
  'label.companyUnit': 'Yksikkö',
  'label.companyWithIncompleteInformation': 'Yrityksen <strong>{0}</strong> tiedot ovat puutteelliset.',
  'label.companyWithInvalidContractorLiability': 'Yrityksen <strong>{0}</strong> tilaajavastuutiedot ovat puutteelliset.',
  'label.competence': 'Pätevyys',
  'label.competenceExpired': 'Pätevyys päättynyt',
  'label.competenceExpiring': 'Pätevyys päättymässä',
  'label.competenceLoadError': 'Pätevyyden tietojen lataamisessa tapahtui virhe.',
  'label.competenceModelRemoved': 'Koulutus \'<strong>{0}</strong>\'  on poistettu ja ei ole enää käytössä.',
  'label.competenceModelRemovedShort': 'Koulutus ei ole enää käytössä',
  'label.competencePendingAccomplishment': 'Odottaa suoritusta',
  'label.competenceReport.active': 'Voimassa olevat',
  'label.competenceReport.expired': 'Umpeutuneet',
  'label.competenceReport.expiring': 'Päättymässä olevat',
  'label.competenceReport.noCompetence': 'Henkilöt, joilta puuttuu voimassaoleva pätevyys',
  'label.competenceReportInfo': 'Hakee Excel-taulukon, joka listaa työntekijöiden pätevyydet annetuilla kriteereillä.',
  'label.competenceReportInfo2': 'Päättymässä olevat -valinta näyttää voimassa olevat pätevyydet, jotka ovat päättymässä 31 päivän sisällä.',
  'label.competences': 'Pätevyydet',
  'label.competencesExpiredOrExpiring': '<strong>{0} pätevyyttä</strong> on päättynyt tai päättymässä 30 päivän sisällä.',
  'label.competencesLoadError': 'Pätevyyksien lataamisessa tapahtui virhe.',
  'label.completed': 'Toteuma',
  'label.confirm': 'Vahvista',
  'label.confirmation': 'Varmennus',
  'label.confirmDeletion': 'Vahvista poisto',
  'label.confirmEnd': 'Vahvista päättäminen',
  'label.confirming': 'Vahvistetaan...',
  'label.confirmRemovalFromSite': 'Vahvista poisto työmaalta',
  'label.confirmRemove': 'Vahvista poistaminen',
  'label.constructor': 'Rakennuttaja',
  'label.contactForTaxAdmin': 'Verottajailmoitusten yhteyshenkilö',
  'label.contactForTaxAdminTooltip': 'Yhteyshenkilö verottajan suuntaan, joka vastaa kuukausi-ilmoitusten lähetyksestä',
  'label.contactInfo': 'Yhteystiedot',
  'label.contactInformation': 'Yhteystiedot',
  'label.contactPerson': 'Yhteyshenkilö',
  'label.contactPersonInCompany': 'Yhteyshenkilö yrityksessä {0}',
  'label.continue': 'Jatka',
  'label.continueEditing': 'Jatka muokkausta',
  'label.continueToPermitAdd': 'Jatka työmaaluvan lisäykseen',
  'label.contract': 'Sopimus',
  'label.contractAndEmployeeDeclarations': 'Urakka- ja työntekijäilmoitukset',
  'label.contractChangeWarning': 'Henkilölle kertyneet leimaukset siirtyvät uudelle palkanmaksajalle. Jos vanha palkanmaksajayritys on fuusioitunut uuteen, tulee vanha palkanmaksajatieto jättää tähän lupaan ja luoda uusi työmaalupa uudella palkanmaksajalla.',
  'label.contractContactPerson': 'Sopimusten yhteyshenkilöt',
  'label.contractContactInfoShort': 'Tähän lisättävä yhteyshenkilö näkyy esimerkiksi urakoitsijalle, mutta ei lisää henkilölle käyttöoikeutta ympäristöönne.',
  'label.contractContactInfoWithLink': 'Tähän lisättävä yhteyshenkilö näkyy esimerkiksi urakoitsijalle, mutta ei lisää henkilölle käyttöoikeutta ympäristöönne. Käyttäjiä voi lisätä valitsemalla',
  'label.contractContactInfoLinkAnchor': 'Hallinta &rarr; Käyttäjät',
  'label.contractContacts': 'Hae sopimusten yhteyshenkilöt',
  'label.contractCount': 'sopimus',
  'label.contractDeclarations': 'Urakkailmoitukset',
  'label.contractDetails': 'Sopimustiedot',
  'label.contractForeman': 'Ensisijainen työnjohtaja',
  'label.contractInformation': 'Sopimustiedot',
  'label.contractingType': 'Urakkalaji',
  'label.contractingTypeConstruction': 'Urakointi tai kunnossapitotyö',
  'label.contractingTypeDevelopment': 'Perustajaurakointi',
  'label.contractingTypeHire': 'Työvoiman vuokraus',
  'label.contractingTypeTooltip': 'Urakkalajit ovat verottajan virallisesta luettelosta. Konsultointi kohtaan "urakointi tai kunnossapitotyö".',
  'label.contractKey': 'Sopimusavain',
  'label.contractManagedLabel': 'Anna urakoitsijalle ja yhteyshenkilölle hallintaoikeus',
  'label.contractManagedLabelMultipleContacts': 'Anna urakoitsijalle ja yhteyshenkilöille hallintaoikeus',
  'label.contractNumber': 'Sopimustunnus',
  'label.contractNumberTooltip': 'Yrityksesi sisäinen numero tai muu tunnus sopimukselle',
  'label.contractor': 'Urakoitsija',
  'label.contractorEmployees': 'urakoitsijan työntekijää',
  'label.contractorIntroduces': 'Urakoitsija voi vahvistaa omat työntekijänsä',
  'label.contractorIntroductionWarning': '<strong>Huom!</strong> Valintaa ei pysty tallennuksen jälkeen muuttamaan.',
  'label.contractorLiability.search': 'Tilaajavastuuhaku',
  'label.contractorLiability': 'Tilaajavastuu',
  'label.companyCheckFailed': 'Yrityksen tietojen hakeminen epäonnistui.',
  'label.contractorLiabilityErrors': 'Tilaajavastuussa puutteita',
  'label.contractorLiabilityExpired': 'Tilaajavastuudokumentit eivät ole voimassa',
  'label.contractorLiabilityMoreInfo': 'https://support.vastuugroup.fi/hc/fi/articles/115005569269-Luotettava-Kumppani-raportin-tulkinnat',
  'label.contractorLiabilityNotFound': 'Ei tietoja',
  'label.contractorLiabilityOk': 'Kunnossa',
  'label.contractorLiabilityReport': 'Tilaajavastuuraportti',
  'label.contractorObligationReport': 'Tilaajavastuuraportti',
  'label.contractorObligationReportShort': 'Tilaajavastuu',
  'label.contractorReport.contractors': 'Urakoitsijat',
  'label.contractorReport.showOnlyActive': 'Näytä vain aktiiviset urakoitsijat',
  'label.contractorReport': 'Urakoitsijaraportti',
  'label.contractorReportInfo': 'Listaa annetun työmaan tai kaikkien työmaiden urakoitsijat.',
  'label.contractorReportShort': 'Urakoitsijat',
  'label.contractors': 'Urakoitsijat',
  'label.contractorSearch': 'Urakoitsijahaku',
  'label.contractorShort': 'Urak.',
  'label.contractorSubcontracts': 'Urakoitsija voi ketjuttaa omia sopimuksiaan',
  'label.contractorsWithoutForeman': '<strong>{0}</strong> urakoitsijaa ilman työmaaluvallista työnjohtajaa.',
  'label.contractorsWithoutForemanInfo': 'työnjohtajat määritetään työmaalupien kautta.',
  'label.contractPartner': 'Sopimuskumppani',
  'label.contracts': 'Sopimukset',
  'label.contractsCount': 'sopimusta',
  'label.contractStatus': 'Sopimuksen tila',
  'label.contractsWithAwaitingDocuments': 'Tilaajavastuudokumentit odottavat hyväksyntää',
  'label.contractTree': 'Sopimuspuu',
  'label.contractType': 'Sopimuksen tyyppi',
  'label.contractWork': 'Urakka',
  'label.copied': 'Kopioitu',
  'label.copy': 'Kopioi',
  'label.copyAccessPermits': 'Kopioi henkilöiden työmaaluvat',
  'label.copyContractsAndPersons': 'Kopioi sopimukset ja henkilöt',
  'label.copyContractTreeAll': 'Kopioi koko sopimuspuu',
  'label.copyContractTreeDeveloperAndMC': 'Kopioi vain tilaajan ja pääurakoitsijan sopimukset',
  'label.copyContractTreeInfoText': 'Kaikkien kopioitavien sopimusten tilaajavastuutiedot on oltava kunnossa. Tämä voidaan hoitaa Luotettava kumppani -ohjelman kautta tai lisäämällä tilaajavastuudokumentit voimassaolopäivämäärän kanssa.',
  'label.copyContractTreeInvalidLiabilityStates': 'Seuraavien sopimusten tilaajavastuutiedot eivät ole kunnossa:',
  'label.copyContractTreeLiabilityStatusCheckFailed': 'Tilaajavastuutietojen tarkastaminen epäonnistui. Ole hyvä ja yritä uudelleen. Jos ongelma ei ratkea, ota yhteyttä tukeen (support@zeroni.fi).',
  'label.copyContractTreeLiabilityStatusCheckRunning': 'Tarkistetaan sopimusten tilaajavastuutietoja...',
  'label.copyContractTreeLiabilityStatusReCheck': 'Yritä uudelleen',
  'label.copyFromSite': 'Kopioidaan työmaasta ',
  'label.copying': 'Kopioidaan...',
  'label.copySite': 'Kopioi työmaa',
  'label.copySourceSite': 'Kopioidaan työmaasta',
  'label.count': 'Lukumäärä',
  'label.country': 'Valtio',
  'label.createAccessPermit': 'Luo työmaalupa leimauksesta',
  'label.createContractAndAccessPermitFromUnresolvedCheckIn': 'Luo sopimus ja työmaalupa leimauksesta',
  'label.createIntroductionForm': 'Luo perehdyttämislomake',
  'label.createSubContract': 'Ketjuta',
  'label.creating': 'Luodaan...',
  'label.creator': 'Lisääjä',
  'label.currentCountry': 'Pääasiallinen työskentelyvaltio',
  'label.daily': 'Työmaapäiväkirja',
  'label.dailyReport': 'Työmaapäiväkirja',
  'label.dashboard': 'Etusivu',
  'label.date': 'Päivämäärä',
  'label.dateFormat_ddmmyyyy': 'pp.kk.vvvv',
  'label.dateFormat_mmyyyy': 'kk/vvvv',
  'label.dateOfBirth': 'Syntymäaika',
  'label.dateOfBirthShort': 'Synt.',
  'label.dateShort': 'Pvm',
  'label.daysAbbreviation': 'pv',
  'label.ddmmyyyy': 'pp.kk.vvvv',
  'label.declarationID': 'Ilmoitustunnus',
  'label.default': 'Oletus',
  'label.defaultRoles': 'vakiorooleille.',
  'label.delete': 'Poista',
  'label.deleteDeviceHeading': 'Poista <strong>{0}</strong>',
  'label.deleteUserGroup': 'Poista käyttäjärooli',
  'label.deleting': 'Poistetaan...',
  'label.department': 'Yksikkö',
  'label.departmentFilterPlaceholder': 'Rajaa yksikön mukaan',
  'label.departmentPlaceholder': 'Etsi tai valitse työmaan yksikkö',
  'label.developer': 'Tilaaja',
  'label.developerAddress': 'Tilaajan osoite',
  'label.developerContactPhoneNumber': 'Tilaajan yhteyshenkilön puhelinnumero',
  'label.developerContractManagedLabel': 'Anna tilaajalle ja yhteyshenkilölle hallintaoikeus',
  'label.developerContractManagedLabelMultipleContacts': 'Anna tilaajalle ja yhteyshenkilöille hallintaoikeus',
  'label.device': 'Laite',
  'label.devices': 'Laitteet',
  'label.digiPenForm': 'Työaikakortti',
  'label.digiPenForms': 'Työaikakortit',
  'label.digiPenHasComment': 'Sis. lisätietoja',
  'label.disallowed': 'Estetty',
  'label.documentCopy': 'Kopio täytetystä lomakkeesta',
  'label.documents': 'Dokumentit',
  'label.documentsCanBeSeenByContractors': 'Dokumentit näkyvät urakoitsijoille.',
  'label.domainContext': 'Työmaan tyyppi ja sijainti',
  'label.doNotAllocate': 'Älä kohdista',
  'label.doNotChange': 'Älä muuta',
  'label.doNotExtend': 'Älä pidennä',
  'label.dontShowAgain': 'Älä kysy uudestaan',
  'label.download': 'Lataa',
  'label.downloadCsv': 'Hae CSV',
  'label.downloadExcel': 'Hae Excel',
  'label.downloadFile': 'Lataa tiedosto',
  'label.downloadNFCReader': 'Lataa NFCReader',
  'label.dragHere': 'Raahaa tähän',
  'label.driversLicense': 'Ajokortti',
  'label.drugTest': 'Viimeisin huumetesti',
  'label.dueDate': 'Eräpäivä',
  'label.edit': 'Muokkaa',
  'label.edited': 'Muokattu',
  'label.editingRights': 'Muokkaus',
  'label.editSiteAccessArea': 'Muokkaa työmaan kulkualuetta',
  'label.editVehiclePermit': 'Muokkaa ajoneuvolupaa',
  'label.eePersonalCode': 'Virolainen henkilötunnus',
  'label.electronicIntroductionError': 'Perehdytys vahvistettu sähköisesti, mutta lomake puuttuu. Luo uusi lomake.',
  'label.electronicIntroductionErrorInfo': 'Perehdytyslomakkeen tallentaminen on epäonnistunut tietoliikennekatkoksen seurauksena. Sovelluksessa ei ollut huomioitu tällaista virhetilannetta, mistä syystä tallennusta ei yritetty automaattisesti uudelleen. Käsittelyä on parannettu tältä osin 4.7.2019. Voit oheisesta linkistä tallentaa perehdytyslomakkeen uudelleen.',
  'label.electronicIntroductionLink': 'Sähköinen vahvistus perehdytyksestä {0} (PDF)',
  'label.email': 'Sähköposti',
  'label.emailAddress': 'Sähköpostiosoite',
  'label.emailAddressRequired': 'Sähköpostiosoite on pakollinen.',
  'label.emailAddressChangeRequired': 'Sähköpostiosoite on vaihdettava.',
  'label.emailAddressInvalid': 'Virheellinen sähköpostiosoite.',
  'label.emailNotification': 'Sähköposti-ilmoitus havainnosta',
  'label.emailNotifications': 'Sähköpostien lähetys',
  'label.emailNotificationsLong': 'Lähetä sähköposti hallintaoikeudesta sopimuksen yhteyshenkilöille',
  'label.emailRequiredHint': 'Mihin sähköpostia tarvitaan?',
  'label.employed': 'Työsuhteinen',
  'label.employee': 'Työntekijä',
  'label.employeeDeclarations': 'Työntekijäilmoitukset',
  'label.employeeInfo': 'Henkilötiedot',
  'label.employeeReport': 'Henkilöraportti (esim. AVI:lle)',
  'label.employeeReportInfo': 'Hakee raportin, joka listaa kaikki annettuja kriteereitä vastaavat työntekijät tai vierailijat. AVI-raportin asetukset -valinta asettaa asetukset AVI-raportin vaatimusten mukaisiksi.',
  'label.employeeReportPlain': 'Henkilöraportti',
  'label.employeeReportShort': 'Henkilöt',
  'label.employees': 'Työntekijät',
  'label.employeeSearch': 'Työntekijähaku',
  'label.employeesWithIncompleteInformation': '<strong>{0}</strong> henkilön tiedot ovat puutteelliset.',
  'label.employeesWithNoPermits': 'Työntekijät ilman työmaalupaa',
  'label.employeeWithIncompleteInformation': 'Henkilön <strong>{0}</strong> tiedot ovat puutteelliset.',
  'label.employeeWithInvalidTaxNumber': 'Henkilön <strong>{0}</strong> veronumero on virheellinen.',
  'label.employer': 'Työnantaja',
  'label.employerRegister': 'Työnantajarekisteri',
  'label.employerRegisterLegend': 'Selvitys työnantaja&shy;rekisteri&shy;merkinnästä',
  'label.emptyOrientationFormLink': 'Tyhjä perehdyttämislomake (PDF)',
  'label.endAction': 'Päätä',
  'label.endDateAbbrev': 'Päättymispvm.',
  'label.ended.alt': 'Päättyi',
  'label.ended': 'Päättynyt',
  'label.endedPlural': 'Päättyneet',
  'label.endMeasurement': 'Päätä mittaus',
  'label.ends': 'Päättyy',
  'label.endTask': 'Lopeta <strong>{0}</strong>',
  'label.enterSickLeaveReason': 'Syötä sairasloman syy',
  'label.enterUsernameFirst': 'Syötä ensin tunnus',
  'label.entrepreneur': 'Ammatinharjoittaja',
  'label.error': 'Virhe',
  'label.ersInvoiceNumber': 'ERS Laskun numero',
  'label.estimatedValue': 'Arvioitu summa',
  'label.estimatedWeeklyHours': 'Syötetyt viikkotunnit',
  'label.estimatedWorkingPeriod': 'Arvioitu työskentelyn kesto',
  'label.estonianBusinessId': 'Virolainen yritystunnus',
  'label.evaluate': 'Arvioi',
  'label.evaluateLabor': 'Työn arviointi',
  'label.evaluateMaterials': 'Materiaalin arviointi',
  'label.evaluation': 'Arviointi',
  'label.evaluationAverage': 'Arvioiden keskiarvo',
  'label.evaluationNotes': 'Arvioinnin perustelu',
  'label.evaluationReport': 'Arviointiraportti',
  'label.evaluationReportShort': 'Arviointi',
  'label.evaluations': 'Arvioinnit',
  'label.evaluationsReport': 'Arviointi',
  'label.excelReportSiteInfo.contactPerson': 'Yhteyshenkilö:',
  'label.excelReportSiteInfo.developer': 'Rakennuttaja:',
  'label.excelReportSiteInfo.email': 'Email:',
  'label.excelReportSiteInfo.mainContractor': 'Pääurakoitsija:',
  'label.excelReportSiteInfo.siteAddress': 'Työmaan osoite:',
  'label.excelReportSiteInfo.siteForeman': 'Vastaava työnjohtaja:',
  'label.excelReportSiteInfo.sitePeriod': 'Alkaa/valmistuu:',
  'label.excelReportSiteInfo.telephone': 'Puhelin:',
  'label.execelReportDoesNotInclude': 'Ei sisälly Excel-raporttiin',
  'label.exempted': 'Ei velvollisuutta',
  'label.expat': 'Lähetetty työntekijä',
  'label.expatCompany': 'Lähettävä yritys',
  'label.expatRepresentative': 'Lähetetyn työntekijän edustaja',
  'label.expatRepresentativeMany': 'Lähetettyjen työntekijöiden edustaja',
  'label.expats': 'Lähetetyt työntekijät',
  'label.expatSocialSecurityDoc': 'Todistus sosiaaliturvasta (esim. A1)',
  'label.expatSocialSecurityDocExpirationDate': 'Todistuksen voimassaolo päättyy',
  'label.expatSocialSecurityDocHelp': 'Jos vastaanotat ulkomailta lähetetyn työntekijän, varmista että hänellä on mukanaan lähetetyn työntekijän todistus A1 tai muu vastaava todistus (aikaisemmin E101) osoituksena kuulumisesta lähettävän maan sosiaaliturvaan.',
  'label.expatSocialSecurityDocShort': 'Sosiaaliturva',
  'label.expatTooltip': 'Työnantajan virallinen edustaja Suomessa',
  'label.expenseReimbursment': 'Kulukorvaus',
  'label.expertise': 'Osaaminen',
  'label.expired': 'Päättyi',
  'label.expiredOn': 'Päättyi {0}',
  'label.expires': 'Päättyy',
  'label.expiringOn': 'Päättyy {0}',
  'label.exportToExcel': 'Vie Exceliin',
  'label.failed': 'Epäonnistui',
  'label.failedToFetchValttiCardImage': 'Valttikortin kuvan lataaminen epäonnistui. Valitettavasti ilman kuvaa korttia ei voi käyttää henkilökortin tilalla. Ole hyvä ja yritä kortin lisäämistä hetken päästä uudelleen.',
  'label.failedToFetchValttiCardImageDueToCardBeingClosed': 'Valttikortin kuvan lataaminen epäonnistui, koska valttikortti ei ole enää voimassa. Valitettavasti ilman kuvaa korttia ei voi käyttää henkilökortin tilalla.',
  'label.failedToLoadPreviousValttiCardImage': 'Zeroniin tallennetun kuvan lataaminen ei onnistunut. Syötä muu kortin kuva.',
  'label.failedVisyPermitIntegrations': '<strong>{0}</strong> luvan lähettämisessä VisyGateen on häiriö. Näistä <strong>{1}</strong> lähettäminen on päättynyt virheeseen.',
  'label.failedVisyPermitIntegrationStatus.FAILED': 'Virhe',
  'label.failedVisyPermitIntegrationStatus.RETRYING': 'Lähetys käynnissä',
  'label.files.removed': 'Liitetiedostot on poistettu.',
  'label.files': 'Tiedostot',
  'label.filter.showSubContractors': 'Näytä myös aliurakoitsijoiden henkilöt',
  'label.filter.visitors': 'Vierailijat',
  'label.findExistingPersonWithIdentifierCallFailed': 'Tunnisteen tarkastus epäonnistui, tallennus estetty.',
  'label.findPersonInformationFailed': 'Tunnisteen tarkastaminen rekisteristä epäonnistui.',
  'label.findPersonInformationNotFound': 'Tunnisteella ei löydy henkilöä rekisteristä.',
  'label.findingNameSuggestion': 'Haetaan nimeä...',
  'label.finished': 'Päättynyt',
  'label.finnishBusinessId': 'Y-tunnus',
  'label.firstName': 'Etunimi',
  'label.firstNames': 'Etunimet',
  'label.fix': 'Korjaa',
  'label.foreignBusinessId': 'Ulkom. rek. nro',
  'label.foreignBusinessIdLabel': 'Ulkomainen tunnus',
  'label.foreignerRepresentative': 'Ulkomaalaisten edustaja',
  'label.foreignSsn': 'Ulkom. hetu',
  'label.foreman': 'Työnjohtaja',
  'label.foremanContactInfo': 'työnjohtajan yhteystiedot',
  'label.foremen': 'Työnjohtajat',
  'label.foremenOnly': 'Näytä vain työnjohtajat',
  'label.found': 'löydetty',
  'label.freeEvaluation': 'Vapaamuotoinen arvio',
  'label.fridayShort': 'pe',
  'label.fromSiteStartToEnd': 'Työmaan alkamisesta valmistumiseen',
  'label.furtherInformation': 'Lisätietoja',
  'label.furtherInformation2': 'Lisätietoja',
  'label.gate': 'Portti',
  'label.gateIntegration': 'Porttiohjaus',
  'label.gateType': 'Portin tyyppi',
  'label.general': 'Yleiset',
  'label.generalRating': 'Yleisarvosana',
  'label.generalSettings': 'Yleiset asetukset',
  'label.genericBusinessId': 'Tunnus',
  'label.geolocationFailed': 'Paikannus epäonnistui. Leimauksiin liitetään huomio.',
  'label.getCheckInsOuts': 'Hae leimaukset',
  'label.getDataFromValtti': 'Hae tiedot Valttikortilta',
  'label.getting': 'Haetaan...',
  'label.giveBusinessId': 'Anna ensin Y-tunnus',
  'label.goBack': 'Takaisin',
  'label.gotIt': 'Selvä',
  'label.goToLiabilityDocuments': 'Siirry tilaajavastuudokumentteihin',
  'label.grade': 'Vahvista',
  'label.groupByContractor': 'Ryhmittele urakoitsijan mukaan',
  'label.handle': 'Käsittele',
  'label.handled': 'Käsitelty',
  'label.handlingFailed': 'Käsittely epäonnistui',
  'label.healthCard': 'Työterveyskortti',
  'label.help': 'Ohje',
  'label.hereIsListedOnlyTrustedPartners': 'Tähän ilmoitukseen on otettu vain Luotettava kumppani -ohjelmaan kuuluvat yritykset.',
  'label.hide': 'Piilota',
  'label.hideAll': 'Piilota kaikki',
  'label.hideEnded': 'Piilota päättyneet',
  'label.hidePreviousCLReports': 'Piilota aikaisemmat tilaajavastuuraportit',
  'label.hideSiteMainContracts': 'Piilota työmaan pääurakointisopimukset',
  'label.hire': 'Vuokra',
  'label.homeCountry': 'Kotivaltio',
  'label.hotWorkForm': 'Tulityölomake',
  'label.hotWorkPermitApproverSignature': 'Myöntäjän allekirjoitus',
  'label.hourBank': 'Työaikapankki',
  'label.hourBankReport.heading': 'Työaikapankkiraportti',
  'label.hourBankReport.info': 'Näyttää kirjautuneen yrityksen työntekijöiden työaikapankkitilanteen: talletetut, käytetyt, ja käytettävissä olevat joustotunnit. Tunnit kerätään kaikilta niiltä sopimuksilta, joissa yritys on tuntien hyväksyjänä.',
  'label.hours': 'Tunnit',
  'label.hoursAbbreviation': 'h',
  'label.id': 'Tunniste',
  'label.idCard': 'Henkilökortti',
  'label.idCardNumber': 'Henkilökortin numero',
  'label.idConfirmation': 'Henkilöllisyyden varmennus',
  'label.identifierNotFoundFromRegistry': 'Tunniste ei löydy rekisteristä.',
  'label.identifierNotFoundFromSSG': 'Numerolla ei löydy SSG Access korttia.',
  'label.identifiers': 'Henkilötunnisteet',
  'label.idNumber': 'Kulkutunnisteen numero',
  'label.iKnowWhatImDoing': 'Ymmärrän ja tiedän mitä olen tekemässä',
  'label.in': 'Sisään',
  'label.inadequate': 'Puutteellinen',
  'label.includeInvoiceHoursAddedByEmployees': 'Näytä myös työntekijöiden lisäämät täydennystä odottavat',
  'label.includeRemovedPermits': 'Näytä poistetut',
  'label.inEnglish': 'Englanniksi',
  'label.inFinnish': 'Suomeksi',
  'label.inOut': 'Kaksisuuntainen',
  'label.instructorSignature': 'Vahvistajan allekirjoitus',
  'label.interruptContract': 'Keskeytä',
  'label.interruptContractHeading': 'Keskeytä sopimus {0}',
  'label.interrupted': 'Keskeytetty',
  'label.introduce': 'Vahvista perehdytys',
  'label.introduction': 'Vahvistus perehdytyksestä',
  'label.introductionDate': 'Vahvistettu',
  'label.introductionFormLabel': 'Perehdyttämis&shy;lomake',
  'label.introductionFormLink': 'Perehdyttämislomake (PDF)',
  'label.introductionInstructor': 'Perehdytyksen vahvistaja',
  'label.introductionMaterial': 'Työmaan perehdytysmateriaali',
  'label.introductionResponsibility': 'Työmaan lisääjänä vakuutat, että henkilöt on vahvistettu asianmukaisesti.',
  'label.introductionTopics': 'Työntekijälle perehdytetyt asiat',
  'label.introductionTopicsAvailable': 'Perehdytettävät asiat',
  'label.introductionWorker': 'Perehdytettävä',
  'label.introSettings': 'Perehdytysasetukset',
  'label.inUse': 'Käytössä',
  'label.invalid': 'Puutteellinen',
  'label.invalidAddress': 'Työmaan osoite on puutteellinen. Osoite on korjattava, jotta verottajailmoitukset voidaan lähettää.',
  'label.invalidate': 'Poista',
  'label.invalidateContractsWithExpiredDocs': 'Sopimukset muutetaan puutteellisiksi, kun tilaajavastuudokumentit vanhentuvat',
  'label.invalidateUser': 'Poista käyttöoikeus',
  'label.invalidating': 'Mitätöidään...',
  'label.invalidContractorLiabilityReport': 'Raportti',
  'label.invalidContracts': 'Sopimuksissa on puutteita.',
  'label.invalidOrientedPermit': 'Työntekijän <strong>{0}</strong> pätevyydet eivät ole ajan tasalla',
  'label.invalidOrientedPermits': '<strong>{0}</strong> työntekijän pätevyydet eivät ole ajan tasalla',
  'label.invalidPermit': 'Työntekijän <strong>{0}</strong> työmaaluvassa on puutteita',
  'label.invalidPermits': '<strong>{0}</strong> työmaaluvassa puutteita',
  'label.invalidTaxNumber': 'Virheellinen veronumero',
  'label.invoiceHours': 'Tuntilappu',
  'label.invoiceHoursNotUsed': 'Tuntilappu ei käytössä.',
  'label.invoicehoursReport.heading': 'Tuntilappuraportti',
  'label.invoicehoursReport.info': 'Näyttää kaikkien aikavälin päivien tuntilaput annetulta työmaalta.',
  'label.invoiceHoursReport.PersonFilter': 'Hae henkilö (kirjoita väh 3 merkkiä)',
  'label.invoiceHoursReport.roleFilter': 'Työntekijän rooli',
  'label.invoiceHoursReport': 'Tuntilappu',
  'label.invoiceHoursWaitingForApproval': 'Hyväksyntää odottavia tunteja.',
  'label.invoiceHoursWaitingForApprovalManySites': 'Hyväksyntää odottavia tunteja <strong>{0}</strong> työmaalla.',
  'label.invoiceHoursWaitingForApprovalOneSite': 'Työmaalla <strong>{0}</strong> hyväksyntää odottavia tunteja.',
  'label.isForeman': 'Henkilö toimii työnjohtajana työmaalla',
  'label.issued': 'Myönnetty',
  'label.jobName': 'Työn nimi',
  'label.jobNumber': 'Työnumero',
  'label.jobNumberList': 'Työnumero-/litteralista',
  'label.jobNumbers': 'Työnumerot',
  'label.jobNumberSearch': 'Työnumero-/litterahaku',
  'label.jobTitleAddOption': 'Työnimikkeiden lisääminen',
  'label.jobTitleList': 'Työnimikelista',
  'label.jobTitles': 'Työtehtävät',
  'label.known': 'Tunnettu',
  'label.labor': 'Työ',
  'label.laborAgreement': 'Työehtosopimus pakollinen',
  'label.langAndNationality': 'Kieli ja kansalaisuus',
  'label.languages': 'Kielet',
  'label.lastCheckIn': 'Edellinen sisäänleimaus',
  'label.lastCheckOut': 'Edellinen ulosleimaus',
  'label.lastContractEnded': '(viimeisin sopimus päättyi {0})',
  'label.lastMonth': 'Viime kuukausi',
  'label.lastName': 'Sukunimi',
  'label.lastWeek': 'Viime viikko',
  'label.latest': 'Viimeisimmät',
  'label.liabilityDocs': 'Tilaajavastuudokumentit',
  'label.liabilityDocsAwaitingApproval': 'Tilaajavastuudokumentti odottaa hyväksyntää',
  'label.liabilityDocsRejected': 'Tilaajavastuudokumentti hylätty',
  'label.liabilityInsuranceEnded': 'vakuutus päättynyt',
  'label.listPersonsWihtoutCompetence': 'Listaa henkilöt, joilta <em>puuttuu</em> valittu lupa',
  'label.listToday': 'Tänään:',
  'label.listTomorrow': 'Huomenna:',
  'label.loading': 'Ladataan',
  'label.loadingMoreResults': 'Ladataan lisää tuloksia...',
  'label.loadMoreResults': 'Lataa lisää tuloksia',
  'label.location': 'Sijainti',
  'label.locationOnSite': 'Sijainti työmaalla',
  'label.locationShort': 'Sij.',
  'label.loginToggleEmail': 'Sähköposti',
  'label.logout': 'Kirjaudu ulos',
  'label.mainContract': 'Pääurakka',
  'label.mainContractor': 'Pääurakoitsija',
  'label.mainContractorAndDeveloperShort': 'Til. & pääurak.',
  'label.mainOrSideContract': 'Pää- tai sivu-urakka',
  'label.manage': 'Hallinta',
  'label.management': 'Hallinta',
  'label.manpowerArea': 'Läsnäolonseuranta-alue',
  'label.markOrientationCompleted': 'Merkkaa henkilöt vahvistetuiksi',
  'label.materials': 'Materiaalit',
  'label.materialsAddOption': 'Materiaalien lisääminen',
  'label.maxFileSize': 'Tiedoston suurin sallittu koko {0}Mt',
  'label.message': 'Viesti',
  'label.messaging': 'Viestintä',
  'label.missing': 'Puuttuu',
  'label.missingCardId': 'Kulkutunniste puuttuu',
  'label.missingData': 'Tietoja odotetaan',
  'label.missingEmployeeInfo': 'Henkilötietojen täydennys',
  'label.missingTagOnlyUserCompany': 'Ei yrityksen {0} kulkutunnistetta tai Valttikorttia',
  'label.mobile.home': 'Etusivu',
  'label.modifiedFromOriginal': 'Muokattu alkuperäisestä',
  'label.mondayShort': 'ma',
  'label.month': 'Kuukausi',
  'label.move': 'Siirrä',
  'label.moveContract': 'Siirrä sopimus',
  'label.moveContractHeading': 'Siirrä sopimus?',
  'label.moving': 'Siirretään...',
  'label.multipleEmployeesWithInvalidTaxNumber': 'Virheellinen veronumero <strong>{0}</strong> henkilöllä.',
  'label.name': 'Nimi',
  'label.nameInEnglish': 'Nimi englanniksi',
  'label.nameInFinnish': 'Nimi suomeksi',
  'label.nameNotFound': 'Ei löytynyt',
  'label.narrowPrintView': 'Kapea tulostusnäkymä',
  'label.nationality': 'Kansalaisuus',
  'label.nEvaluations': '{0} arviointia',
  'label.new': 'Uusi',
  'label.newAccessArea': 'Uusi kulkualue',
  'label.newAccessPermit': 'Uusi työmaalupa',
  'label.newcomerTraining': 'Tulokoulutus',
  'label.newcomerTrainingDone': 'Tulokoulutus suoritettu',
  'label.newCompany': 'Uusi yritys',
  'label.newCompetence': 'Uusi pätevyys',
  'label.newContract': 'Uusi sopimus',
  'label.newContractor': 'Uusi urakoitsija',
  'label.newCriterion': 'Uusi kriteeri',
  'label.newDevice': 'Uusi laite',
  'label.newExpertise': 'Uusi osaaminen',
  'label.newMainOrSideContract': 'Uusi pää- tai sivu-urakointisopimus',
  'label.newMainOrSideContractStrong': 'Uusi <strong>pää-</strong> tai <strong>sivu-urakointi</strong>sopimus',
  'label.newPayment': 'Uusi tapahtuma',
  'label.newPerson': 'Uusi henkilö',
  'label.newSideContract': 'Uusi sivu-urakointisopimus',
  'label.newSideContractStrong': 'Uusi <strong>sivu-urakointi</strong>sopimus',
  'label.newSite': 'Uusi työmaa',
  'label.newSiteAccessArea': 'Uusi työmaan kulkualue',
  'label.newSubContract': 'Uusi aliurakointisopimus',
  'label.newSubContractStrong': 'Uusi <strong>aliurakointi</strong>sopimus',
  'label.newUser': 'Uusi käyttäjä',
  'label.newUserGroup': 'Uusi käyttäjärooli',
  'label.nextDay': 'Seuraava päivä',
  'label.nickName': 'Kutsumanimi',
  'label.no': 'Ei',
  'label.noAccessAreas': 'Yhtään kulkualuetta ei ole vielä lisätty!',
  'label.noActiveContracts': 'Urakoitsijalla ei ole yhtään aktiivista sopimusta.',
  'label.noAffectToTaxReports': 'Arvio ei vaikuta verottajailmoitusten lähettämiseen',
  'label.noCheckInsOuts': 'Ei leimauksia',
  'label.noContractForNewPerson': 'Urakoitsijalla ei ole sopimuksia, joihin voisi lisätä henkilöitä.',
  'label.noFiles': 'Ei tiedostoja',
  'label.noNameSuggestion': 'Nimiehdotusta ei löytynyt - anna nimi',
  'label.noPhoto': 'Ei kuvaa',
  'label.noReportFoundForContractor': 'Urakoitsijalle ei löytynyt tilaajavastuuraporttia',
  'label.noSafetyQuarters': 'Ei merkittyjä turvavartteja',
  'label.notActivated': 'Ei aktivoitu',
  'label.noTagsAtAll': 'Ei kulkutunnisteita',
  'label.notAvailable': 'Ei saatavilla',
  'label.notDefined': 'Ei määritelty',
  'label.notes': 'Lisätietoja',
  'label.notHandled': 'Ei käsitelty',
  'label.noThanks': 'Ei, kiitos',
  'label.notification': 'Ilmoitukset',
  'label.notificationInfo': 'Ilmoitukset näkyvät kaikille työmaan urakoitsijoille etusivulla.',
  'label.notificationSingular': 'Ilmoitus',
  'label.notKnown': 'Ei tiedossa',
  'label.notUsed': 'Ei käytössä',
  'label.noValttiTags': 'Älä huomioi Valttikortteja',
  'label.noVehiclePermits': 'Ei ajoneuvolupia.',
  'label.noVehiclePermitTypes': 'Yhtään ajoneuvolupatyyppiä ei ole vielä lisätty!',
  'label.numberOfActiveEmployees': 'Aktiivisia työntekijöitä',
  'label.numberOfCompanies': 'Yrityksiä',
  'label.numberOfEmployees': 'Työntekijöitä',
  'label.numberOfEmployeesDescription': 'Henkilöt, joilla on työmaalupia voimassa.',
  'label.ok': 'Selvä',
  'label.okThanks': 'Selvä, kiitos!',
  'label.oldestFirst': 'Vanhin ensin',
  'label.oneEvaluation': '1 arviointi',
  'label.online': 'Sähköinen',
  'label.onlyAllocatedToSiteTags': 'Huomioi vain työmaan lisäämät kulkutunnisteet',
  'label.onlyEmployeesPresentAtSite': 'Vain sisäänleimanneet',
  'label.onlyExpats': 'Vain lähetetyt työntekijät',
  'label.onlyForeignEmployees': 'Vain ulkomaiset työntekijät',
  'label.onlyForeMen': 'Vain työnjohtajat',
  'label.onlyOwnEmployees': 'Vain omat työntekijät',
  'label.onlyOwnTags': 'Älä huomioi Valttikortteja',
  'label.onlyOwnTagsInfo': 'Valttikortteja ei lasketa kulkutunnisteiksi',
  'label.onPaper': 'Paperille',
  'label.onSites': '{0} työmaalla',
  'label.open': 'Avaa',
  'label.openPersonSummary': 'Avaa henkilö',
  'label.openReport': 'Avaa raportti (PDF)',
  'label.operationFailed': 'Toiminnon suorittaminen epäonnistui.',
  'label.operationRunning': 'Suoritetaan toimintoa...',
  'label.optional': 'Valinnainen',
  'label.or': 'tai',
  'label.orCreateSideContract': 'tai luo sivu-urakointisopimus',
  'label.orientation': 'Vahvistus perehdytyksestä',
  'label.orientationForm': 'Perehdytyslomake',
  'label.orientationFormLabel': 'Perehdyttämis&shy;lomake',
  'label.orientationFormLink': 'Perehdyttämislomake (PDF)',
  'label.orientations': 'Vahvistukset',
  'label.orSelectFromThesePreviousRepresentatives': 'Tai valitse näistä aikaisemmista edustajista',
  'label.orUseOtherIdCardType': 'tai käytä muuta henkilötunnistekorttia',
  'label.other': 'Muu',
  'label.otherCompetences': 'Muut pätevyydet',
  'label.otherDevice': 'Muu laite',
  'label.otherDocuments': 'Muut dokumentit',
  'label.otherInfo': 'Muut tiedot',
  'label.otherLanguages': 'Muut kielitaidot',
  'label.otherPersonIdentifierGroup': 'Muu yksilöivä henkilötunniste',
  'label.otherRequiredInfo': 'Muut pakolliset tiedot',
  'label.out': 'Ulos',
  'label.outsideSite': 'Olet työmaa-alueen ulkopuolella. Leimaukseesi liitetään huomio.',
  'label.ownEmployees': 'omaa työntekijää',
  'label.page': 'Sivu',
  'label.participants': 'Osallistujia',
  'label.passport': 'Passi',
  'label.passportNumber': 'Passin numero',
  'label.password': 'Salasana',
  'label.paymentNote': 'Lisätieto',
  'label.paymentReference': 'Maksuviite',
  'label.paymentReverseVat': 'käännetty ALV',
  'label.payments': 'Tapahtumat',
  'label.paymentTypeInvoiced': 'Laskutettu',
  'label.paymentTypePaid': 'Maksettu',
  'label.paymentTypeScheduled': 'Odottaa',
  'label.paymentVat': 'suora ALV',
  'label.pending': 'Odottaa',
  'label.pendingApproval': 'Odottaa hyväksyntää',
  'label.pendingTagListUpdates': '<strong>{0}</strong> kulkutunnistetta päivittämättä porttileimauslaitteille.',
  'label.pensionInsurance': 'Eläkevakuutukset',
  'label.pensionInsuranceLegend': 'Todistukset eläkevakuutusten ottamisesta ja suorittamisesta tai selvitys erääntyneiden eläkevakuutus&shy;maksujen maksusopimuksesta',
  'label.peopleCounts': 'Vahvuudet',
  'label.permission.notAllowedToAddExpertise': 'Sinulla ei ole oikeutta lisätä tätä koulutusta. Tarvittaessa ota yhteys työmaan päätoteuttajan yhteyshenkilöön.',
  'label.permitActiveDaily': 'Lupa on voimassa päivittäin klo',
  'label.permitAwaitingIntroduction': 'Henkilön <strong>{0}</strong> työmaalupa odottaa vahvistusta perehdytyksestä.',
  'label.permitForContract': 'Luotu työmaalupa tullaan liittämään sopimukseen <strong>{0}</strong>',
  'label.permitlessEmployees': 'Näytä vain henkilöt, joilla ei ole vielä työmaalupia',
  'label.permitlessEmployeesShort': 'Luvittamattomat',
  'label.permits': 'Työmaaluvat',
  'label.permitsAwaitingIntroduction': '<strong>{0} työmaalupaa</strong> odottaa vahvistusta perehdytyksestä.',
  'label.permitsDesc': 'Näytä vain henkilön viimeisin lupa',
  'label.permitsShort': 'Luvat',
  'label.person.missingIdentifier': 'Tunniste puuttuu. Lisää henkilölle tunniste lisätäksesi hänet työntekijänä.',
  'label.person': 'Henkilö',
  'label.personDetailsDoesntMatchWithRegistry': 'Syötetyt henkilötiedot eivät täsmää rekisteristä löytyviin tietoihin, joissa ',
  'label.personDetailsDoesntMatchWithSSG': 'Syötetyt henkilötiedot eivät täsmää SSG Access kortin tietoihin, joissa ',
  'label.personDetailsNameDoesntMatch':
    '<span><strong>sukunimi</strong> on <strong>{0}</strong> </span>' +
    '<span>ja <strong>etunimi</strong> on <strong>{1}</strong></span>',
  'label.personDetailsBirthDateDoesntMatch': '<strong>syntymäaika</strong> on <strong>{0}</strong>',
  'label.personIdentifiers': 'Henkilön tunnisteet',
  'label.personIdentifiersHelp': 'Vähintään yksi tunniste vaaditaan. Yleensä veronumero riittää. Vaatimus on työmaakohtainen.',
  'label.personnelGate': 'Henkilöportti',
  'label.persons': 'Henkilöt',
  'label.personSearch': 'Henkilöhaku',
  'label.personSearchExample': 'Esimerkiksi nimi, veronumero, sähköposti tai puhelinnumero.',
  'label.personsRole': 'Henkilön rooli',
  'label.phoneNumberRequiredHint': 'Mihin puhelinnumeroa tarvitaan?',
  'label.photo': 'Kuva',
  'label.photos': 'Kuvat',
  'label.photoShort': 'Kuva',
  'label.portDirection': 'Portin suunta',
  'label.postcode': 'Postinumero',
  'label.postingDate': 'Tilityspäivä',
  'label.postOffice': 'Postitoimipaikka',
  'label.prefilledForm': 'Esitäytetty lomake',
  'label.prepayment': 'Ennakkoperintä',
  'label.prepaymentLegend': 'Selvitys ennakko&shy;perintä&shy;rekisteri&shy;merkinnästä',
  'label.preventsOrientation': 'Perehdyttäminen ei ole mahdollista ennenkuin pätevyyksiin ja koulutuksiin liittyvät vaatimukset täyttyvät.',
  'label.previousCLReports': 'Muut tilaajavastuuraportit',
  'label.previousDay': 'Edellinen päivä',
  'label.primaryLanguage': 'Kieli',
  'label.primaryLanguageInfo': 'Zeroni toimii valitulla kielellä.',
  'label.print': 'Tulosta',
  'label.printCard': 'Tulosta kortti',
  'label.printCardA4': 'Tulosta kortti(A4)',
  'label.printVehiclePermit': 'Tulosta ajoneuvolupa',
  'label.processDate': 'Otettu käsittelyyn',
  'label.processed': 'Käsitelty',
  'label.processStatus': 'Tila',
  'label.profession': 'Ammattinimike',
  'label.projectNumber': 'Projektinumero',
  'label.quickOptions': 'Pika&shy;valinnat',
  'label.quickTimeSpans': 'Pika-aikavälit',
  'label.readingRights': 'Luku',
  'label.readMore': 'Lue lisää',
  'label.ready': 'Valmis',
  'label.reasonForRejection': 'Hylkäyksen syy',
  'label.receptionCommitment': 'Vastaanottositoomus',
  'label.references': 'Litterat',
  'label.region': 'Toiminta-alue',
  'label.regionExamples': 'Esim. Uusimaa, Pohjois-Savo',
  'label.regions': 'Toiminta-alueet',
  'label.reject': 'Hylkää',
  'label.rejectAccessPermitHeading': 'Päätä työmaalupa {0}',
  'label.rejected': 'Hylätty',
  'label.rejecting': 'Päätetään...',
  'label.remove': 'Poista',
  'label.removeContract': 'Poista sopimus',
  'label.removeContractHeading': 'Poista sopimus?',
  'label.removed': 'Poistettu',
  'label.removedTrainingAndCourses': 'Poistetut koulutukset ja kurssit',
  'label.removeMeasurement': 'Poista mittaus',
  'label.removeRow': 'Poista rivi',
  'label.removeSelected': 'Poista valitut',
  'label.removeSelections': 'Poista valinnat',
  'label.removeSiteAccessArea': 'Poista työmaan kulkualue',
  'label.removeVehiclePermitType': 'Poista työmaan ajoneuvolupa',
  'label.removing': 'Poistetaan...',
  'label.repairBy': 'Korjattava',
  'label.repaired': 'Korjattu',
  'label.replayToApproval': 'Hyväksy/hylkää',
  'label.report': 'Raportti',
  'label.reportHazards': 'Ilmoita turvallisuushavainnoista',
  'label.reportInjuries': 'Ilmoita tapaturmista',
  'label.reportNearMisses': 'Ilmoita läheltä piti -tilanteista',
  'label.reports': 'Raportit',
  'label.reportsToTaxAdmin': 'Verottajailmoitukset',
  'label.reportTax': 'Ilmoitetaan verottajalle',
  'label.ReportWorkHourBalance.alert': 'Ei työajanseurantatyömaita. Jos olet kiinnostunut saldojen seurannasta, ota yhteyttä support@zeroni.fi.',
  'label.ReportWorkHourBalanceSite.alert': 'Työajanseuranta ei ole voimassa tällä työmaalla. Jos olet kiinnostunut saldojen seurannasta, ota yhteyttä support@zeroni.fi.',
  'label.required': 'Pakollinen tieto',
  'label.requiredReportsToTaxAdmin': 'Ilmoitusvelvollisuudet',
  'label.residencePermit.info': 'EU-maiden ulkopuolelta Suomeen saapuva työntekijä tarvitsee työntekoon oikeuttavan oleskeluluvan. <a href="https://migri.fi/tyoskentely-suomessa" rel="noopener" target="_blank">Lue lisää <span class="far fa-external-link"></span></a> (migri.fi)',
  'label.residencePermit': 'Työntekijän oleskelulupa',
  'label.residencePermitContinuationDueDate': 'Jatkoluvan tarkistuspäivä',
  'label.residencePermitContinuationDueDatePassed': '<strong>Oleskeluluvan</strong> jatkohakemuksen tila tarkistettava',
  'label.residencePermitContinuationHelp': 'Määrittele päivä, jolloin oleskeluluvan jatkon pitäisi olla myönnetty. Etusivulle ilmestyy muistutus oleskeluluvasta annetun päivän jälkeen, mikäli työmaalupa on vielä silloin voimassa.',
  'label.residencePermitContinuationReminder': 'Päivitä uuden oleskeluluvan tiedot tai muuta jatkoluvan tarkistuspäivää. Älä unohda lisätä uusia dokumentteja.',
  'label.residencePermitContinuationRequested': 'Jatkolupa haettu',
  'label.residencePermitExpired': 'Oleskelulupa päättynyt',
  'label.residencePermitExpires': 'Oleskelulupa päättyy',
  'label.residencePermitExpiring': 'Oleskelulupa päättymässä',
  'label.residencePermitNumber': 'Oleskeluluvan numero',
  'label.residencePermitsExpiredOrExpiring': '<strong>{0} oleskelulupaa</strong> on päättynyt tai päättymässä 14 päivän sisällä.',
  'label.residencePermitType': 'Oleskeluluvan tyyppi',
  'label.restArea': 'Sosiaalitila',
  'label.restAreaNeeded': 'Tarvitaan sosiaalitila',
  'label.restore': 'Palauta',
  'label.restoring': 'Palautetaan...',
  'label.result': 'tulos',
  'label.result2': 'tulosta',
  'label.resultAccessPermit': 'työmaalupa',
  'label.resultAccessPermits': 'työmaalupaa',
  'label.resultCompanies': 'yritystä',
  'label.resultCompany': 'yritys',
  'label.resultContract': 'sopimus',
  'label.resultContractor': 'urakoitsija',
  'label.resultContractors': 'urakoitsijaa',
  'label.resultContracts': 'sopimusta',
  'label.resultDevice': 'laite',
  'label.resultDevices': 'laitetta',
  'label.resultEmployee': 'työntekijä',
  'label.resultEmployees': 'työntekijää',
  'label.resultOwn': 'omaa',
  'label.resultPerson': 'henkilö',
  'label.resultPersons': 'henkilöä',
  'label.resultsByContractor': 'urakoitsijan',
  'label.resultSite': 'työmaa',
  'label.resultSites': 'työmaata',
  'label.resultUser': 'käyttäjä',
  'label.resultUserGroup': 'käyttäjärooli',
  'label.resultUserGroups': 'käyttäjäroolia',
  'label.resultUsers': 'käyttäjää',
  'label.resultVisitor': 'vierailija',
  'label.resultVisitors': 'vierailijaa',
  'label.retryFailedAccessControlIntegrations': 'Käynnistä lähetys uudelleen',
  'label.return': 'Palauta',
  'label.returnId': 'Palauta kulkutunniste',
  'label.returning': 'Palautetaan...',
  'label.reverseVat': 'Käännetty ALV',
  'label.role': 'Rooli',
  'label.roleConsultant': 'Konsultointi',
  'label.roleContractor': 'Urakointi',
  'label.roleSecurity': 'Turvallisuus',
  'label.roofHotWorkForm': 'Kattotulityölomake',
  'label.rotatePhoto': 'Käännä',
  'label.safety': 'Turvallisuus',
  'label.safetyContactAt': 'Yritysturvallisuudesta vastaava yrityksessä {0}',
  'label.safetyIndex': 'Indeksi',
  'label.safetyMeasurementInfo': 'Havainnoissa käytettävät työturvallisuusmittaustyypit määritellään työmaan asetuksista. Kun mittaustyyppi on valittu, voidaan valita myös mittauskohde.',
  'label.safetyMeasurer': 'Mittaaja',
  'label.safetyReportContactsInfo1': 'Ylimääräiset vastaanottajat, joille haluat ilmoituksen turvallisuuspoikkeamista',
  'label.safetyReportContactsInfo2': 'Työmaan vastaavalle työnjohtajalle ja vastaavan urakoitsijan turvallisuusvastaavalle ilmoitetaan automaattisesti. Tapaturma- ja läheltä piti -tilanteissa ilmoitetaan myös tilaajan ja pääurakoitsijan turvallisuusvastaaville.',
  'label.safetyReportEmails': 'Sähköpostivastaanottajat',
  'label.safetyReports': 'Turvallisuusmittaukset',
  'label.safetyReportShort': 'Turvallisuus',
  'label.sameAsCompanyContact': 'Sama kuin yrityksen yhteyshenkilö',
  'label.saturdayShort': 'la',
  'label.save': 'Tallenna',
  'label.saveAgain': 'Tallenna uudestaan',
  'label.saveContractorObligationsDocuments': 'Tallenna tilaajavastuudokumentit',
  'label.saved': 'Tallennettu',
  'label.saveEvaluation': 'Tallenna arviointi',
  'label.saveFailed': 'Tallennus epäonnistui!',
  'label.saveIntroductionForm': 'Tallenna perehdyttämislomake',
  'label.saveModifications': 'Tallenna merkinnät',
  'label.saving': 'Tallennetaan...',
  'label.scannedCard': 'Kuva kortista',
  'label.scannedCompetence': 'Pätevyyden todistus',
  'label.scannedIdCard': 'Kuva henkilöllisyys&shy;todistuksesta',
  'label.scannedIntroductionForm': 'Kuva perehdyttämislomakkeesta',
  'label.scannedInvoice': 'Laskun kuva',
  'label.scannedInvoices': 'Laskun kuvat',
  'label.scannedSiteIdCard': 'Kuva yrityksen henkilötunnistekortista',
  'label.scannedVisa': 'Kuva viisumista',
  'label.searchByName': 'Etsi nimellä',
  'label.searchCollectiveLaborAgreements': 'Hae työehtosopimus',
  'label.searchCompanies': 'Hae yritys',
  'label.searchCompetences': 'Hae pätevyys',
  'label.searchContracts': 'Hae sopimus',
  'label.searchCountries': 'Hae valtio',
  'label.searchDevices': 'Hae läsnäoloseurantalaite',
  'label.searchEmployeeBycompetence.noCompetence': 'Henkilöt, joilta puuttuu valittu pätevyys',
  'label.searchEmployeesBySite': 'Hae työntekijät työmaan perusteella',
  'label.searchLanguages': 'Hae kieli',
  'label.searchNationalities': 'Hae kansalaisuus',
  'label.searchOnlyActiveEmployees': 'Aktiivinen työmaalupa',
  'label.searchOrSelectBuyer': 'Tilaaja',
  'label.searchOrSelectRole': 'Rooli',
  'label.searchPersons': 'Hae henkilö',
  'label.searchSites': 'Hae työmaa',
  'label.securityClearance': 'Turvallisuusselvitys',
  'label.securityClearanceDate': 'Turvallisuusselvitys tehty',
  'label.securityClearanceDateSentToPolice': 'Turvallisuusselvitys lähetetty poliisille',
  'label.securityClearanceFormEN': 'Lomakepohja: Turvallisuusselvitys ENG (PDF)',
  'label.securityClearanceFormFI': 'Lomakepohja: Turvallisuusselvitys FIN (PDF)',
  'label.securityClearanceTooltip': 'Fennovoiman yritysturvallisuusvastaava täyttää kun työntekijästä on toimitettu taustaselvitysasiakirjat. Selvityksen puuttuminen estää työmaakortin saannin.',
  'label.securityContact': 'Yritysturvallisuudesta vastaava yrityksessä {0}',
  'label.securityContactPerson': 'Yritysturvallisuudesta vastaava henkilö',
  'label.seeSiteSettings': 'Ks. työmaan asetukset',
  'label.select': 'Valitse',
  'label.selectAccessArea': 'Valitse kulkualue',
  'label.selectAll': 'Valitse kaikki',
  'label.selectAllActive': 'Valitse kaikki aktiiviset',
  'label.selectBuyer': 'Valitse tilaaja',
  'label.selectCollectiveLaborAgreement': 'Valitse työehtosopimus',
  'label.selectCompany': 'Valitse yritys',
  'label.selectContract': 'Valitse sopimus',
  'label.selectContractingType': 'Valitse urakkalaji',
  'label.selectContractPartner': 'Valitse sopimuskumppani',
  'label.selectCountry': 'Valitse valtio',
  'label.selectCurrentCountry': 'Valitse pääasiallinen työskentelyvaltio',
  'label.selectCurrentSite': 'Valitse käsiteltävä työmaa',
  'label.selectDevice': 'Valitse laite',
  'label.selected': 'Valittu',
  'label.selectEmployee': 'Valitse työntekijä',
  'label.selectEndDate': 'Valitse päättymispäivä',
  'label.selectFiles': 'Valitse tiedostot',
  'label.selectHomeCountry': 'Valitse kotivaltio',
  'label.selectIdType': 'Valitse tunnus',
  'label.selectManpowerArea': 'Valitse läsnäolonseuranta-alue',
  'label.selectNationality': 'Hae kansalaisuus',
  'label.selectParentContract': 'Valitse ketjutettava sopimus',
  'label.selectPerson': 'Valitse henkilö',
  'label.selectPhoto': 'Valitse kuva',
  'label.selectPrimaryLanguage': 'Valitse kieli',
  'label.selectProfession': 'Valitse ammattinimike',
  'label.selectRole': 'Valitse rooli',
  'label.selectSite': 'Valitse työmaa',
  'label.selectSiteFirst': 'Valitse ensin työmaa',
  'label.selectSiteForeman': 'Valitse vastaava työnjohtaja',
  'label.selectSiteOrForeman': 'Valitse työmaa tai hae työnjohtajat kaikilta työmailta',
  'label.selectTaxingCountry': 'Valitse verottava valtio',
  'label.send': 'Lähetä',
  'label.sendAsPending': 'Lähetä hyväksyttäväksi',
  'label.sendCopyToSender': 'Lähetä kopio sähköpostiisi',
  'label.sendEmail': 'Lähetä sähköpostia',
  'label.sendEmailToForemen': 'Lähetä viesti myös kaikille yrityksen työnjohtajille',
  'label.sendFile': 'Lähetä tiedosto',
  'label.sending': 'Lähetetään...',
  'label.sendingFailed': 'Lähetys epäonnistui!',
  'label.sendPasswordInSMS': 'Lähetä salasana',
  'label.sent': 'Lähetetty',
  'label.serviceCalls': 'Palvelukutsut',
  'label.serviceCallsReportInfo': 'Listaa työmaan urakoitsijoiden kaikki palvelukutsut, joihin kirjautuneella yrityksellä on näkyvyys.',
  'label.seSsn': 'Ruotsalainen henkilötunnus',
  'label.setPermitPeriodOfValidityAutomatically': 'Ehdota työmaaluvan kestoksi sopimuksen voimassaoloaika',
  'label.settings': 'Asetukset',
  'label.shoeSize': 'Kengän koko',
  'label.show': 'Näytä',
  'label.showAll': 'Näytä kaikki',
  'label.showAllIdentifiers': 'Näytä kaikki henkilön tunnisteet',
  'label.showAllSiteCards': 'Näytä kaikki',
  'label.showAllSites': 'Näytä tiedot kaikilta työmailta',
  'label.showAllTags': 'Näytä kaikki',
  'label.showAlsoCompleted': 'Näytä myös päättyneet',
  'label.showAlsoValttiCards': 'Näytä myös Valttikortit',
  'label.showCheckIns': 'Näytä leimaukset',
  'label.showCompleteDetails': 'Näytä kaikki tiedot',
  'label.showContractEvaluation': 'Näytä sopimuksen arviointi',
  'label.showContracts': 'Näytä sopimukset',
  'label.showEmptyDays': 'Näytä tyhjät päivät',
  'label.showEvaluation': 'Näytä arviointi',
  'label.showEvaluations': 'Näytä arvioinnit',
  'label.showForActiveSites': 'Näytä aktiivisten työmaiden tiedot',
  'label.showLess': 'Näytä vähemmän',
  'label.showMore': 'Näytä lisää',
  'label.showOnlyActive': 'Näytä vain aktiiviset',
  'label.showOnlyActiveEmployees': 'Näytä vain aktiivisten työntekijöiden pätevyydet',
  'label.showOnlyActiveInTimePeriod': 'Näytä vain voimassaolevat',
  'label.showOnlyActiveLogbooks': 'Piilota päättyneet',
  'label.showOnlyAllocatedToSite': 'Näytä vain työmaalle lisätyt kulkutunnisteet',
  'label.showOnlyApprovedInvoiceHours': 'Näytä vain hyväksytyt',
  'label.showOnlyFirstAndLastCheckInOfTheDay': 'Näytä vain päivän ensimmäinen sisäänleimaus ja viimeinen ulosleimaus',
  'label.showOnlyForeMen': 'Vain työnjohtajat',
  'label.showOnlyGates': 'Näytä vain porttilaitteet',
  'label.showOnlyUnUsedSiteCards': 'Näytä vain ei käytössä olevat kulkutunnisteet',
  'label.showOnlyUnUsedTags': 'Näytä vain ei käytössä olevat kulkutunnisteet',
  'label.showOnlyUsedSiteCards': 'Näytä vain käytössä olevat kulkutunnisteet',
  'label.showOnlyUsedTags': 'Näytä vain käytössä olevat kulkutunnisteet',
  'label.showOnlyVacant': 'Näytä vain vapaat',
  'label.showPayments': 'Näytä tapahtumat',
  'label.showPreviousCLReports': 'Näytä aikaisemmat tilaajavastuuraportit',
  'label.showReport': 'Näytä raportti',
  'label.showSiteMainContracts': 'Näytä työmaan pääurakointisopimukset',
  'label.showTargetCompAndOtherCompetencesInfo': 'Näytä {0}- ja muut pätevyystiedot',
  'label.showWeeklyHours': 'Näytä viikkotunnit',
  'label.sideContract': 'Sivu-urakka',
  'label.sideContractor': 'Sivu-urakoitsija',
  'label.sideContractors': 'Sivu-urakoitsijat',
  'label.sign': 'Allekirjoita',
  'label.signature': 'Allekirjoitus',
  'label.signatureClarification': 'Nimenselvennys',
  'label.signedForm': 'Allekirjoitettu lomake',
  'label.site': 'Työmaa',
  'label.siteAccessAreaIdInfo': 'Kulkutunnistetta voidaan käyttää kulkualueen määrittämiseen ulkoisissa kulunvalvontajärjestelmissä.',
  'label.siteAccessAreaRemovalConfirmation': 'Haluatko varmasti poistaa työmaan kulkualueen <b>{0}</b>?',
  'label.siteAccessAreas': 'Työmaan kulkualueet',
  'label.siteAccessAreasUpdated': 'Kulkualueet tallennettu!',
  'label.siteAdditionalSettings': 'Lisäasetukset',
  'label.siteAddress': 'Työmaan osoite',
  'label.siteCardReport.all': 'kaikki',
  'label.siteCardReport.allTagsByCurrentCompany': 'Näytä kaikki yrityksen {0} myöntämät kulkutunnisteet',
  'label.siteCardReport.businessId': 'Y-tunnus',
  'label.siteCardReport.chooseContractorPlaceholder': 'Valitse halutessasi urakoitsija',
  'label.siteCardReport.closed': 'Suljettu',
  'label.siteCardReport.contactPerson': 'Yhteyshenkilö',
  'label.siteCardReport.contract': 'Sopimus:',
  'label.siteCardReport.contractor': 'Urakoitsija',
  'label.siteCardReport.email': 'Sähköposti',
  'label.siteCardReport.employer': 'Palkanmaksaja',
  'label.siteCardReport.endDate': 'Loppu pvm',
  'label.siteCardReport.hasEnded': 'Päättynyt',
  'label.siteCardReport.info': 'Listaa kaikki kulkutunnisteet annettujen kriteereiden mukaan.',
  'label.siteCardReport.missing': 'puuttuu',
  'label.siteCardReport.onlyClosed': 'vain ei käytössä olevat kulkutunnisteet',
  'label.siteCardReport.onlyNotClosed': 'vain käytössä olevat kulkutunnisteet',
  'label.siteCardReport.person': 'Henkilö',
  'label.siteCardReport.pickUpDate': 'Noutopvm',
  'label.siteCardReport.printed': 'Tulostettu',
  'label.siteCardReport.site': 'Työmaa:',
  'label.siteCardReport.startDate': 'Alku pvm',
  'label.siteCardReport.state': 'Tila',
  'label.siteCardReport.tag': 'Kulkutunniste',
  'label.siteCardReport.tags.info': '<b>Näytä kaikki</b> näyttää sekä käytössä olevat että muut tunnisteet. <b>Näytä vain ei käytössä olevat tunnisteet</b> näyttää palautetut, vahingoittuneet ja kadonneet tunnisteet.',
  'label.siteCardReport.tags': 'Kulkutunnisteet:',
  'label.siteCardReport.taxNumber': 'Veronumero',
  'label.siteCardReport.telephone': 'Puhelin',
  'label.siteCardReport.title': 'Kulkutunnisteraportti',
  'label.siteCardReport.waitingForPhotoShooting': 'Odottaa valokuvausta',
  'label.siteCardReport.waitingForPrinting': 'Odottaa tulostusta',
  'label.siteCards': 'Kulkutunnisteet',
  'label.siteCardsDesc': 'Näytä vain ei käytössä olevat kulkutunnisteet',
  'label.siteCreated': 'Työmaa luotu',
  'label.siteDeveloper': 'Tilaaja',
  'label.siteDevices': 'Työmaan <strong>{0}</strong> laitteet',
  'label.siteEnds': 'Valmistuu',
  'label.siteForeman.missingEmail': 'Sähköpostiosoite puuttuu',
  'label.siteForeman.missingPhoneNumber': 'Puhelinnumero puuttuu',
  'label.siteForeman.missingPhoneNumberAndEmail': 'Puhelinnumero ja sähköpostiosoite puuttuvat',
  'label.siteForeman': 'Vastaava työnjohtaja',
  'label.siteForemanContactInfo': 'Vastaavan työnjohtajan yhteystiedot',
  'label.siteInformation': 'Työmaan tiedot',
  'label.siteInvalidAddress': 'Työmaan <strong>{0}</strong> osoite on puutteellinen. Osoite on korjattava, jotta verottajailmoitukset voidaan lähettää.',
  'label.siteKey': 'Työmaa-avain',
  'label.siteMainContracts': 'Työmaan pääurakointisopimukset',
  'label.siteName': 'Työmaan nimi',
  'label.siteNumber': 'Työmaan numero',
  'label.siteOrContract': 'Työmaa / sopimus',
  'label.siteReport': 'Työmaaraportti',
  'label.siteReportHeading': 'Työmaaraportti',
  'label.siteReportInfo': 'Näyttää jokaisen työmaan perustiedot ja asetukset.',
  'label.siteReportInfo2': 'Raporttiin otetaan mukaan kaikki työmaat, joissa yritys on tilaaja ja/tai pääurakoitsija ja jotka ovat voimassa ainakin yhtenä päivänä annetulla aikavälillä.',
  'label.siteReportsAvailableWithinSite': 'Työmaakohtaiset raportit työmaan tiedoissa.',
  'label.siteReportShort': 'Työmaat',
  'label.siteRequirementSettings': 'Vaatimukset',
  'label.sites': 'Työmaat',
  'label.siteSearch': 'Työmaahaku',
  'label.sitesWithAnonymousReports': '<strong>{0}</strong> työmaalla on vahvistamattomia turvallisuushavaintoja.',
  'label.sitesWithIncompleteEmployees': '<strong>{0}</strong> työmaalla on puutteellisia työntekijöitä.',
  'label.sitesWithInvalidContracts': 'Sopimuksissa puutteita <strong>{0}</strong> työmaalla.',
  'label.sitesWithoutAddress': 'Osoite on puutteellinen <strong>{0}</strong> työmaalla. Osoite on korjattava, jotta verottajailmoitukset voidaan lähettää.',
  'label.sitesWithoutLocation': 'Aluetta ei ole määritelty <strong>{0}</strong> työmaalla.',
  'label.sitesWithPendingTagListUpdates': 'Kulkutunnisteita päivittämättä porttileimauslaitteille <strong>{0}</strong> työmaalla.',
  'label.sitesWithUnresolvedCheckIns': 'Tunnistamattomia leimauksia <strong>{0}</strong> työmaalla.',
  'label.siteWithAnonymousReports': 'Työmaalla <strong>{0}</strong> on vahvistamattomia turvallisuushavaintoja.',
  'label.siteWithInvalidContract': 'Työmaan <strong>{0}</strong> sopimuksissa on puutteita.',
  'label.siteWithoutLocation': 'Työmaan <strong>{0}</strong> aluetta ei ole määritelty.',
  'label.siteWithPendingTagListUpdates': 'Työmaalla <strong>{0}</strong> on <strong>{1}</strong> kulkutunnistetta päivittämättä porttileimauslaitteille.',
  'label.siteWithUnresolvedCheckIns': 'Työmaalla <strong>{0}</strong> on tunnistamattomia leimauksia.',
  'label.sms': 'Tekstiviesti (SMS)',
  'label.smsCheckIn': 'SMS-leimaus',
  'label.socialRoomsAndVehicles': 'Sosiaalitilat ja ajoneuvoluvat',
  'label.specialNeeds': 'Erilliset tarpeet',
  'label.splitFromOriginal': 'Pilkottu alkuperäisestä',
  'label.ssgAccess': 'SSG Access',
  'label.ssn': 'Suomalainen henkilötunnus',
  'label.ssoUsername': 'SSO-tunnus',
  'label.start_ends': 'Alkaa - päättyy',
  'label.startDateAbbrev': 'Alkamispvm.',
  'label.starting': 'Alkaen',
  'label.startingFrom': 'Alkaen {0}',
  'label.starts': 'Alkaa',
  'label.startTask': 'Aloita <strong>{0}</strong>',
  'label.status': 'Tila',
  'label.streetAddress': 'Katuosoite',
  'label.subcontract': 'Aliurakka',
  'label.subcontractFromParent': 'Ketjutetaan sopimuksesta {0}',
  'label.subcontracting': 'Ketjuttaminen',
  'label.subcontractor': 'Aliurakoitsija',
  'label.subcontractors': 'Aliurakoitsijat',
  'label.subcontracts.activeSubcontracts': 'Aktiiviset aliurakat',
  'label.subcontracts': 'Aliurakat',
  'label.suggest': 'Ehdota',
  'label.summary': 'Yhteenveto',
  'label.sundayShort': 'su',
  'label.superUserEyesOnly': 'Näkyy vain superkäyttäjille: ',
  'label.swedishBusinessId': 'Ruotsalainen yritystunnus',
  'label.tagBelongsTo': 'Kortti kuuluu henkilölle <strong>{0}</strong>.',
  'label.tagBelongsToWithCompany': 'Kortti kuuluu henkilölle <strong>{0} / {1}</strong>.',
  'label.tagReport': 'Kulkutunnisteraportti',
  'label.tagReportShort': 'Kulkutunnisteet',
  'label.tags.numberOfNew': '{0} uutta kulkutunnistetta',
  'label.tags.oneNew': '1 uusi kulkutunniste',
  'label.tags': 'Kulkutunnisteet',
  'label.tagsDesc': 'Näytä vain ei käytössä olevat kulkutunnisteet',
  'label.targetContract': 'Kohdesopimus',
  'label.taxAdmin': 'Verottaja',
  'label.taxCard': 'Verokortti',
  'label.taxingCountry': 'Verottava valtio',
  'label.taxNumber': 'Veronumero',
  'label.taxNumberShort': 'Veronro',
  'label.taxPayments': 'Veromaksut',
  'label.taxPaymentsLegend': 'Todistus verojen maksamisesta, verovelkatodistus tai selvitys verovelan maksusuunnitelmasta',
  'label.taxReportsSentAutomatically': 'Kaikki vaaditut urakkailmoitukset lähetetään automaattisesti.',
  'label.telephone': 'Puhelin',
  'label.telephoneNumber': 'Puhelinnumero',
  'label.telephoneNumberIsRequired': 'Puhelinnumero on pakollinen',
  'label.telephoneNumberInvalid': 'Puhelinnumero virheellinen',
  'label.telephoneShort': 'Puh.',
  'label.telephoneShortNoDot': 'Puh',
  'label.tempSave': 'Tallenna, älä lähetä',
  'label.tes': 'TES',
  'label.tesLegend': 'Selvitys sovellettavasta työehtosopimuksesta tai keskeisistä työehdoista',
  'label.thisMonth': 'Kuluva kuukausi',
  'label.thisWeek': 'Kuluva viikko',
  'label.thursdayShort': 'to',
  'label.time': 'Aika',
  'label.timeSpan': 'Aikaväli',
  'label.timeTracking': 'Työajanseuranta',
  'label.timeTrackingActive': 'Työajanseuranta päällä',
  'label.timezone': 'Aikavyöhyke',
  'label.timezoneOfDevices': 'Leimauslaitteiden aikavyöhyke',
  'label.tinNumber': 'TIN-tunnus',
  'label.today': 'Tänään',
  'label.todayAndYesterday': 'Tänään ja eilen',
  'label.tomorrow': 'Huomenna',
  'label.topic': 'Aihe',
  'label.topics': 'Aiheet',
  'label.tot': 'Yht.',
  'label.total': 'Yhteensä',
  'label.totalHoursOfMainContractor': 'pääurakoitsija',
  'label.totalHoursOfSubContractors': 'aliurakoitsijat',
  'label.totalPeopleCount': 'Kokonaisvahvuus',
  'label.totalShort': 'Yht.',
  'label.totalSmall': 'yhteensä',
  'label.totalValue': 'Yhteisarvo',
  'label.trackExpatWorkers': 'Ulkomaalaisten työntekijöiden tiedot',
  'label.trackExpatWorkersInfo': 'Ulkomaalaisilta työntekijöiltä vaaditaan todistus sosialiturvasta (esim. A1) sekä oleskeluluvan tiedot.',
  'label.tradeRegister': 'Kaupparekisteri',
  'label.tradeRegisterLegend': 'Voimassaoleva kaupparekisteriote',
  'label.trailer': 'Peräkärry',
  'label.trailerPermit': 'Lupa peräkärrylle',
  'label.trainee': 'Harjoittelija',
  'label.training': 'Koulutus',
  'label.trainingAndCourses': 'Koulutukset ja kurssit',
  'label.trainingAndCoursesName': 'Koulutuksen nimi',
  'label.trainingDescription': 'Henkilön osaamiseen valittavat koulutukset ja kurssit',
  'label.trainingExpired': 'Koulutus päättynyt',
  'label.trainingExpiring': 'Koulutus päättymässä',
  'label.trainingSettings': 'Koulutusasetukset',
  'label.trash.show': 'Näytä roskakori',
  'label.travelDocumentNumber': 'Matkustusasiakirjan numero',
  'label.travelExpenseClaims': 'Matkakorvaukset',
  'label.travelExpenseReport.heading': 'Matkalaskuraportti',
  'label.travelExpenseReport.info': 'Näyttää kaikki valittua työmaata koskevat ja valitulle aikavälille osuvat matkalaskut.',
  'label.travelExpenseReportShort': 'Matkalaskut',
  'label.truck': 'Kuorma-auto',
  'label.trustedPartner': 'Luotettava kumppani',
  'label.trustedPartnerReport': 'Luotettava kumppani -raportti',
  'label.tryAgain': 'Yritä uudelleen',
  'label.tryAgainOrContactSupport': 'Ole hyvä ja yritä uudelleen. Jos ongelma ei ratkea, ota yhteyttä tukeen (support@zeroni.fi).',
  'label.tts': 'Työturvallisuussuunnitelmat',
  'label.tuesdayShort': 'ti',
  'label.työaika': 'Tuntilappu',
  'label.type': 'Tyyppi',
  'label.unallocatedPayments': 'Kohdistamattomat tapahtumat',
  'label.unclear': 'Selvitettävää',
  'label.unconfirmed': 'Varmentamaton',
  'label.undefinedLocation': 'Aluetta ei ole määritelty',
  'label.underagePersonOnSite': '<strong>Alaikäinen</strong> henkilö työmaalla',
  'label.underagePersonsOnSite': '<strong>{0} alaikäistä</strong> henkilöä työmaalla',
  'label.undoing': 'Perutaan...',
  'label.universalSearchPlaceholder': 'Haku',
  'label.unknown': 'Tuntematon',
  'label.unorientedCheckedInEmployee': 'Vahvistamaton henkilö <strong>{0}</strong> on leimannut sisään työmaalle',
  'label.unorientedCheckedInEmployees': '<strong>{0}</strong> vahvistamatonta henkilöä on leimannut sisään työmaalle',
  'label.unpaid': 'Palkaton',
  'label.unresolvedCheckInImei': 'Leimauslaite: <strong>{0}</strong> <span class="text-muted">(IMEI: {1})</span>',
  'label.unresolvedCheckIns': 'Kulkutunnisteella <strong>{0}</strong> tunnistamaton leimaus <strong>{1}.</strong>',
  'label.unresolvedCheckinsShort': 'Tunnistamattomia leimauksia.',
  'label.unresolvedContractPayments': 'Kohdistamattomat tapahtumat',
  'label.unresolvedFENNOCheckIns': 'Henkilöllä <strong>{0}</strong>(kortti: {1}) tunnistamaton leimaus <strong>{2}</strong>',
  'label.unresolvedTaxNumberCheckIns': 'Veronumerolla <strong>{0}</strong> tunnistamaton leimaus <strong>{1}.</strong>',
  'label.unresolvedVALTTICheckIns': 'Valttikortilla <strong>{0}</strong> tunnistamaton leimaus <strong>{1}.</strong>',
  'label.update': 'Päivitä',
  'label.updateAccessPermit': 'Päivitä työmaalupa',
  'label.updateCompany': 'Päivitä yritys',
  'label.updateCompetence': 'Päivitä pätevyys',
  'label.updateContacts': 'Päivitä yhteyshenkilöt',
  'label.updateContract': 'Päivitä sopimus',
  'label.updated': 'Päivitetty',
  'label.updatedDeclaration': 'Korjausilmoitus',
  'label.updateDevice': 'Päivitä laite',
  'label.updatePerson': 'Päivitä henkilö',
  'label.updateSafetyReport': 'Päivitä tapahtuma',
  'label.updateSite': 'Päivitä työmaa',
  'label.updateSiteAdditionalSettings': 'Päivitä lisäasetukset',
  'label.updateSiteRequirementSettings': 'Päivitä vaatimukset',
  'label.updateSiteWorkTimes': 'Päivitä työajat',
  'label.updateUser': 'Päivitä käyttäjä',
  'label.updateUserGroup': 'Päivitä käyttäjärooli',
  'label.updating': 'Päivitetään...',
  'label.uploadCsv': 'Lähetä CSV',
  'label.useCompanyAddress': 'Käytä yrityksen osoitetta',
  'label.useCompanyFinnishAddress': 'Käytä yrityksen Suomen osoitetta',
  'label.useCompanyForeignAddress': 'Käytä yrityksen ulkomaan osoitetta',
  'label.usedNotRequired': 'Käytössä, valinnainen',
  'label.usedRequired': 'Käytössä, pakollinen',
  'label.user': 'Käyttäjä',
  'label.userGroup': 'Käyttäjärooli',
  'label.userGroups': 'Käyttäjäroolit',
  'label.username': 'Käyttäjätunnus',
  'label.usernameShort': 'Tunnus',
  'label.userReport': 'Käyttäjäraportti',
  'label.userReportInfo': 'Luo Excel-tiedoston, joka listaa kaikki yrityksen käyttäjätunnukset.',
  'label.userReportShort': 'Käyttäjät',
  'label.users': 'Käyttäjät',
  'label.userSearch': 'Käyttäjähaku',
  'label.useThis': 'Käytä tätä',
  'label.valid': 'Voimassa',
  'label.validIndefinately': 'Voimassa toistaiseksi',
  'label.validShort': 'Voim.',
  'label.validTaxNumber': 'Veronumero kunnossa',
  'label.validUntil': 'Voimassa <strong>{0}</strong> asti',
  'label.valttiCard': 'Valttikortti',
  'label.valttiCardAdded': 'Valttikortti lisätty!',
  'label.valttiCardExpired': 'Valttikortti päättynyt',
  'label.valttiCardExpiredOrExpiring': '<strong>{0} Valttikorttia</strong> on päättynyt tai päättymässä kuukauden sisällä',
  'label.valttiCardExpiring': 'Valttikortti päättymässä',
  'label.valttiCardNumber': 'Valttikortin numero',
  'label.valttiCards': 'Valttikortit',
  'label.valttiChecking': 'Varmistetaan Valttikortin voimassaolo...',
  'label.valttiContractorHasManyContractsAtSite': 'Urakoitsijalla on työmaalla useampi kuin yksi sopimus. Valitse sopimus, jolle haluat luoda työmaaluvan.',
  'label.valttiFoundWithNumber': 'Valttikortin numerolla {0} löytyy kortti henkilölle:',
  'label.valttiSelectedButMissing': 'Henkilötunnistekortiksi on valittu Valttikortti, mutta työntekijällä ei ole lisätty urakoitsijayritystä vastaavia Valttikortteja.',
  'label.value': 'Arvo',
  'label.van': 'Pakettiauto',
  'label.vanShort': 'Paketti-',
  'label.vatNumber': 'VAT-numero',
  'label.vatObligation': 'ALV-velvollisuus',
  'label.vatObligationLegend': 'Selvitys arvonlisävero&shy;velvollisten rekisteri&shy;merkinnästä',
  'label.vehicle': 'Ajoneuvo',
  'label.vehicleConfirmRemove': 'Poistetaanko ajoneuvo <b>{0}</b> luvalta?',
  'label.vehicleConfirmRemoveHeading': 'Vahvista poistaminen',
  'label.vehicleDetails': 'Ajoneuvon tiedot',
  'label.vehicleGate': 'Ajoneuvoportti',
  'label.vehiclePermit': 'Ajolupa',
  'label.vehiclePermit2': 'Ajoneuvolupa',
  'label.vehiclePermitCardId': 'Ajoluvan tunniste',
  'label.vehiclePermitId': 'Ajoluvan numero',
  'label.vehiclePermitNeeded': 'Tarvitaan ajolupa',
  'label.vehiclePermitReasons': 'Käyttötarkoitus',
  'label.vehiclePermits': 'Ajoneuvoluvat',
  'label.vehiclePermitsShort': 'Ajoneuvot',
  'label.vehiclePermitTypeRemovalConfirmation': 'Haluatko varmasti poistaa ajoneuvolupatyypin <b>{0}</b>?',
  'label.vehicleRegistrationNumber': 'Rekisterinumero',
  'label.vehicleRegistrationNumberAndType': 'Rekisterinumero ja ajoneuvon tyyppi',
  'label.vehicleRemove': 'Poista ajoneuvo',
  'label.vehicleReportHeading': 'Ajoneuvoluvat',
  'label.vehicleReportInfo': 'Hakee raportin kaikista ajoneuvoluvista, jotka ovat voimassa ainakin yhtenä päivänä annetulla aikavälillä.',
  'label.vehicleReportShowOnlyAccepted': 'Näytä vain hyväksytyt',
  'label.vehicles': 'Ajoneuvot',
  'label.vehiclesFromTo': 'Ajoneuvoluvat ajalla {0}-{1}',
  'label.vehicleTimespanTooltip': 'Näyttää ajoneuvoluvat, jotka ovat voimassa ainakin yhtenä päivänä annetulla aikavälillä.',
  'label.vehicleType': 'Ajoneuvon tyyppi',
  'label.verify': 'Tarkasta työnteko-oikeus',
  'label.verifySummary': 'Näytä työnteko-oikeus',
  'label.visa': 'Viisumi',
  'label.visaNumber': 'Viisumin numero',
  'label.visitor': 'Vierailija',
  'label.visitorPermits': 'Tuoteturvallisuusluvat',
  'label.visitors': 'vierailijat',
  'label.volume.timespanTooltip': 'Ottaa raporttiin annetulla aikavälillä tehdyt mittaukset tai poikkeukset.',
  'label.volumeReport': 'Volyymiraportti',
  'label.volumeReportHeading': 'Volyymiraportti',
  'label.volumeReportInfo': 'Näyttää jokaiselta työmaalta tietoa urakoitsijoista, työntekijöistä, tehdyistä työtunneista ja sopimuksista.',
  'label.volumeReportInfo2': 'Raporttiin otetaan mukaan kaikki sopimukset, jotka ovat voimassa ainakin yhtenä päivänä annetulla aikavälillä. Työntekijöistä huomioidaan ne, joilla on voimassa oleva työmaalupa ainakin yhtenä päivänä aikavälillä. Lisäksi raportti kertoo kaikki aikavälillä tehdyt työtunnit.',
  'label.volumeReportShort': 'Volyymi',
  'label.voluntary': 'Vapaaehtoinen',
  'label.wage': 'Tuntipalkka',
  'label.waitingApproval': 'Odottaa hyväksyntää',
  'label.waitingForAccessPermitApproval': 'Lupaa ei voi hyväksyä. Henkilöllä ei ole hyväksyttyä työmaalupaa ajoneuvoluvan koko voimassaolon ajaksi.',
  'label.waitingForOrientation': 'Lupaa ei voi hyväksyä. Henkilön perehdytystä ei ole vahvistettu.',
  'label.waitingForPersonsOrientationToGainVehicleGateAccess': 'Pääsy ajoneuvoportista estetty. Pääsy edellyttää että henkilön perehdytys on vahvistettu.',
  'label.waitingForSignature': 'Odottaa allekirjoitusta',
  'label.waitingRequiredCompetences': 'Odottaa vaadittavia pätevyyksiä',
  'label.wednesdayShort': 'ke',
  'label.week': 'Viikko',
  'label.weeklyHours': 'Viikkotunnit',
  'label.weeklyHoursAlarm': 'Viikkotuntihälytys',
  'label.weekShort': 'vk',
  'label.weeksShort': 'vk.',
  'label.when': 'Milloin',
  'label.whoIsExpat': 'Kuka on lähetetty työntekijä?',
  'label.whyCantIEdit': 'Miksi ei muokattavissa?',
  'label.withdrawApproval': 'Peru hyväksyntä',
  'label.withdrawing': 'Perutaan...',
  'label.withoutTagDesc': 'Näytä vain henkilöt, joilta puuttuu kulkutunniste',
  'label.workDayTooLong': 'Varoitus: yli {0} tunnin työpäivä',
  'label.workerSignature': 'Perehdytettävän allekirjoitus',
  'label.workExperience': 'Työkokemusvuosia',
  'label.workHourBalance': 'Saldokertymä',
  'label.workHourBalanceReport': 'Saldoraportti',
  'label.workingHoursCombined': 'Yhteenlasketut työtunnit kaikilta työmailta',
  'label.workingTime': 'Työaika',
  'label.workPermitExpires': 'Työlupa voimassa',
  'label.workRelation': 'Työsuhteen laatu',
  'label.workSafetyCard': 'Työturvallisuuskortti',
  'label.workTime': 'Työaika',
  'label.workTimeHeadingFromTo': 'Työaikaraportti {0}-{1}',
  'label.workTimeReportHeading': 'Työaikaraportti',
  'label.worktimeReportInfo': 'Listaa tehdyt työtunnit annetulla aikavälillä.',
  'label.yes': 'Kyllä',
  'label.yesterday': 'Eilen',
  'layout.edit': 'Muokkaa pohjapiirustusta',
  'layout.hasRaports': 'Pohjapiirrustukseen on jo tallennettu sijainteja, joten sitä ei voi poistaa. Jos haluat vaihtaa pohjapiirustuksen, poista tämä käytöstä ja luo uusi.',
  'layout.searchOrSelectLayout': 'Pohjapiirustus',
  'layouts.addFirst': 'Lisää ensimmäinen pohjapiirustus',
  'layouts.layout': 'Pohjapiirustus',
  'layouts.layouts': 'Pohjapiirustukset',
  'layouts.newLayout': 'Uusi pohjapiirustus',
  'layouts.noLayouts': 'Ei pohjapiirustuksia',
  'layouts.noReadRights': 'Sinulla ei ole oikeuksia muokata tai lukea pohjapiirustuksia.',
  'layouts.offline': 'Pohjapiirustukset eivät tässä yhteydessä ole käytössä offline-tilassa.',
  'layouts.searchResults.many': '{0} pohjapiirustusta',
  'layouts.searchResults.one': 'Yksi pohjapiirustus',
  'locale.de.nameInThisLanguage': 'Deutsch',
  'locale.en.nameInThisLanguage': 'English',
  'locale.et.nameInThisLanguage': 'Eesti',
  'locale.fi.nameInThisLanguage': 'Suomi',
  'locale.pl.nameInThisLanguage': 'Polski',
  'locale.ru.nameInThisLanguage': 'Русский',
  'locale.sk.nameInThisLanguage': 'Slovenčina',
  'locale.sv.nameInThisLanguage': 'Svenska',
  'locale': 'fi',
  'locationAddedToLayout': 'Sijainti merkitty pohjapiirustukseen ',
  'login.accountNotFound': 'Käyttäjätunnusta ei löytynyt.',
  'login.didNotReceiveTemporaryPasswordViaSms': 'Etkö saanut salasanaa tekstiviestillä?',
  'login.enterAccountAndNumber': 'Syötä tunnuksesi.',
  'login.failedToSendTemporaryPassword': 'Salasanan lähettäminen ei onnistunut!',
  'login.incorrectRequest': 'Virheellinen pyyntö!',
  'login.infoMessage': 'Lähetämme matkapuhelinnumeroosi uuden salasanan.',
  'login.login': 'Kirjaudu sisään',
  'login.newPasswordSentToEmail': 'Uusi salasanasi on lähetetty sähköpostiosoitteeseesi',
  'login.newPasswordSentToTelephone': 'Uusi salasanasi on lähetetty puhelinnumeroon',
  'login.ohNoIe': '<p>Käytössäsi on <strong>Internet Explorer</strong> -selain, jota Zeroni ei enää tue. Tuetut selaimet ovat <a href=\'https://www.google.com/chrome/\'>Chrome</a>, <a href=\'https://www.getfirefox.com/\'>Firefox</a>, <a href=\'https://www.opera.com/\'>Opera</a>, <a href=\'https://www.microsoft.com/fi-fi/windows/microsoft-edge/\'>Edge</a> ja <a href=\'https://www.apple.com/safari/\'>Safari</a>.</p>',
  'login.passwordForgotten': 'Salasana unohtunut?',
  'login.requestNewPassword': 'Pyydä uusi salasana',
  'login.returnToLogin': 'Palaa kirjautumissivulle',
  'login.sendTemporaryPasswordToEmail': 'Lähetä salasana sähköpostiin',
  'login.showPassword': 'Näytä salasana',
  'login.telephone': 'Puhelinnumero',
  'login.telephoneAndAccountDontMatch': 'Puhelinnumero ei täsmää käyttäjätunnukseen.',
  'login.temporaryPasswordSentToEmail': 'Salasana lähetty sähköpostiin!',
  'login.timeout': 'Kirjautumisen aikakatkaisu: aikaa kului liian kauan!',
  'login.userHasSsoUsername': '<p>Yrityksellänne on käytössä erillinen kirjautumispalvelu, <strong>Azure AD</strong>.</p><p>Kirjaudu Zeroniin käyttämällä AD-palveluanne.</p>',
  'login.wrongPassword': 'Väärä sähköpostiosoite tai salasana.',
  'login.wrongVerificationCode': 'Vahvistuskoodi on virheellinen!',
  'mainContractorSignature': 'Pääurakoitsijan allekirjoitus',
  'mainHelp.call': 'Soita',
  'mainHelp.email': 'Lähetä viesti',
  'mainHelp.help.desc': 'Vinkkejä, ohjeita, usein kysytyt kysymykset.',
  'mainHelp.help.heading': 'Käyttöohjeet',
  'mainHelp.notifications': 'Ilmoitustaulu',
  'mainHelp.privacyPolicy': '<a target=\'_blank\' rel=\'noopener\' href=\'https://www.zeroni.fi/tietosuojaseloste-2/\'>Tietosuojaseloste</a>',
  'mainHelp.resources': 'Käyttö',
  'mainHelp.support': 'Tuki',
  'mainHelp.telephone.hours': 'ma-pe klo 8-16',
  'mainHelp.telephone.price': '0,69 € / min',
  'manage.info.accessAreas': 'Hallitse työmaan kulkualueita.',
  'manage.info.devices': 'Täältä voit selata, lisätä ja muokata laitteita.',
  'manage.info.docs': 'Lisää tai poista työmaan dokumentteja.',
  'manage.info.downloadNFCReader': 'Lataa NFC-lukijan asennustiedosto',
  'manage.info.edit': 'Tiedot kuten työmaan nimi, osoite ja kesto.',
  'manage.info.generalSettings': 'Sisältää esimerkiksi uusien työmaiden oletusasetukset, työmaaluvassa kysyttävät luvat jne.',
  'manage.info.introduction': 'Uudelle työntekijälle perehdytettävät asiat.',
  'manage.info.jobtitles': 'Täältä voit päivittää ja hakea nykyisen työnimikelistan.',
  'manage.info.layouts': 'Hallitse työmaan pohjapiirustuksia. Turvallisuus- ja laatuhavainnot voidaan sijoittaa pohjapiirustukselle.',
  'manage.info.location': 'Määrittele työmaan alue. Aluetta hyödynnetään mm. mobiilileimauksessa, työmaan valinnassa, teehavainto.fi -palvelussa.',
  'manage.info.massMessaging': 'Lähetä viestejä yrityksen tai työmaan työntekijöille.',
  'manage.info.orientation': 'Hallitse työmaan perehdytysaiheita.',
  'manage.info.professions': 'Hallitse listaa sallituista ammattinimikkeistä.',
  'manage.info.reportsToTaxAdmin': 'Lähetetyt urakka- ja työntekijäilmoitukset.',
  'manage.info.siteAdditionalSettings': 'Asetukset kuten pätevyyksien seuranta, tulityöluvan oletusasetukset, ja työmaalupaan täytettävät tiedot',
  'manage.info.siteRequirementSettings': 'Pakolliset vaatimukset pätevyyksille ja koulutuksille.',
  'manage.info.userGroups': 'Täältä voit määritellä käyttäjärooleja. Käyttäjäroolien avulla voit hallita käyttäjien näkemää tietoa.',
  'manage.info.users': 'Hallitse käyttäjien käyttöoikeuksia työmaalla.',
  'manage.notShowingExistingValue': 'Asetukselle on asetettu arvo, mutta tallennettua arvoa ei näytetä tietoturvasyistä.',
  'manage.modifyExistingValue': 'Anna asetukselle uusi arvo',
  'manage.info.workTime': 'Hallitse työmaan työaikaan liittyviä asioita.',
  'manage.integrations.stateOfService': 'Palvelun tila',
  'manage.integrations.vg.messageActivationInfo': 'Palvelun tilaa hallinnoidaan Vastuu Groupin verkkopalvelussa ja se päivittyy Zeroniin noin tunnin viiveellä.',
  'manage.integrations.vg.failedToCheckKey': 'Yhteyden toiminnan tarkastaminen epäonnistui.',
  'manage.integrations.vg.keyNotFound': 'Yhteysvirhe, tuntematon integraatioavain.',
  'manage.integrations.vg.keyInvalid': 'Yhteysvirhe, epäkelpo integraatioavain.',
  'manage.integrations.vg.loadingFailed': 'Integraation tietojen lataaminen epäonnistui.',
  'manage.integrations.vg.title': 'Vastuu Group',
  'manage.integrations.vg.label.customerIdentifier': 'Vastuu Group',
  'map.area': 'Alue',
  'map.startOver': 'Poista alue',
  'markLocation': 'Merkitse sijainti',
  'markToLayout': 'Merkitse pohjapiirustukseen',
  'message.acceptanceRecordsOfLiabilityDocumentsStored': 'Tilaajavastuudokumenttien hyväksymismerkinnät sopimukseen <a href="#!/sites/{0}/contracts/{1}">{2}</a> tallennettu.',
  'message.accessPermitAddedAlert': 'Työmaalupa lisätty.',
  'message.accessPermitCreatedForMultipleContracts': '<strong>Huom!</strong> Jokaiselle valitulle sopimukselle luodaan erillinen työmaalupa.',
  'message.accessPermitIntroduced': 'Perehdytyksen vahvistus suoritettu.',
  'message.accessPermitRequirements': '<strong>Huom!</strong> Työmaalupa vaatii voimassaolevat työturvallisuus- ja työterveyskortit.',
  'message.accessPermitUpdatedAlert': 'Työmaalupa päivitetty.',
  'message.accessPermitUpdateFailedAlert': 'Työmaaluvan päivittäminen epäonnistui.',
  'message.accessPermitValidationPeriodInfo': 'Sopimuksen voimassaolo rajaa myös työmaaluvan kestoa.',
  'message.addOtherNotesViaCheckInList': 'Lisää muut kommentit ja kulukorvaukset selaamalla <a href="{0}">leimaushistoriaa</a>.',
  'message.askFinnishShortcut': 'Valitsit kansalaisuudeksi Suomen. Valitaanko myös pääasialliseksi työskentelyvaltioksi Suomi ja kieleksi suomi?',
  'message.automaticTagListUpdateDescription': 'Automaattinen porttilaitteiden päivitys päivittää uudet kulkutunnisteet työmaan porttilaitteille 5 min välein. Automaattinen päivitys kannattaa ottaa pois vain jos työmaalle lisätään paljon uusia henkilöitä saman päivän aikana kesken työmaan keston (yleensä vuosihuoltoseisokeissa), koska liian tiheä tunnistepäivitysten lähetys voi hidastaa laitteen toimintaa leimatessa. Tällöin huolehdit itse päivitysten lähettämisestä laitteille. Etusivulle tulee ilmoitus päivitystä odottavien tunnisteiden määrästä.',
  'message.avoidOverlapWithAccessPermit': 'Työntekijällä on jo seuraava työmaalupa:',
  'message.avoidOverlapWithMultipleAccessPermits': 'Työntekijällä on jo seuraavat työmaaluvat:',
  'message.beforeAnythingElseCompleteCompanyInfo': '<strong>Yritystiedot puutteelliset:</strong> Ole hyvä ja täydennä ensin yrityksesi tiedot.',
  'message.businessIdAlreadyInUse': 'Y-tunnus on jo käytössä!',
  'message.canCreateAccessPermits': 'Voit halutessasi luoda työmaalupia odottamaan puutteiden korjausta.',
  'message.canCreateAccessPermitsDespiteErrors': 'Voit halutessasi luoda työmaalupia odottamaan puutteiden korjausta.',
  'message.changesSavedToPerson': '<strong>Tallennus henkilötietoihin:</strong> Muutokset tallennetaan henkilön yhteystietoihin.',
  'message.changesSavedToPersonSpecific': '<strong>Tallennus henkilötietoihin:</strong> Muutokset tallennetaan henkilön yhteystietoihin työluvan lisäyksen yhteydessä.',
  'message.changesSavedToPersonX': '<strong>Tallennus henkilötietoihin:</strong> Muutokset tallennetaan henkilön <em>{0}</em> yhteystietoihin.',
  'message.checkForemanContactInfo': '<strong>Henkilö toimii työnjohtajana ja työnjohtajalta vaaditaan puhelinnumero ja sähköposti.</strong> Vahvista alla olevat henkilötietojen merkinnät.',
  'message.checkInsNotLoaded': 'Leimauksia ei ole vielä ladattu. Valitse hakuehdot ja klikkaa "Hae leimaukset".',
  'message.checkInsOnMultipleSites': 'Leimaukset tallentuvat kaikkiin työmaihin, joihin laite on lisätty ja joihin leimaajalla on työmaalupa.',
  'message.checkoutForgottenBody': '<p>Leimasit itsesi sisään <strong>{0}</strong> työmaalle <strong>{1}</strong>, mutta ulosleimaus on edelleen tekemättä.</p><p>Ole hyvä ja syötä aika, jolloin poistuit työmaalta.</p>',
  'message.checkSiteForemanContactInfo': '<strong>Vastaavalta työnjohtajalta vaaditaan puhelinnumero ja sähköposti.</strong> Vahvista alla olevat henkilötietojen merkinnät.',
  'message.companyFilterInPersonSearchHelp': 'Näyttää valitun yrityksen aktiivisten sopimusten alla toimivat työntekijät. Vain ne työntekijät näytetään, joilla on aktiivinen työmaalupa.',
  'message.companyHasNoEvaluations': 'Yrityksen sopimuksia ei ole vielä arvioitu.',
  'message.companyNotTrustedPartner': 'Yritys ei kuulu Luotettava kumppani -ohjelmaan.',
  'message.companyRegisterIsEmpty': 'Yritysrekisteri on tyhjä.',
  'message.companyUpdated': 'Yrityksen <a href="{0}">{1}</a> tiedot päivitetty.',
  'message.companyWorksAs': '<strong>{0}</strong> toimii työmaalla <strong>{1}</strong>',
  'message.competenceAddedAlert': 'Pätevyys lisätty.',
  'message.competenceAlreadyExists': 'Henkilöllä on jo tämä pätevyys.',
  'message.competenceFilterInPersonSearchHelp': 'Vain ne työntekijät näytetään, joilla on työmaalle työmaalupa.',
  'message.competenceUpdatedAlert': 'Pätevyys päivitetty.',
  'message.confirmAccessPermitRejection': 'Haluatko varmasti päättää työmaaluvan?',
  'message.confirmDeviceDeletion': 'Haluatko varmasti poistaa laitteen <strong>{0}</strong> laiterekisteristä?',
  'message.confirmEnd': 'Ole hyvä ja vahvista, että mittaus on valmis päätettäväksi.',
  'message.confirmInterruption': 'Myös kaikki siitä ketjutetut sopimukset keskeytetään. Haluatko varmasti keskeyttää sopimuksen?',
  'message.confirmRemoval': 'Poistetaanko sopimus <strong>{0}</strong>? Toiminto poistaa valitun sopimuksen, kaikki sen alla olevat sopimukset, työmaaluvat ja leimaukset.',
  'message.contractAdded': 'Sopimus <a href="#!/sites/{0}/contracts/{1}">{2}</a> lisätty.',
  'message.contractCompanyInvalid': 'Työntekijöitä voi liittää sopimukselle vasta kun yrityksen tiedot on täytetty.',
  'message.contractDeclarationsWaitingForSending': '<strong>Urakkailmoituksia</strong> ei ole vielä lähetetty!',
  'message.contractStatusChangesByReport': 'Sopimuksen tila muuttuu tilaajavastuuraportilla ilmoitetun urakoitsijan statuksen mukaan. Samalla nykyinen tilaajavastuuraportti tulee arkistoiduksi.',
  'message.contractStatusInvalid': 'Sopimus astuu voimaan vasta kun tilaajavastuudokumentit on tallennettu ja hyväksytty tai kunnossa olevat tilaajavastuutiedot saatu Luotettava kumppani-ohjelman kautta.',
  'message.contractStatusWarning': 'Yrityksen Tilaajavastuu-tiedot eivät ole enää kunnossa',
  'message.contractStatusWarningMoreInfo': 'Tiedoksi: Luotettava kumppani -raportin (<a href="{0}" target="_blank"><span class="far fa-file"></span> PDF</a>) perusteella yrityksen tilaajavastuutietoihin on tullut puutteita sopimuksen lisäyksen jälkeen. Puutteet eivät automaattisesti estä mitään tai aiheuta erillisiä toimenpiteitä.',
  'message.contractStatusWarningMoreInfoNoVGAccess': 'Tiedoksi: Luotettava kumppani -raportin perusteella yrityksen tilaajavastuutietoihin on tullut puutteita sopimuksen lisäyksen jälkeen. Puutteet eivät automaattisesti estä mitään tai aiheuta erillisiä toimenpiteitä.',
  'message.contractUpdated': 'Sopimuksen <a href="#!/sites/{0}/contracts/{1}">{2}</a> tiedot päivitetty.',
  'message.csvInstructions': 'Lähetettävä tiedosto on oltava .csv-muodossa, kunkin rivin tietokentät eroteltu puolipisteellä.',
  'message.dashboardSelectionHelp': 'Etusivu näyttää yhteenvedon kaikilta työmailta tai käsiteltävältä työmaalta.',
  'message.deleteUserGroupConfirmation': 'Haluatko varmasti poistaa käyttäjäroolin <strong>{0}</strong>?',
  'message.deleteUserGroupHeading': 'Poista käyttäjärooli {0}',
  'message.deviceAddedAlert': 'Laite lisätty.',
  'message.deviceConnectedAlert': 'Laite <strong>{0}</strong> lisätty työmaalle',
  'message.deviceDeletedAlert': 'Laite poistettu.',
  'message.deviceInUseOnMultipleSites': '<strong>Laite käytössä!</strong> Laite on jo käytössä seuraavilla työmailla:',
  'message.deviceInUseOnOneSite': '<strong>Laite käytössä!</strong> Laite on jo käytössä työmaalla <em>{0} {1}</em>.',
  'message.deviceOpenedRemotely': 'Portti <strong>{0}</strong> etäavattu',
  'message.deviceRemoteOpeningFailed': 'Portin etäavaaminen epäonnistui!',
  'message.deviceRemovedAlert': 'Laite <strong>{0}</strong> poistettu työmaalta',
  'message.emailRequiredInfo': 'Sähköpostiosoite on työntekijän käyttäjätunnus Zeroniin. Verkkokoulutuskutsut toimitetaan työntekijän sähköpostiin. Tästä syystä Zeroniin ei voi lisätä useita käyttäjiä samalla sähköpostiosoitteella.',
  'message.employeeDeclarationsWaitingForSending': '<strong>Työntekijäilmoituksia</strong> ei ole vielä lähetetty!',
  'message.employeeMissingNewcomerTraining': 'Henkilöltä <strong>{0}</strong> puuttuu tulokoulutus.',
  'message.employeesMissingNewcomerTraining': '<strong>{0} henkilöltä</strong> puuttuu tulokoulutus.',
  'message.emptyDeviceRegister': 'Laiterekisteri on tyhjä.',
  'message.emptyEmployeeRegister': 'Henkilörekisteri on tyhjä.',
  'message.emptySiteRegister': 'Työmaarekisteri on tyhjä.',
  'message.emptyUserGroupRegister': 'Käyttäjäroolirekisteri on tyhjä.',
  'message.emptyUserRegister': 'Käyttäjärekisteri on tyhjä.',
  'message.faultMeasurementRemovalConfirmation': '<strong>Haluatko varmasti poistaa mittauksen?</strong> <br>Myös kaikki mittauksen havainnot poistetaan. Toimintoa ei voi perua.',
  'message.fetchNewLiabilityReportAndUpdateContractStatus': 'Hae uusi tilaajavastuuraportti ja päivitä sopimuksen tila.',
  'message.firstAccessPermitAddedAlert': 'Työmaalupa lisätty. <a href="{0}">Lisää seuraavaksi pätevyydet <span class="far fa-angle-right"></span></a>',
  'message.firstLoginNoPassword': '<p><strong>Hei, {0}!</strong></p><p>Sinulla ei vielä ole salasanaa Zeroniin. Lähetä se itsellesi tekstiviestillä.</p>',
  'message.formHasInvalidFields': '<strong>Puutteellisia tietoja:</strong> Osa lomakkeen tiedoista puuttuu tai ei kelpaa.',
  'message.formHasInvalidFieldsTxt': 'Osa tiedoista puuttuu tai ei kelpaa.',
  'message.giveMinimum': 'Syötä hakuun vähintään {0} merkkiä.',
  'message.goToUpdateDocumentsManually': 'Siirry päivittämään tilaajavastuudokumentit sopimuksen tilaajavastuudokumenteista.',
  'message.helpManaged': 'Yhteyshenkilölle annetaan käyttöoikeudet oman yrityksensä osuuteen sopimuksesta, esimerkiksi oikeus luoda työmaalupia työntekijöille.',
  'message.identifierAndBirthDateDontMatch': 'Syntymäpäivä ei täsmää tunnisteen kanssa.',
  'message.identifierIsInUse': 'Henkilön tunniste on jo käytössä jollain toisella henkilöllä. Ota yhteyttä Zeronin tukeen.',
  'message.identityDocumentRequirement': 'Laki vaatii tarkastamaan henkilöllisyystodistuksen jokaiselta työmaalla työskentelevältä. Asiakirjat on säilytettävä kaksi vuotta työskentelyn päättymisen jälkeen.',
  'message.idInUse': '<strong>{0} käyttää jo syötettyä kulkutunnistetta</strong> tällä työmaalla valitulla aikavälillä. Ole hyvä ja valitse toinen tunniste.',
  'message.idUsedByPerson': '<strong>Syötetty kulkutunniste on jo käytössä</strong> tällä työmaalla valitulla aikavälillä. Tunniste merkitty henkilölle <strong>{0}</strong>.',
  'message.imeiExists': '<strong>IMEI jo käytössä.</strong> Onko IMEI syötetty oikein? Onko se käytössä jollain toisella laitteella laiterekisterissä? Jos ongelma ei ratkea, ota yhteyttä tukeen (support@zeroni.fi).',
  'message.incorrectDate': '<strong>Tarkista päiväyksen muoto:</strong> Päivämäärä on kirjoitettava muodossa <strong>pp.kk.vvvv</strong>.',
  'message.incorrectExpirationDate': '<strong>Tarkista voimassaolo:</strong> Voimassaolo on kirjoitettava muodossa <strong>kk/vvvv</strong>.',
  'message.incorrectTime': '<strong>Tarkista kellonaika:</strong> Kellonaika on kirjoitettava muodossa <strong>hh:mm</strong>.',
  'message.instructorStatement': 'Olen lukenut perehdyttämislomakkeen ja vahvistan sen tiedot. Perehdytettävällä on välttämätön työturvallisuustietous sekä määräysten mukaiset henkilökohtaiset suojaimet. Perehdytettävä tuntee työmaan työturvallisuusmääräykset ja sitoutuu noudattamaan niitä.',
  'message.instructorStatementWithPerson': 'Olen perehdyttänyt henkilön <strong>{0}</strong>. Perehdytettävällä on välttämätön työturvallisuustietous sekä määräysten mukaiset henkilökohtaiset suojaimet. Perehdytettävä tuntee työmaan työturvallisuusmääräykset ja sitoutuu noudattamaan niitä.',
  'message.instructorStatementWithWorker': 'Olen perehdyttänyt henkilön <strong>{0}</strong>. Perehdytettävällä on välttämätön työturvallisuustietous sekä määräysten mukaiset henkilökohtaiset suojaimet. Perehdytettävä tuntee työmaan työturvallisuusmääräykset ja sitoutuu noudattamaan niitä.',
  'message.introductionToContractInformationInSiteEditor': 'Sopimustiedoilla luodaan ensimmäinen sopimus työmaalle. Sopimuksia tarvitaan verottaja-ilmoituksiin ja työmaalupien hallintaan.',
  'message.invalidationUserConfirmation': 'Haluatko varmasti poistaa käyttäjältä <strong>{0}</strong> käyttöoikeuden yritykseen <strong>{1}</strong>?',
  'message.invalidChecksumForFinnishBusinessId': '<strong>Oletko varma?</strong> Syötetty tunnus ei vaikuta oikean yrityksen Y-tunnukselta. Ole hyvä ja tarkista numerot.',
  'message.invalidChecksumForVatNumber': 'Syötä VAT-numero muodossa maakoodi + tunnus ilman välilyöntejä ja väliviivoja, esim. SE123456',
  'message.invalidContractTimePeriod': 'Valitse alkamis- ja päättymispäivä, jotta voidaan varmistaa, ettei työmaalle tule kahta päällekkäistä pääurakointisopimusta.',
  'message.jobTitleHelp': 'Hae yritys sopimuksissa käytettyjen työtehtävien mukaan.',
  'message.largeSearchResult': '{0} muuta tulosta.',
  'message.liabilityDocsCheckBy': 'Dokumentit on tarkastanut <strong>{0}</strong>.',
  'message.liabilityDocsElsewhere': 'Tilaajavastuudokumentit tallennettu erilliseen järjestelmään.',
  'message.linkToManualTaxNumberCheck': 'Tarkista veronumero itse (www.vero.fi)',
  'message.lowWage': '<strong>Alhainen tuntipalkka:</strong> Ilmoitettu tuntipalkka ei ole riittävä bruttoansion minimiin. Bruttoansion minimi on {0} €/kk ({1} €/h).',
  'message.message.regionHelp': 'Maakunta, jossa yritys on toiminut.',
  'message.missingCardIdHelp': 'Jos työmaa on valittu, näyttää työmaan työntekijät, joilla ei ole Valttikorttia eikä pääurakoitsijan tai tilaajan antamaa kulkutunnistetta. Jos työmaata ei ole valittu, näyttää työntekijät, joilla ei ole Valttikorttia eikä yrityksesi antamaa kulkutunnistetta.',
  'message.newcomerTrainingRequirements': '<strong>Tulokoulutus</strong> voidaan suorittaa vasta seuraavien kohtien jälkeen:',
  'message.newPersonFromGlobalDb': 'Henkilön <strong>{0}</strong> tiedot esitäytetty yhteisestä tietokannasta. Täytä puuttuvat kentät ja lisää uusi henkilö.',
  'message.newPersonFromValtti': 'Henkilön <strong>{0}</strong> tiedot kopioitu Valttikortilta. Täytä puuttuvat kentät ja lisää uusi henkilö.',
  'message.noAccessPermits': 'Yhtään työmaalupaa ei ole vielä lisätty.',
  'message.noAccessPermitsFound': 'Ei lupia valitulla aikavälillä.',
  'message.noActiveAccessPermits': 'Ei <em>aktiivisia</em> työmaalupia.',
  'message.noCompaniesFoundWithGivenFilters': 'Yrityksiä ei löytynyt annetuilla hakuehdoilla.',
  'message.noCompetences': 'Yhtään pätevyyttä ei ole vielä lisätty.',
  'message.noCompetencesFound': 'Ei pätevyyksiä valituilla ehdoilla.',
  'message.noContracts': 'Yhtään sopimusta ei ole vielä lisätty.',
  'message.noContractsForAccessPermit': 'Työmaalupa on sopimuskohtainen, mutta valitulla työmaalla ei ole sopimuksia tai kaikki ovat päättyneet.',
  'message.noPayments': 'Sopimukseen ei ole liitetty maksutapahtumia.',
  'message.noQualityMeasurementsFound': 'Työmaalla ei ole vielä tehty laatumittauksia.',
  'message.noSafetyMeasurementsFound': 'Työmaalla ei ole vielä tehty turvallisuusmittauksia.',
  'message.noSafetyMeasurementsFoundWithFilters': 'Turvallisuusmittauksia ei löytynyt valituilla hakuehdoilla.',
  'message.noSafetyToursFound': 'Työmaalla ei ole vielä tehty TR- tai MVR-mittauksia.',
  'message.noSearchResults': 'Ei hakutuloksia.',
  'message.noTaxNumberAlert': '<strong>Veronumero syöttämättä!</strong> Et syöttänyt henkilölle veronumeroa. Veronumero vaaditaan useimmissa tapauksissa, esim. verottajailmoituksiin.',
  'message.notUniqueCompetence': '<strong>Tarkista pätevyys ja voimassaolo:</strong> Henkilöllä on jo <strong>{0}</strong>, joka on voimassa <strong>{1}</strong> asti.',
  'message.noUnallocatedPayments': 'Ei kohdistamattomia maksutapahtumia.',
  'message.noValttiTagsInfo': 'Valttikortteja ei lasketa kulkutunnisteiksi.',
  'message.numberOfUnallocatedPayments': 'Työmaalla on <strong>{0} kohdistamatonta tapahtumaa</strong>.',
  'message.overlapsMainContract': 'Annetulla aikavälillä on jo toinen pääurakointisopimus.',
  'message.paperIntroductionHowTo': '<a href="{0}" target="_blank">Avaa perehdyttämislomake</a>, tulosta se ja lisää allekirjoitetusta lomakkeesta otettu kuva alle.',
  'message.passwordSent': '<strong>Salasana lähetetty matkapuhelimeesi.</strong> Voit nyt kirjautua sisään.',
  'message.personAddedAlert': 'Henkilö lisätty.',
  'message.personAlreadyExists': 'Henkilö <strong>{0}</strong> on jo lisätty!',
  'message.personalRequirementsErrorNoSiteCard': '<strong>Puutteita työntekijän tiedoissa.</strong> Työmaakortti voidaan luovuttaa vasta kun seuraavat puutteet on korjattu:',
  'message.personalRequirementsErrorNoTag': '<strong>Puutteita työntekijän tiedoissa.</strong> Työmaakortti voidaan luovuttaa vasta kun seuraavat puutteet on korjattu:',
  'message.personDeleted': 'Henkilö <strong>{0}</strong> poistettu.',
  'message.personUpdatedAlert': 'Henkilö päivitetty.',
  'message.phoneNumberRequiredInfo': 'Työntekijän puhelinnumeroa käytetään kaksivaiheisessa kirjautumisessa salasanan toimittamiseen sekä kriittisten työturvallisuusviestien lähettämiseen. Zeroniin ei voi lisätä useita käyttäjiä samalla puhelinnumerolla.',
  'message.pleaseAddCompetenceFirst': 'Lisää ensin pätevyys',
  'message.pleaseSpecifySearch': 'Tarkenna hakua',
  'message.quarterDeleted': 'Turvavartti <strong>{0}</strong> poistettu.',
  'message.ratingHelp': 'Toimittajan arviointien keskiarvo vähintään tähtien määrä. Arviointihakukriteerin voit poistaa klikkaamalla tähteä uudestaan.',
  'message.regionHelp': 'Maakunta, jossa yritys on toiminut.',
  'message.returnIdHelp': '<strong>Huom!</strong> Kulkutunnisteen palautus päättää kaikki nykyiset ja tulevat työmaaluvat, joihin se on liitetty.',
  'message.scanBothSidesOfResidencePermitDocument': 'Muista tallentaa oleskelulupadokumentin molemmat puolet.',
  'message.selectContractFirst': 'Valitse palkanmaksaja ensin',
  'message.selectEmployerFirst': 'Valitse ensin palkanmaksaja.',
  'message.selectEndDate': '<strong>{{vm.task.text}}</strong> voi kestää useamman päivän. Valitse päättymispäivä.',
  'message.selectPaymentsForAllocation': '<strong>Valitse</strong> siirrettävät tapahtumat.',
  'message.selectSiteFirst:': 'Valitse ensin työmaa: ',
  'message.selectSiteFirst': 'Valitse ensin työmaa.',
  'message.separateReferencesWithCommas': 'Erota litterat pilkulla.',
  'message.sessionExpired': '<strong>Olit liian kauan toimettomana</strong> ja siksi sinut kirjattiin ulos. Ole hyvä ja kirjaudu uudelleen sisään.',
  'message.settingsUpdated': 'Asetukset tallennettu.',
  'message.showGeneralEvaluationForCompany': 'Näytä yrityksen <strong>{0}</strong> yleisarviointi',
  'message.sickLeaveReason': 'Ole hyvä ja syötä syy, jonka vuoksi jäät sairaslomalle.',
  'message.siteAdded': 'Työmaa <a href="#!/sites/{0}/">{1} {2}</a> lisätty.',
  'message.siteFilterInPersonSearchHelp': 'Jos työmaa on valittu, niin vain ne työntekijät näytetään, joilla on aktiivinen työmaalupa.',
  'message.siteNumberTaken': '<strong>Numero varattu!</strong> Syöttämäsi työmaan numero on jo käytössä. Numeron on oltava yksilöllinen.',
  'message.siteUpdated': 'Työmaan <a href="#!/sites/{0}/">{1} {2}</a> tiedot päivitetty.',
  'message.tagIdLongToShort': 'Syöttämäsi tunniste ({0}) vaikutti avaimenperätunnisteen sisäiseltä numerolta. Arvoksi vaihdettiin tunnisteen lyhytnumero.',
  'message.taxNumber.goToInvalidFields': 'Siirry virheellisiin tietoihin',
  'message.taxNumber.invalidTaxNumFields': '<strong>Henkilön tiedot ovat verottajan mukaan virheelliset.</strong> Korjaa tiedot ennen päivitystä.',
  'message.taxNumberFormat': '12 numeroa, alkaen "1000"',
  'message.taxNumberInvalid': 'Veronumero ja syntymäaika eivät täsmää rakennusalan veronumerorekisterissä.',
  'message.taxNumberPendingCheck': 'Veronumeroiden tarkistuksessa on tällä hetkellä häiriöitä. Tietojen oikeellisuutta ei voida vahvistaa. Veronumeron tarkistusta yritetään uudelleen 2 h välein.',
  'message.taxNumberValid': 'Veronumero, nimi ja syntymäaika täsmäävät verottajan tiedoissa.',
  'message.trustedPartnerConnectionError': 'Luotettava kumppani -tarkistusta ei voida tehdä. (Yhteysvirhe: Vastuugroup.fi ei vastaa.)',
  'message.tryAgainOrContactSupport': 'Yritä uudelleen tai ota yhteyttä tukeen.',
  'message.unknownUsername': '<strong>Zeroni ei tunnista syötettyä tunnusta.</strong> Ole hyvä ja tarkista tunnus.',
  'message.userAdded': 'Käyttäjä <strong>{0}</strong> lisätty.',
  'message.userGroupAdded': 'Käyttäjärooli lisätty.',
  'message.userUpdated': 'Käyttäjän <strong>{0}</strong> tiedot päivitetty.',
  'message.valttiCardNotFound': 'Antamallasi Valttikorttinumerolla ei löydy henkilöä. Jos kyseessä on uusi Valttikortti, todennäköisesti tieto ei ole vielä päivittynyt Suomen Vastuu Group Oy:n rekisteriin.',
  'message.ValttiCardPersonExists': 'Henkilö <strong>{1}</strong> on jo rekisterissä. <a href="{0}">Näytä henkilö <span class="far fa-angle-right"></span></a>',
  'message.valttiIdToBarCode': 'Syöttämäsi tunniste ({0}) vaikutti Valttikortin sisäiseltä numerolta. Arvoksi vaihdettiin tunnisteen viivakoodi.',
  'message.workerStatement': 'Olen lukenut perehdyttämislomakkeen ja vahvistan sen tiedot. Minulla on välttämätön työturvallisuustietous sekä määräysten mukaiset henkilökohtaiset suojaimet. Tunnen työmaan työturvallisuusmääräykset ja sitoudun noudattamaan niitä.',
  'message.wrongFormatForFinnishBusinessId': '<strong>Väärä muoto!</strong> Suomalainen Y-tunnus on muotoa <strong>1234567-8</strong>.',
  'messaging.activity.all': 'Kaikki',
  'messaging.activity.contracts': 'Yhteyshenkilöiden sopimusten voimassaolo',
  'messaging.activity.onlyActive': 'Aktiiviset',
  'messaging.activity.onlyActiveOrPending': 'Aktiiviset ja alkavat',
  'messaging.activity.onlyPending': 'Alkavat',
  'messaging.activity.permits': 'Työmaalupien voimassaolo',
  'messaging.bulletinBoard.noEnglish.desc': 'Puuttuva teksti kopioidaan suomenkielisestä kentästä.',
  'messaging.bulletinBoard.noEnglish.title': 'Otsikon tai sisällön englanninkielinen käännös puuttuu.',
  'messaging.bulletinBoard.noFinnish.desc': 'Puuttuva teksti kopioidaan englanninkielisestä kentästä.',
  'messaging.bulletinBoard.noFinnish.title': 'Otsikon tai sisällön suomenkielinen käännös puuttuu.',
  'messaging.category': 'Luokka',
  'messaging.companyRelation.all': 'Kaikki',
  'messaging.companyRelation.onlyOther': 'Vieraat',
  'messaging.companyRelation.onlyOwn': 'Omat',
  'messaging.companyRelation.ownOrOtherEmployees': 'Kaikki / omat työntekijät',
  'messaging.companyRelation.ownOrOtherForemen': 'Kaikki / omat työnjohtajat',
  'messaging.content': 'Sisältö',
  'messaging.defaultSubject': 'Viesti Zeronista',
  'messaging.emailAttachments': 'Liitetiedostot',
  'messaging.foremen.findAll': 'Hae työnjohtajat ja varatyönjohtajat kaikilta työmailta',
  'messaging.foremen.only': 'Vain työnjohtajat ja varatyönjohtajat',
  'messaging.messageSendFailedAlert': 'Viestin lähetys epäonnistui: {0}',
  'messaging.messageSentAlert': 'Viestin lähetys onnistui.',
  'messaging.newMessage': 'Uusi viesti',
  'messaging.receiverName': 'Vastaanottajan nimi',
  'messaging.recipient': 'vastaanottaja',
  'messaging.recipients': 'vastaanottajaa',
  'messaging.recipientsLabel': 'Vastaanottajat',
  'messaging.recipientsWithMissingEmail': 'Joltain vastaanottajilta puuttuu sähköpostiosoite',
  'messaging.recipientsWithMissingPhone': 'Joltain vastaanottajilta puuttuu puhelinnumero',
  'messaging.searchFilters': 'Hakuehdot',
  'messaging.selectedContractNotActive': 'Valittu sopimus ei ole enää voimassa.',
  'messaging.send': 'Lähetä',
  'messaging.sender': 'Lähettäjä',
  'messaging.sending': 'Lähetetään...',
  'messaging.sendMessage': 'Lähetä viesti',
  'messaging.sent': 'Lähetetty!',
  'messaging.smsPrice': 'Hinta: {0}snt/viesti',
  'messaging.subject': 'Otsikko',
  'messge.deviceUpdatedAlert': 'Laitteen tiedot päivitetty.',
  'monitoring.allReports': 'Kaikki havainnot',
  'monitoring.allSources': 'Kaikki lähteet',
  'monitoring.anonymous': 'Vahvistamaton',
  'monitoring.deviation': 'Poikkeama',
  'monitoring.estimatedRepairDate': 'Arvioitu korjauspvm',
  'monitoring.fromMeasurement': 'Mittauksesta',
  'monitoring.layouts': 'Pohjapiirustukset',
  'monitoring.measurementType': 'Mittaustyyppi',
  'monitoring.negativeReport': 'Negatiivinen havainto',
  'monitoring.newPositiveReport': 'Uusi positiivinen havainto',
  'monitoring.noOpenReports': 'Ei avoimia havaintoja',
  'monitoring.openReports': 'Avoimet havainnot',
  'monitoring.positiveEnvironmentReport': 'Positiiviset ympäristöhavainnot',
  'monitoring.positiveEnvironmentReports': 'Positiiviset ympäristöhavainnot',
  'monitoring.positiveHelp': '<strong>Posiitivinen havainto</strong> voidaan tehdä, kun halutaan kiinnittää huomiota turvallisuuden tai ympäristön kannalta esimerkilliseen toimintaan.',
  'monitoring.positiveReport': 'Positiivinen havainto',
  'monitoring.positiveReports': 'Positiiviset havainnot',
  'monitoring.positives': 'Positiiviset',
  'monitoring.positiveSafetyReport': 'Positiivinen turvallisuushavainto',
  'monitoring.positiveSafetyReports': 'Positiiviset turvallisuushavainnot',
  'monitoring.process': 'Ota käsittelyyn',
  'monitoring.repaired': 'Korjattu',
  'monitoring.repairedReports': 'Korjatut havainnot',
  'monitoring.reportInfo': 'Havainnon tiedot',
  'monitoring.selectArea': 'Valitse alue',
  'monitoring.selectReporter': 'Valitse tekijä',
  'monitoring.separatelyAddedPlural': 'Yksittäiset',
  'monitoring.separatelyAddedSingular': 'Yksittäinen havainto',
  'monitoring.teehavainto': 'Teehavainto.fi',
  'months.short': 'kk',
  'months': 'kuukautta',
  'more': 'Lisää',
  'moreInformation.rootDomain': 'Lisätietoja ({0})',
  'moved': 'Siirretty',
  'new': 'Uusi',
  'newLine': 'Uusi rivi',
  'next': 'Seuraava',
  'nextSubmitting': 'Seuraava...',
  'noContractorObligationRaport': 'Ei raporttia',
  'noLayouts': 'Työmaalle ei ole lisätty pohjapiirrustuksia. <a ui-sref="main.sites.site.manage.layouts">Lisää pohjapiirrustus työmaalle hallinta välilehdeltä.</a>',
  'notification.maintenance': 'Zeroni huoltokatko klo {0}. Katkon kesto noin {1} minuuttia.',
  'observation.abbr': 'Hav.',
  'observation': 'Havainto',
  'ordererSignature': 'Tilaajan allekirjoitus',
  'orientation.a1Certificate': 'Todistus sosiaaliturvasta:',
  'orientation.a1CertificateExpires': 'Todistus sosiaalituvasta, viim. voimassaolopäivä:',
  'orientation.a1Certificates': 'Todistukset sosiaaliturvasta (esim. A1)',
  'orientation.additionalInfo': 'Lisätietokenttä',
  'orientation.additionalInfoForm': 'Lisätietokenttä',
  'orientation.addOnlineSignatures': 'Lisää sähköiset allekirjoitukset',
  'orientation.approxWorkingPeriod': 'Arvioitu työskentelyaika:',
  'orientation.backToEditMode': 'Palaa perehdytyksen muokkaukseen',
  'orientation.cantPerformOrientationBody': 'Työntekijän perehdytystä ei pysty vahvistamaan, koska vaadittua osaamista ei ole.',
  'orientation.cantPerformOrientationHeading': 'Vahvistaminen ei onnistu',
  'orientation.city': 'Postitoimipaikka:',
  'orientation.competence': 'Pätevyys',
  'orientation.competences': 'Pätevyydet',
  'orientation.completed': 'Suoritettu',
  'orientation.completeOnlineOrientation': 'Vahvista sähköisesti',
  'orientation.confirmOrientation': 'Vahvista',
  'orientation.date': 'Päivämäärä:',
  'orientation.developer': 'Tilaaja:',
  'orientation.documents': 'Luvat ja todistukset',
  'orientation.driverlicense': 'Ajokortti',
  'orientation.emailAddress': 'Sähköpostiosoite:',
  'orientation.employed': 'Työsuhteinen',
  'orientation.employee': 'Työntekijä',
  'orientation.employeeBirthDate': 'Syntymäaika:',
  'orientation.employeeEmail': 'Sähköposti:',
  'orientation.employeeExperience': 'Työkokemus vuosina:',
  'orientation.employeeFirstName': 'Etunimi:',
  'orientation.employeeHealthCardExpirationDate': 'Terveyskortti viim. voimassaolopäivä:',
  'orientation.employeeInfo': 'Henkilötiedot',
  'orientation.employeeLastName': 'Sukunimi:',
  'orientation.employeePhone': 'Puhelinnumero:',
  'orientation.employeeTaxNumber': 'Veronumero:',
  'orientation.employer': 'Työnantaja:',
  'orientation.employmentRelation': 'Työsuhde:',
  'orientation.entrepreneur': 'Ammatinharjoittaja',
  'orientation.errorMsg.orientAnyway': 'Vahvista siitä huolimatta',
  'orientation.errorMsg': 'Perehdytyslomakkeen luonti epäonnistui. Ongelma johtuu luultavasti heikosta internet-yhteydestä. Voit yrittää lomakkeen luontia uudestaan yllä olevasta painikkeesta tai ohittaa lomakkeen luonnin ja vahvistaa perehdytyksen siitä huolimatta. Lomakkeen voi luoda myöhemmin työmaalupaeditorissa. Jos käytösssäsi on WiFi-yhteys, kannattaa siirtyä lähelle tukiasemaa. Voit koittaa myös puhelimen mobiiliverkkoa, jos käytät työmaan omaa verkkoa.',
  'orientation.errorMsgHeader': 'Virhe dokumentin luonnissa',
  'orientation.expatRepresentative': 'Lähetetyn työntekijän edustaja Suomessa',
  'orientation.expiration': 'Viim. voimassaolopäivä',
  'orientation.filename': 'perehdytyslomake.pdf',
  'orientation.finnishAddress': 'Osoite Suomessa',
  'orientation.foreignAddress': 'Osoite ulkomailla',
  'orientation.foreignWorkerInfo': 'Ulkomaisen työntekijän tiedot:',
  'orientation.homeCountry': 'Pääasiallinen työskentelyvaltio:',
  'orientation.idcard': 'Henkilökortti',
  'orientation.idConfirmationType': 'Henkilöllisyyden varmennus:',
  'orientation.instructor': 'Perehdyttäjä',
  'orientation.known': 'Tunnettu',
  'orientation.mainContractor': 'Pääurakoitsija:',
  'orientation.mentor': 'Ohjaaja',
  'orientation.nationality': 'Kansallisuus:',
  'orientation.notNeeded': 'Ei tarvita',
  'orientation.notUsed': 'Ei ole',
  'orientation.orient': 'Vahvista',
  'orientation.orientationReport': 'Perehdyttämislomake',
  'orientation.orientOnPaper': 'Kuittaa suoritetuksi',
  'orientation.passport': 'Passi',
  'orientation.permanent': 'Pysyvä',
  'orientation.postcode': 'Postinumero:',
  'orientation.primaryLanguage': 'Kieli:',
  'orientation.printAvailableAtNextStep': 'Voit tulostaa perehdytyslomakkeen seuraavassa vaiheessa.',
  'orientation.printOrientationForm': 'Tulosta perehdyttämislomake',
  'orientation.profession': 'Ammattinimike:',
  'orientation.representative': 'Edustaja:',
  'orientation.residencePermit': 'Oleskelulupa:',
  'orientation.residencePermitExpires': 'Oleskelulupa päättyy:',
  'orientation.saveAndProceedToOrientation': 'Tallenna ja jatka vahvistukseen',
  'orientation.showOrientationForm': 'Näytä perehdytetyt asiat',
  'orientation.signatureClarification': 'Nimenselvennys',
  'orientation.signatures': 'Allekirjoitukset',
  'orientation.signatureText': 'Näillä allekirjoituksilla perehdytettävä ja perehdyttäjä vahvistavat, että perehdytettävällä on välttämätön työturvallisuustietoisuus sekä määräysten mukaiset henkilökohtaiset suojaimet. Perehdytettävä tuntee työmaan työturvallisuusmääräykset ja sitoutuu noudattamaan niitä.',
  'orientation.siteAddress': 'Työmaan osoite:',
  'orientation.siteForeman': 'Vastaava työnjohtaja:',
  'orientation.siteForemanPhone': 'Vastaavan työnjohtajan puh.',
  'orientation.siteInfo': 'Työmaan tiedot',
  'orientation.siteName': 'Työmaa:',
  'orientation.siteNumber': 'Työnumero:',
  'orientation.streetAddress': 'Lähiosoite:',
  'orientation.tasks': 'Työtehtävät:',
  'orientation.telephone': 'Puhelinnumero:',
  'orientation.temporary': 'Tilapäinen',
  'orientation.topic': 'Aihe',
  'orientation.topics': 'Perehdytetyt asiat',
  'orientation.topicsForSite': 'Työmaan perehdytyskohteet',
  'orientation.toSite': 'Vahvistus perehdytyksestä',
  'orientation.underageMentor': 'Alaikäisen työntekijän ohjaaja',
  'orientation.unpaid': 'Palkaton',
  'orientation.valttiCard': 'Valttikortti:',
  'otherLocation': 'Muu sijainti',
  'otherServices': 'Muut palvelut',
  'param.languageSortKey': 'nameFinnish',
  'passiveLayouts': 'Ei käytössä olevat pohjapiirustukset',
  'password.changedMessage': 'Salasana vaihdettu',
  'password.changePassword': 'Vaihda salasana',
  'password.changePasswordFailed': 'Salasanan vaihtaminen epäonnistui.',
  'password.changing': 'Vaihdetaan...',
  'password.errorASYNC_VALIDATION_RUNNING': ' ',
  'password.errorERROR': 'Virhe salasanan vahvuuden tarkastuksessa.',
  'password.errorKNOWN': 'Annettua salasanaa ei voida hyväksyä, koska se on liian yleisesti käytössä.',
  'password.errorTOO_LONG': 'Salasanan pituus voi olla maksimissaan 256 merkkiä.',
  'password.errorTOO_SHORT': 'Salasanan pituus tulee olla vähintään 12 merkkiä.',
  'password.failedToSubmit': 'Salasanan vaihtaminen epäonnistui. Ole hyvä ja yritä uudelleen. Jos ongelma ei ratkea, ota yhteyttä tukeen (support@zeroni.fi).',
  'password.invalidPasswordMessage': '<strong>Väärä salasana</strong>: Tarkista vanha salasana ja yritä uudelleen.',
  'password.newPassword': 'Uusi salasana',
  'password.oldPassword': 'Vanha salasana',
  'password.oldPasswordRequired': 'Anna vanha salasana',
  'password.showPassword': 'Näytä/piilota salasana',
  'password.strength.fair': 'Kohtalainen',
  'password.strength.good': 'Hyvä',
  'password.strength.great': 'Erinomainen',
  'password.strength.weak': 'Heikko',
  'password.validationMinLength': 'Salasanan pituus tulee olla vähintään 12 merkkiä.',
  'payment.addNewPayment': 'Lisää uusi tapahtuma',
  'payment.addPayment': 'Lisää tapahtuma',
  'payment.allPayments': 'Kaikki tapahtumat',
  'payment.confirmRemoval': 'Poistetaanko tapahtuma <strong>{0}</strong>?',
  'payment.confirmRemovals': 'Olet poistamassa <strong>{0}</strong> tapahtumaa, yht. <strong>{1}</strong>. Poistetaanko tapahtumat?',
  'payment.editPayment': 'Muokkaa tapahtumaa',
  'payment.estimatedPriceLowerThatn15k': 'Arvioitu summa alle 15 t€.',
  'payment.estimatedSum': 'Arvio',
  'payment.importedFromExternalSystem': 'Tuotu ulkoisesta järjestelmästä',
  'payment.latestPayment': 'Viimeisin lisäys',
  'payment.noEstimatedSum': 'Ei arviota',
  'payment.noPayments': 'Ei tapahtumia',
  'payment.noTaxReportsAreSent': 'Ei ilmoiteta verottajalle.',
  'payment.noTaxReportsFromThisContract': 'Tästä sopimuksesta ei lähetetä urakkailmoituksia. Ks. sopimuksen tiedoista.',
  'payment.noTaxReportsFromThisSite': 'Tämän työmaan sopimusten urakkatapahtumista ei lähetetä verottajailmoituksia.',
  'payment.removePayment': 'Poista tapahtuma',
  'payment.removePaymentHeading': 'Poista tapahtuma?',
  'payment.removePayments': 'Poista tapahtumat',
  'payment.removePaymentsHeading': 'Poista tapahtumat?',
  'payment.searchUnallocatedByCompanyName': 'Hae laskuttavan yrityksen nimellä',
  'person.addTag': 'Lisää kulkutunniste',
  'person.addTagToPerson': 'Lisää kulkutunniste henkilölle <strong>{0}</strong>',
  'person.cardExpired': 'Kortti vanhentunut!',
  'person.chooseRepresentative': 'Valitse edustaja',
  'person.deactivatedNotification': '<strong>{0}</strong> on passivoitu. Jos haluatte aktivoida henkilön uudelleen, ottakaa yhteys Zeronin tukeen (support@zeroni.fi).',
  'person.doesNotLookEmployeeEmail': 'Sähköpostiosoite ei näytä vastaavan henkilön etu- ja sukunimiä. Käytetäänkö silti tätä osoitetta?',
  'person.drugTest.approvalDate': 'Hyväksytty {0}.',
  'person.drugTest.expirationDate': 'Voimassa {0} asti.',
  'person.formerExpatRepresentatives': 'Aikaisempia edustajia',
  'person.hotWorkPermitViaManagement': 'Tulityöluvat lisätään hallintaosion kautta.',
  'person.identifierFound': 'Tunnisteella löytyi henkilö. Lomake täytetty löytyneillä tiedoilla. Tarkista lomakkeen tiedot.',
  'person.incompleteInfo': 'Puutteelliset tiedot',
  'person.incompleteInfoForOrientation': 'Henkilöllä on puutteelliset tiedot vahvistusta varten.',
  'person.invoiceHourDefaultApprover': 'Tuntilappujen oletushyväksyjä',
  'person.isNameCorrect': '<strong>Henkilön sukunimi ("{0}") näyttää etunimeltä.</strong> Vaihdetaanko etu- ja sukunimet keskenään?',
  'person.nationalityFailedToAdd': 'Kansallisuuden lisääminen henkilötietoihin epäonnistui.',
  'person.nationalityMissingAlert': 'Henkilölle ei ole merkitty kansalaisuutta.',
  'person.nationalityMissingLink': 'Lisää kansalaisuus henkilötietoihin',
  'person.nationalityRequiredBeforeAccessPermits': 'Kansalaisuus on tiedettävä ennen työmaaluvan antamista.',
  'person.noAccessPermits': 'Ei työmaalupia.',
  'person.noCompetences': 'Ei pätevyyksiä.',
  'person.noNameIsCorrent': 'Ei, nimi on oikein',
  'person.noTags': 'Ei kulkutunnisteita.',
  'person.noTagsWarning': '<strong>Ei kulkutunnisteita.</strong> Henkilö on kuitenkin merkitty työmaalle, jossa on käytössä leimauslaite.',
  'person.oldTaxNumberHelp': '<p>Jos henkilöstä ei tehdä tiedonantovelvollisuuden mukaisia työntekijäilmoituksia kahteen vuoteen, poistuu henkilö rakennusalan veronumerorekisteristä.</p><p><strong>Veronumero merkitään rekisteriin</strong> <a href="https://www.vero.fi/sahkoiset-asiointipalvelut/omavero/" target="_blank" rel="noopener">OmaVerossa <span class="fas fa-external-link-alt"></span></a> (vero.fi), soittamalla veronumeron palvelunumeroon <a href="tel:+358 29 497 070">029 497 070</a> tai käymällä Verohallinnon toimipisteessä.</p><p>Lisätietoja: <a target="_blank" rel="noopener" href="https://www.vero.fi/henkiloasiakkaat/verokortti-ja-veroilmoitus/ulkomailta_suomeen/toihin_suomeen/rakennusty%C3%B6maalle/veronumero/">Veronumero on pakollinen rakennustyömaalla <span class="fas fa-external-link-alt"></span></a> (vero.fi)</p>',
  'person.onlyPersonCanChange': 'Vain henkilö itse voi muokata.',
  'person.pendingSecurityClearance': 'Odotetaan vastausta. Hakemus jätetty {0}.',
  'person.personNumber': 'Henkilönumero',
  'person.personRole': 'Rooli',
  'person.previousWorkOverTaxAdminLimit': 'Onko työntekijä työskennellyt viimeksi yli 2 vuotta sitten?',
  'person.safetyProcedures': 'Turvallisuustarkistukset',
  'person.search.noneOnThisSite': '<p>Henkilöä ei löydy valitulta työmaalta ({0}).</p>',
  'person.search.pendingVerification': 'Odottaa työnteko-oikeuden tarkastusta',
  'person.searchOrSelectPerson': 'Henkilö',
  'person.securityClearance.created': 'Hakemus jätetty {0}.',
  'person.securityClearance.validity': 'Voimassa {0}.',
  'person.securityClearance.waitingForReply': 'Odotetaan vastausta.',
  'person.submitMissingDetailsBeforeOrientation': 'Täytä puuttuvat tiedot ja jatka sitten vahvistukseen.',
  'person.tags': 'Kulkutunnisteet',
  'person.taxNumber.nameSuggestion': 'Veronumerorekisterin mukaan henkilön oikea nimi on <strong>{0}, {1}</strong>. Käytetäänkö tätä?',
  'person.taxNumber.verificationRequirements': 'Syötä veronumeron lisäksi henkilön etu- ja sukunimi niille varattuihin kenttiin alla.',
  'person.taxNumber.verifying': 'Tarkistetaan...',
  'person.taxNumber.verifyingTaxNumber': 'Tarkistetaan veronumeroa...',
  'person.taxNumberChecked': 'Tarkistin, numero on kunnossa',
  'person.valttiOrAddBelow': 'Syötä VALTTI-kortin numero tai vaihtoehtoisesti henkilötiedot alle.',
  'person.yesSwitchNames': 'Kyllä, vaihda nimet',
  'presence.help': 'Merkitse työmaalla työskennelleet henkilöt. Tietoa käytetään verottajalle lähetettävässä työntekijäilmoituksessa.',
  'presence.personHasCheckIns': 'Henkilöllä on leimauksia kyseisellä kuukaudella',
  'presence.taxNumberMissing': 'Veronumero puuttuu',
  'presence.workersPresentByMonth': 'Työntekijöiden läsnäolo kuukausittain',
  'presence': 'Läsnäolo',
  'previous': 'Edellinen',
  'quality.addFirstDefect': 'Lisää ensimmäinen laatupoikkeama',
  'quality.defect': 'Laatupoikkeama',
  'quality.defectInfo': '<strong>Laatupoikkeama</strong> raportoidaan, kun tehdystä työstä löytyy vikoja tai puutteita.',
  'quality.defects.searchResults.many': '{0} laatupoikkeamaa',
  'quality.defects.searchResults.one': '1 laatupoikkeama',
  'quality.defects.stepsToPreventInFuture': 'Miten estetään jatkossa',
  'quality.defects.underlyingReason': 'Poikkeaman juurisyy',
  'quality.defects': 'Laatupoikkeamat',
  'quality.faultList': 'Vika- ja puutelista',
  'quality.faultLists': 'Vika- ja puutelistat',
  'quality.inspections': 'Katselmoinnit',
  'quality.measurement': 'Laatumittaus',
  'quality.measurements': 'Laatumittaukset',
  'quality.newDefect': 'Uusi laatupoikkeama',
  'quality.noDefects': 'Ei laatupoikkeamia',
  'quality.noOpenDefects': 'Ei avoimia laatupoikkeamia',
  'quality.noRepairedDefects': 'Ei korjattuja laatupoikkeamia',
  'quality.openFaultLists': 'Avoimet vika- ja puutelistat',
  'quality.openFaultListsShort': 'Avoimet',
  'quality.openReports': 'Korjausta odottavat laatupoikkeamat',
  'quality.quality': 'Laatu',
  'raport.layoutInfo': 'Työmaalla ei ole pohjapiirustuksia. <a ui-sref="main.sites.site.manage.layouts">Lisää pohjapiirustus täältä.</a>',
  'ratingTitles': '["Yksi", "Kaksi", "Kolme", "Neljä", "Viisi"]',
  'removeMarker': 'Poista merkintä',
  'report.accessPermitsWithCheckInsFromTo': 'Leimanneet työntekijät ajalla {0} - {1}',
  'report.contractContact.businessId': 'Y-tunnus',
  'report.contractContact.company': 'Yritys',
  'report.contractContact.contractContact': 'Sopimusten yhteyshenkilöt',
  'report.contractContact.contractContactFileName': 'Sopimusyhteyshenkilöt',
  'report.contractContact.contractContactReport': 'Sopimusten yhteyshenkilöt-raportti',
  'report.contractContact.email': 'Sähköposti',
  'report.contractContact.filterContract': 'Sopimus:',
  'report.contractContact.filterManaged': 'Näytetään vain hallintaoikeuden omaavat henkilöt.',
  'report.contractContact.filterManagedTitle': 'Hallintaoikeus',
  'report.contractContact.filterSite': 'Työmaa:',
  'report.contractContact.filterSubcontractors': '( näytetään myös aliurakoitsijat ).',
  'report.contractContact.firstName': 'Etunimi',
  'report.contractContact.info': 'Luo sopimusten yhteyshenkilöt-raportti',
  'report.contractContact.lastName': 'Sukunimi',
  'report.contractContact.telephone': 'Puhelin',
  'report.contractorObligation.info': 'Hae tilaajavastuuraportti',
  'report.device.info': 'Näyttää tiedot kaikista yrityksesi laitteista.',
  'report.device': 'Laiteraportti',
  'report.deviceReportShort': 'Laitteet',
  'report.employeeSafetySummary.info': 'Turvallisuuden yhteenveto',
  'report.employeeSafetySummary': 'Turvallisuuden yhteenveto',
  'report.evaluation.evaluationAllSites': 'Kaikki arvioinnit',
  'report.evaluation.evaluationAllSitesTooltip': 'Luo raportin, jossa on kaikki arvioinnit listattuna kaikilta työmailta',
  'report.evaluation.info': 'Luo arviointiraportti',
  'report.evaluationsReport.info': 'Luo arviointiraportti',
  'report.expats': 'Lähetetyt työntekijät',
  'report.expatsOnlyDesc': 'Näytä vain lähetetyt työntekijät',
  'report.manpower.info': 'Raportti listaa yksikön sisään leimanneet työntekijät alueittain',
  'report.manpower': 'Läsnäoloalueraportti',
  'report.manpowerReportShort': 'Läsnäoloalueet',
  'report.monitoring.timeSpanInfo': 'Ottaa raporttiin annetulla aikavälillä tehdyt mittaukset tai tapahtuneet poikkeamat.',
  'report.presence.info': 'Raportti näyttää henkilöt, jotka ovat tällä hetkellä sisäänleimanneena jollain työmaalla.',
  'report.presence': 'Henkilöraportti',
  'report.quality.info': 'Luo Excel-taulukon tehtyjen laatumittausten tuloksista tai havaituista laatupoikkeamista.',
  'report.quality.quality': 'Laatu',
  'report.quality.report': 'Laaturaportti',
  'report.safety.bySite': 'Työmaakohtainen',
  'report.safety.confirmTime': 'Vahvistusaika',
  'report.safety.createBySiteTooltip': 'Luo työmaakohtaisen raportin',
  'report.safety.custom': 'Erikoiskohteet',
  'report.safety.hazards': 'Poikkeamat',
  'report.safety.hazardsbysite': 'Poikkeamat kaikilta työmailta',
  'report.safety.info.more': 'Luo Excel-taulukon tehtyjen turvallisuusmittausten tuloksista, erikoiskohteiden mittauksen tuloksista, positiivisista turvallisuushavainnoista tai turvallisuuspoikkeamista.<br/><br/>Turvallisuusmittausraportti ei sisällä erikoiskohteita. Erikoiskohteiden tulokset löytyvät erikoiskohderaportilta.',
  'report.safety.info': 'Luo Excel-taulukon tehtyjen turvallisuusmittausten tuloksista, erikoiskohteiden mittauksen tuloksista, positiivisista turvallisuushavainnoista tai turvallisuuspoikkeamista.',
  'report.safety.report': 'Raportti',
  'report.safety.safety': 'Turvallisuus',
  'report.safety.safetyreport': 'Turvallisuusraportti',
  'report.safety.site': 'Työmaa',
  'report.safety.timespan': 'Aikaväli',
  'report.safety.timespanTooltip': 'Sisällyttää raporttiin annetulla aikavälillä tehdyt mittaukset tai poikkeamat.',
  'report.safety.trmvrbyallsites': 'Mittaukset kaikilta työmailta',
  'report.safety.trmvrbysite': 'Koonti mittauksista työmaittain',
  'report.safety.trmvrbysiteTooltip': 'Koonti mittauksista luo raportin kaikkien työmaiden osalta, jossa työmaan kaikki mittaukset on koostettu yhdeksi riviksi',
  'report.serviceCalls': 'Palvelukutsut',
  'report.taxAdmin.info': 'Selaa verottajalle lähetettyjä työntekijä- ja urakkailmoituksia.',
  'report.unresolvedContractPayments.info': 'Luo Excel-taulukon integraation kautta Zeroniin tulleista urakkatapahtumista, joita ei ole automaattisesti pystytty kohdistamaan urakoitsijaan.',
  'report.unresolvedContractPayments.onlyFromLast13Monthsinfo': 'Vain viimeisen 13kk sisälle päivätyt tapahtumat voivat vaikuttaa verottaja ilmoituksiin.',
  'report.unresolvedContractPayments.report': 'Kohdistamattomat tapahtumat -raportti',
  'report.unresolvedContractPayments.showOnlyFromLast13Months': 'Näytä kohdistamattomat tapahtumat vain viimeisen 13kk ajalta',
  'report.unresolvedContractPayments.summaryinfo': 'Listaa kaikki kohdistamattomat tapahtumat, koonti työmaatunnisteen/urakoitsijan mukaan tai koonti työmaatunnisteen mukaan.',
  'report.unresolvedContractPayments.summaryselection.all': 'Kaikki',
  'report.unresolvedContractPayments.summaryselection.contractor': 'Urakoitsijayhteenveto',
  'report.unresolvedContractPayments.summaryselection.site': 'Työmaayhteenveto',
  'report.workHourBalance.info': 'Luo työajanseurantatyömaiden työaika raportin.',
  'report.workTime.deductLunch': 'Vähennä 30min lounastauko kun päivän tuntimäärä on 6 tuntia tai enemmän',
  'report.workTime.officialHours': 'Näytä tunnit vain klo 7:00 - 15:30 väliltä',
  'report.workTime.onlyOneCheckInPairPerDay': 'Laske tunnit vain päivän ensimmäisestä sisäänleimauksesta viimeiseen ulosleimaukseen',
  'report.workTime.workTime': 'Työaika',
  'reporterName': 'Ilmoittajan nimi',
  'reportersName': 'Ilmoittajan nimi',
  'reports.contractorNotification': 'Urakoitsijalle ilmoittaminen',
  'reports.noRights': 'Sinulla ei ole oikeuksia tähän raporttiin.',
  'reports.notifyContractor': 'Ilmoita urakoitsijalle',
  'reports.returnToReports': 'Palaa raportteihin',
  'reports.searchResults.many': '{0} raporttia',
  'reports.searchResults.one': '1 raportti',
  'reports.selectedReports': 'Valitut havainnot',
  'reports.selectOneOrMore': 'Valitse yksi tai useampi havainto',
  'reports.sendingManyReports': 'Lähetetään ilmoitus <strong>{0}</strong> havainnosta',
  'reports.sendingOneReport': 'Lähetetään ilmoitus <strong>1</strong> havainnosta',
  'residencePermit.continuousResidencePermit': 'Jatkuva oleskelulupa',
  'residencePermit.longTermBrexitResidencePermit': 'Erosopimuksen piiriin kuuluva oleskeluoikeus',
  'residencePermit.longTermEuResidencePermit': 'Pitkään oleskelleen kolmannen maan kansalaisen EU-oleskelulupa',
  'residencePermit.permanentBrexitResidencePermit': 'Erosopimuksen mukainen pysyvä oleskeluoikeus',
  'residencePermit.permanentResidencePermit': 'Pysyvä oleskelulupa',
  'residencePermit.residencePermitNotNeeded': 'Oleskelulupaa ei vaadita',
  'residencePermit.temporaryResidencePermit': 'Tilapäinen oleskelulupa',
  'safety.addDeviation': 'Lisää turvallisuushavainto',
  'safety.addingDeviation': 'Turvallisuushavainnon lisäys',
  'safety.additionalDetails': 'Lisätiedot',
  'safety.additionalDetailsAdded': 'Lisätiedot lisätty',
  'safety.addNumberOfObservations': 'Lisää {0} huomautusta',
  'safety.addPhoto': 'Lisää vielä kuva',
  'safety.addReport': 'Lisää tapahtuma',
  'safety.allSites': 'Kaikki työmaat',
  'safety.allSitesGraphHelp': 'Kaikkien työmaiden eri turvallisuusmittausten keskimääräinen taso',
  'safety.andMoreMeasurements': 'Ja {0} muuta mittausta. Näytä kaikki',
  'safety.anonymousReport': 'Vahvistamaton turvallisuushavainto',
  'safety.anonymousReports': 'Vahvistamattomat tapahtumat',
  'safety.askForMore': 'Useampi kerralla?',
  'safety.average': 'Keskiarvo',
  'safety.averageLevel': 'Keskimääräinen taso',
  'safety.class': 'Luokka',
  'safety.classes': 'Luokat',
  'safety.clear': 'Tyhjennä',
  'safety.closedHazards': 'Korjatut turvallisuushavainnot',
  'safety.confirm': 'Kuittaa',
  'safety.confirmBody': 'Merkitse positiivinen havainto käsitellyksi. Voit myös halutessasi lisätä kommentin.',
  'safety.confirmed': 'Kuitattu',
  'safety.confirmer': 'Kuittaaja',
  'safety.confirmerComment': 'Kuittaajan kommentti',
  'safety.confirmHazard': 'Kuittaa havainto',
  'safety.confirming': 'Vahvistetaan...',
  'safety.confirmRepaired': 'Vahvista korjaus',
  'safety.confirmRepairedBody': 'Ole hyvä ja vahvista, että vaaditut toimenpiteet on suoritettu ja havaittu asia voidaan merkitä korjatuksi.',
  'safety.continueMeasurement': 'Jatka <strong>{0}-mittausta</strong>',
  'safety.continueMeasurementNoArg': 'Jatka mittausta',
  'safety.contractorHasNoPersons': 'Urakoitsijalle ei ole lisätty henkiöitä.',
  'safety.contractorInCharge': 'Vastaava urakoitsija',
  'safety.customMeasurement': 'Erikoismittaus',
  'safety.customSectionDetails': 'Erikoiskohteiden kuvaukset',
  'safety.date': 'Päivämäärä',
  'safety.daysSickLeave': 'Sairaspäivä',
  'safety.daysSinceLatestInjury': 'päivää edellisestä tapaturmasta',
  'safety.description': 'Kuvaus',
  'safety.deviation': 'Turvallisuuspoikkeama',
  'safety.deviationHelp': '<p><span class=\'far fa-info-circle\'></span><strong>Turvallisuushavainto</strong> ilmoittaa havaitusta turvallisuuspuutteesta tai –riskistä työmaan johdolle sen korjaamista varten.</p><p><strong>Läheltä piti</strong> –ilmoitus tehdään työmaan tapahtumasta tai onnettomuudesta, joka on aiheuttanut tai olisi voinut aiheuttaa esinevahinkoja.</p><p>Mikäli on tapahtunut henkilövahinko, se on <strong>tapaturma</strong> josta tehdään tapaturmailmoitus.</p>',
  'safety.deviations': 'Turvallisuuspoikkeamat',
  'safety.deviationsForContractorsAndEmployees': 'Urakoitsijat ja työntekijät voivat lisätä työmaalla ollessaan turvallisuushavaintoja osoitteessa <a target="_blank" href="http://teehavainto.fi">teehavainto.fi</a>.',
  'safety.deviationsForContractorsAndEmployeesRequirements': '<p>Vaatimukset:</p><ul><li>päätelaitteessa sijaintitieto (GPS)</li><li>havainto tehdään työmaalla</li></ul>',
  'safety.elmeriMeasurement': 'Elmeri+ -mittaus',
  'safety.elmeriMeasurements': 'Elmeri+ -mittaukset',
  'safety.employeeRepresentativeSignature': 'Työntekijöiden edustajan allekirjoitus',
  'safety.employerRepresentativeSignature': 'Työnantajan edustajan allekirjoitus',
  'safety.entries': 'Merkinnät',
  'safety.entriesTotal': 'Merkinnät yhteensä',
  'safety.environmentalHazard': 'Ympäristöhavainto',
  'safety.environmentalHazards': 'Ympäristöhavainnot',
  'safety.events': 'Turvallisuustapahtumat',
  'safety.followUpMeasures': 'Jatkotoimenpiteet',
  'safety.generalSettings': 'Yleiset asetukset',
  'safety.hazard': 'Turvallisuushavainto',
  'safety.hazardAlreadyRepairedMessage': 'Turvallisuushavainto on merkitty korjatuksi jo aiemmin.',
  'safety.hazardHelp': '<strong>Turvallisuushavainto</strong> ilmoittaa havaitusta turvallisuuspuutteesta tai –riskistä työmaan johdolle sen korjaamista varten.',
  'safety.hazardReason': 'Huomautus',
  'safety.hazardReasonShort': 'Huom.',
  'safety.hazards': 'Turvallisuushavainnot',
  'safety.hazardTeehavainto': 'Teehavainto.fi',
  'safety.help.markingAsRepaired': 'Havaintojen merkitseminen korjatuksi',
  'safety.help.subContractorPermissions': 'Aliurakoitsijalle oikeus turvallisuushavaintoihin',
  'safety.hideDetails': 'Piilota kohteiden kuvaukset',
  'safety.hideFixedReports': 'Piilota korjatut huomautukset',
  'safety.howToPreventInFuture': 'Miten ehkäistään tulevaisuudessa',
  'safety.hse': 'Vaaratilanteet ja tapaturmat',
  'safety.indexedDBunavailable': 'Selaimesi ei tue mittausta. Jos käytät Safaria, varmista että yksityinen tila ei ole käytössä.',
  'safety.injuredBodyPart': 'Vahingoittunut ruumiinosa',
  'safety.injuries': 'Tapaturmat',
  'safety.injury': 'Tapaturma',
  'safety.injuryHelp': 'Mikäli on tapahtunut henkilövahinko, se on <strong>tapaturma</strong> josta tehdään tapaturmailmoitus.',
  'safety.injuryInfo': 'Tapaturman tiedot',
  'safety.lastSync': 'Mittaus tallennettu klo',
  'safety.level': 'Taso',
  'safety.location': 'Sijainti',
  'safety.manyUnsyncedReportsDanger': '<strong>Tallentamattomia väärin-merkintöjä on paljon.</strong> Ole hyvä ja käy paikassa, jossa on parempi internet-yhteys. Mittaus voi jatkua, kun merkinnät on tallennettu.',
  'safety.manyUnsyncedReportsWarning': '<strong>Tallentamattomia väärin-merkintöjä on jo aika monta.</strong> Välitallennus tekisi hyvää. Ehkä voit pikaisesti käväistä paikassa, jossa on parempi internet-yhteys?',
  'safety.markAsConfirmed': 'Kuittaa havainto',
  'safety.markAsRepaired': 'Merkitse korjatuksi',
  'safety.measure': 'Mittaa',
  'safety.measurement.area.contractors.help': 'Voit valita yhden tai useamman urakoitsijan',
  'safety.measurement.area.contractors': 'Alueella toimivat urakoitsijat',
  'safety.measurement.area.help': 'Mittaus alkaa, kun alue on valittu.',
  'safety.measurement.area': 'Alue',
  'safety.measurement.areas': 'Mittausalueet',
  'safety.measurement.contractor': 'Urakoitsija',
  'safety.measurement.hazardsWaitingForRepair': 'huomautusta odottaa korjausta',
  'safety.measurement.hazardWaitingForRepair': 'huomautus odottaa korjausta',
  'safety.measurement.showCustomReport': 'Näytä erikoisraportti',
  'safety.measurement.showReport': 'Näytä raportti',
  'safety.measurement.start': 'Aloita mittaus',
  'safety.measurement.type': 'Mittaustyyppi',
  'safety.measurement': 'Työturvallisuusmittaus',
  'safety.measurementAlreadyFinished': 'Mittaus on jo päätetty',
  'safety.measurementAlreadyFinishedBy': '<strong>{0}</strong> on jo päättänyt mittauksen <strong>{1}</strong>',
  'safety.measurementFromPast': 'Menneisyydessä tehty mittaus',
  'safety.measurementItems': 'Merkinnät',
  'safety.measurementLoadingFailed': 'Mittauksen lataaminen epäonnistui. Palaa takaisin Turvallisuus -näkyämän ja kokeile mittauksen aloittamista uudestaan.',
  'safety.measurements.asphaltFactory.new': 'Uusi <strong>Asfalttimittaus(Tehdas)</strong>',
  'safety.measurements.asphaltFactory': 'Asfalttimittaus(Tehdas)',
  'safety.measurements.asphaltPaving.new': 'Uusi <strong>Asfalttimittaus(Päällystystyömaat)</strong>',
  'safety.measurements.asphaltPaving': 'Asfalttimittaus(Päällystystyömaat)',
  'safety.measurements.boring.new': 'Uusi <strong>porausmittaus</strong>',
  'safety.measurements.boring': 'Porausmittaus',
  'safety.measurements.cleanlinessindex.new': 'Uusi <strong>siisteysindeksi</strong>',
  'safety.measurements.cleanlinessindex': 'Siisteysindeksi',
  'safety.measurements.crushing.new': 'Uusi <strong>murskausmittaus</strong>',
  'safety.measurements.crushing': 'Murskausmittaus',
  'safety.measurements.general.new': 'Uusi <strong>yleinen turvallisuuskierros</strong>',
  'safety.measurements.general': 'Yleinen turvallisuuskierros',
  'safety.measurements.mining.new': 'Uusi <strong>kaivosmittaus</strong>',
  'safety.measurements.mining': 'Kaivosmittaus',
  'safety.measurements.repairs.new': 'Uusi <strong>korjaamomittaus</strong>',
  'safety.measurements.repairs': 'Korjaamomittaus',
  'safety.measurements.sac.new': 'Uusi <strong>turvallisuus- ja siisteyskierros</strong>',
  'safety.measurements.sac': 'Turvallisuus- ja siisteyskierros',
  'safety.measurements.scaffolding.new': 'Uusi <strong>telinemittaus</strong>',
  'safety.measurements.scaffolding': 'Telinemittari',
  'safety.measurements': 'Työturvallisuusmittaukset',
  'safety.measurementsShort': 'Mittaukset',
  'safety.measurementType': '{0}-mittaus',
  'safety.measurer': 'Mittaaja',
  'safety.mostUsed': 'Eniten käytetyt',
  'safety.moveToFinishedMeasurementSummary': 'Siirry mittauksen yhteenvetoon',
  'safety.mvrMeasurement': 'MVR-mittaus',
  'safety.mvrMeasurements': 'MVR-mittaukset',
  'safety.nearMiss': 'Läheltä piti -tilanne',
  'safety.nearMisses': 'Läheltä piti -tilanteet',
  'safety.nearMissesShort': 'Läheltä piti',
  'safety.nearMissHelp': '<strong>Läheltä piti</strong> –ilmoitus tehdään työmaan tapahtumasta tai onnettomuudesta, joka on aiheuttanut tai olisi voinut aiheuttaa esinevahinkoja.',
  'safety.nearMissInfo': 'Tilanteen tiedot',
  'safety.newEnvironmentalHazard': 'Uusi ympäristöhavainto',
  'safety.newHazard': 'Uusi turvallisuushavainto',
  'safety.newHazardWithStrong': 'Uusi <strong>turvallisuushavainto</strong>',
  'safety.newInjury': 'Uusi tapaturma',
  'safety.newInjuryWithStrong': 'Uusi <strong>tapaturma</strong>',
  'safety.newMeasurement': 'Uusi <strong>{0}-mittaus</strong>',
  'safety.newMeasurement1': 'Uusi mittaus',
  'safety.newNearMiss': 'Uusi läheltä piti -tilanne',
  'safety.newNearMissWithStrong': 'Uusi <strong>läheltä piti -tilanne</strong>',
  'safety.newSACMeasurement': 'Uusi <strong>turvallisuus- ja siisteyskierros</strong>',
  'safety.noAnonymousReports': 'Ei vahvistamattomia tapahtumia',
  'safety.noHazards': 'Ei turvallisuushavaintoja',
  'safety.noHazardsWithSelections': 'Ei turvallisuushavaintoja valituilla kriteereillä',
  'safety.noInjuries': 'Ei tapaturmia',
  'safety.noLocationNoDeviationHelp': 'Pääurakoitsija ei ole merkinnyt työmaan aluetta. Uusien turvallisuushavaintojen lisääminen on mahdollista heti kun työmaalle on määritetty alue.',
  'safety.noNearMisses': 'Ei läheltä piti -tilanteita',
  'safety.noOpenEnvironmentalHazards': 'Ei avoimia ympäristöhavaintoja',
  'safety.noOpenHazards': 'Ei avoimia turvallisuushavaintoja',
  'safety.noRepairedEnvironmentalHazards': 'Ei korjattuja ympäristöhavaintoja',
  'safety.noRepairedHazards': 'Ei korjattuja turvallisuushavaintoja',
  'safety.numberOfObservations': 'Lisättävä määrä',
  'safety.offlineMeasurement': '<strong>Ei internet-yhteyttä.</strong> Mittaus voi jatkua, mutta päättäminen vaatii yhteyden.',
  'safety.openDeviationsShort': 'Avoimet',
  'safety.openHazards': 'Korjausta odottavat turvallisuushavainnot',
  'safety.openHazardsShort': 'Avoimet havainnot',
  'safety.otherCompany': 'Muu yritys',
  'safety.pendingReparation': 'Odottaa korjausta',
  'safety.peopleInjured': 'Vahingoittuneet',
  'safety.peopleInvolved': 'Osalliset',
  'safety.performMeasurement': 'Mittaa',
  'safety.photo': 'Valokuva',
  'safety.photos': 'Valokuvat',
  'safety.positiveHelp': '<strong>Posiitivinen havainto</strong> voidaan tehdä, kun halutaan kiinnittää huomiota turvallisuuden tai ympäristön kannalta esimerkilliseen toimintaan.',
  'safety.previousLocation': 'Edellinen sijainti',
  'safety.quarterPermissions': 'Turvavartit aliurakoitsijoille',
  'safety.quarterPermissionsLegend': 'Yritykset, joilla on oikeus pitää turvavartteja.',
  'safety.quarters.add': 'Lisää turvavartti',
  'safety.quarters.delete': 'Poista turvavartti',
  'safety.quarters.documents.info': 'Esim. osallistujalista, oma materiaali',
  'safety.quarters.new': 'Uusi turvavartti',
  'safety.quarters.noLinks': 'Aiheeseen ei ole liitetty linkkejä.',
  'safety.quarters.noMaterial': 'Aiheeseen ei ole liitetty materiaalia.',
  'safety.quarters.noPredefined': 'Työmaalle ei ole määritelty valmiita aiheita.',
  'safety.quarters.participantCount': 'Osallistujien lukumäärä',
  'safety.quarters.participants.info': 'Lista henkilöistä, ryhmän nimi, urakoitsijat, tms.',
  'safety.quarters.participants': 'Osallistujat',
  'safety.quarters.presenterName': 'Esittäjä',
  'safety.quarters.report.info': 'Pidettyjen turvavarttien listaus.',
  'safety.quarters.subject.newOnes': 'Uudet aiheet',
  'safety.quarters.subject.oldOnes': 'Käsitellyt aiheet',
  'safety.quarters.subject.own': 'Oma aihe, mikä?',
  'safety.quarters.subject': 'Aihe',
  'safety.quarters': 'Turvavartit',
  'safety.reason': 'Syy',
  'safety.removeReport.confirm.body': 'Haluatko varmasti poistaa tapahtuman <b>{0}</b>?',
  'safety.removeReport.confirm': 'Poistetaanko tapahtuma?',
  'safety.removeReport': 'Poista tapahtuma',
  'safety.removeSelection': 'Poista valinta',
  'safety.repairBy': 'Korjattava',
  'safety.repaired': 'Korjattu',
  'safety.repairedOnTheSpot': 'Korjattu kierroksella',
  'safety.repairer': 'Korjaaja',
  'safety.repairerComment': 'Korjaajan kommentit',
  'safety.repairs': 'Korjaustoimenpiteet',
  'safety.report.email.info.label1': 'Ilmoitus havainnosta lähetetään vastuuhenkilölle sekä',
  'safety.report.email.info.label2': 'työmaan turvallisuudesta vastaaville henkilöille',
  'safety.report.email.info.p1': 'Pääurakoitsijan ja tilaajan ylimääräisille turvallisuusvastaanottajille',
  'safety.report.email.info.p2': 'Urakoitsijan sopimuksella määritellylle turvallisuusvastaavalle',
  'safety.report.email.info.p3': 'Työmaan vastaavalle työnjohtajalle',
  'safety.report.email.info.p4': 'Pääurakoitsijan ja tilaajan sopimuksella määritellyille turvallisuusvastaaville',
  'safety.report.severityDeath': 'Kuolemaan johtanut',
  'safety.report.severityMild': '1-30 sairaspäivää',
  'safety.report.severityNone': '0 sairaspäivää',
  'safety.report.severitySevere': '31+ sairaspäivää',
  'safety.reporter': 'Ilmoittaja',
  'safety.reporterName': 'Ilmoittajan nimi',
  'safety.reportNotSynced': 'Tallentamatta <b>1</b> väärin-merkintä.',
  'safety.reports': 'Korjausta odottavat turvallisuushavainnot',
  'safety.reportsNotSynced': 'Tallentamatta <b>{0}</b> väärin-merkintää.',
  'safety.reportsSyncedAt': 'havaintoa tallennettu klo',
  'safety.responsiblePerson': 'Vastuuhenkilö',
  'safety.restoreReport.confirm.body': 'Haluatko varmasti palauttaa tapahtuman <b>{0}</b>?',
  'safety.restoreReport.confirm': 'Palautetaanko tapahtuma?',
  'safety.restoreReport': 'Palauta tapahtuma',
  'safety.right': 'Oikein',
  'safety.rightTotal': 'Oikein yhteensä',
  'safety.safetyByMonth': 'Taso kuukausittain',
  'safety.safetyBySite': 'Taso työmaittain',
  'safety.safetyMeasurement': 'Turvallisuusmittaus',
  'safety.safetyMeasurements': 'Turvallisuusmittaukset',
  'safety.safetyReports': 'Turvallisuusmittaukset',
  'safety.searchOrAddOtherCompany': 'Hakusana tai muu yritys',
  'safety.searchOrAddOtherLocation': 'Hakusana tai muu sijainti',
  'safety.searchOrAddOtherReason': 'Hakusana tai muu syy',
  'safety.searchOrOther': 'Haku/muu',
  'safety.searchOrOtherCompany': 'Haku/muu',
  'safety.section': 'Kohde',
  'safety.sectionDetails': 'Kohteiden kuvaukset',
  'safety.sections': 'Kohteet',
  'safety.selectYearAndMonth': 'Valitse vuosi ja kuukausi',
  'safety.showClosedHazards': 'Näytä korjatut turvallisuushavainnot',
  'safety.showDetails': 'Näytä kohteiden kuvaukset',
  'safety.showSummary': 'Näytä yhteenveto',
  'safety.sign': 'Allekirjoita',
  'safety.signatures': 'Allekirjoitukset',
  'safety.specialCategories': 'Erikoiskategoriat',
  'safety.syncedJustNow': 'Tallennettu juuri äsken.',
  'safety.syncing': 'Tallennetaan...',
  'safety.target': 'Tavoite',
  'safety.targetLevel': 'Tavoitetaso',
  'safety.trMeasurement': 'TR-mittaus',
  'safety.type': 'Tyyppi',
  'safety.waitingForSyncFinish': 'Odotetaan tallennuksen valmistumista...',
  'safety.weekShort': 'Vk',
  'safety.whatHappened': 'Kuvaus havainnosta',
  'safety.whatIsMyDeviation': 'Mikä merkintä tulisi lisätä?',
  'safety.wrong': 'Väärin',
  'safety.wrongEntries': 'Huomautukset',
  'safety.wrongTotal': 'Väärin yhteensä',
  'safetyMeasurement.reasonDescription': 'Tarkempi kuvaus',
  'safetyQuarter': 'Turvavartti',
  'safetyQuarters.contractorPresenter': 'Urakoitsija / Esittäjä',
  'safetyQuarters.participantsOnAverage': 'Osallistujat keskim.',
  'safetyQuarters.safetyQuartersQty': 'Turvavartit (kpl)',
  'safetyQuarters': 'Turvavartit',
  'sales.areYouInterestedInThisFeature': 'Haluatko toiminnon käyttöösi?',
  'sales.leaveContactRequest': 'Jätä yhteydenottopyyntö ja asiakasvastaavamme on yhteydessä yritykseenne.',
  'sales.premiumService': 'PREMIUM-<br>palvelu',
  'sales.requestSentModal.body': 'Kiitos! Otamme pian yhteyttä yritykseenne.',
  'sales.requestSentModal.heading': 'Yhteydenottopyyntö lähetetty',
  'sales.sendContactRequest': 'Lähetä yhteydenottopyyntö',
  'sales.sending': 'Lähetetään...',
  'sales.siteCopy.isPremiumFeature': '<b>Työmaan kopiointi</b> on maksullinen lisätoiminto.',
  'sales.siteCopy.readMore': 'Lue lisää työmaiden kopioinnista',
  'search.activeOrEnded': 'Työmaalupa aktiivinen/päättynyt',
  'search.andNOthers': 'ja {0} muuta',
  'search.declarationSearch': 'Ilmoitustunnushaku',
  'search.personNotFoundWithQRCode': 'QR-koodilla ei löytynyt henkilöä.',
  'search.results': 'Tulokset',
  'search.scanAgain': 'Skannaa uudelleen',
  'search.scanQRCode': 'Skannaa QR-koodi',
  'search.searchCustomLocation': 'Valitse sijainti',
  'search.searchOrSelectOrAddPlaceholder': 'Hae, valitse tai lisää',
  'search.searchOrSelectPlaceholder': 'Hae tai valitse',
  'search.showingOnlyPartOfResults': 'Näytetään vain osa hakutuloksista. Tarkenna hakua.',
  'search.showingOnlyTenFirst': 'Näytetään vain 10 ensimmäistä',
  'search.terms': 'Haku',
  'search.totalResults': 'Hakutuloksia {0} kpl',
  'search': 'Haku',
  'serverOffline': 'Yhteys poikki.',
  'serverOfflineReconnecting': 'Yhteys poikki. Yhdistetään...',
  'settings.accessControlTransfer.modal.error': 'Siirron käynnistäminen epäonnistui. Joko sama siirto on jo käynnistetty tai Zeronissa on ruuhkaa. Ole hyvä ja yritä hetken kuluttua uudelleen.',
  'settings.accessControlTransfer.modal.execute': 'Käynnistä siirto',
  'settings.accessControlTransfer.modal.executing': 'Käynnistetään...',
  'settings.accessControlTransfer.modal.info': 'Siirto päivittää työmaan työmaa- sekä ajoneuvoluvat kulunvalvontajärjestelmään.<br/><br/> Lupien päivittäminen voi olla tarpeen poikkeustilanteessa tietoliikenneongelman tai asetusmuutosten jälkeen.<br/><br/> Siirron suoritus kestää useita minuutteja ja se suoritetaan tausta-ajona. Kesto riippuu päivitettävien lupien määrästä.',
  'settings.accessControlTransfer.modal.infoForRetry': 'Siirto päivittää kaikista ympäristön omistamista työmaista työmaa- sekä ajoneuvoluvat kulunvalvontajärjestelmään niiden lupien osalta, joiden siirto on päättynyt virheeseen.<br/><br/> Lupien määrästä riippuen, siirron suoritus voi kestää useita minuutteja. Siirto suoritetaan tausta-ajona.',
  'settings.accessControlTransfer.modal.ready': 'Siirto käynnistetty',
  'settings.accessControlTransfer.modal.success.title': 'Siirto käynnistetty',
  'settings.accessControlTransfer.modal.success': 'Siirto valmistuu noin kello {0}',
  'settings.accessControlTransfer.modal.title': 'Käynnistä siirto',
  'settings.addressOfForeignEmployee': 'Ulkomaalaisen osoitetiedot',
  'settings.admittance': 'Kulkulupa',
  'settings.admittanceAsked': 'Määritellään erikseen halutaanko myös kulkulupa',
  'settings.allowJobTitleOutsideTheList': 'Salli myös työnimikkeet, jotka eivät ole listalla',
  'settings.allowMaterialsOutsideTheList': 'Salli myös materiaalit, jotka eivät ole listalla',
  'settings.askForeignerRepresentative': 'Ulkomaalaisilta ja ulkomailla asuvilta vaaditaan aina edustajan tiedot',
  'settings.automaticDataSharingAgreementDesc': 'Tietojenluovutus-sitoumus luodaan automaattisesti työmaaluvan luonnin yhteydessä',
  'settings.automaticPermitCreation': 'Automaattinen työmaaluvan luonti',
  'settings.automaticPermitCreationDescription': 'Jos henkilöllä ei ole voimassaolevaa työmaalupaa, luodaan uusi työmaalupa automaattisesti, kun henkilö leimaa valttikortillaan. Kortin on oltava voimassa ja korttiin merkityllä yrityksellä on oltava aktiivinen sopimus. Jos työmaalla on päällä "Työnteko-oikeuden tarkastus", jää uusi työmaalupa odottamaan tarkastusta. Työmaalupaa ei luoda porttilaitteilla leimatessa.',
  'settings.automaticPermitCreationFromValttiCheckInEnabled': 'Luo työmaalupa automaattisesti Valttikorttileimauksesta',
  'settings.automaticVisitorPermitDesc': 'Tuoteturvallisuuslupa luodaan automaattisesti työmaaluvan luonnin yhteydessä',
  'settings.birthdayNotifications': 'Ilmoitus syntymäpäivistä',
  'settings.buyerInAccessPermit': 'Tilaajan yhteyshenkilö',
  'settings.buyersReferenceId': 'Ostotilausnumero pakollinen',
  'settings.buyersReferenceIdRequired': 'Ostotilausnumero tuntilapussa',
  'settings.commonDocs.info': 'Yleiset dokumentit ovat kaikkien urakoitsijoiden nähtävillä kaikilla yrityksesi luomilla työmailla.',
  'settings.commonDocs': 'Yleiset dokumentit',
  'settings.companySettingsForPermit': 'Työmaaluvassa kysyttävät tiedot',
  'settings.companySettingsForPermitInfo': 'Alla olevilla asetuksilla voi säätää mitä tietoja uusilla työmailla vaaditaan työntekijän työmaaluvan luomisen yhteydessä. Asetuksia voi muuttaa työmaakohtaisesti.',
  'settings.course': 'Koulutus tai kurssi',
  'settings.courseDuration': 'Voimassaoloaika',
  'settings.courseInfoText': 'Henkilön osaamiseen valittavat koulutukset ja kurssit',
  'settings.courseRemovalConfirmation': 'Haluatko varmasti poistaa koulutuksen tai kurssin <b>{0}</b>?',
  'settings.courses.durationHelp': '<strong>Voimassaoloaika</strong> voidaan myös jättää tyhjäksi. Tällöin koulutus ei vanhene.',
  'settings.courses': 'Koulutukset ja kurssit',
  'settings.coursesAndCompetences': 'Koulutukset ja pätevyydet',
  'settings.coursesEmpty': 'Yhtään koulutusta tai kurssia ei ole vielä lisätty!',
  'settings.createCustomSection': 'Luo kohde',
  'settings.criterionChangeInfo': 'Tämä on kirjoitusasun muutos. Jo syötetyt arviot säilyvät, vain kriteerin nimi vaihtuu.',
  'settings.customMeasurement': 'Erikoismittauskohteet',
  'settings.customSafetyEventLocations.info': 'Tapahtumien kirjaus vain tiettyihin sijainteihin eli sijaintia ei voi syöttää vapaasti. Muutokset näihin sijainteihin tuen kautta.',
  'settings.customSafetyEventLocations': 'Käytä yrityskohtaisia turvallisuustapahtumasijainteja',
  'settings.customSafetySectionName': 'Nimi',
  'settings.customSafetySections': 'Turvallisuusmittauksen erikoiskohteet',
  'settings.customSection': 'Erikoiskohde',
  'settings.customSectionActive': 'Aktiivinen kohde',
  'settings.customSectionDescription': 'Kohteen kuvaus',
  'settings.customSectionHelp.more': 'Turvallisuusmittauksen erikoiskohteet ovat tavalliseen mittaukseen kuulumattomia kohteita, joita voidaan seurata mittauksen yhteydessä.<br><br>Tällä sivulla voit muokata kohteita ja luoda uusia. Uuteen mittaukseen otetaan mukaan aktiiviseksi merkityt kohteet. Erikoiskohteet eivät vaikuta turvallisuusmittauksen tulokseen. Huom. kohdetta ei voi poistaa enää sen jälkeen kun se on luotu.<br/><br/>Luodun kohteen voi asettaa aktiiviseksi valitun työmaan alta, kohdasta "Hallinta" &rarr; "Erikoismittauskohteet".',
  'settings.customSectionHelp': 'Turvallisuusmittauksen erikoiskohteet ovat tavalliseen mittaukseen kuulumattomia kohteita, joita voidaan seurata mittauksen yhteydessä.',
  'settings.customSectionName': 'Nimi',
  'settings.customSections': 'Erikoiskohteet',
  'settings.customSectionsEmpty': 'Yhtään erikoiskohdetta ei ole vielä lisätty.',
  'settings.dailyReportSectionsEmpty': 'Yhtään päiväkirjapohjan kohdetta ei ole vielä lisätty!',
  'settings.dailyReportTemplate': 'Päiväkirjapohja',
  'settings.dailyReportTemplateInfo': 'Tällä sivulla voit määrittää työmaapäiväkirjan oletuspohjan, joka asetetaan työmaalle työmaan luonnin yhteydessä. Pohjaa voi muokata työmaakohtaisesti jälkikäteen.',
  'settings.dailyReportTemplateSectionRemovalConfirmation': 'Haluatko varmasti poistaa päiväkirjapohjan kohteen <b>{0}</b>?',
  'settings.dailyReportTemplateSections': 'Päiväkirjapohjan kohteet',
  'settings.dailyReportTemplateSectionsEmpty': 'Yhtään päiväkirjapohjan kohdetta ei ole vielä lisätty!',
  'settings.dataSharingAgreement': 'Tietojenluovutus-sitoumus',
  'settings.departmentRemovalConfirmation': 'Haluatko varmasti poistaa yksikön <b>{0}</b>?',
  'settings.departments': 'Yksiköt',
  'settings.departmentsEmpty': 'Yhtään yksikköä ei ole vielä lisätty.',
  'settings.departmentsInfo': 'Jos yritykselläsi on useita yksiköitä ja haluat seurata työmaita yksikkökohtaisesti, voit määritellä yrityksesi yksiköt tällä sivulla. Yksiköitä ei voi poistaa, jos niille on jo määritelty työmaita.',
  'settings.description': 'Kuvaus',
  'settings.descriptionOfVehiclePermitType': 'Ajoneuvoluvan tarkempi kuvaus. Esim. mihin ajoneuvolupa oikeuttaa yms.',
  'settings.durationInHours': 'tuntia',
  'settings.editCourse': 'Muokkaa koulutusta tai kurssia',
  'settings.editDailyReportTemplateSection': 'Muokkaa päiväkirjapohjan kohdetta',
  'settings.editDepartment': 'Muokkaa yksikköä',
  'settings.editOrientationTopic': 'Muokkaa perehdytysaihetta',
  'settings.editProfession': 'Muokkaa ammattinimikettä',
  'settings.editSection': 'Muokkaa kohdetta',
  'settings.emailRequired': 'Sähköpostiosoitteen määrittely pakollinen henkilötiedoissa',
  'settings.emailRequiredShort': 'Sähköpostiosoite pakollinen',
  'settings.expertise.companyFollowed.infoShort': 'Yritystasolla seurattaviksi valittuja pätevyyksiä seurataan jokaisella työmaalla.',
  'settings.expertise.followed.info': 'Yritystasolla asetettuja seurattavia pätevyyksiä seurataan jokaisella työmaalla, eikä niitä voi työmaakohtaisesti poistaa. Voit asettaa erillisiä työmaakohtaisia seurattavia pätevyyksiä yritystasolla asetettujen lisäksi.',
  'settings.expertise.followed': 'Työmailla seurattavat pätevyydet',
  'settings.expertise.info': 'Uusille työmaille oletuksena valittavat pätevyys- ja koulutusvaatimukset. Työmaakohtaisia asetuksia voi muuttaa työmaan tiedoissa.',
  'settings.expertise.requiredCompetences': 'Pakolliset pätevyydet',
  'settings.expertise.requiredCourses': 'Pakolliset koulutukset',
  'settings.expertise.requiredExpertise': 'Osaamisvaatimukset',
  'settings.finnishAddressRequiredForForeignEmployee': 'Vaaditaan ulkomaalaisen osoitetieto Suomessa',
  'settings.gateAllowedTags': 'Porttien kulkutunnisteet',
  'settings.general.clStatusAndTaxReport.info': 'Valituille vastaanottajille lähetetään sähköpostia tilaajavastuu- ja verottajailmoitusasioista. Ilmoitus lähtee kun urakoitsijan tilaavastuutiedot muuttuvat puutteellisiksi. Lisäksi kerran kuussa lähetetään raportti verottajailmoitusten virheistä.',
  'settings.general.clStatusAndTaxReport': 'Tilaajavastuu- ja verottajailmoitus -viestien vastaanottajat',
  'settings.general.clStatusNotficationContacts': 'Ilmoitukset urakoitsijoiden tilaajavastuutietojen puuttellisuuksista',
  'settings.general.clStatusNotficationContactsInfo': 'Alla valituille vastaanottajille lähetetään sähköpostia jos urakoitsijoiden tilaajavastuutiedot muuttuvat puutteellisiksi. Ilmoitus lähetetään päivittäin vain viimeisten 24 tunnin aikana tapahtuneista tilaajavastuumuutoksista.',
  'settings.general.gate.desc': 'Ainoastaan vahvistetut työntekijät pääsevät portista läpi',
  'settings.general.gate': 'Portti',
  'settings.generateMissingCheckIns.info': 'Leimaus tulkitaan puuttuvaksi jos sisäänleimauksen jälkeen ei löydy leimausta seuraavan 13 tunnin sisällä tai ulosleimausta ennen ei löydy leimausta edellisen 13 tunnin sisällä. Puuttuva leimaus lisätään 7 tunnin päähän löytyneestä leimauksesta. Järjestelmä täydentää puuttuvia leimauksia kahdesti päivässä klo 05:00 ja 12:00.',
  'settings.generateMissingCheckIns': 'Leimausten lisäys',
  'settings.goEdit': 'Siirry muokkaamaan',
  'settings.hotWorkPermit.allowedFireExtinguishers.43A': 'Sallitaanko käsisammuttimen 43A käyttö',
  'settings.hotWorkPermit.allowedFireExtinguishers.enabled': 'Sallitaan',
  'settings.hotWorkPermit.allowedFireExtinguishers': 'Sallitut sammuttimet tulityöluvissa',
  'settings.hotWorkPermit.hotWorkCardRequiredFromHotWorkPerformersAndGuards': 'Tulityöntekijältä sekä vartijalta vaaditaan tulityökortti',
  'settings.hotWorkPermit.hotWorkCardRequirement': 'Tulityökortin pakollisuus',
  'settings.hotWorkPermitMaximumDuration': 'Tulityöluvan maksimikesto',
  'settings.hotWorkPermitMaximumDurationUnit': 'päivää',
  'settings.hotWorkPermitSettings': 'Tulityöluvan oletusasetukset',
  'settings.hotWorkPermitSettingsInfo': 'Nämä ovat oletusasetuksia, jotka kopioidaan työmaan asetuksiksi työmaan luonnin yhteydessä. Työmaakohtaisia asetuksia voi muuttaa työmaan tiedoissa.',
  'settings.hourBank': 'Työaikapankki',
  'settings.hourBankDesc': 'Tuntilapulla mahdollisuus käyttää työaikapankkia',
  'settings.idConfirmationPhotoInPermit.notRequired': 'Ei vaadita',
  'settings.idConfirmationPhotoInPermit.requiredForForeigners': 'Vaaditaan ulkomaalaisilta',
  'settings.idConfirmationPhotoInPermitShort': 'Kuva henkilöllisyystodistuksesta',
  'settings.insurer': 'Tulityölupien vakuuttaja',
  'settings.integrations': 'Integraatiot',
  'settings.integrations.description': 'Integraatioiden api-avaimet sekä asetukset.',
  'settings.integrations.astora.includeAlreadyProcessed.info': 'Hae myös käsitellyt',
  'settings.integrations.astora.includeAlreadyProcessed': 'Käsitellyt',
  'settings.integrations.astora.invoiceHourTransfer.confirm': 'Oletko varma, että haluat siirtää tuntilappumerkinnät käsitellyiksi csv-tiedostoon annetulta aikaväliltä? Jos aikaväliä ei ole annettu, haetaan kaikki. Toimintoa ei voi peruuttaa vahvistuksen jälkeen.',
  'settings.integrations.astora.invoiceHourTransfer.info': 'Tällä toiminnolla voit hakea hyväksytyt tuntilappurivit. Tulosta voi rajata annetulla aikavälillä. Haku merkitsee tuntilappurivit käsitellyiksi eivätkä ne tule uudelleen näkyviin seuraavaan hakuun. Valitsemalla "Hae myös käsitellyt", voit hakea myös jo aikaisemmin käsiteltyjä tuntilappurivejä.',
  'settings.integrations.invoiceHourTransfer.confirm': 'Oletko varma, että haluat lähettää kaikki aikavälillä <b>{0} - {1}</b> hyväksytyt tuntilappurivit palkanlaskentaan? Toimintoa ei voi peruuttaa.',
  'settings.integrations.invoiceHourTransfer.info': 'Tällä toiminnolla voit siirtää hyväksytyt tuntilappurivit V10-palkanlaskentajärjestelmään. Mukaan otetaan vain ne tuntilappurivit, jotka on kirjattu annetulle aikavälille.',
  'settings.integrations.invoiceHourTransfer': 'Tuntilappusiirto',
  'settings.integrations.mepco.confirm': 'Oletko varma, että haluat luoda palkka-aineiston aikavälin <b>{0} - {1}</b> ja roolin <b>{2}</b> hyväksytyistä tuntilappuriveistä? Toimintoa ei voi kumota.',
  'settings.integrations.mepco.fetchPendingRows': 'Nouda hyväksyntää odottavat rivit',
  'settings.integrations.mepco.getExcel': 'Esikatsele palkka-aineistoa',
  'settings.integrations.mepco.heading': 'Palkka-aineiston luonti',
  'settings.integrations.mepco.info': 'Tällä toiminnolla voit noutaa Excel-raportin tuntilappuriveistä Mepco-palkkajärjestelmää varten. Tunnit merkitään tällöin siirretyiksi eikä niitä voi ottaa enää toiselle raportille. Voit myös esikatsella rivejä ennen tuntien siirtoa.',
  'settings.integrations.mepco.noPendingRows': 'Valitun kauden kaikki rivit on hyväksytty.',
  'settings.integrations.mepco.onlyApproved': 'Vain hyväksytyt',
  'settings.integrations.mepco.onlyPending': 'Vain hyväksyntää odottavat',
  'settings.integrations.mepco.pendingRows': 'Kaudella on edelleen hyväksyntää odottavia rivejä!',
  'settings.integrations.mepco.rowStatus': 'Rivin tila',
  'settings.integrations.mepco.showPendingHours': 'Näytä myös hyväksyntää odottavat',
  'settings.integrations.mepco.showTransferedHours': 'Näytä myös siirretyt tunnit',
  'settings.integrations.mepco.transferHours.success.info': 'Palkka-aineisto luotu.',
  'settings.integrations.mepco.transferHours.success.link': 'Avaa palkka-aineisto.',
  'settings.integrations.mepco.transferHours': 'Luo palkka-aineisto',
  'settings.integrations.mepco': 'Mepco',
  'settings.invoiceHours.checkedInHoursShown.info': 'Työntekijän tuntilappunäkymässä näytetään tuntileimaukset',
  'settings.invoiceHours.checkedInHoursShown': 'Leimausten näkyvyys',
  'settings.invoiceHours.copyApprover': 'Hyväksyjän esivalinta',
  'settings.invoiceHours.copyApproverFromPreviousEntry': 'Esivalitse hyväksyjä edellisen tuntilappumerkinnän perusteella',
  'settings.jobtitles.fileFormatInfo': 'Lähetettävän tiedoston tyypiksi suosittelemme Excel (xlsx tai xls). Jos haluat käyttää csv-tiedostoa on sisältö oltava UTF-8 merkistössä.',
  'settings.jobTitlesUpdated': 'Tiedosto <strong>{0}</strong> käsitelty ja työnimikelista päivitetty.',
  'settings.laborAgreement': 'Työehtosopimus pakollinen',
  'settings.laborAgreementRequired': 'Työehtosopimuksen määrittely pakollinen työmaaluvassa',
  'settings.minimumGuardingTimeAfterHotWork': 'Tulityön jälkeisen vartioinnin minimiaika',
  'settings.modifyVehiclePermitType': 'Muokkaa työmaan ajoneuvolupaa',
  'settings.newCourse': 'Uusi koulutus tai kurssi',
  'settings.newDailyReportTemplateSection': 'Uusi päiväkirjapohjan kohde',
  'settings.newDepartment': 'Uusi yksikkö',
  'settings.newOrientationTopic': 'Uusi perehdytysaihe',
  'settings.newProfession': 'Uusi ammattinimike',
  'settings.newSection': 'Uusi erikoiskohde',
  'settings.newSiteVehiclePermitType': 'Uusi ajoneuvolupatyyppi',
  'settings.noRights': 'Sinulla ei ole oikeuksia.',
  'settings.onlySiteTagsToGates': 'Porteista kulku sallittu vain työmaan luovuttamilla kulkutunnisteilla',
  'settings.orientationTopic': 'Perehdytysaihe',
  'settings.orientationTopicRemovalConfirmation': 'Haluatko varmasti poistaa perehdytysaiheen <b>{0}</b>?',
  'settings.orientationTopics': 'Perehdytysaiheet',
  'settings.orientationTopicsEmpty': 'Yhtään perehdytysaihetta ei ole vielä lisätty!',
  'settings.otherInsurer': 'Tulityölupa ei käytössä',
  'settings.overTimeReasonRequired': 'Selvitys ylityöstä',
  'settings.paperOrientation': 'Paperiperehdytys',
  'settings.paperOrientationAllowed': 'Sallitaan sähköisen perehdytyksen vahvistuksen lisäksi paperivahvistus perehdytyksestä',
  'settings.personsVerification.automatic.info': 'Ei erillistä työnteko-oikeuden tarkastusta. Lupa astuu heti voimaan, kun pätevyydet ja perehdytyksen vahvistus ovat kunnossa.',
  'settings.personsVerification.automatic': 'Työmaalupa astuu välittömästi voimaan',
  'settings.personsVerification.manual.info': 'Jokaisen henkilön työnteko-oikeus tarkastetaan erikseen. Tarkastusta odottavat henkilöt näkyvät myös etusivun ilmoituksissa.',
  'settings.personsVerification.manual': 'Työnteko-oikeus tarkastetaan',
  'settings.personsVerification.newVehiclePermit.convert.no': 'Poista kaikki vanhat ajoneuvoluvat pysyvästi. (Huom! Tämä valinta edellyttää, että jo myönnetyt ajoneuvoluvat luodaan uudelleen)',
  'settings.personsVerification.newVehiclePermit.convert.yes': 'Muokkaa vanhalla lupamoduulilla luodut ajoneuvoluvat uusiksi ajoneuvoluviksi (oletus)',
  'settings.personsVerification.newVehiclePermitInfo': 'Onko työmaallanne käytetty vanhaa ajoneuvolupamoduulia ja haluat ottaa työnteko-oikeuden tarkastusprosessin käyttöön? Huomioithan, että tarkastusprosessin päälle kytkeminen edellyttää työmaalta myös uuden ajoneuvolupamoduulin käyttöönottamista. Vahvista allaolevalla valinnalla, kuinka vanhat ajoneuvoluvat käsitellään jatkossa. Huomioithan, että valintaa ei voi peruuttaa.',
  'settings.personsVerification': 'Työnteko-oikeuden tarkastaminen',
  'settings.phoneNumberRequired': 'Puhelinnumeron määrittely pakollinen henkilötiedoissa',
  'settings.phoneNumberRequiredShort': 'Puhelinnumero pakollinen',
  'settings.postcodeMandatoryForPerson': 'Suomessa asuvilta vaaditaan postinumero',
  'settings.profession': 'Ammattinimike',
  'settings.professionRemovalConfirmation': 'Haluatko varmasti poistaa ammattinimikkeen <b>{0}</b>?',
  'settings.professionRequired': 'Ammattinimikkeen määrittely pakollinen työmaaluvassa',
  'settings.professions': 'Ammattinimikkeet',
  'settings.professionsEmpty': 'Yhtään ammattinimikettä ei ole vielä lisätty!',
  'settings.removeCourse': 'Poista koulutus tai kurssi',
  'settings.removeDailyReportTemplateSection': 'Poista päiväkirjapohjan kohde',
  'settings.removeDepartment': 'Poista yksikkö',
  'settings.removeOrientationTopic': 'Poista perehdytysaihe',
  'settings.removeProfession': 'Poista ammattinimike',
  'settings.representative': 'Ulkomaalaisilta vaaditaan edustajan yhteystiedot',
  'settings.residencePermitDocument': 'Oleskeluluvan dokumentit',
  'settings.residencePermitDocumentRequired': 'Oleskelulupaan täytyy syöttää dokumentit',
  'settings.restAreaAsked': 'Kysytään sosiaalitilaa',
  'settings.restRoom': 'Sosiaalitila',
  'settings.safety.quarters.newSubject': 'Uusi aihe',
  'settings.safety.quarters.removeSubject.confirm': 'Haluatko varmasti poistaa aiheen <b>{0}</b>?',
  'settings.safety.quarters.removeSubject': 'Poista aihe',
  'settings.safety.quarters.subject.material': 'Materiaali',
  'settings.safety.quarters.subject': 'Aihe',
  'settings.safety.quarters.subjectlistEmpty': 'Yhtään aihetta ei ole vielä lisätty.',
  'settings.safety.quarters.subjects.edit': 'Muokkaa aihetta',
  'settings.safety.quarters.subjects': 'Turvavarttien aiheet',
  'settings.safety.reports': 'Turvallisuushavainnot',
  'settings.safety.reportSettings': 'Turvallisuushavaintojen asetukset',
  'settings.safety.reportTimeRequired': 'Turvallisuushavaintoihin vaaditaan kellonaika',
  'settings.safety': 'Turvallisuus',
  'settings.safetyLocations': 'Turvallisuussijainnit',
  'settings.safetySection': 'Erikoismittauskohde',
  'settings.safetySectionActive': 'Aktiivinen',
  'settings.showBirthdayNotifications': 'Näytä etusivulla ilmoitus työmaan työntekijöiden syntymäpäivistä',
  'settings.siteBasicDetails': 'Työmaan perustiedot',
  'settings.siteCardPhotoInPermit.info': 'Kortin tulee sisältää henkilön nimi, valokuva ja veronumero, sekä yrityksen nimi, yhtiömuoto ja Y-tunnus.',
  'settings.siteCardPhotoInPermit.notRequired': 'Ei vaadita',
  'settings.siteCardPhotoInPermit.requiredForAll': 'Vaaditaan kaikilta',
  'settings.siteCardPhotoInPermit.requiredForForeigners': 'Vaaditaan ulkomaalaisilta',
  'settings.siteCardPhotoInPermit': 'Kuva yrityksen henkilötunnistekortista',
  'settings.siteSettings': 'Työmaan oletusasetukset',
  'settings.siteSettingsInfo': 'Nämä ovat oletusasetuksia, jotka kopioidaan työmaan asetuksiksi työmaan luonnin yhteydessä. Työmaakohtaisia asetuksia voi muuttaa työmaan tiedoissa.',
  'settings.siteSettingsNonConstructionInfo': 'Työmaan ei tarvitse olla perinteinen rakennusprojekti, vaan määritelmä voi koskea myös muita töitä, kuten kunnossapitoa. Asetusta valitessasi varmista, täyttääkö työmaa jomman kumman yhteisen rakennustyömaan määritelmän:<ul class="mt-1"><li>Työmaan kesto on yli 30 vuorokautta ja työnsuorittajia (ml. ammatinharjoittajat) on työmaalla yli 10 henkilöä<br><em>tai</em></li><li>Työmaan työmäärä ylittää 500 henkilötyöpäivää kokonaisuudessaan</li></ul>Jos ehdot eivät täyty, tällöin kyseessä ei ole rakennustyömaa, eikä sille sovelleta Valtioneuvoston asetuksen rakennustyön turvallisuudesta <a href="https://www.finlex.fi/fi/laki/alkup/2009/20090205#Pidm46494958209712/" rel="noopener" target="_blank">määrittelemiä velvoitteita <span class="far fa-external-link"></span></a> (finlex.fi)',
  'settings.siteSpecificJobTitles': 'Työnimikkeet',
  'settings.siteSpecificJobTitlesDesc': 'Käytetään työmaakohtaista työnimikelistaa yleisen listan sijaan',
  'settings.siteVehiclePermitTypes': 'Työmaan ajoneuvolupatyypit',
  'settings.specifyBuyerInAccessPermit': 'Työmaaluvassa määritellään työn tilaajan yhteyshenkilö',
  'settings.supplierRegisterCriteria': 'Toimittajien arviontikriteerit',
  'settings.updateCustomSection': 'Päivitä kohde',
  'settings.updateSafetySections': 'Päivitä erikoiskohteet',
  'settings.vehiclePermit': 'Ajoneuvolupa',
  'settings.vehiclePermitAsked': 'Kysytään ajoneuvolupaa',
  'settings.vehiclePermitType': 'Ajoneuvolupatyyppi',
  'settings.vehiclePermitTypes': 'Ajoneuvolupatyypit',
  'settings.visitor': 'Vierailijalupa',
  'settings.visitorAsked': 'Työmaan pääurakoitsija ja tilaaja voivat luoda vierailijalupia',
  'settings.visitorPermit': 'Tuoteturvallisuuslupa',
  'settings.visyTransfer': 'Visygate-siirto',
  'settings.workPermitMaximumDuration': 'Työluvan maksimikesto',
  'site.additionalSelection': 'Lisävalinta',
  'site.additionalSettings': 'Lisäasetukset',
  'site.anonReportInfo': 'Teehavainto.fi kautta tulleista turvallisuushavainnoista lähtee heti sähköpostiviesti  valituille vastaanottajille. Lisäksi vahvistamattomista raporteista lähtee muistutusviesti maanantain ja torstain vastaisena yönä.',
  'site.anonReportReceivers': 'Teehavainto.fi -raporttien vastaanottajat',
  'site.AsafetyMeasurementType': 'Valinta',
  'site.askAboutContractEndDateChange': 'Muutetaanko työmaan sopimusten päättymispäivää? Tämä vaihtoehto päivittää kaikki sopimukset, <strong>joiden päättymispäivä on {0}</strong>. Uusi päättymispäivä on {1}.',
  'site.askAboutContractStartDateChange': 'Muutetaanko työmaan sopimusten alkamispäivää? Tämä vaihtoehto päivittää kaikki sopimukset, <strong>joiden alkamispäivä on {0}</strong>. Uusi aloituspäivämäärä on {1}.',
  'site.askAboutPermitEndDateChange': 'Haluatko muuttaa myös työmaalupien päättymisajankohtaa? Tämä vaihtoehto päivittää kaikki työmaaluvat, <strong>joiden päättymispäivä on {0}</strong>. Uusi päättymispäivä on {1}.',
  'site.askAboutPermitStartDateChange': 'Haluatko muuttaa työmaalupien alkamispäivää? Tämä vaihtoehto päivittää kaikki työmaaluvat, <strong>joiden alkamispäivä on {0} ja joita ei ole vahvistettu</strong>. Uusi aloituspäivämäärä on {1}.',
  'site.automaticPeriodChange': 'Sopimusten ja työmaalupien voimassaoloa muutetaan automaattisesti siten, että ne ovat voimassaolokauden {0} - {1} sisällä.',
  'site.automaticPeriodChangeContractsOnly': 'Sopimusten voimassaoloa muutetaan automaattisesti siten, että ne ovat voimassaolokauden {0} - {1} sisällä.',
  'site.bdayNotificationInfo': 'Lähetetään syntymäpäivää edeltävän päivän aamuna klo 7.',
  'site.bdayNotificationReceivers': 'Ilmoitus työmaan työntekijöiden syntymäpäivistä',
  'site.changeSite': 'Vaihda työmaa',
  'site.changingSite': 'Vaihdetaan työmaata...',
  'site.checkIns': 'Leimaukset',
  'site.chooseReceivers': 'Valitse vastaanottajat',
  'site.continuousReportsToTaxAdmin': 'Lähetyskautta ei ole rajattu. Perusilmoituksia lähetetään työmaan voimassaoloajan mukaan.',
  'site.contractors': 'Urakoitsijat',
  'site.copy': 'Kopioi',
  'site.dailyReport.template': 'Päiväkirjapohja',
  'site.dailyReport': 'Työmaapäiväkirja',
  'site.dailyReports': 'Päiväkirja',
  'site.defineEmailReceivers': 'Työmaata koskevien sähköposti-ilmoitusten vastaanottajat. Vastaanottajat valitaan työmaan pääurakoitsijan ja tilaajan käyttäjistä.',
  'site.chosenDeveloperIsCustomer': 'Valittu tilaaja on Zeroni -asiakas. Myös tilaajalle myönnetään pääsy työmaan tietoihin.',
  'site.editSiteInfo': 'Muokkaa työmaan tietoja',
  'site.emails.blackMarket.info': 'Lähetetään aina kun teehavainto.fi-sivustolla on tehty ilmoitus harmaasta taloudesta.',
  'site.emails.blackMarket': 'Harmaa talous',
  'site.emails.feedback.info': 'Lähetetään aina kun teehavainto.fi-sivustolla on lähetetty vapaamuotoista palautetta.',
  'site.emails.feedback': 'Vapaamuotoinen palaute',
  'site.emails.HSE.info': 'Lähetetään aina kun uusi vaaratilanne tai tapaturma kirjataan.',
  'site.emails.HSE': 'Tapaturma & Vaaratilanne ilmoitusten vastaanottajat',
  'site.emails.personsPendingVerification.info': 'Lähetetään joka yö',
  'site.emails.personsPendingVerification': 'Ilmoitus henkilöistä, joiden työnteko-oikeus odottaa tarkastusta',
  'site.emails.vehiclePermitsPendingApproval': 'Ilmoitus hyväksyntää odottavista ajoneuvoluvista',
  'site.emails': 'Sähköpostit',
  'site.expertise.noRightsToModifyCustomExpertise': 'Koulutukset määrittää työmaan omistaja.',
  'site.expertise.photoOfCompetenceCertificate': 'Pätevyyden todistus',
  'site.expertise.photoOfCompetenceCertificateRequired': 'Uutta pätevyyttä luotaessa vaaditaan kuva pätevyystodistuksesta',
  'site.expertise.requiredCustomCompetences.inherited': 'Tilaajan asettamat',
  'site.expertise.requiredCustomExpertise': 'Työmaan pakolliset koulutukset',
  'site.extendContractsAndPermits': 'Olet pidentämässä työmaan voimassaoloaikaa. Pidennetäänkö myös sopimusten ja työmaalupien voimassaoloajat?',
  'site.extendingEndDate': 'Olet muuttamassa työmaan päättymispäivää myöhäisemmäksi.',
  'site.extendingStartDate': 'Olet muuttamassa työmaan alkamispäivää aikaisemmaksi.',
  'site.followedCompetences.fromCompanyLevel': 'Yritystasolla seurattavaksi valitut pätevyydet',
  'site.followedCompetences': 'Pätevyyksien seuranta',
  'site.followedCompetencesAll': 'Kaikki pätevyydet',
  'site.followedCompetencesDescription': 'Etusivun ilmoituksissa näytetään vanhentuneet tai pian vanhenevat pätevyydet. Ilmoituksissa näkyvät vain seurattavat pätevyydet.',
  'site.followedCompetencesRequired.description': 'Asetetaan työmaan asetuksissa, kohdassa "Vaatimukset"',
  'site.followedCompetencesRequired': 'Työmaan pakolliset pätevyydet',
  'site.followedCompetencesSelected': 'Valitse seurattavat pätevyydet erikseen',
  'site.gate.desc': 'Ainoastaan vahvistetut työntekijät pääsevät portista läpi',
  'site.gate': 'Portti',
  'site.generateMissingCheckins': 'Puuttuvien leimausten automaattinen lisäys',
  'site.hasNoSafetyMeasurementType': 'Työmaalle ei ole valittu työ&shy;turvallisuus&shy;mittauksen tyyppiä',
  'site.hotWorkPermits': 'Tulityöluvat',
  'site.integration.visygateId': 'Visygate-tunniste',
  'site.introduction': 'Vahvistus',
  'site.link.description': 'Kuvaus (esim. Google)',
  'site.link.example': 'Osoite (esim. http://www.google.com)',
  'site.link': 'Linkki',
  'site.links': 'Linkkejä',
  'site.manage': 'Hallinta',
  'site.noContractors': 'Ei urakoitsijoita',
  'site.noneSelected': 'Ei valittua työmaata',
  'site.noRequirementsAvailable': 'Ei valittavia pätevyyksiä',
  'site.noTrainingsAvailable': 'Ei valittavia koulutuksia',
  'site.number.acceptNumberSuggestion': 'Käytetäänkö tunnistetta {0}?',
  'site.number.autoGenerate': 'Laske numero',
  'site.number.edit': 'Muokkaa numeroa',
  'site.number.generationInfo': 'Zeroni voi luoda tekstiosasta ja numero-osasta koostuvan numeron työmaalle, esim. TYOMAA00003. Järjestelmä huolehtii siitä, että kahdella työmaalla ei voi olla samaa numeroa. Syötä tekstiosa alla olevaan kenttään.',
  'site.number.placeholder': 'Syötä tunnisteen tekstiosa (väh. 3 merkkiä)',
  'site.optionalExpertise.inherited': 'Tilaajan asettamat',
  'site.optionalExpertise': 'Työmaan valinnaiset koulutukset',
  'site.ownerDocs': 'Työmaan omistajan yleiset dokumentit',
  'site.permitsNeedContracts': 'Työmaalupien pidentäminen edellyttää sopimusten pidentämistä.',
  'site.persons': 'Henkilöt',
  'site.quality': 'Laatu',
  'site.reports': 'Raportit',
  'site.requiredCompetences.inherited': 'Tilaajan asettamat',
  'site.requiredCompetences': 'Työmaan pakolliset pätevyydet',
  'site.requiredExpertiseSettings': 'Osaamisvaatimukset',
  'site.safety': 'Turvallisuus',
  'site.safetyMeasurement.targetLevel': 'Tavoitetaso',
  'site.safetyMeasurementType': 'Työturvallisuusmittaustyyppi',
  'site.safetyMeasurementTypes': 'Työturvallisuusmittaustyypit',
  'site.safetySettings.contractorRights': 'Aliurakoitsijoiden mittausoikeudet',
  'site.safetySettings.noTypesSelected': 'Ei valittuja turvallisuusmittaustyyppejä',
  'site.safetySettings': 'Mittausasetukset',
  'site.searchOrSelect': 'Työmaa',
  'site.searchOrSelectCompetenceModel': 'Pätevyys',
  'site.searchOrSelectCustomCompetenceModel': 'Koulutus',
  'site.selectSafetyMeasurementTypes': 'Valitse turvallisuusmittauksen tyypit',
  'site.senderOfEmployeeReports': 'Työntekijäilmoitusten lähettäjä',
  'site.settings.contractorCheckins.modify': 'Saa nähdä, saa muokata',
  'site.settings.contractorCheckins.noRights': 'Ei saa nähdä',
  'site.settings.contractorCheckins.read': 'Saa nähdä, ei saa muokata',
  'site.settings.contractorCheckins': 'Urakoitsijan oikeus omiin leimauksiinsa',
  'site.settings.customSectionHelp.more': 'Turvallisuusmittauksen erikoiskohteet ovat tavalliseen mittaukseen kuulumattomia kohteita, joita voidaan seurata mittauksen yhteydessä.<br/><br/>Uuteen mittaukseen otetaan mukaan aktiiviseksi merkityt kohteet. Erikoiskohteet eivät vaikuta turvallisuusmittauksen tulokseen.',
  'site.settings.customSectionHelp': 'Turvallisuusmittauksen erikoiskohteet ovat tavalliseen mittaukseen kuulumattomia kohteita, joita voidaan seurata mittauksen yhteydessä.',
  'site.settings.safetyHelp': 'Turvallisuushavaintojen ja -mittausten asetukset, ja turvavarttien aiheet.',
  'site.settings': 'Asetukset',
  'site.settingsForPermit': 'Työmaaluvassa kysyttävät tiedot',
  'site.settingsForPermitInfo': 'Valitse mitä tietoja tällä työmaalla vaaditaan työntekijän työmaaluvan luomisen yhteydessä.',
  'site.showSiteInfo': 'Näytä työmaan tiedot',
  'site.siteEmails': 'Työmaan sähköpostit',
  'site.siteForeman.notSet': 'Ei valittu',
  'site.siteForeman.set': 'Valitse työnjohtaja',
  'site.sixmonths': '6 kk',
  'site.subcontractorSignedReports.label': 'Urakkapäiväkirjan allekirjoitus',
  'site.subcontractorSignedReports': 'Urakoitsijan päiväkirjaan vaaditaan allekirjoitus',
  'site.summary': 'Työmaan yhteenveto',
  'site.threemonths': '3 kk',
  'site.timePeriodForNoReportsToTaxAdmin': 'Kausi, jolta ilmoituksia ei lähetetä',
  'site.timePeriodForReportsToTaxAdmin': 'Ilmoitusten lähetyskausi',
  'site.timePeriodForReportsToTaxAdminEnd': 'Ilmoitusten lähetyskausi päättyy',
  'site.timePeriodForReportsToTaxAdminStart': 'Ilmoitusten lähetyskausi alkaa',
  'site.twelvemonths': '12 kk',
  'site.users': 'Työmaan käyttäjät',
  'site.usersFromContractors': 'Urakoitsijoiden käyttäjät',
  'site.usersFromMyCompany': '{0}:n käyttäjät',
  'site.usesInvoiceHours.reduceLunch.tooltip': 'Lounas vähennetään automaattisesti tuntilapulta, jos päivän kokonaistyöaika on vähintään 6 tuntia ja henkilöllä on kyseiseltä päivältä vain yksi sisään- ja ulosleimaus',
  'site.usesInvoiceHours.reduceLunch': 'Lounaan automaattinen vähennys',
  'site.usesInvoiceHours': 'Tuntilappu käytössä',
  'site.workHourBalancePeriodType': 'Saldojakson pituus',
  'site.workSafetyPlan': 'Työturvallisuussuunnitelma',
  'site.workSafetyPlans': 'Työturvallisuussuunnitelmat',
  'siteCard.closeReason.damaged': 'Vahingoittunut',
  'siteCard.closeReason.lost': 'Kadonnut',
  'siteCard.closeReason.returned': 'Palautettu',
  'siteCard.status.passive': 'Puutteellinen',
  'siteCard.status.printed': 'Kortti noudettu',
  'siteCard.status.printedShort': 'Noudettu',
  'siteCard.status.suspended': 'Väliaikainen kortti käytössä',
  'siteCard.status.waitingForPhotoshooting': 'Odottaa kortin noutoa',
  'siteCard.status.waitingForPrinting': 'Personointi kesken',
  'siteContractors.contractorList': 'Urakoitsijalista',
  'siteContractors.contractorTree': 'Urakoitsijapuu',
  'siteContractors.showInContractorTree': 'Näytä urakoitsijapuussa',
  'siteDashboard.searchHelp': 'Hae esim. henkilöitä, urakoitsijoita',
  'siteEditor.fileFormatInfo': 'Lähetettävän tiedoston tyypiksi suosittelemme Excel (xlsx tai xls). Ensimmäiseen sarakkeeseen työnumero ja toiseen selite. Jos haluat käyttää csv-tiedostoa on sisältö oltava UTF-8 merkistössä muodossa: työnumero ; selite',
  'siteEditor.getCurrentJobNumberList': 'Hae nykyinen työnumero-/litteralista',
  'siteEditor.selectCSV': 'Lähetettävä tiedosto on oltava .csv-muodossa: työnumero ; selite',
  'siteLocation.mapHelp': '<strong>Aloita</strong> klikkaamalla työmaan kulmaa. <strong>Lisää</strong> pisteitä pitkin työmaan reunaa. <strong>Viimeistele</strong> alue klikkaamalla alkupisteeseen.',
  'siteLocation.mapInformation': 'Aluetta hyödynnetään mm. mobiilileimauksessa, työmaan valinnassa, teehavainto.fi -palvelussa.',
  'sites.usesInvoiceHours': 'Tuntilappu käytössä',
  'smfc.switch': 'Siirry Zeroni urakoitsijalle palveluun',
  'smfc': 'Zeroni urakoitsijalle',
  'sort.latestEndDate': 'Myöhäisin päättymispäivä',
  'sort.latestStartDate': 'Myöhäisin alkamispäivä',
  'sort.siteNumberAsc': 'Työmaan numero A→Z',
  'tag.allocateToSiteInfo': 'Kulkutunnisteella voi leimata kaikilla työmailla mutta annetun työmaan perusteella voit helpommin seurata tunnisteiden palauttamista työmaakohtaisesti tunnisteraportilta.',
  'tag.canOnlyBeReturnedBy': 'Ei palautettavissa. (Haltija: {0})',
  'tag.changeOwner': 'Siirrä kulkutunniste',
  'tag.changing': 'Siiretään...',
  'tag.ClosedMessage': '<strong>Valttikortti on lakkautettu</strong>. Syötä toinen numero.',
  'tag.closeReason.damaged': 'Vahingoittunut',
  'tag.closeReason.lost': 'Kadonnut',
  'tag.closeReason.returned': 'Palautettu',
  'tag.confirmDamaged': 'Merkintäänkö kulkutunniste <strong>{0}</strong> vahingoittuneeksi?',
  'tag.confirmDeleted': 'Poistetaanko kulkutunniste <strong>{0}</strong>?',
  'tag.confirmLost': 'Merkitäänkö kulkutunniste <strong>{0}</strong> kadonneeksi?',
  'tag.confirmReturn': 'Palautetaanko kulkutunniste <strong>{0}</strong>?',
  'tag.delete': 'Poista',
  'tag.employer': 'Työnantaja:',
  'tag.ExpiredMessage': '<strong>Valttikortti on vanhentunut</strong>. Syötä toinen numero.',
  'tag.help': 'Valttikortin, avaimenperätunnisteen tai vastaavan tunnisteen numero',
  'tag.keyRingOrOther': 'Avaimenperätunniste tai muu NFC-tunniste',
  'tag.markAsDamaged': 'Merkitse vahingoittuneeksi',
  'tag.markAsDamagedHeading': 'Merkitse tunniste vahingoittuneeksi?',
  'tag.markAsDeleted': 'Merkitse poistetuksi',
  'tag.markAsLost': 'Merkitse kadonneeksi',
  'tag.markAsLostHeading': 'Merkitse kulkutunniste kadonneeksi?',
  'tag.markTagAsDamagedHeading': 'Merkitse kulkutunniste vahingoittuneeksi?',
  'tag.markTagAsDeletedHeading': 'Merkitse kulkutunniste poistetuksi?',
  'tag.markTagAsLostHeading': 'Merkitse kulkutunniste kadonneeksi?',
  'tag.newTag': 'Uusi kulkutunniste',
  'tag.noTags': 'Ei kulkutunnisteita.',
  'tag.notAvailableMessage': '<strong>Kulkutunniste on käytössä</strong> toisella Zeroni -asiakkaalla</strong>. Syötä toinen numero.',
  'tag.otherPersonsValttiMessage': '<strong>Kulkutunniste on toisen henkilön</strong> VALTTI-kortti. VALTTI-kortteja ei voi siirtää muille.',
  'tag.return': 'Palauta',
  'tag.returnableMessage': '<strong>Kulkutunniste on käytössä</strong> henkilöllä <strong>{0}</strong>. Haluatko siirtää tunnisteen henkilölle <strong>{1}</strong>?',
  'tag.returnTagHeading': 'Palauta kulkutunniste?',
  'tag.serviceError': 'Kulkutunnisteiden käsittelyssä tapahtui virhe.',
  'tag.status.passive': 'Puutteellinen',
  'tag.status.printed': 'Kortti noudettu',
  'tag.status.printedShort': 'Noudettu',
  'tag.status.suspended': 'Väliaikainen kortti käytössä',
  'tag.status.waitingForPhotoshooting': 'Odottaa kortin noutoa',
  'tag.status.waitingForPrinting': 'Personointi kesken',
  'tag.status': 'Tila:',
  'tag.statusClosed': 'Lakkautettu',
  'tag.statusExpired': 'Vanhentunut',
  'tag.statusInuse': 'Käytössä',
  'tag.tagNumber': 'Kulkutunnisteen numero',
  'tag.tags': 'Kulkutunnisteet',
  'tag.validUntil': 'Viimeinen voimassaolopäivä:',
  'tag.valttiExpiredOrClosedMessage': 'Valttikortti on päättynyt tai suljettu eikä sitä voi enää käyttää.',
  'tags.sending': 'Lähetetään...',
  'tags.sendNew': 'Lähetä uudet kulkutunnisteet',
  'taitorekisteri': 'Taitorekisteri',
  'tax.contracts.info': 'Urakkailmoitusten asetukset koskevat vain yrityksen <strong>{0}</strong> ilmoituksia tällä työmaalla.',
  'tax.reportsSentOnSite': 'Työmaalta lähetetään ilmoitukset',
  'topMenu.contractors': 'Urakoitsijat',
  'topMenu.dailyReport': 'Päiväkirja',
  'topMenu.invoiceHours': 'Tuntilappu',
  'topMenu.loginAsCompany': 'Kirjaudu yrityksenä',
  'topMenu.loginAsEmployee': 'Kirjaudu työntekijänä',
  'topMenu.manage': 'Hallinta',
  'travelExpenseClaim.accommodationDays': 'Majoituspäivät',
  'travelExpenseClaim.alertAdded': 'Matkakorvaus lisätty',
  'travelExpenseClaim.alertApproved': 'Matkakorvaus hyväksytty',
  'travelExpenseClaim.alertDeleted': 'Matkakorvaus poistettu',
  'travelExpenseClaim.alertMarkedAsInvoiced': 'Matkakorvaus merkitty laskutetuksi',
  'travelExpenseClaim.alertRejected': 'Matkakorvaus hylätty',
  'travelExpenseClaim.alertRemoved': 'Matkakorvaus poistettu',
  'travelExpenseClaim.alertUpdated': 'Matkakorvaus päivitetty',
  'travelExpenseClaim.approve': 'Hyväksy',
  'travelExpenseClaim.approved': 'Hyväksytty',
  'travelExpenseClaim.approver': 'Hyväksyjä',
  'travelExpenseClaim.begun': 'Alkoi',
  'travelExpenseClaim.car': 'Oma auto',
  'travelExpenseClaim.concise': 'Suppea matkakorvaus',
  'travelExpenseClaim.confirmRejection': 'Vahvista hylkäys',
  'travelExpenseClaim.deleteClaimBody': 'Haluatko varmasti poistaa matkakorvauksen henkilöltä <strong>{0}</strong>?',
  'travelExpenseClaim.deleteClaimHeading': 'Poista matkakorvaus?',
  'travelExpenseClaim.description': 'Selite',
  'travelExpenseClaim.distance': 'Matkan pituus',
  'travelExpenseClaim.ended': 'Päättyi',
  'travelExpenseClaim.expenses': 'Kulut',
  'travelExpenseClaim.expenseType': 'Korvaustyyppi',
  'travelExpenseClaim.fixedExpense': 'Kiinteä matkakulu',
  'travelExpenseClaim.fixedKilometreAllowanceInfo': '<strong>Syötä</strong> kiinteä matkakulu \'Kulut\'-kohtaan',
  'travelExpenseClaim.fullPerDiems': 'Koko&shy;päivä&shy;rahat',
  'travelExpenseClaim.fullPerDiemsUnit': 'Kokopäivärahaa',
  'travelExpenseClaim.homeToSite': 'Asunnon ja työmaan välinen matkakulu',
  'travelExpenseClaim.invoiced': 'Laskutettu',
  'travelExpenseClaim.jobNumber.ownVariant': 'Littera',
  'travelExpenseClaim.jobNumber': 'Työnumero',
  'travelExpenseClaim.jobNumberTh.ownVariant': 'Littera',
  'travelExpenseClaim.jobNumberTh': 'Työ&shy;numero',
  'travelExpenseClaim.kilometreAllowance': 'Kilometrikorvaus',
  'travelExpenseClaim.kilometreBasedExpense': 'Km-perusteinen',
  'travelExpenseClaim.markAsInvoiced': 'Merkitse laskutetuksi',
  'travelExpenseClaim.newExpense': 'Uusi matkakorvaus',
  'travelExpenseClaim.noExpenses': 'Ei kuluja',
  'travelExpenseClaim.noPerDiem': 'Ei veloiteta päivärahaa',
  'travelExpenseClaim.noReceivedClaims': 'Ei vastaanotettuja matkakorvauksia',
  'travelExpenseClaim.noRejectedClaims': 'Ei hylättyjä matkakorvauksia.',
  'travelExpenseClaim.noSentClaims': 'Ei lähetettyjä matkakorvauksia',
  'travelExpenseClaim.notUsedOnSelectedSite': 'Matkakorvaukset eivät ole käytössä valitulla työmaalla.',
  'travelExpenseClaim.other': 'Muu (julkinen, kyytiläinen, taksi tmv.)',
  'travelExpenseClaim.otherClaimHasPerDiem': 'Päivärahaa haettu toisessa matkakorvauksessa.',
  'travelExpenseClaim.otherThanOwnCar': 'Muu kuin oma auto',
  'travelExpenseClaim.over10km': 'Yli 10 km',
  'travelExpenseClaim.over120km': 'Yli 120 km',
  'travelExpenseClaim.over20km': 'Yli 20 km',
  'travelExpenseClaim.over30km': 'Yli 30 km',
  'travelExpenseClaim.over40km': 'Yli 40 km',
  'travelExpenseClaim.over50km': 'Yli 50 km',
  'travelExpenseClaim.over5km': 'Yli 5 km',
  'travelExpenseClaim.over60km': 'Yli 60 km',
  'travelExpenseClaim.over70km': 'Yli 70 km',
  'travelExpenseClaim.over80km': 'Yli 80 km',
  'travelExpenseClaim.over90km': 'Yli 90 km',
  'travelExpenseClaim.partialPerDiems': 'Osa&shy;päivä&shy;rahat',
  'travelExpenseClaim.partialPerDiemsUnit': 'Osapäivärahaa',
  'travelExpenseClaim.pendingApproval': 'Odottaa hyväksyntää',
  'travelExpenseClaim.perDiem': 'Päiväraha',
  'travelExpenseClaim.perDiemEnabled': 'Veloitetaan päiväraha',
  'travelExpenseClaim.reasonForRejection': 'Hylkäyksen syy',
  'travelExpenseClaim.receivedTravelExpenseClaims': 'Vastaanotetut matkakorvaukset',
  'travelExpenseClaim.reject': 'Hylkää',
  'travelExpenseClaim.rejectClaim': 'Hylkää matkakorvaus',
  'travelExpenseClaim.rejected': 'Hylätty',
  'travelExpenseClaim.removeClaim': 'Poistetaanko matkakorvaus?',
  'travelExpenseClaim.requestConfirmationForDeletion': 'Olet poistamassa matkakorvausta henkilöltä <strong>{0}</strong>. Haluatko varmasti poistaa matkakorvauksen?',
  'travelExpenseClaim.route': 'Reitti',
  'travelExpenseClaim.selectApprover': 'Valitse hyväksyjä',
  'travelExpenseClaim.sentTravelExpenseClaims': 'Lähetetyt matkakorvaukset',
  'travelExpenseClaim.showOnlyPending': 'Näytä vain hyväksymistä odottavat',
  'travelExpenseClaim.showOnlyRejected': 'Näytä vain hylätyt',
  'travelExpenseClaim.totalDays': '<strong>Yhteensä päiviä:</strong> {0} pv',
  'travelExpenseClaim.travelDays': 'Matkustuspäivät',
  'travelExpenseClaim.travelHours': 'Matkustustunnit',
  'travelExpenseClaim.undoInvoiced': 'Poista laskutettu-merkintä',
  'travelExpenseClaim.update.add': 'Lisää matkakorvaus',
  'travelExpenseClaim.update': 'Päivitä matkakorvaus',
  'travelExpenseClaim.vehicle': 'Kulkuneuvo',
  'travelExpenseClaim': 'Matkakorvaus',
  'travelExpenseClaims.changeApprover': 'Vaihda hyväksyjää',
  'travelExpenseClaims': 'Matkakorvaukset',
  'up': 'Ylös',
  'user.accessToAllSites': 'Käyttöoikeus kaikkiin yrityksen työmaihin',
  'user.admin': 'Pääkäyttäjä',
  'user.adminAccessInfo': 'Käyttäjät, joilla on rajoittamaton käyttöoikeus, voivat muokata kaikkia työmaita.',
  'user.changeLanguage': 'Vaihda kieli',
  'user.grantedAccessToThisSite': 'Myönnetty oikeus tähän työmaahan',
  'user.noUserRolePermissions': 'Ei oikeuksia käyttäjärooleihin.',
  'user.otherUser': 'Muu käyttäjä',
  'user.otherUsers': 'Muut käyttäjät',
  'user.restrictedToSites': 'Käyttöoikeus vain valituille työmaille',
  'user.restriction': 'Rajoitus',
  'user.searchUserRole': 'Hae käyttäjärooli',
  'user.selectUserRole': 'Valitse käyttäjärooli',
  'user.session': 'Istunto',
  'user.ssoHelp': 'Azure Active Directory on tunnistautumispalvelu, jonka avulla Zeroniin voi kirjautua ilman Zeroni -salasanaa. Jos Azure AD -tunnus on syötetty, kirjautuminen tehdään käyttämällä yrityksen Azure AD -palvelua.',
  'user.ssoUsername': 'Azure AD -tunnus',
  'user.superUser.remainder': '<strong>Varoitus!</strong> Tämä valinta antaa käyttäjälle oikeuden kaikkiin asiakasympäristöhin!',
  'user.superUser': 'Superkäyttäjä',
  'user.unrestricted': 'Rajoittamaton käyttöoikeus',
  'user.userInformation': 'Käyttäjän tiedot',
  'user.userRole': 'Käyttäjärooli',
  'userEditor.adminRestrictionInfo': 'Pääkäyttäjillä on automaattisesti hallintaoikeus kaikkiin työmaihin.',
  'userEditor.emailAddress': 'Sähköposti',
  'userEditor.emailDomainIsDifferent': '<strong>Huomautus:</strong> Sähköpostin loppuosa poikkeaa omastasi. Nyt lisättävä käyttäjä saa käyttöoikeuden yritykseesi. <em>Urakoitsijan</em> käyttäjätunnus luodaan sopimuksen kautta.',
  'userEditor.firstName': 'Etunimi',
  'userEditor.lastLogin': 'Viimeisin kirjautuminen',
  'userEditor.lastName': 'Sukunimi',
  'userEditor.noLogins': 'Käyttäjällä ei kirjautumisia',
  'userEditor.onlyUserCanChange': 'Vain henkilö itse voi muokata.',
  'userEditor.otherRestrictionInfo': 'Käyttäjällä on rajoitettu käyttöoikeus, joten on erikseen määriteltävä mihin työmaihin hänellä on hallintaoikeus.',
  'userEditor.removeSites': 'Tyhjennä',
  'userEditor.selectUserGroup': 'Valitse käyttäjäryhmä',
  'userEditor.telephone': 'Puhelinnumero',
  'userEditor.userEmailExists': '<strong>Sähköpostiosoite on jo käytössä toisella henkilöllä!</strong> <br> <i>Vinkki 1: Tarkista, ettei samaa henkilöä ole mahdollisesti lisätty jo väärällä veronumerolla.</i><br><i>Vinkki 2: Tarkista, ettei yrityksessäsi työskentele kahta saman nimistä henkilöä.</i>',
  'userEditor.userExists': '<strong>Käyttäjä olemassa:</strong> Sähköpostiosoite on jo käytössä.',
  'userEditor.userGroup': 'Hae käyttäjäryhmä',
  'userEditor.valueChangeText': 'Käyttäjätunnusta, sähköpostia ja puhelinnumeroa ei voi tietoturvasyistä vaihtaa. Jos ne on virheellisesti kirjattu, niin olkaa yhteydessä support@zeroni.fi',
  'userGroup.companyRights': 'Oikeudet yrityksiin',
  'userGroup.declineAllRights': 'Poista kaikki oikeudet',
  'userGroup.grantAllRights': 'Anna täydet oikeudet',
  'userGroup.operation.add': 'Lisäys',
  'userGroup.operation.addRemove': 'Lisäys/poisto',
  'userGroup.operation.approve': 'Hyväksyminen',
  'userGroup.operation.approveReject': 'Hyväksyminen / hylkäys',
  'userGroup.operation.asterisk': 'Luku',
  'userGroup.operation.cancel': 'Peruutus',
  'userGroup.operation.confirm': 'Vahvistus',
  'userGroup.operation.connectDisconnect': 'Lisäys työmaalle / poisto työmaalta',
  'userGroup.operation.continueSuspend': 'Keskeytys / jatkaminen',
  'userGroup.operation.modify': 'Muokkaus',
  'userGroup.operation.move': 'Siirrä',
  'userGroup.operation.orient': 'Vahvistus',
  'userGroup.operation.read': 'Luku',
  'userGroup.operation.remove': 'Poisto',
  'userGroup.operation.repair': 'Korjaus',
  'userGroup.operation.subcontract': 'Ketjutus',
  'userGroup.permissions.company': 'Yritykset',
  'userGroup.permissions.person.accessPermit.label': 'Merkinnät',
  'userGroup.permissions.person.accessPermit.role': 'Rooli',
  'userGroup.permissions.person.accessPermit': 'Työmaaluvat',
  'userGroup.permissions.person.additionalRequiredField.postcode': 'Postinumero pakollinen',
  'userGroup.permissions.person.competence': 'Pätevyydet',
  'userGroup.permissions.person.drugTest': 'Huumetesti',
  'userGroup.permissions.person.healthCard': 'Työterveyskortti',
  'userGroup.permissions.person.manualTaxNumberVerification': 'Veronumeron hyväksyntä',
  'userGroup.permissions.person.newcomerTraining': 'Tulokoulutus',
  'userGroup.permissions.person.securityClearance': 'Turvallisuusselvitys',
  'userGroup.permissions.person.tags': 'Kulkutunnisteet',
  'userGroup.permissions.person.workSafetyCard': 'Työturvallisuuskortti',
  'userGroup.permissions.person': 'Henkilöt',
  'userGroup.permissions.site.clockIn': 'Leimaukset',
  'userGroup.permissions.site.contract.contractorObligation': 'Tilaajavastuu',
  'userGroup.permissions.site.contract.payment': 'Maksutapahtumat',
  'userGroup.permissions.site.contract.requestForApproval': 'Hyväksyminen',
  'userGroup.permissions.site.contract': 'Sopimukset',
  'userGroup.permissions.site.dailyReport.supervisor': 'Valvojan huomiot',
  'userGroup.permissions.site.dailyReport.template': 'Päiväkirjapohja',
  'userGroup.permissions.site.dailyReport': 'Työmaapäiväkirja',
  'userGroup.permissions.site.device': 'Laitteet',
  'userGroup.permissions.site.doc': 'Dokumentit',
  'userGroup.permissions.site.invoiceHours': 'Tuntilappu',
  'userGroup.permissions.site.requiredCompetences': 'Vaatimukset',
  'userGroup.permissions.site.safety.deviation.anonymous': 'Vahvistamaton',
  'userGroup.permissions.site.safety.deviation.environment': 'Ympäristö',
  'userGroup.permissions.site.safety.deviation.positive': 'Positiivinen',
  'userGroup.permissions.site.safety.deviation': 'Turvallisuuspoikkeamat',
  'userGroup.permissions.site.safety.measurement': 'Turvallisuusmittaukset',
  'userGroup.permissions.site.safety.summary': 'Turvallisuusyhteenveto työmailta',
  'userGroup.permissions.site.safety': 'Turvallisuus',
  'userGroup.permissions.site.travelExpense': 'Matkakorvaukset',
  'userGroup.permissions.site': 'Työmaat',
  'userGroup.personRights': 'Oikeudet henkilöihin',
  'userGroup.safetyRights': 'Oikeudet turvallisuusmerkintöihin',
  'userGroup.sitePermissionsInfo.sitePermissionsInfo': 'Oikeus muokata työmaan verottajailmoitusten asetuksia on sidottu oikeuteen lisätä työmaa.',
  'userGroup.sitePermissionsInfo': 'Oikeus muokata työmaan verottajailmoitusten asetuksia on sidottu oikeuteen lisätä työmaa.',
  'userGroup.siteRights': 'Oikeudet sopimuksiin',
  'userGroup.standardCantBeChanged': 'Vakioroolia ei voi muokata.',
  'userGroup.standardGroup': 'Vakiorooli',
  'userGroup.thisIsStandard': 'Tämä käyttäjärooli on vakiorooli.',
  'userGroup.userGroupsModifyInfo': 'Käyttäjäroolien muokkausoikeus on sidottu pääkäyttäjärooliin.',
  'userSettings.changePassword': 'Vaihda salasana',
  'userSettings.defaultCompany': 'Oletusyritys',
  'userSettings.defaultCompanyAndSite': 'Oletusyritys ja -työmaa',
  'userSettings.defaultCompanyAndSiteIntro': 'Kun kirjaudut sisään, oletusyritys ja oletustyömaa valitaan automaattisesti.',
  'userSettings.defaultCompanyAndSiteShort': 'Oletusyritys',
  'userSettings.defaultSite': 'Oletustyömaa',
  'userSettings.defaultSiteIntro': 'Kun kirjaudut sisään, oletustyömaa valitaan automaattisesti.',
  'userSettings.defaultSiteOnlyForDefaultCompany': 'Oletustyömaa on asetettavissa vain jos olet kirjautunut sisään oletusyrityksen nimissä (tällä hetkellä olet kirjautunut yritykseen {0}).',
  'userSettings.settingsSaved': 'Asetukset tallennettu!',
  'userSettings': 'Käyttäjäasetukset',
  'validator.errors.emailFormat': 'Virheellinen sähköpostiosoite',
  'validator.errors.emailNotUnique': 'Jollain toisella on jo tämä osoite käytössä',
  'validator.errors.phoneFormat': 'Virheellinen puhelinnumero',
  'validator.errors.phoneNotUnique': 'Jollain toisella on jo tämä numero käytössä',
  'validator.errors.preventEmptying': 'Yhteystieto vaaditaan, jotta henkilö voi kirjautua sisään.',
  'validator.errors.required': 'Pakollinen tieto',
  'validator.valueNotChanged': 'Arvo ei ole muuttunut',
  'vastuugroup.service.notavailable': 'Vastuu Group tiedot ei saatavilla',
  'vastuugroup.service.notAvailableInfo': 'Tilajaavastuutietojen lataaminen vaatii Vastuu Group Yritystilin ja Zeroni-rajapintatunnisteen käyttöönoton <a href="https://www.vastuugroup.fi/" target="_blank">Vastuu Groupin verkkopalvelussa</a>. Tieto tunnisteen käyttöönotosta päivittyy Zeroniin automaattisesti tunnin viiveellä palvelun käyttöönotosta.',
  'vastuugroup.service.pdf': 'PDF',
  'vehiclePermit.active': 'Aktiivinen',
  'vehiclePermit.activeDaily': 'Voimassa päivittäin',
  'vehiclePermit.approvalCancelledUponChange': 'Lupa lähetetään uudestaan hyväksyttäväksi, jos ajoneuvon tiedot muuttuvat.',
  'vehiclePermit.approved': 'Hyväksytty',
  'vehiclePermit.approvedOn': 'Hyväksytty {0}.',
  'vehiclePermit.approvers': 'Hyväksyjät',
  'vehiclePermit.cancelApprovalAndEdit': 'Poista hyväksyntä ja muokkaa ajoneuvon tietoja',
  'vehiclePermit.cancelRejectionAndEdit': 'Poista hylkäys ja muokkaa ajoneuvon tietoja',
  'vehiclePermit.confirmRemoveVehicleFromVehiclePermit': 'Haluatko varmasti poistaa ajoneuvon {0}?',
  'vehiclePermit.confirmVehiclePermitDeletion': 'Haluatko varmasti poistaa ajoneuvoluvan?',
  'vehiclePermit.customOrforeignRegistrationNumber': 'Erityiskilpi tai ulkomainen rekisterinumero',
  'vehiclePermit.duration': 'Kesto',
  'vehiclePermit.expired': 'Vanhentunut',
  'vehiclePermit.hasToBeActiveForAtLeast1Minute': 'Valitse kestoksi yli 0 min.',
  'vehiclePermit.missingAccessPermitForPeriods': 'Henkilöllä ei ole työmaalupaa välille {0}. Voit luoda ajoneuvoluvan, mutta lupa ei ole voimassa edellä mainittuina ajanjaksoina.',
  'vehiclePermit.missingUsersFromListOfPossibleApprovers': 'Jos etsimäsi henkilö puuttuu listalta, varmista, että kyseisen käyttäjän käyttäjäroolille on asetettu ajoneuvolupien hyväksyntäoikeus.',
  'vehiclePermit.newVehiclePermit': 'Uusi ajoneuvolupa',
  'vehiclePermit.noApproversThus': 'Erillisiä hyväksyjiä ei ole valittu. Hyväksyjinä toimivat pääkäyttäjät.',
  'vehiclePermit.noPermission': 'Ei käyttöoikeutta ajoneuvolupiin.',
  'vehiclePermit.noPermissionVerbose': 'Käyttäjäroolillasi ei ole tarvittavia käyttöoikeuksia ajoneuvolupien tarkastelemiseen. Pyydä yrityksesi pääkäyttäjää lisäämään käyttäjäroolillesi tarvittavat käyttöoikeudet. HUOM: Käyttäjäroolin päivittämisen jälkeen sinun tulee kirjautua ulos ja takaisin sisään järjestelmään, jotta päivitetyt käyttöoikeudet astuvat voimaan.',
  'vehiclePermit.noPermitsOnThiSite': 'Työmaalle ei ole annettu ajoneuvolupia.',
  'vehiclePermit.notValid': 'Ei voimassa',
  'vehiclePermit.noValidAccessPermitsForSelectedContract': 'Henkilöllä ei ole voimassa olevia tai tulevaisuudessa voimaan astuvia työmaalupia valitulle sopimukselle.',
  'vehiclePermit.pending': 'Odottaa hyväksyntää',
  'vehiclePermit.permitApprovers': 'Hyväksyjät',
  'vehiclePermit.personHasOverlappingVehiclePermits': 'Henkilöllä on jo seuraavat ajoneuvoluvat:',
  'vehiclePermit.rejected': 'Hylätty',
  'vehiclePermit.rejectedOn': 'Hylätty {0}.',
  'vehiclePermit.requester': 'Luvanhakija',
  'vehiclePermit.search': 'Haku',
  'vehiclePermit.searchHelp': 'Rekisterinumero tai henkilön nimi',
  'vehiclePermit.searchResults.many': '{0} ajoneuvolupaa',
  'vehiclePermit.searchResults.one': '1 ajoneuvolupa',
  'vehiclePermit.showActiveAndUpcoming': 'Aktiiviset ja tulevat',
  'vehiclePermit.showAll': 'Kaikki',
  'vehiclePermit.showApprovable': 'Hyväksyttävät',
  'vehiclePermit.showApproved': 'Hyväksytyt',
  'vehiclePermit.showPendingAndWaitingActions': 'Hyväksyttävät ja toimenpiteitä odottavat',
  'vehiclePermit.showWaitingActions': 'Toimenpiteitä odottavat',
  'vehiclePermit.status': 'Luvan tila',
  'vehiclePermit.timePeriod': 'Kesto',
  'vehiclePermit.upcoming': 'Tuleva',
  'vehiclePermit.upcomingWithStartDate': 'Astuu voimaan {0}',
  'vehiclePermit.updateRevertsVehiclePermitBackToPendingState': 'Haluatko varmasti muokata ajoneuvolupaa? Muokkaaminen palauttaa ajoneuvoluvan takaisin "Odottaa käsittelyä" -tilaan.',
  'vehiclePermit.useAsTemplate': 'Käytä pohjana',
  'vendorEvaluation.averageAll': 'Kaikkien arviointien keskiarvo',
  'vendorEvaluation.siteAverage': 'Arviointien keskiarvo tällä työmaalla',
  'visa.expirationDate': 'Viimeinen voimassaolopäivä',
  'vm.contract.continuing': 'Jatketaan...',
  'vm.contract.interrupting': 'Keskeytetään...',
  'weeklyHours.help': 'Viikkotunteja käytetään tapaturmataajuuden laskemiseen.',
  'workPermit.active': 'Aktiiviset',
  'workPermit.addPermit': 'Lisää työlupa',
  'workPermit.address': 'Työpaikan osoite',
  'workPermit.addressPlaceHolder': 'Osoite',
  'workPermit.approvalAndSignaturesSideButton': 'Hyväksyntä ja allekirjoitukset',
  'workPermit.approverSignature': 'Myöntäjän allekirjoitus',
  'workPermit.area': 'Alueen nimi',
  'workPermit.areDangersIdentified': 'Onko työtapaan mahdollisesti liittyvä vaara tunnistettu ja otettu käyttöön:',
  'workPermit.assignment': 'Työtehtävä',
  'workPermit.backToWorkPermits': 'Takaisin työlupiin',
  'workPermit.changePermitOwner': 'Vaihda haltijaa',
  'workPermit.close': 'Työluvan päättäminen',
  'workPermit.closed': 'Ei suljettuja työlupia.',
  'workPermit.confirmationOfHandoverOfPermission': 'Uusi luvan haltija vahvistaa, että tässä työluvassa ja työlupaan liitetyissä dokumenteissa kuvatut työturvallisuusjärjestelyt on tarkkaan huomioitu ja että kaikki henkilöt työtiimissä ovat tietoisia turvatoimista.',
  'workPermit.confirmationTextOfApproval': 'Luvan myöntäjä vahvistaa, että kaikki valmistelutyöt mukaanlukien eristykset on suoritettu ja että työt on turvallista aloittaa',
  'workPermit.confirmationTextOfPermitOwner': 'Luvan haltija ymmärtää, että työturvallisuus työpisteellä on järjestetty työn aloittamista varten',
  'workPermit.confirmChangeOfPermitOwner': 'Työluvan vaihdon vahvistus',
  'workPermit.currentWorkPermitHolder': 'Nykyinen haltija',
  'workPermit.dangerEstimation': 'Vaarojen kartoitus',
  'workPermit.dangerEstimationMissing': 'Syy arvion puuttumiseen',
  'workPermit.dangerEvaluation': 'Vaarojen arviointi (JSEA)',
  'workPermit.dangerSurvey': 'Vaarojen kartoitus',
  'workPermit.describe': 'Erittele',
  'workPermit.description': 'Työn kuvaus/tarkoitus',
  'workPermit.description2': 'kuvaus',
  'workPermit.descriptionAndGeneralInfo': 'Työn kuvaus, voimassaoloaika, yritys ja myöntäjä',
  'workPermit.descriptionAndGeneralInfoSideButton': 'Kuvaus, Työntekijät, vaarojen selvitys',
  'workPermit.duration': 'Voimassaoloaika',
  'workPermit.electricalLockingNumber': 'Sähköisen lukituksen numero',
  'workPermit.electricLocking': 'Sähköinen lukitus',
  'workPermit.employeeAndIssuingDate': 'Työntekijä ja myöntämispvm.',
  'workPermit.employeeNames': 'Työntekijöiden nimet',
  'workPermit.employer': 'Yritys',
  'workPermit.equipments': 'Työvälineet',
  'workPermit.extend': 'Työluvan siirto',
  'workPermit.extending': 'Työluvan siirron vahvistus',
  'workPermit.exWork': 'Ex-työt',
  'workPermit.formerPermitOwnerSignature': 'Luvan luovuttajan allekirjoitus',
  'workPermit.hotWorkLocking': 'Tulityö',
  'workPermit.issuingPermit': 'Työluvan myöntäminen',
  'workPermit.jobTitle': 'Työtehtävä',
  'workPermit.jobTitleDescription': 'Työtehtävän kuvaus',
  'workPermit.location': 'Työkohde',
  'workPermit.locked': 'Lukittu',
  'workPermit.locked2': 'LUKITTU',
  'workPermit.locker': 'Merkitsijä',
  'workPermit.lockingNumber': 'lukituksen nro.',
  'workPermit.mechanicalLocking': 'Mekaaninen lukitus',
  'workPermit.mechanicalLockingNumber': 'Mekaanisen lukituksen numero',
  'workPermit.mobile.equipments': 'Työväl.',
  'workPermit.mobile.general': 'Yleiset',
  'workPermit.mobile.security': 'Turv.',
  'workPermit.mobile.signaturesAndConfirmation': 'Hyv.',
  'workPermit.nameOfSiteBuildingDepartmentRegionPlaceHolder': 'Yrityksen, rakennuksen, osaston ja/tai alueen nimi',
  'workPermit.new': 'Uusi työlupa',
  'workPermit.newWorkPermit': 'Uusi työlupa',
  'workPermit.newWorkPermitOwner': 'Uusi luvan haltija',
  'workPermit.newWorkPermitOwnerSignature': 'Luvan vastaanottajan allekirjoitus',
  'workPermit.nextStep': 'Seuraava vaihe',
  'workPermit.noActivePermits': 'Ei aktiivisia työlupia',
  'workPermit.none': 'Ei työlupia',
  'workPermit.noPermitsHaveBeenAdded': 'Yhtään työlupaa ei ole vielä lisätty.',
  'workPermit.noTerminatedPermits': 'Ei päätettyjä työlupia',
  'workPermit.opened': 'Aukaistu',
  'workPermit.opened2': 'AUKAISTU',
  'workPermit.openLockings': 'Avaa lukituksia',
  'workPermit.otherWork': 'Muu työ',
  'workPermit.permitOwner': 'Luvan haltija',
  'workPermit.permitOwners': 'Luvan haltijat',
  'workPermit.permitOwnerSignature': 'Luvan haltijan allekirjoitus',
  'workPermit.permitValidDailyAt': 'Lupa voimassa päivittäin klo',
  'workPermit.personInCharge': 'VASTUUHENKILÖ',
  'workPermit.personsInCharge': 'Vastuuhenkilö',
  'workPermit.precautions': 'Turvatoimet',
  'workPermit.precautionsSideButton': 'Varotoimenpiteet',
  'workPermit.remove': 'Poista',
  'workPermit.riskEvaluation': 'Riskien arviointi',
  'workPermit.safetyLocking': 'TURVALUKITUS',
  'workPermit.safetyLockings': 'Turvalukitukset',
  'workPermit.signatureHolder': 'Luvan haltijan allekirjoitus',
  'workPermit.signatureIssuer': 'Luvan myöntäjän allekirjoitus',
  'workPermit.subscriber': 'Työn kuvaus, voimassaoloaika, tilaaja ja myöntäjä',
  'workPermit.terminate': 'Päätä',
  'workPermit.terminated': 'Päätetyt',
  'workPermit.terminateWorkPermit': 'Työluvan sulkeminen',
  'workPermit.terminationConfirmationText': 'Kaikki työt tähän lupaan liittyen on saatu valmiiksi ja muita töitä ei tarvita.',
  'workPermit.toolsSideButton': 'Työvälineet',
  'workPermit.transferToOther': 'Työluvan vaihto eri henkilölle',
  'workPermit.workDescription': 'Syy:',
  'workPermit.workers': 'Työntekijät',
  'workPermit.workPermitApprover': 'Luvan myöntäjä',
  'workPermit.workPermitDelete': 'Poistetaanko työlupa?',
  'workPermit.workPermitDeleteMessage': 'Haluatko varmasti poistaa työluvan?',
  'workPermit.workPermitHolder': 'Työntekijät',
  'workPermit.workPermits': 'Työluvat',
  'workPermit': 'Työlupa',
  'workPermits.close': 'Työluvan päättäminen',
  'workPermits.extend': 'Työluvan siirto',
  'workPermits.new': 'Uusi työlupa',
  'workPermits': 'Työluvat',
  'zfc': 'Zeroni urakoitsijalle',
};
