import React from 'react';
import { angularize } from 'react-in-angularjs';
import Modal from 'ReactComponents/Modal';
import I18n from 'ReactComponents/I18n';
import i18nService from './services/i18n.service';
var ChangeLanguageModal = function (_a) {
    var isOpen = _a.isOpen, onClose = _a.onClose, availableLanguageCodes = _a.availableLanguageCodes;
    var selectedLanguage = window.LOCALE;
    function renderLanguageItem(_a) {
        var languageCode = _a[0], languageName = _a[1];
        if (languageCode === selectedLanguage) {
            return (React.createElement("div", { key: languageCode, className: 'list-group-item list-group-item-action active' }, languageName));
        }
        else {
            return (React.createElement("a", { key: languageCode, href: "?lang=".concat(languageCode), className: 'list-group-item list-group-item-action' }, languageName));
        }
    }
    return (React.createElement(Modal, { isOpen: isOpen, onClose: onClose },
        React.createElement(Modal.Header, { closeButton: true, className: "modalHeader" },
            React.createElement("h3", { className: "mt-0 mb-0" },
                React.createElement(I18n, { code: "user.changeLanguage", text: "Vaihda kieli" }))),
        React.createElement(Modal.Body, { className: "pl-0 pr-0 pb-0" },
            React.createElement("div", { className: "list-group list-group-flush border-top" }, availableLanguageCodes
                .map(function (lang) { return [lang, i18nService.get("locale.".concat(lang, ".nameInThisLanguage"))]; })
                .sort(function (a, b) { return a[1].localeCompare(b[1]); })
                .map(renderLanguageItem))),
        React.createElement(Modal.Footer, { className: "justify-content-start", onCancel: onClose })));
};
var angularBindings = {
    isOpen: '<',
    onClose: '&',
    availableLanguageCodes: '<',
};
var moduleName = window.appName === 'loginApp' ? 'loginApp' : 'app.core';
angularize(ChangeLanguageModal, 'reactChangeLanguageModal', angular.module(moduleName), angularBindings);
export default ChangeLanguageModal;
