(function() {
  'use strict';

  // used in conjunction with ng-if
  angular
    .module('blocks.fadeIn')
    .directive('fadeIn', fadeIn);

  function fadeIn () { // fade IN only
    return {
      restrict: 'A',
      link($scope, element, attrs) {

        var duration = attrs.fadeDuration || 200;

        $scope.$watch(attrs.fadeIn, function (value) {
          if (value) {
            $(element).fadeIn(duration);
          } else {
            $(element).hide();
          }
        });

      },
    };
  }

})();
