import './inc/js/commonIncludes.js';

// LIB FILES
import './loginLibFiles';


//// CSS FiLES START ////

import('../scss/zeroni-login.scss');

//// CSS FiLES END ////


// Same as sitemanager main
import './inc/helpers/sentryAngularPlugin';

// OUR FILES
import './inc/js/constants.js';
import './inc/js/loginApp.js';

import './blocks/lowercase/lowercase.module.js';
import './blocks/lowercase/lowercase.directive.js';

import './inc/js/includes.js';
import './blocks/issue-monitoring/issue-monitoring.module.js';
import './blocks/issue-monitoring/issue-monitoring.service.js';
import './blocks/i18n/i18n.module.js';
import './blocks/i18n/i18n-simple.component.js';
import './blocks/i18n/i18n-simple-placeholder.directive.js';
import './blocks/i18n/i18n-simple.service.js';
import './blocks/i18n/messages-de.service.js';
import './blocks/i18n/messages-en.service.js';
import './blocks/i18n/messages-et.service.js';
import './blocks/i18n/messages-fi.service.js';
import './blocks/i18n/messages-pl.service.js';
import './blocks/i18n/messages-ru.service.js';
import './blocks/i18n/messages-sk.service.js';
import './blocks/i18n/messages-sv.service.js';
import './blocks/i18n/messages-override.service.js';
import './blocks/i18n/current-site-service-stump.js';
import './blocks/fade-in/fade-in.module.js';
import './blocks/fade-in/fade-in.directive.js';

import 'React/ChangeLanguageModal';
