(function() {

  angular
    .module('blocks.i18n')
    .directive('i18nSimplePlaceholder', i18nSimplePlaceholder);

  /* @ngInject */
  function i18nSimplePlaceholder(i18nSimpleService) {
    return {
      template: '<input/>',
      link(scope, element, attrs) {
        const message = i18nSimpleService.get(attrs.i18nSimplePlaceholder);
        element.attr('placeholder', message);
      },
    };
  }

})();
