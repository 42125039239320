(function () {
  'use strict';

  angular
    .module('blocks.lowercase')
    .directive('lowercase', lowercase);

  //directive.$inject = ['dependencies'];

  function lowercase () {

    var directive = {
      link,
      require: 'ngModel',
      restrict: 'A',
    };
    return directive;

    function link(scope, element, attrs, controller) {

      controller.$parsers.push(lowercaseParser);

      function lowercaseParser (inputValue) {

        var transformedInput = inputValue.toLowerCase();

        if (transformedInput !== inputValue) {
          controller.$setViewValue(transformedInput);
          controller.$render();
        }

        return transformedInput;

      }

    }

  }

})();
