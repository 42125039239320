import fiMessages from './messages-fi';

(function() {
  'use strict';

  angular
    .module('blocks.i18n')
    .service('messagesFiService', messagesFiService);

  function messagesFiService() {
    this.messages = fiMessages;
  }

})();
